import { PhaseTypeDTO } from "Contracts/PlayooLeagueClient";
import { Match } from "Domain/Matches/Match";
import { l } from "Languages";
import { formatStage } from "./KnockoutPhase/formatting";

export const formatMatchInPhase = (match: Match, isLegacyKnockoutPhase?: boolean) => {
    switch (match.phaseType) {
        case PhaseTypeDTO.Knockout:
            return formatStage(match.stage, isLegacyKnockoutPhase);
        case PhaseTypeDTO.Custom:
            return match.name;
        case PhaseTypeDTO.Groups:
            return `${match.groupName} - ${l("CompetitionPhases_Details_Schedule_Matchday", match.matchday)}`;
        case PhaseTypeDTO.Table:
            return l("CompetitionPhases_Details_Schedule_Matchday", match.matchday);
    }
};
