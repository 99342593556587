import AccessCodesPage from "Pages/AccessCodes";
import ArticlesPage from "Pages/Articles";
import CreateArticlePage from "Pages/Articles/CreateArticle";
import EditArticlePage from "Pages/Articles/EditArticle";
import CompetitionGroupsPage from "Pages/CompetitionGroups";
import CompetitionPhaseDetailsPage from "Pages/CompetitionPhases/CompetitionPhaseDetails";
import ConfigureKnockoutPhaseFirstStagePage from "Pages/CompetitionPhases/CompetitionPhaseDetails/KnockoutPhase/ConfigureKnockoutPhaseFirstStage";
import PlanSchedulePage from "Pages/CompetitionPhases/CompetitionPhaseDetails/PlanSchedule";
import CompetitionDetailsPage from "Pages/Competitions/CompetitionDetails";
import CompetitionsListPage from "Pages/Competitions/CompetitionsList";
import CompetitionTeamDetailsPage from "Pages/CompetitionTeams/CompetitionTeamDetails";
import CompetitionTeamPlayerDetailsPage from "Pages/CompetitionTeams/CompetitionTeamPlayerDetails";
import LandingsPage from "Pages/Landings";
import CreateMatchArticlePage from "Pages/Matches/CreateMatchArticle";
import EditMatchArticlePage from "Pages/Matches/EditMatchArticle";
import MatchDetailsPage from "Pages/Matches/MatchDetails";
import MatchPhotosPage from "Pages/Matches/MatchPhotos";
import RegistrationsPage from "Pages/Registrations";
import CreateRegistrationPage from "Pages/Registrations/CreateRegistrations";
import EditRegistrationPage from "Pages/Registrations/EditRegistration";
import RegistrationDetailsPage from "Pages/Registrations/RegistrationDetails";
import SeasonsPage from "Pages/Seasons";
import PlanSeasonSchedulePage from "Pages/Seasons/PlanSchedule";
import TenantPage from "Pages/Tenant";
import VideosPage from "Pages/Videos";
import React from "react";
import { Route, Switch } from "react-router";
import routes from "Router/routes";
import DefaultRedirect from "./DefaultRedirect";

const AuthenticatedAppRouter: React.FunctionComponent = () => {
    return (
        <Switch>
            <Route exact path={routes.competitions.pattern} component={CompetitionsListPage} />
            <Route path={routes.competitionDetails.pattern} component={CompetitionDetailsPage} />
            <Route exact path={routes.competitionGroups.pattern} component={CompetitionGroupsPage} />
            <Route
                exact
                path={routes.competitionTeam.players.playerDetails.pattern}
                component={CompetitionTeamPlayerDetailsPage}
            />
            <Route path={routes.competitionTeam.pattern} component={CompetitionTeamDetailsPage} />

            <Route exact path={routes.seasons.pattern} component={SeasonsPage} />
            <Route exact path={routes.seasons.planSchedule.pattern} component={PlanSeasonSchedulePage} />

            <Route exact path={routes.accessCode.pattern} component={AccessCodesPage} />
            <Route exact path={routes.tenant.pattern} component={TenantPage} />

            <Route
                path={routes.competitionPhase.configureKnockoutPhaseFirstStage.pattern}
                component={ConfigureKnockoutPhaseFirstStagePage}
            />
            <Route path={routes.competitionPhase.planSchedule.pattern} component={PlanSchedulePage} />
            <Route path={routes.competitionPhase.pattern} component={CompetitionPhaseDetailsPage} />

            <Route exact path={routes.articles.pattern} component={ArticlesPage} />
            <Route exact path={routes.articles.create.pattern} component={CreateArticlePage} />
            <Route exact path={routes.articles.edit.pattern} component={EditArticlePage} />

            <Route exact path={routes.matchDetails.pattern} component={MatchDetailsPage} />
            <Route exact path={routes.matchDetails.article.create.pattern} component={CreateMatchArticlePage} />
            <Route exact path={routes.matchDetails.article.edit.pattern} component={EditMatchArticlePage} />
            <Route exact path={routes.matchDetails.gallery.pattern} component={MatchPhotosPage} />

            <Route exact path={routes.registrations.pattern} component={RegistrationsPage} />
            <Route exact path={routes.registrations.create.pattern} component={CreateRegistrationPage} />
            <Route exact path={routes.registrations.edit.pattern} component={EditRegistrationPage} />
            <Route exact path={routes.registrations.details.pattern} component={RegistrationDetailsPage} />

            <Route exact path={routes.videos.pattern} component={VideosPage} />

            <Route exact path={routes.landing.pattern} component={LandingsPage} />

            <DefaultRedirect />
        </Switch>
    );
};

export default AuthenticatedAppRouter;
