import { handleResponse } from "@leancode/validation";
import { AddRegistration } from "Contracts/RegistrationsClient";
import Article from "Domain/Articles/Article";
import moment from "moment";
import registrationsApi from "Services/RegistrationsApi";
import { dateToDTO, dateToDTOOptional } from "Utils/DTO";
import { ArrayStore } from "Utils/mobx";
import newId from "Utils/newId";
import retryQuery from "Utils/retryQuery";
import Registration from "./Registration";

class RegistrationsStore extends ArrayStore<Registration> {
    async fetchRegistrations() {
        const registrations = await retryQuery(() => registrationsApi.allRegistrations({}));

        this.set(registrations.map(Registration.fromDTO));
    }

    async fetchRegistrationDetails(registrationId: string, includeApplications: boolean = false) {
        const dto = await retryQuery(() =>
            registrationsApi.registrationDetails({
                RegistrationId: registrationId,
                IncludeApplications: includeApplications,
            }),
        );

        if (!dto) {
            throw new Error(`Registration with id ${registrationId} doesn't exist.`);
        }

        let registration = this.getById(registrationId);

        if (registration) {
            registration.updateFromDetails(dto);
        } else {
            registration = Registration.fromDetailsDTO(dto);

            this.add(registration);
        }

        await registration.fetchArticle();
    }

    async addRegistration({
        id,
        title,
        summary,
        logoUri,
        thumbnailPhotoUri,
        backgroundPhotoUri,
        entryFee,
        startDate,
        endDate,
        maxTeamsCount,
        extraFormFieldDescription,
        maxAllowedBirthYears,
        documents,
        articleTitle,
        articleContent,
        address,
        city,
        sportsVenueName,
    }: AddRegistrationData) {
        const response = await registrationsApi.addRegistration({
            Id: id,
            Title: title,
            Summary: summary,
            LogoUri: logoUri,
            ThumbnailPhotoUri: thumbnailPhotoUri,
            BackgroundPhotoUri: backgroundPhotoUri,
            EntryFee: entryFee,
            StartDate: dateToDTO(startDate),
            EndDate: dateToDTOOptional(endDate),
            MaxTeamsCount: maxTeamsCount,
            AgeGroups: maxAllowedBirthYears.map(y => ({
                Id: newId(),
                MaxAllowedBirthYear: y,
            })),
            Documents: documents?.map(d => ({
                Id: d.id,
                Name: d.name,
                Uri: d.uri,
                RequiresAcceptance: d.requiresAcceptance,
            })),
            SportsVenueName: sportsVenueName,
            Address: address,
            City: city,
            ExtraFormFieldDescription: extraFormFieldDescription,
            ArticleId: newId(),
            ArticleTitle: articleTitle,
            ArticleContent: Article.prepareContent(articleContent),
        });

        return handleResponse(response, AddRegistration);
    }
}

export type AddRegistrationData = {
    id: string;
    title: string;
    summary?: string;
    logoUri?: string;
    thumbnailPhotoUri?: string;
    backgroundPhotoUri?: string;
    entryFee?: string;
    startDate: moment.Moment;
    endDate?: moment.Moment;
    maxTeamsCount?: number;
    extraFormFieldDescription?: string;
    sportsVenueName?: string;
    address?: string;
    city?: string;
    maxAllowedBirthYears: (number | undefined)[];
    documents?: {
        id: string;
        name: string;
        uri: string;
        requiresAcceptance: boolean;
    }[];
    articleTitle: string;
    articleContent: string;
};

export default RegistrationsStore;
