import { DeleteOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Modal, Skeleton, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import DropdownMenu from "Components/DropdownMenu";
import EmptyState from "Components/EmptyState";
import PageContent from "Components/PageContent";
import Competition from "Domain/Competitions/Competition";
import CompetitionReferee from "Domain/Competitions/CompetitionReferee";
import RefereesCreatorSelect from "DomainComponents/RefereesCreatorSelect";
import isPromise from "is-promise";
import { l } from "Languages";
import { Observer, useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useRunInTask from "Utils/Hooks/useRunInTask";

type CompetitionRefereesProps = {
    competition: Competition;
};

const CompetitionReferees: React.FunctionComponent<CompetitionRefereesProps> = ({ competition }) => {
    const [showRefeeresCreatorSelect, setShowRefeeresCreatorSelect] = useState(false);
    const [refereesCreationInProgress, runRefereesCreationInTask] = useRunInTask();

    const showRemoveRefereeConfirmation = useCallback(
        (phoneNumber: string) => {
            Modal.confirm({
                onOk: () => competition.removeReferee(phoneNumber),
                icon: <ExclamationCircleOutlined />,
                title: l("CompetitionDetails_Referees_Remove_Confirmation_Title"),
                content: l("CompetitionDetails_Referees_Remove_Confirmation_Content"),
                okText: l("Common_Remove"),
                okButtonProps: {
                    danger: true,
                },
                cancelText: l("Common_Cancel"),
                centered: true,
                maskClosable: true,
            });
        },
        [competition],
    );

    useEffect(() => {
        if (!competition.referees) {
            competition.fetchReferees();
        }
    }, [competition]);

    const saveReferees = useCallback(
        (referees: CompetitionReferee[]) =>
            runRefereesCreationInTask(async () => {
                const result = await competition.addReferees(referees);

                await Promise.all(
                    result
                        ?.handle(
                            [
                                "CompetitionNotFound",
                                "DuplicateRefereePhoneNumber",
                                "RefereeAlreadyExists",
                                "RefereeFirstNameNullOrEmpty",
                                "RefereeFirstNameTooLong",
                                "RefereeLastNameNullOrEmpty",
                                "RefereeLastNameTooLong",
                                "RefereePhoneNumberInvalidFormat",
                                "RefereePhoneNumberNullOrEmpty",
                                "RefereesNullOrEmpty",
                                "failure",
                            ],
                            () => {
                                message.error(l("CompetitionDetails_Referees_Add_Failure"));
                            },
                        )
                        .handle("success", async () => {
                            await competition.fetchReferees();

                            message.success(l("CompetitionDetails_Referees_Add_Success"));
                            setShowRefeeresCreatorSelect(false);
                        })
                        .check({
                            reducer: (prev, curr) => [...prev, ...(isPromise(curr) ? [curr] : [])],
                            initialValue: [] as Promise<void>[],
                        }),
                );
            }),
        [competition, runRefereesCreationInTask],
    );

    const columns = useMemo(
        (): ColumnProps<CompetitionReferee>[] => [
            {
                title: l("Common_Ordinal"),
                render: (_, referee, ind) => ind + 1,
            },
            {
                title: l("CompetitionDetails_Referees_LastName"),
                render: (_, referee) => referee.lastName,
            },
            {
                title: l("CompetitionDetails_Referees_FirstName"),
                render: (_, referee) => referee.firstName,
            },
            {
                title: l("CompetitionDetails_Referees_PhoneNumber"),
                render: (_, referee) => referee.phoneNumber,
            },
            {
                title: l("Common_Actions"),
                render: (_, referee) => (
                    <Observer>
                        {() => (
                            <DropdownMenu
                                menuItems={[
                                    {
                                        children: (
                                            <>
                                                <DeleteOutlined /> {l("CompetitionDetails_Referees_Remove")}
                                            </>
                                        ),
                                        onClick: () => showRemoveRefereeConfirmation(referee.phoneNumber),
                                    },
                                ]}
                            />
                        )}
                    </Observer>
                ),
            },
        ],
        [showRemoveRefereeConfirmation],
    );

    return useObserver(() => (
        <>
            <Skeleton loading={!competition.referees} active={!competition.referees}>
                <Table
                    dataSource={competition.referees}
                    columns={columns}
                    pagination={false}
                    locale={{
                        emptyText: <EmptyState text={l("CompetitionDetails_Referees_EmptyState")} />,
                    }}
                />
                {showRefeeresCreatorSelect && competition.referees ? (
                    <RefereesCreatorSelect
                        competitionId={competition.id}
                        competitionReferees={competition.referees}
                        onSave={saveReferees}
                        saveInProgress={refereesCreationInProgress}
                        onCancel={() => setShowRefeeresCreatorSelect(false)}
                    />
                ) : (
                    <PageContent.ActionButton onClick={() => setShowRefeeresCreatorSelect(true)}>
                        <PlusOutlined /> {l("CompetitionDetails_Referees_Add")}
                    </PageContent.ActionButton>
                )}
            </Skeleton>
        </>
    ));
};

export default CompetitionReferees;
