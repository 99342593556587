import { Skeleton } from "antd";
import PageContent from "Components/PageContent";
import homePageBannersConfiguration from "Domain/Banners";
import { l } from "Languages";
import React, { useEffect } from "react";
import useRunInTask from "Utils/Hooks/useRunInTask";
import LandingHomePageSkeleton from "./LandingHomePageSkeleton";

const BannersSection: React.FunctionComponent = () => {
    const [bannersFetchInProgress, runBannersFetchInTask] = useRunInTask();

    useEffect(() => {
        runBannersFetchInTask(() => homePageBannersConfiguration.fetch());
    }, [runBannersFetchInTask]);

    return (
        <PageContent.Card title={l("Landing_Banners")}>
            <Skeleton loading={bannersFetchInProgress} active={bannersFetchInProgress}>
                <LandingHomePageSkeleton />
            </Skeleton>
        </PageContent.Card>
    );
};

export default BannersSection;
