import Photo from "Components/Photo";
import RegistrationsBlobStorage from "Domain/Registrations/RegistrationsBlobStorage";
import React, { useCallback } from "react";

type RegistrationPhotoUploadProps = {
    registrationId: string;

    cropDisabled?: boolean;
    fullWidth?: boolean;

    value?: string;
    onChange?: (uri: string) => void;
};

const RegistrationPhotoUpload: React.FunctionComponent<RegistrationPhotoUploadProps> = ({
    registrationId,
    cropDisabled,
    fullWidth,
    value,
    onChange,
}) => {
    const uploadLogo = useCallback(
        async (file: File) => {
            const uri = await RegistrationsBlobStorage.uploadRegistrationPhoto(registrationId, file);

            if (uri) {
                onChange?.(uri);
            }
        },
        [onChange, registrationId],
    );

    return <Photo photo={value} onChange={uploadLogo} editable cropDisabled={cropDisabled} fullWidth={fullWidth} />;
};

export default RegistrationPhotoUpload;
