import { PageHeader, Skeleton } from "antd";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import PageContent from "Components/PageContent";
import competitionStore from "Domain/Competitions";
import competitionTeamStore from "Domain/Competitions/CompetitionTeamStore";
import seasonStore from "Domain/Season";
import UserProfilePicturesGallery from "DomainComponents/UserProfilePicturesGallery";
import { l } from "Languages";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import routes, { PageComponent } from "Router/routes";

const CompetitionTeamPlayerDetailsPage: PageComponent<typeof routes.competitionTeam.players.playerDetails> = observer(
    ({
        match: {
            params: { teamId, playerId },
        },
        history: { push },
    }) => {
        useEffect(() => {
            competitionStore.fetchCompetitionTeamPlayerWithPictures(teamId, playerId);
        }, [playerId, teamId]);

        const team = competitionTeamStore.getById(teamId);
        const competition = team && competitionStore.getById(team.competitionId);
        const season = competition?.seasonId ? seasonStore.getById(competition.seasonId) : undefined;
        const player = team?.players?.find(p => p.id === playerId);
        const playerPictures = player?.pictures;

        const breadcrumb: BreadcrumbProps["routes"] = useMemo(
            () =>
                team && competition && player
                    ? [
                          {
                              breadcrumbName: season ? season.name : l("Competitions_SeasonSelection_NoSeason"),
                              path: routes.competitions({ seasonId: competition.seasonId }),
                          },
                          {
                              breadcrumbName: competition.name,
                              path: routes.competitionDetails.teams({ competitionId: competition.id }),
                          },
                          {
                              breadcrumbName: team?.displayName,
                              path: routes.competitionTeam.players({ teamId: team.id }),
                          },
                          {
                              breadcrumbName: `${player.firstName} ${player.lastName}`,
                              path: routes.competitionTeam.players.playerDetails({
                                  teamId: team.id,
                                  playerId: player.id,
                              }),
                          },
                      ]
                    : [],
            [team, competition, player, season],
        );

        return (
            <PageContent>
                <PageContent.Header>
                    <PageContent.HeaderSkeleton active={!player} loading={!player}>
                        {player && team && (
                            <PageHeader
                                title={`${player.firstName} ${player.lastName}`}
                                onBack={() => push(routes.competitionTeam.players({ teamId: team.id }))}
                                breadcrumb={{
                                    routes: breadcrumb,
                                    itemRender: ({ path, breadcrumbName }) =>
                                        path === location.pathname ? (
                                            breadcrumbName
                                        ) : (
                                            <Link to={path}>{breadcrumbName}</Link>
                                        ),
                                }}
                                avatar={player.pictureUri ? { src: player.pictureUri, shape: "circle" } : undefined}
                            />
                        )}
                    </PageContent.HeaderSkeleton>
                </PageContent.Header>
                <PageContent.Card>
                    <Skeleton active={!playerPictures} loading={!playerPictures}>
                        {playerPictures && player && (
                            <UserProfilePicturesGallery
                                pictures={playerPictures}
                                selectedPictureUri={player.pictureUri}
                                onPictureUpload={file => player.addPicture(file)}
                                onSelectPicture={pictureUri => player.selectPicture(pictureUri)}
                            />
                        )}
                    </Skeleton>
                </PageContent.Card>
            </PageContent>
        );
    },
);

export default CompetitionTeamPlayerDetailsPage;
