import { DownOutlined, UpOutlined } from "@ant-design/icons";
import IconButton from "Components/IconButton";
import Spacing from "Components/Spacing";
import React from "react";

type ReorderButtonsProps = {
    isFirst: boolean;
    isLast: boolean;
    onMoveUp: () => void;
    onMoveDown: () => void;
};

const ReorderButtons: React.FunctionComponent<ReorderButtonsProps> = ({ isFirst, isLast, onMoveDown, onMoveUp }) => {
    return (
        <Spacing childrenGutterX>
            <IconButton disabled={isFirst} onClick={onMoveUp}>
                <UpOutlined />
            </IconButton>
            <IconButton disabled={isLast} onClick={onMoveDown}>
                <DownOutlined />
            </IconButton>
        </Spacing>
    );
};

export default ReorderButtons;
