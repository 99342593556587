import { message, PageHeader, Skeleton } from "antd";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import PageContent from "Components/PageContent";
import competitionPhaseStore from "Domain/CompetitionPhases";
import competitionStore from "Domain/Competitions";
import matchStore from "Domain/Matches";
import { formatTeamVsTeam } from "Domain/Matches/formatting";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import routes, { PageComponent } from "Router/routes";
import MatchArticleEditor, { ArticleEditorValidationErrors, FormFields } from "../MatchArticleEditor";

const EditMatchArticlePage: PageComponent<typeof routes.matchDetails.article.edit> = ({
    match: {
        params: { matchId, articleId },
    },
    history: { push },
}) => {
    useEffect(() => {
        competitionStore.fetchMatchDetails(matchId);
    }, [matchId]);

    const match = useObserver(() => matchStore.getById(matchId));
    const phase = useObserver(() => match && competitionPhaseStore.getById(match.phaseId));
    const competition = useObserver(() => phase && competitionStore.getById(phase.competitionId));

    useEffect(() => {
        if (match?.article) {
            match.fetchArticleDetails();
        }
    }, [match]);

    const breadcrumb: BreadcrumbProps["routes"] = useMemo(
        () =>
            phase && competition && match
                ? [
                      {
                          breadcrumbName: competition.name,
                          path: routes.competitionDetails.phases({ competitionId: competition.id }),
                      },
                      {
                          breadcrumbName: phase?.displayName,
                          path: routes.competitionPhase({ phaseId: phase?.id }),
                      },
                      {
                          breadcrumbName: formatTeamVsTeam(match),
                          path: routes.matchDetails({ matchId: matchId }),
                      },
                      {
                          breadcrumbName: l("MatchDetails_Article_Edit_Title"),
                          path: routes.matchDetails.article.edit({ matchId: matchId, articleId: articleId }),
                      },
                  ]
                : [],
        [competition, phase, match, matchId, articleId],
    );

    const onSaveArticle = useCallback(
        async (fields: FormFields): Promise<ArticleEditorValidationErrors | undefined> => {
            const result = await match?.editArticle(fields.content);

            return result
                ?.handle(
                    [
                        "ArticleNotFound",
                        "MatchNotFound",
                        "ArticleNotRelatedToMatch",
                        "OneOfTagsEmpty",
                        "OneOfTagsTooLong",
                        "DatePublishedInvalid",
                        "DatePublishedRequired",
                        "failure",
                    ],
                    () => {
                        message.error(l("MatchDetails_Article_Edit_Failure"));
                    },
                )
                .handle("ContentMissingOrEmpty", () => ({
                    content: l("Common_Validation_FieldRequired"),
                }))
                .handle(["SummaryTooLong", "SummaryMissingOrEmpty"], error => ({
                    summary:
                        error === "SummaryTooLong"
                            ? l("Common_Validation_FieldTooLong")
                            : l("Common_Validation_FieldRequired"),
                }))
                .handle("ThumbnailPhotoUriTooLong", () => ({
                    thumbnailPhotoUri: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["TitleTooLong", "TitleMissingOrEmpty"], error => ({
                    title:
                        error === "TitleTooLong"
                            ? l("Common_Validation_FieldTooLong")
                            : l("Common_Validation_FieldRequired"),
                }))
                .handle(["success"], () => {
                    message.success(l("MatchDetails_Article_Edit_Success"));

                    push(routes.matchDetails({ matchId: matchId }));
                })
                .check({
                    reducer: (prev, cur) => {
                        return {
                            ...prev,
                            ...(cur || {}),
                        };
                    },
                    initialValue: {} as ArticleEditorValidationErrors,
                });
        },
        [push, match, matchId],
    );

    const articleContentLoaded = match?.article?.content;

    return useObserver(() => (
        <PageContent>
            <PageContent.Header>
                <PageHeader
                    title={l("MatchDetails_Article_Edit_Title")}
                    onBack={() => push(routes.matchDetails({ matchId: matchId }))}
                    breadcrumb={{
                        routes: breadcrumb,
                        itemRender: ({ path, breadcrumbName }) =>
                            path === location.pathname ? breadcrumbName : <Link to={path}>{breadcrumbName}</Link>,
                    }}
                />
            </PageContent.Header>
            <PageContent.Card>
                <Skeleton loading={!articleContentLoaded} active={!articleContentLoaded}>
                    {match?.article?.content && (
                        <MatchArticleEditor
                            mode="edit"
                            initialValues={{ content: match.article.content }}
                            onSave={onSaveArticle}
                            onCancel={() => push(routes.matchDetails({ matchId: matchId }))}
                        />
                    )}
                </Skeleton>
            </PageContent.Card>
        </PageContent>
    ));
};

export default EditMatchArticlePage;
