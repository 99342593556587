import { MatchStatusDTO } from "Contracts/PlayooLeagueClient";
import CompetitionSportsField from "Domain/Competitions/CompetitionSportsField";
import { Match } from "Domain/Matches/Match";
import _ from "lodash";
import { action, computed, observable } from "mobx";
import moment from "moment";
import { FieldData } from "rc-field-form/lib/interface";
import guard from "Utils/guard";
import { notUndefined } from "Utils/predicates";
import SchedulePlanner from "../SchedulePlanner";
import SlotsIterator, { EmptySlot } from "../SlotsIterator";
import { AvailableSportsFieldsConfiguration } from "../SportsFieldConfiguration";

export type SlotsFillingFormFields = {
    team1Id?: string;
    team2Id?: string;
    sportsFieldId: string;
    date: moment.Moment;
};

export type SlotsFillingFormField = keyof SlotsFillingFormFields;

class SlotsFilling {
    private readonly schedulePlanner: SchedulePlanner;
    private readonly slotsIterator: SlotsIterator;
    sportsFields: CompetitionSportsField[];

    @observable currentSlot?: EmptySlot;

    @observable private formData: FieldData[];

    @observable team1Id?: string;
    @observable team2Id?: string;

    constructor(schedulePlanner: SchedulePlanner, sportsFields: CompetitionSportsField[]) {
        this.schedulePlanner = schedulePlanner;
        this.sportsFields = sportsFields;
        this.slotsIterator = new SlotsIterator(this.schedulePlanner.slotsConfiguration);

        this.moveToNextEmptySlot();

        this.formData = [
            {
                name: [guard<SlotsFillingFormField>("team1Id")],
                value: undefined,
            },
            {
                name: [guard<SlotsFillingFormField>("team2Id")],
                value: undefined,
            },
            {
                name: [guard<SlotsFillingFormField>("sportsFieldId")],
                value: this.currentSlot?.sportsFieldId,
            },
            {
                name: [guard<SlotsFillingFormField>("date")],
                value: this.currentSlot?.startTime,
            },
        ];
    }

    @computed get fields(): FieldData[] {
        return this.formData.map(field => ({
            ...field,
            value: (() => {
                const fieldName = field.name[0] as SlotsFillingFormField;

                switch (fieldName) {
                    case "date":
                        return this.currentSlot?.startTime;
                    case "sportsFieldId":
                        return this.currentSlot?.sportsFieldId;
                    default:
                        return this[fieldName];
                }
            })(),
        }));
    }

    @computed private get unassignedMatches() {
        return this.schedulePlanner.filteredUnassignedMatches.filter(m => m.status !== MatchStatusDTO.Canceled);
    }

    @action.bound
    private moveToNextEmptySlot() {
        this.currentSlot = this.slotsIterator.getNextSlot(
            this.schedulePlanner.currentMatchdayConfiguration,
            this.availableSportsFieldConfiguration,
        );
    }

    @computed get isFinished() {
        return this.unassignedMatches.length === 0;
    }

    @computed private get availableSportsFieldConfiguration(): AvailableSportsFieldsConfiguration {
        return new Map(this.sportsFields.map(sf => [sf.id, { closingTime: undefined }]));
    }

    @computed get selectableTeam1() {
        if (this.team2Id) {
            return this.getTeamsCompetingWith(this.team2Id);
        }

        return this.getAllTeamsCompetingInUnassignedMatches();
    }

    @computed get selectableTeam2() {
        if (this.team1Id) {
            return this.getTeamsCompetingWith(this.team1Id);
        }

        return this.getAllTeamsCompetingInUnassignedMatches();
    }

    private getAllTeamsCompetingInUnassignedMatches() {
        return _.uniqBy(this.unassignedMatches.flatMap(m => [m.team1, m.team2]).filter(notUndefined), t => t.id);
    }

    private getTeamsCompetingWith(teamId: string) {
        return _.uniqBy(
            this.unassignedMatches
                .filter(m => m.team1Id === teamId || m.team2Id === teamId)
                .map(m => (m.team1Id === teamId ? m.team2 : m.team1))
                .filter(notUndefined),
            t => t.id,
        );
    }

    @action.bound
    assignSlot() {
        if (!this.team1Id || !this.team2Id || !this.currentSlot) {
            throw new Error("Teams are not selected");
        }

        const match = this.unassignedMatches.find(
            m =>
                (m.team1Id === this.team1Id && m.team2Id === this.team2Id) ||
                (m.team1Id === this.team2Id && m.team2Id === this.team1Id),
        );

        if (!match) {
            throw new Error("Match with selected teams not found.");
        }

        this.assignMatchToSlot(match, this.currentSlot);

        this.team1Id = undefined;
        this.team2Id = undefined;

        this.moveToNextEmptySlot();
    }

    private assignMatchToSlot(match: Match, slot: EmptySlot) {
        const sportsFieldPlan = this.schedulePlanner.currentMatchdayConfiguration.sportsFieldsPlan.find(
            plan => plan.sportsField.id === slot.sportsFieldId,
        );

        if (!sportsFieldPlan) {
            throw new Error("Sports field not found.");
        }

        this.schedulePlanner.currentMatchdayConfiguration.moveSlot(match.id, {
            sportsFieldId: slot.sportsFieldId,
            index: sportsFieldPlan.slots.length,
        });
    }

    @action.bound
    onChange(changedFields: FieldData[], allFields: FieldData[]) {
        this.formData = allFields;

        changedFields.forEach(f => {
            const name = f.name[0] as SlotsFillingFormField;

            switch (name) {
                case "team1Id":
                    this.team1Id = f.value;
                    break;
                case "team2Id":
                    this.team2Id = f.value;
                    break;
            }
        });
    }
}

export default SlotsFilling;
