import mkCx, { rawClassName } from "@leancode/cx";
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import styles from "./styles.scss";

const cx = mkCx(styles);

type NavigationLinkProps = {
    type?: "link" | "button" | "button-primary" | "icon-button";
    external?: boolean;
} & LinkProps;

const NavigationLink: React.FunctionComponent<NavigationLinkProps> = ({ external, type, children, ...linkProps }) => {
    const className = cx(
        "navigation-link",
        (type === "button" || type === "button-primary" || type === "icon-button") && rawClassName("ant-btn"),
        type === "button-primary" && rawClassName("ant-btn-primary"),
        type === "icon-button" && [
            rawClassName("ant-btn-dashed"),
            rawClassName("ant-btn-circle"),
            rawClassName("ant-btn-icon-only"),
        ],
    );

    return external ? (
        <a href={linkProps.to.toString()} className={className} target="_blank" rel="noopener noreferrer">
            {children}
        </a>
    ) : (
        <Link className={className} {...linkProps}>
            {children}
        </Link>
    );
};

export default NavigationLink;
