import { PlusOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Divider, Select as AntdSelect } from "antd";
import { LabeledValue, SelectProps as AntdSelectProps, SelectValue } from "antd/lib/select";
import React from "react";
import styles from "./styles.scss";

const cx = mkCx(styles);

export type SelectProps = {
    onCreate?: () => void;
    createNewItemLabel?: string;
    selectOptions?: (LabeledValue & { searchField?: string; disabled?: boolean })[];

    additionalOptions?: { content: React.ReactNode; onClick: () => void }[];
} & AntdSelectProps<SelectValue>;

export const Select = React.forwardRef<AntdSelect, SelectProps>(
    ({ onCreate, createNewItemLabel, selectOptions, additionalOptions, children, ...selectProps }, ref) => {
        return (
            <AntdSelect
                ref={ref}
                optionFilterProp="children"
                dropdownRender={menu => (
                    <div>
                        {onCreate && (
                            <>
                                <div
                                    className={cx("additional-option")}
                                    onClick={onCreate}
                                    onMouseDown={e => e.preventDefault()}>
                                    <PlusOutlined />
                                    <div>{createNewItemLabel}</div>
                                </div>
                                <Divider className={cx("divider")} />
                            </>
                        )}
                        {menu}
                        {additionalOptions && (
                            <>
                                <Divider className={cx("divider")} />
                                {additionalOptions.map((o, ind) => (
                                    <div key={ind} onClick={o.onClick} className={cx("additional-option")}>
                                        {o.content}
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                )}
                {...selectProps}>
                {selectOptions?.map(o => (
                    <AntdSelect.Option key={o.key} value={o.value} searchField={o.searchField} disabled={o.disabled}>
                        {o.label}
                    </AntdSelect.Option>
                ))}
                {children}
            </AntdSelect>
        );
    },
);

export const SelectOption = AntdSelect.Option;
