import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    RollbackOutlined,
} from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Button, message, Modal, PageHeader, Skeleton, Tag, Typography } from "antd";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import DropdownMenu from "Components/DropdownMenu";
import NavigationLink from "Components/NavigationLink";
import PageContent from "Components/PageContent";
import Section from "Components/Section";
import Spacing from "Components/Spacing";
import { ArticleStateDTO, MatchWinnerDTO } from "Contracts/PlayooLeagueClient";
import competitionPhaseStore from "Domain/CompetitionPhases";
import { formatMatchInPhase } from "Domain/CompetitionPhases/formatting";
import KnockoutPhase from "Domain/CompetitionPhases/KnockoutPhase/KnockoutPhase";
import competitionStore from "Domain/Competitions";
import matchStore from "Domain/Matches";
import tenantSettingsStore from "Domain/Tenant";
import MatchEditionDialog from "DomainComponents/Matches/MatchEditionDialog";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import routes, { PageComponent } from "Router/routes";
import { formatDateWithTime } from "Utils/formatting";
import useRunInTask from "Utils/Hooks/useRunInTask";
import MatchReportDialog from "./MatchReportDialog";
import MatchTimeline from "./MatchTimeline";
import styles from "./styles.scss";
import TeamLineup from "./TeamLineup";

const cx = mkCx(styles);

const MatchDetailsPage: PageComponent<typeof routes.matchDetails> = ({
    match: {
        params: { matchId },
    },
    location,
    history: { push, replace },
}) => {
    const [detailsFetchInProgress, runDetailsFetchInTask] = useRunInTask();

    const [showMatchReportDialog, setShowMatchReportDialog] = useState(false);
    const [showMatchEditionDialog, setShowMatchEditionDialog] = useState(false);

    useEffect(() => {
        runDetailsFetchInTask(async () => {
            await competitionStore.fetchMatchDetails(matchId);

            if (!tenantSettingsStore.isFetched) {
                await tenantSettingsStore.fetchSettings();
            }
        });
    }, [matchId, runDetailsFetchInTask]);

    const match = useObserver(() => matchStore.getById(matchId));
    const phase = useObserver(() => match && competitionPhaseStore.getById(match.phaseId));
    const competition = useObserver(() => phase && competitionStore.getById(phase.competitionId));

    const breadcrumb: BreadcrumbProps["routes"] = useMemo(
        () =>
            phase && competition
                ? [
                      {
                          breadcrumbName: competition.name,
                          path: routes.competitionDetails.phases({ competitionId: competition.id }),
                      },
                      {
                          breadcrumbName: phase?.displayName,
                          path: routes.competitionPhase({ phaseId: phase?.id }),
                      },
                      {
                          breadcrumbName: l("MatchDetails_Title"),
                          path: routes.matchDetails({ matchId: matchId }),
                      },
                  ]
                : [],
        [competition, phase, matchId],
    );

    const recreateMatch = useCallback(async () => {
        if (!match || !phase) {
            return;
        }

        const result = await match?.recreate();

        result
            .handle(["MatchNotFound", "MatchTeamDeleted", "failure"], () =>
                message.error(l("MatchDetails_RecreateMatch_Failure")),
            )
            .handle("NextStageMatchNotInUpcomingState", () => {
                message.error(l("MatchDetails_RecreateMatch_Validation_NextStageMatchNotInUpcomingState"));
            })
            .handle("success", () => {
                message.success(l("MatchDetails_RecreateMatch_Success"));

                replace(routes.competitionPhase.schedule({ phaseId: phase?.id }));
            })
            .check();
    }, [match, phase, replace]);

    const showRecreateMatchConfirmation = useCallback(() => {
        Modal.confirm({
            onOk: recreateMatch,
            icon: <ExclamationCircleOutlined />,
            title: l("MatchDetails_RecreateMatch_Confirmation_Title"),
            content: l("MatchDetails_RecreateMatch_Confirmation_Content"),
            okText: l("MatchDetails_RecreateMatch_Confirmation_OkText"),
            okButtonProps: {
                danger: true,
            },
            cancelText: l("Common_Cancel"),
            centered: true,
            maskClosable: true,
        });
    }, [recreateMatch]);

    const deleteMatch = useCallback(async () => {
        if (!match || !phase || !match.isDeletable) {
            return;
        }

        const result = await phase.deleteMatch(match.id);

        result
            .handle(["MatchNotFound", "MatchIsKnockoutPhaseMatch", "failure"], () =>
                message.error(l("MatchDetails_DeleteMatch_Failure")),
            )
            .handle("success", () => {
                message.success(l("MatchDetails_DeleteMatch_Success"));

                replace(routes.competitionPhase.schedule({ phaseId: phase?.id }));
            })
            .check();
    }, [match, phase, replace]);

    const showDeleteMatchConfirmation = useCallback(() => {
        Modal.confirm({
            onOk: deleteMatch,
            icon: <ExclamationCircleOutlined />,
            title: l("MatchDetails_DeleteMatch_Confirmation_Title"),
            content: l("MatchDetails_DeleteMatch_Confirmation_Content"),
            okText: l("MatchDetails_DeleteMatch_Confirmation_OkText"),
            okButtonProps: {
                danger: true,
            },
            cancelText: l("Common_Cancel"),
            centered: true,
            maskClosable: true,
        });
    }, [deleteMatch]);

    const matchInPhaseDisplayName = match
        ? formatMatchInPhase(match, phase instanceof KnockoutPhase ? phase.isLegacyPhase : undefined)
        : undefined;

    const canRecreateMatch = useObserver(() => match?.canRecreateMatch);
    const canDeleteMatch = useObserver(() => match?.isDeletable);

    const pageHeaderDropdownMenuItems = useMemo(
        () => [
            {
                children: (
                    <>
                        <EditOutlined /> {l("MatchDetails_EditMatch")}
                    </>
                ),
                onClick: () => setShowMatchEditionDialog(true),
            },
            ...(canDeleteMatch
                ? [
                      {
                          children: (
                              <>
                                  <DeleteOutlined /> {l("MatchDetails_DeleteMatch")}
                              </>
                          ),
                          onClick: showDeleteMatchConfirmation,
                      },
                  ]
                : []),
        ],
        [showDeleteMatchConfirmation, canDeleteMatch],
    );

    const matchReportDropdownMenuItems = useMemo(
        () => [
            ...(canRecreateMatch
                ? [
                      {
                          children: (
                              <>
                                  <RollbackOutlined /> {l("MatchDetails_RecreateMatch")}
                              </>
                          ),
                          onClick: showRecreateMatchConfirmation,
                      },
                  ]
                : []),
        ],
        [showRecreateMatchConfirmation, canRecreateMatch],
    );

    const sportsFieldName = useMemo(() => competition?.sportsFields?.find(sf => sf.id === match?.sportsFieldId)?.name, [
        competition?.sportsFields,
        match?.sportsFieldId,
    ]);

    return useObserver(() => (
        <>
            <PageContent>
                <PageContent.Header>
                    <PageContent.HeaderSkeleton active={detailsFetchInProgress} loading={detailsFetchInProgress}>
                        {phase && (
                            <PageHeader
                                title={
                                    <Spacing childrenGutterX>
                                        <span>{l("MatchDetails_Title")}</span>
                                        {matchInPhaseDisplayName && <Tag>{matchInPhaseDisplayName}</Tag>}
                                        {match?.date && (
                                            <span className={cx("match-date")}>{formatDateWithTime(match.date)}</span>
                                        )}
                                        {sportsFieldName && <Tag>{sportsFieldName}</Tag>}
                                    </Spacing>
                                }
                                onBack={() => push(routes.competitionPhase({ phaseId: phase.id }))}
                                breadcrumb={{
                                    routes: breadcrumb,
                                    itemRender: ({ path, breadcrumbName }) =>
                                        path === location.pathname ? (
                                            breadcrumbName
                                        ) : (
                                            <Link to={path}>{breadcrumbName}</Link>
                                        ),
                                }}
                                extra={
                                    pageHeaderDropdownMenuItems.length > 0 && (
                                        <DropdownMenu menuItems={pageHeaderDropdownMenuItems} />
                                    )
                                }
                            />
                        )}
                    </PageContent.HeaderSkeleton>
                </PageContent.Header>
                <PageContent.Card>
                    <Skeleton loading={detailsFetchInProgress} active={detailsFetchInProgress}>
                        <Spacing childrenGutterY>
                            {match && (
                                <Section title={l("MatchDetails_TeamLineups")}>
                                    <Spacing childrenGutterY>
                                        {(match.result?.halfTimeScore || match.result?.penaltyScore) && (
                                            <div className={cx("additional-match-result-info")}>
                                                <Typography.Text strong type="secondary">
                                                    {match.result.halfTimeScore &&
                                                        l(
                                                            "MatchDetails_Result_HalfTimeScore",
                                                            match.result.halfTimeScore.team1,
                                                            match.result.halfTimeScore.team2,
                                                        )}{" "}
                                                    {match.result.penaltyScore &&
                                                        l(
                                                            "MatchDetails_Result_PenaltyScore",
                                                            match.result.penaltyScore.team1,
                                                            match.result.penaltyScore.team2,
                                                        )}
                                                </Typography.Text>
                                            </div>
                                        )}
                                        <div className={cx("team-lineups")}>
                                            {match.team1 && (
                                                <TeamLineup
                                                    teamName={match.team1.displayName}
                                                    lineup={match.team1Lineup}
                                                    teamMvpId={match.team1MvpId}
                                                    matchMvpId={match.matchMvpId}
                                                    teamResult={match.result?.fullTimeScore.team1}
                                                    isMatchWinner={match.result?.winner === MatchWinnerDTO.Team1}
                                                />
                                            )}
                                            {match.team2 && (
                                                <TeamLineup
                                                    teamName={match.team2.displayName}
                                                    lineup={match.team2Lineup}
                                                    teamMvpId={match.team2MvpId}
                                                    matchMvpId={match.matchMvpId}
                                                    teamResult={match.result?.fullTimeScore.team2}
                                                    isMatchWinner={match.result?.winner === MatchWinnerDTO.Team2}
                                                    reverse
                                                />
                                            )}
                                        </div>
                                    </Spacing>
                                </Section>
                            )}
                            {(match?.canAddReport || match?.canEditReport) && (
                                <Section
                                    title={
                                        <div className={cx("match-events-section-title")}>
                                            <Spacing childrenGutterX>
                                                <span>{l("MatchDetails_MatchEvents")}</span>
                                                {match?.canEditReport && (
                                                    <Button
                                                        onClick={() => setShowMatchReportDialog(true)}
                                                        className={cx("show-match-report-dialog-button")}>
                                                        <EditOutlined /> {l("MatchDetails_EditMatchReport")}
                                                    </Button>
                                                )}
                                                {match?.canAddReport && (
                                                    <Button
                                                        type="primary"
                                                        onClick={() => setShowMatchReportDialog(true)}
                                                        className={cx("show-match-report-dialog-button")}>
                                                        <EditOutlined /> {l("MatchDetails_PublishMatchReport")}
                                                    </Button>
                                                )}
                                            </Spacing>
                                            {matchReportDropdownMenuItems.length > 0 && (
                                                <DropdownMenu menuItems={matchReportDropdownMenuItems} />
                                            )}
                                        </div>
                                    }>
                                    {match?.isMatchReportPublished && <MatchTimeline match={match} />}
                                </Section>
                            )}
                            <Section title={l("MatchDetails_Article")} className={cx("article-section")}>
                                <div>
                                    {match?.article?.id === undefined ? (
                                        <NavigationLink
                                            type="button-primary"
                                            to={routes.matchDetails.article.create({ matchId: matchId })}>
                                            {l("MatchDetails_Article_CreateNew")}
                                        </NavigationLink>
                                    ) : (
                                        <Spacing childrenGutterX>
                                            {match.article.state === ArticleStateDTO.Draft ? (
                                                <Button type="primary" onClick={() => match.article?.publish()}>
                                                    {l("MatchDetails_Article_Publish")}
                                                </Button>
                                            ) : (
                                                <Button onClick={() => match.article?.unpublish()}>
                                                    {l("MatchDetails_Article_Unpublish")}
                                                </Button>
                                            )}
                                            <NavigationLink
                                                type="button"
                                                to={routes.matchDetails.article.edit({
                                                    matchId: match.id,
                                                    articleId: match.article.id,
                                                })}>
                                                <EditOutlined /> {l("MatchDetails_Article_Edit")}
                                            </NavigationLink>
                                            {match.article?.previewLink && (
                                                <NavigationLink type="button" to={match.article.previewLink} external>
                                                    <EyeOutlined /> {l("MatchDetails_Article_Preview")}
                                                </NavigationLink>
                                            )}
                                        </Spacing>
                                    )}
                                </div>
                            </Section>
                            {match && (
                                <Section title={l("MatchDetails_Photos")}>
                                    <div>
                                        <NavigationLink
                                            type="button-primary"
                                            to={routes.matchDetails.gallery({ matchId: match.id })}>
                                            {l("MatchDetails_Photos_Edit")}
                                        </NavigationLink>
                                    </div>
                                </Section>
                            )}
                        </Spacing>
                    </Skeleton>
                </PageContent.Card>
            </PageContent>
            {match && showMatchReportDialog && (
                <MatchReportDialog match={match} onClose={() => setShowMatchReportDialog(false)} />
            )}
            {showMatchEditionDialog && match && phase && (
                <MatchEditionDialog
                    competition={competition}
                    match={match}
                    phase={phase}
                    sportsFields={competition?.sportsFields}
                    onClose={() => setShowMatchEditionDialog(false)}
                />
            )}
        </>
    ));
};

export default MatchDetailsPage;
