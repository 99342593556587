import React, { useEffect } from "react";

type ActiveTabKeySetterProps = {
    setKey: () => void;
};

export const ActiveTabKeySetter: React.FunctionComponent<ActiveTabKeySetterProps> = ({ setKey }) => {
    useEffect(setKey, [setKey]);

    return null;
};
