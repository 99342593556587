import { handleResponse } from "@leancode/validation";
import { CancelInvitation, InvitationSummaryDTO } from "Contracts/PlayooLeagueClient";
import api from "Services/Api";

class Invitation {
    readonly id: string;
    readonly phoneNumber: string;

    constructor(id: string, phoneNumber: string) {
        this.id = id;
        this.phoneNumber = phoneNumber;
    }

    static fromDTO(dto: InvitationSummaryDTO): Invitation {
        return new Invitation(dto.Id, dto.PhoneNumber);
    }

    async cancel() {
        const response = await api.cancelInvitation({
            InvitationId: this.id,
        });

        return handleResponse(response, CancelInvitation);
    }
}

export default Invitation;
