import { handleResponse } from "@leancode/validation";
import { MatchSlotDTO, MatchStatusDTO, PlanPhaseSchedule } from "Contracts/PlayooLeagueClient";
import Competition from "Domain/Competitions/Competition";
import CompetitionSportsField from "Domain/Competitions/CompetitionSportsField";
import { Match } from "Domain/Matches/Match";
import { l } from "Languages";
import { action, computed, observable } from "mobx";
import moment from "moment";
import api from "Services/Api";
import { dateTimeOffsetToDTOOptional } from "Utils/DTO";
import newId from "Utils/newId";
import { notUndefined } from "Utils/predicates";
import { CompetitionPhase } from "../CompetitionPhase";
import PlannerFilter from "./Filters/PlannerFilter";
import MatchdayConfiguration, { SportsFieldMatchesPlan } from "./MatchdayConfiguration";
import { isMatch, Slot } from "./Slot";
import SlotsConfiguration from "./SlotsConfiguration";
import { getMatchdays, withBreaksBetweenTooDistantMatches } from "./common";

export enum SchedulePlannerValidationErrors {
    ScheduleNotPlannedForAnySportsField,
    MatchDatesNotGenerated,
}
class SchedulePlanner {
    static readonly defaultMatchDurationInMinutes = 12;
    static readonly defaultPauseBetweenMatchesInMinutes = 3;

    private readonly competition: Competition;
    private readonly phase: CompetitionPhase;

    @observable filter?: PlannerFilter;

    @observable sportsFields: CompetitionSportsField[];

    @observable slotsConfiguration: SlotsConfiguration;

    @observable private matchdaysConfiguration: MatchdayConfiguration[];

    @observable currentMatchdayConfiguration: MatchdayConfiguration;

    constructor(competition: Competition, phase: CompetitionPhase, filter?: PlannerFilter) {
        this.phase = phase;
        this.competition = competition;
        this.filter = filter;

        if (!this.phase.schedule) {
            throw new Error("Phase schedule not available or not generated.");
        }

        const schedule = this.phase.schedule as Match[];

        const matchDurationInMinutes =
            this.phase.defaultMatchDurationInMinutes ?? SchedulePlanner.defaultMatchDurationInMinutes;
        const pauseBetweenMatchesInMinutes =
            this.phase.defaultPauseBetweenMatchesInMinutes ?? SchedulePlanner.defaultPauseBetweenMatchesInMinutes;

        this.slotsConfiguration = new SlotsConfiguration({
            matchDurationInMinutes,
            pauseBetweenMatchesInMinutes,
        });

        const defaultSportsFields = [new CompetitionSportsField(newId(), this.getDefaultSportsFieldName(0))];

        this.sportsFields =
            competition.sportsFields && competition.sportsFields.length > 0
                ? competition.sportsFields.map(sf => sf.clone())
                : defaultSportsFields;

        const assignedMatches = schedule.filter(m => !!m.sportsFieldId && !!m.date);

        const matchdays = getMatchdays(assignedMatches);

        this.matchdaysConfiguration = matchdays.map(matchday => {
            const matchdaySchedule = assignedMatches.filter(m => matchday.isSame(m.date, "day"));

            const earliestMatchInMatchday = matchdaySchedule[0];

            const matchesPlan: SportsFieldMatchesPlan[] = this.sportsFields.map(sf => {
                const matchesOnSportsField = matchdaySchedule.filter(m => m.sportsFieldId === sf.id);

                return {
                    sportsField: sf,
                    slots: this.withBreaksBetweenTooDistantMatches(matchesOnSportsField),
                    startTime: matchesOnSportsField[0]?.date ?? earliestMatchInMatchday?.date,
                };
            });

            return new MatchdayConfiguration(matchday, matchesPlan, this.slotsConfiguration, schedule);
        });

        if (this.matchdaysConfiguration.length === 0) {
            this.matchdaysConfiguration = [
                new MatchdayConfiguration(
                    undefined,
                    this.sportsFields.map(sf => ({
                        sportsField: sf,
                        slots: [],
                        startTime: undefined,
                    })),
                    this.slotsConfiguration,
                    schedule,
                ),
            ];
        }

        this.currentMatchdayConfiguration = this.matchdaysConfiguration[0];
    }

    private withBreaksBetweenTooDistantMatches(matches: Match[]): Slot[] {
        return withBreaksBetweenTooDistantMatches(matches, this.slotsConfiguration.distanceBetweenMatchesInMinutes);
    }

    @computed get matchdays(): moment.Moment[] {
        return this.matchdaysConfiguration.map(mc => mc.day).filter(notUndefined);
    }

    @computed get validationError(): SchedulePlannerValidationErrors | undefined {
        if (!this.slotsConfiguration.isConfigured || !this.matchdaysConfiguration[0].day) {
            return SchedulePlannerValidationErrors.MatchDatesNotGenerated;
        }

        if (this.assignedMatchesIds.size === 0) {
            return SchedulePlannerValidationErrors.ScheduleNotPlannedForAnySportsField;
        }
    }

    @computed get canInferMatchDates() {
        return this.slotsConfiguration.isConfigured && !!this.currentMatchdayConfiguration.day;
    }

    @computed get canPlanCurrentMatchdayAutomatically() {
        return this.canInferMatchDates && this.filteredUnassignedMatches.length > 0;
    }

    @computed get hasAnyNonCancelledUnassignedMatches() {
        return this.filteredUnassignedMatches.some(m => m.status !== MatchStatusDTO.Canceled);
    }

    @computed get assignedMatchesIds(): Set<string> {
        return new Set(this.matchdaysConfiguration.flatMap(mc => mc.assignedMatchesIds));
    }

    @computed get unassignedMatches(): Match[] {
        return (this.phase.schedule as Match[])?.filter(t => !this.assignedMatchesIds.has(t.id)) ?? [];
    }

    @computed get filteredUnassignedMatches(): Match[] {
        return this.filter ? this.filter.apply(this.unassignedMatches) : this.unassignedMatches;
    }

    @action.bound
    setCurrentMatchday(matchday: moment.Moment) {
        const matchdayConfiguration = this.matchdaysConfiguration.find(mc => mc.day && matchday.isSame(mc.day, "day"));

        if (matchdayConfiguration) {
            this.currentMatchdayConfiguration = matchdayConfiguration;
        }
    }

    private getDefaultSportsFieldName(index: number) {
        const charCodeForCapitalA = 65;

        return l(
            "CompetitionPhases_PlanSchedule_DefaultSportsFieldName",
            String.fromCharCode(charCodeForCapitalA + index),
        );
    }

    @action.bound
    addSportsField() {
        const sportsField = new CompetitionSportsField(
            newId(),
            this.getDefaultSportsFieldName(this.sportsFields.length),
        );

        this.sportsFields.push(sportsField);

        this.matchdaysConfiguration.forEach(m => m.addSportsField(sportsField));
    }

    @action.bound
    addMatchday(matchday: moment.Moment) {
        const matchdayConfiguration = new MatchdayConfiguration(
            matchday,
            this.sportsFields.map(sf => ({
                sportsField: sf,
                startTime: moment(matchday),
                slots: [],
            })),
            this.slotsConfiguration,
            this.phase.schedule as Match[],
        );

        this.matchdaysConfiguration = [...this.matchdaysConfiguration, matchdayConfiguration].sort(
            (a, b) => a.day?.diff(b.day) ?? -1,
        );

        this.currentMatchdayConfiguration = matchdayConfiguration;
    }

    async save() {
        if (
            this.slotsConfiguration.matchDurationInMinutes === undefined ||
            this.slotsConfiguration.pauseBetweenMatchesInMinutes === undefined
        ) {
            throw new Error("Schedule planner is not configured.");
        }

        const sportsFieldsSaveHandler = await this.competition.setSportsFields(this.sportsFields);

        let wasSettingSportsFieldsSuccessful = false;

        const sportsFieldsSaveHandlerWithSuccessHandled = sportsFieldsSaveHandler.handle("success", () => {
            wasSettingSportsFieldsSuccessful = true;
        });

        if (wasSettingSportsFieldsSuccessful) {
            const response = await api.planPhaseSchedule({
                CompetitionId: this.competition.id,
                PhaseId: this.phase.id,
                DefaultMatchDurationInMinutes: this.slotsConfiguration.matchDurationInMinutes,
                DefaultPauseBetweenMatchesInMinutes: this.slotsConfiguration.pauseBetweenMatchesInMinutes,
                Schedule: [
                    ...this.unassignedMatches.map<MatchSlotDTO>(m => ({
                        MatchId: m.id,
                        Date: undefined,
                        SportsFieldId: undefined,
                    })),
                    ...this.matchdaysConfiguration.flatMap(mc =>
                        mc.schedule.flatMap(sportsField =>
                            sportsField.slots
                                .filter(s => isMatch(s.slot))
                                .map<MatchSlotDTO>(ps => ({
                                    MatchId: ps.slot.id,
                                    SportsFieldId: sportsField.sportsField.id,
                                    Date: dateTimeOffsetToDTOOptional(ps.startTime),
                                })),
                        ),
                    ),
                ],
            });

            const schedulePlanSaveHandler = handleResponse(response, PlanPhaseSchedule);

            return { sportsFieldsSaveHandler: sportsFieldsSaveHandlerWithSuccessHandled, schedulePlanSaveHandler };
        } else {
            return { sportsFieldsSaveHandler: sportsFieldsSaveHandlerWithSuccessHandled };
        }
    }
}

export default SchedulePlanner;
