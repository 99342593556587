import { PhaseTypeDTO } from "Contracts/PlayooLeagueClient";
import GroupsPhase from "Domain/CompetitionPhases/GroupsPhase/GroupsPhase";
import TeamGroup from "Domain/CompetitionPhases/GroupsPhase/TeamGroup";
import { Match } from "Domain/Matches/Match";
import { action, computed, observable } from "mobx";
import PlannerFilter from "./PlannerFilter";

class GroupsPhasePlannerFilter implements PlannerFilter {
    @observable private groupIds: Set<string>;
    @observable groups: TeamGroup[];

    constructor(phase: GroupsPhase) {
        if (!phase.groups) {
            throw new Error("Groups phase has no groups fetched.");
        }

        this.groups = phase.groups;
        this.groupIds = new Set(phase.groups.map(g => g.id));
    }

    @computed get selectedGroupIds() {
        return [...this.groupIds];
    }

    @computed get isFiltered() {
        return this.groupIds.size !== this.groups.length;
    }

    @action.bound
    setGroups(groupIds: Set<string>) {
        this.groupIds = groupIds;
    }

    apply(matches: Match[]) {
        return matches.filter(m => m.phaseType === PhaseTypeDTO.Groups && this.groupIds.has(m.groupId));
    }
}

export default GroupsPhasePlannerFilter;
