import { handleResponse } from "@leancode/validation";
import { BannerDTO, BannerTypeDTO, EditBanner } from "Contracts/CmsClient";
import { observable, runInAction } from "mobx";
import blobStorageClient from "Services/BlobStorageClient";
import cmsApi from "Services/CmsApi";
import extractExtension from "Utils/extractExtension";
import newId from "Utils/newId";
import retryQuery from "Utils/retryQuery";

class Banner {
    readonly id: string;
    readonly type: BannerTypeDTO;

    @observable pictureUri: string;
    @observable linkUri?: string;

    constructor(id: string, type: BannerTypeDTO, { pictureUri, linkUri }: BannerInit) {
        this.id = id;
        this.type = type;
        this.pictureUri = pictureUri;
        this.linkUri = linkUri;
    }

    static fromDTO(dto: BannerDTO) {
        return new Banner(dto.Id, dto.Type, {
            pictureUri: dto.PictureUri,
            linkUri: dto.LinkUri ?? undefined,
        });
    }

    static async uploadPicture(bannerId: string, file: File) {
        const extension = extractExtension(file);
        const blobName = `${bannerId}/${newId()}.${extension}`;

        const token = await retryQuery(() => cmsApi.bannersCreateToken({}));

        await blobStorageClient.uploadBlob(blobName, file, token);

        return blobStorageClient.blobUriFromToken(token, blobName);
    }

    async edit(picture: string | File, linkUri?: string) {
        let pictureUri: string;

        if (typeof picture === "string") {
            pictureUri = picture;
        } else {
            pictureUri = await Banner.uploadPicture(this.id, picture);
        }

        const response = await cmsApi.editBanner({
            BannerId: this.id,
            PictureUri: pictureUri,
            LinkUri: linkUri,
        });

        const handler = handleResponse(response, EditBanner);

        handler.handle("success", () => {
            runInAction(() => {
                this.pictureUri = pictureUri;
                this.linkUri = linkUri;
            });
        });

        return handler;
    }
}

type BannerInit = Pick<Banner, "pictureUri" | "linkUri">;

export default Banner;
