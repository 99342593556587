import { PlusOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { DatePicker } from "antd";
import { Select } from "Components/Select";
import { l } from "Languages";
import moment from "moment";
import React from "react";
import { dateWithTimeFormat, formatDate, timeFormat } from "Utils/formatting";
import styles from "./styles.scss";

const cx = mkCx(styles);

type MatchdaySelectProps = {
    value?: moment.Moment;
    matchdays: moment.Moment[];

    onChange?: (date: moment.Moment) => void;
    onSetInitialMatchdayDate: (date: moment.Moment) => void;
    onAddMatchday: () => void;
};

const MatchdaySelect: React.FunctionComponent<MatchdaySelectProps> = ({
    value,
    matchdays,
    onChange,
    onAddMatchday,
    onSetInitialMatchdayDate,
}) => {
    if (matchdays.length === 0) {
        return (
            <DatePicker
                dropdownClassName={cx("date-picker-dropdown")}
                showTime={{ format: timeFormat }}
                format={dateWithTimeFormat}
                onChange={date => date && onSetInitialMatchdayDate(date)}
            />
        );
    } else {
        return (
            <Select
                className={cx("select")}
                value={value ? value.toISOString() : undefined}
                onChange={(date: string) => onChange?.(moment(date))}
                selectOptions={matchdays.map(m => ({
                    value: m.toISOString(),
                    key: m.toISOString(),
                    label: formatDate(m),
                }))}
                additionalOptions={[
                    {
                        content: (
                            <>
                                <PlusOutlined /> {l("CompetitionPhases_PlanSchedule_AddMatchday")}
                            </>
                        ),
                        onClick: onAddMatchday,
                    },
                ]}
            />
        );
    }
};

export default MatchdaySelect;
