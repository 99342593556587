import { FunctionComponent } from "react";
import { RouteComponentProps } from "react-router";
import createRouting, { guid, query, segment } from "Utils/createRouting";

const routes = createRouting({
    index: segment`/`,
    articles: {
        ...segment`/articles`,
        children: { create: segment`/create`, edit: segment`/edit/${guid("articleId")}` },
    },
    competitions: segment`/competitions/${guid("seasonId", "?")}`,
    competitionDetails: {
        ...segment`/competition/${guid("competitionId")}`,
        children: {
            teams: segment`/teams`,
            phases: segment`/phases`,
            referees: segment`/referees`,
            settings: segment`/settings`,
        },
    },
    competitionPhase: {
        ...segment`/phase/${guid("phaseId")}`,
        children: {
            schedule: segment`/schedule`,
            teams: segment`/teams`,
            groups: segment`/groups`,
            matchGroups: segment`/match-groups`,
            tablePointsModifiers: segment`/points`,
            configureKnockoutPhaseFirstStage: segment`/configure-knockout`,
            planSchedule: segment`/plan-schedule`,
        },
    },
    competitionTeam: {
        ...segment`/team/${guid("teamId")}`,
        children: {
            players: {
                ...segment`/players`,
                children: {
                    playerDetails: segment`/${guid("playerId")}`,
                },
            },
            admins: segment`/admins`,
            photo: segment`/photo`,
        },
    },
    seasons: {
        ...segment`/seasons`,
        children: {
            planSchedule: segment`/plan-schedule/${guid("seasonId")}`,
        },
    },
    resetPassword: segment`/reset-password/${query({ token: true, userId: true, email: true })}`,
    recoverPassword: segment`/password-recovery`,
    accessCode: segment`/access-code`,
    competitionGroups: segment`/competition-groups`,
    matchDetails: {
        ...segment`/match/${guid("matchId")}`,
        children: {
            article: {
                ...segment`/article`,
                children: {
                    create: segment`/create`,
                    edit: segment`/${guid("articleId")}`,
                },
            },
            gallery: segment`/gallery`,
        },
    },
    tenant: segment`/tenant`,
    signUp: {
        ...segment`/sign-up`,
        children: {
            form: segment`/form`,
            tenantExists: segment`/tenant-exists`,
            success: segment`/success`,
        },
    },
    registrations: {
        ...segment`/registrations`,
        children: {
            create: segment`/create`,
            edit: segment`/edit/${guid("registrationId")}`,
            details: segment`/${guid("registrationId")}`,
        },
    },
    videos: segment`/videos`,
    landing: segment`/landing`,
} as const);

export default routes;

export type RouteParamsFor<T extends (...args: any[]) => string> = Parameters<T>[0] extends undefined
    ? {}
    : NonNullable<Parameters<T>[0]>;

export type PageProps<TPathParams extends (...args: any[]) => string> = RouteComponentProps<
    RouteParamsFor<TPathParams>
>;

export type PageComponent<TPathParams extends (...args: any[]) => string> = FunctionComponent<PageProps<TPathParams>>;
