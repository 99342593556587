import { action, computed, observable } from "mobx";

/**
 * An identifiable entity.
 */
export interface Ident<TId = string> {
    readonly id: TId;
}

/**
 * Extracts `TId` from `Ident<TId>`
 */
export type IdentOf<TIdentifiable extends Ident<unknown>> = TIdentifiable extends Ident<infer I> ? I : never;

/**
 * Due to the need for accessing deeply nested domain entities, I introduce a concept of generalized references.
 * They can be queried for their actual domain entity's value (e.g. a `Course` instance) or just its identifier.
 * While the `Course` (or other entity) might not be fetched, dereferencing it might result in an `undefined`.
 * Any entity that can be referenced has to have an ID (it's its database guid for all entities in this project)
 * and it always has to be specified.
 *
 * Treat this kind of object as a level-upped `courseId`/`moduleId` or an `id + store` combo that gets you the entity
 * provided you remembered to fetch it in the first place.
 */
export interface ItemRef<TItem extends Ident<IdentOf<TItem>>> extends Ident<IdentOf<TItem>> {
    readonly current: TItem | undefined;
}

/**
 * A reference to an item that can be queried for its value or identifier. @see ItemRef<TItem>
 */
export interface AlwaysDefinedItemRef<TItem extends Ident<IdentOf<TItem>>> extends ItemRef<TItem> {
    readonly current: TItem;
}

/**
 * A commonplace item reference implementation for items stored in a mobx store
 */
export class StoredItemRef<TItem extends Ident<IdentOf<TItem>>> implements ItemRef<TItem> {
    readonly id: IdentOf<TItem>;
    readonly store: ReadonlyCollectionStore<TItem>;

    constructor(id: IdentOf<TItem>, store: ReadonlyCollectionStore<TItem>) {
        this.id = id;
        this.store = store;
    }

    @computed get current(): TItem | undefined {
        return this.store.getById(this.id);
    }
}

/**
 * An item reference that can be created just from the item alone
 */
export class SelfRef<TItem extends Ident<IdentOf<TItem>>> implements AlwaysDefinedItemRef<TItem> {
    readonly id: IdentOf<TItem>;
    readonly current: TItem;

    constructor(item: TItem) {
        this.id = item.id as IdentOf<TItem>;
        this.current = item;
    }
}

/**
 * A store containing uniquely identifiable items.
 */
export interface ReadonlyCollectionStore<TItem extends Ident<IdentOf<TItem>>> {
    getById(id: IdentOf<TItem>): TItem | undefined;

    getItemRef(id: IdentOf<TItem>): ItemRef<TItem>;
}

/**
 * A store containing uniquely identifiable items that can also be updated/overridden.
 */
export interface CollectionStore<TItem extends Ident<IdentOf<TItem>>> extends ReadonlyCollectionStore<TItem> {
    set(items: TItem[]): TItem[];
    clear(): this;
    remove(item: TItem): this;
}

/**
 * A generic mobx store implementation for items stored in a map
 */
export class MapStore<TItem extends Ident<IdentOf<TItem>>> implements CollectionStore<TItem> {
    @observable items: Map<IdentOf<TItem>, TItem> = new Map();

    getById(id: IdentOf<TItem>): TItem | undefined {
        return this.items.get(id);
    }

    @action.bound
    put(...items: TItem[]) {
        for (const item of items) {
            this.items.set(item.id, item);
        }
        return items;
    }

    @action.bound
    set(items: TItem[]) {
        this.items = new Map(items.map(item => [item.id, item]));
        return items;
    }

    @action.bound
    clear() {
        this.items.clear();
        return this;
    }

    @action.bound
    remove(item: TItem) {
        this.items.delete(item.id);
        return this;
    }

    getItemRef(id: IdentOf<TItem>): StoredItemRef<TItem> {
        return new StoredItemRef(id, this);
    }
}

/**
 * A generic mobx store implementation for items stored in an array
 */
export class ArrayStore<TItem extends Ident<IdentOf<TItem>>> implements CollectionStore<TItem> {
    @observable private rawItems = observable.array<TItem>();

    @computed get items() {
        return [...this.rawItems.values()];
    }

    getById(id: IdentOf<TItem>) {
        return this.rawItems.find(item => item.id === id);
    }

    @action.bound
    add(...items: TItem[]) {
        this.rawItems.push(...items);
    }

    @action.bound
    set(items: TItem[]) {
        return this.rawItems.replace(items);
    }

    @action.bound
    clear() {
        this.rawItems.clear();
        return this;
    }

    @action.bound
    remove(item: TItem) {
        this.rawItems.remove(item);

        return this;
    }

    getItemRef(id: IdentOf<TItem>): StoredItemRef<TItem> {
        return new StoredItemRef(id, this);
    }
}

/**
 * This is an empty ref for anything that can be used for placeholders. It will throw when asked for `id` and always return `undefined` when dereferencing.
 */
export class EmptyRef implements ItemRef<any> {
    get id(): any {
        throw new Error("This is an empty ref!");
    }
    readonly current = undefined;

    static instance = new EmptyRef();

    protected constructor() {}
}
