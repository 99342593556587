import { Form, Input, message, Spin } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import tenantSettingsStore from "Domain/Tenant";
import { l } from "Languages";
import React, { useCallback } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";

type AccessCodeFormDialogProps = {
    accessCode?: string;

    onClose?: () => void;
};

const AccessCodeFormDialog: React.FunctionComponent<AccessCodeFormDialogProps> = ({ accessCode, onClose }) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const setAccessCodeError = useCallback(
        (value: FormFields["accessCode"], error: string) =>
            form.setFields([
                {
                    name: guard<FormField>("accessCode"),
                    errors: [error],
                    value: value,
                },
            ]),
        [form],
    );

    const onFinish = useCallback(
        (values: FormFields) => {
            runInTask(async () => {
                const result = await tenantSettingsStore.changeAccessCode(values.accessCode);

                result
                    .handle(["NewAccessCodeTooLong"], () =>
                        setAccessCodeError(values.accessCode, l("Common_Validation_FieldTooLong")),
                    )
                    .handle(["NewAccessCodeAlreadyInUse"], () =>
                        setAccessCodeError(values.accessCode, l("AccessCodes_Form_AccessCode_AlreadyInUse")),
                    )
                    .handle("failure", () =>
                        message.error(accessCode ? l("AccessCodes_Change_Failure") : l("AccessCodes_AddNew_Failure")),
                    )
                    .handle(["success"], () => {
                        message.success(accessCode ? l("AccessCodes_Change_Success") : l("AccessCodes_AddNew_Success"));
                        onClose?.();
                    })
                    .check();
            });
        },
        [onClose, runInTask, accessCode, setAccessCodeError],
    );

    return (
        <Modal
            title={accessCode ? l("AccessCodes_Form_Edit_Title") : l("AccessCodes_Form_Create_Title")}
            onCancel={onClose}
            okText={accessCode ? l("Common_Save") : l("Common_Add")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}>
            <Spin spinning={isRunning}>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        accessCode: accessCode,
                    }}>
                    <Form.Item
                        label={l("AccessCodes_Form_AccessCode")}
                        name={guard<FormField>("accessCode")}
                        rules={[{ max: 64, message: l("Common_Validation_FieldTooLong") }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

export type FormFields = {
    accessCode?: string;
};

type FormField = keyof FormFields;

export default AccessCodeFormDialog;
