import Photo from "Components/Photo";
import React, { useCallback } from "react";
import imageFileToBase64 from "Utils/imageFileToBase64";

export type UploadedOrInlinePhoto =
    | string
    | {
          file: File;
          asBase64: string;
      };

type DefferedPhotoUploadProps = {
    value?: UploadedOrInlinePhoto;
    onChange?: (value?: UploadedOrInlinePhoto) => void;
};

const DefferedPhotoUpload: React.FunctionComponent<DefferedPhotoUploadProps> = ({ value, onChange }) => {
    const onPhotoChange = useCallback(
        async (file: File) => {
            const asBase64 = await imageFileToBase64(file);

            if (asBase64) {
                onChange?.({
                    file: file,
                    asBase64: asBase64,
                });
            }
        },
        [onChange],
    );

    return (
        <Photo
            photo={typeof value === "string" ? value : value?.asBase64}
            onChange={onPhotoChange}
            editable
            cropDisabled
            fullWidth
        />
    );
};

export default DefferedPhotoUpload;
