import { Match } from "Domain/Matches/Match";
import Break from "./Break";
import { Slot, isBreak } from "./Slot";
import { notUndefined } from "Utils/predicates";
import moment from "moment";

export function withBreaksBetweenTooDistantMatches(matches: Match[], distanceBetweenMatchesInMinutes?: number): Slot[] {
    return matches.reduce((acc, match) => {
        const previousSlot = acc[acc.length - 1];

        if (
            !previousSlot ||
            isBreak(previousSlot) ||
            !previousSlot.date ||
            !match.date ||
            distanceBetweenMatchesInMinutes === undefined
        ) {
            return [...acc, match];
        }

        const differenceBetweenAdjacentMatchesInMinutes = match.date.diff(previousSlot.date, "minutes");

        if (differenceBetweenAdjacentMatchesInMinutes > distanceBetweenMatchesInMinutes) {
            return [
                ...acc,
                new Break(differenceBetweenAdjacentMatchesInMinutes - distanceBetweenMatchesInMinutes),
                match,
            ];
        }

        return [...acc, match];
    }, [] as Slot[]);
}

export function getMatchdays(matches: Match[]) {
    return Array.from(
        new Set(
            matches.map(m => (m.date ? moment(m.date).startOf("day").toISOString() : undefined)).filter(notUndefined),
        ),
    )
        .map(d => moment(d))
        .sort((a, b) => a.diff(b));
}
