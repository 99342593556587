export type Newtype<TTag, TValue> = Readonly<{
    PHANTOM_TAG?: TTag | never;
    PHANTOM_VALUE?: TValue | never;
}>;

type ValueOf<T extends Newtype<unknown, unknown>> = T extends Newtype<unknown, infer V> ? V : never;

function identity<T>(value: T): T {
    return value;
}

function renameFunction<F extends Function>(name: string, fn: F) {
    return { [name]: (...args: any[]) => fn(...args) }[name];
}

export const newtype = <TNewtype extends Newtype<unknown, unknown>>(name: string) => {
    type NewtypeConstructor = {
        (value: ValueOf<TNewtype>): TNewtype;
        unwrap: (value: TNewtype) => ValueOf<TNewtype>;
    };
    const t: NewtypeConstructor = renameFunction(name, identity) as NewtypeConstructor;
    t.unwrap = identity as any;
    return t;
};
