import { Tabs } from "antd";
import { ActiveTabKeySetter } from "Components/ActiveTabKeySetter";
import React, { PropsWithChildren, useMemo, useState } from "react";
import { Route, Switch, useHistory } from "react-router";

type TabDescription<TKey extends string> = {
    key: TKey;
    label: string;
    routePattern: string;
    navigateTo: string;
    disabled?: boolean;

    content?: React.ReactNode;
};

type RoutedTabsProps<TKey extends string> = {
    tabs: (TabDescription<TKey> & { onClick?: () => void })[];
    defaultKey?: TKey;
};

function RoutedTabs<TKey extends string>({ tabs, defaultKey }: PropsWithChildren<RoutedTabsProps<TKey>>) {
    const [activeKey, setActiveKey] = useState<string | undefined>();
    const { push } = useHistory();

    const navigatableTabs = useMemo(() => tabs.filter(t => !t.disabled), [tabs]);

    return (
        <>
            <Switch>
                {navigatableTabs.map(t => (
                    <Route exact path={t.routePattern} key={t.key}>
                        <ActiveTabKeySetter setKey={() => setActiveKey(t.key)} />
                    </Route>
                ))}
                <Route>
                    <ActiveTabKeySetter setKey={() => setActiveKey(defaultKey)} />
                </Route>
            </Switch>
            <Tabs
                activeKey={activeKey}
                onChange={tabKey => {
                    const tab = tabs.find(t => t.key === tabKey);

                    if (tab) {
                        tab.onClick?.();
                        push(tab.navigateTo);
                    }
                }}>
                {tabs.map(t => (
                    <Tabs.TabPane tab={t.label} key={t.key} disabled={t.disabled}>
                        {t.content}
                    </Tabs.TabPane>
                ))}
            </Tabs>
        </>
    );
}

export default RoutedTabs;
