import mkCx from "@leancode/cx";
import { List, Tag, Typography } from "antd";
import EmptyState from "Components/EmptyState";
import Spacing from "Components/Spacing";
import MatchPlayer from "Domain/Matches/MatchPlayer";
import { l } from "Languages";
import React from "react";
import matchMvpStarIcon from "./match-mvp-star.svg";
import styles from "./styles.scss";
import teamMvpStarIcon from "./team-mvp-star.svg";

const cx = mkCx(styles);

type TeamLineupProps = {
    lineup?: MatchPlayer[];
    teamName: string;

    teamMvpId?: string;
    matchMvpId?: string;

    teamResult?: number;
    isMatchWinner?: boolean;

    reverse?: boolean;
};

const TeamLineup: React.FunctionComponent<TeamLineupProps> = ({
    lineup,
    teamName,
    teamMvpId,
    matchMvpId,
    teamResult,
    isMatchWinner,
    reverse,
}) => {
    return (
        <List
            bordered
            header={
                <div className={cx("header", reverse && "-reverse")}>
                    <Spacing childrenGutterX className={cx("team")}>
                        <Typography.Text strong>{teamName}</Typography.Text>
                        {isMatchWinner && <Tag>{l("MatchDetails_TeamLineup_MatchWinner")}</Tag>}
                    </Spacing>
                    {teamResult !== undefined && <Typography.Text strong>{teamResult}</Typography.Text>}
                </div>
            }
            dataSource={lineup}
            renderItem={player => {
                const isMatchMvp = player.id === matchMvpId;
                const isTeamMvp = player.id === teamMvpId;

                return (
                    <List.Item className={cx("row", reverse && "-reverse")}>
                        <div className={cx("player", reverse && "-reverse")}>
                            <Typography.Text strong className={cx("shirt-number")}>
                                {player.shirtNumber}
                            </Typography.Text>
                            {player.isGoalkeeper && (
                                <Typography.Text strong className={cx("goalkeeper")}>
                                    (BR)
                                </Typography.Text>
                            )}
                            {player.firstName} {player.lastName}
                        </div>
                        {(isMatchMvp || isTeamMvp) && <img src={isMatchMvp ? matchMvpStarIcon : teamMvpStarIcon} />}
                    </List.Item>
                );
            }}
            locale={{ emptyText: <EmptyState text={l("MatchDetails_TeamLineup_EmptyState")} /> }}
        />
    );
};

export default TeamLineup;
