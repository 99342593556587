import { handleResponse } from "@leancode/validation";
import { message } from "antd";
import { DeletePhoto, PhotoDTO } from "Contracts/PlayooLeagueClient";
import { l } from "Languages";
import api from "Services/Api";
import blobStorageClient from "Services/BlobStorageClient";
import extractExtension from "Utils/extractExtension";
import newId from "Utils/newId";
import retryQuery from "Utils/retryQuery";

class Photo {
    readonly id: string;
    readonly uri: string;

    constructor(id: string, uri: string) {
        this.id = id;
        this.uri = uri;
    }

    static fromDTO(dto: PhotoDTO) {
        return new Photo(dto.Id, dto.Uri);
    }

    async delete() {
        const response = await api.deletePhoto({
            PhotoId: this.id,
        });

        handleResponse(response, DeletePhoto)
            .handle(["PhotoNotFound", "failure"], () => {
                message.error(l("Photos_Gallery_Delete_Failure"));
            })
            .handle("success", () => {
                message.success(l("Photos_Gallery_Delete_Success"));
            })
            .check();

        return handleResponse(response, DeletePhoto);
    }

    static async uploadPhotos(
        files: File[],
        callback: (uploadedPhoto: UploadedPhoto) => Promise<boolean>,
    ): Promise<number> {
        const messageKey = `photos-upload-${newId()}`;
        message.loading({
            content: l("Photos_Upload_InProgress"),
            duration: 0,
            key: messageKey,
        });

        const results = await Promise.all(files.map(f => Photo.uploadPhoto(f, callback)));

        const successCount = results.reduce((count, wasSuccessful) => count + (wasSuccessful ? 1 : 0), 0);

        if (successCount === results.length) {
            message.success({
                content: l("Photos_Upload_Success"),
                key: messageKey,
            });
        } else if (successCount > 0) {
            message.info({
                content: l("Photos_Upload_PartialSuccess"),
                key: messageKey,
            });
        } else {
            message.error({
                content: l("Photos_Upload_Failure"),
                key: messageKey,
            });
        }

        return successCount;
    }

    private static async uploadPhoto(
        file: File,
        callback: (uploadedPhoto: UploadedPhoto) => Promise<boolean>,
    ): Promise<boolean> {
        try {
            const photoId = newId();
            const extension = extractExtension(file);

            if (!extension) {
                throw new Error("Cannot obtain image's extension.");
            }

            const uploadLinks = await retryQuery(() =>
                api.generatePresignedPhotoUploadLink({
                    PhotoId: photoId,
                    Extension: extension,
                }),
            );

            // photo with generated id already exists, try again
            if (!uploadLinks) {
                return this.uploadPhoto(file, callback);
            }

            await blobStorageClient.uploadForPresignedLink(file, uploadLinks);

            const uploadedPhoto = {
                photoId: photoId,
                uri: uploadLinks.Link,
            };

            return callback(uploadedPhoto);
        } catch {
            return false;
        }
    }
}

export type UploadedPhoto = {
    photoId: string;
    uri: string;
};

export default Photo;
