import Competition from "Domain/Competitions/Competition";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import { computed } from "mobx";
import { CompetitionPhase } from "../CompetitionPhase";
import GroupsPhase from "./GroupsPhase";

class AvailableTeamsForGroupsPhase {
    private readonly phase: GroupsPhase;
    private readonly linkedPhase?: CompetitionPhase;
    private readonly competition: Competition;

    constructor(phase: GroupsPhase, competition: Competition, linkedPhase?: CompetitionPhase) {
        if (phase.linkedPhaseId && linkedPhase?.id !== phase.linkedPhaseId) {
            throw new Error("Linked phase must be available if phase is linked.");
        }

        this.phase = phase;
        this.competition = competition;
        this.linkedPhase = linkedPhase;
    }

    @computed get teams() {
        const teamsInPhase = new Set(this.phase.teamsIds);
        const teamsInPhaseAssignedToGroups = new Set(this.phase.groups?.flatMap(g => g.teamIds));
        const teamsInLinkedPhase = new Set(this.linkedPhase?.teamsIds);

        const teamsFromPhaseNotAddedToAnyGroup = this.phase.teams?.filter(t => !teamsInPhaseAssignedToGroups.has(t.id));

        const teamsNotAddedToPhase = this.competition.teams?.filter(t => !teamsInPhase.has(t.id));

        let addableTeams: CompetitionTeam[];

        if (!this.phase.linkedPhaseId) {
            addableTeams = [...(teamsFromPhaseNotAddedToAnyGroup || []), ...(teamsNotAddedToPhase || [])];
        } else {
            const teamsNotAddedToPhaseAndLinkedPhase = teamsNotAddedToPhase?.filter(t => !teamsInLinkedPhase.has(t.id));

            addableTeams = [...(teamsFromPhaseNotAddedToAnyGroup || []), ...(teamsNotAddedToPhaseAndLinkedPhase || [])];
        }

        return addableTeams.sort((t1, t2) => t1.displayName.localeCompare(t2.displayName));
    }

    @computed get nonPlaceholderTeams() {
        return this.teams.filter(t => !t.isPlaceholder);
    }
}

export default AvailableTeamsForGroupsPhase;
