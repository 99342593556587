abstract class ExternalReferenceBase {
    readonly id: string;
    abstract readonly type: ExternalReferenceType;
    readonly phaseId: string;
    readonly referencedPhaseId: string;
    readonly advancingTeamId?: string;
    readonly predictedAdvancingTeamId?: string;

    constructor({
        id,
        phaseId,
        referencedPhaseId,
        predictedAdvancingTeamId,
        advancingTeamId,
    }: ExternalReferenceBaseInit) {
        this.id = id;
        this.phaseId = phaseId;
        this.referencedPhaseId = referencedPhaseId;
        this.predictedAdvancingTeamId = predictedAdvancingTeamId;
        this.advancingTeamId = advancingTeamId;
    }

    abstract get displayName(): string;
}

export enum ExternalReferenceType {
    PlaceInGroup,
    NthBestForPlaceInGroup,
    PlaceInTable,
}

export type ExternalReferenceBaseInit = Pick<
    ExternalReferenceBase,
    "id" | "phaseId" | "referencedPhaseId" | "predictedAdvancingTeamId" | "advancingTeamId"
>;

export default ExternalReferenceBase;
