import mkCx from "@leancode/cx";
import { Checkbox, List, Switch, Typography } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { PresentPlayer } from "Domain/Matches/MatchBase";
import { l } from "Languages";
import React, { useCallback } from "react";
import styles from "./styles.scss";

const cx = mkCx(styles);

type LineupSelectProps = {
    teamPlayers: {
        id: string;
        firstName: string;
        lastName: string;
        shirtNumber?: number;
    }[];

    selectedPlayers: PresentPlayer[];
    onToggleSelectPlayer: (playerId: string) => void;
    onToggleGoalkeeper: (playerId: string) => void;
    onSelectAll: () => void;
    onDeselectAll: () => void;
};

const LineupSelect: React.FunctionComponent<LineupSelectProps> = ({
    teamPlayers,
    selectedPlayers,
    onToggleSelectPlayer,
    onToggleGoalkeeper,
    onSelectAll,
    onDeselectAll,
}) => {
    const onCheckAllChange = useCallback(
        (e: CheckboxChangeEvent) => {
            if (e.target.checked) {
                onSelectAll();
            } else {
                onDeselectAll();
            }
        },
        [onSelectAll, onDeselectAll],
    );

    const selectedCount = selectedPlayers.length;
    return (
        <List
            header={
                <div className={cx("header")}>
                    <Typography.Text strong>{l("MatchDetails_PublishMatchReport_Lineup")}</Typography.Text>
                    <Checkbox
                        checked={selectedCount === teamPlayers.length}
                        indeterminate={selectedCount > 0 && selectedCount < teamPlayers.length}
                        onChange={onCheckAllChange}>
                        {l("MatchDetails_PublishMatchReport_Lineup_SelectAll")}
                    </Checkbox>
                </div>
            }
            bordered
            dataSource={teamPlayers}
            renderItem={p => {
                const selectedPlayer = selectedPlayers.find(sp => sp.id === p.id);

                return (
                    <List.Item key={p.id} onClick={() => onToggleSelectPlayer(p.id)} className={cx("list-item")}>
                        <Checkbox checked={selectedPlayers.some(player => player.id === p.id)} />
                        <span className={cx("player")}>
                            <span>
                                {p.lastName} {p.firstName}
                            </span>
                            <Typography.Text strong>{p.shirtNumber}</Typography.Text>
                        </span>
                        <Switch
                            checkedChildren={l("MatchDetails_PublishMatchReport_Lineup_Goalkeeper_Checked")}
                            title={l("MatchDetails_PublishMatchReport_Lineup_Goalkeeper_Tooltip")}
                            className={cx("goalkeeper")}
                            disabled={!selectedPlayer}
                            checked={selectedPlayer?.isGoalkeeper}
                            onChange={(_, e) => {
                                e.stopPropagation();
                                onToggleGoalkeeper(p.id);
                            }}
                        />
                    </List.Item>
                );
            }}
        />
    );
};

export default LineupSelect;
