import { message } from "antd";
import { l } from "Languages";
import blobStorageClient from "Services/BlobStorageClient";
import registrationsApi from "Services/RegistrationsApi";
import extractExtension from "Utils/extractExtension";
import newId from "Utils/newId";
import retryQuery from "Utils/retryQuery";

class RegistrationsBlobStorage {
    static async uploadRegistrationPhoto(registrationId: string, file: File) {
        const extension = extractExtension(file);
        const blobName = `${registrationId}/${newId()}.${extension}`;

        const storageToken = await retryQuery(() => registrationsApi.registrationPhotosCreateToken({}));

        try {
            await blobStorageClient.uploadBlob(blobName, file, storageToken);

            return blobStorageClient.blobUriFromToken(storageToken, blobName);
        } catch (e) {
            message.error(l("Registrations_Form_UploadPhoto_Error"));
        }
    }

    static async uploadRegistrationDocument(documentId: string, registrationId: string, file: File) {
        const extension = extractExtension(file);
        const blobName = `${registrationId}/${documentId}.${extension}`;

        const storageToken = await retryQuery(() => registrationsApi.registrationDocumentsCreateToken({}));

        try {
            await blobStorageClient.uploadBlob(blobName, file, storageToken);

            return blobStorageClient.blobUriFromToken(storageToken, blobName);
        } catch (e) {
            message.error(l("Registrations_Form_UploadDocument_Error"));
        }
    }
}

export default RegistrationsBlobStorage;
