import mkCx from "@leancode/cx";
import Spacing from "Components/Spacing";
import { BannerTypeDTO } from "Contracts/CmsClient";
import homePageBannersConfiguration from "Domain/Banners";
import { l } from "Languages";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";
import BannerTile from "../BannerTile";
import styles from "./styles.scss";

const cx = mkCx(styles);

const LandingHomePageSkeleton: React.FunctionComponent = observer(() => {
    return (
        <div>
            <div className={cx("app-bar")}>
                <div className={cx("logo-placeholder")} />
            </div>
            <Spacing childrenGutterY>
                <BannerTile type={BannerTypeDTO.BelowAppBar} banner={homePageBannersConfiguration.belowAppBarBanner} />
                <BannerTile type={BannerTypeDTO.Main} banner={homePageBannersConfiguration.mainBanner} />
                <div className={cx("content")}>
                    <div>
                        <div className={cx("label")}>{l("Landing_Banners_Skeleton_News")}</div>
                        <div className={cx("news-grid")}>
                            {_.range(0, 6).map(i => (
                                <div key={i} className={cx("tile-skeleton")} />
                            ))}
                            <div className={cx("small-news-banner-container")}>
                                <BannerTile
                                    type={BannerTypeDTO.SmallNews}
                                    banner={homePageBannersConfiguration.smallNewsBanner}
                                    bannerClassName={cx("news-banner")}
                                />
                            </div>
                            <div className={cx("large-news-banner-container")}>
                                <BannerTile
                                    type={BannerTypeDTO.LargeNews}
                                    banner={homePageBannersConfiguration.largeNewsBanner}
                                    bannerClassName={cx("news-banner")}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={cx("label")}>{l("Landing_Banners_Skeleton_Videos")}</div>
                        <div className={cx("videos-grid")}>
                            {_.range(0, 4).map(i => (
                                <div key={i} className={cx("tile-skeleton")} />
                            ))}
                        </div>
                        <Spacing childrenGutterY className={cx("right-column-banners")}>
                            {homePageBannersConfiguration.rightColumnBanners.map(b => (
                                <BannerTile key={b.id} type={BannerTypeDTO.RightColumn} banner={b} />
                            ))}
                            <BannerTile type={BannerTypeDTO.RightColumn} />
                        </Spacing>
                    </div>
                </div>
            </Spacing>
        </div>
    );
});

export default LandingHomePageSkeleton;
