export const notUndefined = <T>(value: T): value is Exclude<T, undefined> => value !== undefined;

interface TypeMap {
    string: string;
    number: number;
    boolean: boolean;
}

type PrimitiveOrConstructor = { new (...args: any[]): any } | keyof TypeMap;

type GuardedType<T extends PrimitiveOrConstructor> = T extends { new (...args: any[]): infer U }
    ? U
    : T extends keyof TypeMap
    ? TypeMap[T]
    : never;

export function ofType<T extends PrimitiveOrConstructor>(className: T) {
    return function typeGuard(o): o is GuardedType<T> {
        const LocalPrimitiveOrConstructor: PrimitiveOrConstructor = className;
        if (typeof LocalPrimitiveOrConstructor === "string") {
            return typeof o === LocalPrimitiveOrConstructor;
        }
        return o instanceof LocalPrimitiveOrConstructor;
    };
}
