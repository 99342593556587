import mkCx from "@leancode/cx";
import { Button, DatePicker, Input } from "antd";
import Form from "antd/lib/form";
import { useForm } from "antd/lib/form/util";
import TextArea from "antd/lib/input/TextArea";
import { Select } from "Components/Select";
import Spacing from "Components/Spacing";
import TinyMceEditor from "Components/TinyMceEditor";
import { l } from "Languages";
import { Moment } from "moment";
import React, { useCallback } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";
import { isValidInteger } from "Utils/validationHelpers";
import DocumentsUpload, { RegistrationDocument } from "./DocumentsUpload";
import RegistrationPhotoFormItem from "./RegistrationPhotoFormItem";
import RegistrationPhotoUpload from "./RegistrationPhotoUpload";
import styles from "./styles.scss";

const cx = mkCx(styles);

export type RegistrationFormValidationErrors = Partial<Record<FormField, string>>;

type RegistrationFormProps = {
    registrationId: string;
    initialValues?: Partial<FormFields>;
    mode: "edit" | "create";
    selectableMaxBirthYears: (number | undefined)[];

    onCancel: () => void;
    onSave: (registration: RegistrationDescription) => Promise<RegistrationFormValidationErrors | undefined>;
};

export const openAgeGroupValue = "open";

const RegistrationForm: React.FunctionComponent<RegistrationFormProps> = ({
    registrationId,
    initialValues,
    mode,
    selectableMaxBirthYears,
    onCancel,
    onSave,
}) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const onFinish = useCallback(
        (values: FormFields) =>
            runInTask(async () => {
                const validationErrors = await onSave({
                    ...values,
                    maxAllowedBirthYears: values.ageGroups.map(ag =>
                        ag === openAgeGroupValue ? undefined : parseInt(ag),
                    ),
                    maxTeamsCount: values.maxTeamsCount ? parseFloat(values.maxTeamsCount) : undefined,
                });

                for (const field in validationErrors) {
                    form.setFields([
                        {
                            name: field,
                            errors: [validationErrors[field]],
                            value: values[field],
                        },
                    ]);
                }
            }),
        [onSave, form, runInTask],
    );

    return (
        <Form
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}>
            <Form.Item
                name={guard<FormField>("title")}
                label={l("Registrations_Form_Title")}
                rules={[
                    { required: true, message: l("Common_Validation_FieldRequired") },
                    { max: 60, message: l("Common_Validation_FieldTooLong") },
                ]}>
                <Input />
            </Form.Item>
            <Form.Item
                name={guard<FormField>("summary")}
                label={l("Registrations_Form_Summary")}
                rules={[{ max: 200, message: l("Common_Validation_FieldTooLong") }]}>
                <TextArea />
            </Form.Item>
            <RegistrationPhotoFormItem name={guard<FormField>("logoUri")} label={l("Registrations_Form_Logo")}>
                <RegistrationPhotoUpload registrationId={registrationId} />
            </RegistrationPhotoFormItem>
            <Form.Item
                name={guard<FormField>("entryFee")}
                label={l("Registrations_Form_EntryFee")}
                rules={[{ max: 100, message: l("Common_Validation_FieldTooLong") }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name={guard<FormField>("startDate")}
                trigger="onSelect"
                rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}
                label={l("Registrations_Form_StartDate")}>
                <DatePicker
                    onChange={date =>
                        date === null &&
                        form.setFieldsValue({
                            [guard<FormField>("startDate")]: undefined,
                        })
                    }
                />
            </Form.Item>
            <Form.Item name={guard<FormField>("endDate")} trigger="onSelect" label={l("Registrations_Form_EndDate")}>
                <DatePicker
                    onChange={date =>
                        date === null &&
                        form.setFieldsValue({
                            [guard<FormField>("endDate")]: undefined,
                        })
                    }
                />
            </Form.Item>
            <Form.Item
                name={guard<FormField>("city")}
                label={l("Registrations_Form_City")}
                rules={[{ max: 250, message: l("Common_Validation_FieldTooLong") }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name={guard<FormField>("address")}
                label={l("Registrations_Form_Address")}
                rules={[{ max: 250, message: l("Common_Validation_FieldTooLong") }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name={guard<FormField>("sportsVenueName")}
                label={l("Registrations_Form_SportsVenueName")}
                rules={[{ max: 250, message: l("Common_Validation_FieldTooLong") }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name={guard<FormField>("maxTeamsCount")}
                label={l("Registrations_Form_MaxTeamsCount")}
                rules={[
                    {
                        validator: (rule, value: FormFields["maxTeamsCount"]) => {
                            if (
                                (value && !isValidInteger(value)) ||
                                (value && isValidInteger(value) && parseInt(value) <= 0)
                            ) {
                                return Promise.reject(rule.message);
                            }

                            return Promise.resolve();
                        },
                        message: l("Common_Validation_Number"),
                    },
                ]}>
                <Input type="number" />
            </Form.Item>
            <RegistrationPhotoFormItem
                name={guard<FormField>("thumbnailPhotoUri")}
                label={l("Registrations_Form_ThumbnailPhoto")}
                className={cx("full-width-image-form-item")}>
                <RegistrationPhotoUpload registrationId={registrationId} cropDisabled fullWidth />
            </RegistrationPhotoFormItem>
            <RegistrationPhotoFormItem
                name={guard<FormField>("backgroundPhotoUri")}
                label={l("Registrations_Form_BackgroundPhoto")}
                className={cx("full-width-image-form-item")}>
                <RegistrationPhotoUpload registrationId={registrationId} cropDisabled fullWidth />
            </RegistrationPhotoFormItem>
            <Form.Item
                name={guard<FormField>("ageGroups")}
                label={l("Registrations_Form_AgeGroups")}
                rules={[
                    {
                        required: true,
                        message: l("Common_Validation_FieldRequired"),
                    },
                ]}>
                <Select
                    mode="multiple"
                    selectOptions={selectableMaxBirthYears.map(y => ({
                        value: y?.toString() ?? openAgeGroupValue,
                        label: y?.toString() ?? l("Registrations_AgeGroups_Open"),
                        key: y?.toString() ?? openAgeGroupValue,
                    }))}
                    showArrow
                    allowClear
                />
            </Form.Item>
            <Form.Item
                name={guard<FormField>("articleTitle")}
                label={l("Registrations_Form_ArticleTitle")}
                rules={[
                    { required: true, message: l("Common_Validation_FieldRequired") },
                    { max: 250, message: l("Common_Validation_FieldTooLong") },
                ]}>
                <Input />
            </Form.Item>
            <Form.Item
                name={guard<FormField>("articleContent")}
                label={l("Registrations_Form_Article")}
                rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}
                trigger="onEditorChange">
                <TinyMceEditor />
            </Form.Item>
            <Form.Item
                name={guard<FormField>("documents")}
                label={l("Registrations_Form_Documents")}
                rules={[
                    {
                        validator: (rule, documents: FormFields["documents"]) => {
                            if (documents?.some(d => d.name.length > 40)) {
                                return Promise.reject(rule.message);
                            }

                            return Promise.resolve();
                        },
                        message: l("Registrations_Form_Documents_Validation_OneOfDocumentsNameTooLong"),
                    },
                    {
                        validator: (rule, documents: FormFields["documents"]) => {
                            if (documents?.some(d => d.name.length === 0)) {
                                return Promise.reject(rule.message);
                            }

                            return Promise.resolve();
                        },
                        message: l("Registrations_Form_Documents_Validation_OneOfDocumentsNameMissingOrEmpty"),
                    },
                ]}>
                <DocumentsUpload registrationId={registrationId} />
            </Form.Item>
            <Form.Item
                name={guard<FormField>("extraFormFieldDescription")}
                label={l("Registrations_Form_ExtraFormFieldDescription")}
                rules={[{ max: 200, message: l("Common_Validation_FieldTooLong") }]}>
                <TextArea />
            </Form.Item>
            <Form.Item>
                <Spacing childrenGutterX>
                    <Button onClick={onCancel}>{l("Common_Cancel")}</Button>
                    <Button type="primary" htmlType="submit" disabled={isRunning} loading={isRunning}>
                        {mode === "create" ? l("Common_Add") : l("Common_Save")}
                    </Button>
                </Spacing>
            </Form.Item>
        </Form>
    );
};

type FormFields = {
    title: string;
    summary?: string;
    logoUri?: string;
    entryFee?: string;
    startDate: Moment;
    endDate?: Moment;
    city?: string;
    address?: string;
    sportsVenueName?: string;
    maxTeamsCount?: string;
    backgroundPhotoUri?: string;
    thumbnailPhotoUri?: string;
    ageGroups: string[];
    articleTitle: string;
    articleContent: string;
    documents?: RegistrationDocument[];
    extraFormFieldDescription?: string;
};
type FormField = keyof FormFields;

export type RegistrationDescription = Omit<FormFields, "ageGroups" | "maxTeamsCount"> & {
    maxAllowedBirthYears: (number | undefined)[];
    maxTeamsCount?: number;
};

export default RegistrationForm;
