import { message, PageHeader, Skeleton } from "antd";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import PageContent from "Components/PageContent";
import articlesStore from "Domain/Articles";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import routes, { PageComponent } from "Router/routes";
import useRunInTask from "Utils/Hooks/useRunInTask";
import ArticleEditor, { ArticleEditorValidationErrors, FormFields } from "../ArticleEditor";

const EditArticlePage: PageComponent<typeof routes.articles.edit> = ({
    match: {
        params: { articleId },
    },
    history: { push },
}) => {
    const [isRunning, runInTask] = useRunInTask();
    const article = useObserver(() => articlesStore.getById(articleId));

    const breadcrumb: BreadcrumbProps["routes"] = useMemo(
        () =>
            article
                ? [
                      {
                          breadcrumbName: l("Articles"),
                          path: routes.articles(),
                      },
                      {
                          breadcrumbName: article.title,
                          path: routes.articles.edit({ articleId: articleId }),
                      },
                  ]
                : [],
        [article, articleId],
    );

    useEffect(() => {
        runInTask(async () => {
            if (articlesStore.articles.length === 0) {
                await articlesStore.fetchArticles();
            }
            await articlesStore.fetchArticleDetails(articleId);
        });
    }, [runInTask, articleId]);

    const onSaveArticle = useCallback(
        async (fields: FormFields): Promise<ArticleEditorValidationErrors | undefined> => {
            if (!article) {
                return;
            }

            const { handler } = await article.edit(fields);

            return handler
                .handle(
                    [
                        "ArticleNotFound",
                        "CompetitionNotFound",
                        "OneOfTagsEmpty",
                        "OneOfTagsTooLong",
                        "DatePublishedInvalid",
                        "DatePublishedRequired",
                        "failure",
                    ],
                    () => {
                        message.error(l("Articles_Edit_Failure"));
                    },
                )
                .handle("ContentMissingOrEmpty", () => ({
                    content: l("Common_Validation_FieldRequired"),
                }))
                .handle(["SummaryTooLong", "SummaryMissingOrEmpty"], error => ({
                    summary:
                        error === "SummaryTooLong"
                            ? l("Common_Validation_FieldTooLong")
                            : l("Common_Validation_FieldRequired"),
                }))
                .handle("ThumbnailPhotoUriTooLong", () => ({
                    thumbnailPhotoUri: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["TitleTooLong", "TitleMissingOrEmpty"], error => ({
                    title:
                        error === "TitleTooLong"
                            ? l("Common_Validation_FieldTooLong")
                            : l("Common_Validation_FieldRequired"),
                }))
                .handle(["success"], () => {
                    message.success(l("Articles_Edit_Success"));

                    push(routes.articles());
                })
                .check({
                    reducer: (prev, cur) => {
                        return {
                            ...prev,
                            ...(cur || {}),
                        };
                    },
                    initialValue: {} as ArticleEditorValidationErrors,
                });
        },
        [article, push],
    );

    return useObserver(() => (
        <PageContent>
            <PageContent.Header>
                <PageContent.HeaderSkeleton active={!article} loading={!article}>
                    <PageHeader
                        title={l("Articles_Edit_Title")}
                        onBack={() => push(routes.articles())}
                        breadcrumb={{
                            routes: breadcrumb,
                            itemRender: ({ path, breadcrumbName }) =>
                                path === location.pathname ? breadcrumbName : <Link to={path}>{breadcrumbName}</Link>,
                        }}
                    />
                </PageContent.HeaderSkeleton>
            </PageContent.Header>
            <PageContent.Card>
                <Skeleton loading={isRunning} active={isRunning}>
                    {article?.hasDetails && (
                        <ArticleEditor
                            mode="edit"
                            onSave={onSaveArticle}
                            onCancel={() => push(routes.articles())}
                            tagsFromOtherArticles={articlesStore.allTags}
                            initialValues={{
                                content: article.content,
                                isNews: article.isNews,
                                summary: article.summary,
                                tags: article.tags,
                                title: article.title,
                                thumbnailPhotoUri: article.thumbnailPhoto,
                                datePublished: article.datePublished,
                                competitionId: article.competitionId,
                            }}
                        />
                    )}
                </Skeleton>
            </PageContent.Card>
        </PageContent>
    ));
};

export default EditArticlePage;
