import { Match } from "Domain/Matches/Match";
import Break from "./Break";
import KnockoutPhaseMatch from "../KnockoutPhase/KnockoutPhaseMatch";
import { PhaseTypeDTO } from "Contracts/PlayooLeagueClient";

export type Slot = Match | Break;

export function isMatch(slot: Slot): slot is Match {
    return (slot as Match).phaseType !== undefined;
}

export function isBreak(slot: Slot): slot is Break {
    return (slot as Break).durationInMinutes !== undefined;
}

export function isKnockoutPhaseMatch(slot: Slot): slot is KnockoutPhaseMatch {
    return isMatch(slot) && slot.phaseType === PhaseTypeDTO.Knockout;
}
