import { PlusOutlined } from "@ant-design/icons";
import { message, Tag } from "antd";
import EditableLabel from "Components/EditableLabel";
import NavigationLink from "Components/NavigationLink";
import PageContent from "Components/PageContent";
import Spacing from "Components/Spacing";
import TablePhase from "Domain/CompetitionPhases/TablePhase/TablePhase";
import TablePhaseMatch from "Domain/CompetitionPhases/TablePhase/TablePhaseMatch";
import CompetitionSportsField from "Domain/Competitions/CompetitionSportsField";
import MatchdayFormDialog from "DomainComponents/Matches/MatchdayFormDialog";
import TablePhaseMatchFormDialog, { FormFields } from "DomainComponents/Matches/TablePhaseMatchFormDialog";
import ScheduleTable from "DomainComponents/ScheduleTable";
import isPromise from "is-promise";
import { l } from "Languages";
import { Observer, useObserver } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import routes from "Router/routes";

type TablePhaseScheduleProps = {
    phase: TablePhase;
    sportsFields: CompetitionSportsField[];
};

const TablePhaseSchedule: React.FunctionComponent<TablePhaseScheduleProps> = ({ phase, sportsFields }) => {
    const [showAddMatchDialog, setShowAddMatchDialog] = useState(false);
    const [matchToUpdateMatchday, setMatchToUpdateMatchday] = useState<TablePhaseMatch>();

    const saveMatchCreation = useCallback(
        async (values: FormFields) => {
            const result = await phase.addMatch({
                team1Id: values.team1Id,
                team2Id: values.team2Id,
                matchday: parseInt(values.matchday),
                date: values.date ?? undefined,
                sportsFieldId: values.sportsFieldId,
            });

            await Promise.all([
                result
                    .handle("success", async () => {
                        await phase.fetchDetails();

                        message.success(l("CompetitionPhases_Table_AddMatch_Success"));
                        setShowAddMatchDialog(false);
                    })
                    .handle(
                        [
                            "PhaseNotFoundOrNotATablePhase",
                            "TeamCannotPlayAgainstItself",
                            "TeamNotFoundInPhase",
                            "MatchdayNotPositive",
                            "SportsFieldNotFound",
                            "NameTooLong",
                            "failure",
                        ],
                        () => {
                            message.error(l("CompetitionPhases_Table_AddMatch_Failure"));
                        },
                    )
                    .check({
                        reducer: (prev, curr) => {
                            return [...prev, isPromise(curr) ? curr : Promise.resolve(curr)];
                        },
                        initialValue: [] as Promise<void>[],
                    }),
            ]);
        },
        [phase],
    );

    return useObserver(() => (
        <>
            <Spacing childrenGutterY>
                {phase.schedule && (
                    <NavigationLink
                        to={routes.competitionPhase.planSchedule({ phaseId: phase.id })}
                        type="button-primary">
                        {l("CompetitionPhases_PlanSchedule")}
                    </NavigationLink>
                )}
                <ScheduleTable
                    matches={phase.schedule}
                    phase={phase}
                    sportsFields={sportsFields}
                    phaseSpecificColumnProps={{
                        render: (_, match) => (
                            <Observer>
                                {() => (
                                    <EditableLabel
                                        onClick={e => {
                                            setMatchToUpdateMatchday(match);
                                            e.stopPropagation();
                                        }}>
                                        <Tag>{l("CompetitionPhases_Details_Schedule_Matchday", match.matchday)}</Tag>
                                    </EditableLabel>
                                )}
                            </Observer>
                        ),
                    }}
                    onScheduleChange={() => phase.fetchDetails()}
                />
            </Spacing>
            <PageContent.ActionButton onClick={() => setShowAddMatchDialog(true)}>
                <PlusOutlined /> {l("CompetitionPhases_Table_AddMatch")}
            </PageContent.ActionButton>
            {matchToUpdateMatchday && (
                <MatchdayFormDialog
                    match={matchToUpdateMatchday}
                    onClose={savedFormFields => {
                        if (savedFormFields) {
                            phase.fetchDetails();
                        }

                        setMatchToUpdateMatchday(undefined);
                    }}
                />
            )}
            {showAddMatchDialog && phase.teams && (
                <TablePhaseMatchFormDialog
                    mode="create"
                    teams={phase.teams}
                    sportsFields={sportsFields}
                    onSave={saveMatchCreation}
                    onClose={() => setShowAddMatchDialog(false)}
                />
            )}
        </>
    ));
};

export default TablePhaseSchedule;
