import SchedulePlanningWizard from "Domain/CompetitionPhases/SchedulePlanner/SchedulePlanningWizard/SchedulePlanningWizard";
import { observer } from "mobx-react-lite";
import React from "react";
import SlotsFillingFormDialog from "./SlotsFillingForm";
import SportsFieldsSelectionFormDialog from "./SportsFieldsSelectionFormDialog";

type SchedulePlanningWizardFormProps = {
    wizard: SchedulePlanningWizard;

    onClose: () => void;
};

const SchedulePlanningWizardForm: React.FunctionComponent<SchedulePlanningWizardFormProps> = observer(
    ({ wizard, onClose }) => {
        return wizard.slotsFilling ? (
            <SlotsFillingFormDialog slotsFilling={wizard.slotsFilling} onClose={onClose} />
        ) : (
            <SportsFieldsSelectionFormDialog wizard={wizard} onClose={onClose} />
        );
    },
);

export default SchedulePlanningWizardForm;
