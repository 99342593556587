import { EditOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Button, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import EditableLabel from "Components/EditableLabel";
import EmptyState from "Components/EmptyState";
import TeamLogo from "Components/TeamLogo";
import TeamParticipatingInMatch from "Components/TeamParticipatingInMatch";
import { PhaseTypeDTO } from "Contracts/PlayooLeagueClient";
import { CompetitionPhase } from "Domain/CompetitionPhases/CompetitionPhase";
import KnockoutPhase from "Domain/CompetitionPhases/KnockoutPhase/KnockoutPhase";
import Competition from "Domain/Competitions/Competition";
import CompetitionSportsField from "Domain/Competitions/CompetitionSportsField";
import { Match } from "Domain/Matches/Match";
import MatchDateFormDialog from "DomainComponents/Matches/MatchDateFormDialog";
import MatchEditionDialog from "DomainComponents/Matches/MatchEditionDialog";
import { l } from "Languages";
import { Observer } from "mobx-react-lite";
import React, { PropsWithChildren, useMemo, useState } from "react";
import { useHistory } from "react-router";
import routes from "Router/routes";
import { formatDate, formatTime } from "Utils/formatting";
import styles from "./styles.scss";

const cx = mkCx(styles);

type ScheduleTableProps<TMatch extends Match> = {
    matches?: TMatch[];
    phase: CompetitionPhase;
    sportsFields: CompetitionSportsField[];
    competition?: Competition;

    emptyText?: string;

    renderTeam1Column?: (match: TMatch) => React.ReactNode;
    renderTeam2Column?: (match: TMatch) => React.ReactNode;

    phaseSpecificColumnProps: ColumnProps<TMatch>;
    onScheduleChange?: () => void;
};

function ScheduleTable<TMatch extends Match>({
    matches,
    phase,
    sportsFields,
    competition,
    emptyText,
    renderTeam1Column,
    renderTeam2Column,
    onScheduleChange,
    phaseSpecificColumnProps,
}: PropsWithChildren<ScheduleTableProps<TMatch>>) {
    const [matchToSetDate, setMatchToSetDate] = useState<TMatch>();
    const [matchToEdit, setMatchToEdit] = useState<TMatch>();

    const { push } = useHistory();

    const columns = useMemo(
        (): ColumnProps<TMatch>[] => [
            {
                title: l("CompetitionPhases_Details_Schedule_Date"),
                render: (_, match) => (
                    <Observer>
                        {() =>
                            match.date ? (
                                <EditableLabel
                                    onClick={e => {
                                        setMatchToSetDate(match);
                                        e.stopPropagation();
                                    }}>
                                    {formatDate(match.date)}
                                </EditableLabel>
                            ) : (
                                <Button
                                    type="link"
                                    onClick={e => {
                                        setMatchToSetDate(match);
                                        e.stopPropagation();
                                    }}>
                                    {l("CompetitionPhases_Details_SetMatchDate")}
                                </Button>
                            )
                        }
                    </Observer>
                ),
            },
            {
                title: l("CompetitionPhases_Details_Schedule_Team1"),
                render: (_, match) =>
                    renderTeam1Column ? (
                        renderTeam1Column(match)
                    ) : (
                        <TeamParticipatingInMatch
                            match={match}
                            side="team1"
                            knockoutPhase={phase.type === PhaseTypeDTO.Knockout ? (phase as KnockoutPhase) : undefined}
                        />
                    ),
            },
            {
                render: (_, match) => <TeamLogo photo={match.team1?.logo} />,
                align: "right",
            },
            {
                render: (_, match) => (
                    <Observer>
                        {() => (
                            <div>
                                {match.result && (
                                    <div className={cx("full-time-result")}>
                                        {match.result.fullTimeScore.team1} : {match.result.fullTimeScore.team2}
                                    </div>
                                )}
                                {match.date && formatTime(match.date)}
                            </div>
                        )}
                    </Observer>
                ),
                align: "center",
            },
            {
                render: (_, match) => <TeamLogo photo={match.team2?.logo} />,
            },
            {
                title: l("CompetitionPhases_Details_Schedule_Team2"),
                render: (_, match) =>
                    renderTeam2Column ? (
                        renderTeam2Column(match)
                    ) : (
                        <TeamParticipatingInMatch
                            match={match}
                            side="team2"
                            knockoutPhase={phase.type === PhaseTypeDTO.Knockout ? (phase as KnockoutPhase) : undefined}
                        />
                    ),
                align: "right",
            },
            {
                align: "right",
                className: cx("phase-specific-column"),
                ...phaseSpecificColumnProps,
            },
            {
                title: l("CompetitionPhases_Details_Schedule_SportsField"),
                render: (_, match) => sportsFields.find(sf => sf.id == match.sportsFieldId)?.name,
            },
            {
                render: (_, match) => (
                    <Button
                        icon={<EditOutlined />}
                        shape="circle"
                        onClick={e => {
                            setMatchToEdit(match);
                            e.stopPropagation();
                        }}
                    />
                ),
            },
        ],
        [phase, phaseSpecificColumnProps, renderTeam1Column, renderTeam2Column, sportsFields],
    );

    return (
        <>
            <Table
                rowKey={m => m.id}
                columns={columns}
                dataSource={matches}
                pagination={false}
                tableLayout="fixed"
                onRow={m => ({
                    onClick: () =>
                        push(
                            routes.matchDetails({
                                matchId: m.id,
                            }),
                        ),
                    className: cx("match-row"),
                })}
                locale={{
                    emptyText: <EmptyState text={emptyText ?? l("CompetitionPhases_Details_Schedule_EmptyState")} />,
                }}
            />
            {matchToSetDate && (
                <MatchDateFormDialog
                    phase={phase}
                    match={matchToSetDate}
                    onClose={savedFormFields => {
                        if (savedFormFields && onScheduleChange) {
                            onScheduleChange();
                        }

                        setMatchToSetDate(undefined);
                    }}
                />
            )}
            {matchToEdit && (
                <MatchEditionDialog
                    competition={competition}
                    match={matchToEdit}
                    phase={phase}
                    onClose={async ({ editionSaved }) => {
                        if (editionSaved) {
                            onScheduleChange?.();
                        }

                        setMatchToEdit(undefined);
                    }}
                    sportsFields={sportsFields}
                />
            )}
        </>
    );
}

export default ScheduleTable;
