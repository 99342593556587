import mkCx from "@leancode/cx";
import { DatePicker, Form, message, Spin } from "antd";
import { useForm } from "antd/lib/form/util";
import Paragraph from "antd/lib/typography/Paragraph";
import Modal from "Components/Modal";
import Spacing from "Components/Spacing";
import TeamLogo from "Components/TeamLogo";
import TeamParticipatingInMatch from "Components/TeamParticipatingInMatch";
import { PhaseTypeDTO } from "Contracts/PlayooLeagueClient";
import { CompetitionPhase } from "Domain/CompetitionPhases/CompetitionPhase";
import KnockoutPhase from "Domain/CompetitionPhases/KnockoutPhase/KnockoutPhase";
import { Match } from "Domain/Matches/Match";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import moment from "moment";
import React, { useCallback } from "react";
import { dateWithTimeFormat, timeFormat } from "Utils/formatting";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";
import styles from "./styles.scss";

const cx = mkCx(styles);

type MatchDateFormDialogProps = {
    match: Match;
    phase: CompetitionPhase;

    onClose?: (savedFormFields?: FormFields) => void;
};

const MatchDateFormDialog: React.FunctionComponent<MatchDateFormDialogProps> = ({ match, phase, onClose }) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const onFinish = useCallback(
        (values: FormFields) => {
            runInTask(async () => {
                const result = await match.setDate(values.date);

                result
                    .handle(["MatchNotFound", "failure"], () => {
                        message.error(l("CompetitionPhases_SetMatchDate_Failure"));
                    })
                    .handle("success", () => {
                        message.success(l("CompetitionPhases_SetMatchDate_Success"));

                        onClose?.(values);
                    })
                    .check();
            });
        },
        [runInTask, match, onClose],
    );

    return useObserver(() => (
        <Modal
            title={
                match.date
                    ? l("CompetitionPhases_SetMatchDate_Form_Edit_Title")
                    : l("CompetitionPhases_SetMatchDate_Form_Add_Title")
            }
            onCancel={() => onClose?.()}
            okText={l("Common_Save")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}>
            <Spin spinning={isRunning}>
                <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ date: match.date }}>
                    <Paragraph>
                        <Spacing childrenGutterX>
                            <span>
                                <TeamParticipatingInMatch
                                    match={match}
                                    side="team1"
                                    knockoutPhase={
                                        phase.type === PhaseTypeDTO.Knockout ? (phase as KnockoutPhase) : undefined
                                    }
                                />{" "}
                                <TeamLogo photo={match.team1?.logo} />
                            </span>
                            <b className={cx("versus-text")}>{l("Common_Versus")}</b>
                            <span>
                                <TeamLogo photo={match.team2?.logo} />{" "}
                                <TeamParticipatingInMatch
                                    match={match}
                                    side="team2"
                                    knockoutPhase={
                                        phase.type === PhaseTypeDTO.Knockout ? (phase as KnockoutPhase) : undefined
                                    }
                                />
                            </span>
                        </Spacing>
                    </Paragraph>
                    <Form.Item name={guard<FormField>("date")} trigger="onSelect">
                        <DatePicker
                            showTime={{ format: timeFormat }}
                            format={dateWithTimeFormat}
                            onChange={date =>
                                date === null &&
                                form.setFieldsValue({
                                    [guard<FormField>("date")]: undefined,
                                })
                            }
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    ));
};

export type FormFields = {
    date?: moment.Moment;
};

type FormField = keyof FormFields;

export default MatchDateFormDialog;
