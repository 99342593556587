import { Tag } from "antd";
import React from "react";
import { SeasonStateDTO } from "Contracts/PlayooLeagueClient";
import { l } from "Languages";

type SeasonStateTagProps = {
    seasonState: SeasonStateDTO;
};

const SeasonStateTag: React.FunctionComponent<SeasonStateTagProps> = ({ seasonState }) => {
    switch (seasonState) {
        case SeasonStateDTO.Next:
            return <Tag>{l("Seasons_State_Next")}</Tag>;
        case SeasonStateDTO.Ongoing:
            return <Tag>{l("Seasons_State_Ongoing")}</Tag>;
    }

    return null;
};

export default SeasonStateTag;
