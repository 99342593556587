import { Form, Input } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import TeamGroup from "Domain/CompetitionPhases/GroupsPhase/TeamGroup";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";

type GroupNameFormDialogProps = {
    group: TeamGroup;

    onClose: () => void;
    onSave: (name: string) => Promise<void>;
};

const GroupNameFormDialog: React.FunctionComponent<GroupNameFormDialogProps> = ({ group, onClose, onSave }) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const onFinish = useCallback(
        (values: FormFields) => {
            runInTask(() => onSave(values.name));
        },
        [onSave, runInTask],
    );

    return useObserver(() => (
        <Modal
            title={l("CompetitionPhases_Groups_SetGroupName")}
            onCancel={onClose}
            okText={l("Common_Save")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}
            okButtonProps={{
                disabled: isRunning,
                loading: isRunning,
            }}>
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                initialValues={{ [guard<FormField>("name")]: group.name }}>
                <Form.Item
                    name={guard<FormField>("name")}
                    label={l("CompetitionPhases_Groups_Name")}
                    rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    ));
};

export type FormFields = {
    name: string;
};

type FormField = keyof FormFields;

export default GroupNameFormDialog;
