import { MatchEventSideDTO, MatchEventSubtypeDTO, MatchEventTypeDTO } from "Contracts/PlayooLeagueClient";
import { action, computed, observable } from "mobx";
import { FieldData } from "rc-field-form/lib/interface";
import guard from "Utils/guard";
import MatchEvent from "./MatchEvent";
import MatchPlayer from "./MatchPlayer";

export type FormFields = {
    side: MatchEventSideDTO;
    type: MatchEventTypeDTO;
    subtype?: MatchEventSubtypeDTO;
    minute?: string;
    second?: string;
    playerId?: string;
    otherPlayerId?: string;
    goalkeeperPlayerId?: string;
    penaltyValue?: number;
};

export type FormField = keyof FormFields;

class MatchEventEditor {
    private readonly team1Lineup: MatchPlayer[];
    private readonly team2Lineup: MatchPlayer[];

    @observable side?: MatchEventSideDTO;
    @observable type?: MatchEventTypeDTO;
    @observable subtype?: MatchEventSubtypeDTO;
    @observable minute?: string;
    @observable second?: string;
    @observable playerId?: string;
    @observable otherPlayerId?: string;
    @observable goalkeeperPlayerId?: string;
    @observable penaltyValue?: string;

    @observable formData: FieldData[] = ([
        "side",
        "type",
        "subtype",
        "minute",
        "second",
        "playerId",
        "otherPlayerId",
        "goalkeeperPlayerId",
        "penaltyValue",
    ] as const).map(field => ({
        name: guard<FormField>(field),
        value: this[field],
    }));

    private constructor(event: MatchEvent | undefined, team1Lineup: MatchPlayer[], team2Lineup: MatchPlayer[]) {
        if (event) {
            this.side = event.side;
            this.type = event.type;
            this.subtype = event.subtype;
            this.minute = event.minuteOfMatch?.toString();
            this.second = event.secondOfMatchMinute?.toString();
            this.playerId = event.playerId;
            this.otherPlayerId = event.otherPlayerId;
            this.goalkeeperPlayerId = event.goalkeeperPlayerId;
            this.penaltyValue = event.penaltyValue?.toString();
        }

        this.team1Lineup = team1Lineup;
        this.team2Lineup = team2Lineup;
    }

    static fromEvent(event: MatchEvent, team1Lineup: MatchPlayer[], team2Lineup: MatchPlayer[]) {
        return new MatchEventEditor(event, team1Lineup, team2Lineup);
    }

    static fromEmpty(team1Lineup: MatchPlayer[], team2Lineup: MatchPlayer[]) {
        return new MatchEventEditor(undefined, team1Lineup, team2Lineup);
    }

    @computed private get lineupForSide() {
        return this.side === MatchEventSideDTO.Team1 ? this.team1Lineup : this.team2Lineup;
    }

    @computed private get lineupForOppositeSide() {
        return this.side === MatchEventSideDTO.Team1 ? this.team2Lineup : this.team1Lineup;
    }

    @action.bound
    private clearPlayers() {
        this.selectPlayer(undefined);
        this.selectOtherPlayer(undefined);
        this.selectGoalkeeper(undefined);
    }

    @action.bound
    selectSide(side: MatchEventSideDTO) {
        if (this.side !== side) {
            this.side = side;

            this.clearPlayers();
        }
    }

    @action.bound
    selectType(type: MatchEventTypeDTO) {
        if (this.type !== type) {
            this.type = type;

            if (type === MatchEventTypeDTO.Goal) {
                this.selectSubtype(MatchEventSubtypeDTO.Unspecified);
            } else if (type === MatchEventTypeDTO.Shot) {
                this.selectSubtype(MatchEventSubtypeDTO.OnTarget);
            } else {
                this.selectSubtype(undefined);
            }

            this.setPenaltyValue(undefined);

            this.clearPlayers();
        }
    }

    @action.bound
    selectSubtype(subtype?: MatchEventSubtypeDTO) {
        if (this.subtype !== subtype) {
            if (
                this.type === MatchEventTypeDTO.Goal &&
                (subtype === MatchEventSubtypeDTO.Own || this.subtype === MatchEventSubtypeDTO.Own)
            ) {
                this.selectPlayer(undefined);
                this.selectOtherPlayer(undefined);
            }

            if (this.type === MatchEventTypeDTO.Goal || this.type === MatchEventTypeDTO.Shot) {
                this.subtype = subtype;
            }
        }
    }

    @action.bound
    selectPlayer(playerId?: string) {
        this.playerId = playerId;
    }

    @action.bound
    selectOtherPlayer(otherPlayerId?: string) {
        this.otherPlayerId = otherPlayerId;
    }

    @action.bound
    selectGoalkeeper(goalkeeperPlayerId?: string) {
        this.goalkeeperPlayerId = goalkeeperPlayerId;
    }

    @action.bound
    setMinute(minute?: string) {
        this.minute = minute;
    }

    @action.bound
    setSecond(second?: string) {
        this.second = second;
    }

    @action.bound
    setPenaltyValue(penaltyValue?: string) {
        this.penaltyValue = penaltyValue;
    }

    @computed get selectablePlayers(): MatchPlayer[] | undefined {
        let lineup: MatchPlayer[] | undefined;

        switch (this.type) {
            case MatchEventTypeDTO.Goal:
                switch (this.subtype) {
                    case MatchEventSubtypeDTO.Own:
                        lineup = this.lineupForOppositeSide;
                        break;
                    case undefined:
                        lineup = undefined;
                        break;
                    default:
                        lineup = this.lineupForSide;
                        break;
                }
                break;
            default:
                lineup = this.lineupForSide;
        }

        return lineup?.filter(p => p.id !== this.otherPlayerId);
    }

    @computed get selectableOtherPlayers(): MatchPlayer[] | undefined {
        let lineup: MatchPlayer[] | undefined;

        switch (this.type) {
            case MatchEventTypeDTO.Goal:
                switch (this.subtype) {
                    case MatchEventSubtypeDTO.Own:
                        lineup = this.lineupForOppositeSide;
                        break;
                    default:
                        lineup = this.lineupForSide;
                        break;
                }
                break;
            case MatchEventTypeDTO.Foul:
                lineup = this.lineupForOppositeSide;
                break;
            default:
                lineup = undefined;
        }

        return lineup?.filter(p => p.id !== this.playerId);
    }

    @computed get selectableGoalkeepers(): MatchPlayer[] | undefined {
        if (this.type === MatchEventTypeDTO.Goal || this.type === MatchEventTypeDTO.Shot) {
            return this.lineupForOppositeSide;
        }

        return undefined;
    }

    @computed get fields(): FieldData[] {
        return this.formData.map(field => ({
            ...field,
            value: this[field.name as FormField],
        }));
    }

    @action.bound
    onChange(changedFields: FieldData[], allFields: FieldData[]) {
        this.formData = allFields;

        changedFields.forEach(f => {
            switch (f.name[0] as FormField) {
                case "side":
                    this.selectSide(f.value);
                    break;
                case "type":
                    this.selectType(f.value);
                    break;
                case "subtype":
                    this.selectSubtype(f.value);
                    break;
                case "playerId":
                    this.selectPlayer(f.value);
                    break;
                case "otherPlayerId":
                    this.selectOtherPlayer(f.value);
                    break;
                case "goalkeeperPlayerId":
                    this.selectGoalkeeper(f.value);
                    break;
                case "minute":
                    this.setMinute(f.value);
                    break;
                case "second":
                    this.setSecond(f.value);
                    break;
                case "penaltyValue":
                    this.setPenaltyValue(f.value);
                    break;
            }
        });
    }

    @computed get currentEvent(): MatchEvent {
        if (this.side === undefined || this.type === undefined) {
            throw new Error("Editor is in invalid state.");
        }

        if (
            (this.type === MatchEventTypeDTO.Goal || this.type === MatchEventTypeDTO.Shot) &&
            this.subtype === undefined
        ) {
            throw new Error("Subtype is required for Goal and Shot types.");
        }

        const secondOfMatch = this.minute
            ? parseInt(this.minute) * 60 + (this.second ? parseInt(this.second) : 0)
            : undefined;

        return new MatchEvent({
            side: this.side,
            type: this.type,
            subtype: this.subtype,
            secondOfMatch: secondOfMatch,
            playerId: this.playerId,
            otherPlayerId: this.otherPlayerId,
            goalkeeperPlayerId: this.goalkeeperPlayerId,
            penaltyValue: this.penaltyValue ? parseInt(this.penaltyValue) : undefined,
        });
    }
}

export default MatchEventEditor;
