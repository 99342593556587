import { l } from "Languages";
import KnockoutPhaseMatch, { AdvancingTeamPlaceholder, KnockoutMatchNodeData } from "./KnockoutPhaseMatch";

export const formatStage = (stage: number, isLegacyPhase?: boolean) => {
    if (isLegacyPhase) return undefined;

    switch (stage) {
        case 1:
            return l("CompetitionPhases_Knockout_Stage_Final");
        case 2:
            return l("CompetitionPhases_Knockout_Stage_Semifinal");
        case 4:
            return l("CompetitionPhases_Knockout_Stage_Quarterfinal");
        default:
            return l("CompetitionPhases_Knockout_Stage_Nth", stage);
    }
};

export const isLegacyNthPlaceNode = (nodeData: KnockoutMatchNodeData) =>
    nodeData.treeId === 0 && nodeData.nodeNumber > 1;

export const isNthPlaceNode = (nodeData: KnockoutMatchNodeData) =>
    isLegacyNthPlaceNode(nodeData) || (nodeData.treeId !== undefined && nodeData.nodeNumber === 1);

export const formatMatchNumber = (nodeData: KnockoutMatchNodeData, _isLegacyPhase: boolean) => {
    if (nodeData.treeId === undefined) return l("CompetitionPhases_Knockout_MatchNumber", nodeData.nodeNumber);

    if (isNthPlaceNode(nodeData))
        return l("CompetitionPhases_Knockout_MatchForNthPlace", KnockoutPhaseMatch.matchForPlaceFromNode(nodeData));

    return l(
        "CompetitionPhases_Knockout_MatchNumberForNthPlace",
        nodeData.nodeNumber,
        KnockoutPhaseMatch.matchForPlaceFromNode(nodeData),
    );
};

export const formatAdvancingTeamPlaceholder = ({ advancingFromNode, advancingAs }: AdvancingTeamPlaceholder) => {
    if (advancingFromNode.treeId === undefined) {
        if (advancingAs === "winner") {
            return l("CompetitionPhases_Knockout_TeamAdvancingFromAsWinner", advancingFromNode.nodeNumber);
        } else {
            return l("CompetitionPhases_Knockout_TeamAdvancingFromAsLoser", advancingFromNode.nodeNumber);
        }
    }

    if (advancingAs === "winner") {
        return l(
            "CompetitionPhases_Knockout_TeamAdvancingFromAsWinnerForNthPlace",
            advancingFromNode.nodeNumber,
            KnockoutPhaseMatch.matchForPlaceFromNode(advancingFromNode),
        );
    } else {
        return l(
            "CompetitionPhases_Knockout_TeamAdvancingFromAsLoserForNthPlace",
            advancingFromNode.nodeNumber,
            KnockoutPhaseMatch.matchForPlaceFromNode(advancingFromNode),
        );
    }
};
