import { SearchOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Button, Input } from "antd";
import { FilterDropdownProps } from "antd/lib/table/interface";
import Spacing from "Components/Spacing";
import { l } from "Languages";
import React from "react";
import styles from "./styles.scss";

const cx = mkCx(styles);

type TextSearchFilterProps = {
    placeholder: string;
} & FilterDropdownProps;

const TextSearchFilter: React.FunctionComponent<TextSearchFilterProps> = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    placeholder,
}) => {
    return (
        <div className={cx("container")}>
            <Input
                placeholder={placeholder}
                className={cx("input")}
                value={selectedKeys?.[0]}
                onChange={e => setSelectedKeys && setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={confirm}
            />
            <Spacing childrenGutterX>
                <Button size="small" className={cx("button")} onClick={() => clearFilters && clearFilters()}>
                    {l("Common_Reset")}
                </Button>
                <Button
                    icon={<SearchOutlined />}
                    size="small"
                    className={cx("button")}
                    type="primary"
                    onClick={confirm}>
                    {l("Common_Search")}
                </Button>
            </Spacing>
        </div>
    );
};

export default TextSearchFilter;
