import { PhaseTypeDTO } from "Contracts/PlayooLeagueClient";
import { formatAdvancingTeamPlaceholder } from "Domain/CompetitionPhases/KnockoutPhase/formatting";
import KnockoutPhase from "Domain/CompetitionPhases/KnockoutPhase/KnockoutPhase";
import { l } from "Languages";
import { Match } from "./Match";

export const formatTeamParticipatingInMatch = (
    match: Match,
    side: "team1" | "team2",
    knockoutPhase?: KnockoutPhase,
) => {
    const teamName = side === "team1" ? match.team1?.displayName : match.team2?.displayName;

    if (teamName) {
        return teamName;
    }

    if ((side === "team1" && match.isTeam1Deleted) || (side === "team2" && match.isTeam2Deleted)) {
        return l("CompetitionTeams_TeamDeleted");
    }

    if (match.phaseType === PhaseTypeDTO.Knockout) {
        if (!knockoutPhase) throw "For knockout phase matches phase is needed";

        const advancingTeamPlaceholder = knockoutPhase.getAdvancingTeamPlaceholder(match, side);

        if (advancingTeamPlaceholder) {
            return formatAdvancingTeamPlaceholder(advancingTeamPlaceholder);
        }
    }

    return l("CompetitionTeams_TeamDeleted");
};

export const formatTeamVsTeam = (match: Match) => {
    return `${formatTeamParticipatingInMatch(match, "team1")} ${l("Common_Versus")} ${formatTeamParticipatingInMatch(
        match,
        "team2",
    )}`;
};
