import { Tag, Tooltip } from "antd";
import NavigationLink from "Components/NavigationLink";
import ScheduleTableTags from "Components/ScheduleTableTags";
import Spacing from "Components/Spacing";
import TeamParticipatingInMatch from "Components/TeamParticipatingInMatch";
import { formatMatchNumber, formatStage } from "Domain/CompetitionPhases/KnockoutPhase/formatting";
import KnockoutPhase from "Domain/CompetitionPhases/KnockoutPhase/KnockoutPhase";
import CompetitionSportsField from "Domain/Competitions/CompetitionSportsField";
import ScheduleTable from "DomainComponents/ScheduleTable";
import { l } from "Languages";
import { Observer } from "mobx-react-lite";
import React from "react";
import routes from "Router/routes";

type KnockoutPhaseScheduleProps = {
    phase: KnockoutPhase;
    sportsFields: CompetitionSportsField[];
};

const KnockoutPhaseSchedule: React.FunctionComponent<KnockoutPhaseScheduleProps> = ({ phase, sportsFields }) => {
    return (
        <>
            <Spacing childrenGutterY>
                {phase.schedule && (
                    <NavigationLink
                        to={routes.competitionPhase.planSchedule({ phaseId: phase.id })}
                        type="button-primary">
                        {l("CompetitionPhases_PlanSchedule")}
                    </NavigationLink>
                )}
                <ScheduleTable
                    matches={phase.schedule}
                    phase={phase}
                    sportsFields={sportsFields}
                    phaseSpecificColumnProps={{
                        render: (_, match) => {
                            return (
                                <Observer>
                                    {() => (
                                        <ScheduleTableTags>
                                            {match.name && <Tag color="blue">{match.name}</Tag>}
                                            <Tag>{formatMatchNumber(match.nodeData, phase.isLegacyPhase)}</Tag>
                                            {match.stage && !phase.isLegacyPhase && (
                                                <Tooltip title={formatStage(match.stage)}>
                                                    <Tag>{formatStage(match.stage)}</Tag>
                                                </Tooltip>
                                            )}
                                        </ScheduleTableTags>
                                    )}
                                </Observer>
                            );
                        },
                    }}
                    onScheduleChange={() => phase.fetchDetails()}
                    renderTeam1Column={match => (
                        <TeamParticipatingInMatch match={match} side="team1" knockoutPhase={phase} />
                    )}
                    renderTeam2Column={match => (
                        <TeamParticipatingInMatch match={match} side="team2" knockoutPhase={phase} />
                    )}
                />
            </Spacing>
        </>
    );
};

export default KnockoutPhaseSchedule;
