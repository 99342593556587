import React from "react";
import styles from "./styles.scss";
import mkCx, { rawClassName } from "@leancode/cx";

const cx = mkCx(styles);

export const TopSpacing: React.FunctionComponent = ({ children }) => (
    <div className={cx("top-spacing")}>{children}</div>
);

export const TopPadding: React.FunctionComponent = ({ children }) => (
    <div className={cx("top-padding")}>{children}</div>
);

type SpacingProps = {
    className?: string;
    top?: boolean;
    childrenGutterX?: boolean;
    childrenGutterY?: boolean;
};

const Spacing: React.FunctionComponent<SpacingProps> = ({
    children,
    className,
    childrenGutterX,
    childrenGutterY,
    top,
}) => {
    return (
        <div
            className={cx(
                top && "top-spacing",
                childrenGutterX && "children-gutter-x",
                childrenGutterY && "children-gutter-y",
                rawClassName(className),
            )}>
            {children}
        </div>
    );
};

export default Spacing;
