import { DeleteOutlined } from "@ant-design/icons";
import { Button, message, PageHeader } from "antd";
import { PageHeaderProps } from "antd/lib/page-header";
import DropdownMenu from "Components/DropdownMenu";
import PageContent from "Components/PageContent";
import RoutedTabs from "Components/RoutedTabs";
import TablePhase from "Domain/CompetitionPhases/TablePhase/TablePhase";
import Competition from "Domain/Competitions/Competition";
import { l } from "Languages";
import { Observer, useObserver } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import routes from "Router/routes";
import CompetitionPhaseTeams from "../CompetitionPhaseTeams";
import TablePointsModifiers from "../TablePointsModifiers";
import usePlaceholderTeamForPhaseDeletion from "../usePlaceholderTeamForPhaseDeletion";
import useScheduleDeletion from "../useScheduleDeletion";
import TablePhaseSchedule from "./TablePhaseSchedule";
import GeneratePhaseScheduleFormDialog from "../PhaseScheduleForm/GeneratePhaseScheduleFormDialog";
import { PhaseScheduleConfigurationFormFields } from "../PhaseScheduleForm/PhaseScheduleConfigurationForm";
import TableDrawDialog from "./TableDrawDialog";
import TableOrderModifiers from "../TableOrderModifiers";
import Spacing from "Components/Spacing";

type TablePhaseDetailsProps = {
    phase: TablePhase;
    competition: Competition;

    headerProps: PageHeaderProps;
};

const TablePhaseDetails: React.FunctionComponent<TablePhaseDetailsProps> = ({ phase, competition, headerProps }) => {
    const { push } = useHistory();
    const [isGenerateScheduleDialogOpen, setIsGenerateScheduleDialogOpen] = useState(false);
    const [isDrawDialogOpen, setIsDrawDialogOpen] = useState(false);

    const showDeleteScheduleConfirmation = useScheduleDeletion(phase);
    const showDeletePlaceholderForPhaseConfirmation = usePlaceholderTeamForPhaseDeletion(competition, phase.id);

    const onScheduleGenerate = useCallback(
        async (values: PhaseScheduleConfigurationFormFields) => {
            const result = await phase.generateSchedule(values.matchesPerPair);

            result
                .handle(
                    [
                        "MatchesPerPairOutOfRange",
                        "PhaseHasTooFewTeams",
                        "PhaseNotFound",
                        "ScheduleAlreadyGenerated",
                        "failure",
                    ],
                    () => {
                        message.error(l("CompetitionDetails_GeneratePhaseSchedule_Failure"));
                    },
                )
                .handle("success", () => {
                    message.success(l("CompetitionDetails_GeneratePhaseSchedule_Success"));

                    setIsGenerateScheduleDialogOpen(false);
                    push(routes.competitionPhase.schedule({ phaseId: phase.id }));
                })
                .check();
        },
        [phase, push],
    );

    const hasPlaceholders = useMemo(() => (phase.teams?.filter(t => t.isPlaceholder).length ?? 0) > 0, [phase.teams]);

    return useObserver(() => (
        <>
            <PageContent wide>
                <PageContent.Header>
                    <PageHeader
                        {...headerProps}
                        extra={
                            <Observer>
                                {() =>
                                    phase.schedule ? (
                                        <>
                                            {hasPlaceholders && (
                                                <Button onClick={() => setIsDrawDialogOpen(true)}>
                                                    {l("CompetitionDetails_DrawTeams")}
                                                </Button>
                                            )}
                                            <DropdownMenu
                                                menuItems={[
                                                    {
                                                        children: (
                                                            <>
                                                                <DeleteOutlined />{" "}
                                                                {l("CompetitionPhases_Details_DeleteSchedule")}
                                                            </>
                                                        ),
                                                        onClick: showDeleteScheduleConfirmation,
                                                    },
                                                ]}
                                            />
                                        </>
                                    ) : (
                                        <Button
                                            type="primary"
                                            disabled={phase?.teams && phase.teams.length < 2}
                                            onClick={() => setIsGenerateScheduleDialogOpen(true)}>
                                            {l("CompetitionDetails_GenerateSchedule")}
                                        </Button>
                                    )
                                }
                            </Observer>
                        }
                    />
                </PageContent.Header>
                <PageContent.Card>
                    <RoutedTabs<"schedule" | "teams" | "points">
                        tabs={[
                            {
                                key: "schedule",
                                label: l("CompetitionPhases_Details_Schedule"),
                                navigateTo: routes.competitionPhase.schedule({ phaseId: phase.id }),
                                routePattern: routes.competitionPhase.schedule.pattern,
                                content: phase.schedule && competition.sportsFields && (
                                    <TablePhaseSchedule phase={phase} sportsFields={competition.sportsFields} />
                                ),
                                disabled: phase && !phase.schedule,
                            },
                            {
                                key: "teams",
                                label: l("CompetitionPhases_Details_Teams"),
                                navigateTo: routes.competitionPhase.teams({ phaseId: phase.id }),
                                routePattern: routes.competitionPhase.teams.pattern,
                                content: competition?.teams && (
                                    <CompetitionPhaseTeams
                                        competition={competition}
                                        phase={phase}
                                        teamsInCompetition={competition.teams}
                                        onDeletePlaceholderTeamForPhase={showDeletePlaceholderForPhaseConfirmation}
                                    />
                                ),
                            },
                            {
                                key: "points",
                                label: l("CompetitionPhases_TablePointsAndOrder"),
                                navigateTo: routes.competitionPhase.tablePointsModifiers({ phaseId: phase.id }),
                                routePattern: routes.competitionPhase.tablePointsModifiers.pattern,
                                onClick: () => phase.sortTieBreakingOrder(),
                                content: (
                                    <>
                                        <TablePointsModifiers phase={phase} />
                                        <Spacing top />
                                        <TableOrderModifiers phase={phase} />
                                    </>
                                ),
                            },
                        ]}
                        defaultKey={phase.schedule ? "schedule" : "teams"}
                    />
                </PageContent.Card>
            </PageContent>
            {isGenerateScheduleDialogOpen && (
                <GeneratePhaseScheduleFormDialog
                    onFinish={onScheduleGenerate}
                    onClose={() => setIsGenerateScheduleDialogOpen(false)}
                />
            )}
            {isDrawDialogOpen && (
                <TableDrawDialog competition={competition} onClose={() => setIsDrawDialogOpen(false)} phase={phase} />
            )}
        </>
    ));
};

export default TablePhaseDetails;
