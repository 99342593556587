import mkCx from "@leancode/cx";
import { Button, Select as AntdSelect, Skeleton } from "antd";
import { Select, SelectProps } from "Components/Select";
import Spacing from "Components/Spacing";
import CompetitionReferee from "Domain/Competitions/CompetitionReferee";
import RefereesCreator from "Domain/Competitions/RefereesCreator";
import RefereeFormDialog from "DomainComponents/RefereeFormDialog";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef, useState } from "react";
import useRunInTask from "Utils/Hooks/useRunInTask";
import styles from "./styles.scss";

const cx = mkCx(styles);

type RefereesCreatorSelectProps = {
    competitionId: string;
    competitionReferees: CompetitionReferee[];

    saveInProgress?: boolean;

    onSave: (referees: CompetitionReferee[]) => void;
    onCancel: () => void;
} & SelectProps;

const RefereesCreatorSelect: React.FunctionComponent<RefereesCreatorSelectProps> = ({
    competitionId,
    competitionReferees,
    saveInProgress,
    onCancel,
    onSave,
    ...selectProps
}) => {
    const creator = useMemo(() => new RefereesCreator(competitionId, competitionReferees), [
        competitionId,
        competitionReferees,
    ]);

    const [fetchInProgress, runFetchInTask] = useRunInTask();

    const [showCreateNewRefereeDialog, setShowCreateNewRefereeDialog] = useState(false);
    const [searchPhrase, setSearchPhrase] = useState("");

    useEffect(() => {
        runFetchInTask(() => creator.fetchRefereesFromOtherCompetitions());
    }, [creator, runFetchInTask]);

    const selectRef = useRef<AntdSelect | null>(null);

    return useObserver(() => (
        <Skeleton loading={fetchInProgress} active={fetchInProgress} paragraph={{ rows: 1 }}>
            <Spacing childrenGutterY className={cx("container")}>
                <Select
                    onSearch={phrase => setSearchPhrase(phrase)}
                    searchValue={searchPhrase}
                    showArrow
                    allowClear
                    showAction={["focus", "click"]}
                    ref={selectRef}
                    value={creator.selectedRefereesPhoneNumbers}
                    onChange={(selectedPlayersIds: string[] | string) => {
                        creator.setSelectedRefereesPhoneNumbers(
                            typeof selectedPlayersIds === "string" ? [selectedPlayersIds] : selectedPlayersIds,
                        );

                        setSearchPhrase("");
                    }}
                    mode="multiple"
                    className={cx("select")}
                    createNewItemLabel={l("CompetitionDetails_Referees_Add_Select_AddNew")}
                    onCreate={() => setShowCreateNewRefereeDialog(true)}
                    selectOptions={creator.allReferees.map(r => ({
                        value: r.phoneNumber,
                        key: r.phoneNumber,
                        label: `${r.lastName} ${r.firstName}`,
                    }))}
                    {...selectProps}
                />
                <Spacing childrenGutterX>
                    <Button onClick={onCancel}>{l("Common_Cancel")}</Button>
                    <Button
                        loading={saveInProgress}
                        disabled={saveInProgress || creator.selectedRefereesPhoneNumbers.length === 0}
                        onClick={() => onSave(creator.selection)}
                        type="primary">
                        {l("Common_Add")}
                    </Button>
                </Spacing>
                {showCreateNewRefereeDialog && (
                    <RefereeFormDialog
                        initialValues={(() => {
                            const [firstName, ...splittedLastName] = searchPhrase.split(" ");

                            return {
                                firstName: firstName,
                                lastName: splittedLastName.join(" "),
                            };
                        })()}
                        onSave={async values => {
                            selectRef.current && selectRef.current.focus();

                            values &&
                                creator.addNewReferee(
                                    values.firstName,
                                    values.lastName,
                                    `${values.countryCode}${values.phoneNumber}`,
                                );
                            setSearchPhrase("");
                            setShowCreateNewRefereeDialog(false);

                            return undefined;
                        }}
                        onPhoneNumberUniquenessCheck={(countryCode, phoneNumber) =>
                            !creator.isPhoneNumberAlreadyInUse(`${countryCode}${phoneNumber}`)
                        }
                        onClose={() => setShowCreateNewRefereeDialog(false)}
                    />
                )}
            </Spacing>
        </Skeleton>
    ));
};

export default RefereesCreatorSelect;
