export const isValidInteger = (value: string) => {
    return Number.isInteger(parseFloat(value));
};

export const isValidPhoneNumber = (value: string) => /^\d{9}$/.test(value);

export const acceptedImageExtensions = ["jpg", "jpeg", "png", "gif", "apng"];
export const isValidImageFileExtension = (fileName: string) => {
    const extension = fileName.split(".").pop()?.toLowerCase();

    if (!acceptedImageExtensions.some(e => e === extension)) {
        return false;
    }

    return true;
};

export const moneyRegex = /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
