import { DatePicker, Form, Skeleton } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import { Select } from "Components/Select";
import { MatchStatusDTO } from "Contracts/PlayooLeagueClient";
import CompetitionSportsField from "Domain/Competitions/CompetitionSportsField";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import MatchTeamsFormItem from "DomainComponents/Matches/MatchTeamsFormItem";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import moment from "moment";
import React, { useCallback } from "react";
import { dateWithTimeFormat, timeFormat } from "Utils/formatting";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";
import MatchdayFormItem from "../FormItems/MatchdayFormItem";
import MatchStatusFormItem from "../MatchStatusFormItem";

export type TablePhaseMatchFormDialogProps = {
    initialValues?: Optional<FormFields, "team1Id" | "team2Id">;

    teams?: CompetitionTeam[];
    sportsFields?: CompetitionSportsField[];

    teamsEditionDisabled?: boolean;

    mode: "edit" | "create";

    onSave: (formFields: FormFields) => Promise<void>;
    onClose: () => void;
};

const TablePhaseMatchFormDialog: React.FunctionComponent<TablePhaseMatchFormDialogProps> = ({
    initialValues,
    teams,
    sportsFields,
    teamsEditionDisabled,
    mode,
    onSave,
    onClose,
}) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const onFinish = useCallback(
        (values: FormFields) => {
            runInTask(() => onSave(values));
        },
        [runInTask, onSave],
    );

    return useObserver(() => (
        <Modal
            title={
                mode === "create"
                    ? l("CompetitionPhases_Table_AddMatch_Form_Title")
                    : l("MatchDetails_EditMatch_Form_Title")
            }
            onCancel={onClose}
            okText={mode === "create" ? l("Common_Add") : l("Common_Save")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}
            okButtonProps={{
                loading: isRunning,
                disabled: isRunning,
            }}>
            {teams && sportsFields ? (
                <Form layout="vertical" form={form} onFinish={onFinish} initialValues={initialValues}>
                    <MatchTeamsFormItem
                        teams={teams}
                        teamsEditionDisabled={teamsEditionDisabled}
                        team1IdKey={guard<FormField>("team1Id")}
                        team2IdKey={guard<FormField>("team2Id")}
                    />
                    <Form.Item
                        name={guard<FormField>("date")}
                        trigger="onSelect"
                        label={l("CompetitionPhases_Table_Form_Date")}>
                        <DatePicker showTime={{ format: timeFormat }} format={dateWithTimeFormat} />
                    </Form.Item>
                    <Form.Item
                        name={guard<FormField>("sportsFieldId")}
                        label={l("CompetitionPhases_Table_Form_SportsField")}>
                        <Select
                            showSearch
                            showArrow
                            allowClear
                            selectOptions={sportsFields.map(sf => ({ label: sf.name, value: sf.id, key: sf.id }))}
                        />
                    </Form.Item>
                    <MatchdayFormItem
                        formItemKey={guard<FormField>("matchday")}
                        label={l("CompetitionPhases_Table_Form_Matchday")}
                    />
                    {mode === "edit" && initialValues && (
                        <MatchStatusFormItem
                            currentStatus={initialValues.status}
                            fieldName={guard<FormField>("status")}
                        />
                    )}
                </Form>
            ) : (
                <Skeleton active loading />
            )}
        </Modal>
    ));
};

export type FormFields = {
    team1Id: string;
    team2Id: string;
    date?: moment.Moment | null;
    sportsFieldId?: string;
    matchday: string;
    status: MatchStatusDTO;
};

type FormField = keyof FormFields;

export default TablePhaseMatchFormDialog;
