import { l } from "Languages";
import { message } from "antd";
import { useCallback } from "react";
import competitionStore from "Domain/Competitions";
import Competition from "Domain/Competitions/Competition";
import { Moment } from "moment";
import useRunInTask from "Utils/Hooks/useRunInTask";

export type CompetitionsFormState =
    | {
          mode: "edit" | "copy";
          competition: Competition;
      }
    | { mode: "create" | "off" };

export type FormFields = {
    name: string;
    seasonId?: string;
    groupId?: string;
    firstMatchDay?: Moment;
};

type ReturnedValues = {
    modal: {
        title: string;
        okText: string;
    };
    onFinish: (values: FormFields) => void;
    isSaving: boolean;
};

type OnFinishDependencies = {
    onClose?: (savedFormFields?: FormFields) => void;
    setNameError: (value: FormFields["name"], error: string) => void;
};

const useCompetitionModal = (
    formState: CompetitionsFormState,
    { onClose, setNameError }: OnFinishDependencies,
): ReturnedValues => {
    const [isSaving, runInTask] = useRunInTask();

    const competition = competitionFromFormState(formState);

    const onCreate = useCallback(
        (values: FormFields) => {
            runInTask(async () => {
                const result = await competitionStore.addCompetition(values);

                result
                    .handle("NameMissingOrEmpty", () => setNameError(values.name, l("Common_Validation_FieldRequired")))
                    .handle("NameTooLong", () => setNameError(values.name, l("Common_Validation_FieldTooLong")))
                    .handle(["CompetitionIdAlreadyInUse", "SeasonNotFound", "GroupNotFound", "failure"], () =>
                        message.error(l("Competitions_Create_Failure")),
                    )
                    .handle(["success"], () => {
                        message.success(l("Competitions_Create_Success"));
                        onClose?.(values);
                    })
                    .check();
            });
        },
        [onClose, runInTask, setNameError],
    );

    const onEdit = useCallback(
        (values: FormFields) => {
            runInTask(async () => {
                if (competition) {
                    const result = await competition.edit(values);

                    result
                        .handle("NameMissingOrEmpty", () =>
                            setNameError(values.name, l("Common_Validation_FieldRequired")),
                        )
                        .handle("NameTooLong", () => setNameError(values.name, l("Common_Validation_FieldTooLong")))
                        .handle(["CompetitionNotFound", "SeasonNotFound", "GroupNotFound", "failure"], () =>
                            message.error(l("Competitions_Edit_Failure")),
                        )
                        .handle(["success"], () => {
                            message.success(l("Competitions_Edit_Success"));
                            onClose?.(values);
                        })
                        .check();
                }
            });
        },
        [competition, onClose, runInTask, setNameError],
    );

    const onCopy = useCallback(
        (values: FormFields) => {
            runInTask(async () => {
                if (values.firstMatchDay && competition) {
                    const result = await competition.copy({ ...values, firstMatchDay: values.firstMatchDay });

                    result
                        .handle("NameMissingOrEmpty", () =>
                            setNameError(values.name, l("Common_Validation_FieldRequired")),
                        )
                        .handle("NameTooLong", () => setNameError(values.name, l("Common_Validation_FieldTooLong")))
                        .handle(
                            [
                                "CompetitionIdAlreadyInUse",
                                "SeasonNotFound",
                                "GroupNotFound",
                                "CompetitionToCopyFromNotFound",
                                "failure",
                            ],
                            () => message.error(l("Competitions_Create_Failure")),
                        )
                        .handle(["success"], () => {
                            message.success(l("Competitions_Create_Success"));
                            onClose?.(values);
                        })
                        .check();
                } else {
                    message.error(l("Competitions_Create_Failure"));
                }
            });
        },
        [competition, onClose, runInTask, setNameError],
    );

    switch (formState.mode) {
        case "create":
            return {
                modal: {
                    title: l("Competitions_Create_Title"),
                    okText: l("Common_Add"),
                },
                onFinish: onCreate,
                isSaving,
            };
        case "edit":
            return {
                modal: {
                    title: l("Competitions_Edit_Title"),
                    okText: l("Common_Save"),
                },
                onFinish: onEdit,
                isSaving,
            };
        case "copy":
            return {
                modal: {
                    title: l("Competitions_Copy_Title"),
                    okText: l("Competitions_Copy_Ok"),
                },
                onFinish: onCopy,
                isSaving,
            };
        default:
            return {
                modal: {
                    title: "",
                    okText: "",
                },
                onFinish: () => {},
                isSaving,
            };
    }
};

export const competitionFromFormState = (formState: CompetitionsFormState) =>
    formState.mode === "edit" || formState.mode === "copy" ? formState.competition : undefined;

export default useCompetitionModal;
