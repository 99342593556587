import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { message, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import DropdownMenu from "Components/DropdownMenu";
import EmptyState from "Components/EmptyState";
import PageContent from "Components/PageContent";
import Competition from "Domain/Competitions/Competition";
import CompetitionSportsField from "Domain/Competitions/CompetitionSportsField";
import { l } from "Languages";
import { Observer, useObserver } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import useRunInTask from "Utils/Hooks/useRunInTask";
import CompetitionSportsFieldFormDialog from "./CompetitionSportsFieldFormDialog";
import styles from "./styles.scss";

const cx = mkCx(styles);

type CompetitionSportsFieldsTableProps = {
    competition: Competition;
};

type FormState =
    | {
          mode: "edit";
          sportsField: CompetitionSportsField;
      }
    | { mode: "create" | "off" };

const CompetitionSportsFieldsTable: React.FunctionComponent<CompetitionSportsFieldsTableProps> = ({ competition }) => {
    const [formState, setFormState] = useState<FormState>({ mode: "off" });
    const [isSaveInProgress, runSaveInTask] = useRunInTask();

    const addSportsField = useCallback(
        (name: string) =>
            runSaveInTask(async () => {
                const result = await competition.addSportsField(name);

                result
                    .handle(
                        [
                            "CompetitionNotFound",
                            "OneOfSportsFieldsIdAlreadyInUse",
                            "OneOfSportsFieldsNameMissingOrEmpty",
                            "OneOfSportsFieldsNameTooLong",
                            "SportsFieldsDeletionNotSupported",
                            "SportsFieldsMissingOrEmpty",
                            "failure",
                        ],
                        () => {
                            message.error(l("CompetitionDetails_SportsFields_Create_Failure"));
                        },
                    )
                    .handle("success", () => {
                        message.success(l("CompetitionDetails_SportsFields_Create_Success"));

                        setFormState({ mode: "off" });
                    })
                    .check();
            }),
        [competition, runSaveInTask],
    );

    const editSportsFieldName = useCallback(
        (sportsFieldId: string, name: string) =>
            runSaveInTask(async () => {
                const result = await competition.editSportsFieldName(sportsFieldId, name);

                result
                    .handle(
                        [
                            "CompetitionNotFound",
                            "OneOfSportsFieldsIdAlreadyInUse",
                            "OneOfSportsFieldsNameMissingOrEmpty",
                            "OneOfSportsFieldsNameTooLong",
                            "SportsFieldsDeletionNotSupported",
                            "SportsFieldsMissingOrEmpty",
                            "failure",
                        ],
                        () => {
                            message.error(l("CompetitionDetails_SportsFields_Edit_Failure"));
                        },
                    )
                    .handle("success", () => {
                        message.success(l("CompetitionDetails_SportsFields_Edit_Success"));

                        setFormState({ mode: "off" });
                    })
                    .check();
            }),
        [competition, runSaveInTask],
    );

    const columns = useMemo(
        (): ColumnProps<CompetitionSportsField>[] => [
            {
                title: l("Common_Ordinal"),
                render: (_, sportsField, ind) => ind + 1,
            },
            {
                title: "Nazwa",
                render: (_, sportsField) => <Observer>{() => <>{sportsField.name}</>}</Observer>,
                className: cx("name-column"),
            },
            {
                title: l("Common_Actions"),
                render: (_, sportsField) => (
                    <DropdownMenu
                        menuItems={[
                            {
                                children: (
                                    <>
                                        <EditOutlined /> {l("CompetitionDetails_SportsFields_Edit")}
                                    </>
                                ),
                                onClick: () => setFormState({ mode: "edit", sportsField: sportsField }),
                            },
                        ]}
                    />
                ),
            },
        ],
        [],
    );

    return useObserver(() => (
        <div>
            <Table
                rowKey={sf => sf.id}
                dataSource={competition.sportsFields}
                columns={columns}
                pagination={false}
                locale={{
                    emptyText: <EmptyState text={l("CompetitionDetails_SportsFields_EmptyState")} />,
                }}
            />
            <PageContent.ActionButton onClick={() => setFormState({ mode: "create" })}>
                <PlusOutlined /> {l("CompetitionDetails_SportsFields_Create")}
            </PageContent.ActionButton>
            {formState.mode === "create" && (
                <CompetitionSportsFieldFormDialog
                    mode="create"
                    onClose={() => setFormState({ mode: "off" })}
                    saveInProgress={isSaveInProgress}
                    onFinish={addSportsField}
                />
            )}
            {formState.mode === "edit" && (
                <CompetitionSportsFieldFormDialog
                    initialValues={{
                        name: formState.sportsField.name,
                    }}
                    mode="edit"
                    onClose={() => setFormState({ mode: "off" })}
                    saveInProgress={isSaveInProgress}
                    onFinish={name => editSportsFieldName(formState.sportsField.id, name)}
                />
            )}
        </div>
    ));
};

export default CompetitionSportsFieldsTable;
