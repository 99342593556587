class MathHelpers {
    static lowerPowerOfTwo(value: number) {
        return Math.pow(2, Math.floor(Math.log2(value)));
    }

    static isPowerOfTwo(value: number) {
        return Math.log2(value) % 1 === 0;
    }
}

export default MathHelpers;
