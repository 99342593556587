import { CustomPhaseMatchDTO, MatchStatusDTO, PhaseTypeDTO } from "Contracts/PlayooLeagueClient";
import { CompetitionTeamStore } from "Domain/Competitions/CompetitionTeamStore";
import MatchResult from "Domain/Matches/MatchResult";
import { action, observable } from "mobx";
import moment from "moment";
import { dateTimeOffsetFromDTOOptional } from "Utils/DTO";
import MatchBase, { MatchInit } from "../../Matches/MatchBase";

class CustomPhaseMatch extends MatchBase {
    readonly phaseType = PhaseTypeDTO.Custom;

    @observable name?: string;
    @observable matchGroupId?: string;

    get isDeletable(): boolean {
        return true;
    }

    constructor(id: string, phaseId: string, init: CustomPhaseMatchInit, competitionTeamStore: CompetitionTeamStore) {
        super(id, phaseId, init, competitionTeamStore);

        this.name = init.name;
        this.matchGroupId = init.matchGroupId;
    }

    @action.bound
    setMatchGroup(matchGroupId: string | undefined) {
        this.matchGroupId = matchGroupId;
    }

    @action.bound
    updateFromDTO(dto: CustomPhaseMatchDTO) {
        this.name = dto.Name ?? undefined;
        this.matchGroupId = dto.LocalGroupId ?? undefined;
        this.date = dateTimeOffsetFromDTOOptional(dto.Date);
        this.team1Id = dto.Team1Id ?? undefined;
        this.team2Id = dto.Team2Id ?? undefined;
        this.status = dto.Status;
        this.result = dto.Result ? MatchResult.fromDTO(dto.Result) : undefined;
        this.sportsFieldId = dto.SportsFieldId ?? undefined;
    }

    @action.bound
    update({ team1Id, team2Id, name, matchGroupId, date, sportsFieldId, status }: CustomPhaseMatchEditData) {
        this.team1Id = team1Id;
        this.team2Id = team2Id;
        this.name = name;
        this.matchGroupId = matchGroupId;
        this.date = date;
        this.sportsFieldId = sportsFieldId;
        this.status = status;
    }
}

type CustomPhaseMatchInit = MatchInit & Pick<CustomPhaseMatch, "matchGroupId" | "name">;

export type CustomPhaseMatchEditData = {
    team1Id: string;
    team2Id: string;
    name?: string;
    matchGroupId?: string;
    sportsFieldId?: string;
    date?: moment.Moment;
    status: MatchStatusDTO;
};

export default CustomPhaseMatch;
