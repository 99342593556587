import { action, computed, observable, runInAction } from "mobx";
import api from "Services/Api";
import retryQuery from "Utils/retryQuery";
import CompetitionReferee from "./CompetitionReferee";

class RefereesCreator {
    @observable private readonly competitionReferees: CompetitionReferee[];
    @observable private readonly competitionId: string;

    @observable refereesFromOtherCompetitions?: CompetitionReferee[];
    @observable newReferees: CompetitionReferee[] = [];

    @observable selectedRefereesPhoneNumbers: string[] = [];

    constructor(competitionId: string, competitionReferees: CompetitionReferee[]) {
        this.competitionId = competitionId;
        this.competitionReferees = competitionReferees;
    }

    async fetchRefereesFromOtherCompetitions() {
        const referees = await retryQuery(() =>
            api.refereesAutocompleteData({
                CompetitionId: this.competitionId,
            }),
        );

        runInAction(() => {
            this.refereesFromOtherCompetitions = referees.map(CompetitionReferee.fromDTO);
        });
    }

    @computed get allReferees() {
        return [...(this.refereesFromOtherCompetitions ?? []), ...this.newReferees];
    }

    @action.bound
    setSelectedRefereesPhoneNumbers(refereesPhoneNumbers: string[]) {
        this.selectedRefereesPhoneNumbers = refereesPhoneNumbers;
    }

    @action.bound
    selectReferee(phoneNumber: string) {
        this.selectedRefereesPhoneNumbers = [...this.selectedRefereesPhoneNumbers, phoneNumber];
    }

    @action.bound
    addNewReferee(firstName: string, lastName: string, phoneNumber: string) {
        if (this.isPhoneNumberAlreadyInUse(phoneNumber)) {
            throw new Error("Cannot add two referees with same phone number.");
        }

        const referee = new CompetitionReferee({
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
        });

        this.newReferees = [...this.newReferees, referee];
        this.selectReferee(referee.phoneNumber);

        return referee;
    }

    isPhoneNumberAlreadyInUse(phoneNumber: string) {
        return (
            this.allReferees.some(r => r.phoneNumber === phoneNumber) ||
            this.competitionReferees.some(r => r.phoneNumber === phoneNumber)
        );
    }

    @computed get selection(): CompetitionReferee[] {
        const selectedRefereesPhoneNumbers = new Set(this.selectedRefereesPhoneNumbers);

        return this.allReferees.filter(r => selectedRefereesPhoneNumbers.has(r.phoneNumber));
    }
}

export default RefereesCreator;
