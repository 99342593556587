import { DeleteOutlined } from "@ant-design/icons";
import { Button, PageHeader } from "antd";
import { PageHeaderProps } from "antd/lib/page-header";
import DropdownMenu from "Components/DropdownMenu";
import PageContent from "Components/PageContent";
import RoutedTabs from "Components/RoutedTabs";
import {
    isLinkablePhase,
    LinkablePhase,
    linkablePhases,
} from "Domain/CompetitionPhases/ExternalReferences/ExternalReference";
import KnockoutPhase from "Domain/CompetitionPhases/KnockoutPhase/KnockoutPhase";
import KnockoutPhaseTeamsConfigurator from "Domain/CompetitionPhases/KnockoutPhase/KnockoutPhaseTeamsConfigurator";
import Competition from "Domain/Competitions/Competition";
import { l } from "Languages";
import { observer, Observer } from "mobx-react-lite";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import routes from "Router/routes";
import CompetitionPhaseTeams from "../CompetitionPhaseTeams";
import usePlaceholderTeamForPhaseDeletion from "../usePlaceholderTeamForPhaseDeletion";
import useScheduleDeletion from "../useScheduleDeletion";
import KnockoutPhaseSchedule from "./KnockoutPhaseSchedule";

type KnockoutPhaseDetailsProps = {
    phase: KnockoutPhase;
    competition: Competition;

    headerProps: PageHeaderProps;
};

const KnockoutPhaseDetails: FunctionComponent<KnockoutPhaseDetailsProps> = observer(
    ({ phase, competition, headerProps }) => {
        const { push } = useHistory();

        const showDeleteScheduleConfirmation = useScheduleDeletion(phase);
        const showDeletePlaceholderForPhaseConfirmation = usePlaceholderTeamForPhaseDeletion(competition, phase.id);

        const [phaseConfigurator, setPhaseConfigurator] = useState<KnockoutPhaseTeamsConfigurator>();

        const linkedPhase = useMemo(() => {
            return phase.linkedPhaseId ? competition.phases?.find(p => p.id === phase.linkedPhaseId) : undefined;
        }, [competition.phases, phase.linkedPhaseId]);

        const linkedPhaseDetailsFetched = linkedPhase?.hasDetails;

        useEffect(() => {
            if (linkedPhase && !linkedPhaseDetailsFetched && linkablePhases.includes(linkedPhase.type)) {
                linkedPhase.fetchDetails();
            }
        }, [phase.linkedPhaseId, linkedPhase, linkedPhaseDetailsFetched]);

        useEffect(() => {
            let newConfigurator: KnockoutPhaseTeamsConfigurator | undefined = undefined;
            if (!phase.linkedPhaseId) {
                newConfigurator = new KnockoutPhaseTeamsConfigurator(phase, competition);
            }

            if (phase.linkedPhaseId && linkedPhase && isLinkablePhase(linkedPhase) && linkedPhaseDetailsFetched) {
                newConfigurator = new KnockoutPhaseTeamsConfigurator(phase, competition, linkedPhase as LinkablePhase);
            }

            if (newConfigurator) {
                setPhaseConfigurator(newConfigurator);
            }
        }, [competition, phase, linkedPhase, linkedPhaseDetailsFetched]);

        return (
            <>
                <PageContent wide>
                    <PageContent.Header>
                        <PageHeader
                            {...headerProps}
                            extra={
                                <Observer>
                                    {() =>
                                        phase.schedule ? (
                                            <DropdownMenu
                                                menuItems={[
                                                    {
                                                        children: (
                                                            <>
                                                                <DeleteOutlined />{" "}
                                                                {l("CompetitionPhases_Details_DeleteSchedule")}
                                                            </>
                                                        ),
                                                        onClick: showDeleteScheduleConfirmation,
                                                    },
                                                ]}
                                            />
                                        ) : (
                                            <Button
                                                type="primary"
                                                disabled={phase?.teams && phase.teams.length < 2}
                                                onClick={() =>
                                                    push(
                                                        routes.competitionPhase.configureKnockoutPhaseFirstStage({
                                                            phaseId: phase.id,
                                                        }),
                                                    )
                                                }>
                                                {l("CompetitionPhases_Knockout_GenerateSchedule")}
                                            </Button>
                                        )
                                    }
                                </Observer>
                            }
                        />
                    </PageContent.Header>
                    <PageContent.Card>
                        <RoutedTabs<"schedule" | "teams">
                            tabs={[
                                {
                                    key: "schedule",
                                    label: l("CompetitionPhases_Details_Schedule"),
                                    navigateTo: routes.competitionPhase.schedule({ phaseId: phase.id }),
                                    routePattern: routes.competitionPhase.schedule.pattern,
                                    content: phase.schedule && competition.sportsFields && (
                                        <KnockoutPhaseSchedule phase={phase} sportsFields={competition.sportsFields} />
                                    ),
                                    disabled: !phase.schedule,
                                },
                                {
                                    key: "teams",
                                    label: l("CompetitionPhases_Details_Teams"),
                                    navigateTo: routes.competitionPhase.teams({ phaseId: phase.id }),
                                    routePattern: routes.competitionPhase.teams.pattern,
                                    content: competition?.teams && (
                                        <CompetitionPhaseTeams
                                            competition={competition}
                                            phaseConfigurator={phaseConfigurator}
                                            phase={phase}
                                            teamsInCompetition={competition.teams}
                                            onDeletePlaceholderTeamForPhase={showDeletePlaceholderForPhaseConfirmation}
                                        />
                                    ),
                                },
                            ]}
                            defaultKey={phase.schedule ? "schedule" : "teams"}
                        />
                    </PageContent.Card>
                </PageContent>
            </>
        );
    },
);

export default KnockoutPhaseDetails;
