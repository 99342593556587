import { Typography } from "antd";
import Spacing from "Components/Spacing";
import React from "react";

type SectionProps = {
    title: React.ReactNode;

    className?: string;
};

const Section: React.FunctionComponent<SectionProps> = ({ title, children, className }) => {
    return (
        <Spacing childrenGutterY className={className}>
            <Typography.Title level={4}>{title}</Typography.Title>
            {children}
        </Spacing>
    );
};

export default Section;
