import { PlusOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Button, Select as AntdSelect, Skeleton, Tag } from "antd";
import { Select, SelectProps } from "Components/Select";
import Spacing from "Components/Spacing";
import externalReferencesStore from "Domain/CompetitionPhases/ExternalReferences";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import CompetitionTeamsCreator, { CompetitionTeamsSelectionResult } from "Domain/Competitions/CompetitionTeamsCreator";
import CompetitionTeamFormDialog from "DomainComponents/CompetitionTeamFormDialog";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useRunInTask from "Utils/Hooks/useRunInTask";
import styles from "./styles.scss";

const { OptGroup, Option } = AntdSelect;

const cx = mkCx(styles);

type CompetitionTeamsCreatorSelectProps = {
    competitionId: string;
    withPlaceholderTeams?: boolean;
    saveText: string;

    saveInProgress?: boolean;

    onSave: (selection: CompetitionTeamsSelectionResult) => void;
    onCancel: () => void;
} & SelectProps;

const CompetitionTeamsCreatorSelect: React.FunctionComponent<CompetitionTeamsCreatorSelectProps> = ({
    competitionId,
    saveInProgress,
    withPlaceholderTeams,
    saveText,
    onCancel,
    onSave,
    ...selectProps
}) => {
    const creator = useMemo(() => new CompetitionTeamsCreator(externalReferencesStore), []);
    const [fetchInProgress, runFetchInTask] = useRunInTask();

    const [showCreateNewTeamDialog, setShowCreateNewTeamDialog] = useState(false);
    const [searchPhrase, setSearchPhrase] = useState("");

    useEffect(() => {
        runFetchInTask(() => creator.fetchExistingTeamsAddableToCompetition(competitionId));
    }, [competitionId, creator, runFetchInTask]);

    const selectRef = useRef<AntdSelect | null>(null);

    const getSearchPhraseForTeam = useCallback(
        (team: CompetitionTeam) => team.displayName + (team.ageGroup ? ` ${team.ageGroup}` : ""),
        [],
    );

    return useObserver(() => (
        <Skeleton loading={fetchInProgress} active={fetchInProgress} paragraph={{ rows: 1 }}>
            <Spacing childrenGutterY className={cx("container")}>
                <Select
                    optionFilterProp="searchField" // HACK: filterOption doesn't work correctly with OptGroups, so we use Option's searchField prop for filtering
                    tagRender={({ label, value, onClose }) => {
                        const isPlaceholder = creator.placeholderTeamsIds.some(id => id === value);

                        return (
                            <Tag
                                color={isPlaceholder ? "warning" : undefined}
                                closable
                                onClose={onClose}
                                className={cx("select-tag")}>
                                {label}
                            </Tag>
                        );
                    }}
                    onSearch={phrase => setSearchPhrase(phrase)}
                    searchValue={searchPhrase}
                    showArrow
                    allowClear
                    showAction={["focus", "click"]}
                    ref={selectRef}
                    value={creator.selectedTeamsIds}
                    onChange={(selectedTeamsIds: string[] | string) => {
                        creator.setSelectedTeamsIds(
                            typeof selectedTeamsIds === "string" ? [selectedTeamsIds] : selectedTeamsIds,
                        );

                        setSearchPhrase("");
                    }}
                    mode="multiple"
                    className={cx("select")}
                    createNewItemLabel={l("CompetitionTeams_Select_AddNewTeam")}
                    onCreate={() => setShowCreateNewTeamDialog(true)}
                    additionalOptions={
                        withPlaceholderTeams
                            ? [
                                  {
                                      content: (
                                          <>
                                              <PlusOutlined /> {l("CompetitionTeams_Select_AddPlaceholderTeam")}
                                          </>
                                      ),
                                      onClick: creator.addPlaceholderTeam,
                                  },
                              ]
                            : undefined
                    }
                    {...selectProps}>
                    {(creator.newTeams.length > 0 || creator.placeholderTeamsIds.length > 0) && (
                        <OptGroup label={l("CompetitionTeams_Select_NewTeams")}>
                            {creator.newTeams.map(t => (
                                <Option value={t.id} key={t.id} searchField={getSearchPhraseForTeam(t)}>
                                    <TeamOptionContent team={t} />
                                </Option>
                            ))}
                            {creator.placeholderTeamsIds.map(id => (
                                <Option value={id} key={id} searchField={l("CompetitionDetails_Teams_PlaceholderTeam")}>
                                    {l("CompetitionDetails_Teams_PlaceholderTeam")}
                                </Option>
                            ))}
                        </OptGroup>
                    )}
                    {creator.previousSeason && creator.previousSeason.teams.length > 0 && (
                        <OptGroup label={creator.previousSeason.name}>
                            {creator.previousSeason.teams.map(t => (
                                <Option value={t.id} key={t.id} searchField={getSearchPhraseForTeam(t)}>
                                    <TeamOptionContent team={t} />
                                </Option>
                            ))}
                        </OptGroup>
                    )}
                    {creator.otherTeams && creator.otherTeams.length > 0 && (
                        <OptGroup label={l("CompetitionTeams_Select_OtherTeams")}>
                            {creator.otherTeams.map(t => (
                                <Option value={t.id} key={t.id} searchField={getSearchPhraseForTeam(t)}>
                                    <TeamOptionContent team={t} />
                                </Option>
                            ))}
                        </OptGroup>
                    )}
                </Select>
                <Spacing childrenGutterX>
                    <Button onClick={onCancel}>{l("Common_Cancel")}</Button>
                    <Button
                        loading={saveInProgress}
                        disabled={saveInProgress || creator.selectedTeamsIds.length === 0}
                        onClick={() => onSave(creator.selection)}
                        type="primary">
                        {saveText}
                    </Button>
                </Spacing>
                {showCreateNewTeamDialog && (
                    <CompetitionTeamFormDialog
                        initialValues={{
                            name: searchPhrase,
                        }}
                        onSave={async values => {
                            selectRef.current && selectRef.current.focus();

                            values && creator.addNewTeam(values.name, values.shortName, values.ageGroup);
                            setSearchPhrase("");
                            setShowCreateNewTeamDialog(false);

                            return undefined;
                        }}
                        onClose={() => setShowCreateNewTeamDialog(false)}
                    />
                )}
            </Spacing>
        </Skeleton>
    ));
};

export const TeamOptionContent: React.FunctionComponent<{ team: CompetitionTeam }> = ({ team }) => {
    return (
        <>
            {team.displayName} {team.ageGroup && <Tag>{team.ageGroup}</Tag>}
        </>
    );
};

export default CompetitionTeamsCreatorSelect;
