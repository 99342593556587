import { PlusOutlined } from "@ant-design/icons";
import { message, Tag, Tooltip } from "antd";
import EditableLabel from "Components/EditableLabel";
import PageContent from "Components/PageContent";
import ScheduleTableTags from "Components/ScheduleTableTags";
import Spacing from "Components/Spacing";
import GroupsPhase from "Domain/CompetitionPhases/GroupsPhase/GroupsPhase";
import GroupsPhaseMatch from "Domain/CompetitionPhases/GroupsPhase/GroupsPhaseMatch";
import CompetitionSportsField from "Domain/Competitions/CompetitionSportsField";
import GroupsPhaseMatchFormDialog, { FormFields } from "DomainComponents/Matches/GroupsPhaseMatchFormDialog";
import MatchdayFormDialog from "DomainComponents/Matches/MatchdayFormDialog";
import ScheduleTable from "DomainComponents/ScheduleTable";
import { l } from "Languages";
import { Observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import GroupsPhaseScheduleActions from "./GroupsPhaseScheduleActions";

type GroupsPhaseScheduleProps = {
    phase: GroupsPhase;
    sportsFields: CompetitionSportsField[];
};

const GroupsPhaseSchedule: React.FunctionComponent<GroupsPhaseScheduleProps> = ({ phase, sportsFields }) => {
    const [showAddMatchDialog, setShowAddMatchDialog] = useState(false);
    const [matchToUpdateMatchday, setMatchToUpdateMatchday] = useState<GroupsPhaseMatch>();

    const addMatch = useCallback(
        async (values: FormFields) => {
            const result = await phase.addMatch({
                groupId: values.groupId,
                matchday: parseInt(values.matchday),
                team1Id: values.team1Id,
                team2Id: values.team2Id,
                date: values.date ?? undefined,
                sportsFieldId: values.sportsFieldId,
            });

            result
                .handle("success", () => {
                    message.success(l("CompetitionPhases_Groups_AddMatch_Success"));

                    setShowAddMatchDialog(false);
                })
                .handle(
                    [
                        "GroupNotFound",
                        "TeamNotFoundInGroup",
                        "PhaseNotFoundOrNotAGroupsPhase",
                        "TeamCannotPlayAgainstItself",
                        "MatchdayNotPositive",
                        "SportsFieldNotFound",
                        "NameTooLong",
                        "failure",
                    ],
                    () => {
                        message.error(l("CompetitionPhases_Groups_AddMatch_Failure"));
                    },
                )
                .check();
        },
        [phase],
    );

    return (
        <>
            <Spacing childrenGutterY>
                <GroupsPhaseScheduleActions phase={phase} />
                <ScheduleTable
                    matches={phase.schedule}
                    phase={phase}
                    sportsFields={sportsFields}
                    phaseSpecificColumnProps={{
                        render: (_, match) => {
                            return (
                                <Observer>
                                    {() => (
                                        <ScheduleTableTags>
                                            <EditableLabel
                                                onClick={e => {
                                                    setMatchToUpdateMatchday(match);
                                                    e.stopPropagation();
                                                }}>
                                                <Tag>
                                                    {l("CompetitionPhases_Details_Schedule_Matchday", match.matchday)}
                                                </Tag>
                                            </EditableLabel>
                                            <Tooltip title={match.groupName}>
                                                <Tag>{match.groupName}</Tag>
                                            </Tooltip>
                                        </ScheduleTableTags>
                                    )}
                                </Observer>
                            );
                        },
                    }}
                    onScheduleChange={() => phase.fetchDetails()}
                    emptyText={l("CompetitionPhases_Details_Schedule_EmptyState_GroupsPhase")}
                />
            </Spacing>
            {phase.schedule && (
                <PageContent.ActionButton onClick={() => setShowAddMatchDialog(true)}>
                    <PlusOutlined /> {l("CompetitionPhases_Groups_AddMatch")}
                </PageContent.ActionButton>
            )}
            {matchToUpdateMatchday && (
                <MatchdayFormDialog
                    match={matchToUpdateMatchday}
                    onClose={savedFormFields => {
                        if (savedFormFields) {
                            phase.fetchDetails();
                        }

                        setMatchToUpdateMatchday(undefined);
                    }}
                />
            )}
            {showAddMatchDialog && (
                <GroupsPhaseMatchFormDialog
                    phase={phase}
                    sportsFields={sportsFields}
                    onClose={() => setShowAddMatchDialog(false)}
                    mode="create"
                    onSave={addMatch}
                />
            )}
        </>
    );
};

export default GroupsPhaseSchedule;
