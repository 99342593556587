import { Skeleton } from "antd";
import PageContent from "Components/PageContent";
import { SeasonStateDTO } from "Contracts/PlayooLeagueClient";
import seasonStore from "Domain/Season";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import routes from "../routes";

const DefaultRedirect: React.FunctionComponent = () => {
    const { replace } = useHistory();

    useEffect(() => {
        const redirectToOngoingOrNewestSeason = async () => {
            const seasons = await seasonStore.fetchSeasons();

            const ongoingSeason = seasons.find(s => s.state === SeasonStateDTO.Ongoing);
            const seasonToRedirect = ongoingSeason ?? seasons[0];

            replace(routes.competitions({ seasonId: seasonToRedirect?.id }));
        };

        redirectToOngoingOrNewestSeason();
    });

    return (
        <PageContent>
            <PageContent.Card>
                <Skeleton active={true} loading={true} />
            </PageContent.Card>
        </PageContent>
    );
};

export default DefaultRedirect;
