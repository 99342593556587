import { EllipsisOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { DropDownProps } from "antd/lib/dropdown";
import { MenuItemProps } from "antd/lib/menu/MenuItem";
import IconButton from "Components/IconButton";
import React from "react";

type DropdownMenuProps = {
    menuItems: (MenuItemProps & { key?: number | string })[];

    icon?: React.ReactNode;
} & Partial<DropDownProps>;

const DropdownMenu: React.FunctionComponent<DropdownMenuProps> = ({ menuItems, icon, ...dropdownProps }) => {
    return (
        <Dropdown
            overlay={
                <Menu>
                    {menuItems.map(({ key, ...itemProps }, ind) => (
                        <Menu.Item {...itemProps} key={key ?? ind} />
                    ))}
                </Menu>
            }
            trigger={["click"]}
            {...dropdownProps}>
            <IconButton>{icon ?? <EllipsisOutlined />}</IconButton>
        </Dropdown>
    );
};

export default DropdownMenu;
