export default function definedOrThrow<T>(val: T | undefined): T {
    if (val === undefined) {
        throw new Error("Value cannot be undefined");
    }

    return val;
}

export function notEmptyOrThrow<T>(val: T | undefined | null): T {
    if (val === undefined || val === null) {
        throw new Error("Value cannot be empty");
    }

    return val;
}

export function assertDefined<T>(x: T | undefined): asserts x is T {
    console.assert(x !== undefined);
}
