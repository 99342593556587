import { PlusOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Button, TimePicker } from "antd";
import EditableLabel from "Components/EditableLabel";
import ExtendBoardColumn from "Components/ExtendBoardColumn";
import Spacing from "Components/Spacing";
import { CompetitionPhase } from "Domain/CompetitionPhases/CompetitionPhase";
import PlannerFilter from "Domain/CompetitionPhases/SchedulePlanner/Filters/PlannerFilter";
import SeasonPlannerFilter from "Domain/CompetitionPhases/SchedulePlanner/Filters/SeasonPlannerFilters";
import { MatchConflicts, SportsFieldSchedule } from "Domain/CompetitionPhases/SchedulePlanner/MatchdayConfiguration";
import { isBreak, isMatch } from "Domain/CompetitionPhases/SchedulePlanner/Slot";
import { AvailableSportsFieldsConfiguration } from "Domain/CompetitionPhases/SchedulePlanner/SportsFieldConfiguration";
import CompetitionSportsField from "Domain/Competitions/CompetitionSportsField";
import { Match } from "Domain/Matches/Match";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { timeFormat } from "Utils/formatting";
import DroppableColumn from "../../../Components/DroppableColumn";
import AddBreakFormDialog from "./AddBreakFormDialog";
import AutomaticScheduleGenerationFormDialog from "./AutomaticScheduleGenerationFormDialog";
import BreakItemDraggable from "./BreakItemDraggable";
import MatchItemDraggable from "./MatchItemDraggable";
import SportsFieldNameFormDialog from "./SportsFieldNameFormDialog";
import styles from "./styles.scss";
import UnassignedMatchesFilter from "./UnassignedMatchesFilter";

const cx = mkCx(styles);

const unassignedMatchesKey = "unassigned";

type SchedulePlannerBoardProps = {
    unassignedMatches: Match[];
    schedulePlan: SportsFieldSchedule[];
    sportsFields: CompetitionSportsField[];
    canPlanScheduleAutomatically: boolean;
    matchConflicts: MatchConflicts;

    phases?: CompetitionPhase[];

    filter?: PlannerFilter | SeasonPlannerFilter;

    onChange: (matchId: string, destination: { sportsFieldId?: string; index: number }) => void;
    onAddSportsField: () => void;
    onSportsFieldStartTimeChange: (sportsFieldId: string, startTime: moment.Moment) => void;
    onAddBreak: (sportsFieldId: string, breakDuration: number, insertAfterIndex?: number) => void;
    onRemoveBreak: (breakId: string) => void;
    onPlanScheduleAutomatically: (configuration: AvailableSportsFieldsConfiguration) => void;
};

const SchedulePlannerBoard: React.FunctionComponent<SchedulePlannerBoardProps> = ({
    unassignedMatches,
    schedulePlan,
    sportsFields,
    canPlanScheduleAutomatically,
    matchConflicts,
    phases,
    filter,
    onChange,
    onAddSportsField,
    onSportsFieldStartTimeChange,
    onAddBreak,
    onRemoveBreak,
    onPlanScheduleAutomatically,
}) => {
    const [sportsFieldToEditName, setSportsFieldToEditName] = useState<CompetitionSportsField>();
    const [sportsFieldToAddBreak, setSportsFieldToAddBreak] = useState<CompetitionSportsField>();
    const [showAutomaticScheduleGenerationDialog, setShowAutomaticScheduleGenerationDialog] = useState(false);

    const [hoveredTeamId, setHoveredTeamId] = useState<string>();
    const [hoveredMatchId, setHoveredMatchId] = useState<string>();

    const onDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) {
                return;
            }

            const sportsFieldId =
                result.destination.droppableId === unassignedMatchesKey ? undefined : result.destination.droppableId;

            onChange(result.draggableId, { sportsFieldId, index: result.destination.index });
        },
        [onChange],
    );

    const planScheduleAutomatically = useCallback(() => {
        if (sportsFields.length > 1) {
            setShowAutomaticScheduleGenerationDialog(true);
        } else {
            onPlanScheduleAutomatically(new Map(sportsFields.map(sf => [sf.id, { closingTime: undefined }])));
        }
    }, [onPlanScheduleAutomatically, sportsFields]);

    return useObserver(() => (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className={cx("columns")}>
                    <DroppableColumn
                        headerClassName={cx("unassigned-matches-column-header")}
                        columnId={unassignedMatchesKey}
                        label={
                            <Spacing childrenGutterY className={cx("unassigned-matches-column-header-content")}>
                                <div className={cx("unassigned-matches-label")}>
                                    {l("CompetitionPhases_PlanSchedule_Board_Unassigned")}
                                    <Button
                                        size="small"
                                        type="primary"
                                        disabled={!canPlanScheduleAutomatically}
                                        onClick={planScheduleAutomatically}>
                                        {l("CompetitionPhases_PlanSchedule_AutomaticSchedulePlanning_Plan")}
                                    </Button>
                                </div>
                                {filter && (
                                    <div>
                                        <UnassignedMatchesFilter filter={filter} />
                                    </div>
                                )}
                            </Spacing>
                        }>
                        {unassignedMatches.map((m, ind) => (
                            <MatchItemDraggable
                                phase={phases?.find(p => p.id === m.phaseId)}
                                key={m.id}
                                match={m}
                                index={ind}
                                hoveredTeamId={hoveredTeamId}
                                hoveredMatchId={hoveredMatchId}
                                onHover={setHoveredMatchId}
                                onTeamHover={setHoveredTeamId}
                            />
                        ))}
                    </DroppableColumn>
                    {schedulePlan.map(({ sportsField, slots, startTime }) => (
                        <DroppableColumn
                            headerClassName={cx("sports-field-column-header")}
                            key={sportsField.id}
                            label={
                                <Spacing childrenGutterX className={cx("sports-field-column-header-content")}>
                                    <EditableLabel onClick={() => setSportsFieldToEditName(sportsField)}>
                                        {sportsField.name}
                                    </EditableLabel>
                                    {startTime && (
                                        <TimePicker
                                            dropdownClassName={cx("time-picker-dropdown")}
                                            value={startTime}
                                            allowClear={false}
                                            format={timeFormat}
                                            onChange={date =>
                                                date && onSportsFieldStartTimeChange(sportsField.id, date)
                                            }
                                        />
                                    )}
                                </Spacing>
                            }
                            columnId={sportsField.id}
                            extra={
                                <Button type="dashed" onClick={() => setSportsFieldToAddBreak(sportsField)}>
                                    <PlusOutlined /> {l("CompetitionPhases_PlanSchedule_AddBreak")}
                                </Button>
                            }>
                            {slots.map((ps, index) =>
                                isMatch(ps.slot) ? (
                                    <MatchItemDraggable
                                        phase={phases?.find(p => p.id === (ps.slot as Match).phaseId)}
                                        key={ps.slot.id}
                                        match={ps.slot}
                                        index={index}
                                        date={ps.startTime}
                                        hoveredTeamId={hoveredTeamId}
                                        hoveredMatchId={hoveredMatchId}
                                        onHover={setHoveredMatchId}
                                        onTeamHover={setHoveredTeamId}
                                        conflictingMatches={matchConflicts.get(ps.slot.id)}
                                    />
                                ) : (
                                    <BreakItemDraggable
                                        key={ps.slot.id}
                                        scheduleBreak={ps.slot}
                                        index={index}
                                        onRemove={() => onRemoveBreak(ps.slot.id)}
                                        onDuplicate={() => {
                                            if (isBreak(ps.slot))
                                                onAddBreak(sportsField.id, ps.slot.durationInMinutes, index);
                                        }}
                                    />
                                ),
                            )}
                        </DroppableColumn>
                    ))}
                    <ExtendBoardColumn onAddNewColumn={onAddSportsField} />
                </div>
            </DragDropContext>
            {sportsFieldToEditName && (
                <SportsFieldNameFormDialog
                    sportsField={sportsFieldToEditName}
                    onClose={() => setSportsFieldToEditName(undefined)}
                />
            )}
            {sportsFieldToAddBreak && (
                <AddBreakFormDialog
                    onFinish={breakDuration => {
                        onAddBreak(sportsFieldToAddBreak.id, breakDuration);
                        setSportsFieldToAddBreak(undefined);
                    }}
                    onClose={() => setSportsFieldToAddBreak(undefined)}
                />
            )}
            {showAutomaticScheduleGenerationDialog && (
                <AutomaticScheduleGenerationFormDialog
                    schedulePlan={schedulePlan}
                    onFinish={selectedSportsFieldsIds => {
                        onPlanScheduleAutomatically(selectedSportsFieldsIds);

                        setShowAutomaticScheduleGenerationDialog(false);
                    }}
                    onClose={() => setShowAutomaticScheduleGenerationDialog(false)}
                />
            )}
        </>
    ));
};

export default SchedulePlannerBoard;
