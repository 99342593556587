import AppLayout from "Components/AppLayout";
import authentication from "Domain/Account/Authentication";
import { useObserver } from "mobx-react-lite";
import * as React from "react";
import AuthenticatedAppRouter from "./AuthenticatedAppRouter";
import UnauthenticatedAppRouter from "./UnauthenticatedAppRouter";

export const ApplicationRouter: React.FunctionComponent = () => {
    const signInState = useObserver(() => authentication.signInState);

    if (signInState === "signed_in") {
        return (
            <AppLayout>
                <AuthenticatedAppRouter />
            </AppLayout>
        );
    } else {
        return <UnauthenticatedAppRouter />;
    }
};
