import { PlusOutlined } from "@ant-design/icons";
import mkCx, { rawClassName } from "@leancode/cx";
import { Button } from "antd";
import { ImgCropProps } from "antd-img-crop";
import ImageCrop from "Components/ImageCrop";
import ImageUpload from "Components/ImageUpload";
import React, { useMemo } from "react";
import styles from "./styles.scss";

const cx = mkCx(styles);

export type PhotoProps = {
    photo?: string;
    defaultPhoto?: string;
    editable?: boolean;
    cropDisabled?: boolean;
    fullWidth?: boolean;
    small?: boolean;

    imgCropProps?: ImgCropProps;

    className?: string;

    onChange?: (file: File) => void;
};

export const Photo: React.FunctionComponent<PhotoProps> = ({
    photo: photoUri,
    editable,
    defaultPhoto: defaultPhotoUri,
    cropDisabled,
    fullWidth,
    small,
    imgCropProps,
    className,
    onChange,
}) => {
    const image = useMemo(
        () => (
            <img
                src={photoUri ?? defaultPhotoUri}
                className={cx(
                    "photo",
                    { "-editable": editable, "-full-width": fullWidth, "-small": small },
                    rawClassName(className),
                )}
            />
        ),
        [photoUri, defaultPhotoUri, editable, fullWidth, small, className],
    );

    const imageUpload = useMemo(
        () => (
            <ImageUpload onUpload={onChange}>
                {photoUri ? image : <Button type="dashed" shape="circle" icon={<PlusOutlined />} />}
            </ImageUpload>
        ),
        [photoUri, image, onChange],
    );

    return editable ? cropDisabled ? imageUpload : <ImageCrop {...imgCropProps}>{imageUpload}</ImageCrop> : image;
};

export default Photo;
