import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Button, Modal } from "antd";
import DropdownMenu from "Components/DropdownMenu";
import EditableLabel from "Components/EditableLabel";
import ExtendBoardColumn from "Components/ExtendBoardColumn";
import Spacing from "Components/Spacing";
import GroupsPhaseConfigurator from "Domain/CompetitionPhases/GroupsPhase/GroupsPhaseConfigurator";
import TeamGroup from "Domain/CompetitionPhases/GroupsPhase/TeamGroup";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import competitionTeamStore from "Domain/Competitions/CompetitionTeamStore";
import PlaceholderTeamForPhaseFormDialog from "DomainComponents/PlaceholderTeamForPhaseFormDialog";
import { l } from "Languages";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import useRunInTask from "Utils/Hooks/useRunInTask";
import DroppableColumn from "../../Components/DroppableColumn";
import AddTeamsToGroupSelect from "./AddTeamsToGroupSelect";
import GroupNameFormDialog from "./GroupNameFormDialog";
import GroupsPhaseTeamItemDraggable from "./GroupsPhaseTeamItemDraggable";
import styles from "./styles.scss";

const cx = mkCx(styles);

const unassignedTeamsKey = "unassigned";

type PlaceholderTeamForPhaseFormState =
    | {
          mode: "edit";
          placeholderTeam: CompetitionTeam;
      }
    | { mode: "create" | "off" };

type GroupsBoardProps = {
    phaseConfigurator: GroupsPhaseConfigurator;
};

const GroupsBoard: React.FunctionComponent<GroupsBoardProps> = observer(({ phaseConfigurator }) => {
    const [groupToEditName, setGroupToEditName] = useState<TeamGroup>();
    const [
        placeholderTeamForPhaseFormState,
        setPlaceholderTeamForPhaseFormState,
    ] = useState<PlaceholderTeamForPhaseFormState>({ mode: "off" });

    const [isPlaceholderTeamForPhaseSaveInProgress, runPlaceholderForPhaseSaveInTask] = useRunInTask();

    const closePlaceholderTeamForPhaseFormDialog = useCallback(() => {
        setPlaceholderTeamForPhaseFormState({ mode: "off" });
    }, []);

    const onDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) {
                return;
            }

            const groupId =
                result.destination.droppableId === unassignedTeamsKey ? undefined : result.destination.droppableId;

            phaseConfigurator.moveTeam(result.draggableId, { groupId: groupId, index: result.destination.index });
        },
        [phaseConfigurator],
    );

    const showRemoveGroupConfirmation = useCallback(
        (groupId: string) => {
            Modal.confirm({
                onOk: () => phaseConfigurator.deleteGroup(groupId),
                icon: <ExclamationCircleOutlined />,
                title: l("CompetitionPhases_Groups_DeleteGroup_Confirmation_Title"),
                content: l("CompetitionPhases_Groups_DeleteGroup_Confirmation_Content"),
                okText: l("Common_Remove"),
                okButtonProps: {
                    danger: true,
                },
                cancelText: l("Common_Cancel"),
                centered: true,
                maskClosable: true,
            });
        },
        [phaseConfigurator],
    );

    const showAdvanceTeamConfirmation = useCallback(
        (placeholderId: string) => {
            Modal.confirm({
                onOk: () => phaseConfigurator.advanceTeamForGroupsPhaseReference(placeholderId),
                icon: <ExclamationCircleOutlined />,
                title: l("CompetitionPhases_ExternalReferences_AdvanceTeam_Confirmation_Title"),
                content: l("CompetitionPhases_ExternalReferences_AdvanceTeam_Confirmation_Content"),
                okText: l("CompetitionPhases_ExternalReferences_AdvanceTeam"),
                okButtonProps: {
                    danger: true,
                },
                cancelText: l("Common_Cancel"),
                centered: true,
                maskClosable: true,
            });
        },
        [phaseConfigurator],
    );

    const showAdvanceAllTeamsConfirmation = useCallback(() => {
        Modal.confirm({
            onOk: () => phaseConfigurator.advanceTeamsForAllGroupsPhaseReferences(),
            icon: <ExclamationCircleOutlined />,
            title: l("CompetitionPhases_ExternalReferences_AdvanceTeams_Confirmation_Title"),
            content: l("CompetitionPhases_ExternalReferences_AdvanceTeams_Confirmation_Content"),
            okText: l("CompetitionPhases_ExternalReferences_AdvanceTeams"),
            okButtonProps: {
                danger: true,
            },
            cancelText: l("Common_Cancel"),
            centered: true,
            maskClosable: true,
        });
    }, [phaseConfigurator]);

    const showRevokeTeamAdvancingConfirmation = useCallback(
        (externalReferenceId: string) => {
            Modal.confirm({
                onOk: () => phaseConfigurator.revokeTeamAdvancing(externalReferenceId),
                icon: <ExclamationCircleOutlined />,
                title: l("CompetitionPhases_ExternalReferences_RevokeTeamAdvancing_Confirmation_Title"),
                content: l("CompetitionPhases_ExternalReferences_RevokeTeamAdvancing_Confirmation_Content"),
                okText: l("CompetitionPhases_ExternalReferences_RevokeTeamAdvancing"),
                okButtonProps: {
                    danger: true,
                },
                cancelText: l("Common_Cancel"),
                centered: true,
                maskClosable: true,
            });
        },
        [phaseConfigurator],
    );

    const renameGroup = useCallback(
        async name => {
            if (!groupToEditName) {
                return;
            }

            const success = await phaseConfigurator.renameGroup(groupToEditName.id, name);

            if (success) {
                setGroupToEditName(undefined);
            }
        },
        [groupToEditName, phaseConfigurator],
    );

    const addPlaceholderTeamToGroup = useCallback(
        async (name: string) => {
            const groupId = phaseConfigurator.teamsSelection?.groupId;

            if (!groupId) {
                return;
            }

            const placeholder = await runPlaceholderForPhaseSaveInTask(() =>
                phaseConfigurator.addPlaceholderTeamToGroup(groupId, name),
            );

            if (placeholder) {
                closePlaceholderTeamForPhaseFormDialog();
            }
        },
        [runPlaceholderForPhaseSaveInTask, phaseConfigurator, closePlaceholderTeamForPhaseFormDialog],
    );

    const renamePlaceholderTeamForPhase = useCallback(
        async (placeholderId: string, name: string) => {
            const success = await runPlaceholderForPhaseSaveInTask(() =>
                phaseConfigurator.renamePlaceholderTeamForPhase(placeholderId, name),
            );

            if (success) {
                closePlaceholderTeamForPhaseFormDialog();
            }
        },
        [runPlaceholderForPhaseSaveInTask, closePlaceholderTeamForPhaseFormDialog, phaseConfigurator],
    );

    return (
        <Spacing childrenGutterY>
            {phaseConfigurator.canAdvanceAnyPlaceholderTeam && (
                <Button type="primary" onClick={showAdvanceAllTeamsConfirmation}>
                    {l("CompetitionPhases_ExternalReferences_AdvanceTeams")}
                </Button>
            )}
            <DragDropContext onDragEnd={onDragEnd}>
                <div className={cx("columns")}>
                    {phaseConfigurator.groups.map(g => (
                        <DroppableColumn
                            key={g.id}
                            label={<EditableLabel onClick={() => setGroupToEditName(g)}>{g.name}</EditableLabel>}
                            columnId={g.id}
                            isDropDisabled={phaseConfigurator.groupsEditionDisabled}
                            headerExtra={
                                !phaseConfigurator.groupsEditionDisabled ? (
                                    <DropdownMenu
                                        menuItems={[
                                            {
                                                onClick: () => showRemoveGroupConfirmation(g.id),
                                                children: (
                                                    <>
                                                        <DeleteOutlined /> {l("CompetitionPhases_Groups_DeleteGroup")}
                                                    </>
                                                ),
                                            },
                                        ]}
                                    />
                                ) : undefined
                            }
                            extra={
                                !phaseConfigurator.groupsEditionDisabled && (
                                    <AddTeamsToGroupSelect
                                        selection={
                                            phaseConfigurator.teamsSelection?.groupId === g.id
                                                ? phaseConfigurator.teamsSelection
                                                : undefined
                                        }
                                        onCancel={phaseConfigurator.cancelAddingTeamsToGroup}
                                        onShowTeamsSelection={() => phaseConfigurator.startAddingTeamsToGroup(g.id)}
                                        onAddTeamsToGroup={() => phaseConfigurator.saveTeamsAddition()}
                                        onAddPlaceholderTeamForPhase={() =>
                                            setPlaceholderTeamForPhaseFormState({ mode: "create" })
                                        }
                                    />
                                )
                            }>
                            {g.teams.map((t, ind) => {
                                const predictedAdvancingTeam = t.externalReference?.predictedAdvancingTeamId
                                    ? competitionTeamStore.getById(t.externalReference.predictedAdvancingTeamId)
                                    : undefined;

                                const externalReferenceForAdvancingTeam = phaseConfigurator.externalReferencesForAdvancingTeams?.get(
                                    t.id,
                                );

                                return (
                                    <GroupsPhaseTeamItemDraggable
                                        key={t.id}
                                        team={t}
                                        predictedAdvancingTeam={predictedAdvancingTeam}
                                        index={ind}
                                        isDragDisabled={phaseConfigurator.groupsEditionDisabled}
                                        onRenamePlaceholderForPhase={
                                            t.isNonReferenceRelatedPlaceholderForPhase
                                                ? () =>
                                                      setPlaceholderTeamForPhaseFormState({
                                                          mode: "edit",
                                                          placeholderTeam: t,
                                                      })
                                                : undefined
                                        }
                                        onDelete={() =>
                                            phaseConfigurator.groupsEditionDisabled || externalReferenceForAdvancingTeam
                                                ? Modal.confirm({
                                                      onOk: () => phaseConfigurator.removeTeamFromGroup(t, g.id),
                                                      icon: <ExclamationCircleOutlined />,
                                                      title: l("GroupsPhaseTeam_Remove_Confirmation_Title"),
                                                      content: l("GroupsPhaseTeam_Remove_Confirmation_Content"),
                                                      okText: l("Common_Remove"),
                                                      okButtonProps: {
                                                          danger: true,
                                                      },
                                                      cancelText: l("Common_Cancel"),
                                                      centered: true,
                                                      maskClosable: true,
                                                  })
                                                : phaseConfigurator.removeTeamFromGroup(t, g.id)
                                        }
                                        availableTeamsForPlaceholderReplacement={
                                            phaseConfigurator.availableTeams.nonPlaceholderTeams
                                        }
                                        availableTeamsForTeamSwap={phaseConfigurator.groups.reduce(
                                            (acc, curr) => [...acc, ...curr.teams.filter(({ id }) => id !== t.id)],
                                            [],
                                        )}
                                        onTeamsSwapForPhase={phaseConfigurator.swapTeams}
                                        onReplacePlaceholderForPhase={
                                            t.isNonReferenceRelatedPlaceholderForPhase
                                                ? replacementId =>
                                                      phaseConfigurator.replacePlaceholdersForPhase([
                                                          { placeholderTeamId: t.id, replacementId },
                                                      ])
                                                : undefined
                                        }
                                        onAdvanceTeam={
                                            t.isPlaceholderForExternalReference &&
                                            !predictedAdvancingTeam?.isPlaceholder
                                                ? () => showAdvanceTeamConfirmation(t.id)
                                                : undefined
                                        }
                                        isPredictedAdvancingTeamAlreadyInPhase={
                                            t.externalReference?.predictedAdvancingTeamId
                                                ? phaseConfigurator.isTeamAddedToOneOfGroups(
                                                      t.externalReference?.predictedAdvancingTeamId,
                                                  )
                                                : undefined
                                        }
                                        externalReferenceForAdvancingTeam={externalReferenceForAdvancingTeam}
                                        onRevokeTeamAdvancing={
                                            externalReferenceForAdvancingTeam
                                                ? () =>
                                                      showRevokeTeamAdvancingConfirmation(
                                                          externalReferenceForAdvancingTeam.id,
                                                      )
                                                : undefined
                                        }
                                    />
                                );
                            })}
                        </DroppableColumn>
                    ))}
                    {!phaseConfigurator.groupsEditionDisabled && (
                        <ExtendBoardColumn onAddNewColumn={() => phaseConfigurator.addGroup()} />
                    )}
                </div>
            </DragDropContext>
            {groupToEditName && (
                <GroupNameFormDialog
                    group={groupToEditName}
                    onClose={() => setGroupToEditName(undefined)}
                    onSave={renameGroup}
                />
            )}
            {placeholderTeamForPhaseFormState.mode === "create" && (
                <PlaceholderTeamForPhaseFormDialog
                    onClose={closePlaceholderTeamForPhaseFormDialog}
                    saveInProgress={isPlaceholderTeamForPhaseSaveInProgress}
                    onSave={addPlaceholderTeamToGroup}
                />
            )}
            {placeholderTeamForPhaseFormState.mode === "edit" && (
                <PlaceholderTeamForPhaseFormDialog
                    placeholderTeam={placeholderTeamForPhaseFormState.placeholderTeam}
                    onClose={closePlaceholderTeamForPhaseFormDialog}
                    saveInProgress={isPlaceholderTeamForPhaseSaveInProgress}
                    onSave={name =>
                        renamePlaceholderTeamForPhase(placeholderTeamForPhaseFormState.placeholderTeam.id, name)
                    }
                />
            )}
        </Spacing>
    );
});

export default GroupsBoard;
