import { handleResponse } from "@leancode/validation";
import { message } from "antd";
import { AddPartner, DeletePartner } from "Contracts/CmsClient";
import { l } from "Languages";
import { runInAction } from "mobx";
import cmsApi from "Services/CmsApi";
import { ArrayStore } from "Utils/mobx";
import newId from "Utils/newId";
import retryQuery from "Utils/retryQuery";
import Partner from "./Partner";

class PartnersStore extends ArrayStore<Partner> {
    async fetchPartners() {
        const partners = await retryQuery(() => cmsApi.allPartners({}));

        this.set(partners.map(Partner.fromDTO));
    }

    async addPartner() {
        const partnerId = newId();

        const response = await cmsApi.addPartner({
            Id: partnerId,
        });

        const handler = handleResponse(response, AddPartner)
            .handle("success", () => {
                this.add(new Partner(partnerId, {}));

                message.success(l("Landing_Partners_Add_Success"));
            })
            .handle(["PartnerIdAlreadyInUse", "failure"], () => {
                message.error(l("Landing_Partners_Add_Failure"));
            })
            .check();

        return handler;
    }

    async deletePartner(partnerId: string) {
        const response = await cmsApi.deletePartner({
            PartnerId: partnerId,
        });

        const handler = handleResponse(response, DeletePartner)
            .handle(["PartnerNotFound", "failure"], () => {
                message.error(l("Landing_Partners_Delete_Failure"));
            })
            .handle("success", () => {
                runInAction(() => {
                    const partner = this.getItemRef(partnerId);

                    if (partner.current) {
                        this.remove(partner.current);
                    }
                });

                message.success(l("Landing_Partners_Delete_Success"));
            });

        return handler;
    }
}

export default PartnersStore;
