import { Form, InputNumber } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import Break from "Domain/CompetitionPhases/SchedulePlanner/Break";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React from "react";
import guard from "Utils/guard";

type AddBreakFormDialogProps = {
    onFinish: (duration: number) => void;

    onClose: () => void;
};

const AddBreakFormDialog: React.FunctionComponent<AddBreakFormDialogProps> = ({ onFinish, onClose }) => {
    const [form] = useForm();

    return useObserver(() => (
        <Modal
            title={l("CompetitionPhases_PlanSchedule_AddBreak_Form_Title")}
            onCancel={onClose}
            okText={l("Common_Save")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}>
            <Form
                layout="vertical"
                form={form}
                onFinish={(values: FormFields) => onFinish(values.breakDuration)}
                initialValues={{ [guard<FormField>("breakDuration")]: Break.defaultDurationInMinutes }}>
                <Form.Item
                    name={guard<FormField>("breakDuration")}
                    label={l("CompetitionPhases_PlanSchedule_AddBreak_Form_BreakDuration")}
                    rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                    <InputNumber min={1} precision={0} />
                </Form.Item>
            </Form>
        </Modal>
    ));
};

export type FormFields = {
    breakDuration: number;
};

type FormField = keyof FormFields;

export default AddBreakFormDialog;
