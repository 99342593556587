import { Form, Select as AntdSelect } from "antd";
import { Select } from "Components/Select";
import { ExternalReferenceType } from "Domain/CompetitionPhases/ExternalReferences/ExternalReferenceBase";
import { l } from "Languages";
import React, { useCallback } from "react";
import { observer } from "mobx-react-lite";
import CustomPhaseTeamsSelection from "Domain/CompetitionPhases/CustomPhase/CustomPhaseTeamsSelection";
import getTeamsAndReferencesOptions from "Components/TeamsAndReferencesOptions";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";

const { Option, OptGroup } = AntdSelect;

type MatchTeamsFormItemProps = {
    teams: CompetitionTeam[];
    teamsEditionDisabled?: boolean;

    team1IdKey: string;
    team2IdKey: string;

    selection?: CustomPhaseTeamsSelection;
};

const MatchTeamsFormItem: React.FunctionComponent<MatchTeamsFormItemProps> = observer(
    ({ teams, teamsEditionDisabled, team1IdKey, team2IdKey, selection }) => {
        // mobx won't trigger a rerender when an element's property changes
        // slicing seems to expose the actual values
        selection?.selectedReferenceTypes.slice();

        const getTeamsOptGroup = useCallback(
            (teamId: string) => {
                const filtered = teams.filter(t => t.id !== teamId);

                return (
                    filtered.length > 0 && (
                        <OptGroup label={l("CompetitionPhases_MatchFrom_Teams")}>
                            {filtered.map(t => (
                                <Option value={t.id} key={t.id}>
                                    {t.displayName}
                                </Option>
                            ))}
                        </OptGroup>
                    )
                );
            },
            [teams],
        );

        return (
            <>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currValues) =>
                        prevValues[team1IdKey] !== currValues[team1IdKey] ||
                        prevValues[team2IdKey] !== currValues[team2IdKey]
                    }>
                    {({ getFieldValue, setFieldsValue }) => (
                        <Form.Item
                            name={team1IdKey}
                            label={l("CompetitionPhases_MatchForm_Team1")}
                            rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                            <Select disabled={teamsEditionDisabled} showSearch showArrow allowClear>
                                {selection
                                    ? getTeamsAndReferencesOptions(selection, {
                                          getIdToFilterOut: () => getFieldValue(team2IdKey),
                                          onSwitchChange: r => {
                                              if (
                                                  selection.availableReferences?.find(ar =>
                                                      r.type === ExternalReferenceType.PlaceInGroup
                                                          ? ar.placeInGroupReferences.find(
                                                                p =>
                                                                    p.id === getFieldValue(team2IdKey) &&
                                                                    p.placeInGroup === r.placeInGroup,
                                                            )
                                                          : ar.nthBestForPlaceInGroupReferences.find(
                                                                p =>
                                                                    p.id === getFieldValue(team2IdKey) &&
                                                                    p.placeInGroup === r.placeInGroup,
                                                            ),
                                                  )
                                              )
                                                  setFieldsValue({ [team2IdKey]: undefined });
                                          },
                                      })
                                    : getTeamsOptGroup(team2IdKey)}
                            </Select>
                        </Form.Item>
                    )}
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currValues) =>
                        prevValues[team1IdKey] !== currValues[team1IdKey] ||
                        prevValues[team2IdKey] !== currValues[team2IdKey]
                    }>
                    {({ getFieldValue, setFieldsValue }) => (
                        <Form.Item
                            name={team2IdKey}
                            label={l("CompetitionPhases_MatchForm_Team2")}
                            rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                            <Select disabled={teamsEditionDisabled} showSearch showArrow allowClear>
                                {selection
                                    ? getTeamsAndReferencesOptions(selection, {
                                          getIdToFilterOut: () => getFieldValue(team1IdKey),
                                          onSwitchChange: r => {
                                              if (
                                                  selection.availableReferences?.find(ar =>
                                                      r.type === ExternalReferenceType.PlaceInGroup
                                                          ? ar.placeInGroupReferences.find(
                                                                p =>
                                                                    p.id === getFieldValue(team1IdKey) &&
                                                                    p.placeInGroup === r.placeInGroup,
                                                            )
                                                          : ar.nthBestForPlaceInGroupReferences.find(
                                                                p =>
                                                                    p.id === getFieldValue(team1IdKey) &&
                                                                    p.placeInGroup === r.placeInGroup,
                                                            ),
                                                  )
                                              )
                                                  setFieldsValue({ [team1IdKey]: undefined });
                                          },
                                      })
                                    : getTeamsOptGroup(team1IdKey)}
                            </Select>
                        </Form.Item>
                    )}
                </Form.Item>
            </>
        );
    },
);

export default MatchTeamsFormItem;
