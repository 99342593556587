import { action, computed, observable, runInAction } from "mobx";
import api from "Services/Api";
import newId from "Utils/newId";
import retryQuery from "Utils/retryQuery";

export type PlayerDescription = {
    id: string;
    firstName: string;
    lastName: string;
};

export type NewPlayerDescription = {
    shirtNumber?: number;
} & PlayerDescription;

export type ForeignPlayerDescription = {
    teamName: string;
    competitionName: string;
} & PlayerDescription;

class TeamPlayersCreator {
    @observable playersFromPreviousCompetitions?: PlayerDescription[];
    @observable otherPlayers?: ForeignPlayerDescription[];
    @observable newPlayers: NewPlayerDescription[] = [];

    @observable selectedPlayersIds: string[] = [];

    async fetchExistingPlayersAddableToTeam(teamId: string) {
        const response = await retryQuery(() =>
            api.playersAutocompleteData({
                TeamId: teamId,
            }),
        );

        runInAction(() => {
            this.otherPlayers = response.OtherPlayers.map(t => ({
                id: t.Id,
                firstName: t.FirstName,
                lastName: t.LastName,
                competitionName: t.CompetitionName,
                teamName: t.TeamName,
            }));

            this.playersFromPreviousCompetitions = response.PlayersFromPreviousCompetitions.map(p => ({
                id: p.Id,
                firstName: p.FirstName,
                lastName: p.LastName,
            }));
        });
    }

    @action.bound
    setSelectedPlayersIds(playersIds: string[]) {
        this.selectedPlayersIds = playersIds;
    }

    @action.bound
    selectPlayer(playerId: string) {
        this.selectedPlayersIds = [...this.selectedPlayersIds, playerId];
    }

    @action.bound
    addNewPlayer(firstName: string, lastName: string, shirtNumber?: number) {
        const player: NewPlayerDescription = {
            id: newId(),
            firstName: firstName,
            lastName: lastName,
            shirtNumber: shirtNumber,
        };

        this.newPlayers = [...this.newPlayers, player];
        this.selectPlayer(player.id);

        return player;
    }

    @computed get selection(): TeamPlayersSelectionResult {
        const selectedPlayersIds = new Set(this.selectedPlayersIds);

        const newPlayers = this.newPlayers.filter(t => selectedPlayersIds.has(t.id));
        const existingPlayers = [
            ...(this.playersFromPreviousCompetitions ?? []),
            ...(this.otherPlayers ?? []),
        ].filter(t => selectedPlayersIds.has(t.id));

        return {
            newPlayers: newPlayers,
            existingPlayers: existingPlayers,
        };
    }
}

export type TeamPlayersSelectionResult = {
    newPlayers: NewPlayerDescription[];
    existingPlayers: PlayerDescription[];
};

export default TeamPlayersCreator;
