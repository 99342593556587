import { message, PageHeader, Skeleton } from "antd";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import PageContent from "Components/PageContent";
import articlesStore from "Domain/Articles";
import Article from "Domain/Articles/Article";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import routes, { PageComponent } from "Router/routes";
import useRunInTask from "Utils/Hooks/useRunInTask";
import ArticleEditor, { ArticleEditorValidationErrors, FormFields } from "../ArticleEditor";

const CreateArticlePage: PageComponent<typeof routes.articles.create> = ({ history: { push } }) => {
    const [isRunning, runInTask] = useRunInTask();

    const breadcrumb: BreadcrumbProps["routes"] = useMemo(
        () => [
            {
                breadcrumbName: l("Articles"),
                path: routes.articles(),
            },
            {
                breadcrumbName: l("Articles_AddNew"),
                path: routes.articles.create(),
            },
        ],
        [],
    );

    useEffect(() => {
        if (articlesStore.articles.length === 0) {
            runInTask(() => articlesStore.fetchArticles());
        }
    }, [runInTask]);

    const onSaveArticle = useCallback(
        async (fields: FormFields): Promise<ArticleEditorValidationErrors | undefined> => {
            const response = await articlesStore.addArticle(fields);

            return response
                .handle(
                    ["ArticleIdAlreadyInUse", "CompetitionNotFound", "OneOfTagsEmpty", "OneOfTagsTooLong", "failure"],
                    () => {
                        message.error(l("Articles_AddNew_Failure"));
                    },
                )
                .handle("ContentMissingOrEmpty", () => ({
                    content: l("Common_Validation_FieldRequired"),
                }))
                .handle(["SummaryTooLong", "SummaryMissingOrEmpty"], error => ({
                    summary:
                        error === "SummaryTooLong"
                            ? l("Common_Validation_FieldTooLong")
                            : l("Common_Validation_FieldRequired"),
                }))
                .handle("ThumbnailPhotoUriTooLong", () => ({
                    thumbnailPhotoUri: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["TitleTooLong", "TitleMissingOrEmpty"], error => ({
                    title:
                        error === "TitleTooLong"
                            ? l("Common_Validation_FieldTooLong")
                            : l("Common_Validation_FieldRequired"),
                }))
                .handle(["success"], () => {
                    message.success(l("Articles_AddNew_Success"));

                    push(routes.articles());
                })
                .check({
                    reducer: (prev, cur) => {
                        return {
                            ...prev,
                            ...(cur || {}),
                        };
                    },
                    initialValue: {} as ArticleEditorValidationErrors,
                });
        },
        [push],
    );

    return useObserver(() => (
        <PageContent>
            <PageContent.Header>
                <PageHeader
                    title={l("Articles_AddNew")}
                    onBack={() => push(routes.articles())}
                    breadcrumb={{
                        routes: breadcrumb,
                        itemRender: ({ path, breadcrumbName }) =>
                            path === location.pathname ? breadcrumbName : <Link to={path}>{breadcrumbName}</Link>,
                    }}
                />
            </PageContent.Header>
            <PageContent.Card>
                <Skeleton loading={isRunning} active={isRunning}>
                    <ArticleEditor
                        mode="create"
                        onSave={onSaveArticle}
                        onCancel={() => push(routes.articles())}
                        tagsFromOtherArticles={articlesStore.allTags}
                        initialValues={{
                            isNews: true,
                            tags: [Article.newsTag],
                        }}
                    />
                </Skeleton>
            </PageContent.Card>
        </PageContent>
    ));
};

export default CreateArticlePage;
