import { handleResponse } from "@leancode/validation";
import { ChangeMatchday, PhaseTypeDTO, UpdateGroupsPhaseMatch } from "Contracts/PlayooLeagueClient";
import { CompetitionTeamStore } from "Domain/Competitions/CompetitionTeamStore";
import { observable, runInAction } from "mobx";
import api from "Services/Api";
import { dateTimeOffsetToDTOOptional } from "Utils/DTO";
import MatchBase, { MatchInit } from "../../Matches/MatchBase";

class GroupsPhaseMatch extends MatchBase {
    readonly phaseType = PhaseTypeDTO.Groups;

    @observable matchday: number;
    @observable groupId: string;
    @observable groupName: string;

    get isDeletable(): boolean {
        return true;
    }

    constructor(id: string, phaseId: string, init: GroupsPhaseMatchInit, competitionTeamStore: CompetitionTeamStore) {
        super(id, phaseId, init, competitionTeamStore);

        this.matchday = init.matchday;
        this.groupId = init.groupId;
        this.groupName = init.groupName;
    }

    async update({ groupId, team1Id, team2Id, matchday, date, sportsFieldId, status }: GroupsPhaseMatchUpdateData) {
        const response = await api.updateGroupsPhaseMatch({
            GroupId: groupId,
            Team1Id: team1Id,
            Team2Id: team2Id,
            MatchId: this.id,
            Matchday: matchday,
            Date: dateTimeOffsetToDTOOptional(date),
            SportsFieldId: sportsFieldId,
            Status: status,
        });

        handleResponse(response, UpdateGroupsPhaseMatch).handle("success", () => {
            runInAction(() => {
                this.groupId = groupId;
                this.team1Id = team1Id;
                this.team2Id = team2Id;
                this.matchday = matchday;
                this.date = date;
                this.sportsFieldId = sportsFieldId;
                this.status = status;
            });
        });

        return handleResponse(response, UpdateGroupsPhaseMatch);
    }

    async updateMatchday(matchday: number) {
        const response = await api.changeMatchday({
            MatchId: this.id,
            Matchday: matchday,
        });

        return handleResponse(response, ChangeMatchday);
    }
}

type GroupsPhaseMatchInit = MatchInit & Pick<GroupsPhaseMatch, "matchday" | "groupId" | "groupName">;

export type GroupsPhaseMatchUpdateData = Pick<
    GroupsPhaseMatch,
    "groupId" | "matchday" | "date" | "sportsFieldId" | "status"
> & {
    team1Id: string;
    team2Id: string;
};

export default GroupsPhaseMatch;
