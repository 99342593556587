import { handleResponse } from "@leancode/validation";
import {
    CloseRegistration,
    OpenRegistration,
    RegistrationDetailsDTO,
    RegistrationDTO,
    UpdateRegistration,
    UpdateRegistrationHighlightState,
} from "Contracts/RegistrationsClient";
import Article from "Domain/Articles/Article";
import _ from "lodash";
import { action, computed, observable, runInAction } from "mobx";
import moment from "moment";
import api from "Services/Api";
import registrationsApi from "Services/RegistrationsApi";
import { dateFromDTO, dateFromDTOOptional, dateToDTO, dateToDTOOptional } from "Utils/DTO";
import newId from "Utils/newId";
import retryQuery from "Utils/retryQuery";
import Application from "./Application";
import RegistrationAgeGroup from "./RegistrationAgeGroup";
import RegistrationDocument from "./RegistrationDocument";

class Registration {
    readonly id: string;

    @observable title: string;
    @observable startDate: moment.Moment;
    @observable isOpen: boolean;
    @observable isHighlighted: boolean;

    @observable endDate?: moment.Moment;
    @observable summary?: string;
    @observable logoUri?: string;
    @observable thumbnailPhotoUri?: string;
    @observable backgroundPhotoUri?: string;
    @observable articleId?: string;
    @observable maxTeamsCount?: number;
    @observable entryFee?: string;
    @observable extraFormFieldDescription?: string;
    @observable sportsVenueName?: string;
    @observable address?: string;
    @observable city?: string;

    @observable ageGroups?: RegistrationAgeGroup[];
    @observable documents?: RegistrationDocument[];

    @observable article?: Article;

    @observable applications?: Application[];

    constructor(id: string, { title, startDate, isOpen, isHighlighted }: RegistrationInit) {
        this.id = id;
        this.title = title;
        this.startDate = startDate;
        this.isOpen = isOpen;
        this.isHighlighted = isHighlighted;
    }

    static fromDTO(dto: RegistrationDTO) {
        return new Registration(dto.Id, {
            title: dto.Title,
            startDate: dateFromDTO(dto.StartDate),
            isOpen: dto.IsOpen,
            isHighlighted: dto.IsHighlighted,
        });
    }

    @computed get hasDetails() {
        return !!this.ageGroups && !!this.article;
    }

    @computed get allPossibleMaxBirthYears(): (number | undefined)[] {
        const allPossibleMaxBirthYearsForCurrentYear = RegistrationAgeGroup.allPossibleMaxBirthYearsForCurrentYear();

        return _([
            ...allPossibleMaxBirthYearsForCurrentYear,
            ...(this.ageGroups || []).map(ag => ag.maxAllowedBirthYear),
        ])
            .uniq()
            .orderBy(y => y ?? 0, "desc")
            .value();
    }

    static fromDetailsDTO(dto: RegistrationDetailsDTO) {
        const registration = Registration.fromDTO(dto);

        registration.updateFromDetails(dto);

        return registration;
    }

    @action.bound
    updateFromDetails(dto: RegistrationDetailsDTO) {
        this.title = dto.Title;
        this.startDate = dateFromDTO(dto.StartDate);
        this.isOpen = dto.IsOpen;
        this.isHighlighted = dto.IsHighlighted;
        this.endDate = dateFromDTOOptional(dto.EndDate);
        this.summary = dto.Summary ?? undefined;
        this.logoUri = dto.LogoUri ?? undefined;
        this.thumbnailPhotoUri = dto.ThumbnailPhotoUri ?? undefined;
        this.backgroundPhotoUri = dto.BackgroundPhotoUri ?? undefined;
        this.articleId = dto.ArticleId ?? undefined;
        this.maxTeamsCount = dto.MaxTeamsCount ?? undefined;
        this.entryFee = dto.EntryFee ?? undefined;
        this.extraFormFieldDescription = dto.ExtraFormFieldDescription ?? undefined;
        this.sportsVenueName = dto.SportsVenueName ?? undefined;
        this.address = dto.Address ?? undefined;
        this.city = dto.City ?? undefined;

        this.ageGroups = dto.AgeGroups.map(RegistrationAgeGroup.fromDTO);
        this.documents = dto.Documents.map(RegistrationDocument.fromDTO);

        this.applications = dto.Applications?.map(Application.fromDTO);
    }

    async fetchArticle() {
        if (this.articleId) {
            const articleId = this.articleId;
            const article = await retryQuery(() => api.articleDetails({ ArticleId: articleId }));

            runInAction(() => {
                this.article = Article.fromDTO(article);
            });
        }
    }

    async edit({
        title,
        summary,
        logoUri,
        thumbnailPhotoUri,
        backgroundPhotoUri,
        entryFee,
        startDate,
        endDate,
        maxTeamsCount,
        extraFormFieldDescription,
        maxAllowedBirthYears,
        documents,
        articleTitle,
        articleContent,
        address,
        city,
        sportsVenueName,
    }: EditRegistrationData) {
        if (!this.ageGroups || !this.article) {
            throw new Error("Registration is in invalid state.");
        }

        const {
            handler: articleEditionHandler,
            wasSuccessful: wasArticleEdititionSuccessful,
        } = await this.article.edit({
            isNews: false,
            title: articleTitle,
            summary: articleTitle,
            content: articleContent,
        });

        if (!wasArticleEdititionSuccessful) {
            return { articleEditionHandler };
        }

        const maxAllowedBirthYearsSet = new Set(maxAllowedBirthYears);

        const existingAgeGroups = this.ageGroups.filter(ag => maxAllowedBirthYearsSet.has(ag.maxAllowedBirthYear));
        const newAgeGroups = maxAllowedBirthYears
            .filter(y => existingAgeGroups.findIndex(ag => ag.maxAllowedBirthYear === y) === -1)
            .map(y => new RegistrationAgeGroup(newId(), y));

        const response = await registrationsApi.updateRegistration({
            RegistrationId: this.id,
            Title: title,
            Summary: summary,
            LogoUri: logoUri,
            ThumbnailPhotoUri: thumbnailPhotoUri,
            BackgroundPhotoUri: backgroundPhotoUri,
            EntryFee: entryFee,
            StartDate: dateToDTO(startDate),
            EndDate: dateToDTOOptional(endDate),
            MaxTeamsCount: maxTeamsCount,
            AgeGroups: [...existingAgeGroups, ...newAgeGroups].map(ag => ag.toDTO()),
            Documents: documents?.map(d => ({
                Id: d.id,
                Name: d.name,
                Uri: d.uri,
                RequiresAcceptance: d.requiresAcceptance,
            })),
            SportsVenueName: sportsVenueName,
            Address: address,
            City: city,
            ExtraFormFieldDescription: extraFormFieldDescription,
        });

        return {
            editionHandler: handleResponse(response, UpdateRegistration),
            articleEditionHandler,
        };
    }

    async open() {
        const response = await registrationsApi.openRegistration({
            RegistrationId: this.id,
        });

        handleResponse(response, OpenRegistration).handle("success", () => {
            runInAction(() => {
                this.isOpen = true;
            });
        });

        return handleResponse(response, OpenRegistration);
    }

    async close() {
        const response = await registrationsApi.closeRegistration({
            RegistrationId: this.id,
        });

        handleResponse(response, CloseRegistration).handle("success", () => {
            runInAction(() => {
                this.isOpen = false;
            });
        });

        return handleResponse(response, CloseRegistration);
    }

    async updateHighlightState(isHighlighted: boolean) {
        const response = await registrationsApi.updateRegistrationHighlightState({
            RegistrationId: this.id,
            IsHighlighted: isHighlighted,
        });

        handleResponse(response, UpdateRegistrationHighlightState).handle("success", () => {
            runInAction(() => {
                this.isHighlighted = isHighlighted;
            });
        });

        return handleResponse(response, UpdateRegistrationHighlightState);
    }
}

type RegistrationInit = Pick<Registration, "title" | "startDate" | "isOpen" | "isHighlighted" | "applications">;

export type EditRegistrationData = {
    title: string;
    summary?: string;
    logoUri?: string;
    thumbnailPhotoUri?: string;
    backgroundPhotoUri?: string;
    entryFee?: string;
    startDate: moment.Moment;
    endDate?: moment.Moment;
    maxTeamsCount?: number;
    extraFormFieldDescription?: string;
    sportsVenueName?: string;
    address?: string;
    city?: string;
    maxAllowedBirthYears: (number | undefined)[];
    documents?: {
        id: string;
        name: string;
        uri: string;
        requiresAcceptance: boolean;
    }[];
    articleTitle: string;
    articleContent: string;
};

export default Registration;
