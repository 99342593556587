import { handleResponse } from "@leancode/validation";
import { message } from "antd";
import {
    AddLinkedPhaseBase,
    AddPhaseBase,
    AddReferees,
    BeginPhase,
    CompetitionDetailsDTO,
    CompetitionDTO,
    CopyCompetition,
    DeleteTeam,
    EndPhase,
    PhaseTypeDTO,
    RemovePhase,
    RemoveReferee,
    SetCompetitionSportsFields,
    UpdateCompetition,
    UpdateCompetitionSettings,
} from "Contracts/PlayooLeagueClient";
import CompetitionGroupStore from "Domain/CompetitionGroups/CompetitionGroupStore";
import CompetitionPhaseFactory from "Domain/CompetitionPhases/CompetitionPhaseFactory";
import CompetitionPhaseStore from "Domain/CompetitionPhases/CompetitionPhaseStore";
import ExternalReferencesStore from "Domain/CompetitionPhases/ExternalReferences/ExternalReferencesStore";
import MatchStore from "Domain/Matches/MatchStore";
import { l } from "Languages";
import { computed, observable, runInAction } from "mobx";
import moment, { Moment } from "moment";
import api from "Services/Api";
import { dateTimeFromDTO, dateToDTO } from "Utils/DTO";
import newId from "Utils/newId";
import { notUndefined } from "Utils/predicates";
import retryQuery from "Utils/retryQuery";
import TablePhaseScoring from "../CompetitionPhases/TablePhase/TablePhaseScoring";
import CompetitionRankingsConfiguration from "./CompetitionRankingsConfiguration";
import CompetitionReferee from "./CompetitionReferee";
import CompetitionSettings from "./CompetitionSettings";
import CompetitionSportsField from "./CompetitionSportsField";
import CompetitionTeam from "./CompetitionTeam";
import { CompetitionTeamStore } from "./CompetitionTeamStore";

const linkablePhaseTypes = [PhaseTypeDTO.Groups, PhaseTypeDTO.Table];

class Competition {
    private readonly competitionPhaseStore: CompetitionPhaseStore;
    private readonly competitionTeamStore: CompetitionTeamStore;
    private readonly competitionGroupStore: CompetitionGroupStore;
    private readonly matchStore: MatchStore;
    private readonly externalReferencesStore: ExternalReferencesStore;

    readonly id: string;
    readonly createdAt: moment.Moment;

    @observable seasonId?: string;
    @observable groupId?: string;
    @observable name: string;

    @observable private phasesIds?: string[];
    @observable teamsIds?: string[];
    @observable referees?: CompetitionReferee[];
    @observable settings?: CompetitionSettings;
    @observable sportsFields?: CompetitionSportsField[];
    @observable allTeams: CompetitionTeam[] = [];

    private constructor(
        id: string,
        init: CompetitionInit,
        {
            competitionPhaseStore,
            competitionGroupStore,
            competitionTeamStore,
            matchStore,
            externalReferencesStore,
        }: {
            competitionPhaseStore: CompetitionPhaseStore;
            competitionTeamStore: CompetitionTeamStore;
            competitionGroupStore: CompetitionGroupStore;
            matchStore: MatchStore;
            externalReferencesStore: ExternalReferencesStore;
        },
    ) {
        this.id = id;
        this.seasonId = init.seasonId;
        this.groupId = init.groupId;
        this.createdAt = init.createdAt;

        this.competitionPhaseStore = competitionPhaseStore;
        this.competitionTeamStore = competitionTeamStore;
        this.competitionGroupStore = competitionGroupStore;
        this.matchStore = matchStore;
        this.externalReferencesStore = externalReferencesStore;
    }

    @computed get phases() {
        return this.phasesIds?.map(id => this.competitionPhaseStore.getById(id)).filter(notUndefined);
    }

    @computed get linkablePhases() {
        return this.phases?.filter(({ type }) => linkablePhaseTypes.includes(type));
    }

    @computed get teams() {
        return this.teamsIds?.map(id => this.competitionTeamStore.getById(id)).filter(notUndefined);
    }

    @computed get group() {
        return this.groupId ? this.competitionGroupStore.getById(this.groupId) : undefined;
    }

    canPhaseBeLinked(id: string) {
        const phase = this.competitionPhaseStore.getById(id);

        return phase ? linkablePhaseTypes.includes(phase.type) : false;
    }

    isPhaseReferencedByOtherPhase(phaseId: string) {
        if (!this.phases) {
            throw new Error("Competition details not fetched.");
        }

        return this.phases.some(p => p.linkedPhaseId === phaseId);
    }

    static fromDTO(
        dto: CompetitionDTO | CompetitionDetailsDTO,
        seasonId: string | undefined,
        dependencies: {
            competitionPhaseStore: CompetitionPhaseStore;
            competitionTeamStore: CompetitionTeamStore;
            competitionGroupStore: CompetitionGroupStore;
            matchStore: MatchStore;
            externalReferencesStore: ExternalReferencesStore;
        },
    ): Competition {
        return new Competition(
            dto.Id,
            {
                seasonId: seasonId,
                groupId: dto.GroupId ?? undefined,
                createdAt: dateTimeFromDTO(dto.CreatedAt),
            },
            dependencies,
        ).updateFromDTO(dto);
    }

    updateFromDTO(dto: CompetitionDTO | CompetitionDetailsDTO) {
        this.name = dto.Name;

        if ("Phases" in dto) {
            this.phasesIds = dto.Phases.map(p => p.Id);

            dto.Phases.forEach(p => {
                const phase = this.competitionPhaseStore.getById(p.Id);

                if (phase) {
                    phase.updateFromDTO(p);
                } else {
                    this.competitionPhaseStore.put(
                        CompetitionPhaseFactory.fromDTO(p, this.id, {
                            competitionTeamStore: this.competitionTeamStore,
                            matchStore: this.matchStore,
                            externalReferencesStore: this.externalReferencesStore,
                        }),
                    );
                }
            });
        }

        if ("Teams" in dto) {
            this.teamsIds = dto.Teams.filter(t => !t.PhaseId).map(t => t.Id);
            this.allTeams = dto.Teams.map(t => CompetitionTeam.fromDTO(t, this.id, this.externalReferencesStore));

            dto.Teams.forEach(t => {
                const team = this.competitionTeamStore.getById(t.Id);

                if (team) {
                    team.updateFromDTO(t);
                } else {
                    this.competitionTeamStore.put(CompetitionTeam.fromDTO(t, this.id, this.externalReferencesStore));
                }
            });
        }

        if ("SportsFields" in dto) {
            this.sportsFields = dto.SportsFields.map(CompetitionSportsField.fromDTO);
        }

        return this;
    }

    async fetchDetails() {
        const competitionDetails = await retryQuery(() =>
            api.competitionDetails({
                CompetitionId: this.id,
            }),
        );

        this.updateFromDTO(competitionDetails);
    }

    async fetchReferees() {
        const referees = await retryQuery(() =>
            api.competitionReferees({
                CompetitionId: this.id,
            }),
        );

        runInAction(() => {
            this.referees = referees.map(CompetitionReferee.fromDTO);
        });
    }

    async fetchSettings() {
        const settings = await retryQuery(() =>
            api.competitionSettings({
                CompetitionId: this.id,
            }),
        );

        runInAction(() => {
            this.settings = new CompetitionSettings(CompetitionRankingsConfiguration.fromDTO(settings.PlayerRankings));
        });
    }

    async updateSettings(rankingsConfiguration: CompetitionRankingsConfiguration) {
        const response = await api.updateCompetitionSettings({
            CompetitionId: this.id,
            PlayerRankings: rankingsConfiguration.toDTO(),
        });

        handleResponse(response, UpdateCompetitionSettings)
            .handle(["CompetitionNotFound", "PlayerRankingsConfigNullOrMissing", "failure"], () =>
                message.error(l("CompetitionSettings_Update_Failure")),
            )
            .handle("success", () => {
                message.success(l("CompetitionSettings_Update_Success"));

                runInAction(() => {
                    if (this.settings) {
                        this.settings.rankingsConfiguration = rankingsConfiguration;
                    }
                });
            });
    }

    async edit({ name, seasonId, groupId }: EditCompetitionData) {
        const response = await api.updateCompetition({
            CompetitionId: this.id,
            Name: name,
            SeasonId: seasonId,
            GroupId: groupId,
        });

        handleResponse(response, UpdateCompetition).handle("success", () => {
            runInAction(() => {
                this.name = name;
                this.seasonId = seasonId;
                this.groupId = groupId;
            });
        });

        return handleResponse(response, UpdateCompetition);
    }

    async copy({ name, seasonId, groupId, firstMatchDay }: CopyCompetitionData) {
        const response = await api.copyCompetition({
            CompetitionToCopyFromId: this.id,
            CompetitionId: newId(),
            Name: name,
            SeasonId: seasonId,
            GroupId: groupId,
            FirstMatchDay: dateToDTO(firstMatchDay),
        });

        return handleResponse(response, CopyCompetition);
    }

    async addTablePhase(
        phaseName: string | undefined,
        customScoring: TablePhaseScoring | undefined,
        addAllTeams: boolean,
    ) {
        const phaseId = newId();

        const response = await api.addTablePhase({
            CompetitionId: this.id,
            PhaseId: phaseId,
            Name: phaseName,
            CustomScoring: customScoring?.toDTO(),
        });

        if (response.isSuccess && response.result.WasSuccessful) {
            await this.fetchDetails();

            if (addAllTeams) {
                await this.addAllTeamsToPhase(phaseId);
            }
        }

        return handleResponse(response, AddPhaseBase);
    }

    async addGroupsPhase(phaseName: string | undefined, addAllTeams: boolean, phaseIdToLink?: string) {
        const phaseId = newId();

        const linkedPhaseId = phaseIdToLink && this.canPhaseBeLinked(phaseIdToLink) ? phaseIdToLink : undefined;

        const response = await api.addGroupsPhase({
            CompetitionId: this.id,
            PhaseId: phaseId,
            Name: phaseName,
            LinkedPhaseId: linkedPhaseId,
        });

        if (response.isSuccess && response.result.WasSuccessful) {
            await this.fetchDetails();

            if (addAllTeams && !linkedPhaseId) {
                await this.addAllTeamsToPhase(phaseId);
            }
        }

        return handleResponse(response, {
            ErrorCodes: { ...AddLinkedPhaseBase.ErrorCodes, ...AddPhaseBase.ErrorCodes },
        });
    }

    async addCustomPhase(phaseName: string | undefined, addAllTeams: boolean, phaseIdToLink?: string) {
        const phaseId = newId();

        const linkedPhaseId = phaseIdToLink && this.canPhaseBeLinked(phaseIdToLink) ? phaseIdToLink : undefined;

        const response = await api.addCustomPhase({
            CompetitionId: this.id,
            PhaseId: phaseId,
            Name: phaseName,
            LinkedPhaseId: linkedPhaseId,
        });

        if (response.isSuccess && response.result.WasSuccessful) {
            await this.fetchDetails();

            if (addAllTeams) {
                await this.addAllTeamsToPhase(phaseId);
            }
        }

        return handleResponse(response, {
            ErrorCodes: { ...AddLinkedPhaseBase.ErrorCodes, ...AddPhaseBase.ErrorCodes },
        });
    }

    async addKnockoutPhase(phaseName: string | undefined, addAllTeams: boolean, phaseIdToLink?: string) {
        const phaseId = newId();

        const linkedPhaseId = phaseIdToLink && this.canPhaseBeLinked(phaseIdToLink) ? phaseIdToLink : undefined;

        const response = await api.addKnockoutPhase({
            CompetitionId: this.id,
            PhaseId: phaseId,
            Name: phaseName,
            LinkedPhaseId: linkedPhaseId,
        });

        if (response.isSuccess && response.result.WasSuccessful) {
            await this.fetchDetails();

            if (addAllTeams) {
                await this.addAllTeamsToPhase(phaseId);
            }
        }

        return handleResponse(response, AddPhaseBase);
    }

    async addTeamsFromExisting(teamsIds: string[]) {
        const response = await api.addCopiesOfTeams({
            CompetitionId: this.id,
            TeamIds: teamsIds,
        });

        return response;
    }

    async addNewTeam(team: CompetitionTeam) {
        const response = await api.addNewTeam({
            CompetitionId: this.id,
            TeamId: team.id,
            Name: team.name,
            ShortName: team.shortName,
            AgeGroup: team.ageGroup,
        });

        return response;
    }

    async addPlaceholderTeam(teamId: string) {
        const response = await api.addPlaceholderTeam({
            CompetitionId: this.id,
            TeamId: teamId,
        });

        return response;
    }

    async replaceTeamWithCopy(replacedTeamId: string, replacementTeamId: string) {
        const response = await api.replaceTeamWithCopy({
            ReplacedTeamId: replacedTeamId,
            ReplacementTeamId: replacementTeamId,
        });

        return response;
    }

    async replaceTeamWithNew(replacedTeamId: string, replacementTeam: CompetitionTeam) {
        const response = await api.replaceTeamWithNew({
            TeamId: replacedTeamId,
            Name: replacementTeam.name,
            ShortName: replacementTeam.shortName,
            AgeGroup: replacementTeam.ageGroup,
        });

        return response;
    }

    async deleteTeam(teamId: string) {
        const response = await api.deleteTeam({
            TeamId: teamId,
        });

        const team = this.competitionTeamStore.getById(teamId);
        if (team && response.isSuccess) this.competitionTeamStore.remove(team);

        return response;
    }

    async deletePlaceholderTeamForPhase(phaseId: string, teamId: string) {
        const phase = this.phases?.find(p => p.id === phaseId);

        if (!phase) {
            throw new Error("Phase not found. Cannot delete placeholder team for phase.");
        }

        if (!phase.teams?.some(t => t.id === teamId && t.isPlaceholderForSinglePhaseOnly)) {
            throw new Error("Placeholder team for phase not found or is not a placeholder team for phase.");
        }

        const response = await api.deleteTeam({
            TeamId: teamId,
        });

        let success = false;

        handleResponse(response, DeleteTeam)
            .handle(["TeamNotFound", "failure"], () =>
                message.error(l("CompetitionPhases_PlaceholderTeams_Delete_Failure")),
            )
            .handle("success", () => {
                success = true;

                message.success(l("CompetitionPhases_PlaceholderTeams_Delete_Success"));
            })
            .check();

        if (success) {
            await phase.fetchDetails();
        }

        return success;
    }

    async beginPhase(phaseId: string) {
        const phase = this.phases?.find(p => p.id === phaseId);

        if (!phase) {
            throw new Error("Phase not found in this competition.");
        }

        const response = await api.beginPhase({
            PhaseId: phaseId,
        });

        handleResponse(response, BeginPhase)
            .handle("success", () => {
                phase.begin();

                this.phases?.forEach(p => {
                    if (p.isOngoing && p.id !== phaseId) {
                        p.end();
                    }
                });

                message.success(l("CompetitionDetails_Phases_Start_Success"));
            })
            .handle(["PhaseNotFound", "PhaseAlreadyOngoing", "failure"], () => {
                message.error(l("CompetitionDetails_Phases_Start_Failure"));
            })
            .check();

        return handleResponse(response, BeginPhase);
    }

    async endPhase(phaseId: string) {
        const phase = this.phases?.find(p => p.id === phaseId);

        if (!phase) {
            throw new Error("Phase not found in this competition.");
        }

        const response = await api.endPhase({
            PhaseId: phaseId,
        });

        handleResponse(response, EndPhase)
            .handle("success", () => {
                phase.end();

                message.success(l("CompetitionDetails_Phases_Finish_Success"));
            })
            .handle(["PhaseNotFound", "PhaseNotOngoing", "failure"], () => {
                message.error(l("CompetitionDetails_Phases_Finish_Failure"));
            })
            .check();

        return handleResponse(response, EndPhase);
    }

    async removePhase(phaseId: string) {
        const response = await api.removePhase({
            PhaseId: phaseId,
        });

        handleResponse(response, RemovePhase)
            .handle("success", () => {
                runInAction(() => {
                    this.phasesIds = this.phasesIds?.filter(id => id !== phaseId);
                });

                message.success(l("CompetitionDetails_Phases_Remove_Success"));
            })
            .handle(["PhaseNotFound", "PhaseReferencedByOtherPhase", "failure"], () => {
                message.error(l("CompetitionDetails_Phases_Remove_Failure"));
            })
            .check();

        return response;
    }

    async addReferees(referees: CompetitionReferee[]) {
        if (referees.length === 0) {
            throw new Error("No referees to add.");
        }

        const response = await api.addReferees({
            CompetitionId: this.id,
            Referees: referees.map(r => r.toDTO()),
        });

        return handleResponse(response, AddReferees);
    }

    async removeReferee(phoneNumber: string) {
        const referee = this.referees?.find(r => r.phoneNumber === phoneNumber);

        if (!referee) {
            throw new Error("Referee not found");
        }

        const response = await api.removeReferee({
            CompetitionId: this.id,
            PhoneNumber: phoneNumber,
        });

        handleResponse(response, RemoveReferee)
            .handle(["PhoneNumberInvalidFormat", "PhoneNumberNullOrEmpty", "RefereeNotFound", "failure"], () => {
                message.error(l("CompetitionDetails_Referees_Remove_Failure"));
            })
            .handle("success", () => {
                message.success(l("CompetitionDetails_Referees_Remove_Success"));

                runInAction(() => {
                    this.referees = this.referees?.filter(r => r.phoneNumber !== phoneNumber);
                });
            });

        return handleResponse(response, RemoveReferee);
    }

    async addAllTeamsToPhase(phaseId: string) {
        const phase = this.phases?.find(p => p.id === phaseId);

        if (!phase) {
            throw new Error("Phase not found. Cannot add teams.");
        }

        if (!this.teamsIds) {
            throw new Error("Competition details not fetched.");
        }

        if (this.teamsIds.length === 0) {
            return;
        }

        return phase.addTeamsToPhase(this.teamsIds);
    }

    async setSportsFields(sportsFields: CompetitionSportsField[]) {
        const response = await api.setCompetitionSportsFields({
            CompetitionId: this.id,
            SportsFields: sportsFields.map(s => s.toDTO()),
        });

        handleResponse(response, SetCompetitionSportsFields).handle("success", () => {
            runInAction(() => {
                this.sportsFields = sportsFields;
            });
        });

        return handleResponse(response, SetCompetitionSportsFields);
    }

    addSportsField(name: string) {
        if (!this.sportsFields) {
            throw new Error("Cannot add sports field. Competition in invalid state.");
        }

        const id = newId();

        const sportsField = new CompetitionSportsField(id, name);

        const sportsFields = [...this.sportsFields, sportsField];

        return this.setSportsFields(sportsFields);
    }

    editSportsFieldName(sportsFieldId: string, name: string) {
        if (!this.sportsFields) {
            throw new Error("Cannot edit sports field. Competition in invalid state.");
        }

        const sportsFieldIndex = this.sportsFields.findIndex(sf => sf.id === sportsFieldId);

        if (sportsFieldIndex < 0) {
            throw new Error("Sports field not found.");
        }

        const sportsFields = [...this.sportsFields];

        sportsFields.splice(sportsFieldIndex, 1, new CompetitionSportsField(sportsFieldId, name));

        return this.setSportsFields(sportsFields);
    }
}

type CompetitionInit = Pick<Competition, "createdAt" | "seasonId" | "groupId">;

type EditCompetitionData = {
    name: string;
    seasonId?: string;
    groupId?: string;
};

type CopyCompetitionData = {
    name: string;
    seasonId?: string;
    groupId?: string;
    firstMatchDay: Moment;
};

export default Competition;
