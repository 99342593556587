import { CompetitionPhase } from "Domain/CompetitionPhases/CompetitionPhase";
import GroupsPhase from "Domain/CompetitionPhases/GroupsPhase/GroupsPhase";
import GroupsPhasePlannerFilter from "./GroupsPhasePlannerFilters";
import PlannerFilter from "./PlannerFilter";

class PlannerFilterFactory {
    static forPhase(phase: CompetitionPhase): PlannerFilter | undefined {
        if (phase instanceof GroupsPhase) {
            return new GroupsPhasePlannerFilter(phase);
        }

        return undefined;
    }
}

export default PlannerFilterFactory;
