import { message } from "antd";
import { MatchStatusDTO, PhaseTypeDTO } from "Contracts/PlayooLeagueClient";
import { CompetitionPhase } from "Domain/CompetitionPhases/CompetitionPhase";
import CustomPhase from "Domain/CompetitionPhases/CustomPhase/CustomPhase";
import GroupsPhase from "Domain/CompetitionPhases/GroupsPhase/GroupsPhase";
import GroupsPhaseMatch, { GroupsPhaseMatchUpdateData } from "Domain/CompetitionPhases/GroupsPhase/GroupsPhaseMatch";
import KnockoutPhaseMatch, {
    UpdateKnockoutPhaseMatchData,
} from "Domain/CompetitionPhases/KnockoutPhase/KnockoutPhaseMatch";
import TablePhaseMatch, { TablePhaseMatchUpdateData } from "Domain/CompetitionPhases/TablePhase/TablePhaseMatch";
import competitionStore from "Domain/Competitions";
import Competition from "Domain/Competitions/Competition";
import CompetitionSportsField from "Domain/Competitions/CompetitionSportsField";
import { Match } from "Domain/Matches/Match";
import CustomPhaseMatchFormDialog, {
    FormFields as CustomPhaseMatchDialogFormFields,
} from "DomainComponents/Matches/CustomPhaseMatchFormDialog";
import TablePhaseMatchFormDialog from "DomainComponents/Matches/TablePhaseMatchFormDialog";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import routes from "Router/routes";
import GroupsPhaseMatchFormDialog from "../GroupsPhaseMatchFormDialog";
import EditKnockoutPhaseMatchFormDialog from "./EditKnockoutPhaseMatchFormDialog";

type MatchEditionDialogProps = {
    match: Match;
    phase: CompetitionPhase;
    sportsFields?: CompetitionSportsField[];
    competition?: Competition;

    onClose: (params: { editionSaved: boolean }) => void;
};

const MatchEditionDialog: React.FunctionComponent<MatchEditionDialogProps> = ({
    match,
    phase,
    sportsFields,
    competition,
    onClose,
}) => {
    const { replace } = useHistory();

    useEffect(() => {
        if (!phase.teams) {
            competitionStore.fetchCompetitionPhaseDetails(phase.id);
        }
    }, [phase]);

    const displaySuccessMessage = useCallback(() => {
        message.success(l("MatchDetails_EditMatch_Failure"));
    }, []);

    const displayErrorMessage = useCallback(() => {
        message.error(l("MatchDetails_EditMatch_Success"));
    }, []);

    const updateKnockoutPhaseMatch = useCallback(
        async (match: KnockoutPhaseMatch, data: UpdateKnockoutPhaseMatchData) => {
            const result = await match.update(data);

            result
                .handle(
                    ["MatchNotFoundOrNotAKnockoutPhaseMatch", "SportsFieldNotFound", "NameTooLong", "failure"],
                    () => {
                        displayErrorMessage();
                    },
                )
                .handle("success", () => {
                    displaySuccessMessage();
                    onClose({ editionSaved: true });
                })
                .check();
        },
        [displayErrorMessage, displaySuccessMessage, onClose],
    );

    const updateTablePhaseMatch = useCallback(
        async (match: TablePhaseMatch, data: TablePhaseMatchUpdateData) => {
            const result = await match.update(data);

            result
                .handle(
                    [
                        "ChangingFinishedMatchStatusNotAllowed",
                        "ChangingTeamNotAllowed",
                        "MatchNotFoundOrNotATablePhaseMatch",
                        "MatchdayNotPositive",
                        "SportsFieldNotFound",
                        "TeamCannotPlayAgainstItself",
                        "TeamNotFoundInPhase",
                        "NameTooLong",
                        "failure",
                    ],
                    () => {
                        displayErrorMessage();
                    },
                )
                .handle("success", () => {
                    displaySuccessMessage();
                    onClose({ editionSaved: true });
                })
                .check();
        },
        [displayErrorMessage, displaySuccessMessage, onClose],
    );

    const updateCustomPhaseMatch = useCallback(
        async (
            phase: CustomPhase,
            { team1Id, team2Id, date, matchGroupId, name, sportsFieldId, status }: CustomPhaseMatchDialogFormFields,
        ) => {
            const result = await phase.editMatch({
                id: match.id,
                team1Id,
                team2Id,
                name,
                matchGroupId,
                date: date ?? undefined,
                sportsFieldId,
                status,
            });

            return result
                .handle(
                    [
                        "ChangingTeamsNotAllowed",
                        "GroupNotFound",
                        "MatchNotFoundOrNotACustomPhaseMatch",
                        "NameTooLong",
                        "TeamCannotPlayAgainstItself",
                        "TeamNotFoundInPhase",
                        "SportsFieldNotFound",
                        "ChangingFinishedMatchStatusNotAllowed",
                        "failure",
                    ],
                    () => {
                        displayErrorMessage();
                        return false;
                    },
                )
                .handle("success", () => {
                    displaySuccessMessage();

                    if (match.team1Id !== team1Id || match.team2Id !== team2Id) {
                        replace(routes.competitionPhase.schedule({ phaseId: phase.id }));
                    }

                    onClose({ editionSaved: true });
                    return true;
                })
                .check({
                    reducer: (prev, curr) => {
                        return prev || curr;
                    },
                    initialValue: false,
                });
        },
        [displayErrorMessage, displaySuccessMessage, match.id, match.team1Id, match.team2Id, onClose, replace],
    );

    const updateGroupsPhaseMatch = useCallback(
        async (match: GroupsPhaseMatch, data: GroupsPhaseMatchUpdateData) => {
            const result = await match.update(data);

            let success = false;

            result
                .handle(
                    [
                        "ChangingFinishedMatchStatusNotAllowed",
                        "ChangingTeamNotAllowed",
                        "MatchNotFoundOrNotAGroupsPhaseMatch",
                        "MatchdayNotPositive",
                        "SportsFieldNotFound",
                        "TeamCannotPlayAgainstItself",
                        "TeamNotFoundInGroup",
                        "GroupNotFound",
                        "NameTooLong",
                        "failure",
                    ],
                    () => {
                        displayErrorMessage();
                    },
                )
                .handle("success", () => {
                    success = true;
                })
                .check();

            if (success) {
                await competitionStore.fetchMatchDetails(match.id);

                displaySuccessMessage();
                onClose({ editionSaved: true });
            }
        },
        [displayErrorMessage, displaySuccessMessage, onClose],
    );

    return useObserver(() => (
        <div>
            {match.phaseType === PhaseTypeDTO.Knockout && (
                <EditKnockoutPhaseMatchFormDialog
                    teams={phase.teams}
                    sportsFields={sportsFields}
                    initialValues={{
                        date: match.date,
                        sportsFieldId: match.sportsFieldId,
                        name: match.name,
                    }}
                    onClose={() => onClose({ editionSaved: false })}
                    onSave={({ date, sportsFieldId, name }) =>
                        updateKnockoutPhaseMatch(match, { sportsFieldId, date: date ?? undefined, name })
                    }
                />
            )}
            {match.phaseType === PhaseTypeDTO.Table && (
                <TablePhaseMatchFormDialog
                    mode="edit"
                    teams={phase.teams}
                    sportsFields={sportsFields}
                    teamsEditionDisabled={match.status !== MatchStatusDTO.Canceled}
                    initialValues={{
                        team1Id: match.team1Id,
                        team2Id: match.team2Id,
                        matchday: match.matchday.toString(),
                        date: match.date,
                        sportsFieldId: match.sportsFieldId,
                        status: match.status,
                    }}
                    onClose={() => onClose({ editionSaved: false })}
                    onSave={({ date, sportsFieldId, matchday, team1Id, team2Id, status }) =>
                        updateTablePhaseMatch(match, {
                            team1Id,
                            team2Id,
                            status,
                            sportsFieldId,
                            date: date ?? undefined,
                            matchday: parseInt(matchday),
                        })
                    }
                />
            )}
            {match.phaseType === PhaseTypeDTO.Groups && phase instanceof GroupsPhase && (
                <GroupsPhaseMatchFormDialog
                    mode="edit"
                    sportsFields={sportsFields}
                    phase={phase}
                    initialValues={{
                        groupId: match.groupId,
                        team1Id: match.team1Id,
                        team2Id: match.team2Id,
                        matchday: match.matchday.toString(),
                        date: match.date,
                        sportsFieldId: match.sportsFieldId,
                        status: match.status,
                    }}
                    teamsEditionDisabled={match.status !== MatchStatusDTO.Canceled}
                    onClose={() => onClose({ editionSaved: false })}
                    onSave={({ groupId, date, sportsFieldId, matchday, team1Id, team2Id, status }) =>
                        updateGroupsPhaseMatch(match, {
                            groupId,
                            team1Id,
                            team2Id,
                            status,
                            sportsFieldId,
                            date: date ?? undefined,
                            matchday: parseInt(matchday),
                        })
                    }
                />
            )}
            {match.phaseType === PhaseTypeDTO.Custom && phase instanceof CustomPhase && competition && (
                <CustomPhaseMatchFormDialog
                    competition={competition}
                    phase={phase}
                    teams={phase.teams}
                    onClose={() => onClose({ editionSaved: false })}
                    onSave={formFields => updateCustomPhaseMatch(phase, formFields)}
                    matchGroups={phase.groups}
                    sportsFields={sportsFields}
                    teamsEditionDisabled={match.status === MatchStatusDTO.Finished}
                    initialValues={{
                        team1Id: match.team1Id,
                        team2Id: match.team2Id,
                        date: match.date,
                        matchGroupId: match.matchGroupId,
                        name: match.name,
                        sportsFieldId: match.sportsFieldId,
                        status: match.status,
                    }}
                    mode="edit"
                />
            )}
        </div>
    ));
};

export default MatchEditionDialog;
