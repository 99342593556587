import { message, PageHeader, Skeleton } from "antd";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import PageContent from "Components/PageContent";
import registrationsStore from "Domain/Registrations";
import RegistrationForm, {
    openAgeGroupValue,
    RegistrationDescription,
    RegistrationFormValidationErrors,
} from "DomainComponents/RegistrationForm";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import routes, { PageComponent } from "Router/routes";
import { mkGuard } from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";

type RegistrationFormValidationErrorKey = keyof RegistrationFormValidationErrors;

const guard = mkGuard<RegistrationFormValidationErrorKey>();

const EditRegistrationPage: PageComponent<typeof routes.registrations.edit> = ({
    history: { push },
    match: {
        params: { registrationId },
    },
}) => {
    const [registrationDetailsFetchInProgress, runRegistrationDetailsFetchInTask] = useRunInTask();

    const breadcrumb: BreadcrumbProps["routes"] = useMemo(
        () => [
            {
                breadcrumbName: l("Registrations"),
                path: routes.registrations(),
            },
            {
                breadcrumbName: l("Registrations_Edit"),
                path: routes.registrations.edit({ registrationId }),
            },
        ],
        [registrationId],
    );

    const registration = useObserver(() => registrationsStore.getById(registrationId));

    useEffect(() => {
        runRegistrationDetailsFetchInTask(() => registrationsStore.fetchRegistrationDetails(registrationId));
    }, [registrationId, runRegistrationDetailsFetchInTask]);

    const editRegistation = useCallback(
        async (formData: RegistrationDescription): Promise<RegistrationFormValidationErrors | undefined> => {
            if (!registration) {
                return;
            }

            const { editionHandler, articleEditionHandler } = await registration.edit({
                sportsVenueName: formData.sportsVenueName,
                address: formData.address,
                city: formData.city,
                articleContent: formData.articleContent,
                articleTitle: formData.articleTitle,
                logoUri: formData.logoUri,
                maxAllowedBirthYears: formData.maxAllowedBirthYears,
                startDate: formData.startDate,
                title: formData.title,
                thumbnailPhotoUri: formData.thumbnailPhotoUri,
                backgroundPhotoUri: formData.backgroundPhotoUri,
                documents: formData.documents,
                endDate: formData.endDate,
                entryFee: formData.entryFee,
                extraFormFieldDescription: formData.extraFormFieldDescription,
                maxTeamsCount: formData.maxTeamsCount,
                summary: formData.summary,
            });

            let wasEditionSuccessful = false;

            const editionErrors = editionHandler
                ?.handle(
                    [
                        "AgeGroupsContainsDuplicates",
                        "RegistrationNotFound",
                        "OneOfDocumentsIdAlreadyInUse",
                        "OneOfAgeGroupsIdAlreadyInUse",
                        "OneOfDocumentsUriMissingOrEmpty",
                        "OneOfDocumentsUriTooLong",
                        "failure",
                    ],
                    () => {
                        message.error(l("Registrations_Edit_Failure"));
                    },
                )
                .handle(["SummaryTooLong"], () => ({
                    [guard("summary")]: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["TitleTooLong", "TitleMissingOrEmpty"], error => ({
                    [guard("title")]:
                        error === "TitleTooLong"
                            ? l("Common_Validation_FieldTooLong")
                            : l("Common_Validation_FieldRequired"),
                }))
                .handle(["AddressTooLong"], () => ({
                    [guard("address")]: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["CityTooLong"], () => ({
                    [guard("city")]: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["SportsVenueNameTooLong"], () => ({
                    [guard("sportsVenueName")]: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["LogoUriTooLong"], () => ({
                    [guard("logoUri")]: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["ThumbnailPhotoUriTooLong"], () => ({
                    [guard("thumbnailPhotoUri")]: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["BackgroundPhotoUriTooLong"], () => ({
                    [guard("backgroundPhotoUri")]: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["AgeGroupsMissingOrEmpty"], () => ({
                    [guard("ageGroups")]: l("Common_Validation_FieldRequired"),
                }))
                .handle(["ExtraFormFieldDescriptionTooLong"], () => ({
                    [guard("extraFormFieldDescription")]: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["OneOfDocumentsNameMissingOrEmpty"], () => ({
                    [guard("documents")]: l("Registrations_Form_Documents_Validation_OneOfDocumentsNameMissingOrEmpty"),
                }))
                .handle(["OneOfDocumentsNameTooLong"], () => ({
                    [guard("documents")]: l("Registrations_Form_Documents_Validation_OneOfDocumentsNameTooLong"),
                }))
                .handle(["EntryFeeTooLong"], () => ({
                    [guard("entryFee")]: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["MaxTeamsCountNotPositive"], () => ({
                    [guard("maxTeamsCount")]: l("Common_Validation_Number"),
                }))
                .handle("success", () => {
                    wasEditionSuccessful = true;
                })
                .check({
                    reducer: (prev, cur) => {
                        return {
                            ...prev,
                            ...(cur || {}),
                        };
                    },
                    initialValue: {} as RegistrationFormValidationErrors,
                });

            let wasArticleEditionSuccessful = false;

            const articleEditionErrors = articleEditionHandler
                ?.handle(
                    [
                        "ArticleNotFound",
                        "CompetitionNotFound",
                        "DatePublishedInvalid",
                        "DatePublishedRequired",
                        "SummaryTooLong",
                        "SummaryMissingOrEmpty",
                        "OneOfTagsTooLong",
                        "OneOfTagsEmpty",
                        "ThumbnailPhotoUriTooLong",
                        "failure",
                    ],
                    () => {
                        message.error(l("Registrations_Edit_Failure"));
                    },
                )
                .handle("ContentMissingOrEmpty", () => ({
                    [guard("articleContent")]: l("Common_Validation_FieldRequired"),
                }))
                .handle(["TitleTooLong", "TitleMissingOrEmpty"], error => ({
                    [guard("articleTitle")]:
                        error === "TitleTooLong"
                            ? l("Common_Validation_FieldTooLong")
                            : l("Common_Validation_FieldRequired"),
                }))
                .handle("success", () => {
                    wasArticleEditionSuccessful = true;
                })
                .check({
                    reducer: (prev, cur) => {
                        return {
                            ...prev,
                            ...(cur || {}),
                        };
                    },
                    initialValue: {} as RegistrationFormValidationErrors,
                });

            if (wasEditionSuccessful && wasArticleEditionSuccessful) {
                message.success(l("Registrations_Edit_Success"));

                push(routes.registrations());

                return undefined;
            }

            return { ...(editionErrors ?? {}), ...articleEditionErrors };
        },
        [push, registration],
    );

    return useObserver(() => (
        <PageContent>
            <PageContent.Header>
                <PageHeader
                    title={l("Registrations_Edit")}
                    onBack={() => push(routes.registrations())}
                    breadcrumb={{
                        routes: breadcrumb,
                        itemRender: ({ path, breadcrumbName }) =>
                            path === location.pathname ? breadcrumbName : <Link to={path}>{breadcrumbName}</Link>,
                    }}
                />
            </PageContent.Header>
            <PageContent.Card>
                <Skeleton loading={registrationDetailsFetchInProgress} active={registrationDetailsFetchInProgress}>
                    {registration && registration.hasDetails && registration.article && (
                        <RegistrationForm
                            registrationId={registrationId}
                            initialValues={{
                                title: registration.title,
                                thumbnailPhotoUri: registration.thumbnailPhotoUri,
                                backgroundPhotoUri: registration.backgroundPhotoUri,
                                endDate: registration.endDate,
                                startDate: registration.startDate,
                                entryFee: registration.entryFee?.toString(),
                                logoUri: registration.logoUri,
                                summary: registration.summary,
                                maxTeamsCount: registration.maxTeamsCount?.toString(),
                                articleTitle: registration.article.title,
                                articleContent: registration.article.content,
                                address: registration.address,
                                sportsVenueName: registration.sportsVenueName,
                                city: registration.city,
                                extraFormFieldDescription: registration.extraFormFieldDescription,
                                documents: registration.documents?.map(d => ({
                                    id: d.id,
                                    name: d.name,
                                    uri: d.uri,
                                    requiresAcceptance: d.requiresAcceptance,
                                })),
                                ageGroups: registration.ageGroups?.map(
                                    ag => ag.maxAllowedBirthYear?.toString() ?? openAgeGroupValue,
                                ),
                            }}
                            mode="edit"
                            selectableMaxBirthYears={registration.allPossibleMaxBirthYears}
                            onCancel={() => push(routes.registrations())}
                            onSave={editRegistation}
                        />
                    )}
                </Skeleton>
            </PageContent.Card>
        </PageContent>
    ));
};

export default EditRegistrationPage;
