import { message } from "antd";
import ImgCrop, { ImgCropProps } from "antd-img-crop";
import { l } from "Languages";
import React from "react";
import { isValidImageFileExtension } from "Utils/validationHelpers";

type ImageCropProps = ImgCropProps;

const ImageCrop: React.FunctionComponent<ImageCropProps> = ({ children, ...imgCropProps }) => {
    return (
        <ImgCrop
            shape="round"
            modalOk={l("Common_Save")}
            modalCancel={l("Common_Cancel")}
            modalTitle={l("ImageUpload_CropModalTitle")}
            beforeCrop={file => {
                if (isValidImageFileExtension(file.name)) {
                    return true;
                } else {
                    message.error(l("ImageUpload_InvalidFormat"));

                    return false;
                }
            }}
            {...imgCropProps}>
            {children}
        </ImgCrop>
    );
};

export default ImageCrop;
