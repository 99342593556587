import { ExclamationCircleFilled } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Tag } from "antd";
import Spacing from "Components/Spacing";
import TeamParticipatingInMatch from "Components/TeamParticipatingInMatch";
import { PhaseTypeDTO } from "Contracts/PlayooLeagueClient";
import { CompetitionPhase } from "Domain/CompetitionPhases/CompetitionPhase";
import { formatMatchInPhase } from "Domain/CompetitionPhases/formatting";
import KnockoutPhase from "Domain/CompetitionPhases/KnockoutPhase/KnockoutPhase";
import { Match } from "Domain/Matches/Match";
import moment from "moment";
import React, { useMemo } from "react";
import { Draggable } from "react-beautiful-dnd";
import { formatTime } from "Utils/formatting";
import styles from "./styles.scss";

const cx = mkCx(styles);

type MatchItemDraggableProps = {
    index: number;
    match: Match;
    phase?: CompetitionPhase;

    date?: moment.Moment;

    conflictingMatches?: Match[];

    hoveredTeamId?: string;
    onTeamHover: (teamId?: string) => void;

    hoveredMatchId?: string;
    onHover: (matchId?: string) => void;
};

const MatchItemDraggable: React.FunctionComponent<MatchItemDraggableProps> = ({
    index,
    match,
    phase,
    date,
    conflictingMatches,
    hoveredTeamId,
    onTeamHover,
    hoveredMatchId,
    onHover,
}) => {
    const formattedMatchInPhase =
        match.name ?? formatMatchInPhase(match, phase instanceof KnockoutPhase ? phase.isLegacyPhase : undefined);

    const isTeam1Conflicting = useMemo(
        () =>
            conflictingMatches &&
            match.team1Id !== undefined &&
            conflictingMatches.some(m => m.team1Id === match.team1Id || m.team2Id === match.team1Id),
        [conflictingMatches, match.team1Id],
    );

    const isTeam2Conflicting = useMemo(
        () =>
            conflictingMatches &&
            match.team2Id !== undefined &&
            conflictingMatches.some(m => m.team1Id === match.team2Id || m.team2Id === match.team2Id),
        [conflictingMatches, match.team2Id],
    );

    const conflictsBetweenMatchesExist = isTeam1Conflicting || isTeam2Conflicting;

    const shouldHighlightConflictBasedOnCurrentMatchHover = useMemo(() => {
        if (!conflictsBetweenMatchesExist) {
            return false;
        }

        return hoveredMatchId === match.id || conflictingMatches?.some(m => m.id === hoveredMatchId);
    }, [conflictingMatches, conflictsBetweenMatchesExist, hoveredMatchId, match.id]);

    return (
        <Draggable draggableId={match.id} index={index}>
            {provided => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onMouseEnter={() => onHover(match.id)}
                    onMouseLeave={() => onHover(undefined)}>
                    <Spacing
                        childrenGutterX
                        className={cx(
                            "match-item",
                            shouldHighlightConflictBasedOnCurrentMatchHover && "conflicting-match-highlight",
                        )}>
                        <div className={cx("date-container")}>
                            {date && formatTime(date)}
                            {conflictsBetweenMatchesExist && (
                                <ExclamationCircleFilled className={cx("conflicting-match-icon")} />
                            )}
                        </div>
                        <div className={cx("teams-and-tags")}>
                            <TeamParticipatingInMatch
                                match={match}
                                side="team1"
                                danger={isTeam1Conflicting}
                                reserveSpaceForHighlight
                                className={cx(
                                    hoveredTeamId !== undefined &&
                                        hoveredTeamId === match.team1Id &&
                                        "highlighted-team-name",
                                )}
                                onMouseEnter={() => onTeamHover(match.team1Id)}
                                onMouseLeave={() => onTeamHover(undefined)}
                                knockoutPhase={
                                    phase?.type === PhaseTypeDTO.Knockout ? (phase as KnockoutPhase) : undefined
                                }
                            />
                            <TeamParticipatingInMatch
                                match={match}
                                side="team2"
                                danger={isTeam2Conflicting}
                                reserveSpaceForHighlight
                                className={cx(
                                    hoveredTeamId !== undefined &&
                                        hoveredTeamId === match.team2Id &&
                                        "highlighted-team-name",
                                )}
                                onMouseEnter={() => onTeamHover(match.team2Id)}
                                onMouseLeave={() => onTeamHover(undefined)}
                                knockoutPhase={
                                    phase?.type === PhaseTypeDTO.Knockout ? (phase as KnockoutPhase) : undefined
                                }
                            />
                            {phase?.competitionName && (
                                <Tag className={cx("tag")}>
                                    {phase.competitionName} - {phase.name ?? ""}
                                </Tag>
                            )}
                            {formattedMatchInPhase && (
                                <Tag className={cx("tag")} color={match.name ? "blue" : undefined}>
                                    {formattedMatchInPhase}
                                </Tag>
                            )}
                        </div>
                    </Spacing>
                </div>
            )}
        </Draggable>
    );
};

export default MatchItemDraggable;
