import UnauthorizedAppLayout from "Components/AuthenticationLayout";
import NavigationLink from "Components/NavigationLink";
import { l } from "Languages";
import React from "react";
import routes, { PageComponent } from "Router/routes";

const TenantExists: PageComponent<typeof routes.signUp.tenantExists> = () => {
    return (
        <UnauthorizedAppLayout>
            <UnauthorizedAppLayout.Header>{l("SignUp_TenantExists_Title")}</UnauthorizedAppLayout.Header>
            <UnauthorizedAppLayout.Actions>
                <NavigationLink type="button" to={routes.index()}>
                    {l("SignUp_GoToSignIn")}
                </NavigationLink>
            </UnauthorizedAppLayout.Actions>
        </UnauthorizedAppLayout>
    );
};

export default TenantExists;
