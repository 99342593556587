import moment from "moment";
import "moment/locale/pl";
import PolishTranslations from "./pl";

type TranslationType = string | number | undefined;
type TranslationsType = typeof PolishTranslations;
export type TranslationsKeys<TTranslationsType = TranslationsType> = keyof TTranslationsType;
type TranslationsDictionary<TTranslationsType> = {
    [K in TranslationsKeys<TTranslationsType>]: (...items: TranslationType[]) => string;
};

export type Language = "pl";

const languages: { [key in Language]: TranslationsType } = {
    pl: PolishTranslations,
};

export const supportedLanguages: Language[] = ["pl"];

const getDefaultLanguage = (): Language => "pl";

export let currentLanguage: Language = getDefaultLanguage();

function getTranslationParts(translation: string) {
    const regex = /{([0-9]+)}/g;
    let matches:
        | {
              index: number;
              length: number;
              argNum: number;
          }[]
        | undefined = undefined;
    let match: RegExpExecArray | null;

    do {
        match = regex.exec(translation);
        if (match) {
            matches = [
                ...(matches || []),
                {
                    index: match.index,
                    length: match.length,
                    argNum: Number(match[1]),
                },
            ];
        }
    } while (match);

    if (!matches) {
        return undefined;
    }

    const translationParts: (string | number)[] = [];
    let currentIndex = 0;

    for (const m of matches) {
        if (m.index !== currentIndex) {
            translationParts.push(translation.substring(currentIndex, m.index));
        }
        currentIndex = m.index + m.length + 1;
        translationParts.push(m.argNum);
    }

    if (currentIndex !== translation.length) {
        translationParts.push(translation.substr(currentIndex));
    }

    return translationParts;
}

function compilePattern(translation: string): (...items: TranslationType[]) => string {
    const translationParts = getTranslationParts(translation);

    if (!translationParts) {
        return () => translation;
    }

    return (...items: TranslationType[]) => translationParts.map(t => (typeof t === "string" ? t : items[t])).join("");
}

function createTranslationsDictionary<TTranslationsType extends { [key: string]: string }>(
    translations: TTranslationsType,
): TranslationsDictionary<TTranslationsType> {
    const ret: TranslationsDictionary<TTranslationsType> = {} as any;

    for (const key in translations) {
        if ((key as string) === "default") {
            continue;
        }

        ret[key] = compilePattern(translations[key]);
    }

    return ret;
}

let translations = createTranslationsDictionary(languages[getDefaultLanguage()]);

export function setAppLanguage(language?: string) {
    currentLanguage = (language as Language) || getDefaultLanguage();
    moment.locale(language);
    const languageTranslation = languages[currentLanguage];
    translations = createTranslationsDictionary(languageTranslation);
}

export function setDefaultLanguage() {
    currentLanguage = getDefaultLanguage();
    translations = createTranslationsDictionary(languages[currentLanguage]);
}

export function l(a: TranslationsKeys<TranslationsType>, ...items: TranslationType[]): string {
    return translations[a](...items);
}
