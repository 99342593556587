import { message } from "antd";
import Competition from "Domain/Competitions/Competition";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import { l } from "Languages";
import _ from "lodash";
import { action, computed, observable } from "mobx";
import newId from "Utils/newId";
import { TeamReplacement } from "../CompetitionPhaseBase";
import { LinkablePhase } from "../ExternalReferences/ExternalReference";
import AvailableTeamsForGroupsPhase from "./AvailableTeamsForGroupsPhase";
import GroupsPhase, { ScheduleGenerationStatus } from "./GroupsPhase";
import GroupsPhaseTeamsSelection from "./GroupsPhaseTeamsSelection";
import TeamGroup from "./TeamGroup";

class GroupsPhaseConfigurator {
    private readonly phase: GroupsPhase;
    private readonly competition: Competition;
    private readonly linkedPhase?: LinkablePhase;

    @observable teamsSelection?: GroupsPhaseTeamsSelection;
    @observable availableTeams: AvailableTeamsForGroupsPhase;

    @observable groups: TeamGroup[];

    constructor(phase: GroupsPhase, competition: Competition, linkedPhase?: LinkablePhase) {
        if (phase.linkedPhaseId && !linkedPhase) {
            throw new Error("Previous phase needs to be available if phase is linked.");
        }

        this.phase = phase;
        this.competition = competition;
        this.linkedPhase = linkedPhase;

        this.availableTeams = new AvailableTeamsForGroupsPhase(phase, competition, linkedPhase);

        if (this.phase.groups && this.phase.groups.length > 0) {
            this.syncGroups();
        } else {
            this.groups = [0, 1].map(
                index =>
                    new TeamGroup(
                        newId(),
                        {
                            name: this.getDefaultGroupName(index),
                            teamIds: [],
                        },
                        this.phase.competitionTeamStore,
                    ),
            );
        }
    }

    @computed get groupsEditionDisabled() {
        return (
            this.phase.scheduleGenerationStatus === ScheduleGenerationStatus.Generated ||
            this.competition.isPhaseReferencedByOtherPhase(this.phase.id)
        );
    }

    @action.bound
    startAddingTeamsToGroup(groupId: string) {
        this.teamsSelection = new GroupsPhaseTeamsSelection(groupId, this.availableTeams, this.phase, this.linkedPhase);
    }

    @action.bound
    cancelAddingTeamsToGroup() {
        this.teamsSelection = undefined;
    }

    private getDefaultGroupName(groupIndex: number) {
        const charCodeForCapitalA = 65;

        return `${l("CompetitionPhases_Groups_DefaultGroupPrefix")} ${String.fromCharCode(
            charCodeForCapitalA + groupIndex,
        )}`;
    }

    @computed private get assignedTeamsMap(): Map<string, string> {
        return new Map(this.groups.flatMap(g => g.teamIds.map(t => [t, g.id])));
    }

    isTeamAddedToOneOfGroups(teamId: string) {
        return this.phase.isTeamAddedToOneOfGroups(teamId);
    }

    @computed get canAdvanceAnyPlaceholderTeam() {
        return this.phase.placeholdersReadyForAdvancing && this.phase.placeholdersReadyForAdvancing.length > 0;
    }

    @computed get externalReferencesForAdvancingTeams() {
        return this.phase.externalReferencesForAdvancingTeams;
    }

    @action.bound
    moveTeam(teamId: string, destination: { groupId?: string; index: number }) {
        const sourceGroupId = this.assignedTeamsMap.get(teamId);

        const sourceGroup = sourceGroupId ? this.groups.find(g => g.id === sourceGroupId) : undefined;
        const destinationGroup = destination.groupId ? this.groups.find(g => g.id === destination.groupId) : undefined;

        if (sourceGroup) {
            sourceGroup.setTeams(sourceGroup.teamIds.filter(t => t !== teamId));
        }

        if (destinationGroup) {
            const destinationGroupTeamIds = [...destinationGroup.teamIds];
            destinationGroupTeamIds.splice(destination.index, 0, teamId);

            destinationGroup.setTeams(destinationGroupTeamIds);
        }

        return this.save(l("CompetitionPhases_Groups_Save_Success"), l("CompetitionPhases_Groups_Save_Failure"));
    }

    @action.bound
    addGroup() {
        this.groups.push(
            new TeamGroup(
                newId(),
                {
                    name: this.getDefaultGroupName(this.groups.length),
                    teamIds: [],
                },
                this.phase.competitionTeamStore,
            ),
        );

        return this.save(
            l("CompetitionPhases_Groups_AddGroup_Success"),
            l("CompetitionPhases_Groups_AddGroup_Failure"),
        );
    }

    @action.bound
    deleteGroup(groupId: string) {
        const groupIndex = this.groups.findIndex(g => g.id === groupId);

        if (groupIndex < 0) {
            throw new Error("Group to delete not found");
        }

        this.groups.splice(groupIndex, 1);

        return this.save(
            l("CompetitionPhases_Groups_DeleteGroup_Success"),
            l("CompetitionPhases_Groups_DeleteGroup_Failure"),
        );
    }

    @action.bound
    renameGroup(groupId: string, name: string) {
        const group = this.groups.find(g => g.id === groupId);

        if (!group) {
            throw new Error("Group to rename not found.");
        }

        group.setName(name);

        return this.save(
            l("CompetitionPhases_Groups_RenameGroup_Success"),
            l("CompetitionPhases_Groups_RenameGroup_Failure"),
        );
    }

    async removeTeamFromGroup(team: CompetitionTeam, groupId: string) {
        const removeTeamFromDraft = () => {
            const group = this.groups.find(g => g.id === groupId);

            if (!group) {
                throw new Error("Group not found.");
            }

            group.setTeams(group.teamIds.filter(t => t !== team.id));
        };

        if (team.isPlaceholderForSinglePhaseOnly) {
            await this.competition.deletePlaceholderTeamForPhase(this.phase.id, team.id);

            removeTeamFromDraft();
        } else {
            await this.phase.removeTeam(team.id);

            this.syncGroups();
        }
    }

    async addPlaceholderTeamToGroup(groupId: string, placeholderName: string) {
        const placeholder = await this.phase.addPlaceholderTeam(placeholderName);

        if (!placeholder) {
            return;
        }

        const group = this.groups.find(g => g.id === groupId);

        if (!group) {
            throw new Error("Group not found.");
        }

        group.setTeams([...group.teamIds, placeholder.id]);

        return this.save();
    }

    async replacePlaceholdersForPhase(replacements: TeamReplacement[]) {
        const success = await this.phase.replacePlaceholderTeams(replacements);

        if (success) {
            this.syncGroups();
        }

        return success;
    }

    async advanceTeamForGroupsPhaseReference(placeholderId: string) {
        const success = await this.phase.advanceTeamForGroupsPhaseReference(placeholderId);

        if (success) {
            this.syncGroups();
        }
    }

    async revokeTeamAdvancing(externalReferenceId: string) {
        const success = await this.phase.revokeTeamAdvancing(externalReferenceId);

        if (success) {
            this.syncGroups();
        }
    }

    async advanceTeamsForAllGroupsPhaseReferences() {
        const success = await this.phase.advanceTeamsForAllGroupsPhaseReferences();

        if (success) {
            this.syncGroups();
        }
    }

    renamePlaceholderTeamForPhase(placeholderId: string, name: string) {
        return this.phase.renamePlaceholderTeam(placeholderId, name);
    }

    @action.bound
    async swapTeams(team1Id: string, team2Id: string) {
        const success = await this.phase.swapTeams(team1Id, team2Id);

        if (success) {
            this.syncGroups();
        }

        return success;
    }

    async saveTeamsAddition() {
        if (!this.teamsSelection) {
            return;
        }

        const isDraftSaved = await this.ensureDraftSaved();

        if (isDraftSaved) {
            const wasTeamsAdditionSuccessfull = await this.addTeams(this.teamsSelection);
            const wasPlaceInGroupReferencesAdditionSuccessful = await this.addPlaceInGroupReferences(
                this.teamsSelection,
            );
            const wasNthBestForPlaceInGroupReferencesAdditionSuccessful = await this.addNthBestForPlaceInGroupReferences(
                this.teamsSelection,
            );
            const wasPlaceInTableReferencesAdditionSuccessful = await this.addPlaceInTableReferences(
                this.teamsSelection,
            );

            if (
                wasPlaceInGroupReferencesAdditionSuccessful &&
                wasTeamsAdditionSuccessfull &&
                wasNthBestForPlaceInGroupReferencesAdditionSuccessful &&
                wasPlaceInTableReferencesAdditionSuccessful
            ) {
                await this.phase.fetchDetails();

                message.success(l("CompetitionPhases_Groups_AddTeams_Success"));
            } else {
                message.error(l("CompetitionPhases_Groups_AddTeams_Failure"));
            }
        }

        this.syncGroups();
        this.cancelAddingTeamsToGroup();
    }

    @action.bound
    syncGroups() {
        if (this.phase.groups) {
            this.groups = this.phase.groups.map(g => g.clone());
        }
    }

    private async ensureDraftSaved() {
        const isDraftSaved = _.isEqual(this.groups, this.phase.groups);

        if (!isDraftSaved) {
            return this.save(undefined, l("CompetitionPhases_Groups_Save_Failure"));
        }

        return true;
    }

    private addTeams(selection: GroupsPhaseTeamsSelection) {
        if (selection.teamsSelection.length === 0) {
            return true;
        }

        return this.phase.addTeamsToGroup(
            selection.teamsSelection.map(t => t.id),
            selection.groupId,
        );
    }

    private addPlaceInGroupReferences(selection: GroupsPhaseTeamsSelection) {
        if (!selection.placeInGroupReferencesSelection || selection.placeInGroupReferencesSelection.length === 0) {
            return true;
        }

        return this.phase.addPlaceInGroupReferencesToGroup(
            selection.placeInGroupReferencesSelection,
            selection.groupId,
        );
    }

    private addNthBestForPlaceInGroupReferences(selection: GroupsPhaseTeamsSelection) {
        if (
            !selection.nthBestForPlaceInGroupReferencesSelection ||
            selection.nthBestForPlaceInGroupReferencesSelection.length === 0
        ) {
            return true;
        }

        return this.phase.addNthBestForPlaceInGroupReferencesToGroup(
            selection.nthBestForPlaceInGroupReferencesSelection,
            selection.groupId,
        );
    }

    private addPlaceInTableReferences(selection: GroupsPhaseTeamsSelection) {
        if (!selection.placeInTableReferencesSelection || selection.placeInTableReferencesSelection.length === 0) {
            return true;
        }

        return this.phase.addPlaceInTableReferencesToGroup(
            selection.placeInTableReferencesSelection,
            selection.groupId,
        );
    }

    async save(messageSuccess?: string, messageFailure?: string) {
        const result = await this.saveGroups();

        let success = false;

        result
            .handle(
                [
                    "PhaseNotFound",
                    "PhaseScheduleGenerated",
                    "TeamGroupDuplicateId",
                    "TeamGroupNameMissingOrEmpty",
                    "TeamGroupNameTooLong",
                    "TeamGroupTeamIdsTooMany",
                    "TeamGroupTeamInMultipleGroups",
                    "TeamGroupTeamNotFound",
                    "TeamGroupTeamIdsMissing",
                    "TeamGroupsNullOrMissing",
                    "PhaseReferencedByOtherPhase",
                    "failure",
                ],
                () => messageFailure && message.error(messageFailure),
            )
            .handle("success", () => {
                messageSuccess && message.success(messageSuccess);

                success = true;
            })
            .check();

        this.syncGroups();

        return success;
    }

    private saveGroups() {
        return this.phase.setGroups(this.groups.map(g => g.clone()));
    }
}

export default GroupsPhaseConfigurator;
