import { handleResponse } from "@leancode/validation";
import { EditVideo, VideoDTO } from "Contracts/PlayooLeagueClient";
import { observable } from "mobx";
import moment from "moment";
import api from "Services/Api";
import blobStorageClient from "Services/BlobStorageClient";
import { dateTimeFromDTO, dateTimeToDTO } from "Utils/DTO";
import extractExtension from "Utils/extractExtension";
import newId from "Utils/newId";
import retryQuery from "Utils/retryQuery";

class Video {
    readonly id: string;

    @observable title: string;
    @observable thumbnailUri: string;
    @observable uri: string;
    @observable dateRecorded: moment.Moment;

    constructor(id: string, { title, thumbnailUri, uri, dateRecorded }: VideoInit) {
        this.id = id;
        this.title = title;
        this.thumbnailUri = thumbnailUri;
        this.uri = uri;
        this.dateRecorded = dateRecorded;
    }

    static fromDTO(dto: VideoDTO) {
        return new Video(dto.Id, {
            title: dto.Title,
            thumbnailUri: dto.ThumbnailUri,
            uri: dto.Uri,
            dateRecorded: dateTimeFromDTO(dto.DateRecorded),
        });
    }

    async edit({ title, thumbnail, uri, dateRecorded }: EditVideoData) {
        let thumbnailUri: string;

        if (typeof thumbnail === "string") {
            thumbnailUri = thumbnail;
        } else {
            thumbnailUri = await Video.uploadThumbnail(thumbnail);
        }

        const response = await api.editVideo({
            VideoId: this.id,
            Title: title,
            Uri: uri,
            ThumbnailUri: thumbnailUri,
            DateRecorded: dateTimeToDTO(dateRecorded),
        });

        return handleResponse(response, EditVideo);
    }

    static async uploadThumbnail(thumbnail: File): Promise<string> {
        const extension = extractExtension(thumbnail);

        if (!extension) {
            throw new Error("Failed to obtain video thumbnail extension. Cannot upload.");
        }

        const uploadLinks = await retryQuery(() =>
            api.generatePresignedVideoThumbnailUploadLink({
                FileName: `${newId()}.${extension}`,
            }),
        );

        await blobStorageClient.uploadForPresignedLink(thumbnail, uploadLinks);

        return uploadLinks.Link;
    }
}

type EditVideoData = {
    thumbnail: string | File;
    uri: string;
    title: string;
    dateRecorded: moment.Moment;
};

type VideoInit = Pick<Video, "title" | "uri" | "thumbnailUri" | "dateRecorded">;

export default Video;
