import { MatchSlotDTO, MatchStatusDTO } from "Contracts/PlayooLeagueClient";
import competitionStore from "Domain/Competitions";
import CompetitionSportsField from "Domain/Competitions/CompetitionSportsField";
import CompetitionStore from "Domain/Competitions/CompetitionStore";
import { Match } from "Domain/Matches/Match";
import { l } from "Languages";
import { action, computed, observable } from "mobx";
import moment from "moment";
import api from "Services/Api";
import { dateTimeOffsetToDTOOptional } from "Utils/DTO";
import newId from "Utils/newId";
import { notUndefined } from "Utils/predicates";
import retryQuery from "Utils/retryQuery";
import competitionPhaseStore from "..";
import CompetitionPhaseStore from "../CompetitionPhaseStore";
import SeasonPlannerFilter from "./Filters/SeasonPlannerFilters";
import MatchdayConfiguration, { SportsFieldMatchesPlan } from "./MatchdayConfiguration";
import SchedulePlanner, { SchedulePlannerValidationErrors } from "./SchedulePlanner";
import { Slot, isMatch } from "./Slot";
import SlotsConfiguration from "./SlotsConfiguration";
import { maxBy } from "lodash";
import { getMatchdays, withBreaksBetweenTooDistantMatches } from "./common";
import definedOrThrow from "Utils/empty";

type CompetitionSportsFieldInfoMap = Record<string, { competitionId: string; sportsFieldId: string }>;

class SeasonSchedulePlanner {
    private readonly seasonId: string;
    private readonly competitionStore: CompetitionStore = competitionStore;
    private readonly phaseStore: CompetitionPhaseStore = competitionPhaseStore;

    @observable status: "initialized" | "loading" | "loaded" = "initialized";

    @observable competitionsIds: string[] = [];

    @observable filter?: SeasonPlannerFilter;

    @observable sportsFields: CompetitionSportsField[];

    @observable slotsConfiguration: SlotsConfiguration;

    @observable private matchdaysConfiguration: MatchdayConfiguration[];

    @observable currentMatchdayConfiguration: MatchdayConfiguration;

    constructor(seasonId: string) {
        this.seasonId = seasonId;
    }

    @action.bound
    async fetchSeasonData() {
        this.status = "loading";

        const competitions = await retryQuery(() => api.competitionsInSeason({ SeasonId: this.seasonId }));

        this.competitionsIds = competitions.map(c => c.Id);

        await Promise.all(this.competitionsIds.map(id => this.competitionStore.fetchCompetitionDetails(id)));

        await Promise.all(
            this.allCompetitions.map(c =>
                c.phases
                    ? Promise.all(c.phases.map(p => this.competitionStore.fetchCompetitionPhaseDetails(p.id)))
                    : undefined,
            ),
        );

        const fetchedPhases = this.allCompetitions
            .filter(c => c.phases)
            .flatMap(c => c.phases)
            .filter(notUndefined);

        if (!fetchedPhases.some(p => p.schedule)) {
            throw new Error("No phases with schedule.");
        }
        const schedule = fetchedPhases.flatMap(p => p.schedule as Match[]).filter(notUndefined);

        const slotsConfig = fetchedPhases.reduce(
            (acc, curr) =>
                curr?.defaultMatchDurationInMinutes !== undefined &&
                curr.defaultMatchDurationInMinutes > acc.matchDurationInMinute
                    ? {
                          matchDurationInMinute: curr.defaultMatchDurationInMinutes,
                          pauseBetweenMatchesInMinutes:
                              curr.defaultPauseBetweenMatchesInMinutes ?? acc.pauseBetweenMatchesInMinutes,
                      }
                    : acc,
            {
                matchDurationInMinute: -1,
                pauseBetweenMatchesInMinutes: SchedulePlanner.defaultPauseBetweenMatchesInMinutes,
            },
        );

        this.slotsConfiguration = new SlotsConfiguration({
            matchDurationInMinutes:
                slotsConfig.matchDurationInMinute === -1
                    ? SchedulePlanner.defaultMatchDurationInMinutes
                    : slotsConfig.matchDurationInMinute,
            pauseBetweenMatchesInMinutes: slotsConfig.pauseBetweenMatchesInMinutes,
        });

        const maxSportsFields =
            maxBy(this.allCompetitions, competition => competition.sportsFields?.length ?? 0)?.sportsFields ?? [];

        this.sportsFields =
            maxSportsFields.length > 0
                ? maxSportsFields.map(({ name }) => new CompetitionSportsField(newId(), name))
                : [new CompetitionSportsField(newId(), this.getDefaultSportsFieldName(0))];

        const matchdays = getMatchdays(schedule);

        this.matchdaysConfiguration = matchdays.map(matchday => {
            const matchdaySchedule = schedule
                .filter(m => matchday.isSame(m.date, "day"))
                .sort((a, b) => definedOrThrow(a.date).diff(definedOrThrow(b.date)));

            const earliestMatchInMatchday = matchdaySchedule[0];

            const matchesPlan: SportsFieldMatchesPlan[] = this.sportsFields.map(sf => {
                const matchesOnSportsField = matchdaySchedule.filter(
                    m =>
                        m.sportsFieldId &&
                        this.competitionSportsFieldToCompetitionIdAndSportsFieldMap[m.sportsFieldId].sportsFieldId ===
                            sf.id,
                );

                return {
                    sportsField: sf,
                    slots: this.withBreaksBetweenTooDistantMatches(matchesOnSportsField),
                    startTime: matchesOnSportsField[0]?.date ?? earliestMatchInMatchday?.date,
                };
            });

            return new MatchdayConfiguration(matchday, matchesPlan, this.slotsConfiguration, schedule);
        });

        if (this.matchdaysConfiguration.length === 0) {
            this.matchdaysConfiguration = [
                new MatchdayConfiguration(
                    undefined,
                    this.sportsFields.map(sf => ({
                        sportsField: sf,
                        slots: [],
                        startTime: undefined,
                    })),
                    this.slotsConfiguration,
                    schedule,
                ),
            ];
        }

        this.currentMatchdayConfiguration = this.matchdaysConfiguration[0];

        this.filter = new SeasonPlannerFilter(this.allCompetitions);

        this.status = "loaded";
    }

    @computed get competitionSportsFieldToCompetitionIdAndSportsFieldMap() {
        return this.allCompetitions.reduce(
            (accumulator, current) => ({
                ...accumulator,
                ...current.sportsFields?.reduce(
                    (sfAccumulator, sfCurrent, index) => ({
                        ...sfAccumulator,
                        [sfCurrent.id]: { competitionId: current.id, sportsFieldId: this.sportsFields[index].id },
                    }),
                    {} as CompetitionSportsFieldInfoMap,
                ),
            }),
            {} as CompetitionSportsFieldInfoMap,
        );
    }

    @computed get allCompetitions() {
        return this.competitionsIds.map(id => this.competitionStore.getById(id)).filter(notUndefined);
    }
    @computed get allPhases() {
        return this.allCompetitions
            .filter(c => c.phases)
            .flatMap(c =>
                c.phases?.map(p => {
                    p.competitionName = c.name;
                    return p;
                }),
            )
            .filter(notUndefined);
    }

    @computed get fullSchedule() {
        return this.allPhases.flatMap(p => p.schedule as Match[]).filter(notUndefined);
    }

    @computed get matchdays(): moment.Moment[] {
        return this.matchdaysConfiguration?.map(mc => mc.day).filter(notUndefined) ?? [];
    }

    @computed get validationError(): SchedulePlannerValidationErrors | undefined {
        if (
            !this.slotsConfiguration?.isConfigured ||
            ((this.matchdaysConfiguration?.length ?? 0) > 0 && !this.matchdaysConfiguration[0]?.day)
        ) {
            return SchedulePlannerValidationErrors.MatchDatesNotGenerated;
        }

        if (this.assignedMatchesIds.size === 0) {
            return SchedulePlannerValidationErrors.ScheduleNotPlannedForAnySportsField;
        }
    }

    @computed get canInferMatchDates() {
        return this.slotsConfiguration?.isConfigured && !!this.currentMatchdayConfiguration?.day;
    }

    @computed get canPlanCurrentMatchdayAutomatically() {
        return this.canInferMatchDates && this.filteredUnassignedMatches.length > 0;
    }

    @computed get hasAnyNonCancelledUnassignedMatches() {
        return this.filteredUnassignedMatches.some(m => m.status !== MatchStatusDTO.Canceled);
    }

    @computed get assignedMatchesIds(): Set<string> {
        return new Set(this.matchdaysConfiguration?.flatMap(mc => mc.assignedMatchesIds) ?? []);
    }

    @computed get unassignedMatches(): Match[] {
        return this.fullSchedule.filter(t => !this.assignedMatchesIds.has(t.id)) ?? [];
    }

    @computed get filteredUnassignedMatches(): Match[] {
        return this.filter ? this.filter.apply(this.unassignedMatches) : this.unassignedMatches;
    }

    @action.bound
    setCurrentMatchday(matchday: moment.Moment) {
        const matchdayConfiguration = this.matchdaysConfiguration?.find(mc => mc.day && matchday.isSame(mc.day, "day"));

        if (matchdayConfiguration) {
            this.currentMatchdayConfiguration = matchdayConfiguration;
        }
    }

    private getDefaultSportsFieldName(index: number) {
        const charCodeForCapitalA = 65;

        return l(
            "CompetitionPhases_PlanSchedule_DefaultSportsFieldName",
            String.fromCharCode(charCodeForCapitalA + index),
        );
    }

    @action.bound
    addSportsField() {
        if (!this.sportsFields || !this.matchdaysConfiguration) return;

        const sportsField = new CompetitionSportsField(
            newId(),
            this.getDefaultSportsFieldName(this.sportsFields?.length ?? 0),
        );

        this.sportsFields.push(sportsField);

        this.matchdaysConfiguration.forEach(m => m.addSportsField(sportsField));
    }

    @action.bound
    addMatchday(matchday: moment.Moment) {
        if (!this.sportsFields || !this.matchdaysConfiguration || !this.slotsConfiguration) return;

        const matchdayConfiguration = new MatchdayConfiguration(
            matchday,
            this.sportsFields?.map(sf => ({
                sportsField: sf,
                startTime: moment(matchday),
                slots: [],
            })),
            this.slotsConfiguration,
            this.fullSchedule,
        );

        this.matchdaysConfiguration = [...this.matchdaysConfiguration, matchdayConfiguration].sort(
            (a, b) => a.day?.diff(b.day) ?? -1,
        );

        this.currentMatchdayConfiguration = matchdayConfiguration;
    }

    private withBreaksBetweenTooDistantMatches(matches: Match[]): Slot[] {
        return withBreaksBetweenTooDistantMatches(matches, this.slotsConfiguration.distanceBetweenMatchesInMinutes);
    }

    async save() {
        if (
            this.slotsConfiguration?.matchDurationInMinutes === undefined ||
            this.slotsConfiguration?.pauseBetweenMatchesInMinutes === undefined ||
            !this.sportsFields
        ) {
            throw new Error("Schedule planner is not configured.");
        }

        const updatedCompetitions = this.allCompetitions.filter(c => this.filter?.isCompetitionNotFilteredOut(c));

        const newSportsFieldsForCompetitions = updatedCompetitions.map(c => ({
            competition: c,
            newSportsFields: [
                ...(c.sportsFields?.map(csf => {
                    const sportField = this.sportsFields.find(
                        sf =>
                            sf.id === this.competitionSportsFieldToCompetitionIdAndSportsFieldMap[csf.id].sportsFieldId,
                    );

                    return new CompetitionSportsField(csf.id, sportField?.name ?? csf.name);
                }) ?? []),
                ...this.sportsFields
                    .slice(c.sportsFields?.length ?? 0)
                    .map(s => new CompetitionSportsField(newId(), s.name)),
            ],
        }));

        const sportsFieldsSaveHandler = await Promise.all(
            newSportsFieldsForCompetitions.map(({ competition, newSportsFields }) =>
                competition.setSportsFields(newSportsFields),
            ),
        );

        const sportsFieldsSavedSuccessfully = sportsFieldsSaveHandler.reduce((acc, handler) => {
            if (!acc) return false;

            let isSuccess = false;
            handler.handle("success", () => (isSuccess = true));
            return isSuccess;
        }, true);

        const updatedPhases = updatedCompetitions
            .flatMap(c => c.phases ?? [])
            .filter(p => this.filter?.isPhaseNotFilteredOut(p));

        if (sportsFieldsSavedSuccessfully) {
            try {
                for (const p of updatedPhases) {
                    const sportsFieldsForPhase = newSportsFieldsForCompetitions.find(
                        sf => sf.competition.id === p.competitionId,
                    )?.newSportsFields;

                    const unassignedMatches = this.unassignedMatches
                        .filter(m => m.phaseId === p.id)
                        .map<MatchSlotDTO>(m => ({
                            MatchId: m.id,
                            Date: undefined,
                            SportsFieldId: undefined,
                        }));

                    const assignedMatches =
                        this.matchdaysConfiguration?.flatMap(mc =>
                            mc.schedule.flatMap(sportsField => {
                                const sportsFieldFromCompetition = sportsFieldsForPhase?.find(
                                    sf =>
                                        this.competitionSportsFieldToCompetitionIdAndSportsFieldMap[sf.id]
                                            .sportsFieldId === sportsField.sportsField.id,
                                );

                                return sportsField.slots
                                    .filter(s => isMatch(s.slot))
                                    .filter(ps => (ps.slot as Match).phaseId === p.id)
                                    .map<MatchSlotDTO>(ps => ({
                                        MatchId: ps.slot.id,
                                        SportsFieldId: sportsFieldFromCompetition?.id,
                                        Date: dateTimeOffsetToDTOOptional(ps.startTime),
                                    }));
                            }),
                        ) ?? [];

                    await api.planPhaseSchedule({
                        CompetitionId: p.competitionId,
                        PhaseId: p.id,
                        DefaultMatchDurationInMinutes:
                            this.slotsConfiguration?.matchDurationInMinutes ??
                            SchedulePlanner.defaultMatchDurationInMinutes,
                        DefaultPauseBetweenMatchesInMinutes:
                            this.slotsConfiguration?.pauseBetweenMatchesInMinutes ??
                            SchedulePlanner.defaultPauseBetweenMatchesInMinutes,
                        Schedule: [...unassignedMatches, ...assignedMatches],
                    });
                }
            } catch {
                return { sportsFieldsSavedSuccessfully, schedulePlanSavedSuccessfully: false };
            }

            return { sportsFieldsSavedSuccessfully, schedulePlanSavedSuccessfully: true };
        } else {
            return { sportsFieldsSavedSuccessfully };
        }
    }
}

export default SeasonSchedulePlanner;
