import { handleResponse } from "@leancode/validation";
import { message } from "antd";
import { PartnerDTO, UpdatePartnerLink, UpdatePartnerLogo } from "Contracts/CmsClient";
import { l } from "Languages";
import { observable, runInAction } from "mobx";
import blobStorageClient from "Services/BlobStorageClient";
import cmsApi from "Services/CmsApi";
import extractExtension from "Utils/extractExtension";
import newId from "Utils/newId";
import retryQuery from "Utils/retryQuery";

class Partner {
    readonly id: string;

    @observable logoUri?: string;
    @observable linkUri?: string;

    constructor(id: string, { logoUri, linkUri }: PartnerInit) {
        this.id = id;
        this.logoUri = logoUri;
        this.linkUri = linkUri;
    }

    static fromDTO(dto: PartnerDTO) {
        return new Partner(dto.Id, {
            logoUri: dto.LogoUri ?? undefined,
            linkUri: dto.LinkUri ?? undefined,
        });
    }

    async updateLinkUri(linkUri?: string) {
        const response = await cmsApi.updatePartnerLink({
            PartnerId: this.id,
            LinkUri: linkUri,
        });

        const handler = handleResponse(response, UpdatePartnerLink);

        handler.handle("success", () => {
            runInAction(() => {
                this.linkUri = linkUri;
            });
        });

        return handler;
    }

    async updateLogo(file: File) {
        const extension = extractExtension(file);
        const blobName = `${this.id}/${newId()}.${extension}`;

        const messageKey = `logo-update-${this.id}`;
        message.loading({
            content: l("Landing_Partners_UpdateLogo_InProgress"),
            duration: 0,
            key: messageKey,
        });

        const displayErrorMessage = () =>
            message.error({
                content: l("Landing_Partners_UpdateLogo_Failure"),
                key: messageKey,
            });

        try {
            const token = await retryQuery(() => cmsApi.partnerLogosCreateToken({}));

            await blobStorageClient.uploadBlob(blobName, file, token);

            const logoUri = blobStorageClient.blobUriFromToken(token, blobName);

            const response = await cmsApi.updatePartnerLogo({
                PartnerId: this.id,
                LogoUri: logoUri,
            });

            handleResponse(response, UpdatePartnerLogo)
                .handle(["success"], () => {
                    message.success({
                        content: l("Landing_Partners_UpdateLogo_Success"),
                        key: messageKey,
                    });

                    runInAction(() => {
                        this.logoUri = logoUri;
                    });
                })
                .handle(
                    ["LogoUriMissingOrEmpty", "LogoUriNotValid", "LogoUriTooLong", "PartnerNotFound", "failure"],
                    () => displayErrorMessage(),
                );
        } catch (e) {
            displayErrorMessage();
        }
    }
}

type PartnerInit = Pick<Partner, "linkUri" | "logoUri">;

export default Partner;
