import { PlaceInGroupReferenceDTO } from "Contracts/PlayooLeagueClient";
import { l } from "Languages";
import { computed, observable } from "mobx";
import ExternalReferenceBase, { ExternalReferenceBaseInit, ExternalReferenceType } from "./ExternalReferenceBase";

class PlaceInGroupReference extends ExternalReferenceBase {
    readonly type = ExternalReferenceType.PlaceInGroup;

    readonly placeInGroup: number;

    readonly groupId: string;
    @observable groupName: string;

    constructor({ placeInGroup, groupId, groupName, ...baseInit }: PlaceInGroupReferenceInit) {
        super(baseInit);

        this.placeInGroup = placeInGroup;
        this.groupId = groupId;
        this.groupName = groupName;
    }

    static fromDTO(dto: PlaceInGroupReferenceDTO, phaseId: string) {
        return new PlaceInGroupReference({
            id: dto.Id,
            phaseId: phaseId,
            referencedPhaseId: dto.ReferencedPhaseId,
            placeInGroup: dto.PlaceInGroup,
            groupId: dto.GroupId,
            groupName: dto.GroupName,
            predictedAdvancingTeamId: dto.PredictedAdvancingTeamId ?? undefined,
            advancingTeamId: dto.AdvancingTeamId ?? undefined,
        });
    }

    @computed get displayName() {
        return l("CompetitionPhases_ExternalReferences_PlaceInGroup_DisplayName", this.placeInGroup, this.groupName);
    }
}

type PlaceInGroupReferenceInit = ExternalReferenceBaseInit &
    Pick<PlaceInGroupReference, "placeInGroup" | "groupId" | "groupName">;

export default PlaceInGroupReference;
