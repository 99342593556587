import { CustomTableScoringDTO } from "Contracts/PlayooLeagueClient";
import { observable } from "mobx";

class TablePhaseScoring {
    static readonly defaults = {
        victory: 3,
        draw: 1,
        defeat: 0,
    };

    @observable pointsPerVictory = TablePhaseScoring.defaults.victory;
    @observable pointsPerDraw = TablePhaseScoring.defaults.draw;
    @observable pointsPerDefeat = TablePhaseScoring.defaults.defeat;

    constructor(init?: TablePhaseScoringInit) {
        if (init) {
            this.pointsPerDefeat = init.pointsPerDefeat;
            this.pointsPerDraw = init.pointsPerDraw;
            this.pointsPerVictory = init.pointsPerVictory;
        }
    }

    static fromDTO(dto: CustomTableScoringDTO): TablePhaseScoring {
        return new TablePhaseScoring({
            pointsPerDefeat: dto.PointsPerDefeat,
            pointsPerDraw: dto.PointsPerDraw,
            pointsPerVictory: dto.PointsPerVictory,
        });
    }

    toDTO(): CustomTableScoringDTO {
        return {
            PointsPerDefeat: this.pointsPerDefeat,
            PointsPerDraw: this.pointsPerDraw,
            PointsPerVictory: this.pointsPerVictory,
        };
    }
}

type TablePhaseScoringInit = Pick<TablePhaseScoring, "pointsPerDefeat" | "pointsPerDraw" | "pointsPerVictory">;

export default TablePhaseScoring;
