import { Match } from "Domain/Matches/Match";
import _ from "lodash";
import moment from "moment";
import MatchdayConfiguration from "../MatchdayConfiguration";
import { areSlotsOverlapping } from "../predicates";
import { isMatch } from "../Slot";
import SlotsConfiguration from "../SlotsConfiguration";
import SlotsIterator, { EmptySlot } from "../SlotsIterator";
import { AvailableSportsFieldsConfiguration } from "../SportsFieldConfiguration";

type TeamId = string;

type TeamsLastMatchTimes = Map<TeamId, moment.Moment | undefined>;

export enum PlanResult {
    FullyPlanned,
    PartiallyPlanned,
}

export type SportsFieldConfiguration = {
    closingTime?: moment.Moment;
};

class GreedyMatchdayScheduler {
    private readonly slotsConfiguration: SlotsConfiguration;
    private readonly slotsIterator: SlotsIterator;

    constructor(slotsConfiguration: SlotsConfiguration) {
        this.slotsConfiguration = slotsConfiguration;
        this.slotsIterator = new SlotsIterator(slotsConfiguration);
    }

    plan(
        matchdayConfiguration: MatchdayConfiguration,
        matches: Match[],
        availableSportsFieldsConfiguration: AvailableSportsFieldsConfiguration,
    ): PlanResult {
        let matchesToPlan = [...matches];

        const teamLastMatchesTimes = this.createTeamsLastMatchTimesMap(
            matchesToPlan,
            matchdayConfiguration,
            availableSportsFieldsConfiguration,
        );

        while (matchesToPlan.length > 0) {
            const slot = this.getNextSlot(matchdayConfiguration, availableSportsFieldsConfiguration);

            if (!slot) {
                return PlanResult.PartiallyPlanned;
            }

            const teamsNotAvailableForSlot = this.getTeamsNotAvailableForSlot(matchdayConfiguration, slot.startTime);

            const matchesAvailableForSlot = this.getMatchesAvailableForSlot(matchesToPlan, teamsNotAvailableForSlot);

            const matchWithHighestPriority = this.getMatchWithHighestPriority(
                matchesAvailableForSlot,
                slot.startTime,
                teamLastMatchesTimes,
            );

            if (!matchWithHighestPriority) {
                return PlanResult.PartiallyPlanned;
            }

            this.assignMatchToSlot(matchWithHighestPriority, slot, matchdayConfiguration);
            this.updateTeamsLastMatchTimes(teamLastMatchesTimes, matchWithHighestPriority, slot);

            matchesToPlan = matchesToPlan.filter(m => m.id !== matchWithHighestPriority.id);
        }

        return PlanResult.FullyPlanned;
    }

    getNextSlot(
        matchdayConfiguration: MatchdayConfiguration,
        availableSportsFieldsConfiguration: AvailableSportsFieldsConfiguration,
    ): EmptySlot | undefined {
        return this.slotsIterator.getNextSlot(matchdayConfiguration, availableSportsFieldsConfiguration);
    }

    private assignMatchToSlot(match: Match, slot: EmptySlot, matchdayConfiguration: MatchdayConfiguration) {
        const sportsFieldPlan = matchdayConfiguration.sportsFieldsPlan.find(
            plan => plan.sportsField.id === slot.sportsFieldId,
        );

        if (!sportsFieldPlan) {
            throw new Error("Sports field not found.");
        }

        matchdayConfiguration.moveSlot(match.id, {
            sportsFieldId: slot.sportsFieldId,
            index: sportsFieldPlan.slots.length,
        });
    }

    private updateTeamsLastMatchTimes(teamsLastMatchTimes: TeamsLastMatchTimes, match: Match, slot: EmptySlot) {
        match.team1Id && teamsLastMatchTimes.set(match.team1Id, slot.startTime);
        match.team2Id && teamsLastMatchTimes.set(match.team2Id, slot.startTime);
    }

    private createTeamsLastMatchTimesMap(
        matchesToPlan: Match[],
        matchdayConfiguration: MatchdayConfiguration,
        availableSportsFieldsConfiguration: AvailableSportsFieldsConfiguration,
    ): TeamsLastMatchTimes {
        const firstSlotStartTime = this.getNextSlot(matchdayConfiguration, availableSportsFieldsConfiguration)
            ?.startTime;

        const teamsLastMatchesTimes = new Map<TeamId, moment.Moment | undefined>();

        const defaultTime = moment(firstSlotStartTime).subtract(
            this.slotsConfiguration.distanceBetweenMatchesInMinutes,
            "minutes",
        );

        matchesToPlan.forEach(m => {
            m.team1Id && teamsLastMatchesTimes.set(m.team1Id, defaultTime);
            m.team2Id && teamsLastMatchesTimes.set(m.team2Id, defaultTime);
        });

        return teamsLastMatchesTimes;
    }

    private getTeamsNotAvailableForSlot(
        matchdayConfiguration: MatchdayConfiguration,
        slotStartTime: moment.Moment,
    ): Set<TeamId> {
        const slotEndTime = this.getSlotEndTime(slotStartTime);

        const matchesOverlappingWithSlot = matchdayConfiguration.schedule
            .flatMap(sportsField =>
                sportsField.slots.filter(ps => {
                    if (!ps.startTime) {
                        throw new Error("Matchday is not configured.");
                    }

                    return (
                        isMatch(ps.slot) &&
                        ps.startTime &&
                        areSlotsOverlapping(
                            {
                                startTime: ps.startTime,
                                endTime: this.getSlotEndTime(ps.startTime),
                            },
                            {
                                startTime: slotStartTime,
                                endTime: slotEndTime,
                            },
                        )
                    );
                }),
            )
            .map(m => m.slot) as Match[];

        return new Set<TeamId>(
            matchesOverlappingWithSlot.reduce((acc, match) => {
                return [...acc, ...(match.team1Id ? [match.team1Id] : []), ...(match.team2Id ? [match.team2Id] : [])];
            }, [] as TeamId[]),
        );
    }

    private getMatchesAvailableForSlot(matchesToPlan: Match[], notAvailableTeams: Set<TeamId>): Match[] {
        return matchesToPlan.filter(m => {
            if (m.team1Id && notAvailableTeams.has(m.team1Id)) {
                return false;
            }

            if (m.team2Id && notAvailableTeams.has(m.team2Id)) {
                return false;
            }

            return true;
        });
    }

    private getMatchWithHighestPriority(
        matches: Match[],
        slotStartTime: moment.Moment,
        teamsLastMatchTimes: TeamsLastMatchTimes,
    ): Match | undefined {
        return _.maxBy(matches, m => this.calculateMatchPriority(m, slotStartTime, teamsLastMatchTimes));
    }

    private calculateMatchPriority(
        match: Match,
        slotStartTime: moment.Moment,
        teamLastMatchTimes: TeamsLastMatchTimes,
    ): number {
        const team1RestingTimeInMinutes = match.team1Id ? slotStartTime.diff(teamLastMatchTimes.get(match.team1Id)) : 0;
        const team2RestingTimeInMinutes = match.team2Id ? slotStartTime.diff(teamLastMatchTimes.get(match.team2Id)) : 0;

        return team1RestingTimeInMinutes + team2RestingTimeInMinutes;
    }

    private getSlotEndTime(startTime: moment.Moment): moment.Moment {
        return moment(startTime).add(this.slotsConfiguration.distanceBetweenMatchesInMinutes, "minutes");
    }
}

export default GreedyMatchdayScheduler;
