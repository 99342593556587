import { PlusOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { message, Timeline, Typography } from "antd";
import PageContent from "Components/PageContent";
import { MatchEventSideDTO, MatchEventTypeDTO } from "Contracts/PlayooLeagueClient";
import { Match } from "Domain/Matches/Match";
import MatchEvent from "Domain/Matches/MatchEvent";
import MatchEventEditor from "Domain/Matches/MatchEventEditor";
import MatchEventFormDialog from "DomainComponents/Matches/MatchEventFormDialog";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import goalIcon from "./goal.svg";
import redCardIcon from "./red-card.svg";
import styles from "./styles.scss";
import yellowCardIcon from "./yellow-card.svg";

const cx = mkCx(styles);

type MatchTimelineProps = {
    match: Match;
};

const iconsMap: Record<MatchEventTypeDTO, string | undefined> = {
    [MatchEventTypeDTO.Goal]: goalIcon,
    [MatchEventTypeDTO.Shot]: undefined, // TODO: add icons when available
    [MatchEventTypeDTO.Foul]: undefined,
    [MatchEventTypeDTO.MinutesPenalty]: undefined,
    [MatchEventTypeDTO.RedCard]: redCardIcon,
    [MatchEventTypeDTO.YellowCard]: yellowCardIcon,
};

const MatchTimeline: React.FunctionComponent<MatchTimelineProps> = ({ match }) => {
    const [editedEventIndex, setEditedEventIndex] = useState<number>();
    const [eventEditor, setEventEditor] = useState<MatchEventEditor>();
    const [eventCreator, setEventCreator] = useState<MatchEventEditor>();

    const closeEditor = useCallback(() => {
        setEditedEventIndex(undefined);
        setEventEditor(undefined);
    }, []);

    const onSaveEventEdition = useCallback(async () => {
        const newEvent = eventEditor?.currentEvent;

        if (editedEventIndex === undefined || !newEvent) {
            return undefined;
        }

        const result = await match.editEvent(editedEventIndex, newEvent);

        result
            .handle("success", () => {
                closeEditor();

                message.success(l("MatchEvent_Edit_Success"));
            })
            .handle("KnockoutPhaseMatchesCannotHaveWinnerChanged", () => {
                message.error(l("MatchEvent_Edit_Validation_KnockoutPhaseMatchesCannotHaveWinnerChanged"));
            })
            .handle(
                [
                    "EventNotValid",
                    "EventsNullOrMissing",
                    "EventsOutOfOrder",
                    "MatchNotFound",
                    "MatchReportNotFound",
                    "PlayerNotFoundInPresentPlayersForTeam",
                    "ResultNotValid",
                    "ResultNullOrMissing",
                    "PlayerNotFound",
                    "PlayersHaveDuplicates",
                    "failure",
                ],
                () => {
                    message.error(l("MatchEvent_Edit_Failure"));
                },
            )
            .check();
    }, [editedEventIndex, match, eventEditor, closeEditor]);

    const closeCreator = useCallback(() => {
        setEventCreator(undefined);
    }, []);

    const onSaveEventCreation = useCallback(async () => {
        const event = eventCreator?.currentEvent;

        if (!event) {
            return undefined;
        }

        const result = await match.addEvent(event);

        result
            .handle("success", () => {
                closeCreator();

                message.success(l("MatchEvent_Add_Success"));
            })
            .handle("KnockoutPhaseMatchesCannotHaveWinnerChanged", () => {
                message.error(l("MatchEvent_Add_Validation_KnockoutPhaseMatchesCannotHaveWinnerChanged"));
            })
            .handle(
                [
                    "EventNotValid",
                    "EventsNullOrMissing",
                    "EventsOutOfOrder",
                    "MatchNotFound",
                    "MatchReportNotFound",
                    "PlayerNotFoundInPresentPlayersForTeam",
                    "ResultNotValid",
                    "ResultNullOrMissing",
                    "PlayerNotFound",
                    "PlayersHaveDuplicates",
                    "failure",
                ],
                () => {
                    message.error(l("MatchEvent_Add_Failure"));
                },
            )
            .check();
    }, [match, eventCreator, closeCreator]);

    const onDeleteEvent = useCallback(async () => {
        if (editedEventIndex === undefined) {
            return;
        }

        const result = await match.deleteEvent(editedEventIndex);

        result
            .handle("success", () => {
                closeEditor();

                message.success(l("MatchEvent_Delete_Success"));
            })
            .handle("KnockoutPhaseMatchesCannotHaveWinnerChanged", () => {
                message.error(l("MatchEvent_Delete_Validation_KnockoutPhaseMatchesCannotHaveWinnerChanged"));
            })
            .handle(
                [
                    "EventNotValid",
                    "EventsNullOrMissing",
                    "EventsOutOfOrder",
                    "MatchNotFound",
                    "MatchReportNotFound",
                    "PlayerNotFoundInPresentPlayersForTeam",
                    "ResultNotValid",
                    "ResultNullOrMissing",
                    "PlayerNotFound",
                    "PlayersHaveDuplicates",
                    "failure",
                ],
                () => {
                    message.error(l("MatchEvent_Delete_Failure"));
                },
            )
            .check();
    }, [editedEventIndex, match, closeEditor]);

    const startEventCreation = useCallback(() => {
        if (match.team1Lineup && match.team2Lineup) {
            setEventCreator(MatchEventEditor.fromEmpty(match.team1Lineup, match.team2Lineup));
        }
    }, [match.team1Lineup, match.team2Lineup]);

    const startEventEdition = useCallback(
        (event: MatchEvent, eventIndex: number) => {
            if (match.team1Lineup && match.team2Lineup) {
                setEditedEventIndex(eventIndex);
                setEventEditor(MatchEventEditor.fromEvent(event, match.team1Lineup, match.team2Lineup));
            }
        },
        [match.team1Lineup, match.team2Lineup],
    );

    return useObserver(() =>
        match.events && match.team1Lineup && match.team2Lineup && match.team1 && match.team2 ? (
            <>
                <PageContent.ActionButton onClick={startEventCreation}>
                    <PlusOutlined />
                    {l("MatchEvent_Add")}
                </PageContent.ActionButton>
                <Timeline mode="alternate" className={cx("timeline")}>
                    {match.events.map((e, ind) => {
                        const formattedSubtype = e.formatSubtype();
                        const player = e.playerId ? match.allPlayers.get(e.playerId) : undefined;

                        return (
                            <Timeline.Item
                                key={ind}
                                position={e.side === MatchEventSideDTO.Team1 ? "right" : "left"}
                                dot={iconsMap[e.type] && <img src={iconsMap[e.type]} />}>
                                <div className={cx("event")} onClick={() => startEventEdition(e, ind)}>
                                    {e.formatType()} {formattedSubtype && `(${formattedSubtype})`}
                                    {e.minuteOfMatch !== undefined && (
                                        <span className={cx("minute-of-match")}>{` ${e.minuteOfMatch}'`}</span>
                                    )}
                                    {player && (
                                        <div>
                                            <Typography.Text strong>
                                                {player.firstName} {player.lastName}
                                            </Typography.Text>
                                        </div>
                                    )}
                                </div>
                            </Timeline.Item>
                        );
                    })}
                </Timeline>
                {editedEventIndex !== undefined && eventEditor && (
                    <MatchEventFormDialog
                        mode="edit"
                        onSave={onSaveEventEdition}
                        onDelete={onDeleteEvent}
                        onClose={closeEditor}
                        team1Name={match.team1.displayName}
                        team2Name={match.team2.displayName}
                        editor={eventEditor}
                    />
                )}
                {eventCreator && (
                    <MatchEventFormDialog
                        mode="create"
                        onSave={onSaveEventCreation}
                        onClose={closeCreator}
                        team1Name={match.team1.displayName}
                        team2Name={match.team2.displayName}
                        editor={eventCreator}
                    />
                )}
            </>
        ) : null,
    );
};

export default MatchTimeline;
