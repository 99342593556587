import { CheckOutlined, CloseCircleOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Table, Tag } from "antd";
import { ColumnProps } from "antd/lib/table";
import DropdownMenu from "Components/DropdownMenu";
import EmptyState from "Components/EmptyState";
import PageContent from "Components/PageContent";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import Invitation from "Domain/Competitions/Invitation";
import UserProfile from "Domain/Competitions/UserProfile";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import TeamAdminFormDialog from "./TeamAdminFormDialog";

type TeamAdminsProps = {
    team: CompetitionTeam;
};

type RowData = Invitation | UserProfile;

const TeamAdmins: React.FunctionComponent<TeamAdminsProps> = ({ team }) => {
    const [showTeamAdminFormDialog, setShowTeamAdminFormDialog] = useState(false);

    const showRemoveAdminConfirmationDialog = useCallback(
        (user: UserProfile) => () => {
            Modal.confirm({
                onOk: () => team.removeAdmin(user.id),
                icon: <ExclamationCircleOutlined />,
                title: l("CompetitionTeams_Admin_Remove_Confirm_Title"),
                content: l("CompetitionTeams_Admin_Remove_Confirm_Content", user.firstName, user.lastName),
                okText: l("CompetitionTeams_Admin_Remove_Confirm_OkText"),
                okButtonProps: {
                    danger: true,
                },
                cancelText: l("Common_Cancel"),
                centered: true,
                maskClosable: true,
            });
        },
        [team],
    );

    const columns = useMemo(
        (): ColumnProps<RowData>[] => [
            {
                title: l("Common_Ordinal"),
                render: (_, data, index) => index + 1,
            },
            {
                title: l("CompetitionTeams_Admin_LastName"),
                render: (_, data) => "lastName" in data && data.lastName,
            },
            {
                title: l("CompetitionTeams_Admin_FirstName"),
                render: (_, data) => "firstName" in data && data.firstName,
            },
            {
                title: l("CompetitionTeams_Admin_PhoneNumber"),
                render: (_, data) => "phoneNumber" in data && data.phoneNumber,
            },
            {
                render: (_, data) =>
                    data instanceof UserProfile ? (
                        <CheckOutlined />
                    ) : (
                        <Tag>{l("CompetitionTeams_Admin_InvitationSent")}</Tag>
                    ),
            },
            {
                render: (_, data) => (
                    <DropdownMenu
                        menuItems={[
                            data instanceof UserProfile
                                ? {
                                      children: (
                                          <>
                                              <CloseCircleOutlined /> {l("CompetitionTeams_Admin_Remove")}
                                          </>
                                      ),
                                      onClick: showRemoveAdminConfirmationDialog(data),
                                  }
                                : {
                                      children: (
                                          <>
                                              <CloseCircleOutlined /> {l("CompetitionTeams_Admin_CancelInvitation")}
                                          </>
                                      ),
                                      onClick: () => team.cancelAdminInvitation(data.id),
                                  },
                        ]}
                    />
                ),
            },
        ],
        [team, showRemoveAdminConfirmationDialog],
    );

    return useObserver(() => (
        <>
            <Table
                dataSource={[...(team.admins ?? []), ...(team.adminInvitations ?? [])]}
                columns={columns}
                pagination={false}
                locale={{ emptyText: <EmptyState text={l("CompetitionTeams_Admin_EmptyState")} /> }}
            />

            <PageContent.ActionButton onClick={() => setShowTeamAdminFormDialog(true)}>
                <PlusOutlined /> {l("CompetitionTeams_Admin_Add")}
            </PageContent.ActionButton>
            {showTeamAdminFormDialog && (
                <TeamAdminFormDialog team={team} onClose={() => setShowTeamAdminFormDialog(false)} />
            )}
        </>
    ));
};

export default TeamAdmins;
