import { observable } from "mobx";
import CompetitionRankingsConfiguration from "./CompetitionRankingsConfiguration";

class CompetitionSettings {
    @observable rankingsConfiguration: CompetitionRankingsConfiguration;

    constructor(rankingsConfiguration: CompetitionRankingsConfiguration) {
        this.rankingsConfiguration = rankingsConfiguration;
    }
}

export default CompetitionSettings;
