import { PageHeader, Skeleton } from "antd";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import PageContent from "Components/PageContent";
import registrationsStore from "Domain/Registrations";
import ApplicationsTable from "DomainComponents/ApplicationsTable";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import routes, { PageComponent } from "Router/routes";
import useRunInTask from "Utils/Hooks/useRunInTask";

const RegistrationDetailsPage: PageComponent<typeof routes.registrations.details> = ({
    match: {
        params: { registrationId },
    },
    history: { push },
    location,
}) => {
    const [detailsFetchInProgress, runDetailsFetchInTask] = useRunInTask();

    useEffect(() => {
        runDetailsFetchInTask(() => registrationsStore.fetchRegistrationDetails(registrationId, true));
    }, [registrationId, runDetailsFetchInTask]);

    const registration = useObserver(() => registrationsStore.getById(registrationId));

    const breadcrumb: BreadcrumbProps["routes"] = useMemo(
        () =>
            registration
                ? [
                      {
                          breadcrumbName: l("Registrations"),
                          path: routes.registrations(),
                      },
                      {
                          breadcrumbName: registration.title,
                          path: routes.registrations.details({ registrationId }),
                      },
                  ]
                : [],
        [registration, registrationId],
    );

    return (
        <PageContent>
            <PageContent.Header>
                <PageContent.HeaderSkeleton active={!registration} loading={!registration}>
                    {registration && (
                        <PageHeader
                            title={registration.title}
                            onBack={() => push(routes.registrations())}
                            breadcrumb={{
                                routes: breadcrumb,
                                itemRender: ({ path, breadcrumbName }) =>
                                    path === location.pathname ? (
                                        breadcrumbName
                                    ) : (
                                        <Link to={path}>{breadcrumbName}</Link>
                                    ),
                            }}
                        />
                    )}
                </PageContent.HeaderSkeleton>
            </PageContent.Header>
            <PageContent.Card>
                <Skeleton active={detailsFetchInProgress} loading={detailsFetchInProgress}>
                    <ApplicationsTable applications={registration?.applications} />
                </Skeleton>
            </PageContent.Card>
        </PageContent>
    );
};

export default RegistrationDetailsPage;
