import { Skeleton } from "antd";
import PageContent from "Components/PageContent";
import Spacing from "Components/Spacing";
import tenantSettingsStore from "Domain/Tenant";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import routes, { PageComponent } from "Router/routes";
import useRunInTask from "Utils/Hooks/useRunInTask";
import ManagerUsersTable from "./ManagerUsersTable";
import TenantDetailsTable from "./TenantDetailsTable";
import TenantNameFormDialog from "./TenantNameFormDialog";

const TenantPage: PageComponent<typeof routes.tenant> = () => {
    const [isRunning, runInTask] = useRunInTask();
    const [isTenantNameFormDialogOpen, setIsTenantNameFormDialogOpen] = useState(false);

    useEffect(() => {
        runInTask(() => tenantSettingsStore.fetchSettings(true));
    }, [runInTask]);

    return useObserver(() => (
        <>
            <PageContent>
                <PageContent.Card title={l("Tenant")}>
                    <Skeleton active={isRunning} loading={isRunning}>
                        <Spacing childrenGutterY>
                            {tenantSettingsStore.name && (
                                <TenantDetailsTable
                                    tenant={{
                                        name: tenantSettingsStore.name,
                                        logoUri: tenantSettingsStore.logoUri,
                                        landingLogoUri: tenantSettingsStore.landingLogoUri,
                                    }}
                                    onEditTenantName={() => setIsTenantNameFormDialogOpen(true)}
                                />
                            )}
                            <ManagerUsersTable />
                        </Spacing>
                    </Skeleton>
                </PageContent.Card>
            </PageContent>
            {isTenantNameFormDialogOpen && tenantSettingsStore.isFetched && (
                <TenantNameFormDialog onClose={() => setIsTenantNameFormDialogOpen(false)} />
            )}
        </>
    ));
};

export default TenantPage;
