import { handleResponse } from "@leancode/validation";
import { ChangeMatchday, PhaseTypeDTO, UpdateTablePhaseMatch } from "Contracts/PlayooLeagueClient";
import { CompetitionTeamStore } from "Domain/Competitions/CompetitionTeamStore";
import { observable, runInAction } from "mobx";
import api from "Services/Api";
import { dateTimeOffsetToDTOOptional } from "Utils/DTO";
import MatchBase, { MatchInit } from "../../Matches/MatchBase";

class TablePhaseMatch extends MatchBase {
    readonly phaseType = PhaseTypeDTO.Table;

    @observable matchday: number;

    get isDeletable(): boolean {
        return true;
    }

    constructor(id: string, phaseId: string, init: TablePhaseMatchInit, competitionTeamStore: CompetitionTeamStore) {
        super(id, phaseId, init, competitionTeamStore);

        this.matchday = init.matchday;
    }

    async update({ team1Id, team2Id, matchday, date, sportsFieldId, status }: TablePhaseMatchUpdateData) {
        const response = await api.updateTablePhaseMatch({
            Team1Id: team1Id,
            Team2Id: team2Id,
            MatchId: this.id,
            Matchday: matchday,
            Date: dateTimeOffsetToDTOOptional(date),
            SportsFieldId: sportsFieldId,
            Status: status,
        });

        handleResponse(response, UpdateTablePhaseMatch).handle("success", () => {
            runInAction(() => {
                this.matchday = matchday;
                this.date = date;
                this.sportsFieldId = sportsFieldId;
                this.status = status;
                this.team1Id = team1Id;
                this.team2Id = team2Id;
            });
        });

        return handleResponse(response, UpdateTablePhaseMatch);
    }

    async updateMatchday(matchday: number) {
        const response = await api.changeMatchday({
            MatchId: this.id,
            Matchday: matchday,
        });

        return handleResponse(response, ChangeMatchday);
    }
}

type TablePhaseMatchInit = MatchInit & Pick<TablePhaseMatch, "matchday">;

export type TablePhaseMatchUpdateData = Pick<TablePhaseMatch, "matchday" | "date" | "sportsFieldId" | "status"> & {
    team1Id: string;
    team2Id: string;
};

export default TablePhaseMatch;
