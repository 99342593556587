import PageContent from "Components/PageContent";
import Spacing from "Components/Spacing";
import React from "react";
import routes, { PageComponent } from "Router/routes";
import BannersSection from "./BannersSection";
import PartnersSection from "./PartnersSection";

const LandingsPage: PageComponent<typeof routes.landing> = () => {
    return (
        <PageContent>
            <Spacing childrenGutterY>
                <BannersSection />
                <PartnersSection />
            </Spacing>
        </PageContent>
    );
};

export default LandingsPage;
