import { Locale } from "antd/es/locale-provider";
import plAntdLocale from "antd/es/locale/pl_PL";
import { l } from "Languages";

const antdLocale: Locale = {
    ...plAntdLocale,
    Table: {
        ...plAntdLocale.Table,
        triggerAsc: l("Table_TriggerAsc"),
        triggerDesc: l("Table_TriggerDesc"),
        cancelSort: l("Table_CancelSort"),
    },
};

export default antdLocale;
