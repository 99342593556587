import { Form } from "antd";
import { Select } from "Components/Select";
import { MatchStatusDTO } from "Contracts/PlayooLeagueClient";
import { l } from "Languages";
import React from "react";

type MatchTeamsFormItemProps = {
    currentStatus: MatchStatusDTO;

    fieldName: string;
};

const matchStatusOptions = [
    {
        key: MatchStatusDTO.Upcoming,
        label: l("MatchDetails_Status_Upcoming"),
    },
    {
        key: MatchStatusDTO.Finished,
        label: l("MatchDetails_Status_Finished"),
    },
    {
        key: MatchStatusDTO.Canceled,
        label: l("MatchDetails_Status_Canceled"),
    },
    {
        key: MatchStatusDTO.Ongoing,
        label: l("MatchDetails_Status_Ongoing"),
    },
] as const;

const MatchStatusFormItem: React.FunctionComponent<MatchTeamsFormItemProps> = ({ currentStatus, fieldName }) => {
    return (
        <Form.Item name={fieldName} label={l("MatchDetails_Status")}>
            <Select
                disabled={currentStatus ? currentStatus === MatchStatusDTO.Finished : false}
                selectOptions={matchStatusOptions
                    .filter(
                        o =>
                            (currentStatus === MatchStatusDTO.Finished && o.key === MatchStatusDTO.Finished) ||
                            (currentStatus !== MatchStatusDTO.Finished && o.key !== MatchStatusDTO.Finished),
                    )
                    .map(o => ({
                        label: o.label,
                        value: o.key,
                        key: o.key.toString(),
                    }))}
            />
        </Form.Item>
    );
};

export default MatchStatusFormItem;
