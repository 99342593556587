import { handleResponse } from "@leancode/validation";
import { Button, Form, Input, message, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import UnauthorizedAppLayout from "Components/AuthenticationLayout";
import { SendPasswordRecoveryEmail } from "Contracts/PlayooLeagueClient";
import React, { useCallback } from "react";
import routes, { PageComponent } from "Router/routes";
import api from "Services/Api";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";
import { l } from "../../../Languages";

export const RecoveryPassword: PageComponent<typeof routes.recoverPassword> = ({ history: { push } }) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const onFinish = useCallback(
        (values: FormFields) => {
            runInTask(async () => {
                const response = await api.sendPasswordRecoveryEmail({
                    EmailAddress: values.email,
                });

                handleResponse(response, SendPasswordRecoveryEmail)
                    .handle(["InvalidEmailAddress", "MissingEmailAddress"], () => {
                        form.setFields([
                            {
                                name: guard<FormField>("email"),
                                value: values.email,
                                errors: [l("Common_Validation_InvalidEmail")],
                            },
                        ]);
                    })
                    .handle("failure", () => {
                        message.error(l("SignIn_Send_Reset_Password_Failure"));
                    })
                    .handle("success", () => {
                        message.success(l("SignIn_Sent_Reset_Password_Email"));

                        push(routes.index());
                    });
            });
        },
        [push, form, runInTask],
    );

    return (
        <UnauthorizedAppLayout>
            <Spin spinning={isRunning}>
                <Form form={form} onFinish={onFinish} layout="vertical">
                    <Form.Item
                        name={guard<FormField>("email")}
                        label={l("SignIn_Email")}
                        rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                        <Input type="email" />
                    </Form.Item>
                    <Form.Item>
                        <UnauthorizedAppLayout.Actions>
                            <Button htmlType="submit" type="primary">
                                {l("SignIn_Send_Password_Recovery_Link")}
                            </Button>
                        </UnauthorizedAppLayout.Actions>
                    </Form.Item>
                </Form>
            </Spin>
        </UnauthorizedAppLayout>
    );
};

type FormFields = {
    email: string;
};

type FormField = keyof FormFields;

export default RecoveryPassword;
