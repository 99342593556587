import mkCx, { rawClassName } from "@leancode/cx";
import { Input } from "antd";
import { InputProps } from "antd/lib/input";
import React from "react";
import styles from "./styles.scss";

const cx = mkCx(styles);

type PhoneNumberInputProps = InputProps;

const PhoneNumberInput: React.FunctionComponent<PhoneNumberInputProps> = ({ ...inputProps }) => {
    return <Input type="number" {...inputProps} className={cx("input", rawClassName(inputProps.className))} />;
};

export default PhoneNumberInput;
