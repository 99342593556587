import { Upload as AntdUpload } from "antd";
import { UploadProps as AntdUploadProps } from "antd/lib/upload/Upload";
import React from "react";
import extractFile from "Utils/extractFile";

type UploadProps = {
    onUpload?: (file: File) => void;
} & AntdUploadProps;

const Upload: React.FunctionComponent<UploadProps> = ({ onUpload, ...uploadProps }) => {
    return (
        <AntdUpload
            showUploadList={false}
            customRequest={({ file, onSuccess }) => {
                setImmediate(() => onSuccess({}, file));
            }} // HACK: prevent Upload control to automatically upload file to server
            onChange={info => {
                if (info.file.status === "done") {
                    const file = extractFile(info.file);

                    file && onUpload?.(file);
                }
            }}
            {...uploadProps}
        />
    );
};

export default Upload;
