import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Button, Modal, Skeleton } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import DropdownMenu from "Components/DropdownMenu";
import NavigationLink from "Components/NavigationLink";
import PageContent from "Components/PageContent";
import Photo from "Components/Photo";
import partnersStore from "Domain/Partners";
import Partner from "Domain/Partners/Partner";
import { l } from "Languages";
import { Observer, useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useRunInTask from "Utils/Hooks/useRunInTask";
import PartnerLinkFormDialog from "./PartnerLinkFormDialog";
import styles from "./styles.scss";

const cx = mkCx(styles);

const PartnersSection: React.FunctionComponent = () => {
    const [partnerAdditionInProgress, runPartnerAdditionInTask] = useRunInTask();
    const [partnersFetchInProgress, runPartnersFetchInTask] = useRunInTask();
    const [partnerToEditLinkUri, setPartnerToEditLinkUri] = useState<Partner>();

    useEffect(() => {
        runPartnersFetchInTask(() => partnersStore.fetchPartners());
    }, [runPartnersFetchInTask]);

    const partners = useObserver(() => partnersStore.items);

    const addPartner = useCallback(() => {
        runPartnerAdditionInTask(() => partnersStore.addPartner());
    }, [runPartnerAdditionInTask]);

    const showDeletePartnerConfirmation = useCallback((partnerId: string) => {
        Modal.confirm({
            onOk: () => partnersStore.deletePartner(partnerId),
            icon: <ExclamationCircleOutlined />,
            title: l("Landing_Partners_Delete_Confirmation_Title"),
            content: l("Landing_Partners_Delete_Confirmation_Content"),
            okText: l("Common_Remove"),
            okButtonProps: {
                danger: true,
            },
            cancelText: l("Common_Cancel"),
            centered: true,
            maskClosable: true,
        });
    }, []);

    const columns = useMemo(
        (): ColumnProps<Partner>[] => [
            {
                title: l("Common_Ordinal"),
                render: (_, partner, index) => index + 1,
            },
            {
                title: l("Landing_Partners_Logo"),
                render: (_, partner) => (
                    <Observer>
                        {() => (
                            <Photo
                                photo={partner.logoUri}
                                onChange={file => partner.updateLogo(file)}
                                editable
                                cropDisabled
                                className={cx("logo")}
                            />
                        )}
                    </Observer>
                ),
            },
            {
                title: l("Landing_Partners_Link"),
                render: (_, partner) =>
                    partner.linkUri ? (
                        <NavigationLink to={partner.linkUri} external>
                            {l("Landing_Partners_NavigateToLink")}
                        </NavigationLink>
                    ) : (
                        <Button type="link" onClick={() => setPartnerToEditLinkUri(partner)}>
                            {l("Common_Add")}
                        </Button>
                    ),
            },
            {
                render: (_, partner) => (
                    <DropdownMenu
                        menuItems={[
                            {
                                children: (
                                    <>
                                        <EditOutlined /> {l("Landing_Partners_UpdateLink")}
                                    </>
                                ),
                                onClick: () => setPartnerToEditLinkUri(partner),
                            },
                            {
                                children: (
                                    <>
                                        <DeleteOutlined /> {l("Common_Remove")}
                                    </>
                                ),
                                onClick: () => showDeletePartnerConfirmation(partner.id),
                            },
                        ]}
                    />
                ),
            },
        ],
        [showDeletePartnerConfirmation],
    );

    return (
        <>
            <PageContent.Card title={l("Landing_Partners")}>
                <Skeleton loading={partnersFetchInProgress} active={partnersFetchInProgress}>
                    {partners && <Table columns={columns} dataSource={partners} pagination={false} />}
                    <PageContent.ActionButton
                        disabled={partnerAdditionInProgress}
                        loading={partnerAdditionInProgress}
                        onClick={addPartner}>
                        <PlusOutlined /> {l("Common_Add")}
                    </PageContent.ActionButton>
                </Skeleton>
            </PageContent.Card>
            {partnerToEditLinkUri && (
                <PartnerLinkFormDialog
                    partner={partnerToEditLinkUri}
                    onClose={() => setPartnerToEditLinkUri(undefined)}
                />
            )}
        </>
    );
};

export default PartnersSection;
