import GroupsPhasePlannerFilter from "Domain/CompetitionPhases/SchedulePlanner/Filters/GroupsPhasePlannerFilters";
import PlannerFilter from "Domain/CompetitionPhases/SchedulePlanner/Filters/PlannerFilter";
import SeasonPlannerFilter from "Domain/CompetitionPhases/SchedulePlanner/Filters/SeasonPlannerFilters";
import GroupsPhaseUnassignedMatchesFilter from "DomainComponents/SchedulePlanner/Filters/GroupsPhaseUnassignedMatchesFilter";
import SeasonUnassignedMatchesFilter from "DomainComponents/SchedulePlanner/Filters/SeasonUnassignedMatchesFilter";
import React from "react";

type UnassignedMatchesFilterProps = {
    filter: PlannerFilter | SeasonPlannerFilter;
};

const UnassignedMatchesFilter: React.FunctionComponent<UnassignedMatchesFilterProps> = ({ filter }) => {
    if (filter instanceof GroupsPhasePlannerFilter) {
        return <GroupsPhaseUnassignedMatchesFilter filter={filter} />;
    } else if (filter instanceof SeasonPlannerFilter) {
        return <SeasonUnassignedMatchesFilter filter={filter} />;
    }

    return null;
};

export default UnassignedMatchesFilter;
