import { Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import tenantSettingsStore from "Domain/Tenant";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";

type TenantNameFormDialogProps = {
    onClose: () => void;
};

const TenantNameFormDialog: React.FunctionComponent<TenantNameFormDialogProps> = ({ onClose }) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const onFinish = useCallback(
        (values: FormFields) =>
            runInTask(async () => {
                const result = await tenantSettingsStore.updateName(values.name);

                result
                    .handle(["NewNameTooLong", "NewNameMissingOrEmpty"], error =>
                        form.setFields([
                            {
                                name: guard<FormField>("name"),
                                value: values.name,
                                errors: [
                                    error === "NewNameTooLong"
                                        ? l("Common_Validation_FieldTooLong")
                                        : l("Common_Validation_FieldRequired"),
                                ],
                            },
                        ]),
                    )
                    .handle("failure", () => {
                        message.error(l("Tenant_UpdateName_Failure"));
                    })
                    .handle("success", () => {
                        message.success(l("Tenant_UpdateName_Success"));

                        onClose();
                    });
            }),
        [onClose, form, runInTask],
    );

    return useObserver(() => (
        <Modal
            title={l("Tenant_UpdateName_Form_Title")}
            onCancel={onClose}
            okText={l("Common_Save")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}
            okButtonProps={{
                disabled: isRunning,
                loading: isRunning,
            }}>
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                initialValues={{ [guard<FormField>("name")]: tenantSettingsStore.name }}>
                <Form.Item
                    name={guard<FormField>("name")}
                    label={l("Tenant_UpdateName_Form_Name")}
                    rules={[
                        { required: true, message: l("Common_Validation_FieldRequired") },
                        { max: 20, message: l("Common_Validation_FieldTooLong") },
                    ]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    ));
};

export type FormFields = {
    name: string;
};

type FormField = keyof FormFields;

export default TenantNameFormDialog;
