import { ExclamationCircleOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Button, Modal } from "antd";
import ImagesDraggerUpload from "Components/ImagesDraggerUpload";
import Spacing from "Components/Spacing";
import Photo from "Domain/Photos/Photo";
import { l } from "Languages";
import React, { useCallback, useMemo, useRef, useState } from "react";
import GridGallery, { Gallery, GridGalleryImage } from "react-grid-gallery";
import { buildURL } from "react-imgix";
import styles from "./styles.scss";

const cx = mkCx(styles);

type PhotosGalleryProps = {
    photos: Photo[];

    onPhotosUpload?: (files: File[]) => void;
    onPhotoDelete?: (photoId: string) => void;
};

const thumbnailRowHeight = 150;

const PhotosGallery: React.FunctionComponent<PhotosGalleryProps> = ({ photos, onPhotosUpload, onPhotoDelete }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState<number>();

    const ref = useRef<Gallery>();

    const images: GridGalleryImage[] = useMemo(
        () =>
            photos.map(p => ({
                src: buildURL(p.uri, { "max-h": 1080, "max-w": 1920 }),
                thumbnail: buildURL(p.uri, { h: thumbnailRowHeight }),
            })),
        [photos],
    );

    const onDeleteCurrentPhoto = useCallback(async () => {
        if (currentImageIndex === undefined) {
            return;
        }

        const photo = photos[currentImageIndex];

        const isLastPhoto = currentImageIndex === photos.length - 1;
        const isOnlyPhoto = photos.length === 1;

        if (photo) {
            await onPhotoDelete?.(photo.id);

            if (isOnlyPhoto) {
                setCurrentImageIndex(undefined);

                ref.current?.closeLightbox();
            } else if (isLastPhoto) {
                setCurrentImageIndex(currentImageIndex - 1);
            }
        }
    }, [currentImageIndex, onPhotoDelete, photos]);

    const showDeletePhotoConfirmation = useCallback(() => {
        Modal.confirm({
            onOk: onDeleteCurrentPhoto,
            icon: <ExclamationCircleOutlined />,
            title: l("Photos_Gallery_Delete_Confirmation_Title"),
            content: l("Photos_Gallery_Delete_Confirmation_Content"),
            okText: l("Common_Remove"),
            okButtonProps: {
                danger: true,
            },
            cancelText: l("Common_Cancel"),
            centered: true,
            maskClosable: true,
        });
    }, [onDeleteCurrentPhoto]);

    const customControls = useMemo(
        () =>
            onPhotoDelete
                ? [
                      <div key="delete-button" className={cx("delete-button-container")}>
                          <Button onClick={showDeletePhotoConfirmation}>{l("Common_Remove")}</Button>
                      </div>,
                  ]
                : undefined,
        [onPhotoDelete, showDeletePhotoConfirmation],
    );

    return (
        <Spacing childrenGutterY>
            {onPhotosUpload && (
                <ImagesDraggerUpload multiple text={l("Photos_Upload_Text")} onUpload={onPhotosUpload} />
            )}
            <GridGallery
                ref={ref}
                currentImageWillChange={setCurrentImageIndex}
                customControls={customControls}
                enableImageSelection={false}
                showLightboxThumbnails
                backdropClosesModal
                imageCountSeparator={l("Photos_Gallery_ImageCountSeparator")}
                rowHeight={thumbnailRowHeight}
                images={images}
            />
        </Spacing>
    );
};

export default PhotosGallery;
