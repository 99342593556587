import { PageHeader, Skeleton } from "antd";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import PageContent from "Components/PageContent";
import competitionPhaseStore from "Domain/CompetitionPhases";
import competitionStore from "Domain/Competitions";
import matchStore from "Domain/Matches";
import { formatTeamVsTeam } from "Domain/Matches/formatting";
import PhotosGallery from "DomainComponents/Photos/PhotosGallery";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import routes, { PageComponent } from "Router/routes";
import useRunInTask from "Utils/Hooks/useRunInTask";

const MatchPhotosPage: PageComponent<typeof routes.matchDetails> = ({
    match: {
        params: { matchId },
    },
    location,
    history: { push },
}) => {
    const [detailsFetchInProgress, runDetailsFetchInTask] = useRunInTask();

    const match = useObserver(() => matchStore.getById(matchId));
    const phase = useObserver(() => match && competitionPhaseStore.getById(match.phaseId));
    const competition = useObserver(() => phase && competitionStore.getById(phase.competitionId));

    useEffect(() => {
        runDetailsFetchInTask(async () => {
            if (!match) {
                await competitionStore.fetchMatchDetails(matchId);
            }

            await match?.fetchPhotos();
        });
    }, [matchId, match, runDetailsFetchInTask]);

    const breadcrumb: BreadcrumbProps["routes"] = useMemo(
        () =>
            phase && competition && match
                ? [
                      {
                          breadcrumbName: competition.name,
                          path: routes.competitionDetails.phases({ competitionId: competition.id }),
                      },
                      {
                          breadcrumbName: phase?.displayName,
                          path: routes.competitionPhase({ phaseId: phase?.id }),
                      },
                      {
                          breadcrumbName: formatTeamVsTeam(match),
                          path: routes.matchDetails({ matchId: matchId }),
                      },
                      {
                          breadcrumbName: l("MatchDetails_Photos_Title"),
                          path: routes.matchDetails.gallery({ matchId: matchId }),
                      },
                  ]
                : [],
        [phase, competition, match, matchId],
    );

    return useObserver(() => (
        <PageContent>
            <PageContent.Header>
                <PageContent.HeaderSkeleton active={detailsFetchInProgress} loading={detailsFetchInProgress}>
                    {match && (
                        <PageHeader
                            title={l("MatchDetails_Photos_Title")}
                            onBack={() => push(routes.matchDetails({ matchId: match.id }))}
                            breadcrumb={{
                                routes: breadcrumb,
                                itemRender: ({ path, breadcrumbName }) =>
                                    path === location.pathname ? (
                                        breadcrumbName
                                    ) : (
                                        <Link to={path}>{breadcrumbName}</Link>
                                    ),
                            }}
                        />
                    )}
                </PageContent.HeaderSkeleton>
            </PageContent.Header>
            <PageContent.Card>
                <Skeleton loading={detailsFetchInProgress} active={detailsFetchInProgress}>
                    {match?.photos && (
                        <PhotosGallery
                            photos={match?.photos}
                            onPhotosUpload={files => match.uploadPhotos(files)}
                            onPhotoDelete={photoId => match.deletePhoto(photoId)}
                        />
                    )}
                </Skeleton>
            </PageContent.Card>
        </PageContent>
    ));
};

export default MatchPhotosPage;
