import mkCx from "@leancode/cx";
import Spacing from "Components/Spacing";
import TeamLogo from "Components/TeamLogo";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import styles from "./styles.scss";

const cx = mkCx(styles);

export type TeamItemDraggableProps = {
    index: number;
    team: CompetitionTeam;
    isDragDisabled?: boolean;
};

const TeamItemDraggable: React.FunctionComponent<TeamItemDraggableProps> = ({ team, index, isDragDisabled }) => {
    return (
        <Draggable draggableId={team.id} index={index} isDragDisabled={isDragDisabled}>
            {provided => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <Spacing childrenGutterX className={cx("team-item")}>
                        <div className={cx("content")}>
                            <TeamLogo photo={team.logo} small />
                            <div className={cx("team-name")}>{team.displayName}</div>
                        </div>
                    </Spacing>
                </div>
            )}
        </Draggable>
    );
};

export default TeamItemDraggable;
