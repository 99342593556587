import { Form, Input } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React from "react";
import guard from "Utils/guard";

type PlaceholderTeamForPhaseFormDialogProps = {
    placeholderTeam?: CompetitionTeam;

    saveInProgress?: boolean;

    onClose: () => void;
    onSave: (name: string) => void;
};

const PlaceholderTeamForPhaseFormDialog: React.FunctionComponent<PlaceholderTeamForPhaseFormDialogProps> = ({
    placeholderTeam,
    saveInProgress,
    onClose,
    onSave,
}) => {
    const [form] = useForm();

    return useObserver(() => (
        <Modal
            title={
                placeholderTeam
                    ? l("CompetitionPhases_PlaceholderTeams_Form_Edit_Title")
                    : l("CompetitionPhases_PlaceholderTeams_Form_Add_Title")
            }
            onCancel={onClose}
            okText={placeholderTeam ? l("Common_Save") : l("Common_Add")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}
            okButtonProps={{
                loading: saveInProgress,
                disabled: saveInProgress,
            }}>
            <Form
                layout="vertical"
                form={form}
                onFinish={(values: FormFields) => onSave(values.name)}
                initialValues={{ [guard<FormField>("name")]: placeholderTeam?.name }}>
                <Form.Item
                    name={guard<FormField>("name")}
                    label={l("CompetitionPhases_PlaceholderTeams_Form_Name")}
                    rules={[
                        {
                            required: true,
                            message: l("Common_Validation_FieldRequired"),
                        },
                        {
                            max: 250,
                            message: l("Common_Validation_FieldTooLong"),
                        },
                    ]}>
                    <Input autoFocus />
                </Form.Item>
            </Form>
        </Modal>
    ));
};

export type FormFields = {
    name: string;
};

type FormField = keyof FormFields;

export default PlaceholderTeamForPhaseFormDialog;
