import { CompetitionTeamStore } from "Domain/Competitions/CompetitionTeamStore";
import { computed, observable } from "mobx";

class TieBreakingOrderTeam {
    private readonly competitionTeamStore: CompetitionTeamStore;

    readonly id: string;
    @observable tieBreakingOrder: number;

    constructor(id: string, tieBreakingOrder: number, competitionTeamStore: CompetitionTeamStore) {
        this.id = id;
        this.tieBreakingOrder = tieBreakingOrder;

        this.competitionTeamStore = competitionTeamStore;
    }

    @computed get team() {
        return this.competitionTeamStore.getById(this.id);
    }
}

export default TieBreakingOrderTeam;
