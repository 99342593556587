import { Form, Input, Spin, Typography } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import Spacing from "Components/Spacing";
import { AccountStateDTO } from "Contracts/PlayooLeagueClient";
import CompetitionTeamPlayer from "Domain/Competitions/CompetitionTeamPlayer";
import { l } from "Languages";
import React, { useCallback } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";
import { isValidInteger } from "Utils/validationHelpers";

export type PlayerFormValidationErrors = Partial<Record<FormField, string>>;

type PlayerFormDialogProps = {
    player?: CompetitionTeamPlayer;
    initialValues?: Partial<FormFields>;

    onSave: (fields: FormFields) => Promise<PlayerFormValidationErrors | undefined>;
    onClose: () => void;
};

const PlayerFormDialog: React.FunctionComponent<PlayerFormDialogProps> = ({
    player,
    initialValues,
    onSave,
    onClose,
}) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const onFinish = useCallback(
        (values: FormFields) =>
            runInTask(async () => {
                const validationErrors = await onSave(values);

                for (const field in validationErrors) {
                    form.setFields([
                        {
                            name: field,
                            errors: [validationErrors[field]],
                            value: values[field],
                        },
                    ]);
                }
            }),
        [onSave, form, runInTask],
    );

    const canEditFirstAndLastName = !player || player.accountState !== AccountStateDTO.Linked;

    return (
        <Modal
            title={player ? l("CompetitionTeamPlayers_CreateNew_Title") : l("CompetitionTeamPlayers_Edit")}
            onCancel={() => onClose()}
            okText={player ? l("Common_Save") : l("Common_Add")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}>
            <Spin spinning={isRunning}>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        firstName: initialValues?.firstName ?? player?.firstName ?? undefined,
                        lastName: initialValues?.lastName ?? player?.lastName ?? undefined,
                        shirtNumber: initialValues?.shirtNumber ?? player?.shirtNumber ?? undefined,
                    }}>
                    <Spacing childrenGutterY>
                        {!canEditFirstAndLastName && (
                            <Typography.Text>
                                {l("CompetitionTeamPlayers_FirstAndLastNameEditionDisabled")}
                            </Typography.Text>
                        )}
                        <Form.Item
                            label={l("CompetitionTeamPlayers_FirstName")}
                            name={guard<FormField>("firstName")}
                            rules={[
                                { required: true, message: l("Common_Validation_FieldRequired") },
                                { max: 250, message: l("Common_Validation_FieldTooLong") },
                            ]}>
                            <Input disabled={!canEditFirstAndLastName} />
                        </Form.Item>
                    </Spacing>
                    <Form.Item
                        label={l("CompetitionTeamPlayers_LastName")}
                        name={guard<FormField>("lastName")}
                        rules={[
                            { required: true, message: l("Common_Validation_FieldRequired") },
                            { max: 250, message: l("Common_Validation_FieldTooLong") },
                        ]}>
                        <Input disabled={!canEditFirstAndLastName} />
                    </Form.Item>
                    <Form.Item
                        label={l("CompetitionTeamPlayers_ShirtNumber")}
                        name={guard<FormField>("shirtNumber")}
                        rules={[
                            {
                                validator: (rule, value: FormFields["shirtNumber"], callback) => {
                                    if (
                                        value &&
                                        (!isValidInteger(value) || (isValidInteger(value) && parseInt(value) < 0))
                                    ) {
                                        callback(rule.message as string);
                                    } else {
                                        callback();
                                    }
                                },
                                message: l("CompetitionTeamPlayers_CreateNew_ShirtNumberError"),
                            },
                        ]}>
                        <Input type="number" />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

export type FormFields = {
    firstName: string;
    lastName: string;
    shirtNumber?: string;
};

type FormField = keyof FormFields;

export default PlayerFormDialog;
