import { PlusOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Avatar, Button, Radio } from "antd";
import ImageUpload from "Components/ImageUpload";
import { l } from "Languages";
import React from "react";
import styles from "./styles.scss";

const cx = mkCx(styles);

type ThumbnailSelectProps = {
    photos: string[];
    selectedPhoto?: string;

    onUpload: (file: File) => void;
    onChange?: (selectedPhoto?: string) => void;
};

const ThumbnailSelect: React.FunctionComponent<ThumbnailSelectProps> = ({
    photos,
    selectedPhoto,
    onUpload,
    onChange,
}) => {
    return (
        <Radio.Group className={cx("container")} value={selectedPhoto} onChange={e => onChange?.(e.target.value)}>
            <ImageUpload onUpload={onUpload}>
                <Button className={cx("upload-photo-button")} type="dashed" size="large" icon={<PlusOutlined />} />
            </ImageUpload>
            {photos.map(p => (
                <Radio key={p} value={p}>
                    <img src={p} className={cx("image")} />
                </Radio>
            ))}
            <Radio value={undefined}>
                <Avatar shape="square" size={64}>
                    {l("Articles_Editor_Thumbnail_NoThumbnail")}
                </Avatar>
            </Radio>
        </Radio.Group>
    );
};

export default ThumbnailSelect;
