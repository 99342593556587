import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    PlusOutlined,
    RetweetOutlined,
} from "@ant-design/icons";
import { Button, message, Modal, Table } from "antd";
import { ColumnProps } from "antd/lib/table/Column";
import DropdownMenu from "Components/DropdownMenu";
import PageContent from "Components/PageContent";
import ReorderButtons from "Components/ReorderButtons";
import Spacing from "Components/Spacing";
import CustomPhase from "Domain/CompetitionPhases/CustomPhase/CustomPhase";
import MatchGroup from "Domain/CompetitionPhases/CustomPhase/MatchGroup";
import ReorderMatchGroupsStore from "Domain/CompetitionPhases/CustomPhase/ReorderMatchGroupsStore";
import { l } from "Languages";
import { Observer, useObserver } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import useRunInTask from "Utils/Hooks/useRunInTask";
import MatchGroupFormDialog, { FormFields } from "./MatchGroupFormDialog";

type MatchGroupsProps = {
    phase: CustomPhase;
};

type MatchGroupFormState =
    | {
          mode: "edit";
          group: MatchGroup;
      }
    | { mode: "create" | "off" };

const MatchGroups: React.FunctionComponent<MatchGroupsProps> = ({ phase }) => {
    const [matchGroupFormState, setMatchGroupFormState] = useState<MatchGroupFormState>({
        mode: "off",
    });

    const [matchGroupFormSaveInProgress, runGroupFormSaveInTask] = useRunInTask();

    const reorderMatchGroupsStore = useMemo(() => new ReorderMatchGroupsStore(phase), [phase]);

    const onFinish = useCallback(
        (values: FormFields) => {
            runGroupFormSaveInTask(async () => {
                if (matchGroupFormState.mode === "create") {
                    const result = await phase.addMatchGroup(values.name);

                    result
                        .handle(
                            ["GroupIdAlreadyInUse", "PhaseNotFound", "NameMissingOrEmpty", "NameTooLong", "failure"],
                            () => message.error(l("CompetitionPhases_Details_MatchGroups_Create_Failure")),
                        )
                        .handle(["success"], () => {
                            message.success(l("CompetitionPhases_Details_MatchGroups_Create_Success"));

                            setMatchGroupFormState({ mode: "off" });
                        })
                        .check();
                } else if (matchGroupFormState.mode === "edit") {
                    const result = await phase.renameMatchGroup(matchGroupFormState.group.id, values.name);

                    result
                        .handle(
                            ["PhaseNotFound", "GroupNotFound", "NameMissingOrEmpty", "NameTooLong", "failure"],
                            () => message.error(l("CompetitionPhases_Details_MatchGroups_Edit_Failure")),
                        )
                        .handle(["success"], () => {
                            message.success(l("CompetitionPhases_Details_MatchGroups_Edit_Success"));

                            setMatchGroupFormState({ mode: "off" });
                        })
                        .check();
                }
            });
        },
        [matchGroupFormState, phase, runGroupFormSaveInTask],
    );

    const onSaveMatchGroupsOrder = useCallback(async () => {
        const saveMatchGroupsMessageKey = "match-groups-order-save-in-progress";
        message.loading({
            content: l("CompetitionPhases_Details_MatchGroups_Reorder_SaveInProgress"),
            duration: 0,
            key: saveMatchGroupsMessageKey,
        });

        const result = await reorderMatchGroupsStore.saveMatchGroupsOrder();

        result
            .handle(
                [
                    "GroupNotFound",
                    "LocalGroupIdsContainsDuplicates",
                    "LocalGroupIdsNullOrMissing",
                    "PhaseNotFound",
                    "failure",
                ],
                () => {
                    message.error({
                        content: l("CompetitionPhases_Details_MatchGroups_Reorder_Failure"),
                        key: saveMatchGroupsMessageKey,
                    });
                },
            )
            .handle("success", () => {
                message.success({
                    content: l("CompetitionPhases_Details_MatchGroups_Reorder_Success"),
                    key: saveMatchGroupsMessageKey,
                });
            })
            .check();
    }, [reorderMatchGroupsStore]);

    const deleteMatchGroup = useCallback(
        async (matchGroupId: string) => {
            const result = await phase.deleteMatchGroup(matchGroupId);

            result
                .handle(["GroupNotFound", "PhaseNotFoundOrNotACustomPhase", "failure"], () =>
                    message.error(l("CompetitionPhases_Details_MatchGroups_Delete_Failure")),
                )
                .handle("success", () => {
                    message.success(l("CompetitionPhases_Details_MatchGroups_Delete_Success"));
                })
                .check();
        },
        [phase],
    );

    const showDeleteMatchGroupConfirmation = useCallback(
        (matchGroupId: string) => {
            Modal.confirm({
                onOk: () => deleteMatchGroup(matchGroupId),
                icon: <ExclamationCircleOutlined />,
                title: l("CompetitionPhases_Details_MatchGroups_Delete_Confirmation_Title"),
                content: l("CompetitionPhases_Details_MatchGroups_Delete_Confirmation_Content"),
                okText: l("Common_Remove"),
                okButtonProps: {
                    danger: true,
                },
                cancelText: l("Common_Cancel"),
                centered: true,
                maskClosable: true,
            });
        },
        [deleteMatchGroup],
    );

    const columns = useMemo(
        (): ColumnProps<MatchGroup>[] => [
            {
                title: l("CompetitionPhases_Details_MatchGroups_Name"),
                render: (_, group) => <Observer>{() => <span>{group.name}</span>}</Observer>,
            },
            {
                title: phase.groups && phase.groups?.length >= 2 && (
                    <Observer>
                        {() =>
                            reorderMatchGroupsStore.isReordering ? (
                                <Spacing childrenGutterX>
                                    <Button onClick={() => reorderMatchGroupsStore.stopReorder()}>
                                        {l("Common_Cancel")}
                                    </Button>
                                    <Button type="primary" onClick={onSaveMatchGroupsOrder}>
                                        {l("Common_Save")}
                                    </Button>
                                </Spacing>
                            ) : (
                                <DropdownMenu
                                    menuItems={[
                                        {
                                            children: (
                                                <>
                                                    <RetweetOutlined />{" "}
                                                    {l("CompetitionPhases_Details_MatchGroups_Reorder")}
                                                </>
                                            ),
                                            onClick: reorderMatchGroupsStore.startMatchGroupsReorder,
                                        },
                                    ]}
                                />
                            )
                        }
                    </Observer>
                ),
                render: (_, group, index) => (
                    <Observer>
                        {() =>
                            reorderMatchGroupsStore.isReordering && phase.groups ? (
                                <ReorderButtons
                                    isFirst={index === 0}
                                    isLast={index === phase.groups?.length - 1}
                                    onMoveDown={() => reorderMatchGroupsStore.moveItemDown(group.id)}
                                    onMoveUp={() => reorderMatchGroupsStore.moveItemUp(group.id)}
                                />
                            ) : (
                                <DropdownMenu
                                    menuItems={[
                                        {
                                            children: (
                                                <>
                                                    <EditOutlined /> {l("CompetitionPhases_Details_MatchGroups_Edit")}
                                                </>
                                            ),
                                            onClick: () => setMatchGroupFormState({ mode: "edit", group: group }),
                                        },
                                        {
                                            children: (
                                                <>
                                                    <DeleteOutlined /> {l("Common_Remove")}
                                                </>
                                            ),
                                            onClick: () => showDeleteMatchGroupConfirmation(group.id),
                                        },
                                    ]}
                                />
                            )
                        }
                    </Observer>
                ),
                align: "right",
            },
        ],
        [reorderMatchGroupsStore, phase.groups, onSaveMatchGroupsOrder, showDeleteMatchGroupConfirmation],
    );

    return useObserver(() => (
        <>
            <Table columns={columns} dataSource={reorderMatchGroupsStore.draft ?? phase.groups} pagination={false} />
            {!reorderMatchGroupsStore.isReordering && (
                <PageContent.ActionButton onClick={() => setMatchGroupFormState({ mode: "create" })}>
                    <PlusOutlined /> {l("CompetitionPhases_Details_MatchGroups_Create")}
                </PageContent.ActionButton>
            )}
            {matchGroupFormState.mode !== "off" && (
                <MatchGroupFormDialog
                    matchGroup={matchGroupFormState.mode === "edit" ? matchGroupFormState.group : undefined}
                    saveInProgress={matchGroupFormSaveInProgress}
                    onFinish={onFinish}
                    onClose={() => {
                        setMatchGroupFormState({ mode: "off" });
                    }}
                />
            )}
        </>
    ));
};

export default MatchGroups;
