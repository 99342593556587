import { CompetitionPhaseDetailsDTO, CompetitionPhaseDTO, PhaseTypeDTO } from "Contracts/PlayooLeagueClient";
import { CompetitionTeamStore } from "Domain/Competitions/CompetitionTeamStore";
import MatchStore from "Domain/Matches/MatchStore";
import { CompetitionPhase } from "./CompetitionPhase";
import CustomPhase from "./CustomPhase/CustomPhase";
import ExternalReferencesStore from "./ExternalReferences/ExternalReferencesStore";
import GroupsPhase from "./GroupsPhase/GroupsPhase";
import KnockoutPhase from "./KnockoutPhase/KnockoutPhase";
import TablePhase from "./TablePhase/TablePhase";

class CompetitionPhaseFactory {
    static fromDTO(
        dto: CompetitionPhaseDTO | CompetitionPhaseDetailsDTO,
        competitionId: string,
        dependencies: {
            competitionTeamStore: CompetitionTeamStore;
            matchStore: MatchStore;
            externalReferencesStore: ExternalReferencesStore;
        },
    ): CompetitionPhase {
        switch (dto.Type) {
            case PhaseTypeDTO.Table:
                return TablePhase.fromDTO(dto, competitionId, dependencies);
            case PhaseTypeDTO.Groups:
                return GroupsPhase.fromDTO(dto, competitionId, dependencies);
            case PhaseTypeDTO.Custom:
                return CustomPhase.fromDTO(dto, competitionId, dependencies);
            case PhaseTypeDTO.Knockout:
                return KnockoutPhase.fromDTO(dto, competitionId, dependencies);
        }
    }
}

export default CompetitionPhaseFactory;
