import { computed } from "mobx";
import CustomPhase from "./CustomPhase";

class AvailableTeamsForCustomPhase {
    private readonly phase: CustomPhase;

    constructor(phase: CustomPhase) {
        this.phase = phase;
    }

    @computed get teams() {
        const teamsInPhaseIds = new Set(this.phase.teamsIds);

        const teamsInPhase = this.phase.teams?.filter(t => teamsInPhaseIds.has(t.id));

        return teamsInPhase?.sort((t1, t2) => t1.displayName.localeCompare(t2.displayName)) ?? [];
    }

    @computed get nonPlaceholderTeams() {
        return this.teams.filter(t => !t.isPlaceholder);
    }
}

export default AvailableTeamsForCustomPhase;
