import { handleResponse } from "@leancode/validation";
import {
    AddGroupsPhaseMatch,
    CompetitionPhaseDetailsDTO,
    CompetitionPhaseDTO,
    GenerateGroupsPhaseMatchSchedule,
    PhaseTypeDTO,
    SetGroupsPhaseTeamGroups,
} from "Contracts/PlayooLeagueClient";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import { CompetitionTeamStore } from "Domain/Competitions/CompetitionTeamStore";
import MatchResult from "Domain/Matches/MatchResult";
import MatchStore from "Domain/Matches/MatchStore";
import { l } from "Languages";
import { action, computed, observable, runInAction } from "mobx";
import moment from "moment";
import api from "Services/Api";
import { dateTimeOffsetFromDTOOptional, dateTimeOffsetToDTOOptional } from "Utils/DTO";
import newId from "Utils/newId";
import { notUndefined } from "Utils/predicates";
import CompetitionPhaseBase, { TeamReplacement } from "../CompetitionPhaseBase";
import { ExternalReference } from "../ExternalReferences/ExternalReference";
import ExternalReferencesStore from "../ExternalReferences/ExternalReferencesStore";
import NthBestForPlaceInGroupReference from "../ExternalReferences/NthBestForPlaceInGroupReference";
import PlaceInGroupReference from "../ExternalReferences/PlaceInGroupReference";
import PlaceInTableReference from "../ExternalReferences/PlaceInTableReference";
import TablePointsModifier from "../TablePointsModifier";
import TieBreakingOrderTeam from "../TieBreakingOrderTeam";
import GroupsPhaseMatch from "./GroupsPhaseMatch";
import TeamGroup from "./TeamGroup";

export enum ScheduleGenerationStatus {
    OneOfGroupsHasTooFewTeams,
    PhaseHasNoGroups,
    NotGenerated,
    Generated,
}

class GroupsPhase extends CompetitionPhaseBase<GroupsPhaseMatch> {
    @observable groups?: TeamGroup[];

    private constructor(
        id: string,
        competitionId: string,
        dependencies: {
            competitionTeamStore: CompetitionTeamStore;
            matchStore: MatchStore;
            externalReferencesStore: ExternalReferencesStore;
        },
    ) {
        super(id, competitionId, PhaseTypeDTO.Groups, dependencies);
    }

    @computed get teamsAssignedToGroups() {
        return this.groups?.flatMap(g => g.teamIds);
    }

    @computed get groupsMap(): Map<string, TeamGroup> | undefined {
        return this.groups ? new Map(this.groups.map(g => [g.id, g])) : undefined;
    }

    @computed get displayName() {
        return this.name ?? l("CompetitionPhases_Groups");
    }

    @computed get scheduleGenerationStatus(): ScheduleGenerationStatus | undefined {
        if (this.schedule) {
            return ScheduleGenerationStatus.Generated;
        }

        if (!this.groups) {
            return undefined;
        }

        if (this.groups.some(g => g.teamIds.length < 2)) {
            return ScheduleGenerationStatus.OneOfGroupsHasTooFewTeams;
        }

        if (this.groups.length === 0) {
            return ScheduleGenerationStatus.PhaseHasNoGroups;
        }

        return ScheduleGenerationStatus.NotGenerated;
    }

    isTeamAddedToOneOfGroups(teamId: string) {
        return this.teamsAssignedToGroups?.some(t => t === teamId);
    }

    isTeamAddedToPhaseAndNotToAnyGroup(teamId: string) {
        return this.teamsIds?.some(t => t === teamId) && !this.isTeamAddedToOneOfGroups(teamId);
    }

    static fromDTO(
        dto: CompetitionPhaseDTO | CompetitionPhaseDetailsDTO,
        competitionId: string,
        dependencies: {
            competitionTeamStore: CompetitionTeamStore;
            matchStore: MatchStore;
            externalReferencesStore: ExternalReferencesStore;
        },
    ): GroupsPhase {
        return new GroupsPhase(dto.Id, competitionId, dependencies).updateFromDTO(dto);
    }

    @action.bound
    updateFromDTO(dto: CompetitionPhaseDTO | CompetitionPhaseDetailsDTO) {
        this.name = dto.Name ?? undefined;
        this.isOngoing = dto.IsOngoing;
        this.linkedPhaseId = dto.LinkedPhaseId ?? undefined;

        if ("Teams" in dto) {
            this.teamsIds = dto.Teams.map(t => t.TeamId);
            this.teamsTieBreakingOrder = dto.Teams.map(
                t => new TieBreakingOrderTeam(t.TeamId, t.TieBreakingOrder, this.competitionTeamStore),
            );

            if (dto.GroupsPhaseDetails) {
                this.groups = dto.GroupsPhaseDetails.Groups.map(g => TeamGroup.fromDTO(g, this.competitionTeamStore));

                this.matchStore.put(
                    ...dto.GroupsPhaseDetails.Schedule.map(
                        m =>
                            new GroupsPhaseMatch(
                                m.Id,
                                this.id,
                                {
                                    matchday: m.MatchInPhaseDesignator ?? 0,
                                    groupId: m.LocalGroupId,
                                    groupName: this.groupsMap?.get(m.LocalGroupId)?.name ?? "",
                                    article: undefined,
                                    date: dateTimeOffsetFromDTOOptional(m.Date),
                                    team1Id: m.Team1Id ?? undefined,
                                    team2Id: m.Team2Id ?? undefined,
                                    status: m.Status,
                                    sportsFieldId: m.SportsFieldId ?? undefined,
                                    result: m.Result ? MatchResult.fromDTO(m.Result) : undefined,
                                },
                                this.competitionTeamStore,
                            ),
                    ),
                );

                this.matchIds =
                    dto.GroupsPhaseDetails.Schedule.length > 0
                        ? dto.GroupsPhaseDetails.Schedule.map(m => m.Id)
                        : undefined;

                dto.PlaceInGroupReferences.forEach(r =>
                    this.externalReferencesStore.put(PlaceInGroupReference.fromDTO(r, this.id)),
                );

                dto.NthBestForPlaceInGroupReferences.forEach(r =>
                    this.externalReferencesStore.put(NthBestForPlaceInGroupReference.fromDTO(r, this.id)),
                );

                dto.PlaceInTableReferences.forEach(r =>
                    this.externalReferencesStore.put(PlaceInTableReference.fromDTO(r, this.id)),
                );

                this.externalReferencesIds = [
                    ...dto.PlaceInGroupReferences.map(r => r.Id),
                    ...dto.NthBestForPlaceInGroupReferences.map(r => r.Id),
                    ...dto.PlaceInTableReferences.map(r => r.Id),
                ];

                this.defaultMatchDurationInMinutes = dto.DefaultMatchDurationInMinutes ?? undefined;
                this.defaultPauseBetweenMatchesInMinutes = dto.DefaultPauseBetweenMatchesInMinutes ?? undefined;

                this.pointsModifiers = dto.GroupsPhaseDetails.PointsModifiers.map(m =>
                    TablePointsModifier.fromDTO(this.id, m, this.competitionTeamStore),
                );
            }
        }

        return this;
    }

    async removeTeam(teamId: string, suppressMessage?: boolean) {
        const handler = await super.removeTeam(teamId, suppressMessage);

        handler.handle("success", () => {
            const group = this.groups?.find(g => g.teamIds.some(tid => teamId === tid));

            group?.setTeams(group.teamIds.filter(tid => tid !== teamId));
        });

        return handler;
    }

    async addTeamsToGroup(teamIds: string[], groupId: string) {
        if (!this.teamsIds || !this.groups) {
            throw new Error("Team details not fetched.");
        }

        const teamsNotAddedToPhase = teamIds.filter(t => !this.teamsIds?.some(pt => pt === t));

        if (teamsNotAddedToPhase.length > 0) {
            const addTeamsToPhaseHandler = await this.addTeamsToPhase(teamsNotAddedToPhase);

            let wasAddingTeamsToPhaseSuccessful = false;

            addTeamsToPhaseHandler.handle("success", () => {
                wasAddingTeamsToPhaseSuccessful = true;
            });

            if (!wasAddingTeamsToPhaseSuccessful) {
                return false;
            }
        }

        const newGroups = this.groups.map(g => g.clone());

        const group = newGroups.find(g => g.id === groupId);

        group?.setTeams([...group.teamIds, ...teamIds]);

        const setGroupsHandler = await this.setGroups(newGroups);

        let wasSettingGroupsSuccessful = false;

        setGroupsHandler.handle("success", () => {
            wasSettingGroupsSuccessful = true;
        });

        return wasSettingGroupsSuccessful;
    }

    async setGroups(groups: TeamGroup[]) {
        const response = await api.setGroupsPhaseTeamGroups({
            PhaseId: this.id,
            TeamGroups: groups.map(g => g.toDTO()),
        });

        handleResponse(response, SetGroupsPhaseTeamGroups).handle("success", () => {
            runInAction(() => {
                this.groups = groups;
            });
        });

        return handleResponse(response, SetGroupsPhaseTeamGroups);
    }

    async generateSchedule(matchesPerPair: 1 | 2) {
        if (this.scheduleGenerationStatus !== ScheduleGenerationStatus.NotGenerated) {
            throw new Error("Phase is in invalid state or schedule is already generated.");
        }

        const response = await api.generateGroupsPhaseMatchSchedule({
            PhaseId: this.id,
            MatchesPerPair: matchesPerPair,
        });

        if (response.isSuccess && response.result.WasSuccessful) {
            await this.fetchDetails();
        }

        return handleResponse(response, GenerateGroupsPhaseMatchSchedule);
    }

    async addMatch({ groupId, team1Id, team2Id, matchday, date, sportsFieldId }: AddGroupsPhaseMatchData) {
        const response = await api.addGroupsPhaseMatch({
            PhaseId: this.id,
            GroupId: groupId,
            Team1Id: team1Id,
            Team2Id: team2Id,
            Matchday: matchday,
            Date: dateTimeOffsetToDTOOptional(date),
            SportsFieldId: sportsFieldId,
        });

        if (response.isSuccess && response.result.WasSuccessful) {
            await this.fetchDetails();
        }

        return handleResponse(response, AddGroupsPhaseMatch);
    }

    async addPlaceInGroupReferencesToGroup(references: PlaceInGroupReference[], groupId: string) {
        return this.addExternalReferencesToGroupBase(references, groupId, async placeholderIdsMap => {
            if (!this.linkedPhaseId) {
                return false;
            }

            const response = await api.addPlaceInGroupReferencesToPhase({
                PhaseId: this.id,
                ReferencedPhaseId: this.linkedPhaseId,
                References: references.map(r => ({
                    Id: r.id,
                    GroupId: r.groupId,
                    PlaceInGroup: r.placeInGroup,
                    PlaceholderId: placeholderIdsMap.get(r.id) ?? "",
                })),
            });

            return response.isSuccess && response.result.WasSuccessful;
        });
    }

    async addNthBestForPlaceInGroupReferencesToGroup(references: NthBestForPlaceInGroupReference[], groupId: string) {
        return this.addExternalReferencesToGroupBase(references, groupId, async placeholderIdsMap => {
            if (!this.linkedPhaseId) {
                return false;
            }

            const response = await api.addNthBestForPlaceInGroupReferences({
                PhaseId: this.id,
                ReferencedPhaseId: this.linkedPhaseId,
                References: references.map(r => ({
                    Id: r.id,
                    Place: r.place,
                    PlaceInGroup: r.placeInGroup,
                    PlaceholderId: placeholderIdsMap.get(r.id) ?? "",
                })),
            });

            return response.isSuccess && response.result.WasSuccessful;
        });
    }

    async addPlaceInTableReferencesToGroup(references: PlaceInTableReference[], groupId: string) {
        return this.addExternalReferencesToGroupBase(references, groupId, async placeholderIdsMap => {
            if (!this.linkedPhaseId) {
                return false;
            }

            const response = await api.addPlaceInTableReferencesToPhase({
                PhaseId: this.id,
                ReferencedPhaseId: this.linkedPhaseId,
                References: references.map(r => ({
                    Id: r.id,
                    PlaceInTable: r.placeInTable,
                    PlaceholderId: placeholderIdsMap.get(r.id) ?? "",
                })),
            });

            return response.isSuccess && response.result.WasSuccessful;
        });
    }

    private async addExternalReferencesToGroupBase<TReference extends ExternalReference>(
        references: TReference[],
        groupId: string,
        adder: (placeholderIdsMap: Map<string, string>) => Promise<boolean>,
    ) {
        if (!this.groups) {
            throw new Error("Groups are not available.");
        }

        if (!this.linkedPhaseId) {
            throw new Error("External references can be added only to phases linked to other phases.");
        }

        if (references.some(r => r.referencedPhaseId !== this.linkedPhaseId)) {
            throw new Error("All references should point to linked phase.");
        }

        const placeholderIdsMap: Map<string, string> = new Map(references.map(r => [r.id, newId()]));

        const success = await adder(placeholderIdsMap);

        if (success) {
            const placeholderTeams = references.map(r =>
                CompetitionTeam.createPlaceholderTeamForExternalReference(
                    {
                        competitionId: this.competitionId,
                        phaseId: this.id,
                        placeholderId: placeholderIdsMap.get(r.id) ?? "",
                        referenceId: r.id,
                    },
                    this.externalReferencesStore,
                ),
            );

            this.competitionTeamStore.put(...placeholderTeams);
            this.externalReferencesStore.put(...references);

            runInAction(() => {
                this.teamsIds?.push(...placeholderTeams.map(t => t.id));
            });

            return this.addTeamsToGroup(
                placeholderTeams.map(t => t.id),
                groupId,
            );
        } else {
            return false;
        }
    }

    async replacePlaceholderTeams(replacements: TeamReplacement[]) {
        if (!this.teamsIds || !this.teamsAssignedToGroups) {
            throw new Error("Phase details not fetched.");
        }

        return super.replacePlaceholderTeams(replacements);
    }

    async advanceTeamsForAllGroupsPhaseReferences() {
        const placeholdersAddedToPhaseAndNotAddedToAnyGroup =
            this.placeholdersReadyForAdvancing
                ?.map(p => p.externalReference?.predictedAdvancingTeamId)
                .filter(notUndefined)
                .filter(predictedAdvancingTeamId =>
                    this.isTeamAddedToPhaseAndNotToAnyGroup(predictedAdvancingTeamId),
                ) ?? [];

        await Promise.all(placeholdersAddedToPhaseAndNotAddedToAnyGroup.map(p => this.removeTeam(p, true)));

        return super.advanceTeamsForAllGroupsPhaseReferences();
    }

    async advanceTeamForGroupsPhaseReference(placeholderId: string) {
        const placeholder = this.teams?.find(t => t.id === placeholderId);

        if (!placeholder || !placeholder.externalReference?.predictedAdvancingTeamId) {
            throw new Error("Placeholder not found or not reference related");
        }

        if (this.isTeamAddedToPhaseAndNotToAnyGroup(placeholder.externalReference.predictedAdvancingTeamId)) {
            await this.removeTeam(placeholder.externalReference.predictedAdvancingTeamId, true);
        }

        return super.advanceTeamForGroupsPhaseReference(placeholderId);
    }

    async swapTeams(team1Id: string, team2Id: string) {
        if (!this.teamsIds || !this.teamsAssignedToGroups) {
            throw new Error("Phase details not fetched.");
        }

        return super.swapTeams(team1Id, team2Id);
    }
}

export type AddGroupsPhaseMatchData = {
    groupId: string;
    team1Id: string;
    team2Id: string;
    date?: moment.Moment;
    matchday: number;
    sportsFieldId?: string;
};

export default GroupsPhase;
