import { Checkbox, Col, Form, Input, message, Radio, Row, Select, Spin } from "antd";
import { Rule } from "antd/lib/form";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import { PhaseTypeDTO } from "Contracts/PlayooLeagueClient";
import TablePhaseScoring from "Domain/CompetitionPhases/TablePhase/TablePhaseScoring";
import Competition from "Domain/Competitions/Competition";
import { l } from "Languages";
import React, { ComponentProps, useCallback, useMemo } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";
import { isValidInteger } from "Utils/validationHelpers";

const phasesWhichCanHaveALinkedPhase = [PhaseTypeDTO.Groups, PhaseTypeDTO.Custom, PhaseTypeDTO.Knockout];

type CreateCompetitionPhaseFormDialogProps = {
    competition: Competition;

    onClose?: (savedFormFields?: FormFields) => void;
};

const CreateCompetitionPhaseDialog: React.FunctionComponent<CreateCompetitionPhaseFormDialogProps> = ({
    competition,
    onClose,
}) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const addTablePhase = useCallback(
        async (values: FormFields) => {
            const customScoring =
                values.useCustomTableScoring &&
                values.pointsPerVictory !== undefined &&
                values.pointsPerVictory.length > 0 &&
                values.pointsPerDraw !== undefined &&
                values.pointsPerDraw.length > 0 &&
                values.pointsPerDefeat !== undefined &&
                values.pointsPerDefeat.length > 0
                    ? new TablePhaseScoring({
                          pointsPerVictory: parseInt(values.pointsPerVictory),
                          pointsPerDefeat: parseInt(values.pointsPerDefeat),
                          pointsPerDraw: parseInt(values.pointsPerDraw),
                      })
                    : undefined;

            const result = await competition.addTablePhase(
                values.name,
                customScoring,
                values.addTeamsFromCompetitionToPhase,
            );

            result
                .handle(["CompetitionNotFound", "NameTooLong", "PhaseIdAlreadyInUse", "failure"], () =>
                    message.error(l("CompetitionPhases_Create_Failure")),
                )
                .handle("success", () => {
                    message.success(l("CompetitionPhases_Create_Success"));

                    onClose?.();
                })
                .check();
        },
        [competition, onClose],
    );

    const addGroupsPhase = useCallback(
        async (values: FormFields) => {
            const result = await competition.addGroupsPhase(
                values.name,
                !values.isLinked && values.addTeamsFromCompetitionToPhase,
                values.isLinked ? values.phaseIdToLink : undefined,
            );

            result
                .handle(
                    [
                        "CompetitionNotFound",
                        "NameTooLong",
                        "PhaseIdAlreadyInUse",
                        "LinkedPhaseNotFoundOrIsInvalidType",
                        "failure",
                    ],
                    () => message.error(l("CompetitionPhases_Create_Failure")),
                )
                .handle("success", () => {
                    message.success(l("CompetitionPhases_Create_Success"));

                    onClose?.();
                })
                .check();
        },
        [competition, onClose],
    );

    const addCustomPhase = useCallback(
        async (values: FormFields) => {
            const result = await competition.addCustomPhase(
                values.name,
                values.addTeamsFromCompetitionToPhase,
                values.isLinked ? values.phaseIdToLink : undefined,
            );

            result
                .handle(
                    [
                        "CompetitionNotFound",
                        "NameTooLong",
                        "PhaseIdAlreadyInUse",
                        "LinkedPhaseNotFoundOrIsInvalidType",
                        "failure",
                    ],
                    () => message.error(l("CompetitionPhases_Create_Failure")),
                )
                .handle("success", () => {
                    message.success(l("CompetitionPhases_Create_Success"));

                    onClose?.();
                })
                .check();
        },
        [competition, onClose],
    );

    const addKnockoutPhase = useCallback(
        async (values: FormFields) => {
            const result = await competition.addKnockoutPhase(
                values.name,
                values.addTeamsFromCompetitionToPhase,
                values.isLinked ? values.phaseIdToLink : undefined,
            );

            result
                .handle(["CompetitionNotFound", "NameTooLong", "PhaseIdAlreadyInUse", "failure"], () =>
                    message.error(l("CompetitionPhases_Create_Failure")),
                )
                .handle("success", () => {
                    message.success(l("CompetitionPhases_Create_Success"));

                    onClose?.();
                })
                .check();
        },
        [competition, onClose],
    );

    const onFinish = useCallback(
        (values: FormFields) => {
            runInTask(async () => {
                switch (values.phaseType) {
                    case PhaseTypeDTO.Table:
                        return addTablePhase(values);
                    case PhaseTypeDTO.Groups:
                        return addGroupsPhase(values);
                    case PhaseTypeDTO.Custom:
                        return addCustomPhase(values);
                    case PhaseTypeDTO.Knockout:
                        return addKnockoutPhase(values);
                }
            });
        },
        [runInTask, addTablePhase, addGroupsPhase, addCustomPhase, addKnockoutPhase],
    );

    const pointsConfigRule: Rule = useMemo(
        () => ({
            validator: (rule, value: string | undefined, callback) => {
                const useCustomTableScoring = form.getFieldValue(guard<FormField>("useCustomTableScoring"));

                if (useCustomTableScoring && (!value || !isValidInteger(value))) {
                    callback(rule.message as string);
                } else {
                    callback();
                }
            },
            message: l("Common_Validation_Number"),
        }),
        [form],
    );

    const phasesOptions = useMemo<ComponentProps<typeof Select>["options"]>(
        () => competition.linkablePhases?.map(({ id, name }) => ({ value: id, label: name })) ?? [],
        [competition.linkablePhases],
    );

    const initialValues: Partial<FormFields> = useMemo(
        () => ({
            pointsPerVictory: TablePhaseScoring.defaults.victory.toString(),
            pointsPerDraw: TablePhaseScoring.defaults.draw.toString(),
            pointsPerDefeat: TablePhaseScoring.defaults.defeat.toString(),
            addTeamsFromCompetitionToPhase: true,
            isLinked: !!competition.linkablePhases?.length,
        }),
        [competition.linkablePhases?.length],
    );

    return (
        <>
            <Modal
                title={l("CompetitionPhases_Create_Title")}
                onCancel={() => onClose?.()}
                okText={l("Common_Add")}
                cancelText={l("Common_Cancel")}
                onOk={form.submit}>
                <Spin spinning={isRunning}>
                    <Form layout="vertical" form={form} onFinish={onFinish} initialValues={initialValues}>
                        <Form.Item
                            label={l("CompetitionPhases_Create_Name")}
                            name={guard<FormField>("name")}
                            rules={[{ max: 250, message: l("Common_Validation_FieldTooLong") }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={l("CompetitionPhases_Create_PhaseType")}
                            name={guard<FormField>("phaseType")}
                            rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                            <Radio.Group>
                                <Radio.Button value={PhaseTypeDTO.Table}>{l("CompetitionPhases_Table")}</Radio.Button>
                                <Radio.Button value={PhaseTypeDTO.Knockout}>
                                    {l("CompetitionPhases_Knockout")}
                                </Radio.Button>
                                <Radio.Button value={PhaseTypeDTO.Groups}>{l("CompetitionPhases_Groups")}</Radio.Button>
                                <Radio.Button value={PhaseTypeDTO.Custom}>{l("CompetitionPhases_Custom")}</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues: FormFields, currentValues: FormFields) =>
                                prevValues.phaseType !== currentValues.phaseType ||
                                prevValues.useCustomTableScoring !== currentValues.useCustomTableScoring
                            }>
                            {({ getFieldValue }) =>
                                getFieldValue(guard<FormField>("phaseType")) === PhaseTypeDTO.Table && (
                                    <>
                                        <Form.Item
                                            name={guard<FormField>("useCustomTableScoring")}
                                            valuePropName="checked">
                                            <Checkbox>{l("CompetitionPhases_UpdateScoring")}</Checkbox>
                                        </Form.Item>
                                        {getFieldValue(guard<FormField>("useCustomTableScoring")) && (
                                            <Row gutter={[16, 0]}>
                                                <Col span={8}>
                                                    <Form.Item
                                                        label={l("CompetitionPhases_Scoring_Victories")}
                                                        name={guard<FormField>("pointsPerVictory")}
                                                        rules={[{ ...pointsConfigRule }]}>
                                                        <Input type="number" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        label={l("CompetitionPhases_Scoring_Draws")}
                                                        name={guard<FormField>("pointsPerDraw")}
                                                        rules={[{ ...pointsConfigRule }]}>
                                                        <Input type="number" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={8}>
                                                    <Form.Item
                                                        label={l("CompetitionPhases_Scoring_Defeats")}
                                                        name={guard<FormField>("pointsPerDefeat")}
                                                        rules={[{ ...pointsConfigRule }]}>
                                                        <Input type="number" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        )}
                                    </>
                                )
                            }
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues: FormFields, currentValues: FormFields) =>
                                prevValues.phaseType !== currentValues.phaseType ||
                                prevValues.isLinked !== currentValues.isLinked
                            }>
                            {({ getFieldValue }) =>
                                (!phasesWhichCanHaveALinkedPhase.includes(
                                    getFieldValue(guard<FormField>("phaseType")),
                                ) ||
                                    !getFieldValue(guard<FormField>("isLinked"))) && (
                                    <Form.Item
                                        name={guard<FormField>("addTeamsFromCompetitionToPhase")}
                                        valuePropName="checked">
                                        <Checkbox>{l("CompetitionPhases_Create_AddAllTeamsFromCompetition")}</Checkbox>
                                    </Form.Item>
                                )
                            }
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues: FormFields, currentValues: FormFields) =>
                                prevValues.phaseType !== currentValues.phaseType ||
                                prevValues.isLinked !== currentValues.isLinked
                            }>
                            {({ getFieldValue }) =>
                                phasesWhichCanHaveALinkedPhase.includes(getFieldValue(guard<FormField>("phaseType"))) &&
                                (competition.linkablePhases?.length ?? 0) > 0 && (
                                    <>
                                        <Form.Item name={guard<FormField>("isLinked")} valuePropName="checked">
                                            <Checkbox>{l("CompetitionPhases_Create_LinkedPhase")}</Checkbox>
                                        </Form.Item>
                                        {getFieldValue(guard<FormField>("isLinked")) && (
                                            <Form.Item
                                                name={guard<FormField>("phaseIdToLink")}
                                                label={l("CompetitionPhases_Create_PhaseWhichWillBeLinkedWithNewPhase")}
                                                rules={[
                                                    { required: true, message: l("Common_Validation_FieldRequired") },
                                                ]}>
                                                <Select
                                                    options={phasesOptions}
                                                    placeholder={l("CompetitionPhases_Create_ChooseLinkedPhase")}
                                                />
                                            </Form.Item>
                                        )}
                                    </>
                                )
                            }
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
};

export type FormFields = {
    name?: string;
    phaseType: PhaseTypeDTO;
    useCustomTableScoring?: boolean;
    pointsPerVictory?: string;
    pointsPerDraw?: string;
    pointsPerDefeat?: string;
    addTeamsFromCompetitionToPhase: boolean;
    isLinked: boolean;
    phaseIdToLink?: string;
};

type FormField = keyof FormFields;

export default CreateCompetitionPhaseDialog;
