import { Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import Partner from "Domain/Partners/Partner";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";

type PartnerFormDialogProps = {
    partner: Partner;
    onClose: () => void;
};

const PartnerFormDialog: React.FunctionComponent<PartnerFormDialogProps> = ({ partner, onClose }) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const setLinkUriError = useCallback(
        (error: string) => {
            form.setFields([
                {
                    name: guard<FormField>("linkUri"),
                    errors: [error],
                },
            ]);
        },
        [form],
    );

    const onFinish = useCallback(
        (values: FormFields) =>
            runInTask(async () => {
                const handler = await partner.updateLinkUri(values.linkUri);

                handler
                    .handle(["PartnerNotFound", "failure"], () => {
                        message.error(l("Landing_Partners_UpdateLink_Failure"));
                    })
                    .handle("LinkUriTooLong", () => {
                        setLinkUriError(l("Common_Validation_FieldTooLong"));
                    })
                    .handle("LinkUriNotValid", () => {
                        setLinkUriError(l("Landing_Partners_UpdateLink_Validation_LinkNotValid"));
                    })
                    .handle("success", () => {
                        message.success(l("Landing_Partners_UpdateLink_Success"));

                        onClose();
                    });
            }),
        [onClose, partner, runInTask, setLinkUriError],
    );

    return useObserver(() => (
        <Modal
            title={l("Landing_Partners_UpdateLink_Form_Title")}
            onCancel={onClose}
            okText={l("Common_Save")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}
            okButtonProps={{
                disabled: isRunning,
                loading: isRunning,
            }}>
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                initialValues={{
                    [guard<FormField>("linkUri")]: partner?.linkUri,
                }}>
                <Form.Item
                    name={guard<FormField>("linkUri")}
                    label={l("Landing_Partners_UpdateLink_Form_Link")}
                    rules={[{ max: 500, message: l("Common_Validation_FieldTooLong") }]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    ));
};

export type FormFields = {
    linkUri?: string;
};

type FormField = keyof FormFields;

export default PartnerFormDialog;
