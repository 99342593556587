import { computed, observable, runInAction } from "mobx";
import loginManager from "Services/LoginManager";

export type SignInState = "signed_in" | "signing_in" | "not_signed_in" | "invalid_password" | "network_error";

class Authentication {
    @observable signInState: SignInState = loginManager.isSigned() ? "signed_in" : "not_signed_in";

    @computed
    get isSignedIn() {
        return this.signInState === "signed_in";
    }

    constructor() {
        loginManager.onChange(isSignedIn => {
            runInAction(() => {
                if (this.signInState !== "not_signed_in" && !isSignedIn) {
                    this.signInState = "not_signed_in";
                } else if (this.signInState !== "signed_in" && isSignedIn) {
                    this.signInState = "signed_in";
                }
            });
        });
    }

    signIn = async (username: string, password: string) => {
        runInAction(() => {
            this.signInState = "signing_in";
        });

        const signInResponse = await loginManager.trySignIn(username, password);

        runInAction(() => {
            switch (signInResponse.type) {
                case "success":
                    this.signInState = "signed_in";
                    break;

                case "failure":
                    this.signInState = "invalid_password";
                    break;

                case "networkError":
                    this.signInState = "network_error";
                    break;
            }
        });

        return this.signInState;
    };

    signOut = () => {
        loginManager.signOut();
    };
}

export default new Authentication();
