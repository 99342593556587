import { InfoCircleFilled } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Collapse } from "antd";
import { Select } from "Components/Select";
import GroupsPhase from "Domain/CompetitionPhases/GroupsPhase/GroupsPhase";
import SeasonPlannerFilter from "Domain/CompetitionPhases/SchedulePlanner/Filters/SeasonPlannerFilters";
import { l } from "Languages";
import React, { useMemo } from "react";
import styles from "./styles.scss";

const cx = mkCx(styles);

type SeasonUnassignedMatchesFilterProps = {
    filter: SeasonPlannerFilter;
};

const SeasonUnassignedMatchesFilter: React.FunctionComponent<SeasonUnassignedMatchesFilterProps> = ({ filter }) => {
    const groups = useMemo(
        () =>
            filter.groups.map(g => {
                const phase = filter.phases
                    .filter(p => p instanceof GroupsPhase)
                    .find(p => (p as GroupsPhase).groups?.some(({ id }) => id === g.id));
                return {
                    value: g.id,
                    label: `${g.name} - ${phase?.name ?? ""} - ${
                        filter.competitions.find(c => c.id === phase?.competitionId)?.name ?? ""
                    }`,
                    key: g.id,
                };
            }),
        [filter.competitions, filter.groups, filter.phases],
    );

    const phases = useMemo(
        () =>
            filter.phasesWithoutGroups.map(p => ({
                value: p.id,
                label: `${p.name} - ${filter.competitions.find(c => c.id === p.competitionId)?.name ?? ""}`,
                key: p.id,
            })),
        [filter.competitions, filter.phasesWithoutGroups],
    );

    return (
        <Collapse>
            <Collapse.Panel
                key="filters"
                header={
                    <div>
                        {l("CompetitionPhases_PlanSchedule_UnassignedMatchesFilter")}{" "}
                        {filter.isFiltered && <InfoCircleFilled className={cx("matches-list-narrowed-icon")} />}
                    </div>
                }>
                <Select
                    className={cx("groups-select")}
                    value={[...filter.selectedGroupIds, ...filter.selectedPhaseIds]}
                    onChange={(ids: string[]) => filter.setAllIds(new Set(ids))}
                    mode="multiple"
                    selectOptions={[...groups, ...phases]}
                    placeholder={l("CompetitionPhases_PlanSchedule_UnassignedMatchesFilter_GroupsOrPhases_Placeholder")}
                    showArrow
                    allowClear
                />
            </Collapse.Panel>
        </Collapse>
    );
};

export default SeasonUnassignedMatchesFilter;
