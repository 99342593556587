import { Match } from "Domain/Matches/Match";
import moment from "moment";
import MatchdayConfiguration, { SportsFieldSchedule } from "../MatchdayConfiguration";
import { isKnockoutPhaseMatch, isMatch } from "../Slot";
import SlotsConfiguration from "../SlotsConfiguration";
import { EmptySlot, getNextEmptySlotStartTimeOnSportsField } from "../SlotsIterator";
import { AvailableSportsFieldsConfiguration } from "../SportsFieldConfiguration";
import GreedyMatchdayScheduler, { PlanResult } from "./GreedyMatchdayScheduler";
import Competition from "Domain/Competitions/Competition";
import definedOrThrow from "Utils/empty";
import { maxBy } from "lodash";
import { PhaseTypeDTO } from "Contracts/PlayooLeagueClient";
import KnockoutPhaseMatch from "Domain/CompetitionPhases/KnockoutPhase/KnockoutPhaseMatch";

export type SportsFieldConfiguration = {
    closingTime?: moment.Moment;
};

class GreedySeasonMatchdayScheduler {
    private readonly slotsConfiguration: SlotsConfiguration;
    private readonly greedyMatchdayScheduler: GreedyMatchdayScheduler;

    constructor(slotsConfiguration: SlotsConfiguration) {
        this.slotsConfiguration = slotsConfiguration;
        this.greedyMatchdayScheduler = new GreedyMatchdayScheduler(slotsConfiguration);
    }

    plan(
        competitions: Competition[],
        matchdayConfiguration: MatchdayConfiguration,
        matches: Match[],
        availableSportsFieldsConfiguration: AvailableSportsFieldsConfiguration,
    ): PlanResult {
        let matchesToPlan = [...matches];

        const phasesList = this.getPhasesList(competitions);

        while (phasesList.some(phases => phases.iterator < phases.phases.length)) {
            const remainingUnassignedMatches = matchesToPlan.length;

            for (let i = 0; i < phasesList.length; i++) {
                const phases = phasesList[i];

                const phaseMatchesToPlan = this.getEarliestUnassignedPhaseMatches(phases, matchesToPlan);
                if (phaseMatchesToPlan.length === 0) {
                    continue;
                }

                const nextSlot = this.greedyMatchdayScheduler.getNextSlot(
                    matchdayConfiguration,
                    availableSportsFieldsConfiguration,
                );

                if (!nextSlot) {
                    return PlanResult.PartiallyPlanned;
                }

                const knockoutPhaseStage =
                    phases.phases[phases.iterator].type === PhaseTypeDTO.Knockout
                        ? this.getKnockoutMatchesToPlanStage(phaseMatchesToPlan as KnockoutPhaseMatch[])
                        : undefined;

                if (!this.isNextSlotValid(nextSlot, phases, matchdayConfiguration, knockoutPhaseStage)) {
                    continue;
                }

                const slotEndTime = nextSlot.startTime
                    .clone()
                    .add(this.slotsConfiguration.distanceBetweenMatchesInMinutes, "minutes");

                this.greedyMatchdayScheduler.plan(
                    matchdayConfiguration,
                    phaseMatchesToPlan,
                    this.getAvailableSportsFieldsConfigurationUntilEndTime(
                        availableSportsFieldsConfiguration,
                        slotEndTime,
                    ),
                );

                matchesToPlan = this.getUnassignedMatches(matchdayConfiguration, matchesToPlan);
            }

            if (remainingUnassignedMatches === 0) {
                return PlanResult.FullyPlanned;
            }

            if (remainingUnassignedMatches === matchesToPlan.length) {
                if (this.eventUpSportsFieldsEndTimes(matchdayConfiguration) === EvenUpResult.AlreadyEven) {
                    return PlanResult.PartiallyPlanned;
                }
            }
        }

        return PlanResult.FullyPlanned;
    }

    private getPhaseLastMatchStartDate(schedule: SportsFieldSchedule[], lastPhaseId: string) {
        return schedule
            .flatMap(({ slots }) => slots)
            .reduce((accumulator, current) => {
                const { slot, startTime } = current;
                if (
                    isMatch(slot) &&
                    slot.phaseId === lastPhaseId &&
                    startTime !== undefined &&
                    (accumulator === undefined || startTime.isAfter(accumulator))
                ) {
                    return startTime.clone();
                }

                return accumulator;
            }, undefined);
    }

    private getPhasesList(competitions: Competition[]): Phases[] {
        return competitions.reduce((accumulator, current) => {
            if ((current.phases?.length ?? 0) === 0) {
                return accumulator;
            }

            return [
                ...accumulator,
                { iterator: 0, phases: definedOrThrow(current.phases).map(({ id, type }) => ({ id, type })) },
            ];
        }, []);
    }

    private getEarliestUnassignedPhaseMatches(phases: Phases, matchesToPlan: Match[]) {
        while (phases.iterator < phases.phases.length) {
            const { id, type } = phases.phases[phases.iterator];

            const phaseMatchesToPlan = matchesToPlan.filter(match => match.phaseId === id);

            if (phaseMatchesToPlan.length > 0) {
                if (type !== PhaseTypeDTO.Knockout) {
                    return phaseMatchesToPlan;
                }

                const earliestStage = definedOrThrow(
                    maxBy(phaseMatchesToPlan as KnockoutPhaseMatch[], ({ stage }) => stage),
                ).stage;

                return (phaseMatchesToPlan as KnockoutPhaseMatch[]).filter(({ stage }) => stage === earliestStage);
            }

            phases.iterator++;
        }

        return [];
    }

    private isNextSlotValid(
        nextSlot: EmptySlot,
        phases: Phases,
        matchdayConfiguration: MatchdayConfiguration,
        knockoutPhaseStage?: number,
    ) {
        if (phases.iterator > 0) {
            const lastPhase = phases.phases[phases.iterator - 1];

            const lastPhaseMatchStartTime = this.getPhaseLastMatchStartDate(
                matchdayConfiguration.schedule,
                lastPhase.id,
            );

            if (!this.isNextSlotAfterMatchSlotEnd(nextSlot, lastPhaseMatchStartTime)) {
                return false;
            }
        }

        const { id: phaseId, type: phaseType } = phases.phases[phases.iterator];

        // Only knockout phase has stages so if phase is not knockout then we already know we can use next slot
        if (phaseType !== PhaseTypeDTO.Knockout) {
            return true;
        }

        const lastKnockoutStageMatchStartTime = this.getPhaseLastMatchOfKnockoutStage(
            matchdayConfiguration.schedule,
            phaseId,
            definedOrThrow(knockoutPhaseStage) * 2, // Higher number means earlier stage
        );

        return this.isNextSlotAfterMatchSlotEnd(nextSlot, lastKnockoutStageMatchStartTime);
    }

    private getAvailableSportsFieldsConfigurationUntilEndTime(
        availableSportsFieldsConfiguration: AvailableSportsFieldsConfiguration,
        endTime: moment.Moment,
    ): AvailableSportsFieldsConfiguration {
        const newConfiguration = new Map(availableSportsFieldsConfiguration);

        newConfiguration.forEach(({ closingTime }, key) => {
            const newClosingTime = closingTime === undefined ? endTime : moment.min(closingTime, endTime);

            newConfiguration.set(key, { closingTime: newClosingTime.clone() });
        });

        return newConfiguration;
    }

    private getUnassignedMatches(matchdayConfiguration: MatchdayConfiguration, matchesToPlan: Match[]): Match[] {
        return matchesToPlan.filter(match => matchdayConfiguration.assignedMatchesIds.every(id => id !== match.id));
    }

    /** Adds breaks to all sports fields so that next match on every sports field would start at the same time */
    private eventUpSportsFieldsEndTimes(matchdayConfiguration: MatchdayConfiguration): EvenUpResult {
        if (matchdayConfiguration.schedule.length === 0) {
            return EvenUpResult.AlreadyEven;
        }

        const sportsFieldNextSlotStartTimes = matchdayConfiguration.schedule.map(slot => ({
            startTime: this.getNextEmptySlotStartTimeOnSportsField(slot),
            sportFieldId: slot.sportsField.id,
        }));

        const maxStartTime = definedOrThrow(
            maxBy(sportsFieldNextSlotStartTimes, ({ startTime }) => startTime),
        ).startTime.clone();

        if (sportsFieldNextSlotStartTimes.every(({ startTime }) => startTime.isSame(maxStartTime))) {
            return EvenUpResult.AlreadyEven;
        }

        sportsFieldNextSlotStartTimes.forEach(({ startTime, sportFieldId }) => {
            if (startTime.isBefore(maxStartTime)) {
                matchdayConfiguration.addBreakToSportsField(sportFieldId, maxStartTime.diff(startTime, "minutes"));
            }
        });

        return EvenUpResult.EvenedUp;
    }

    private getNextEmptySlotStartTimeOnSportsField(plan: SportsFieldSchedule) {
        return getNextEmptySlotStartTimeOnSportsField(plan, this.slotsConfiguration.distanceBetweenMatchesInMinutes);
    }

    private getPhaseLastMatchOfKnockoutStage(schedule: SportsFieldSchedule[], phaseId: string, stage: number) {
        return schedule
            .flatMap(({ slots }) => slots)
            .reduce((accumulator, current) => {
                const { slot, startTime } = current;
                if (
                    isKnockoutPhaseMatch(slot) &&
                    slot.phaseId === phaseId &&
                    slot.stage === stage &&
                    startTime !== undefined &&
                    (accumulator === undefined || startTime.isAfter(accumulator))
                ) {
                    return startTime.clone();
                }

                return accumulator;
            }, undefined);
    }

    /** This function assumes all matches to plan are at the same knockout stage */
    private getKnockoutMatchesToPlanStage(matchesToPlan: KnockoutPhaseMatch[]) {
        return matchesToPlan[0].stage;
    }

    private isNextSlotAfterMatchSlotEnd(nextSlot: EmptySlot, matchSlotStart: moment.Moment | undefined) {
        return (
            matchSlotStart === undefined ||
            nextSlot.startTime.isSameOrAfter(
                matchSlotStart.clone().add(this.slotsConfiguration.distanceBetweenMatchesInMinutes, "minutes"),
            )
        );
    }
}

export default GreedySeasonMatchdayScheduler;

type Phases = {
    phases: { id: string; type: PhaseTypeDTO }[];
    iterator: number;
};

enum EvenUpResult {
    AlreadyEven,
    EvenedUp,
}
