import { DocumentDTO } from "Contracts/RegistrationsClient";
import { observable } from "mobx";

class RegistrationDocument {
    readonly id: string;

    @observable name: string;
    @observable uri: string;
    @observable requiresAcceptance: boolean;

    constructor(id: string, { name, uri, requiresAcceptance }: RegistrationDocumentInit) {
        this.id = id;
        this.name = name;
        this.uri = uri;
        this.requiresAcceptance = requiresAcceptance;
    }

    static fromDTO(dto: DocumentDTO) {
        return new RegistrationDocument(dto.Id, {
            name: dto.Name,
            uri: dto.Uri,
            requiresAcceptance: dto.RequiresAcceptance,
        });
    }
}

export default RegistrationDocument;

type RegistrationDocumentInit = Pick<RegistrationDocument, "name" | "requiresAcceptance" | "uri">;
