import { DatePicker, Form, Skeleton } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import { Select } from "Components/Select";
import { MatchStatusDTO } from "Contracts/PlayooLeagueClient";
import GroupsPhase from "Domain/CompetitionPhases/GroupsPhase/GroupsPhase";
import CompetitionSportsField from "Domain/Competitions/CompetitionSportsField";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import moment from "moment";
import React, { useCallback } from "react";
import { dateWithTimeFormat, timeFormat } from "Utils/formatting";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";
import MatchdayFormItem from "../FormItems/MatchdayFormItem";
import MatchStatusFormItem from "../MatchStatusFormItem";
import MatchTeamsFormItem from "../MatchTeamsFormItem";

export type GroupsPhaseMatchFormDialogProps = {
    initialValues?: Optional<FormFields, "team1Id" | "team2Id">;

    phase: GroupsPhase;
    sportsFields?: CompetitionSportsField[];

    teamsEditionDisabled?: boolean;

    mode: "edit" | "create";

    onClose: () => void;
    onSave: (formFields: FormFields) => Promise<void>;
};

const GroupsPhaseMatchFormDialog: React.FunctionComponent<GroupsPhaseMatchFormDialogProps> = ({
    phase,
    sportsFields,
    mode,
    initialValues,
    teamsEditionDisabled,
    onClose,
    onSave,
}) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const onValuesChange = useCallback(
        (changedValues: Partial<FormFields>) => {
            if (changedValues.groupId !== undefined) {
                form.setFieldsValue({
                    [guard<FormField>("team1Id")]: undefined,
                    [guard<FormField>("team2Id")]: undefined,
                });
            }
        },
        [form],
    );

    const onFinish = useCallback(
        (values: FormFields) => {
            runInTask(() => onSave(values));
        },
        [onSave, runInTask],
    );

    return useObserver(() => (
        <Modal
            title={
                mode === "create"
                    ? l("CompetitionPhases_Groups_Match_Form_Add_Title")
                    : l("CompetitionPhases_Groups_Match_Form_Edit_Title")
            }
            onCancel={onClose}
            okText={mode === "create" ? l("Common_Add") : l("Common_Edit")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}
            okButtonProps={{
                loading: isRunning,
                disabled: isRunning,
            }}>
            {sportsFields ? (
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    onValuesChange={onValuesChange}
                    initialValues={initialValues}>
                    <Form.Item
                        name={guard<FormField>("groupId")}
                        label={l("CompetitionPhases_Groups_Match_Form_Groups")}>
                        <Select
                            showSearch
                            showArrow
                            selectOptions={phase.groups?.map(sf => ({
                                label: sf.name,
                                value: sf.id,
                                key: sf.id,
                            }))}
                            disabled={teamsEditionDisabled}
                        />
                    </Form.Item>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currValues) =>
                            prevValues[guard<FormField>("groupId")] !== currValues[guard<FormField>("groupId")]
                        }>
                        {() => {
                            const groupId = form.getFieldValue(guard<FormField>("groupId"));
                            const teams = phase.groups?.find(g => g.id === groupId)?.teams ?? [];

                            return (
                                <MatchTeamsFormItem
                                    teams={teams}
                                    teamsEditionDisabled={!groupId || teamsEditionDisabled}
                                    team1IdKey={guard<FormField>("team1Id")}
                                    team2IdKey={guard<FormField>("team2Id")}
                                />
                            );
                        }}
                    </Form.Item>
                    <Form.Item
                        name={guard<FormField>("date")}
                        trigger="onSelect"
                        label={l("CompetitionPhases_Groups_Match_Form_Date")}>
                        <DatePicker showTime={{ format: timeFormat }} format={dateWithTimeFormat} />
                    </Form.Item>
                    <Form.Item
                        name={guard<FormField>("sportsFieldId")}
                        label={l("CompetitionPhases_Groups_Match_Form_SportsField")}>
                        <Select
                            showSearch
                            showArrow
                            allowClear
                            selectOptions={sportsFields.map(sf => ({ label: sf.name, value: sf.id, key: sf.id }))}
                        />
                    </Form.Item>
                    <MatchdayFormItem
                        formItemKey={guard<FormField>("matchday")}
                        label={l("CompetitionPhases_Groups_Match_Form_Matchday")}
                    />
                    {mode === "edit" && initialValues && (
                        <MatchStatusFormItem
                            currentStatus={initialValues.status}
                            fieldName={guard<FormField>("status")}
                        />
                    )}
                </Form>
            ) : (
                <Skeleton active loading />
            )}
        </Modal>
    ));
};

export type FormFields = {
    groupId: string;
    team1Id: string;
    team2Id: string;
    date?: moment.Moment | null;
    sportsFieldId?: string;
    matchday: string;
    status: MatchStatusDTO;
};

type FormField = keyof FormFields;

export default GroupsPhaseMatchFormDialog;
