import definedOrThrow from "Utils/empty";

export default function stableSort<T>(arr: T[], comparer: (a: T, b: T) => number) {
    function mergeSort(arr: T[]): T[] {
        if (arr.length < 2) {
            return arr;
        }

        const middle = arr.length / 2;
        const left = arr.slice(0, middle);
        const right = arr.slice(middle, arr.length);

        return merge(mergeSort(left), mergeSort(right));
    }

    function merge(left: T[], right: T[]) {
        const result: T[] = [];

        while (left.length && right.length) {
            if (comparer(left[0], right[0]) <= 0) {
                result.push(definedOrThrow(left.shift()));
            } else {
                result.push(definedOrThrow(right.shift()));
            }
        }

        while (left.length) result.push(definedOrThrow(left.shift()));

        while (right.length) result.push(definedOrThrow(right.shift()));

        return result;
    }

    return mergeSort(arr);
}
