import { Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import { CompetitionPhase } from "Domain/CompetitionPhases/CompetitionPhase";
import { l } from "Languages";
import React, { useCallback } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";

type RenamePhaseFormDialogProps = {
    phase: CompetitionPhase;

    onClose: () => void;
};

const RenamePhaseFormDialog: React.FunctionComponent<RenamePhaseFormDialogProps> = ({ phase, onClose }) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const onFinish = useCallback(
        (values: FormFields) =>
            runInTask(async () => {
                const result = await phase.rename(values.name);

                result
                    .handle(["PhaseNotFound", "failure"], () =>
                        message.error(l("CompetitionPhases_RenamePhase_Failure")),
                    )
                    .handle("NameTooLong", () => {
                        form.setFields([
                            {
                                name: guard<FormField>("name"),
                                errors: [l("Common_Validation_FieldTooLong")],
                                value: values.name,
                            },
                        ]);
                    })
                    .handle("success", () => {
                        message.success(l("CompetitionPhases_RenamePhase_Success"));

                        onClose();
                    })
                    .check();
            }),
        [runInTask, phase, onClose, form],
    );

    return (
        <Modal
            title={l("CompetitionPhases_RenamePhase_Form_Title")}
            onCancel={onClose}
            okText={l("Common_Save")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}
            okButtonProps={{
                disabled: isRunning,
                loading: isRunning,
            }}>
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                initialValues={{
                    [guard<FormField>("name")]: phase.name,
                }}>
                <Form.Item
                    label={l("CompetitionPhases_RenamePhase_Form_Name")}
                    name={guard<FormField>("name")}
                    rules={[{ max: 250, message: l("Common_Validation_FieldTooLong") }]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export type FormFields = {
    name?: string;
};

type FormField = keyof FormFields;

export default RenamePhaseFormDialog;
