import { Tag } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import EmptyState from "Components/EmptyState";
import Application from "Domain/Registrations/Application";
import { l } from "Languages";
import React, { useMemo } from "react";
import { formatDateWithTime } from "Utils/formatting";

type ApplicationsTableProps = {
    applications?: Application[];
};

const ApplicationsTable: React.FunctionComponent<ApplicationsTableProps> = ({ applications }) => {
    const columns = useMemo(
        (): ColumnProps<Application>[] => [
            {
                title: l("Registrations_Applications_SubmittedAt"),
                render: (_, application) => formatDateWithTime(application.submittedAt),
            },
            {
                title: l("Registrations_Applications_TeamName"),
                render: (_, application) => application.teamName,
            },
            {
                title: l("Registrations_Applications_TeamCaptainOrCoachFullName"),
                render: (_, application) => application.teamCaptainOrCoachFullName,
            },
            {
                title: l("Registrations_Applications_PhoneNumber"),
                render: (_, application) => application.phoneNumber,
            },
            {
                title: l("Registrations_Applications_Email"),
                render: (_, application) => application.email,
            },
            {
                title: l("Registrations_Applications_AgeGroups"),
                render: (_, application) => (
                    <>
                        {application.maxAllowedBirthYears.map(y => (
                            <Tag key={y}>{y === undefined ? l("Registrations_AgeGroups_Open") : y.toString()}</Tag>
                        ))}
                    </>
                ),
            },
        ],
        [],
    );

    return (
        <Table
            rowKey={application => application.id}
            columns={columns}
            dataSource={applications}
            pagination={false}
            expandable={{
                expandedRowRender: application => <span>{application.additionalInformation}</span>,
                rowExpandable: application => !!application.additionalInformation,
            }}
            locale={{ emptyText: <EmptyState text={l("Registrations_Applications_EmptyText")} /> }}
        />
    );
};

export default ApplicationsTable;
