import { HistoryCompetitionDTO } from "Contracts/PlayooLeagueClient";
import { observable } from "mobx";

class HistoryCompetition {
    readonly id: string;

    @observable name: string;

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }

    static fromDTO(dto: HistoryCompetitionDTO): HistoryCompetition {
        return new HistoryCompetition(dto.Id, dto.Name);
    }
}

export default HistoryCompetition;
