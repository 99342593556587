import { Button, Form } from "antd";
import { useForm } from "antd/lib/form/util";
import Spacing from "Components/Spacing";
import TinyMceEditor from "Components/TinyMceEditor";
import { l } from "Languages";
import React, { useCallback } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";

export type ArticleEditorValidationErrors = Partial<Record<FormField, string>>;

type ArticleEditorProps = {
    initialValues?: Partial<FormFields>;
    mode: "edit" | "create";

    onCancel: () => void;
    onSave: (fields: FormFields) => Promise<ArticleEditorValidationErrors | undefined>;
};

const MatchArticleEditor: React.FunctionComponent<ArticleEditorProps> = ({ mode, onCancel, onSave, initialValues }) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const onFinish = useCallback(
        (values: FormFields) =>
            runInTask(async () => {
                const validationErrors = await onSave(values);

                for (const field in validationErrors) {
                    form.setFields([
                        {
                            name: field,
                            errors: [validationErrors[field]],
                            value: values[field],
                        },
                    ]);
                }
            }),
        [onSave, form, runInTask],
    );

    return (
        <Form
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}>
            <Form.Item
                name={guard<FormField>("content")}
                label={l("Articles_Editor_Content")}
                rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}
                trigger="onEditorChange">
                <TinyMceEditor />
            </Form.Item>
            <Form.Item>
                <Spacing childrenGutterX>
                    <Button onClick={onCancel}>{l("Common_Cancel")}</Button>
                    <Button type="primary" htmlType="submit" disabled={isRunning} loading={isRunning}>
                        {mode === "create" ? l("Articles_Editor_SaveDraft") : l("Common_Save")}
                    </Button>
                </Spacing>
            </Form.Item>
        </Form>
    );
};

export type FormFields = {
    content: string;
};

type FormField = keyof FormFields;

export default MatchArticleEditor;
