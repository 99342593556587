import * as React from "react";
import logo from "../../../images/logo.svg";

type ViriumLogoProps = {
    className?: string;
};

const ViriumLogo: React.FunctionComponent<ViriumLogoProps> = ({ className }) => {
    return <img className={className} alt="logo" src={logo} />;
};

export default ViriumLogo;
