import { action } from "mobx";
import ReorderStore from "Utils/ReorderStore";
import CustomPhase from "./CustomPhase";
import MatchGroup from "./MatchGroup";

class ReorderMatchGroupsStore extends ReorderStore<MatchGroup> {
    private readonly phase: CustomPhase;

    constructor(phase: CustomPhase) {
        super();

        this.phase = phase;
    }

    @action.bound
    startMatchGroupsReorder() {
        if (!this.phase.groups || this.phase.groups.length <= 1) {
            throw new Error("There is no match groups to be reordered.");
        }

        this.startReorder(this.phase.groups);
    }

    async saveMatchGroupsOrder() {
        if (!this.draft) {
            throw new Error("Store is not in reorder mode.");
        }

        const result = await this.phase.reorderMatchGroups(this.draft.map(g => g.id));

        result.handle("success", () => {
            this.stopReorder();
        });

        return result;
    }
}

export default ReorderMatchGroupsStore;
