import { handleResponse } from "@leancode/validation";
import {
    AddCustomPhaseMatch,
    AddMatchGroupToCustomPhase,
    CompetitionPhaseDetailsDTO,
    CompetitionPhaseDTO,
    DeleteMatchGroupFromCustomPhase,
    MatchStatusDTO,
    PhaseTypeDTO,
    RenameMatchGroupInCustomPhase,
    ReorderMatchGroupsInCustomPhase,
    UpdateCustomPhaseMatch,
} from "Contracts/PlayooLeagueClient";
import { CompetitionTeamStore } from "Domain/Competitions/CompetitionTeamStore";
import MatchResult from "Domain/Matches/MatchResult";
import MatchStore from "Domain/Matches/MatchStore";
import { l } from "Languages";
import _ from "lodash";
import { action, computed, observable, runInAction } from "mobx";
import moment from "moment";
import api from "Services/Api";
import { dateTimeOffsetFromDTOOptional, dateTimeOffsetToDTOOptional } from "Utils/DTO";
import newId from "Utils/newId";
import CompetitionPhaseBase from "../CompetitionPhaseBase";
import ExternalReferencesStore from "../ExternalReferences/ExternalReferencesStore";
import NthBestForPlaceInGroupReference from "../ExternalReferences/NthBestForPlaceInGroupReference";
import PlaceInGroupReference from "../ExternalReferences/PlaceInGroupReference";
import PlaceInTableReference from "../ExternalReferences/PlaceInTableReference";
import CustomPhaseMatch from "./CustomPhaseMatch";
import MatchGroup from "./MatchGroup";

class CustomPhase extends CompetitionPhaseBase<CustomPhaseMatch> {
    @observable groups?: MatchGroup[];

    private constructor(
        id: string,
        competitionId: string,
        dependencies: {
            competitionTeamStore: CompetitionTeamStore;
            matchStore: MatchStore;
            externalReferencesStore: ExternalReferencesStore;
        },
    ) {
        super(id, competitionId, PhaseTypeDTO.Custom, dependencies);
    }

    @computed get displayName() {
        return this.name ?? l("CompetitionPhases_Custom");
    }

    @computed get groupsMap(): Map<string, MatchGroup> | undefined {
        return this.groups ? new Map(this.groups.map(g => [g.id, g])) : undefined;
    }

    static fromDTO(
        dto: CompetitionPhaseDTO | CompetitionPhaseDetailsDTO,
        competitionId: string,
        dependencies: {
            competitionTeamStore: CompetitionTeamStore;
            matchStore: MatchStore;
            externalReferencesStore: ExternalReferencesStore;
        },
    ): CustomPhase {
        return new CustomPhase(dto.Id, competitionId, dependencies).updateFromDTO(dto);
    }

    @action.bound
    updateFromDTO(dto: CompetitionPhaseDTO | CompetitionPhaseDetailsDTO) {
        this.name = dto.Name ?? undefined;
        this.isOngoing = dto.IsOngoing;
        this.linkedPhaseId = dto.LinkedPhaseId ?? undefined;

        if ("Teams" in dto) {
            this.teamsIds = dto.Teams.map(t => t.TeamId);

            if (dto.CustomPhaseDetails) {
                this.groups = dto.CustomPhaseDetails.Groups.map(MatchGroup.fromDTO);

                dto.CustomPhaseDetails.Schedule.forEach(m => {
                    const match = this.matchStore.getById(m.Id);

                    if (match && match.phaseType === PhaseTypeDTO.Custom) {
                        match.updateFromDTO(m);
                    } else {
                        this.matchStore.put(
                            new CustomPhaseMatch(
                                m.Id,
                                this.id,
                                {
                                    matchGroupId: m.LocalGroupId ?? undefined,
                                    name: m.Name ?? undefined,
                                    article: undefined,
                                    date: dateTimeOffsetFromDTOOptional(m.Date),
                                    team1Id: m.Team1Id ?? undefined,
                                    team2Id: m.Team2Id ?? undefined,
                                    status: m.Status,
                                    sportsFieldId: m.SportsFieldId ?? undefined,
                                    result: m.Result ? MatchResult.fromDTO(m.Result) : undefined,
                                },
                                this.competitionTeamStore,
                            ),
                        );
                    }
                });

                this.matchIds =
                    dto.CustomPhaseDetails.Schedule.length > 0
                        ? dto.CustomPhaseDetails.Schedule.map(m => m.Id)
                        : undefined;

                dto.PlaceInGroupReferences.forEach(r =>
                    this.externalReferencesStore.put(PlaceInGroupReference.fromDTO(r, this.id)),
                );

                dto.NthBestForPlaceInGroupReferences.forEach(r =>
                    this.externalReferencesStore.put(NthBestForPlaceInGroupReference.fromDTO(r, this.id)),
                );

                dto.PlaceInTableReferences.forEach(r =>
                    this.externalReferencesStore.put(PlaceInTableReference.fromDTO(r, this.id)),
                );

                this.externalReferencesIds = [
                    ...dto.PlaceInGroupReferences.map(r => r.Id),
                    ...dto.NthBestForPlaceInGroupReferences.map(r => r.Id),
                    ...dto.PlaceInTableReferences.map(r => r.Id),
                ];

                this.defaultMatchDurationInMinutes = dto.DefaultMatchDurationInMinutes ?? undefined;
                this.defaultPauseBetweenMatchesInMinutes = dto.DefaultPauseBetweenMatchesInMinutes ?? undefined;
            }
        }

        return this;
    }

    async addMatch({ name, team1Id, team2Id, date, matchGroupId, sportsFieldId }: AddCustomPhaseMatchData) {
        const response = await api.addCustomPhaseMatch({
            PhaseId: this.id,
            MatchId: newId(),
            Name: name,
            Team1Id: team1Id,
            Team2Id: team2Id,
            LocalGroupId: matchGroupId,
            Date: dateTimeOffsetToDTOOptional(date),
            SportsFieldId: sportsFieldId,
        });

        return handleResponse(response, AddCustomPhaseMatch);
    }

    async editMatch({
        id,
        name,
        team1Id,
        team2Id,
        date,
        matchGroupId,
        sportsFieldId,
        status,
    }: UpdateCustomPhaseMatchData & { id: string }) {
        const match = this.schedule?.find(m => m.id === id);

        if (!match) {
            throw new Error("Cannot edit match. Match not found.");
        }

        if (match.status === MatchStatusDTO.Finished && (match.team1Id !== team1Id || match.team2Id !== team2Id)) {
            throw new Error("Cannot edit teams in match that is not upcoming.");
        }

        const response = await api.updateCustomPhaseMatch({
            MatchId: id,
            Name: name,
            Team1Id: team1Id,
            Team2Id: team2Id,
            Date: dateTimeOffsetToDTOOptional(date),
            LocalGroupId: matchGroupId,
            SportsFieldId: sportsFieldId,
            Status: status,
        });

        handleResponse(response, UpdateCustomPhaseMatch).handle("success", () => {
            match.update({ team1Id, team2Id, name, date, matchGroupId, sportsFieldId, status });
        });

        return handleResponse(response, UpdateCustomPhaseMatch);
    }

    async addMatchGroup(name: string) {
        const groupId = newId();

        const response = await api.addMatchGroupToCustomPhase({
            PhaseId: this.id,
            LocalGroupId: groupId,
            Name: name,
        });

        handleResponse(response, AddMatchGroupToCustomPhase).handle("success", () => {
            runInAction(() => {
                this.groups = [...(this.groups ?? []), new MatchGroup(groupId, name)];
            });
        });

        return handleResponse(response, AddMatchGroupToCustomPhase);
    }

    async deleteMatchGroup(matchGroupId: string) {
        const response = await api.deleteMatchGroupFromCustomPhase({
            PhaseId: this.id,
            LocalGroupId: matchGroupId,
        });

        handleResponse(response, DeleteMatchGroupFromCustomPhase).handle("success", () => {
            runInAction(() => {
                this.groups = this.groups?.filter(g => g.id !== matchGroupId);

                this.schedule?.forEach(m => {
                    if (m.matchGroupId === matchGroupId) {
                        m.setMatchGroup(undefined);
                    }
                });
            });
        });

        return handleResponse(response, DeleteMatchGroupFromCustomPhase);
    }

    async renameMatchGroup(groupId: string, name: string) {
        const group = this.groups?.find(g => g.id === groupId);

        if (!group) {
            throw new Error("Match group to edit name not found.");
        }

        const response = await api.renameMatchGroupInCustomPhase({
            PhaseId: this.id,
            LocalGroupId: groupId,
            Name: name,
        });

        handleResponse(response, RenameMatchGroupInCustomPhase).handle("success", () => {
            group.updateName(name);
        });

        return handleResponse(response, RenameMatchGroupInCustomPhase);
    }

    async reorderMatchGroups(groupIds: string[]) {
        const response = await api.reorderMatchGroupsInCustomPhase({
            PhaseId: this.id,
            LocalGroupIds: groupIds,
        });

        handleResponse(response, ReorderMatchGroupsInCustomPhase).handle("success", () => {
            runInAction(() => {
                this.groups = _.sortBy(this.groups, g => _.findIndex(groupIds, id => g.id === id));
            });
        });

        return handleResponse(response, ReorderMatchGroupsInCustomPhase);
    }
}

export type CustomPhaseMatchData = {
    name?: string;
    team1Id: string;
    team2Id: string;
    date?: moment.Moment;
    matchGroupId?: string;
    sportsFieldId?: string;
};

type AddCustomPhaseMatchData = CustomPhaseMatchData;

type UpdateCustomPhaseMatchData = CustomPhaseMatchData & {
    status: MatchStatusDTO;
};

export default CustomPhase;
