import { TeamGroupDTO } from "Contracts/PlayooLeagueClient";
import { CompetitionTeamStore } from "Domain/Competitions/CompetitionTeamStore";
import { action, computed, observable } from "mobx";
import { notUndefined } from "Utils/predicates";

class TeamGroup {
    private readonly competitionTeamStore: CompetitionTeamStore;
    readonly id: string;

    @observable name: string;
    @observable teamIds: string[];

    constructor(id: string, { name, teamIds }: TeamGroupInit, competitionTeamStore: CompetitionTeamStore) {
        this.id = id;
        this.name = name;
        this.teamIds = teamIds;

        this.competitionTeamStore = competitionTeamStore;
    }

    @computed get teams() {
        return this.teamIds
            ?.map(id => this.competitionTeamStore.getById(id))
            .filter(notUndefined)
            .sort((t1, t2) => t1.displayName.localeCompare(t2.displayName));
    }

    static fromDTO(dto: TeamGroupDTO, competitionTeamStore: CompetitionTeamStore) {
        return new TeamGroup(
            dto.LocalId,
            {
                name: dto.Name,
                teamIds: dto.TeamIds,
            },
            competitionTeamStore,
        );
    }

    toDTO(): TeamGroupDTO {
        return {
            LocalId: this.id,
            Name: this.name,
            TeamIds: [...this.teamIds],
        };
    }

    clone() {
        return new TeamGroup(this.id, { name: this.name, teamIds: [...this.teamIds] }, this.competitionTeamStore);
    }

    @action.bound
    setTeams(teamIds: string[]) {
        this.teamIds = teamIds;
    }

    @action.bound
    setName(name: string) {
        this.name = name;
    }
}

type TeamGroupInit = Pick<TeamGroup, "name" | "teamIds">;

export default TeamGroup;
