import UnauthorizedAppLayout from "Components/AuthenticationLayout";
import NavigationLink from "Components/NavigationLink";
import { l } from "Languages";
import React from "react";
import routes, { PageComponent } from "Router/routes";

const SignUp: PageComponent<typeof routes.signUp> = () => {
    return (
        <UnauthorizedAppLayout>
            <UnauthorizedAppLayout.Header>{l("SignUp_TenantExistence")}</UnauthorizedAppLayout.Header>
            <UnauthorizedAppLayout.Actions>
                <NavigationLink type="button" to={routes.signUp.tenantExists()}>
                    {l("SignUp_TenantExistence_Yes")}
                </NavigationLink>
                <NavigationLink type="button" to={routes.signUp.form()}>
                    {l("SignUp_TenantExistence_No")}
                </NavigationLink>
            </UnauthorizedAppLayout.Actions>
        </UnauthorizedAppLayout>
    );
};

export default SignUp;
