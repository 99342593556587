import { DeleteOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Modal, Typography } from "antd";
import Table, { ColumnProps } from "antd/lib/table";
import DropdownMenu from "Components/DropdownMenu";
import EmptyState from "Components/EmptyState";
import PageContent from "Components/PageContent";
import GroupsPhase from "Domain/CompetitionPhases/GroupsPhase/GroupsPhase";
import TablePhase from "Domain/CompetitionPhases/TablePhase/TablePhase";
import TablePointsModifier from "Domain/CompetitionPhases/TablePointsModifier";
import TablePointsModifierFormDialog, {
    FormFields,
} from "DomainComponents/CompetitionPhases/TablePointsModifierFormDialog";
import { l } from "Languages";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";

type TablePointsModifiersProps = {
    phase: TablePhase | GroupsPhase;
};

const TablePointsModifiers: React.FunctionComponent<TablePointsModifiersProps> = observer(({ phase }) => {
    const [showAddTablePointsModifierDialog, setShowAddTablePointsModifierDialog] = useState(false);

    const deleteTablePointsModifier = useCallback(
        async (modifierId: string) => {
            const handler = await phase.deleteTablePointsModifier(modifierId);

            handler
                .handle(["TablePointsModifierNotFoundInPhase", "failure"], () => {
                    message.error(l("CompetitionPhases_TablePointsModifiers_Delete_Failure"));
                })
                .handle("success", () => {
                    message.success(l("CompetitionPhases_TablePointsModifiers_Delete_Success"));
                })
                .check();
        },
        [phase],
    );

    const showDeleteTablePointsModifierConfirmation = useCallback(
        (modifierId: string) => {
            Modal.confirm({
                onOk: () => deleteTablePointsModifier(modifierId),
                icon: <ExclamationCircleOutlined />,
                title: l("CompetitionPhases_TablePointsModifiers_Delete_Confirmation_Title"),
                content: l("CompetitionPhases_TablePointsModifiers_Delete_Confirmation_Content"),
                okText: l("Common_Remove"),
                okButtonProps: {
                    danger: true,
                },
                cancelText: l("Common_Cancel"),
                centered: true,
                maskClosable: true,
            });
        },
        [deleteTablePointsModifier],
    );

    const columns = useMemo(
        (): ColumnProps<TablePointsModifier>[] => [
            {
                title: l("CompetitionPhases_TablePointsModifiers_Team"),
                render: (_, modifier) => modifier.team?.displayName,
            },
            {
                title: l("CompetitionPhases_TablePointsModifiers_Points"),
                render: (_, modifier) => (modifier.points > 0 ? `+${modifier.points}` : modifier.points),
            },
            {
                title: l("CompetitionPhases_TablePointsModifiers_Description"),
                render: (_, modifier) => modifier.description,
            },
            {
                render: (_, modifier) => (
                    <DropdownMenu
                        menuItems={[
                            {
                                children: (
                                    <>
                                        <DeleteOutlined /> {l("Common_Remove")}
                                    </>
                                ),
                                onClick: () => showDeleteTablePointsModifierConfirmation(modifier.id),
                            },
                        ]}
                    />
                ),
            },
        ],
        [showDeleteTablePointsModifierConfirmation],
    );

    const addTablePointsModifier = useCallback(
        async (formFields: FormFields) => {
            const handler = await phase.addTablePointsModifier(
                formFields.teamId,
                formFields.points,
                formFields.description,
            );

            handler
                .handle(
                    [
                        "DescriptionTooLong",
                        "PhaseNotFoundOrNotATableOrGroupsPhase",
                        "TablePointsModifierIdAlreadyInUse",
                        "TeamIsAPlaceholder",
                        "TeamNotFoundInPhase",
                        "PointsEqualZero",
                        "failure",
                    ],
                    () => {
                        message.error(l("CompetitionPhases_TablePointsModifiers_Add_Failure"));
                    },
                )
                .handle("success", () => {
                    message.success(l("CompetitionPhases_TablePointsModifiers_Add_Success"));

                    setShowAddTablePointsModifierDialog(false);
                })
                .check();
        },
        [phase],
    );

    return (
        <>
            <Typography.Title level={4}>{l("CompetitionPhases_TablePointsModifiers")}</Typography.Title>
            <Table
                rowKey={m => m.id}
                columns={columns}
                dataSource={phase.pointsModifiers}
                pagination={false}
                locale={{
                    emptyText: <EmptyState text={l("CompetitionPhases_TablePointsModifiers_EmptyState")} />,
                }}
            />
            {phase.nonPlaceholderTeams && (
                <PageContent.ActionButton onClick={() => setShowAddTablePointsModifierDialog(true)}>
                    <PlusOutlined /> {l("CompetitionPhases_TablePointsModifiers_Add")}
                </PageContent.ActionButton>
            )}
            {showAddTablePointsModifierDialog && phase.nonPlaceholderTeams && (
                <TablePointsModifierFormDialog
                    nonPlaceholderPhaseTeams={phase.nonPlaceholderTeams}
                    onSave={addTablePointsModifier}
                    onClose={() => setShowAddTablePointsModifierDialog(false)}
                />
            )}
        </>
    );
});

export default TablePointsModifiers;
