import mkCx from "@leancode/cx";
import { Select, Switch } from "antd";
import CustomPhaseTeamsSelection from "Domain/CompetitionPhases/CustomPhase/CustomPhaseTeamsSelection";
import { ExternalReferenceType } from "Domain/CompetitionPhases/ExternalReferences/ExternalReferenceBase";
import NthBestForPlaceInGroupReference from "Domain/CompetitionPhases/ExternalReferences/NthBestForPlaceInGroupReference";
import PlaceInGroupReference from "Domain/CompetitionPhases/ExternalReferences/PlaceInGroupReference";
import GroupsPhaseTeamsSelection from "Domain/CompetitionPhases/GroupsPhase/GroupsPhaseTeamsSelection";
import KnockoutPhaseTeamsSelection from "Domain/CompetitionPhases/KnockoutPhase/KnockoutPhaseTeamsSelection";
import { l } from "Languages";
import { ObservableMap } from "mobx";
import React from "react";
import styles from "./styles.scss";

const cx = mkCx(styles);

const { OptGroup, Option } = Select;

const getTeamsAndReferencesOptions = (
    selection: GroupsPhaseTeamsSelection | CustomPhaseTeamsSelection | KnockoutPhaseTeamsSelection,
    options?: {
        getIdToFilterOut?: () => string;
        onSwitchChange?: (r: { type?: ExternalReferenceType; placeInGroup: number }) => void;
    },
) => {
    const selectedReferenceTypes =
        selection.selectedReferenceTypes instanceof ObservableMap
            ? selection.selectedReferenceTypes
            : selection.selectedReferenceTypes instanceof Array
            ? selection.selectedReferenceTypes.slice()
            : [];

    const teams = options?.getIdToFilterOut
        ? selection.availableTeams.teams.filter(t => t.id !== options.getIdToFilterOut?.())
        : selection.availableTeams.teams;
    const teamsOptGroup = teams.length > 0 && (
        <OptGroup label={l("CompetitionPhases_Groups_AddTeams_Teams")}>
            {teams.map(t => (
                <Option value={t.id} key={t.id}>
                    {t.displayName}
                </Option>
            ))}
        </OptGroup>
    );

    const placeInTableReferences =
        selection.availableReferences?.filter(r => r.placeInTableReference).map(r => r.placeInTableReference) ?? [];
    const availablePlaceInTableReferences = options?.getIdToFilterOut
        ? placeInTableReferences.filter(r => r?.id !== options.getIdToFilterOut?.())
        : placeInTableReferences;
    const placeInTableOptGroup = availablePlaceInTableReferences.length > 0 && (
        <OptGroup label={l("CompetitionPhases_Table_AddTeams_LinkedTable")}>
            {availablePlaceInTableReferences.map(r => (
                <Option value={r?.id ?? ""} key={r?.id}>
                    {r?.displayName ?? ""}
                </Option>
            ))}
        </OptGroup>
    );

    const groupsPhaseOptGroup = selection.availableReferences
        ?.map(r => {
            const typeSelection = selectedReferenceTypes
                ? selectedReferenceTypes instanceof ObservableMap
                    ? selectedReferenceTypes?.get(r.placeInGroup)
                    : selectedReferenceTypes?.[r.placeInGroup - 1]
                : undefined;

            const references: (PlaceInGroupReference | NthBestForPlaceInGroupReference)[] =
                typeSelection?.type === ExternalReferenceType.PlaceInGroup
                    ? r.placeInGroupReferences
                    : r.nthBestForPlaceInGroupReferences;

            return {
                placeInGroup: r.placeInGroup,
                references: options?.getIdToFilterOut
                    ? references.filter(r => r?.id !== options.getIdToFilterOut?.())
                    : references,
                selectionDisabled: !!typeSelection?.selectionDisabled,
                type: typeSelection?.type,
            };
        })
        .filter(r => r.references.length > 0)
        .map(r => (
            <OptGroup
                label={
                    <div className={cx("reference-type-switch")}>
                        {l("CompetitionPhases_Groups_AddTeams_PlaceInGroup", r.placeInGroup)}
                        <Switch
                            checkedChildren={l("CompetitionPhases_Groups_AddTeams_PlaceInGroupReferences")}
                            unCheckedChildren={l("CompetitionPhases_Groups_AddTeams_NthBestForPlaceInGroupReferences")}
                            disabled={r.selectionDisabled}
                            checked={r?.type === ExternalReferenceType.PlaceInGroup}
                            onChange={() => {
                                options?.onSwitchChange?.(r);
                                selection.toggleReferenceType(r.placeInGroup);
                            }}
                        />
                    </div>
                }
                key={r.placeInGroup}>
                {r.references.map(r => (
                    <Option value={r.id} key={r.id}>
                        {r.displayName}
                    </Option>
                ))}
            </OptGroup>
        ));

    return [
        ...(teamsOptGroup ? [teamsOptGroup] : []),
        ...(placeInTableOptGroup ? [placeInTableOptGroup] : []),
        ...(groupsPhaseOptGroup && groupsPhaseOptGroup.length > 0 ? groupsPhaseOptGroup : []),
    ];
};

export default getTeamsAndReferencesOptions;
