import mkCx from "@leancode/cx";
import { Button, message, Skeleton, Typography } from "antd";
import Checkbox from "antd/es/checkbox";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import PageHeader from "antd/lib/page-header";
import PageContent from "Components/PageContent";
import Spacing from "Components/Spacing";
import competitionPhaseStore from "Domain/CompetitionPhases";
import KnockoutPhase from "Domain/CompetitionPhases/KnockoutPhase/KnockoutPhase";
import KnockoutPhaseConfigurator, {
    ValidationErrors,
} from "Domain/CompetitionPhases/KnockoutPhase/KnockoutPhaseConfigurator";
import competitionStore from "Domain/Competitions";
import seasonStore from "Domain/Season";
import KnockoutPhaseFirstStageBoard from "DomainComponents/KnockoutPhaseFirstStageBoard";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import routes, { PageComponent } from "Router/routes";
import useRunInTask from "Utils/Hooks/useRunInTask";
import styles from "./styles.scss";

const cx = mkCx(styles);

const getGenerateScheduleErrorMessage = (validationError: ValidationErrors): string | undefined => {
    switch (validationError) {
        case ValidationErrors.ConfiguredMatchesCountNotAPowerOfTwo:
            return l("CompetitionPhases_Knockout_GenerateSchedule_ConfiguredMatchesCountNotAPowerOfTwo");
        case ValidationErrors.OneOfMatchesMisconfigured:
            return l("CompetitionPhases_Knockout_GenerateSchedule_OneOfMatchesMisconfigured");
    }
};

const ConfigureKnockoutPhaseFirstStagePage: PageComponent<
    typeof routes.competitionPhase.configureKnockoutPhaseFirstStage
> = ({
    match: {
        params: { phaseId },
    },
    history: { push },
    location,
}) => {
    const [phaseConfigurator, setPhaseConfigurator] = useState<KnockoutPhaseConfigurator>();
    const [isScheduleGenerationInProgress, runScheduleGenerationInTask] = useRunInTask();

    const phase = useObserver(() => competitionPhaseStore.getById(phaseId));
    const competition = useObserver(() => phase && competitionStore.getById(phase.competitionId));
    const season = useObserver(() => (competition?.seasonId ? seasonStore.getById(competition.seasonId) : undefined));

    useEffect(() => {
        competitionStore.fetchCompetitionPhaseDetails(phaseId);
    }, [phaseId]);

    const phaseTeams = phase?.teams;

    useEffect(() => {
        if (phase && phaseTeams && phase instanceof KnockoutPhase) {
            setPhaseConfigurator(new KnockoutPhaseConfigurator(phase));
        }
    }, [phaseTeams, phase]);

    const onGenerateSchedule = useCallback(async () => {
        if (phaseConfigurator && competition) {
            const result = await phaseConfigurator.generateSchedule();

            result
                .handle(
                    [
                        "InitialTeamOrderContainsDuplicates",
                        "InitialTeamOrderMissingOrEmpty",
                        "NumberOfTeamsMustBeAPowerOfTwo",
                        "PhaseNotFound",
                        "ScheduleAlreadyGenerated",
                        "InitialTeamOrderIsNotASubsetOfAllTeamsInPhase",
                        "TooFewTeamsForAdditionalNthPlaceTree",
                        "failure",
                    ],
                    () => {
                        message.error(l("CompetitionPhases_Knockout_GenerateSchedule_Failure"));
                    },
                )
                .handle("success", () => {
                    message.success(l("CompetitionPhases_Knockout_GenerateSchedule_Success"));

                    push(routes.competitionPhase({ phaseId: phaseId }));
                })
                .check();
        }
    }, [push, phaseConfigurator, competition, phaseId]);

    const breadcrumb: BreadcrumbProps["routes"] = useMemo(
        () =>
            phase && competition
                ? [
                      {
                          breadcrumbName: season ? season.name : l("Competitions_SeasonSelection_NoSeason"),
                          path: routes.competitions({ seasonId: competition.seasonId }),
                      },
                      {
                          breadcrumbName: competition.name,
                          path: routes.competitionDetails.phases({ competitionId: competition.id }),
                      },
                      {
                          breadcrumbName: phase?.name ?? l("CompetitionPhases_Details_NoTitlePhase"),
                          path: routes.competitionPhase({ phaseId: phase?.id }),
                      },
                      {
                          breadcrumbName: l("CompetitionPhases_Knockout_ScheduleCreation"),
                          path: routes.competitionPhase.configureKnockoutPhaseFirstStage({ phaseId: phase?.id }),
                      },
                  ]
                : [],
        [competition, phase, season],
    );

    const areDetailsFetched = phase?.teams !== undefined && competition?.teams !== undefined;

    return useObserver(() => (
        <PageContent>
            <PageContent.Header>
                <PageContent.HeaderSkeleton active={!areDetailsFetched} loading={!areDetailsFetched}>
                    <PageHeader
                        title={l("CompetitionPhases_Knockout_ScheduleCreation")}
                        onBack={() => push(routes.competitionPhase({ phaseId: phaseId }))}
                        breadcrumb={{
                            routes: breadcrumb,
                            itemRender: ({ path, breadcrumbName }) =>
                                path === location.pathname ? breadcrumbName : <Link to={path}>{breadcrumbName}</Link>,
                        }}
                        extra={
                            phaseConfigurator ? (
                                <Spacing className={cx("header-extra-container")}>
                                    <Spacing childrenGutterX>
                                        <Button onClick={() => push(routes.competitionPhase({ phaseId: phaseId }))}>
                                            {l("Common_Cancel")}
                                        </Button>
                                        {!phase?.schedule && (
                                            <Button
                                                type="primary"
                                                onClick={() => runScheduleGenerationInTask(onGenerateSchedule)}
                                                loading={isScheduleGenerationInProgress}
                                                disabled={
                                                    isScheduleGenerationInProgress ||
                                                    phaseConfigurator.validationError !== undefined
                                                }>
                                                {l("CompetitionPhases_Knockout_GenerateSchedule")}
                                            </Button>
                                        )}
                                    </Spacing>
                                    {phaseConfigurator.validationError !== undefined && (
                                        <Typography.Text type="danger">
                                            {getGenerateScheduleErrorMessage(phaseConfigurator.validationError)}
                                        </Typography.Text>
                                    )}
                                </Spacing>
                            ) : undefined
                        }
                    />
                </PageContent.HeaderSkeleton>
            </PageContent.Header>
            <PageContent.Card>
                <Skeleton active={!areDetailsFetched} loading={!areDetailsFetched}>
                    {phaseConfigurator && (
                        <>
                            <div className={cx("nth-place")}>
                                {phaseConfigurator &&
                                    Array.from(
                                        { length: phaseConfigurator.consecutiveNthPlaceMatches + 1 },
                                        (_, i) => i,
                                    ).map(p =>
                                        p === phaseConfigurator.maxConsecutivePlaceMatches ? null : (
                                            <Checkbox
                                                key={p}
                                                checked={phaseConfigurator.consecutiveNthPlaceMatches !== p}
                                                onChange={e =>
                                                    e.target.checked
                                                        ? phaseConfigurator.incrementNthPlaceMatches()
                                                        : phaseConfigurator.decrementNthPlaceMatches()
                                                }>
                                                {l(
                                                    "CompetitionPhases_Knockout_ScheduleCreation_AddNthPlaceMatch",
                                                    p * 2 + 3,
                                                )}
                                            </Checkbox>
                                        ),
                                    )}
                            </div>
                            <KnockoutPhaseFirstStageBoard
                                matches={phaseConfigurator.matches}
                                unassignedTeams={phaseConfigurator.unassignedTeams}
                                onChange={phaseConfigurator.moveTeam}
                            />
                        </>
                    )}
                </Skeleton>
            </PageContent.Card>
        </PageContent>
    ));
};

export default ConfigureKnockoutPhaseFirstStagePage;
