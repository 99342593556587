declare var app_config: {
    readonly API_HOST: string;
    readonly AUTH_ENDPOINT: string;
    readonly API_ENDPOINT: string;
    readonly CMS_ENDPOINT: string;
    readonly REGISTRATIONS_ENDPOINT: string;
    readonly STORAGE_BASEADDRESS: string;
    readonly DEFAULT_TEAM_LOGO: string;
    readonly TINY_DRIVE_API_KEY: string;
    readonly LANDINGS_BASEADDRESS: string;
};

const config: Configuration = {
    apiHost: app_config.API_HOST,
    authEndpoint: app_config.AUTH_ENDPOINT,
    apiEndpoint: app_config.API_ENDPOINT,
    cmsEndpoint: app_config.CMS_ENDPOINT,
    registrationsEndpoint: app_config.REGISTRATIONS_ENDPOINT,
    storageBaseAddress: app_config.STORAGE_BASEADDRESS,
    defaultTeamLogo: app_config.DEFAULT_TEAM_LOGO,
    tinyDriveApiKey: app_config.TINY_DRIVE_API_KEY,
    landingsBaseAddress: app_config.LANDINGS_BASEADDRESS,
};

export default config;
