import { UserProfileDTO } from "Contracts/PlayooLeagueClient";
import { observable } from "mobx";

class UserProfile {
    readonly id: string;

    @observable firstName: string;
    @observable lastName: string;

    constructor(id: string, firstName: string, lastName: string) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
    }

    static fromDTO(dto: UserProfileDTO): UserProfile {
        return new UserProfile(dto.Id, dto.FirstName, dto.LastName);
    }
}

export default UserProfile;
