import { handleResponse } from "@leancode/validation";
import { MatchStatusDTO, PhaseTypeDTO, UpdateKnockoutPhaseMatch } from "Contracts/PlayooLeagueClient";
import { CompetitionTeamStore } from "Domain/Competitions/CompetitionTeamStore";
import MatchBase, { MatchInit } from "Domain/Matches/MatchBase";
import { computed, observable, runInAction } from "mobx";
import api from "Services/Api";
import { dateTimeOffsetToDTOOptional } from "Utils/DTO";
import MathHelpers from "Utils/MathHelpers";
import { isLegacyNthPlaceNode } from "./formatting";

export type KnockoutMatchNodeData = {
    treeId?: number;
    nodeNumber: number;
};

class KnockoutPhaseMatch extends MatchBase {
    readonly phaseType = PhaseTypeDTO.Knockout;

    @observable nodeData: KnockoutMatchNodeData;

    @computed get isDeletable(): boolean {
        return this.isNthPlaceMatch;
    }

    constructor(id: string, phaseId: string, init: KnockoutPhaseMatchInit, competitionTeamStore: CompetitionTeamStore) {
        super(id, phaseId, init, competitionTeamStore);

        this.nodeData = KnockoutPhaseMatch.decodeNodeNumber(init.nodeId);
    }

    async update({ name, sportsFieldId, date }: UpdateKnockoutPhaseMatchData) {
        const response = await api.updateKnockoutPhaseMatch({
            MatchId: this.id,
            Date: dateTimeOffsetToDTOOptional(date),
            SportsFieldId: sportsFieldId,
            Name: name || undefined,
        });

        handleResponse(response, UpdateKnockoutPhaseMatch).handle("success", () => {
            runInAction(() => {
                this.date = date;
                this.sportsFieldId = sportsFieldId;
                this.name = name;
            });
        });

        return handleResponse(response, UpdateKnockoutPhaseMatch);
    }

    @computed get isAdvancingAsLoser() {
        return this.nodeData.treeId === 0;
    }

    @computed get isNthPlaceMatch() {
        return this.nodeData.treeId !== undefined && this.nodeData.treeId > 0;
    }

    @computed get isTeam1Deleted() {
        if ((this.status === MatchStatusDTO.Canceled || this.status === MatchStatusDTO.Finished) && !this.team1Id) {
            return true;
        }

        return false;
    }

    @computed get isTeam2Deleted() {
        if ((this.status === MatchStatusDTO.Canceled || this.status === MatchStatusDTO.Finished) && !this.team2Id) {
            return true;
        }

        return false;
    }

    @computed get advancingTeam1Placeholder(): AdvancingTeamPlaceholder | undefined {
        if (this.nodeData.treeId === undefined)
            return !this.team1
                ? {
                      advancingFromNode: { treeId: this.nodeData.treeId, nodeNumber: this.nodeData.nodeNumber * 2 },
                      advancingAs: this.isAdvancingAsLoser ? "loser" : "winner",
                  }
                : undefined;

        return undefined;
    }

    @computed get advancingTeam2Placeholder(): AdvancingTeamPlaceholder | undefined {
        if (this.nodeData.treeId === undefined)
            return !this.team2
                ? {
                      advancingFromNode: { treeId: this.nodeData.treeId, nodeNumber: this.nodeData.nodeNumber * 2 + 1 },
                      advancingAs: this.isAdvancingAsLoser ? "loser" : "winner",
                  }
                : undefined;

        return undefined;
    }

    // 1 for final, 2 for semifinal, 4 for quarterfinal etc...
    @computed get stage() {
        return MathHelpers.lowerPowerOfTwo(this.nodeData.nodeNumber);
    }

    static matchForPlaceFromNode(nodeData: KnockoutMatchNodeData) {
        if (nodeData.treeId === undefined) return 1;

        if (isLegacyNthPlaceNode(nodeData)) return this.legacyMatchForPlace(nodeData.nodeNumber);

        return 2 * nodeData.treeId + 3;
    }

    static decodeNodeNumber(nodeNumber: number): KnockoutMatchNodeData {
        if (nodeNumber > 0) {
            return { treeId: undefined, nodeNumber };
        } else {
            nodeNumber = -nodeNumber;

            return { treeId: nodeNumber >> 16, nodeNumber: nodeNumber % 65536 };
        }
    }

    static legacyMatchForPlace(nodeNumber: number) {
        return 2 * nodeNumber + 1;
    }
}

export type AdvancingTeamPlaceholder = {
    advancingFromNode: KnockoutMatchNodeData;
    advancingAs: "loser" | "winner";
};

type KnockoutPhaseMatchInit = MatchInit & { nodeId: number };

export type UpdateKnockoutPhaseMatchData = Pick<KnockoutPhaseMatch, "sportsFieldId" | "date" | "name">;

export default KnockoutPhaseMatch;
