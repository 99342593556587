import { handleResponse } from "@leancode/validation";
import { message } from "antd";
import config from "config";
import {
    AddManagerUser,
    ChangeAccessCode,
    ChangeTenantLandingLogo,
    ChangeTenantLogo,
    ChangeTenantName,
    RemoveManagerUser,
} from "Contracts/PlayooLeagueClient";
import { l } from "Languages";
import { computed, observable, runInAction } from "mobx";
import api from "Services/Api";
import blobStorageClient from "Services/BlobStorageClient";
import extractExtension from "Utils/extractExtension";
import newId from "Utils/newId";
import retryQuery from "Utils/retryQuery";
import ManagerUser from "./ManagerUser";

class TenantSettingsStore {
    @observable name?: string;
    @observable logoUri?: string;
    @observable landingLogoUri?: string;
    @observable accessCode?: string;
    @observable customLandingUri?: string;
    @observable managerUsers?: ManagerUser[];

    @observable isFetched: boolean = false;

    @computed get landingUri() {
        if (this.customLandingUri) {
            return this.customLandingUri;
        }

        if (this.accessCode) {
            return `${config.landingsBaseAddress}/${this.accessCode}`;
        }
    }

    async fetchSettings(includeManagerUsers?: boolean) {
        const managerUsersQuery = includeManagerUsers ? retryQuery(() => api.managerUsers({})) : undefined;

        const [settings, managerUsers] = await Promise.all([
            retryQuery(() => api.tenantSettings({})),
            managerUsersQuery,
        ]);

        runInAction(() => {
            this.name = settings.Name;
            this.logoUri = settings.LogoUri ?? undefined;
            this.landingLogoUri = settings.LandingLogoUri ?? undefined;
            this.accessCode = settings.AccessCode ?? undefined;
            this.customLandingUri = settings.LandingUri ?? undefined;
            this.isFetched = true;

            if (includeManagerUsers) {
                this.managerUsers = managerUsers?.map(ManagerUser.fromDTO) ?? [];
            }
        });
    }

    async addManagerUser(email: string) {
        const response = await api.addManagerUser({
            EmailAddress: email,
        });

        if (response.isSuccess && response.result.WasSuccessful) {
            await this.fetchSettings(true);
        }

        return handleResponse(response, AddManagerUser);
    }

    async changeAccessCode(accessCode?: string) {
        const response = await api.changeAccessCode({
            NewAccessCode: accessCode,
        });

        if (response.isSuccess && response.result.WasSuccessful) {
            this.fetchSettings();
        }

        return handleResponse(response, ChangeAccessCode);
    }

    async updateName(name: string) {
        const response = await api.changeTenantName({
            NewName: name,
        });

        handleResponse(response, ChangeTenantName).handle("success", () => {
            runInAction(() => {
                this.name = name;
            });
        });

        return handleResponse(response, ChangeTenantName);
    }

    async updateLogo(file: File) {
        return this.updateLogoBase(file, async (logoUri, displaySuccessMessage, displayErrorMessage) => {
            const response = await api.changeTenantLogo({
                NewLogoUri: logoUri,
            });

            handleResponse(response, ChangeTenantLogo)
                .handle(["success"], () => {
                    displaySuccessMessage();

                    runInAction(() => {
                        this.logoUri = logoUri;
                    });
                })
                .handle(["NewLogoUriTooLong", "NewLogoUriNotAbsolute", "NewLogoUriUnacceptableScheme", "failure"], () =>
                    displayErrorMessage(),
                )
                .check();
        });
    }

    async updateLandingLogo(file: File) {
        return this.updateLogoBase(file, async (logoUri, displaySuccessMessage, displayErrorMessage) => {
            const response = await api.changeTenantLandingLogo({
                NewLogoUri: logoUri,
            });

            handleResponse(response, ChangeTenantLandingLogo)
                .handle(["success"], () => {
                    displaySuccessMessage();

                    runInAction(() => {
                        this.landingLogoUri = logoUri;
                    });
                })
                .handle(["NewLogoUriTooLong", "NewLogoUriNotAbsolute", "NewLogoUriUnacceptableScheme", "failure"], () =>
                    displayErrorMessage(),
                )
                .check();
        });
    }

    async removeManagerUser(userId: string) {
        const response = await api.removeManagerUser({
            UserId: userId,
        });

        return handleResponse(response, RemoveManagerUser)
            .handle(["CannotRemoveSelf"], () => message.error(l("Tenant_ManagerUsers_Remove_CannotRemoveSelf")))
            .handle(["ManagerUserNotFound", "failure"], () => message.error(l("Tenant_ManagerUsers_Remove_Failure")))
            .handle("success", () => {
                runInAction(() => {
                    this.managerUsers = this.managerUsers?.filter(({ id }) => id !== userId);
                });

                message.success(l("Tenant_ManagerUsers_Remove_Success"));
            })
            .check();
    }

    private async updateLogoBase<T>(
        file: File,
        updater: (logoUri: string, displaySuccessMessage: () => void, displayErrorMessage: () => void) => T,
    ) {
        const extension = extractExtension(file);
        const blobName = `${newId()}.${extension}`;

        const messageKey = "tenant-logo-update";
        message.loading({
            content: l("Tenant_UpdateLogo_InProgress"),
            duration: 0,
            key: messageKey,
        });

        const displayErrorMessage = () =>
            message.error({
                content: l("Tenant_UpdateLogo_Failure"),
                key: messageKey,
            });

        const displaySuccessMessage = () =>
            message.success({
                content: l("Tenant_UpdateLogo_Success"),
                key: messageKey,
            });

        try {
            const token = await retryQuery(() => api.tenantLogosCreateToken({}));

            await blobStorageClient.uploadBlob(blobName, file, token);

            const logoUri = blobStorageClient.blobUriFromToken(token, blobName);

            return updater(logoUri, displaySuccessMessage, displayErrorMessage);
        } catch (e) {
            displayErrorMessage();
        }
    }
}

export default TenantSettingsStore;
