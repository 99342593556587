import { handleResponse } from "@leancode/validation";
import { Button, Checkbox, Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/util";
import UnauthorizedAppLayout from "Components/AuthenticationLayout";
import NavigationLink from "Components/NavigationLink";
import { RegisterTenant } from "Contracts/PlayooLeagueClient";
import Regulations from "Domain/Regulations/Regulations";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import routes, { PageComponent } from "Router/routes";
import api from "Services/Api";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";
import { emailRegex } from "Utils/validateEmail";
import { l } from "../../../Languages";

const SignUpForm: PageComponent<typeof routes.signUp.form> = () => {
    const [isRunning, runInTask] = useRunInTask();
    const [form] = useForm();
    const { push } = useHistory();

    const onFinish = useCallback(
        (values: FormFields) =>
            runInTask(async () => {
                const response = await api.registerTenant({
                    TenantName: values.tenantName,
                    EmailAddress: values.email,
                });

                handleResponse(response, RegisterTenant)
                    .handle(["NameTooLong", "NameMissingOrEmpty"], error =>
                        form.setFields([
                            {
                                name: guard<FormField>("tenantName"),
                                value: values.tenantName,
                                errors: [
                                    error === "NameTooLong"
                                        ? l("SignUp_Form_TenantNameTooLong")
                                        : l("Common_Validation_FieldRequired"),
                                ],
                            },
                        ]),
                    )
                    .handle(["EmailAddressMissingOrEmpty", "EmailAddressNotValid", "EmailAddressTooLong"], error =>
                        form.setFields([
                            {
                                name: guard<FormField>("email"),
                                value: values.tenantName,
                                errors: [
                                    error === "EmailAddressMissingOrEmpty"
                                        ? l("Common_Validation_FieldRequired")
                                        : error === "EmailAddressTooLong"
                                        ? l("Common_Validation_FieldTooLong")
                                        : l("Common_Validation_InvalidEmail"),
                                ],
                            },
                        ]),
                    )
                    .handle("failure", () => message.error(l("SignUp_Form_RegistrationFailed")))
                    .handle("success", () => {
                        push(routes.signUp.success());
                    })
                    .check();
            }),
        [form, runInTask, push],
    );

    return (
        <UnauthorizedAppLayout>
            <UnauthorizedAppLayout.Header>{l("SignUp_Form_Title")}</UnauthorizedAppLayout.Header>
            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                initialValues={{
                    [guard<FormField>("areRegulationsAccepted")]: false,
                }}>
                <Form.Item
                    name={guard<FormField>("tenantName")}
                    label={l("SignUp_Form_TenantName")}
                    rules={[
                        { required: true, message: l("Common_Validation_FieldRequired") },
                        { max: 20, message: l("SignUp_Form_TenantNameTooLong") },
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name={guard<FormField>("email")}
                    label={l("SignUp_Form_Email")}
                    rules={[
                        { required: true, message: l("Common_Validation_FieldRequired") },
                        {
                            pattern: emailRegex,
                            message: l("Common_Validation_InvalidEmail"),
                        },
                        {
                            max: 250,
                            message: l("Common_Validation_FieldTooLong"),
                        },
                    ]}
                    validateTrigger="onBlur">
                    <Input />
                </Form.Item>
                <Form.Item
                    name={guard<FormField>("areRegulationsAccepted")}
                    rules={[
                        {
                            required: true,
                            transform: value => value || undefined,
                            type: "boolean",
                            message: l("SignUp_Form_Regulations_ValidationError"),
                        },
                    ]}
                    valuePropName="checked">
                    <Checkbox>
                        {l("SignUp_Form_Regulations_Part1")}
                        <NavigationLink external to={Regulations.regulationsLink()}>
                            {l("SignUp_Form_Regulations_Part2")}
                        </NavigationLink>
                        {l("SignUp_Form_Regulations_Part3")}
                        <NavigationLink external to={Regulations.privacyPolicyLink()}>
                            {l("SignUp_Form_Regulations_Part4")}
                        </NavigationLink>
                    </Checkbox>
                </Form.Item>
                <UnauthorizedAppLayout.Actions>
                    <Button htmlType="submit" type="primary" loading={isRunning} disabled={isRunning}>
                        {l("SignUp_Form_Submit")}
                    </Button>
                    <NavigationLink type="button" to={routes.index()}>
                        {l("Common_Cancel")}
                    </NavigationLink>
                </UnauthorizedAppLayout.Actions>
            </Form>
        </UnauthorizedAppLayout>
    );
};

type FormFields = {
    tenantName: string;
    email: string;
    areRegulationsAccepted: boolean;
};

type FormField = keyof FormFields;

export default SignUpForm;
