import { handleResponse } from "@leancode/validation";
import { message } from "antd";
import { ReorderCompetitionGroups, ReorderCompetitions } from "Contracts/PlayooLeagueClient";
import CompetitionGroup from "Domain/CompetitionGroups/CompetitionGroup";
import { l } from "Languages";
import { action } from "mobx";
import api from "Services/Api";
import ReorderStore from "Utils/ReorderStore";
import CompetitionGroupStore from "./CompetitionGroupStore";

class ReorderCompetitionGroupStore extends ReorderStore<CompetitionGroup> {
    private readonly competitionGroupStore: CompetitionGroupStore;

    constructor(competitionGroupStore: CompetitionGroupStore) {
        super();

        this.competitionGroupStore = competitionGroupStore;
    }

    @action.bound
    startCompetitionGroupsReorder() {
        if (this.competitionGroupStore.items.length <= 1) {
            throw new Error("There is no competition groups to be reordered.");
        }

        this.startReorder(this.competitionGroupStore.items);
    }

    async saveCompetitionGroupsOrder() {
        if (!this.draft) {
            throw new Error("Store is not in reorder mode.");
        }

        const saveOrderMessageKey = "competition-groups-order-save-in-progress";
        message.loading({ content: l("CompetitionGroups_Reorder_Loading"), duration: 0, key: saveOrderMessageKey });

        const response = await api.reorderCompetitionGroups({
            OrderedGroupIds: this.draft.map(c => c.id),
        });

        if (response.isSuccess && response.result.WasSuccessful) {
            await this.competitionGroupStore.fetchCompetitionGroups();
        }

        handleResponse(response, ReorderCompetitionGroups)
            .handle("success", () => {
                this.stopReorder();

                message.success({
                    content: l("CompetitionGroups_Reorder_Success"),
                    key: saveOrderMessageKey,
                });
            })
            .handle(["DuplicateGroupId", "GroupNotFound", "OrderedGroupIdsMissingOrEmpty", "failure"], () => {
                message.error({
                    content: l("CompetitionGroups_Reorder_Failure"),
                    key: saveOrderMessageKey,
                });
            })
            .check();

        return handleResponse(response, ReorderCompetitions);
    }
}

export default ReorderCompetitionGroupStore;
