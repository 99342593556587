import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Table, Typography } from "antd";
import { ColumnProps } from "antd/lib/table";
import PageContent from "Components/PageContent";
import Spacing from "Components/Spacing";
import tenantSettingsStore from "Domain/Tenant";
import ManagerUser from "Domain/Tenant/ManagerUser";
import { l } from "Languages";
import React, { useMemo, useState } from "react";
import AddManagerUserFormDialog from "./AddManagerUserFormDialog";
import DropdownMenu from "Components/DropdownMenu";
import { observer } from "mobx-react-lite";

const ManagerUsersTable: React.FunctionComponent = observer(() => {
    const [isAddManagerUserFormDialogOpen, setIsAddManagerUserFormDialogOpen] = useState(false);

    const columns = useMemo(
        (): ColumnProps<ManagerUser>[] => [
            {
                title: l("Common_Ordinal"),
                render: (_, user, ind) => ind + 1,
            },
            {
                title: l("Tenant_ManagerUsers_Email"),
                render: (_, user) => user.email,
            },
            {
                render: (_, { id }) => (
                    <DropdownMenu
                        menuItems={[
                            {
                                children: l("Tenant_ManagerUsers_Remove"),
                                onClick: () =>
                                    Modal.confirm({
                                        onOk: () => tenantSettingsStore.removeManagerUser(id),
                                        icon: <ExclamationCircleOutlined />,
                                        title: l("Tenant_ManagerUsers_Remove_Confirmation_Title"),
                                        content: l("Tenant_ManagerUsers_Remove_Confirmation_Content"),
                                        okText: l("Common_Remove"),
                                        okButtonProps: {
                                            danger: true,
                                        },
                                        cancelText: l("Common_Cancel"),
                                        centered: true,
                                        maskClosable: true,
                                    }),
                            },
                        ]}
                    />
                ),
            },
        ],
        [],
    );

    return (
        <div>
            <Typography.Title level={4}>{l("Tenant_ManagerUsers")}</Typography.Title>
            <Spacing childrenGutterY>
                <Table columns={columns} dataSource={tenantSettingsStore.managerUsers} pagination={false} />
                <PageContent.ActionButton onClick={() => setIsAddManagerUserFormDialogOpen(true)}>
                    <PlusOutlined /> {l("Tenant_ManagerUsers_Add")}
                </PageContent.ActionButton>
            </Spacing>
            {isAddManagerUserFormDialogOpen && (
                <AddManagerUserFormDialog onClose={() => setIsAddManagerUserFormDialogOpen(false)} />
            )}
        </div>
    );
});

export default ManagerUsersTable;
