import pathToRegexp from "path-to-regexp";

export class PathPattern<
    TRequiredParams extends string = never,
    TOptionalParams extends string = never,
    TRequiredQuery extends string = never,
    TOptionalQuery extends string = never
> {
    public _: [TRequiredParams, TOptionalParams, TRequiredQuery, TOptionalQuery];
    value: string;

    constructor(pattern: string) {
        this.value = pattern;
    }
}

interface RouteDescription<
    TRequiredParams extends string = never,
    TOptionalParams extends string = never,
    TRequiredQuery extends string = never,
    TOptionalQuery extends string = never,
    TChildren extends Record<string, RouteDescription<string, string, string, string, any>> = {}
> {
    pattern: PathPattern<TRequiredParams, TOptionalParams, TRequiredQuery, TOptionalQuery>;
    children?: TChildren;
}

type MapToRoute<TRouteDescription extends RouteDescription<string, string, string, string, any>> = {
    (
        ...params: PathPatternArgs<
            ParamsOf<TRouteDescription>[0],
            ParamsOf<TRouteDescription>[1],
            ParamsOf<TRouteDescription>[2],
            ParamsOf<TRouteDescription>[3]
        >
    ): string;
    pattern: string;
} & {
    [TKey in keyof ChildrenOf<TRouteDescription>]: MapToRoute<
        AddParams<ChildrenOf<TRouteDescription>[TKey], TRouteDescription>
    >;
};

type PathPatternArgs<
    TRequiredParams extends string = never,
    TOptionalParams extends string = never,
    TRequiredQuery extends string = never,
    TOptionalQuery extends string = never
> = [TRequiredParams] extends [never]
    ? [TRequiredQuery] extends [never]
        ? [Partial<Record<TOptionalParams, string>>?, Partial<Record<TOptionalQuery, string>>?]
        : [
              Partial<Record<TOptionalParams, string>>,
              Record<TRequiredQuery, string> & Partial<Record<TOptionalQuery, string>>,
          ]
    : [TRequiredQuery] extends [never]
    ? [
          Record<TRequiredParams, string> & Partial<Record<TOptionalParams, string>>,
          Partial<Record<TOptionalQuery, string>>?,
      ]
    : [
          Record<TRequiredParams, string> & Partial<Record<TOptionalParams, string>>,
          Record<TRequiredQuery, string> & Partial<Record<TOptionalQuery, string>>,
      ];

type ParamsOf<TRD extends RouteDescription<string, string, string, string, any>> = TRD["pattern"]["_"];

type ChildrenOf<TRD extends RouteDescription<string, string, string, string, any>> = TRD extends RouteDescription<
    string,
    string,
    string,
    string,
    infer C
>
    ? C
    : never;

type AddParams<
    TSourceRouteDescription extends RouteDescription<string, string, string, string, any>,
    TDestRouteDescription extends RouteDescription<string, string, string, string, any>
> = RouteDescription<
    ParamsOf<TSourceRouteDescription>[0] | ParamsOf<TDestRouteDescription>[0],
    ParamsOf<TSourceRouteDescription>[1] | ParamsOf<TDestRouteDescription>[1],
    ParamsOf<TSourceRouteDescription>[2] | ParamsOf<TDestRouteDescription>[2],
    ParamsOf<TSourceRouteDescription>[3] | ParamsOf<TDestRouteDescription>[3],
    ChildrenOf<TSourceRouteDescription>
>;

export default function createRouting<
    TRoutes extends Record<string, RouteDescription<string, string, string, string, any>>
>(routes: TRoutes): { [TKey in keyof TRoutes]: MapToRoute<TRoutes[TKey]> } {
    function createRoute(currentPattern: string, currentRoute: RouteDescription<string, string, string, string, any>) {
        const pattern = currentPattern + currentRoute.pattern.value;
        const compiledPattern = pathToRegexp.compile(pattern);

        function route(params: Record<string, string> | undefined, queryParams: Record<string, string> | undefined) {
            const queryString = queryParams ? "?" + new URLSearchParams(queryParams).toString() : "";
            return compiledPattern(params) + queryString;
        }

        route.pattern = pattern;

        if (currentRoute.children) {
            for (const childRoute in currentRoute.children) {
                route[childRoute] = createRoute(pattern, currentRoute.children[childRoute]);
            }
        }

        return route;
    }

    const mappedRoutes: Record<keyof TRoutes, any> = {} as any;

    for (const route in routes) {
        mappedRoutes[route] = createRoute("", routes[route]);
    }

    return mappedRoutes;
}

type SegmentRequiredParam<TParam extends string> = {
    name: TParam;
    type: "required-param";
    segment: string;
};

type SegmentOptionalParam<TParam extends string> = {
    name: TParam;
    type: "optional-param";
    segment: string;
};

type SegmentRequiredQuery<TParam extends string> = {
    name: TParam;
    type: "required-query";
};

type SegmentOptionalQuery<TParam extends string> = {
    name: TParam;
    type: "optional-query";
};

export function segment<
    TRequiredParam extends string = never,
    TOptionalParam extends string = never,
    TRequiredQuery extends string = never,
    TOptionalQuery extends string = never
>(
    literals: TemplateStringsArray,
    ...placeholders: (
        | SegmentRequiredParam<TRequiredParam>
        | SegmentOptionalParam<TOptionalParam>
        | SegmentRequiredQuery<TRequiredQuery>
        | SegmentOptionalQuery<TOptionalQuery>
        | Array<
              | SegmentRequiredParam<TRequiredParam>
              | SegmentOptionalParam<TOptionalParam>
              | SegmentRequiredQuery<TRequiredQuery>
              | SegmentOptionalQuery<TOptionalQuery>
          >
    )[]
) {
    let result = "";

    for (let i = 0; i < placeholders.length; i++) {
        result += literals[i];
        const placeholder = placeholders[i];
        if ("segment" in placeholder) {
            result += placeholder.segment;
        }
    }

    result += literals[literals.length - 1];

    return {
        pattern: new PathPattern<TRequiredParam, TOptionalParam, TRequiredQuery, TOptionalQuery>(result),
    };
}

export function query<Q extends Record<string, boolean>>(
    params: Q,
): Array<
    | SegmentRequiredQuery<GetKeysAssignableTo<true, Q> & string>
    | SegmentOptionalQuery<GetKeysAssignableTo<false, Q> & string>
> {
    return Object.keys(params).map(name => ({ name, type: params[name] ? "required-query" : "optional-query" })) as any;
}

type GetKeysAssignableTo<TValue, T> = { [K in keyof T]: T[K] extends TValue ? K : never }[keyof T];

export function arg<TParam extends string, TOptional extends "?" | "" = "">(
    name: TParam,
    optionality?: TOptional,
    pattern?: string,
): TOptional extends "?" ? SegmentOptionalParam<TParam> : SegmentRequiredParam<TParam> {
    const patternPart = pattern ? `(${pattern})` : "";
    const requirementPart = optionality ?? "";

    const segment: SegmentRequiredParam<any> | SegmentOptionalParam<any> = {
        name: name,
        type: optionality === "?" ? "optional-param" : "required-param",
        segment: `:${name}${patternPart}${requirementPart}`,
    };

    return segment as any;
}

export function guid<TParam extends string, TOptional extends "?" | "" = "">(
    name: TParam,
    optionality?: TOptional,
): TOptional extends "?" ? SegmentOptionalParam<TParam> : SegmentRequiredParam<TParam> {
    const uuid = "[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}";

    return arg(name, optionality, uuid);
}
