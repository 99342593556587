import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    PlusOutlined,
    RollbackOutlined,
} from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Button, Modal, Skeleton, Table, Tag, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";
import DropdownMenu from "Components/DropdownMenu";
import EmptyState from "Components/EmptyState";
import NavigationLink from "Components/NavigationLink";
import PageContent from "Components/PageContent";
import TextSearchFilter from "Components/TextSearchFilter";
import { ArticleStateDTO } from "Contracts/PlayooLeagueClient";
import articlesStore from "Domain/Articles";
import Article from "Domain/Articles/Article";
import tenantSettingsStore from "Domain/Tenant";
import { l } from "Languages";
import { Observer, useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo } from "react";
import routes, { PageComponent } from "Router/routes";
import { compareDates } from "Utils/comparators";
import { formatDateWithTime } from "Utils/formatting";
import useRunInTask from "Utils/Hooks/useRunInTask";
import styles from "./styles.scss";

const cx = mkCx(styles);

const ArticlesPage: PageComponent<typeof routes.articles> = ({ history: { push } }) => {
    const [isRunning, runInTask] = useRunInTask();

    useEffect(() => {
        if (!tenantSettingsStore.isFetched) {
            tenantSettingsStore.fetchSettings();
        }
    }, []);

    const showRemoveArticleConfirmation = useCallback((articleId: string) => {
        Modal.confirm({
            onOk: () => articlesStore.deleteArticle(articleId),
            icon: <ExclamationCircleOutlined />,
            title: l("Articles_Delete_Confirmation_Title"),
            content: l("Articles_Delete_Confirmation_Content"),
            okText: l("Common_Remove"),
            okButtonProps: {
                danger: true,
            },
            cancelText: l("Common_Cancel"),
            centered: true,
            maskClosable: true,
        });
    }, []);

    const showPublishArticleConfirmation = useCallback((article: Article) => {
        Modal.confirm({
            onOk: () => article.publish(),
            icon: <ExclamationCircleOutlined />,
            title: l("Articles_Publish_Confirmation_Title"),
            content: l("Articles_Publish_Confirmation_Content"),
            okText: l("Articles_Publish"),
            cancelText: l("Common_Cancel"),
            centered: true,
            maskClosable: true,
        });
    }, []);

    const showUnpublishArticleConfirmation = useCallback((article: Article) => {
        Modal.confirm({
            onOk: () => article.unpublish(),
            icon: <ExclamationCircleOutlined />,
            title: l("Articles_Unpublish_Confirmation_Title"),
            content: l("Articles_Unpublish_Confirmation_Content"),
            okText: l("Articles_Unpublish"),
            cancelText: l("Common_Cancel"),
            centered: true,
            maskClosable: true,
        });
    }, []);

    const columns = useMemo(
        (): ColumnProps<Article>[] => [
            {
                title: l("Articles_Title"),
                render: (_, article) => (
                    <NavigationLink to={routes.articles.edit({ articleId: article.id })}>
                        {article.title}
                    </NavigationLink>
                ),
                sorter: (a, b) => a.title.localeCompare(b.title),
                onFilter: (value: string, article) => article.title.toLowerCase().includes(value.toLowerCase()),
                filterDropdown: props => <TextSearchFilter {...props} placeholder={l("Articles_Title")} />,
            },
            {
                title: l("Articles_DatePublished"),
                render: (_, article) => (
                    <Observer>
                        {() =>
                            article.state === ArticleStateDTO.Draft ? (
                                <Button type="link" onClick={() => showPublishArticleConfirmation(article)}>
                                    {l("Articles_Publish")}
                                </Button>
                            ) : (
                                <span>{article.datePublished && formatDateWithTime(article.datePublished)}</span>
                            )
                        }
                    </Observer>
                ),
                sorter: (a, b) => compareDates(a.datePublished, b.datePublished),
            },
            {
                title: l("Articles_DateModified"),
                render: (_, article) => (
                    <Observer>{() => <span>{formatDateWithTime(article.dateModified)}</span>}</Observer>
                ),
                sorter: (a, b) => compareDates(a.dateModified, b.dateModified),
            },
            {
                title: l("Articles_State"),
                render: (_, article) => (
                    <Observer>
                        {() => (
                            <span>
                                {article.state === ArticleStateDTO.Draft
                                    ? l("Articles_State_Draft")
                                    : l("Articles_State_Published")}
                            </span>
                        )}
                    </Observer>
                ),
                filters: [
                    {
                        text: l("Articles_State_Published"),
                        value: ArticleStateDTO.Published,
                    },
                    {
                        text: l("Articles_State_Draft"),
                        value: ArticleStateDTO.Draft,
                    },
                ],
                sorter: (a, b) => (a.state === b.state ? 0 : a.state === ArticleStateDTO.Published ? 1 : -1),
                onFilter: (value: ArticleStateDTO, record) => value === record.state,
            },
            {
                title: l("Articles_Tags"),
                render: (_, article) => (
                    <Observer>
                        {() => (
                            <>
                                {article.tags.map(t => (
                                    <Tag key={t} className={cx("article-tag")}>
                                        {t}
                                    </Tag>
                                ))}
                            </>
                        )}
                    </Observer>
                ),
                onFilter: (value: string, article) =>
                    article.tags.some(t => t.toLowerCase().includes(value.toLowerCase())),
                filterDropdown: props => <TextSearchFilter {...props} placeholder={l("Articles_Tags")} />,
            },
            {
                title: l("Common_Actions"),
                render: (_, article) => (
                    <Observer>
                        {() => (
                            <DropdownMenu
                                menuItems={[
                                    ...(tenantSettingsStore.isFetched
                                        ? [
                                              {
                                                  children: tenantSettingsStore.landingUri ? (
                                                      <>
                                                          <EyeOutlined /> {l("Articles_Preview")}
                                                      </>
                                                  ) : (
                                                      <Tooltip title={l("Articles_Preview_NotAvailable")}>
                                                          <span>
                                                              <EyeOutlined /> {l("Articles_Preview")}
                                                          </span>
                                                      </Tooltip>
                                                  ),
                                                  onClick: () => window.open(article.previewLink),
                                                  disabled: !tenantSettingsStore.landingUri,
                                              },
                                          ]
                                        : []),
                                    ...[
                                        {
                                            children: (
                                                <>
                                                    <EditOutlined /> {l("Articles_Edit")}
                                                </>
                                            ),
                                            onClick: () => push(routes.articles.edit({ articleId: article.id })),
                                        },
                                        {
                                            children: (
                                                <>
                                                    <DeleteOutlined /> {l("Articles_Delete")}
                                                </>
                                            ),
                                            onClick: () => showRemoveArticleConfirmation(article.id),
                                        },
                                    ],
                                    ...(article.state === ArticleStateDTO.Published
                                        ? [
                                              {
                                                  children: (
                                                      <>
                                                          <RollbackOutlined /> {l("Articles_Unpublish")}
                                                      </>
                                                  ),
                                                  onClick: () => showUnpublishArticleConfirmation(article),
                                              },
                                          ]
                                        : []),
                                ]}
                            />
                        )}
                    </Observer>
                ),
            },
        ],
        [showRemoveArticleConfirmation, showPublishArticleConfirmation, showUnpublishArticleConfirmation, push],
    );

    useEffect(() => {
        runInTask(() => articlesStore.fetchArticles());
    }, [runInTask]);

    return useObserver(() => (
        <PageContent>
            <PageContent.Card title={l("Articles")}>
                <Skeleton loading={isRunning} active={isRunning}>
                    <Table
                        columns={columns}
                        dataSource={articlesStore.articles}
                        locale={{ emptyText: <EmptyState text={l("Articles_EmptyState")} /> }}
                    />
                    <PageContent.Link to={routes.articles.create()}>
                        <PlusOutlined /> {l("Articles_AddNew")}
                    </PageContent.Link>
                </Skeleton>
            </PageContent.Card>
        </PageContent>
    ));
};

export default ArticlesPage;
