import { handleResponse } from "@leancode/validation";
import { message } from "antd";
import {
    AddPlaceholderTeamForPhase,
    AddTablePointsModifier,
    AddTeamsToPhase,
    AdvanceTeamsForPhaseRelatedReferences,
    CompetitionPhaseDetailsDTO,
    CompetitionPhaseDTO,
    DeleteMatch,
    DeletePhaseMatchSchedule,
    DeleteTablePointsModifier,
    PhaseTypeDTO,
    RemoveTeamFromPhase,
    RenamePhase,
    RenamePlaceholderTeamForPhase,
    ReplacePlaceholderTeamForPhase,
    RevokeTeamAdvancing,
    SetPhaseTieBreakingOrder,
    SwapTeamsInPhase,
} from "Contracts/PlayooLeagueClient";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import { CompetitionTeamStore } from "Domain/Competitions/CompetitionTeamStore";
import MatchBase from "Domain/Matches/MatchBase";
import MatchStore from "Domain/Matches/MatchStore";
import { l } from "Languages";
import { action, computed, observable, runInAction } from "mobx";
import api from "Services/Api";
import newId from "Utils/newId";
import { notUndefined } from "Utils/predicates";
import retryQuery from "Utils/retryQuery";
import { ExternalReference } from "./ExternalReferences/ExternalReference";
import ExternalReferencesStore from "./ExternalReferences/ExternalReferencesStore";
import NthBestForPlaceInGroupReference from "./ExternalReferences/NthBestForPlaceInGroupReference";
import PlaceInGroupReference from "./ExternalReferences/PlaceInGroupReference";
import PlaceInTableReference from "./ExternalReferences/PlaceInTableReference";
import TablePointsModifier from "./TablePointsModifier";
import TieBreakingOrderTeam from "./TieBreakingOrderTeam";

abstract class CompetitionPhaseBase<TMatch extends MatchBase> {
    readonly competitionTeamStore: CompetitionTeamStore;
    readonly matchStore: MatchStore;
    readonly externalReferencesStore: ExternalReferencesStore;

    readonly id: string;
    readonly competitionId: string;
    readonly type: PhaseTypeDTO;

    @observable competitionName?: string;

    @observable linkedPhaseId?: string;

    @observable name?: string;
    abstract get displayName(): string;
    @observable isOngoing: boolean;

    @observable teamsIds?: string[];
    @observable matchIds?: string[];

    @observable teamsTieBreakingOrder?: TieBreakingOrderTeam[];

    @observable defaultMatchDurationInMinutes?: number;
    @observable defaultPauseBetweenMatchesInMinutes?: number;

    @observable pointsModifiers?: TablePointsModifier[];

    @observable externalReferencesIds?: string[];

    @computed get schedule(): TMatch[] | undefined {
        return this.matchIds?.map(id => this.matchStore.getById(id)).filter(notUndefined) as TMatch[] | undefined;
    }

    @computed get teams() {
        return this.teamsIds
            ?.map(id => this.competitionTeamStore.getById(id))
            .filter(notUndefined)
            .sort((t1, t2) => t1.displayName.localeCompare(t2.displayName));
    }

    @computed get externalReferences() {
        return this.externalReferencesIds?.map(id => this.externalReferencesStore.getById(id)).filter(notUndefined);
    }

    @computed get nonPlaceholderTeams() {
        return this.teams?.filter(t => !t.isPlaceholder);
    }

    @computed get placeholderTeamsForPhase() {
        return this.teams?.filter(t => t.isPlaceholderForSinglePhaseOnly);
    }

    @computed get placeholderTeamsWithNonPlaceholderTeamAdvancing() {
        return this.teams?.filter(t => {
            const advancingTeam = t.externalReference?.predictedAdvancingTeamId
                ? this.competitionTeamStore.getById(t.externalReference.predictedAdvancingTeamId)
                : undefined;

            if (!advancingTeam) {
                return false;
            }

            return !advancingTeam.isPlaceholder;
        });
    }

    @computed get placeholdersReadyForAdvancing() {
        return this.placeholderTeamsWithNonPlaceholderTeamAdvancing?.filter(
            t => !this.teamsIds?.some(teamId => teamId === t.externalReference?.predictedAdvancingTeamId),
        );
    }

    constructor(
        id: string,
        competitionId: string,
        type: PhaseTypeDTO,
        dependencies: {
            competitionTeamStore: CompetitionTeamStore;
            matchStore: MatchStore;
            externalReferencesStore: ExternalReferencesStore;
        },
    ) {
        this.id = id;
        this.competitionId = competitionId;
        this.type = type;

        this.competitionTeamStore = dependencies.competitionTeamStore;
        this.matchStore = dependencies.matchStore;
        this.externalReferencesStore = dependencies.externalReferencesStore;
    }

    @computed get hasDetails() {
        return !!this.teams;
    }

    @computed get externalReferencesForAdvancingTeams(): Map<string, ExternalReference | undefined> | undefined {
        if (!this.teamsIds || !this.externalReferences) {
            return undefined;
        }

        return new Map(
            this.teamsIds.map(teamId => [teamId, this.externalReferences?.find(r => r.advancingTeamId === teamId)]),
        );
    }

    async fetchDetails() {
        const details = await retryQuery(() => api.phaseDetails({ PhaseId: this.id }));

        this.updateFromDTO(details);
    }

    abstract updateFromDTO(dto: CompetitionPhaseDTO | CompetitionPhaseDetailsDTO): this;

    async rename(name?: string) {
        const response = await api.renamePhase({
            PhaseId: this.id,
            Name: name,
        });

        handleResponse(response, RenamePhase).handle("success", () => {
            runInAction(() => {
                this.name = name;
            });
        });

        return handleResponse(response, RenamePhase);
    }

    async addTeamsToPhase(teamIds: string[]) {
        const filteredIds = teamIds.filter(id => !this.teamsIds?.includes(id));

        if (filteredIds.length === 0)
            return handleResponse({ isSuccess: true, result: { WasSuccessful: true } }, AddTeamsToPhase);

        const response = await api.addTeamsToPhase({
            CompetitionId: this.competitionId,
            PhaseId: this.id,
            TeamIds: filteredIds,
        });

        if (response.isSuccess && response.result.WasSuccessful) {
            runInAction(() => {
                this.teamsIds?.push(...filteredIds);
            });
        }

        return handleResponse(response, AddTeamsToPhase);
    }

    async removeTeam(teamId: string, suppressMessage?: boolean) {
        const response = await api.removeTeamFromPhase({
            PhaseId: this.id,
            TeamId: teamId,
        });

        handleResponse(response, RemoveTeamFromPhase)
            .handle(
                ["PhaseNotFound", "TeamNotInPhase", "failure"],
                () => !suppressMessage && message.error(l("CompetitionDetails_Teams_Remove_Failure")),
            )
            .handle("success", () => {
                !suppressMessage && message.success(l("CompetitionDetails_Teams_Remove_Success"));

                this.handlePhaseTeamDeletion(teamId);
            });

        return handleResponse(response, AddTeamsToPhase);
    }

    async deleteSchedule() {
        const response = await api.deletePhaseMatchSchedule({
            PhaseId: this.id,
        });

        handleResponse(response, DeletePhaseMatchSchedule).handle("success", () => {
            runInAction(() => {
                this.matchIds = undefined;
            });
        });

        return handleResponse(response, DeletePhaseMatchSchedule);
    }

    async deleteMatch(matchId: string) {
        const response = await api.deleteMatch({
            MatchId: matchId,
        });

        handleResponse(response, DeleteMatch).handle("success", () => {
            runInAction(() => {
                this.matchIds = this.matchIds?.filter(id => id !== matchId);
            });
        });

        return handleResponse(response, DeleteMatch);
    }

    @action.bound
    async begin() {
        this.isOngoing = true;
    }

    @action.bound
    async end() {
        this.isOngoing = false;
    }

    async addPlaceholderTeam(name: string) {
        const placeholderId = newId();

        const response = await api.addPlaceholderTeamForPhase({
            CompetitionId: this.competitionId,
            PhaseId: this.id,
            TeamId: placeholderId,
            Name: name,
        });

        let placeholder: CompetitionTeam | undefined;

        handleResponse(response, AddPlaceholderTeamForPhase)
            .handle("success", async () => {
                placeholder = CompetitionTeam.createPlaceholderTeamForPhase(
                    {
                        placeholderId,
                        competitionId: this.competitionId,
                        phaseId: this.id,
                        name: name,
                    },
                    this.externalReferencesStore,
                );

                this.competitionTeamStore.put(placeholder);
                this.teamsIds = [...(this.teamsIds ?? []), placeholder.id];

                message.success(l("CompetitionPhases_PlaceholderTeams_Add_Success"));
            })
            .handle(
                ["NameMissingOrEmpty", "NameTooLong", "PhaseNotFoundInCompetition", "TeamIdAlreadyInUse", "failure"],
                () => {
                    message.error(l("CompetitionPhases_PlaceholderTeams_Add_Failure"));
                },
            )
            .check();

        return placeholder;
    }

    async renamePlaceholderTeam(teamId: string, name: string) {
        const team = this.teams?.find(t => t.id === teamId && t.isPlaceholderForSinglePhaseOnly);

        if (!team) {
            throw new Error("Placeholder team not found in phase. Cannot rename.");
        }

        const response = await api.renamePlaceholderTeamForPhase({
            TeamId: teamId,
            Name: name,
        });

        let success = false;

        handleResponse(response, RenamePlaceholderTeamForPhase)
            .handle("success", () => {
                message.success(l("CompetitionPhases_PlaceholderTeams_Edit_Success"));

                team.rename(name);

                success = true;
            })
            .handle(
                [
                    "TeamNotFound",
                    "TeamIsNotAPlaceholderForSinglePhaseOnly",
                    "NameMissingOrEmpty",
                    "NameTooLong",
                    "failure",
                ],
                () => message.error(l("CompetitionPhases_PlaceholderTeams_Edit_Failure")),
            );

        return success;
    }

    async replacePlaceholderTeams(replacements: TeamReplacement[]) {
        const placeholderTeam = this.teams?.find(
            t => replacements.map(r => r.placeholderTeamId).includes(t.id) && t.isPlaceholderForSinglePhaseOnly,
        );

        if (!placeholderTeam) {
            throw new Error("Placeholder team not found in phase. Cannot rename.");
        }

        const response = await api.replacePlaceholderTeamForPhase({
            CompetitionId: this.competitionId,
            PhaseId: this.id,
            Replacements: replacements.map(r => ({
                PlaceholderTeamId: r.placeholderTeamId,
                ReplacementTeamId: r.replacementId,
            })),
        });

        let success = false;

        handleResponse(response, ReplacePlaceholderTeamForPhase)
            .handle(
                [
                    "CompetitionNotFound",
                    "PhaseNotFoundInCompetition",
                    "SomePlaceholderTeamsForPhaseNotFound",
                    "SomeReplacementTeamsAlreadyAddedToPhase",
                    "SomeReplacementTeamsArePlaceholders",
                    "SomeReplacementTeamsNotFoundInCompetition",
                    "failure",
                ],
                () => message.error(l("CompetitionPhases_PlaceholderTeams_Replace_Failure")),
            )
            .handle("success", () => {
                success = true;

                message.success(l("CompetitionPhases_PlaceholderTeams_Replace_Success"));
            })
            .check();

        if (success) {
            await this.fetchDetails();
        }

        return success;
    }

    async addTablePointsModifier(teamId: string, points: number, description: string | undefined) {
        if (this.type === PhaseTypeDTO.Custom || this.type === PhaseTypeDTO.Knockout) {
            throw new Error("Table points modifiers can only be added to Table and Groups phases.");
        }

        const pointsModifierId = newId();

        const response = await api.addTablePointsModifier({
            Id: pointsModifierId,
            PhaseId: this.id,
            TeamId: teamId,
            Points: points,
            Description: description,
        });

        const handler = handleResponse(response, AddTablePointsModifier);

        handler.handle("success", () => {
            runInAction(() => {
                const pointsModifier = new TablePointsModifier(
                    pointsModifierId,
                    {
                        phaseId: this.id,
                        points,
                        teamId,
                        description,
                    },
                    this.competitionTeamStore,
                );

                this.pointsModifiers = [pointsModifier, ...(this.pointsModifiers ?? [])];
            });
        });

        return handler;
    }

    async deleteTablePointsModifier(modifierId: string) {
        if (!this.pointsModifiers?.find(pm => pm.id === modifierId)) {
            throw new Error("Table points modifier not found.");
        }

        const response = await api.deleteTablePointsModifier({
            PhaseId: this.id,
            TablePointsModifierId: modifierId,
        });

        const handler = handleResponse(response, DeleteTablePointsModifier);

        handler.handle("success", () => {
            runInAction(() => {
                this.pointsModifiers = this.pointsModifiers?.filter(pm => pm.id !== modifierId);
            });
        });

        return handler;
    }

    async advanceTeamForGroupsPhaseReference(placeholderId: string) {
        let success = false;

        const handler = await this.advanceTeamsForPhaseReferenceBase([placeholderId]);

        handler
            .handle("success", () => {
                success = true;

                message.success(l("CompetitionPhases_ExternalReferences_AdvanceTeam_Success"));
            })
            .handle(
                [
                    "ExternalReferenceIdsNullOrEmpty",
                    "OneOfAdvancingTeamsAlreadyInPhase",
                    "OneOfAdvancingTeamsIsAPlaceholder",
                    "OneOfReferencesNotFoundInPhase",
                    "PhaseNotFound",
                    "PhaseNotLinked",
                    "failure",
                ],
                () => {
                    message.error(l("CompetitionPhases_ExternalReferences_AdvanceTeam_Failure"));
                },
            );

        return success;
    }

    async advanceTeamsForAllGroupsPhaseReferences() {
        if (!this.placeholdersReadyForAdvancing || this.placeholdersReadyForAdvancing.length === 0) {
            throw new Error("No placeholder teams ready for advancing.");
        }

        let success = false;

        const handler = await this.advanceTeamsForPhaseReferenceBase(this.placeholdersReadyForAdvancing.map(t => t.id));

        handler
            .handle("success", () => {
                if (
                    this.placeholdersReadyForAdvancing?.length ===
                    this.placeholderTeamsWithNonPlaceholderTeamAdvancing?.length
                ) {
                    message.success(l("CompetitionPhases_ExternalReferences_AdvanceTeams_Success"));
                } else {
                    message.error(l("CompetitionPhases_ExternalReferences_AdvanceTeams_PartialFailure"));
                }

                success = true;
            })
            .handle(
                [
                    "ExternalReferenceIdsNullOrEmpty",
                    "OneOfAdvancingTeamsAlreadyInPhase",
                    "OneOfAdvancingTeamsIsAPlaceholder",
                    "OneOfReferencesNotFoundInPhase",
                    "PhaseNotFound",
                    "PhaseNotLinked",
                    "failure",
                ],
                () => {
                    message.error(l("CompetitionPhases_ExternalReferences_AdvanceTeams_Failure"));
                },
            );

        return success;
    }

    async advanceTeamsForPhaseReferenceBase(placeholderIds: string[]) {
        const externalReferenceIds = placeholderIds
            .map(placeholderId => this.teams?.find(t => t.id === placeholderId))
            .map(t => t?.externalReferenceId)
            .filter(notUndefined);

        if (externalReferenceIds.length !== placeholderIds.length) {
            throw new Error("One of placeholders is not external reference related.");
        }

        const response = await api.advanceTeamsForPhaseRelatedReferences({
            PhaseId: this.id,
            ExternalReferenceIds: externalReferenceIds,
        });

        let success = false;

        const handler = handleResponse(response, AdvanceTeamsForPhaseRelatedReferences);

        handler.handle("success", () => {
            success = true;
        });

        if (success) {
            await this.fetchDetails();
        }

        return handler;
    }

    async revokeTeamAdvancing(externalReferenceId: string) {
        const placeholderId = newId();

        const response = await api.revokeTeamAdvancing({
            PhaseId: this.id,
            ExternalReferenceId: externalReferenceId,
            ReplacementPlaceholderId: placeholderId,
        });

        let success = false;

        handleResponse(response, RevokeTeamAdvancing)
            .handle(
                [
                    "PhaseNotFound",
                    "ExternalReferenceNotFound",
                    "ExternalReferenceWithNoTeamAdvanced",
                    "ReplacementPlaceholderIdAlreadyInUse",
                    "failure",
                ],
                () => {
                    message.error(l("CompetitionPhases_ExternalReferences_RevokeTeamAdvancing_Failure"));
                },
            )
            .handle("success", () => {
                success = true;
            })
            .check();

        if (success) {
            await this.fetchDetails();

            const placeholderTeam = CompetitionTeam.createPlaceholderTeamForExternalReference(
                {
                    competitionId: this.competitionId,
                    phaseId: this.id,
                    placeholderId: placeholderId,
                    referenceId: externalReferenceId,
                },
                this.externalReferencesStore,
            );

            this.competitionTeamStore.put(placeholderTeam);

            message.success(l("CompetitionPhases_ExternalReferences_RevokeTeamAdvancing_Success"));
        }

        return success;
    }

    async swapTeams(team1Id: string, team2Id: string) {
        const response = await api.swapTeamsInPhase({
            PhaseId: this.id,
            Team1Id: team1Id,
            Team2Id: team2Id,
        });

        let success = false;

        handleResponse(response, SwapTeamsInPhase)
            .handle(["PhaseNotFound", "Team1AndTeam2AreTheSame", "Team1NotFound", "Team2NotFound", "failure"], () => {
                message.error(l("CompetitionPhases_SwapTeams_Failure"));
            })
            .handle("success", () => {
                success = true;
            })
            .check();

        if (success) {
            await this.fetchDetails();

            message.success(l("CompetitionPhases_SwapTeams_Success"));
        }

        return success;
    }

    @action.bound
    handlePhaseTeamDeletion(teamId: string) {
        this.teamsIds = this.teamsIds?.filter(id => id !== teamId);
        this.pointsModifiers = this.pointsModifiers?.filter(pm => pm.teamId !== teamId);
    }

    @action.bound
    sortTieBreakingOrder() {
        if (!this.teamsTieBreakingOrder || !this.teamsIds) return;

        this.teamsTieBreakingOrder = this.teamsTieBreakingOrder.filter(
            t => this.teamsIds?.includes(t.id) && t.team && !t.team.isPlaceholder,
        );

        const sortedOrder = this.teamsTieBreakingOrder.slice();
        sortedOrder.sort((a, b) => {
            if (a.tieBreakingOrder !== b.tieBreakingOrder) return b.tieBreakingOrder - a.tieBreakingOrder;

            if (!a.team && !b.team) return 0;
            if (!a.team) return 1;
            if (!b.team) return -1;

            return a.team.displayName < b.team.displayName ? -1 : 1;
        });
        this.teamsTieBreakingOrder = sortedOrder;

        const newIds = this.teamsIds
            .filter(
                id =>
                    !this.teamsTieBreakingOrder?.some(t => t.id === id) &&
                    !this.competitionTeamStore.getById(id)?.isPlaceholder,
            )
            .map(id => new TieBreakingOrderTeam(id, 0, this.competitionTeamStore));
        this.teamsTieBreakingOrder.push(...newIds);
    }

    async changeTieBreakingOrder(index: number, direction: "up" | "down") {
        if (!this.teamsTieBreakingOrder || !this.teamsTieBreakingOrder[index]) return;

        const flag = direction === "up" ? -1 : 1;

        if (!this.teamsTieBreakingOrder[index + flag]) return;

        [this.teamsTieBreakingOrder[index + flag], this.teamsTieBreakingOrder[index]] = [
            this.teamsTieBreakingOrder[index],
            this.teamsTieBreakingOrder[index + flag],
        ];
    }

    async saveTieBreakingOrder() {
        if (!this.teamsTieBreakingOrder) return;

        const teams = [...this.teamsTieBreakingOrder, ...(this.teams?.filter(t => t.isPlaceholder) ?? [])];
        const response = await api.setPhaseTieBreakingOrder({
            PhaseId: this.id,
            TeamsTieBreakingOrder: teams.map((t, index) => ({
                TeamId: t.id,
                TieBreakingOrder: teams.length - index,
            })),
        });

        let success = false;

        handleResponse(response, SetPhaseTieBreakingOrder)
            .handle(
                [
                    "OneOfTeamsIsNotInThePhase",
                    "PhaseNotFound",
                    "TieBreakingOrderMustBePositive",
                    "TieBreakingOrderMustBeUnique",
                    "TieBreakingOrderMustCoverAllTeamsInPhase",
                    "failure",
                ],
                () => {
                    message.error(l("CompetitionPhases_SaveTieBreakingOrder_Failure"));
                },
            )
            .handle("success", () => {
                success = true;
            })
            .check();

        if (success) {
            message.success(l("CompetitionPhases_SaveTieBreakingOrder_Success"));
        }

        return success;
    }

    async addPlaceInGroupReferences(references: PlaceInGroupReference[]) {
        return this.addExternalReferencesBase(references, async placeholderIdsMap => {
            if (!this.linkedPhaseId) {
                return false;
            }

            const response = await api.addPlaceInGroupReferencesToPhase({
                PhaseId: this.id,
                ReferencedPhaseId: this.linkedPhaseId,
                References: references
                    .filter(r => !this.externalReferencesIds?.includes(r.id))
                    .map(r => ({
                        Id: r.id,
                        GroupId: r.groupId,
                        PlaceInGroup: r.placeInGroup,
                        PlaceholderId: placeholderIdsMap.get(r.id) ?? "",
                    })),
            });

            return response.isSuccess && response.result.WasSuccessful;
        });
    }

    async addNthBestForPlaceInGroupReferences(references: NthBestForPlaceInGroupReference[]) {
        return this.addExternalReferencesBase(references, async placeholderIdsMap => {
            if (!this.linkedPhaseId) {
                return false;
            }

            const response = await api.addNthBestForPlaceInGroupReferences({
                PhaseId: this.id,
                ReferencedPhaseId: this.linkedPhaseId,
                References: references
                    .filter(r => !this.externalReferencesIds?.includes(r.id))
                    .map(r => ({
                        Id: r.id,
                        Place: r.place,
                        PlaceInGroup: r.placeInGroup,
                        PlaceholderId: placeholderIdsMap.get(r.id) ?? "",
                    })),
            });

            return response.isSuccess && response.result.WasSuccessful;
        });
    }

    async addPlaceInTableReferences(references: PlaceInTableReference[]) {
        return this.addExternalReferencesBase(references, async placeholderIdsMap => {
            if (!this.linkedPhaseId) {
                return false;
            }

            const response = await api.addPlaceInTableReferencesToPhase({
                PhaseId: this.id,
                ReferencedPhaseId: this.linkedPhaseId,
                References: references
                    .filter(r => !this.externalReferencesIds?.includes(r.id))
                    .map(r => ({
                        Id: r.id,
                        PlaceInTable: r.placeInTable,
                        PlaceholderId: placeholderIdsMap.get(r.id) ?? "",
                    })),
            });

            return response.isSuccess && response.result.WasSuccessful;
        });
    }

    private async addExternalReferencesBase<TReference extends ExternalReference>(
        references: TReference[],
        adder: (placeholderIdsMap: Map<string, string>) => Promise<boolean>,
    ) {
        if (!this.linkedPhaseId) {
            throw new Error("External references can be added only to phases linked to other phases.");
        }

        if (references.some(r => r.referencedPhaseId !== this.linkedPhaseId)) {
            throw new Error("All references should point to linked phase.");
        }

        const placeholderIdsMap: Map<string, string> = new Map(references.map(r => [r.id, newId()]));

        const success = await adder(placeholderIdsMap);

        if (success) {
            const placeholderTeams = references.map(r =>
                CompetitionTeam.createPlaceholderTeamForExternalReference(
                    {
                        competitionId: this.competitionId,
                        phaseId: this.id,
                        placeholderId: placeholderIdsMap.get(r.id) ?? "",
                        referenceId: r.id,
                    },
                    this.externalReferencesStore,
                ),
            );

            this.competitionTeamStore.put(...placeholderTeams);
            this.externalReferencesStore.put(...references);

            runInAction(() => {
                this.teamsIds?.push(...placeholderTeams.map(t => t.id));
            });

            return this.addTeamsToPhase(placeholderTeams.map(t => t.id));
        } else {
            return false;
        }
    }
}

export type TeamReplacement = { placeholderTeamId: string; replacementId: string };

export default CompetitionPhaseBase;
