import { PageHeader, Skeleton } from "antd";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import PageContent from "Components/PageContent";
import RoutedTabs from "Components/RoutedTabs";
import config from "config";
import competitionStore from "Domain/Competitions";
import competitionTeamStore from "Domain/Competitions/CompetitionTeamStore";
import seasonStore from "Domain/Season";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import routes, { PageComponent } from "Router/routes";
import TeamAdmins from "./TeamAdmins";
import TeamPhoto from "./TeamPhoto";
import TeamPlayers from "./TeamPlayers";

const CompetitionTeamDetailsPage: PageComponent<typeof routes.competitionTeam> = ({
    match: {
        params: { teamId },
    },
    history: { push },
}) => {
    useEffect(() => {
        competitionStore.fetchCompetitionTeamDetails(teamId);
    }, [teamId]);

    const team = useObserver(() => competitionTeamStore.getById(teamId));
    const competition = useObserver(() => team && competitionStore.getById(team.competitionId));
    const season = useObserver(() => (competition?.seasonId ? seasonStore.getById(competition.seasonId) : undefined));

    const breadcrumb: BreadcrumbProps["routes"] = useMemo(
        () =>
            team && competition
                ? [
                      {
                          breadcrumbName: season ? season.name : l("Competitions_SeasonSelection_NoSeason"),
                          path: routes.competitions({ seasonId: competition.seasonId }),
                      },
                      {
                          breadcrumbName: competition.name,
                          path: routes.competitionDetails.teams({ competitionId: competition.id }),
                      },
                      {
                          breadcrumbName: team?.displayName,
                          path: routes.competitionTeam({ teamId: team.id }),
                      },
                  ]
                : [],
        [competition, team, season],
    );

    const detailsNotAvailable = team?.players === undefined;

    return useObserver(() => (
        <PageContent>
            <PageContent.Header>
                <PageContent.HeaderSkeleton active={!team} loading={!team}>
                    <PageHeader
                        title={team?.displayName}
                        onBack={() =>
                            competition && push(routes.competitionDetails.teams({ competitionId: competition.id }))
                        }
                        breadcrumb={{
                            routes: breadcrumb,
                            itemRender: ({ path, breadcrumbName }) =>
                                path === location.pathname ? breadcrumbName : <Link to={path}>{breadcrumbName}</Link>,
                        }}
                        avatar={{ src: team?.logo ?? config.defaultTeamLogo, shape: "square" }}
                    />
                </PageContent.HeaderSkeleton>
            </PageContent.Header>
            <PageContent.Card>
                <Skeleton active={detailsNotAvailable} loading={detailsNotAvailable}>
                    {team && competition && (
                        <RoutedTabs<"players" | "admins" | "photo">
                            tabs={[
                                {
                                    key: "players",
                                    label: l("CompetitionTeams_Players"),
                                    navigateTo: routes.competitionTeam.players({
                                        teamId: teamId,
                                    }),
                                    routePattern: routes.competitionTeam.players.pattern,
                                    content: <TeamPlayers team={team} competition={competition} season={season} />,
                                },
                                {
                                    key: "admins",
                                    label: l("CompetitionTeams_Admin"),
                                    navigateTo: routes.competitionTeam.admins({
                                        teamId: teamId,
                                    }),
                                    routePattern: routes.competitionTeam.admins.pattern,
                                    content: <TeamAdmins team={team} />,
                                },
                                {
                                    key: "photo",
                                    label: l("CompetitionTeams_Photo"),
                                    navigateTo: routes.competitionTeam.photo({
                                        teamId: teamId,
                                    }),
                                    routePattern: routes.competitionTeam.photo.pattern,
                                    content: (
                                        <TeamPhoto
                                            photoUri={team.photoUri}
                                            onUpdateTeamPhoto={file => team.updatePhoto(file)}
                                            onRemovePhoto={() => team.removePhoto()}
                                        />
                                    ),
                                },
                            ]}
                            defaultKey="players"
                        />
                    )}
                </Skeleton>
            </PageContent.Card>
        </PageContent>
    ));
};

export default CompetitionTeamDetailsPage;
