import { RefereeDTO } from "Contracts/PlayooLeagueClient";
import { observable } from "mobx";

class CompetitionReferee {
    @observable phoneNumber: string;
    @observable firstName: string;
    @observable lastName: string;

    constructor({ phoneNumber, firstName, lastName }: CompetitionRefereeInit) {
        this.phoneNumber = phoneNumber;
        this.firstName = firstName;
        this.lastName = lastName;
    }

    static fromDTO(dto: RefereeDTO) {
        return new CompetitionReferee({
            phoneNumber: dto.PhoneNumber,
            firstName: dto.FirstName,
            lastName: dto.LastName,
        });
    }

    toDTO(): RefereeDTO {
        return {
            FirstName: this.firstName,
            LastName: this.lastName,
            PhoneNumber: this.phoneNumber,
        };
    }
}

type CompetitionRefereeInit = Pick<CompetitionReferee, "phoneNumber" | "firstName" | "lastName">;

export default CompetitionReferee;
