import { DatePicker, Form, Input, message, Skeleton } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import { Select } from "Components/Select";
import { MatchStatusDTO } from "Contracts/PlayooLeagueClient";
import CustomPhase from "Domain/CompetitionPhases/CustomPhase/CustomPhase";
import CustomPhaseTeamsConfigurator from "Domain/CompetitionPhases/CustomPhase/CustomPhaseTeamsConfigurator";
import MatchGroup from "Domain/CompetitionPhases/CustomPhase/MatchGroup";
import {
    isLinkablePhase,
    LinkablePhase,
    linkablePhases,
} from "Domain/CompetitionPhases/ExternalReferences/ExternalReference";
import Competition from "Domain/Competitions/Competition";
import CompetitionSportsField from "Domain/Competitions/CompetitionSportsField";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import MatchTeamsFormItem from "DomainComponents/Matches/MatchTeamsFormItem";
import { l } from "Languages";
import { observer, useObserver } from "mobx-react-lite";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { dateWithTimeFormat, timeFormat } from "Utils/formatting";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";
import MatchStatusFormItem from "../MatchStatusFormItem";

export type CustomPhaseMatchFormDialogProps = {
    teams?: CompetitionTeam[];
    matchGroups?: MatchGroup[];
    sportsFields?: CompetitionSportsField[];

    teamsEditionDisabled?: boolean;
    initialValues?: Partial<FormFields>;
    mode: "create" | "edit";

    onSave: (formFields: FormFields) => Promise<boolean>;
    onClose: () => void;

    competition: Competition;
    phase: CustomPhase;
};

const CustomPhaseMatchFormDialog: React.FunctionComponent<CustomPhaseMatchFormDialogProps> = observer(
    ({
        teams,
        matchGroups,
        sportsFields,
        teamsEditionDisabled,
        initialValues,
        mode,
        onSave,
        onClose,
        competition,
        phase,
    }) => {
        const [phaseConfigurator, setPhaseConfigurator] = useState<CustomPhaseTeamsConfigurator>();

        const linkedPhase = useMemo(() => {
            return phase.linkedPhaseId ? competition.phases?.find(p => p.id === phase.linkedPhaseId) : undefined;
        }, [competition.phases, phase.linkedPhaseId]);

        const linkedPhaseDetailsFetched = linkedPhase?.hasDetails;

        useEffect(() => {
            if (linkedPhase && !linkedPhaseDetailsFetched && linkablePhases.includes(linkedPhase.type)) {
                linkedPhase.fetchDetails();
            }
        }, [phase.linkedPhaseId, linkedPhase, linkedPhaseDetailsFetched]);

        useEffect(() => {
            let newConfigurator: CustomPhaseTeamsConfigurator | undefined = undefined;
            if (!phase.linkedPhaseId) {
                newConfigurator = new CustomPhaseTeamsConfigurator(phase);
            }

            if (phase.linkedPhaseId && linkedPhase && isLinkablePhase(linkedPhase) && linkedPhaseDetailsFetched) {
                newConfigurator = new CustomPhaseTeamsConfigurator(phase, linkedPhase as LinkablePhase);
            }

            if (newConfigurator) {
                setPhaseConfigurator(newConfigurator);
                newConfigurator.startAddingTeams();
            }
        }, [competition, phase, linkedPhase, linkedPhaseDetailsFetched]);

        const [form] = useForm();
        const [isRunning, runInTask] = useRunInTask();

        const onFinish = useCallback(
            (values: FormFields) => {
                runInTask(async () => {
                    phaseConfigurator?.teamsSelection?.setSelectedIds([values.team1Id, values.team2Id]);

                    const added = await phaseConfigurator?.saveTeamsAddition();
                    if (!added) {
                        message.error(l("CompetitionPhases_Custom_AddMatch_Failure"));
                        return;
                    }
                    await competition.fetchDetails();

                    const team1Id =
                        competition.allTeams?.find(t => t.externalReferenceId === values.team1Id)?.id ?? values.team1Id;
                    const team2Id =
                        competition.allTeams?.find(t => t.externalReferenceId === values.team2Id)?.id ?? values.team2Id;

                    const saved = await onSave({ ...values, team1Id, team2Id });
                    if (saved) phaseConfigurator?.cancelAddingTeams();
                });
            },
            [runInTask, phaseConfigurator, competition, onSave],
        );

        return useObserver(() => (
            <Modal
                title={
                    mode === "create"
                        ? l("CompetitionPhases_Custom_AddMatch_Form_Title")
                        : l("MatchDetails_EditMatch_Form_Title")
                }
                onCancel={() => {
                    onClose?.();
                    phaseConfigurator?.cancelAddingTeams();
                }}
                okText={mode === "create" ? l("Common_Add") : l("Common_Save")}
                cancelText={l("Common_Cancel")}
                onOk={form.submit}
                okButtonProps={{
                    loading: isRunning,
                    disabled: isRunning,
                }}>
                {teams && matchGroups && sportsFields ? (
                    <Form
                        layout="vertical"
                        form={form}
                        initialValues={
                            initialValues
                                ? {
                                      ...initialValues,
                                      team1Id:
                                          competition.allTeams.find(t => t.id === initialValues.team1Id)
                                              ?.externalReferenceId ?? initialValues.team1Id,
                                      team2Id:
                                          competition.allTeams.find(t => t.id === initialValues.team2Id)
                                              ?.externalReferenceId ?? initialValues.team2Id,
                                  }
                                : undefined
                        }
                        onFinish={onFinish}>
                        <Form.Item
                            name={guard<FormField>("name")}
                            label={l("CompetitionPhases_Custom_Form_Name")}
                            rules={[
                                {
                                    max: 250,
                                    message: l("Common_Validation_FieldTooLong"),
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                        <MatchTeamsFormItem
                            teams={teams}
                            teamsEditionDisabled={teamsEditionDisabled}
                            team1IdKey={guard<FormField>("team1Id")}
                            team2IdKey={guard<FormField>("team2Id")}
                            selection={phaseConfigurator?.teamsSelection}
                        />
                        <Form.Item
                            name={guard<FormField>("matchGroupId")}
                            label={l("CompetitionPhases_Custom_Form_MatchGroups")}>
                            <Select
                                showSearch
                                showArrow
                                allowClear
                                selectOptions={matchGroups.map(g => ({ label: g.name, value: g.id, key: g.id }))}
                            />
                        </Form.Item>
                        <Form.Item
                            name={guard<FormField>("sportsFieldId")}
                            label={l("CompetitionPhases_Custom_Form_SportsField")}>
                            <Select
                                showSearch
                                showArrow
                                allowClear
                                selectOptions={sportsFields.map(sf => ({ label: sf.name, value: sf.id, key: sf.id }))}
                            />
                        </Form.Item>
                        <Form.Item
                            name={guard<FormField>("date")}
                            trigger="onSelect"
                            label={l("CompetitionPhases_Custom_Form_Date")}>
                            <DatePicker showTime={{ format: timeFormat }} format={dateWithTimeFormat} />
                        </Form.Item>
                        {mode === "edit" && initialValues?.status !== undefined && (
                            <MatchStatusFormItem
                                currentStatus={initialValues.status}
                                fieldName={guard<FormField>("status")}
                            />
                        )}
                    </Form>
                ) : (
                    <Skeleton active loading />
                )}
            </Modal>
        ));
    },
);

export type FormFields = {
    name?: string;
    team1Id: string;
    team2Id: string;
    matchGroupId?: string;
    sportsFieldId?: string;
    date?: moment.Moment | null;
    status: MatchStatusDTO;
};

type FormField = keyof FormFields;

export default CustomPhaseMatchFormDialog;
