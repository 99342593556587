import { handleResponse } from "@leancode/validation";
import { message } from "antd";
import { PublishArticle, UnpublishArticle } from "Contracts/CmsClient";
import { ArticleDetailsDTO, ArticleDTO, ArticleStateDTO, EditArticle } from "Contracts/PlayooLeagueClient";
import tenantSettingsStore from "Domain/Tenant";
import { l } from "Languages";
import { computed, observable, runInAction } from "mobx";
import moment, { Moment } from "moment";
import api from "Services/Api";
import cmsApi from "Services/CmsApi";
import { dateTimeFromDTO, dateTimeFromDTOOptional, dateTimeToDTOOptional } from "Utils/DTO";

class Article {
    public static readonly newsTag = "news";

    readonly id: string;

    @observable isNews?: boolean;
    @observable title: string;
    @observable content?: string;
    @observable summary?: string;
    @observable thumbnailPhoto?: string;
    @observable state: ArticleStateDTO;
    @observable datePublished?: moment.Moment;
    @observable dateModified: moment.Moment;
    @observable tags: string[];
    @observable competitionId?: string;

    private constructor(id: string, init: ArticleInit) {
        this.id = id;
        this.title = init.title;
        this.content = init.content;
        this.summary = init.summary;
        this.thumbnailPhoto = init.thumbnailPhoto;
        this.state = init.state;
        this.dateModified = init.dateModified;
        this.datePublished = init.datePublished;
        this.tags = init.tags;
        this.isNews = init.isNews;
        this.competitionId = init.competitionId;
    }

    static fromDTO(dto: ArticleDTO | ArticleDetailsDTO) {
        return new Article(dto.Id, {
            title: dto.Title,
            state: dto.State as ArticleStateDTO,
            dateModified: dateTimeFromDTO(dto.DateModified),
            datePublished: dateTimeFromDTOOptional(dto.DatePublished),
            tags: dto.Tags,
            content: "Content" in dto ? dto.Content : undefined,
            summary: "Summary" in dto ? dto.Summary : undefined,
            isNews: "IsNews" in dto ? dto.IsNews : undefined,
            thumbnailPhoto: "ThumbnailPhotoUri" in dto ? dto.ThumbnailPhotoUri ?? undefined : undefined,
            competitionId: "CompetitionId" in dto ? dto.CompetitionId ?? undefined : undefined,
        });
    }

    static prepareContent(content: string) {
        const doc = new DOMParser().parseFromString(content, "text/html");

        const paragraphs = Array.from(doc.getElementsByTagName("p"));

        // flatten html structure for easier content styling
        paragraphs.forEach(p => {
            const media = p ? Array.from(p.querySelectorAll("img, iframe")) : [];

            if (media.length > 0) {
                p?.replaceWith(...media);
            }
        });

        return new XMLSerializer().serializeToString(doc.body);
    }

    async edit({
        isNews,
        title,
        content,
        summary,
        thumbnailPhotoUri,
        tags,
        datePublished,
        competitionId,
    }: EditArticleData) {
        const datePublishedDto = dateTimeToDTOOptional(datePublished);

        const response = await api.editArticle({
            ArticleId: this.id,
            Content: Article.prepareContent(content),
            IsNews: isNews,
            Summary: summary,
            Title: title,
            Tags: tags,
            DatePublished: this.datePublished
                ? datePublishedDto ?? dateTimeToDTOOptional(this.datePublished)
                : datePublishedDto,
            ThumbnailPhotoUri: thumbnailPhotoUri,
            CompetitionId: competitionId,
        });

        return {
            handler: handleResponse(response, EditArticle),
            wasSuccessful: response.isSuccess && response.result.WasSuccessful,
        };
    }

    async publish() {
        if (this.state === ArticleStateDTO.Published) {
            throw new Error("Article is already published");
        }

        const response = await cmsApi.publishArticle({
            ArticleId: this.id,
        });

        handleResponse(response, PublishArticle)
            .handle(["ArticleAlreadyPublished", "ArticleNotFound", "failure"], () => {
                message.error(l("Articles_Publish_Failure"));
            })
            .handle("success", () => {
                runInAction(() => {
                    this.state = ArticleStateDTO.Published;
                    this.datePublished = moment();
                    this.dateModified = moment();
                });

                message.success(l("Articles_Publish_Success"));
            })
            .check();

        return handleResponse(response, PublishArticle);
    }

    async unpublish() {
        if (this.state === ArticleStateDTO.Draft) {
            throw new Error("Article is already unpublished.");
        }

        const response = await cmsApi.unpublishArticle({
            ArticleId: this.id,
        });

        handleResponse(response, UnpublishArticle)
            .handle(["ArticleIsNotPublished", "ArticleNotFound", "failure"], () => {
                message.error(l("Articles_Unpublish_Failure"));
            })
            .handle("success", () => {
                runInAction(() => {
                    this.state = ArticleStateDTO.Draft;
                    this.dateModified = moment();
                });

                message.success(l("Articles_Unpublish_Success"));
            })
            .check();

        return handleResponse(response, PublishArticle);
    }

    @computed get hasDetails() {
        return this.content !== undefined;
    }

    @computed get previewLink() {
        return tenantSettingsStore.landingUri ? `${tenantSettingsStore.landingUri}/articles/${this.id}` : undefined;
    }
}

export type EditArticleData = {
    isNews: boolean;
    title: string;
    content: string;
    summary: string;
    thumbnailPhotoUri?: string;
    datePublished?: Moment;
    tags?: string[];
    competitionId?: string;
};

type ArticleInit = Pick<
    Article,
    | "title"
    | "content"
    | "summary"
    | "state"
    | "datePublished"
    | "dateModified"
    | "tags"
    | "thumbnailPhoto"
    | "isNews"
    | "competitionId"
>;

export default Article;
