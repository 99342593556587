/* eslint-disable */
import { CQRS } from "@leancode/cqrs-client/CQRS";
import { IRemoteCommand, IRemoteQuery } from "@leancode/cqrs-client/ClientType";
import { ApiDateTime, ApiDate, ApiDateTimeOffset } from "Utils/DTO";

interface AuthorizeWhenAttribute { }

export interface AddRegistration extends IRemoteCommand {
    Id: string;
    Title: string;
    Summary?: string | null;
    LogoUri?: string | null;
    EntryFee?: string | null;
    StartDate: ApiDate;
    EndDate?: ApiDate | null;
    SportsVenueName?: string | null;
    Address?: string | null;
    City?: string | null;
    MaxTeamsCount?: number | null;
    BackgroundPhotoUri?: string | null;
    ThumbnailPhotoUri?: string | null;
    ArticleId: string;
    ArticleTitle: string;
    ArticleContent: string;
    ExtraFormFieldDescription?: string | null;
    AgeGroups: AgeGroupDTO[];
    Documents?: DocumentDTO[] | null;
}

export interface AgeGroupDTO {
    Id: string;
    MaxAllowedBirthYear?: number | null;
}

export interface AllRegistrations extends IRemoteQuery<RegistrationDTO[]> {
}

export interface ApplicationAgeGroupDTO {
    MaxAllowedBirthYear?: number | null;
}

export interface ApplicationDTO {
    Id: string;
    TeamName: string;
    TeamCaptainOrCoachFullName: string;
    PhoneNumber: string;
    Email: string;
    AdditionalInformation?: string | null;
    SubmittedAt: ApiDateTime;
    AgeGroups: ApplicationAgeGroupDTO[];
}

export interface AuthorizeWhenCreatedOrInvitedUser extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenCreatedOrInvitedUsers extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenIsCompetitionTeamsAdmin extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenIsInvitationsRecipient extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsArticle extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsBanner extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetition extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetitionGroup extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetitionGroupOrCompetitionGroupNotSelected extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetitionOrCompetitionNotSelected extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetitionPhase extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetitionTeam extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetitionTeams extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsInvitation extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsMatch extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsPartner extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsPhoto extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsRegistration extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsSeason extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsSeasonOrSeasonNotSelected extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsSharedTeams extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsVideo extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenRefereesMatch extends AuthorizeWhenAttribute {
}

export interface AzureStorageTokenDTO {
    StorageUri: string;
    ContainerName: string;
    SharedAccessSignatureToken: string;
}

export interface CloseRegistration extends IRemoteCommand, IRegistrationRelated {
    RegistrationId: string;
}

export interface DocumentDTO {
    Id: string;
    Name: string;
    Uri: string;
    RequiresAcceptance: boolean;
}

export interface IArticleRelated {
    ArticleId: string;
}

export interface IBannerRelated {
    BannerId: string;
}

export interface ICompetitionGroupMaybeRelated {
    GroupId?: string | null;
}

export interface ICompetitionGroupRelated {
    GroupId: string;
}

export interface ICompetitionMaybeRelated {
    CompetitionId?: string | null;
}

export interface ICompetitionPhaseRelated {
    PhaseId: string;
}

export interface ICompetitionRelated {
    CompetitionId: string;
}

export interface ICompetitionTeamRelated {
    TeamId: string;
}

export interface ICompetitionTeamsRelated {
}

export interface ICreatedOrInvitedUser {
}

export interface ICreatedOrInvitedUsers {
}

export interface IInvitationRelated {
    InvitationId: string;
}

export interface IIsCompetitionTeamsAdmin {
}

export interface IIsInvitationsRecipient {
}

export interface IMatchRelated {
    MatchId: string;
}

export interface IOwnsArticle {
}

export interface IOwnsBanner {
}

export interface IOwnsCompetition {
}

export interface IOwnsCompetitionGroup {
}

export interface IOwnsCompetitionGroupOrCompetitionGroupNotSelected {
}

export interface IOwnsCompetitionOrCompetitionNotSelected {
}

export interface IOwnsCompetitionPhase {
}

export interface IOwnsCompetitionTeam {
}

export interface IOwnsCompetitionTeams {
}

export interface IOwnsInvitation {
}

export interface IOwnsMatch {
}

export interface IOwnsPartner {
}

export interface IOwnsPhoto {
}

export interface IOwnsRegistration {
}

export interface IOwnsSeason {
}

export interface IOwnsSeasonOrSeasonNotSelected {
}

export interface IOwnsSharedTeams {
}

export interface IOwnsVideo {
}

export interface IPartnerRelated {
    PartnerId: string;
}

export interface IPhotoRelated {
    PhotoId: string;
}

export interface IRefereesMatch {
}

export interface IRegistrationRelated {
    RegistrationId: string;
}

export interface ISeasonMaybeRelated {
    SeasonId?: string | null;
}

export interface ISeasonRelated {
    SeasonId: string;
}

export interface ISharedTeamsRelated {
}

export interface IUserProfileRelated {
    UserId: string;
}

export interface IUserProfilesRelated {
}

export interface IVideoRelated {
    VideoId: string;
}

export interface OpenRegistration extends IRemoteCommand, IRegistrationRelated {
    RegistrationId: string;
}

export interface RegistrationDetails extends IRemoteQuery<RegistrationDetailsDTO>, IRegistrationRelated {
    RegistrationId: string;
    IncludeApplications: boolean;
}

export interface RegistrationDetailsDTO extends RegistrationDTO {
    Summary?: string | null;
    LogoUri?: string | null;
    BackgroundPhotoUri?: string | null;
    ThumbnailPhotoUri?: string | null;
    ArticleId: string;
    SportsVenueName?: string | null;
    Address?: string | null;
    City?: string | null;
    MaxTeamsCount?: number | null;
    EntryFee?: string | null;
    EndDate?: ApiDate | null;
    ExtraFormFieldDescription?: string | null;
    AgeGroups: AgeGroupDTO[];
    Documents: DocumentDTO[];
    Applications?: ApplicationDTO[] | null;
}

export interface RegistrationDocumentsCreateToken extends IRemoteQuery<AzureStorageTokenDTO> {
}

export interface RegistrationDTO {
    Id: string;
    Title: string;
    StartDate: ApiDate;
    IsOpen: boolean;
    IsHighlighted: boolean;
}

export interface RegistrationPhotosCreateToken extends IRemoteQuery<AzureStorageTokenDTO> {
}

export interface UpdateRegistration extends IRemoteCommand, IRegistrationRelated {
    RegistrationId: string;
    Title: string;
    Summary?: string | null;
    LogoUri?: string | null;
    EntryFee?: string | null;
    StartDate: ApiDate;
    EndDate?: ApiDate | null;
    SportsVenueName?: string | null;
    Address?: string | null;
    City?: string | null;
    MaxTeamsCount?: number | null;
    BackgroundPhotoUri?: string | null;
    ThumbnailPhotoUri?: string | null;
    ExtraFormFieldDescription?: string | null;
    AgeGroups: AgeGroupDTO[];
    Documents?: DocumentDTO[] | null;
}

export interface UpdateRegistrationHighlightState extends IRemoteCommand, IRegistrationRelated {
    RegistrationId: string;
    IsHighlighted: boolean;
}

export const AddRegistration = {
    ErrorCodes: {
        RegistrationIdAlreadyInUse: 1,
        TitleMissingOrEmpty: 2,
        TitleTooLong: 3,
        SummaryTooLong: 4,
        EntryFeeTooLong: 5,
        SportsVenueNameTooLong: 6,
        AddressTooLong: 7,
        CityTooLong: 8,
        MaxTeamsCountNotPositive: 9,
        BackgroundPhotoUriTooLong: 10,
        LogoUriTooLong: 11,
        AgeGroupsMissingOrEmpty: 12,
        OneOfAgeGroupsIdAlreadyInUse: 13,
        AgeGroupsContainsDuplicates: 14,
        ArticleIdAlreadyInUse: 15,
        ArticleTitleMissingOrEmpty: 16,
        ArticleTitleTooLong: 17,
        ArticleContentMissingOrEmpty: 18,
        ArticleDescriptionInvalid: 19,
        OneOfDocumentsIdAlreadyInUse: 20,
        OneOfDocumentsNameMissingOrEmpty: 21,
        OneOfDocumentsNameTooLong: 22,
        OneOfDocumentsUriMissingOrEmpty: 23,
        OneOfDocumentsUriTooLong: 24,
        ExtraFormFieldDescriptionTooLong: 25,
        ThumbnailPhotoUriTooLong: 26,
    },
};

export const CloseRegistration = {
    ErrorCodes: {
        RegistrationNotFound: 1,
    },
};

export const OpenRegistration = {
    ErrorCodes: {
        RegistrationNotFound: 1,
    },
};

export const UpdateRegistration = {
    ErrorCodes: {
        RegistrationNotFound: 1,
        TitleMissingOrEmpty: 2,
        TitleTooLong: 3,
        SummaryTooLong: 4,
        EntryFeeTooLong: 5,
        SportsVenueNameTooLong: 6,
        AddressTooLong: 7,
        CityTooLong: 8,
        MaxTeamsCountNotPositive: 9,
        BackgroundPhotoUriTooLong: 10,
        LogoUriTooLong: 11,
        AgeGroupsMissingOrEmpty: 12,
        OneOfAgeGroupsIdAlreadyInUse: 13,
        AgeGroupsContainsDuplicates: 14,
        OneOfDocumentsIdAlreadyInUse: 15,
        OneOfDocumentsNameMissingOrEmpty: 16,
        OneOfDocumentsNameTooLong: 17,
        OneOfDocumentsUriMissingOrEmpty: 18,
        OneOfDocumentsUriTooLong: 19,
        ExtraFormFieldDescriptionTooLong: 20,
        ThumbnailPhotoUriTooLong: 21,
    },
};

export const UpdateRegistrationHighlightState = {
    ErrorCodes: {
        RegistrationNotFound: 1,
    },
};

export default function (cqrsClient: CQRS) {
    return {
        addRegistration: (dto: AddRegistration) => cqrsClient.executeCommand<typeof AddRegistration["ErrorCodes"]>("PlayooLeague.Registrations.Contracts.Admin.Registrations.AddRegistration", dto),
        allRegistrations: (dto: AllRegistrations) => cqrsClient.executeQuery<RegistrationDTO[]>("PlayooLeague.Registrations.Contracts.Admin.Registrations.AllRegistrations", dto),
        closeRegistration: (dto: CloseRegistration) => cqrsClient.executeCommand<typeof CloseRegistration["ErrorCodes"]>("PlayooLeague.Registrations.Contracts.Admin.Registrations.CloseRegistration", dto),
        openRegistration: (dto: OpenRegistration) => cqrsClient.executeCommand<typeof OpenRegistration["ErrorCodes"]>("PlayooLeague.Registrations.Contracts.Admin.Registrations.OpenRegistration", dto),
        registrationDetails: (dto: RegistrationDetails) => cqrsClient.executeQuery<RegistrationDetailsDTO>("PlayooLeague.Registrations.Contracts.Admin.Registrations.RegistrationDetails", dto),
        registrationDocumentsCreateToken: (dto: RegistrationDocumentsCreateToken) => cqrsClient.executeQuery<AzureStorageTokenDTO>("PlayooLeague.Registrations.Contracts.Admin.Storage.RegistrationDocumentsCreateToken", dto),
        registrationPhotosCreateToken: (dto: RegistrationPhotosCreateToken) => cqrsClient.executeQuery<AzureStorageTokenDTO>("PlayooLeague.Registrations.Contracts.Admin.Storage.RegistrationPhotosCreateToken", dto),
        updateRegistration: (dto: UpdateRegistration) => cqrsClient.executeCommand<typeof UpdateRegistration["ErrorCodes"]>("PlayooLeague.Registrations.Contracts.Admin.Registrations.UpdateRegistration", dto),
        updateRegistrationHighlightState: (dto: UpdateRegistrationHighlightState) => cqrsClient.executeCommand<typeof UpdateRegistrationHighlightState["ErrorCodes"]>("PlayooLeague.Registrations.Contracts.Admin.Registrations.UpdateRegistrationHighlightState", dto),
    };
}
