import { handleResponse } from "@leancode/validation";
import {
    AddTablePhaseMatch,
    CompetitionPhaseDetailsDTO,
    CompetitionPhaseDTO,
    GenerateTablePhaseMatchSchedule,
    PhaseTypeDTO,
} from "Contracts/PlayooLeagueClient";
import TablePhaseMatch from "Domain/CompetitionPhases/TablePhase/TablePhaseMatch";
import { CompetitionTeamStore } from "Domain/Competitions/CompetitionTeamStore";
import MatchResult from "Domain/Matches/MatchResult";
import MatchStore from "Domain/Matches/MatchStore";
import { l } from "Languages";
import { action, computed, observable } from "mobx";
import moment from "moment";
import api from "Services/Api";
import { dateTimeOffsetFromDTOOptional, dateTimeOffsetToDTOOptional } from "Utils/DTO";
import CompetitionPhaseBase from "../CompetitionPhaseBase";
import ExternalReferencesStore from "../ExternalReferences/ExternalReferencesStore";
import NthBestForPlaceInGroupReference from "../ExternalReferences/NthBestForPlaceInGroupReference";
import PlaceInGroupReference from "../ExternalReferences/PlaceInGroupReference";
import PlaceInTableReference from "../ExternalReferences/PlaceInTableReference";
import TablePointsModifier from "../TablePointsModifier";
import TieBreakingOrderTeam from "../TieBreakingOrderTeam";
import TablePhaseScoring from "./TablePhaseScoring";

class TablePhase extends CompetitionPhaseBase<TablePhaseMatch> {
    @observable scoring?: TablePhaseScoring;

    private constructor(
        id: string,
        competitionId: string,
        dependencies: {
            competitionTeamStore: CompetitionTeamStore;
            matchStore: MatchStore;
            externalReferencesStore: ExternalReferencesStore;
        },
    ) {
        super(id, competitionId, PhaseTypeDTO.Table, dependencies);
    }

    @computed get displayName() {
        return this.name ?? l("CompetitionPhases_Table");
    }

    static fromDTO(
        dto: CompetitionPhaseDTO | CompetitionPhaseDetailsDTO,
        competitionId: string,
        dependencies: {
            competitionTeamStore: CompetitionTeamStore;
            matchStore: MatchStore;
            externalReferencesStore: ExternalReferencesStore;
        },
    ): TablePhase {
        return new TablePhase(dto.Id, competitionId, dependencies).updateFromDTO(dto);
    }

    @action.bound
    updateFromDTO(dto: CompetitionPhaseDTO | CompetitionPhaseDetailsDTO) {
        this.name = dto.Name ?? undefined;
        this.isOngoing = dto.IsOngoing;
        this.linkedPhaseId = dto.LinkedPhaseId ?? undefined;

        if ("Teams" in dto) {
            this.teamsIds = dto.Teams.map(t => t.TeamId);
            this.teamsTieBreakingOrder = dto.Teams.map(
                t => new TieBreakingOrderTeam(t.TeamId, t.TieBreakingOrder, this.competitionTeamStore),
            );
        }

        if ("TablePhaseDetails" in dto) {
            this.scoring = dto.TablePhaseDetails ? TablePhaseScoring.fromDTO(dto.TablePhaseDetails.Scoring) : undefined;

            if (dto.TablePhaseDetails) {
                this.matchStore.put(
                    ...dto.TablePhaseDetails.Schedule.map(
                        m =>
                            new TablePhaseMatch(
                                m.Id,
                                this.id,
                                {
                                    article: undefined,
                                    matchday: m.MatchInPhaseDesignator ?? 0,
                                    date: dateTimeOffsetFromDTOOptional(m.Date),
                                    team1Id: m.Team1Id ?? undefined,
                                    team2Id: m.Team2Id ?? undefined,
                                    status: m.Status,
                                    result: m.Result ? MatchResult.fromDTO(m.Result) : undefined,
                                    sportsFieldId: m.SportsFieldId ?? undefined,
                                },
                                this.competitionTeamStore,
                            ),
                    ),
                );

                this.pointsModifiers = dto.TablePhaseDetails.PointsModifiers.map(m =>
                    TablePointsModifier.fromDTO(this.id, m, this.competitionTeamStore),
                );
            }

            this.matchIds =
                dto.TablePhaseDetails && dto.TablePhaseDetails.Schedule.length > 0
                    ? dto.TablePhaseDetails.Schedule.map(m => m.Id)
                    : undefined;

            dto.PlaceInGroupReferences.forEach(r =>
                this.externalReferencesStore.put(PlaceInGroupReference.fromDTO(r, this.id)),
            );

            dto.NthBestForPlaceInGroupReferences.forEach(r =>
                this.externalReferencesStore.put(NthBestForPlaceInGroupReference.fromDTO(r, this.id)),
            );

            dto.PlaceInTableReferences.forEach(r =>
                this.externalReferencesStore.put(PlaceInTableReference.fromDTO(r, this.id)),
            );

            this.externalReferencesIds = [
                ...dto.PlaceInGroupReferences.map(r => r.Id),
                ...dto.NthBestForPlaceInGroupReferences.map(r => r.Id),
                ...dto.PlaceInTableReferences.map(r => r.Id),
            ];

            this.defaultMatchDurationInMinutes = dto.DefaultMatchDurationInMinutes ?? undefined;
            this.defaultPauseBetweenMatchesInMinutes = dto.DefaultPauseBetweenMatchesInMinutes ?? undefined;
        }

        return this;
    }

    async generateSchedule(matchesPerPair: 1 | 2) {
        const response = await api.generateTablePhaseMatchSchedule({
            MatchesPerPair: matchesPerPair,
            PhaseId: this.id,
        });

        if (response.isSuccess && response.result.WasSuccessful) {
            await this.fetchDetails();
        }

        return handleResponse(response, GenerateTablePhaseMatchSchedule);
    }

    async addMatch({ team1Id, team2Id, matchday, date, sportsFieldId }: TablePhaseMatchData) {
        const response = await api.addTablePhaseMatch({
            PhaseId: this.id,
            Team1Id: team1Id,
            Team2Id: team2Id,
            Matchday: matchday,
            Date: dateTimeOffsetToDTOOptional(date),
            SportsFieldId: sportsFieldId,
        });

        return handleResponse(response, AddTablePhaseMatch);
    }
}

export type TablePhaseMatchData = {
    team1Id: string;
    team2Id: string;
    date?: moment.Moment;
    matchday: number;
    sportsFieldId?: string;
};

export default TablePhase;
