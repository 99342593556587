import { Button } from "antd";
import Form, { FormItemProps } from "antd/lib/form";
import Spacing from "Components/Spacing";
import { l } from "Languages";
import React from "react";

type RegistrationPhotoFormItemProps = FormItemProps & {
    name: string;
    label: string;
};

const RegistrationPhotoFormItem: React.FunctionComponent<RegistrationPhotoFormItemProps> = ({
    name,
    label,
    ...formItemProps
}) => {
    return (
        <Form.Item noStyle shouldUpdate={(prevValues, currValues) => prevValues[name] !== currValues[name]}>
            {({ getFieldValue, setFieldsValue }) => (
                <Form.Item
                    name={name}
                    label={
                        <Spacing childrenGutterX>
                            {label}
                            {!!getFieldValue(name) && (
                                <Button
                                    type="link"
                                    onClick={() =>
                                        setFieldsValue({
                                            [name]: undefined,
                                        })
                                    }>
                                    {l("Common_Remove")}
                                </Button>
                            )}
                        </Spacing>
                    }
                    {...formItemProps}
                />
            )}
        </Form.Item>
    );
};

export default RegistrationPhotoFormItem;
