import { DeleteOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useForm } from "antd/lib/form/util";
import DefferedPhotoUpload, { UploadedOrInlinePhoto } from "Components/DefferedPhotoUpload";
import Modal from "Components/Modal";
import Spacing from "Components/Spacing";
import Banner from "Domain/Banners/Banner";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";

export type BannerFormValidationErrors = Partial<Record<FormField, string>>;

type BannerFormDialogProps = {
    banner?: Banner;
    onDeleteBanner?: () => void;
    onSave: (values: BannerFormFields) => Promise<BannerFormValidationErrors | undefined>;
    onClose: () => void;
};

const BannerFormDialog: React.FunctionComponent<BannerFormDialogProps> = ({
    banner,
    onClose,
    onSave,
    onDeleteBanner,
}) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const onFinish = useCallback(
        async (values: BannerFormFields) => {
            const validationErrors = await runInTask(() => onSave(values));

            for (const field in validationErrors) {
                form.setFields([
                    {
                        name: field,
                        errors: [validationErrors[field]],
                        value: values[field],
                    },
                ]);
            }
        },
        [form, onSave, runInTask],
    );

    return useObserver(() => (
        <Modal
            title={
                <Spacing childrenGutterY>
                    <div>{banner ? l("Landing_Banners_Form_Edit") : l("Landing_Banners_Form_Add")}</div>
                    {banner && onDeleteBanner && (
                        <Button type="danger" onClick={onDeleteBanner}>
                            <DeleteOutlined /> {l("Common_Remove")}
                        </Button>
                    )}
                </Spacing>
            }
            onCancel={onClose}
            okText={banner ? l("Common_Save") : l("Common_Add")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}
            okButtonProps={{
                disabled: isRunning,
                loading: isRunning,
            }}>
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                initialValues={{
                    [guard<FormField>("picture")]: banner?.pictureUri,
                    [guard<FormField>("linkUri")]: banner?.linkUri,
                }}>
                <Form.Item
                    label={l("Landing_Banners_Form_Picture")}
                    name={guard<FormField>("picture")}
                    rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                    <DefferedPhotoUpload />
                </Form.Item>
                <Form.Item
                    name={guard<FormField>("linkUri")}
                    label={l("Landing_Banners_Form_Link")}
                    rules={[{ max: 500, message: l("Common_Validation_FieldTooLong") }]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    ));
};

export type BannerFormFields = {
    picture: UploadedOrInlinePhoto;
    linkUri?: string;
};

type FormField = keyof BannerFormFields;

export default BannerFormDialog;
