import mkCx from "@leancode/cx";
import { DatePicker, Form, Select as AntdSelect } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import { Select } from "Components/Select";
import SlotsFilling, {
    SlotsFillingFormField,
} from "Domain/CompetitionPhases/SchedulePlanner/SchedulePlanningWizard/SlotsFilling";
import { l } from "Languages";
import { observer } from "mobx-react-lite";
import React, { useCallback, useRef } from "react";
import { dateWithTimeFormat, timeFormat } from "Utils/formatting";
import guard from "Utils/guard";
import styles from "./styles.scss";

const cx = mkCx(styles);

type SlotsFillingFormDialogProps = {
    slotsFilling: SlotsFilling;

    onClose: () => void;
};

const SlotsFillingFormDialog: React.FunctionComponent<SlotsFillingFormDialogProps> = observer(
    ({ slotsFilling, onClose }) => {
        const [form] = useForm();

        const team1SelectRef = useRef<AntdSelect | null>(null);

        const onFinish = useCallback(() => {
            slotsFilling.assignSlot();

            if (slotsFilling.isFinished) {
                onClose();
            }

            team1SelectRef.current?.focus();
        }, [onClose, slotsFilling]);

        return (
            <Modal
                title={l("CompetitionPhases_PlanSchedule_Wizard_Title")}
                okText={l("Common_Save")}
                onOk={form.submit}
                onCancel={onClose}
                cancelButtonProps={{
                    className: cx("cancel-button"),
                }}>
                <Form
                    layout="vertical"
                    form={form}
                    fields={slotsFilling.fields}
                    onFinish={onFinish}
                    onFieldsChange={slotsFilling.onChange}>
                    <Form.Item
                        name={guard<SlotsFillingFormField>("date")}
                        label={l("CompetitionPhases_PlanSchedule_Wizard_SlotsFilling_Date")}>
                        <DatePicker disabled showTime={{ format: timeFormat }} format={dateWithTimeFormat} />
                    </Form.Item>
                    <Form.Item
                        name={guard<SlotsFillingFormField>("sportsFieldId")}
                        label={l("CompetitionPhases_PlanSchedule_Wizard_SlotsFilling_SportsField")}>
                        <Select
                            showArrow
                            allowClear
                            showSearch
                            selectOptions={slotsFilling.sportsFields.map(sf => ({
                                value: sf.id,
                                key: sf.id,
                                label: sf.name,
                            }))}
                            disabled
                        />
                    </Form.Item>
                    <Form.Item
                        name={guard<SlotsFillingFormField>("team1Id")}
                        label={l("CompetitionPhases_PlanSchedule_Wizard_SlotsFilling_Team1")}
                        rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                        <Select
                            ref={team1SelectRef}
                            showArrow
                            allowClear
                            showSearch
                            selectOptions={slotsFilling.selectableTeam1.map(t => ({
                                value: t.id,
                                key: t.id,
                                label: t.displayName,
                            }))}
                            autoFocus
                        />
                    </Form.Item>
                    <Form.Item
                        name={guard<SlotsFillingFormField>("team2Id")}
                        label={l("CompetitionPhases_PlanSchedule_Wizard_SlotsFilling_Team2")}
                        rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                        <Select
                            showArrow
                            allowClear
                            showSearch
                            selectOptions={slotsFilling.selectableTeam2.map(t => ({
                                value: t.id,
                                key: t.id,
                                label: t.displayName,
                            }))}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        );
    },
);

export default SlotsFillingFormDialog;
