import mkCx from "@leancode/cx";
import { Select as AntdSelect, Skeleton, Tag } from "antd";
import { Select, SelectProps } from "Components/Select";
import competitionStore from "Domain/Competitions";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useEffect } from "react";
import useRunInTask from "Utils/Hooks/useRunInTask";
import styles from "./styles.scss";

const cx = mkCx(styles);

const { OptGroup, Option } = AntdSelect;

type PlayersCreatorSelectProps = SelectProps;

const CompetitionsAutocomplete: React.FunctionComponent<PlayersCreatorSelectProps> = selectProps => {
    const [fetchInProgress, runFetchInTask] = useRunInTask();

    useEffect(() => {
        runFetchInTask(() => competitionStore.fetchAllSeasonsWithCompetitions());
    }, [runFetchInTask]);

    return useObserver(() => (
        <Skeleton loading={fetchInProgress} active={fetchInProgress} paragraph={{ rows: 1 }}>
            <Select showArrow showSearch allowClear optionLabelProp="label" {...selectProps}>
                {competitionStore.seasonsWithCompetitions
                    .filter(({ competitions }) => competitions.length > 0)
                    .map(({ season, competitions }) => (
                        <OptGroup label={season?.name ?? l("Competitions_SeasonSelection_NoSeason")} key={season?.id}>
                            {competitions.map(c => (
                                <Option
                                    value={c.id}
                                    key={c.id}
                                    label={
                                        <>
                                            {c.name}
                                            <Tag className={cx("season-tag")}>
                                                {season?.name ?? l("Competitions_SeasonSelection_NoSeason")}
                                            </Tag>
                                        </>
                                    }>
                                    {c.name}
                                </Option>
                            ))}
                        </OptGroup>
                    ))}
            </Select>
        </Skeleton>
    ));
};

export default CompetitionsAutocomplete;
