import { ExclamationCircleOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Collapse, Form, List, Modal as AntdModal, Select as AntdSelect, Skeleton } from "antd";
import { useForm } from "antd/lib/form/util";
import EmptyState from "Components/EmptyState";
import Modal from "Components/Modal";
import { Select } from "Components/Select";
import externalReferencesStore from "Domain/CompetitionPhases/ExternalReferences";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import CompetitionTeamsCreator from "Domain/Competitions/CompetitionTeamsCreator";
import { TeamOptionContent } from "DomainComponents/CompetitionTeamsCreatorSelect";
import { l } from "Languages";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useRunInTask from "Utils/Hooks/useRunInTask";
import styles from "./styles.scss";

const cx = mkCx(styles);

const { OptGroup, Option } = AntdSelect;

const teamIdField = "teamId";

type MergeTeamsFormDialogProps = {
    team: CompetitionTeam;

    onClose: () => void;
};

const MergeTeamsFormDialog: React.FunctionComponent<MergeTeamsFormDialogProps> = ({ team, onClose }) => {
    const [form] = useForm();

    const creator = useMemo(() => new CompetitionTeamsCreator(externalReferencesStore), []);

    const [selectedTeamId, setSelectedTeamId] = useState<string>();
    const [selectedTeamDetails, setSelectedTeamDetails] = useState<CompetitionTeam>();

    const [fetchInProgress, runFetchInTask] = useRunInTask();
    const [teamFetchInProgress, runTeamFetchInTask] = useRunInTask();
    const [selectedTeamFetchInProgress, runSelectedTeamFetchInTask] = useRunInTask();

    const [isSelectedTeamFetched, setIsSelectedTeamFetched] = useState(false);

    useEffect(() => {
        runTeamFetchInTask(() => team.fetchDetails());
        runFetchInTask(() => creator.fetchExistingTeamsAddableToCompetition(team.competitionId));
    }, [creator, runFetchInTask, runTeamFetchInTask, team]);

    useEffect(() => {
        if (selectedTeamId && selectedTeamDetails?.id !== selectedTeamId) {
            setSelectedTeamDetails(
                new CompetitionTeam({ id: selectedTeamId, name: "", competitionId: "" }, { externalReferencesStore }),
            );
            setIsSelectedTeamFetched(false);
        }

        if (selectedTeamDetails && !selectedTeamFetchInProgress && !isSelectedTeamFetched) {
            runSelectedTeamFetchInTask(() => selectedTeamDetails.fetchDetails());
            setIsSelectedTeamFetched(true);
        }
    }, [
        isSelectedTeamFetched,
        runSelectedTeamFetchInTask,
        selectedTeamDetails,
        selectedTeamFetchInProgress,
        selectedTeamId,
    ]);

    const getSearchPhraseForTeam = useCallback(
        (team: CompetitionTeam) => team.displayName + (team.ageGroup ? ` ${team.ageGroup}` : ""),
        [],
    );

    const showMergeTeamsConfirmation = useCallback(
        (teamSharedId: string) => {
            AntdModal.confirm({
                onOk: () => team.mergeTeam(teamSharedId, onClose),
                icon: <ExclamationCircleOutlined />,
                title: l("CompetitionDetails_Teams_Merge_Confirmation_Title"),
                content: l("CompetitionDetails_Teams_Merge_Confirmation_Content"),
                okText: l("CompetitionDetails_Teams_Merge_Confirmation_OkText"),
                okButtonProps: {
                    danger: true,
                },
                cancelText: l("Common_Cancel"),
                centered: true,
                maskClosable: true,
            });
        },
        [onClose, team],
    );

    const onFinish = useCallback(() => showMergeTeamsConfirmation(selectedTeamDetails?.sharedId ?? ""), [
        selectedTeamDetails?.sharedId,
        showMergeTeamsConfirmation,
    ]);

    return (
        <Modal
            title={team.name}
            onCancel={onClose}
            okText={l("CompetitionDetails_Teams_Merge_Form_OkText")}
            cancelText={l("Common_Cancel")}
            confirmLoading={selectedTeamFetchInProgress}
            okButtonProps={{ disabled: !selectedTeamDetails }}
            onOk={form.submit}>
            <Collapse>
                <Collapse.Panel
                    className={cx("team-collapse")}
                    header={`${l("CompetitionDetails_Teams_Merge_History_Team")} ${team.name}`}
                    key="1">
                    {!teamFetchInProgress ? (
                        team.history?.map((h, index) => (
                            <List
                                key={index}
                                header={`${l("CompetitionDetails_Teams_Merge_History_Season")} ${h.name}`}
                                dataSource={h.competitions}
                                renderItem={i => (
                                    <List.Item>
                                        <span className={cx("competition-item")}>{i.name}</span>
                                    </List.Item>
                                )}
                            />
                        ))
                    ) : (
                        <Skeleton active loading paragraph={{ rows: 1 }} />
                    )}
                </Collapse.Panel>
            </Collapse>
            {!fetchInProgress ? (
                <Form layout="vertical" form={form} onFinish={onFinish}>
                    <Form.Item
                        label={l("CompetitionDetails_Teams_Merge_Form_Team")}
                        name={teamIdField}
                        rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                        <Select
                            notFoundContent={<EmptyState text={l("CompetitionDetails_Teams_Merge_Form_EmptyState")} />}
                            onChange={(v: string) => setSelectedTeamId(v)}>
                            {creator.previousSeason && creator.previousSeason.teams.length > 0 && (
                                <OptGroup label={creator.previousSeason.name}>
                                    {creator.previousSeason.teams.map(t => (
                                        <Option
                                            value={t.id}
                                            key={t.id}
                                            label={t.name}
                                            searchField={getSearchPhraseForTeam(t)}>
                                            <TeamOptionContent team={t} />
                                        </Option>
                                    ))}
                                </OptGroup>
                            )}
                            {creator.otherTeams && creator.otherTeams.length > 0 && (
                                <OptGroup label={l("CompetitionTeams_Select_OtherTeams")}>
                                    {creator.otherTeams.map(t => (
                                        <Option
                                            value={t.id}
                                            key={t.id}
                                            label={t.name}
                                            searchField={getSearchPhraseForTeam(t)}>
                                            <TeamOptionContent team={t} />
                                        </Option>
                                    ))}
                                </OptGroup>
                            )}
                        </Select>
                    </Form.Item>
                </Form>
            ) : (
                <Skeleton active loading paragraph={{ rows: 1 }} />
            )}
            {selectedTeamDetails && (
                <Collapse>
                    <Collapse.Panel
                        className={cx("team-collapse")}
                        header={`${l("CompetitionDetails_Teams_Merge_History_Team")} ${selectedTeamDetails.name}`}
                        key="1">
                        {!selectedTeamFetchInProgress ? (
                            selectedTeamDetails.history?.map((h, index) => (
                                <List
                                    key={index}
                                    header={`${l("CompetitionDetails_Teams_Merge_History_Season")} ${h.name}`}
                                    dataSource={h.competitions}
                                    renderItem={i => (
                                        <List.Item>
                                            <span className={cx("competition-item")}>{i.name}</span>
                                        </List.Item>
                                    )}
                                />
                            ))
                        ) : (
                            <Skeleton active loading paragraph={{ rows: 1 }} />
                        )}
                    </Collapse.Panel>
                </Collapse>
            )}
        </Modal>
    );
};

export default MergeTeamsFormDialog;
