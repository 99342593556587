import { Form, Input, message, Spin } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import PhoneNumberInput from "Components/PhoneNumberInput";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import { l } from "Languages";
import React, { useCallback } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";
import { isValidPhoneNumber } from "Utils/validationHelpers";

type TeamAdminFormDialogProps = {
    team: CompetitionTeam;
    onClose: () => void;
};

const TeamAdminFormDialog: React.FunctionComponent<TeamAdminFormDialogProps> = ({ team, onClose }) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const setPhoneNumberError = useCallback(
        (value: FormFields["phoneNumber"], error: string) =>
            form.setFields([
                {
                    name: guard<FormField>("phoneNumber"),
                    errors: [error],
                    value: value,
                },
            ]),
        [form],
    );

    const onFinish = useCallback(
        (values: FormFields) => {
            runInTask(async () => {
                const result = await team.addAdmin(values.countryCode, values.phoneNumber);

                result
                    .handle(["TeamIsAPlaceholder", "TeamNotFound", "failure"], () =>
                        message.error(l("CompetitionTeams_Admin_Invite_Failure")),
                    )
                    .handle("PhoneNumberInvalidFormat", () => {
                        setPhoneNumberError(values.phoneNumber, l("Common_Validation_InvalidPhoneNumber"));
                    })
                    .handle("InvitationAlreadyExists", () => {
                        setPhoneNumberError(
                            values.phoneNumber,
                            l("CompetitionTeams_Admin_Invite_InvitationAlreadyExists"),
                        );
                    })
                    .handle("PhoneNumberNullOrEmpty", () => {
                        setPhoneNumberError(values.phoneNumber, l("Common_Validation_FieldRequired"));
                    })
                    .handle("success", () => {
                        message.success(l("CompetitionTeams_Admin_Invite_Success"));

                        onClose();
                    })
                    .check();
            });
        },
        [runInTask, onClose, setPhoneNumberError, team],
    );

    return (
        <Modal
            title={l("CompetitionTeams_Admin_Invite_Form_Title")}
            onCancel={() => onClose?.()}
            okText={l("CompetitionTeams_Admin_Invite_Form_OkText")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}
            okButtonProps={{
                disabled: isRunning,
                loading: isRunning,
            }}>
            <Spin spinning={isRunning}>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        countryCode: "+48",
                    }}>
                    <Form.Item label={l("Common_Form_CountryCode")} name={guard<FormField>("countryCode")}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label={l("Common_Form_PhoneNumber")}
                        name={guard<FormField>("phoneNumber")}
                        rules={[
                            { required: true, message: l("Common_Validation_FieldRequired") },
                            {
                                message: l("Common_Validation_InvalidPhoneNumber"),
                                validator: (rule, value: FormFields["phoneNumber"] | undefined, callback) => {
                                    if (value && !isValidPhoneNumber(value)) {
                                        callback(rule.message as string);
                                    } else {
                                        callback();
                                    }
                                },
                                validateTrigger: "onBlur",
                            },
                        ]}>
                        <PhoneNumberInput />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

export type FormFields = {
    countryCode: string;
    phoneNumber: string;
};

type FormField = keyof FormFields;

export default TeamAdminFormDialog;
