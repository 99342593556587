import Competition from "Domain/Competitions/Competition";
import { action, observable } from "mobx";
import { LinkablePhase } from "../ExternalReferences/ExternalReference";
import AvailableTeamsForKnockoutPhase from "./AvailableTeamsForKnockoutPhase";
import KnockoutPhase from "./KnockoutPhase";
import KnockoutPhaseTeamsSelection from "./KnockoutPhaseTeamsSelection";

class KnockoutPhaseTeamsConfigurator {
    private readonly phase: KnockoutPhase;
    private readonly linkedPhase?: LinkablePhase;

    @observable teamsSelection?: KnockoutPhaseTeamsSelection;
    @observable availableTeams: AvailableTeamsForKnockoutPhase;

    constructor(phase: KnockoutPhase, competition: Competition, linkedPhase?: LinkablePhase) {
        if (phase.linkedPhaseId && !linkedPhase) {
            throw new Error("Previous phase needs to be available if phase is linked.");
        }

        this.phase = phase;
        this.linkedPhase = linkedPhase;

        this.availableTeams = new AvailableTeamsForKnockoutPhase(phase, competition);
    }

    @action.bound
    startAddingTeams() {
        this.teamsSelection = new KnockoutPhaseTeamsSelection(this.availableTeams, this.phase, this.linkedPhase);
    }

    @action.bound
    cancelAddingTeams() {
        this.teamsSelection = undefined;
    }

    async saveTeamsAddition() {
        if (!this.teamsSelection) {
            return;
        }

        const wasTeamsAdditionSuccessful = await this.addTeams(this.teamsSelection);
        const wasPlaceInGroupReferencesAdditionSuccessful = await this.addPlaceInGroupReferences(this.teamsSelection);
        const wasNthBestForPlaceInGroupReferencesAdditionSuccessful = await this.addNthBestForPlaceInGroupReferences(
            this.teamsSelection,
        );
        const wasPlaceInTableReferencesAdditionSuccessful = await this.addPlaceInTableReferences(this.teamsSelection);

        await this.phase.fetchDetails();

        return (
            wasPlaceInGroupReferencesAdditionSuccessful &&
            wasTeamsAdditionSuccessful &&
            wasNthBestForPlaceInGroupReferencesAdditionSuccessful &&
            wasPlaceInTableReferencesAdditionSuccessful
        );
    }

    private addTeams(selection: KnockoutPhaseTeamsSelection) {
        if (selection.teamsSelection.length === 0) return true;

        return this.phase.addTeamsToPhase(selection.teamsSelection.map(t => t.id));
    }

    private addPlaceInGroupReferences(selection: KnockoutPhaseTeamsSelection) {
        if (!selection.placeInGroupReferencesSelection || selection.placeInGroupReferencesSelection.length === 0)
            return true;

        return this.phase.addPlaceInGroupReferences(selection.placeInGroupReferencesSelection);
    }

    private addNthBestForPlaceInGroupReferences(selection: KnockoutPhaseTeamsSelection) {
        if (
            !selection.nthBestForPlaceInGroupReferencesSelection ||
            selection.nthBestForPlaceInGroupReferencesSelection.length === 0
        )
            return true;

        return this.phase.addNthBestForPlaceInGroupReferences(selection.nthBestForPlaceInGroupReferencesSelection);
    }

    private addPlaceInTableReferences(selection: KnockoutPhaseTeamsSelection) {
        if (!selection.placeInTableReferencesSelection || selection.placeInTableReferencesSelection.length === 0) {
            return true;
        }

        return this.phase.addPlaceInTableReferences(selection.placeInTableReferencesSelection);
    }
}

export default KnockoutPhaseTeamsConfigurator;
