import { handleResponse } from "@leancode/validation";
import { AddCompetition, CompetitionDetailsDTO, CompetitionDTO, HideCompetition } from "Contracts/PlayooLeagueClient";
import CompetitionGroupStore from "Domain/CompetitionGroups/CompetitionGroupStore";
import CompetitionPhaseFactory from "Domain/CompetitionPhases/CompetitionPhaseFactory";
import CompetitionPhaseStore from "Domain/CompetitionPhases/CompetitionPhaseStore";
import ExternalReferencesStore from "Domain/CompetitionPhases/ExternalReferences/ExternalReferencesStore";
import MatchFactory from "Domain/Matches/MatchFactory";
import MatchStore from "Domain/Matches/MatchStore";
import Season from "Domain/Season/Season";
import SeasonStore from "Domain/Season/SeasonStore";
import { action, computed } from "mobx";
import api from "Services/Api";
import { MapStore } from "Utils/mobx";
import newId from "Utils/newId";
import retryQuery from "Utils/retryQuery";
import Competition from "./Competition";
import CompetitionTeam from "./CompetitionTeam";
import { CompetitionTeamStore } from "./CompetitionTeamStore";

class CompetitionStore extends MapStore<Competition> {
    private readonly seasonStore: SeasonStore;
    readonly competitionPhaseStore: CompetitionPhaseStore;
    readonly competitionTeamStore: CompetitionTeamStore;
    readonly competitionGroupStore: CompetitionGroupStore;
    readonly matchStore: MatchStore;
    readonly externalReferencesStore: ExternalReferencesStore;

    constructor({
        seasonStore,
        competitionPhaseStore,
        competitionGroupStore,
        competitionTeamStore,
        matchStore,
        externalReferencesStore,
    }: {
        seasonStore: SeasonStore;
        competitionPhaseStore: CompetitionPhaseStore;
        competitionTeamStore: CompetitionTeamStore;
        competitionGroupStore: CompetitionGroupStore;
        matchStore: MatchStore;
        externalReferencesStore: ExternalReferencesStore;
    }) {
        super();

        this.seasonStore = seasonStore;
        this.competitionPhaseStore = competitionPhaseStore;
        this.competitionTeamStore = competitionTeamStore;
        this.competitionGroupStore = competitionGroupStore;
        this.matchStore = matchStore;
        this.externalReferencesStore = externalReferencesStore;
    }

    @action.bound
    putFromDTO(seasonId?: string, ...competitions: (CompetitionDTO | CompetitionDetailsDTO)[]) {
        competitions.forEach(c =>
            this.put(
                Competition.fromDTO(c, seasonId, {
                    competitionPhaseStore: this.competitionPhaseStore,
                    competitionTeamStore: this.competitionTeamStore,
                    competitionGroupStore: this.competitionGroupStore,
                    matchStore: this.matchStore,
                    externalReferencesStore: this.externalReferencesStore,
                }),
            ),
        );
    }

    async fetchCompetitionDetails(competitionId: string) {
        const competitionDetails = await retryQuery(() => api.competitionDetails({ CompetitionId: competitionId }));

        if (competitionDetails.Season) {
            this.seasonStore.put(Season.fromDTO(competitionDetails.Season));
        }

        const competition = this.getById(competitionId);

        if (competition) {
            competition.updateFromDTO(competitionDetails);
        } else {
            this.putFromDTO(competitionDetails.Season?.Id, competitionDetails);
        }

        return competition;
    }

    async fetchAllSeasonsWithCompetitions() {
        const [, competitions] = await Promise.all([
            this.seasonStore.fetchSeasons(),
            retryQuery(() => api.allCompetitions({})),
        ]);

        competitions.forEach(c => {
            const competition = this.getById(c.Id);

            if (competition) {
                competition.updateFromDTO(c);
            } else {
                this.putFromDTO(c.SeasonId ?? undefined, c);
            }
        });
    }

    async fetchCompetitionPhaseDetails(phaseId: string) {
        const phaseDetails = await retryQuery(() => api.phaseDetails({ PhaseId: phaseId }));

        if (phaseDetails.Season) {
            this.seasonStore.put(Season.fromDTO(phaseDetails.Season));
        }

        this.competitionPhaseStore.put(
            CompetitionPhaseFactory.fromDTO(phaseDetails, phaseDetails.Competition.Id, {
                competitionTeamStore: this.competitionTeamStore,
                matchStore: this.matchStore,
                externalReferencesStore: this.externalReferencesStore,
            }),
        );

        const competition = this.getById(phaseDetails.Competition.Id);

        if (!competition) {
            await this.fetchCompetitionDetails(phaseDetails.Competition.Id);
        }
    }

    async fetchMatchDetails(matchId: string) {
        const dto = await retryQuery(() => api.matchDetails({ MatchId: matchId }));

        const match = MatchFactory.fromDTO(dto, this.competitionTeamStore);

        if (match) {
            this.matchStore.put(match);

            const phase = this.competitionPhaseStore.getById(dto.Phase.Id);

            if (phase) {
                phase.updateFromDTO(dto.Phase);
            } else {
                this.competitionPhaseStore.put(
                    CompetitionPhaseFactory.fromDTO(dto.Phase, dto.Competition.Id, {
                        competitionTeamStore: this.competitionTeamStore,
                        matchStore: this.matchStore,
                        externalReferencesStore: this.externalReferencesStore,
                    }),
                );
            }

            const competition = this.getById(dto.Competition.Id);

            if (!competition) {
                await this.fetchCompetitionDetails(dto.Competition.Id);
            }
        }

        return match;
    }

    async fetchCompetitionTeamDetails(teamId: string) {
        const teamDetails = await retryQuery(() => api.teamDetails({ TeamId: teamId }));

        if (teamDetails.Season) {
            this.seasonStore.put(Season.fromDTO(teamDetails.Season));
        }

        this.competitionTeamStore.put(
            CompetitionTeam.fromDTO(teamDetails, teamDetails.Competition.Id, this.externalReferencesStore),
        );

        const competition = this.getById(teamDetails.Competition.Id);

        if (!competition) {
            this.putFromDTO(teamDetails.Season?.Id, teamDetails.Competition);
        }
    }

    async fetchCompetitionTeamPlayerWithPictures(teamId: string, userId: string) {
        const teamRef = this.competitionTeamStore.getItemRef(teamId);

        if (!teamRef.current) {
            await this.fetchCompetitionTeamDetails(teamId);
        }

        await teamRef.current?.players?.find(p => p.id === userId)?.fetchPictures();
    }

    async addCompetition(data: CreateCompetitionData) {
        const response = await api.addCompetition({
            CompetitionId: newId(),
            Name: data.name,
            SeasonId: data.seasonId,
            GroupId: data.groupId,
        });

        return handleResponse(response, AddCompetition);
    }

    async hideCompetition(competitionId: string) {
        const competition = this.getById(competitionId);

        if (!competition) {
            throw new Error("Competition not found.");
        }

        const response = await api.hideCompetition({
            CompetitionId: competitionId,
        });

        handleResponse(response, HideCompetition).handle("success", () => {
            this.remove(competition);
        });

        return handleResponse(response, HideCompetition);
    }

    @computed get seasonsWithCompetitions(): { season?: Season; competitions: Competition[] }[] {
        const allCompetitions = Array.from(this.items.values());

        return [...this.seasonStore.seasons, undefined].map(s => ({
            season: s,
            competitions: allCompetitions.filter(c => c.seasonId === s?.id),
        }));
    }
}

type CreateCompetitionData = {
    name: string;
    seasonId?: string;
    groupId?: string;
};

export default CompetitionStore;
