import { handleResponse } from "@leancode/validation";
import { message } from "antd";
import {
    AccountStateDTO,
    AddProfilePicture,
    InvitePlayerToProfile,
    MergeProfiles,
    SelectProfilePicture,
    TeamPlayerDTO,
    UpdatePlayer,
} from "Contracts/PlayooLeagueClient";
import { l } from "Languages";
import { observable, runInAction } from "mobx";
import api from "Services/Api";
import blobStorageClient from "Services/BlobStorageClient";
import extractExtension from "Utils/extractExtension";
import newId from "Utils/newId";
import retryQuery from "Utils/retryQuery";
import Invitation from "./Invitation";

class CompetitionTeamPlayer {
    readonly id: string;
    readonly teamId: string;

    @observable firstName: string;
    @observable lastName: string;
    @observable pictureUri?: string;
    @observable shirtNumber?: number;
    @observable accountState: AccountStateDTO;

    @observable pendingProfileInvitation?: Invitation;

    @observable pictures?: string[];

    constructor(
        id: string,
        teamId: string,
        {
            firstName,
            lastName,
            shirtNumber,
            accountState,
            pendingProfileInvitation,
            pictureUri,
        }: CompetitionTeamPlayerInit,
    ) {
        this.id = id;
        this.teamId = teamId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.pictureUri = pictureUri;
        this.shirtNumber = shirtNumber;
        this.accountState = accountState;
        this.pendingProfileInvitation = pendingProfileInvitation;
    }

    static fromDTO(dto: TeamPlayerDTO, teamId: string): CompetitionTeamPlayer {
        return new CompetitionTeamPlayer(dto.Id, teamId, {
            firstName: dto.FirstName,
            lastName: dto.LastName,
            pictureUri: dto.PictureUri ?? undefined,
            shirtNumber: dto.ShirtNumber ?? undefined,
            accountState: dto.AccountState,
            pendingProfileInvitation: dto.PendingProfileInvitation
                ? Invitation.fromDTO(dto.PendingProfileInvitation)
                : undefined,
        });
    }

    async fetchPictures() {
        const pictures = await retryQuery(() =>
            api.userProfilePictures({
                UserId: this.id,
            }),
        );

        runInAction(() => {
            this.pictures = pictures;
        });
    }

    async addPicture(file: File) {
        const extension = extractExtension(file);
        const blobName = `${newId()}.${extension}`;

        const messageKey = `add-profile-picture-${blobName}`;
        message.loading({
            content: l("CompetitionTeamPlayers_ProfilePictures_Add_InProgress"),
            duration: 0,
            key: messageKey,
        });

        const displayErrorMessage = () =>
            message.error({
                content: l("CompetitionTeamPlayers_ProfilePictures_Add_Failure"),
                key: messageKey,
            });

        try {
            const token = await retryQuery(() =>
                api.profilePicturesCreateToken({
                    UserId: this.id,
                }),
            );

            await blobStorageClient.uploadBlob(blobName, file, token);

            const pictureUri = blobStorageClient.blobUriFromToken(token, blobName);

            const response = await api.addProfilePicture({
                UserId: this.id,
                PictureUri: pictureUri,
            });

            handleResponse(response, AddProfilePicture)
                .handle(["success"], () => {
                    message.success({
                        content: l("CompetitionTeamPlayers_ProfilePictures_Add_Success"),
                        key: messageKey,
                    });

                    runInAction(() => {
                        this.pictures = [pictureUri, ...(this.pictures ?? [])];
                        this.pictureUri = pictureUri;
                    });
                })
                .handle(
                    [
                        "PictureAlreadyAdded",
                        "PictureUriNotValid",
                        "PictureUriNullOrEmpty",
                        "PictureUriTooLong",
                        "UserProfileNotFound",
                        "failure",
                    ],
                    () => displayErrorMessage(),
                )
                .check();
        } catch (e) {
            displayErrorMessage();
        }
    }

    async selectPicture(pictureUri: string | undefined) {
        if (pictureUri && !this.pictures?.some(p => p === pictureUri)) {
            throw new Error("Picture to select not found.");
        }

        const response = await api.selectProfilePicture({
            UserId: this.id,
            PictureUri: pictureUri,
        });

        handleResponse(response, SelectProfilePicture)
            .handle(["PictureNotFound", "UserProfileNotFound", "failure"], () => {
                message.error(l("CompetitionTeamPlayers_ProfilePictures_Select_Failure"));
            })
            .handle("success", () => {
                runInAction(() => {
                    this.pictureUri = pictureUri;
                });
            });
    }

    async edit({ firstName, lastName, shirtNumber }: EditPlayerData) {
        const response = await api.updatePlayer({
            PlayerId: this.id,
            TeamId: this.teamId,
            FirstName: firstName,
            LastName: lastName,
            ShirtNumber: shirtNumber,
        });

        handleResponse(response, UpdatePlayer).handle("success", () => {
            runInAction(() => {
                this.firstName = firstName;
                this.lastName = lastName;
                this.shirtNumber = shirtNumber;
            });
        });

        return handleResponse(response, UpdatePlayer);
    }

    async inviteToProfile(countryCode: string, phoneNumber: string) {
        const response = await api.invitePlayerToProfile({
            UserId: this.id,
            TeamId: this.teamId,
            PhoneNumber: countryCode + phoneNumber,
        });

        handleResponse(response, InvitePlayerToProfile).handle("success", () => {
            runInAction(() => {
                this.accountState = AccountStateDTO.Invited;
            });
        });

        return handleResponse(response, InvitePlayerToProfile);
    }

    async cancelProfileInvitation() {
        const result = await this.pendingProfileInvitation?.cancel();

        result
            ?.handle(["InvitationNotFound", "failure"], () =>
                message.error(l("CompetitionTeamPlayers_CancelInvitation_Failure")),
            )
            .handle("InvitationNotPending", () =>
                message.error(l("CompetitionTeamPlayers_CancelInvitation_InvitationNotPending")),
            )
            .handle("success", () => {
                runInAction(() => {
                    this.pendingProfileInvitation = undefined;
                    this.accountState = AccountStateDTO.Unlinked;
                });

                message.success(l("CompetitionTeamPlayers_CancelInvitation_Success"));
            })
            .check();

        return result;
    }

    async mergeProfile(profileId: string) {
        const response = await api.mergeProfiles({
            User1Id: profileId,
            User2Id: this.id,
        });

        return handleResponse(response, MergeProfiles);
    }
}

export type EditPlayerData = {
    firstName: string;
    lastName: string;
    shirtNumber?: number;
};

export type CompetitionTeamPlayerInit = Pick<
    CompetitionTeamPlayer,
    "firstName" | "lastName" | "shirtNumber" | "accountState" | "pendingProfileInvitation" | "pictureUri"
>;

export default CompetitionTeamPlayer;
