import { PlusOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Button } from "antd";
import { l } from "Languages";
import React from "react";
import styles from "./styles.scss";

const cx = mkCx(styles);

type ExtendBoardColumnProps = {
    onAddNewColumn: () => void;
};

const ExtendBoardColumn: React.FunctionComponent<ExtendBoardColumnProps> = ({ onAddNewColumn }) => {
    return (
        <div className={cx("extend-board-column")}>
            <Button onClick={onAddNewColumn} type="dashed" className={cx("full-width-button")}>
                <PlusOutlined /> {l("Common_Add")}
            </Button>
            <Button onClick={onAddNewColumn} type="dashed">
                <PlusOutlined />
            </Button>
            <Button onClick={onAddNewColumn} type="dashed" className={cx("full-width-button")}>
                <PlusOutlined /> {l("Common_Add")}
            </Button>
        </div>
    );
};

export default ExtendBoardColumn;
