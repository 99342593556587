import competitionGroupStore from "Domain/CompetitionGroups";
import competitionPhaseStore from "Domain/CompetitionPhases";
import externalReferencesStore from "Domain/CompetitionPhases/ExternalReferences";
import matchStore from "Domain/Matches";
import seasonStore from "Domain/Season";
import CompetitionStore from "./CompetitionStore";
import competitionTeamStore from "./CompetitionTeamStore";

const competitionStore = new CompetitionStore({
    seasonStore,
    competitionPhaseStore,
    competitionTeamStore,
    competitionGroupStore,
    matchStore,
    externalReferencesStore,
});

export default competitionStore;
