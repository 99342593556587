import { action, computed, observable } from "mobx";

class SlotsConfiguration {
    @observable matchDurationInMinutes?: number;
    @observable pauseBetweenMatchesInMinutes?: number;

    constructor(filters: FiltersDescription) {
        this.update(filters);
    }

    @action.bound
    update({ matchDurationInMinutes, pauseBetweenMatchesInMinutes }: FiltersDescription) {
        this.matchDurationInMinutes = matchDurationInMinutes;
        this.pauseBetweenMatchesInMinutes = pauseBetweenMatchesInMinutes;
    }

    @computed get isConfigured() {
        return this.matchDurationInMinutes !== undefined && this.pauseBetweenMatchesInMinutes !== undefined;
    }

    @computed get distanceBetweenMatchesInMinutes() {
        if (this.matchDurationInMinutes === undefined || this.pauseBetweenMatchesInMinutes === undefined) {
            return undefined;
        }

        return this.matchDurationInMinutes + this.pauseBetweenMatchesInMinutes;
    }
}

export type FiltersDescription = Pick<SlotsConfiguration, "matchDurationInMinutes" | "pauseBetweenMatchesInMinutes">;

export default SlotsConfiguration;
