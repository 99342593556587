import cmsApi from "Services/CmsApi";
import { ArrayStore } from "Utils/mobx";
import retryQuery from "Utils/retryQuery";
import Banner from "./Banner";

class BannersStore extends ArrayStore<Banner> {
    async fetchBanners() {
        const banners = await retryQuery(() => cmsApi.allBanners({}));

        return this.set(banners.map(Banner.fromDTO));
    }
}

export default BannersStore;
