import { Editor } from "@tinymce/tinymce-react";
import { Button, Col, DatePicker, Input, Row, Switch } from "antd";
import Form from "antd/lib/form";
import { useForm } from "antd/lib/form/util";
import TextArea from "antd/lib/input/TextArea";
import { Select } from "Components/Select";
import Spacing from "Components/Spacing";
import TinyMceEditor from "Components/TinyMceEditor";
import Article from "Domain/Articles/Article";
import TinyDriveTokenStore from "Domain/Articles/TinyDriveTokenStore";
import CompetitionsAutocomplete from "DomainComponents/CompetitionsAutocomplete";
import { l } from "Languages";
import { Moment } from "moment";
import React, { useCallback, useMemo, useRef, useState } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";
import ThumbnailSelect from "./ThumbnailSelect";

const autogeneratedSummaryLength = 100;

export type ArticleEditorValidationErrors = Partial<Record<FormField, string>>;

type ArticleEditorProps = {
    initialValues?: Partial<FormFields>;
    tagsFromOtherArticles: string[];
    mode: "edit" | "create";

    onCancel: () => void;
    onSave: (fields: FormFields) => Promise<ArticleEditorValidationErrors | undefined>;
};

const ArticleEditor: React.FunctionComponent<ArticleEditorProps> = ({
    initialValues,
    tagsFromOtherArticles,
    mode,
    onCancel,
    onSave,
}) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();
    const [isSummaryFieldTouched, setIsSummaryFieldTouched] = useState(false);
    const [newTags, setNewTags] = useState<string[]>([]);
    const [searchPhrase, setSearchPhrase] = useState("");

    const [articlePhotos, setArticlePhotos] = useState<string[]>([]);
    const [thumbnailPhotos, setThumbnailPhotos] = useState<string[]>(
        initialValues?.thumbnailPhotoUri ? [initialValues.thumbnailPhotoUri] : [],
    );

    const thumbnailPhotosToChoose = useMemo(() => _.uniq([...thumbnailPhotos, ...articlePhotos]), [
        thumbnailPhotos,
        articlePhotos,
    ]);

    const synchronizeArticlePhotosFromContent = useCallback(
        (editorDocument: Document) => {
            const photos = Array.from(editorDocument.getElementsByTagName("img")).map(i => i.src);
            setArticlePhotos(photos);

            const thumbnailPhotoUri = form.getFieldValue(guard<FormField>("thumbnailPhotoUri"));
            const isThumbnailPhotoStillOnList =
                thumbnailPhotos.some(uri => uri === thumbnailPhotoUri) || photos.some(uri => uri === thumbnailPhotoUri);

            if (!isThumbnailPhotoStillOnList) {
                form.setFieldsValue({
                    [guard<FormField>("thumbnailPhotoUri")]: undefined,
                });
            }
        },
        [form, thumbnailPhotos],
    );

    const onEditorContentChange = useCallback(
        (
            _,
            editor: {
                getContent: (options?: { format: "text" | "html" }) => string;
                contentDocument: Document;
            },
        ) => {
            if (!isSummaryFieldTouched && mode === "create") {
                const asText = editor.getContent({ format: "text" });

                form.setFieldsValue({
                    [guard<FormField>("summary")]: asText.substring(0, autogeneratedSummaryLength),
                });
            }

            synchronizeArticlePhotosFromContent(editor.contentDocument);
        },
        [form, isSummaryFieldTouched, mode, synchronizeArticlePhotosFromContent],
    );

    const onEditorInit = useCallback(
        (
            _,
            editor: {
                contentDocument: Document;
            },
        ) => {
            synchronizeArticlePhotosFromContent(editor.contentDocument);
        },
        [synchronizeArticlePhotosFromContent],
    );

    const onFinish = useCallback(
        (values: FormFields) =>
            runInTask(async () => {
                const validationErrors = await onSave(values);

                for (const field in validationErrors) {
                    form.setFields([
                        {
                            name: field,
                            errors: [validationErrors[field]],
                            value: values[field],
                        },
                    ]);
                }
            }),
        [onSave, form, runInTask],
    );

    const onCreateNewTag = useCallback(() => {
        const selectedTags: string[] | undefined = form.getFieldValue(guard<FormField>("tags"));

        form.setFieldsValue({
            [guard<FormField>("tags")]: [...(selectedTags || []), searchPhrase],
        });

        setNewTags([...newTags, searchPhrase]);
        setSearchPhrase("");
    }, [form, setNewTags, newTags, setSearchPhrase, searchPhrase]);

    const synchronizeNewsTag = useCallback(
        (changedValues: Partial<FormFields>, values: FormFields) => {
            if (changedValues.isNews !== undefined) {
                if (changedValues.isNews) {
                    form.setFieldsValue({
                        [guard<FormField>("tags")]: [Article.newsTag, ...(values.tags || [])],
                    });
                } else if (values.tags && values.tags.some(t => t === Article.newsTag)) {
                    form.setFieldsValue({
                        [guard<FormField>("tags")]: values.tags.filter(t => t !== Article.newsTag),
                    });
                }
            }

            if (changedValues.tags !== undefined) {
                if (changedValues.tags.some(t => t === Article.newsTag) && !values.isNews) {
                    form.setFieldsValue({
                        [guard<FormField>("isNews")]: true,
                    });
                }

                if (!changedValues.tags.some(t => t === Article.newsTag) && values.isNews) {
                    form.setFieldsValue({
                        [guard<FormField>("isNews")]: false,
                    });
                }
            }
        },
        [form],
    );

    const allTags = [...tagsFromOtherArticles, ...newTags];
    const isTagAlreadyOnList = allTags.some(t => t === searchPhrase);

    const editorRef = useRef<Editor | null>(null);

    return (
        <Form
            form={form}
            onFinish={onFinish}
            initialValues={initialValues}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onValuesChange={synchronizeNewsTag}>
            <Row gutter={16} align="bottom">
                <Col span={20}>
                    <Form.Item
                        name={guard<FormField>("title")}
                        label={l("Articles_Editor_Title")}
                        rules={[
                            { required: true, message: l("Common_Validation_FieldRequired") },
                            { max: 250, message: l("Common_Validation_FieldTooLong") },
                        ]}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name={guard<FormField>("isNews")}
                        label={l("Articles_Editor_IsNews")}
                        valuePropName="checked"
                        labelCol={{ span: 12 }}
                        wrapperCol={{ span: 12 }}>
                        <Switch
                            checkedChildren={l("Articles_Editor_IsNews_Checked")}
                            unCheckedChildren={l("Articles_Editor_IsNews_Unchecked")}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                name={guard<FormField>("content")}
                label={l("Articles_Editor_Content")}
                rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}
                trigger="onEditorChange">
                <TinyMceEditor ref={editorRef} onEditorChange={onEditorContentChange} onInit={onEditorInit} />
            </Form.Item>
            <Form.Item
                name={guard<FormField>("thumbnailPhotoUri")}
                label={l("Articles_Editor_Thumbnail")}
                valuePropName="selectedPhoto">
                <ThumbnailSelect
                    onUpload={async file => {
                        if (editorRef && editorRef.current) {
                            const result = await (editorRef.current as any).editor.plugins.tinydrive.upload({
                                path: TinyDriveTokenStore.thumbnailUploadPath,
                                name: file.name,
                                blob: file,
                            });

                            const uri = result.file.url;

                            setThumbnailPhotos([uri, ...thumbnailPhotos]);

                            form.setFieldsValue({
                                [guard<FormField>("thumbnailPhotoUri")]: uri,
                            });
                        }
                    }}
                    photos={thumbnailPhotosToChoose}
                />
            </Form.Item>
            <Form.Item
                name={guard<FormField>("summary")}
                label={l("Articles_Editor_Summary")}
                rules={[
                    { required: true, message: l("Common_Validation_FieldRequired") },
                    { max: 4000, message: l("Common_Validation_FieldTooLong") },
                ]}>
                <TextArea onChange={() => setIsSummaryFieldTouched(true)} />
            </Form.Item>
            <Form.Item name={guard<FormField>("tags")} label={l("Articles_Editor_Tags")}>
                <Select
                    createNewItemLabel={l("Articles_Editor_AddTag")}
                    showArrow
                    allowClear
                    onSearch={phrase => setSearchPhrase(phrase)}
                    onChange={() => setSearchPhrase("")}
                    searchValue={searchPhrase}
                    onCreate={searchPhrase.length > 0 && !isTagAlreadyOnList ? onCreateNewTag : undefined}
                    mode="multiple"
                    selectOptions={allTags.map(t => ({ value: t, key: t, label: t }))}
                />
            </Form.Item>
            <Form.Item name={guard<FormField>("competitionId")} label={l("Articles_Editor_Competitions")}>
                <CompetitionsAutocomplete />
            </Form.Item>
            <Form.Item label={l("Articles_DatePublished")} name={guard<FormField>("datePublished")}>
                <DatePicker
                    allowClear={false}
                    disabled={mode === "create" || !initialValues?.datePublished}
                    showTime={true}
                />
            </Form.Item>
            <Form.Item>
                <Spacing childrenGutterX>
                    <Button onClick={onCancel}>{l("Common_Cancel")}</Button>
                    <Button type="primary" htmlType="submit" disabled={isRunning} loading={isRunning}>
                        {mode === "create" ? l("Articles_Editor_SaveDraft") : l("Common_Save")}
                    </Button>
                </Spacing>
            </Form.Item>
        </Form>
    );
};

export type FormFields = {
    isNews: boolean;
    title: string;
    content: string;
    summary: string;
    tags?: string[];
    thumbnailPhotoUri?: string;
    datePublished?: Moment;
    competitionId?: string;
};

type FormField = keyof FormFields;

export default ArticleEditor;
