import { ManagerUserDTO } from "Contracts/PlayooLeagueClient";

class ManagerUser {
    readonly id: string;
    readonly email: string;

    constructor(id: string, email: string) {
        this.id = id;
        this.email = email;
    }

    static fromDTO(dto: ManagerUserDTO) {
        return new ManagerUser(dto.Id, dto.EmailAddress);
    }
}

export default ManagerUser;
