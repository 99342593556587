import _ from "lodash";
import { action, computed, observable } from "mobx";
import MathHelpers from "Utils/MathHelpers";
import KnockoutPhase from "./KnockoutPhase";
import KnockoutPhaseMatchConfiguration, {
    KnockoutPhaseMatchConfigurationState,
} from "./KnockoutPhaseMatchConfiguration";

export enum ValidationErrors {
    ConfiguredMatchesCountNotAPowerOfTwo,
    OneOfMatchesMisconfigured,
}

class KnockoutPhaseConfigurator {
    private readonly phase: KnockoutPhase;

    @observable matches: KnockoutPhaseMatchConfiguration[];
    readonly maxConsecutivePlaceMatches;
    @observable consecutiveNthPlaceMatches: number;

    constructor(phase: KnockoutPhase) {
        this.phase = phase;

        if (!this.phase.teams) {
            throw new Error("Cannot configure uninitialized phase.");
        }

        const matchesCount = MathHelpers.lowerPowerOfTwo(this.phase.teams.length) / 2;
        this.matches = _.times(
            matchesCount,
            () => new KnockoutPhaseMatchConfiguration(this.phase.competitionTeamStore),
        );
        this.maxConsecutivePlaceMatches = KnockoutPhase.getMaxValidNthPlaceMatchForFirstStageMatches(matchesCount);
        this.consecutiveNthPlaceMatches = this.maxConsecutivePlaceMatches;
    }

    @computed private get assignedTeamsMap(): Map<string, number> {
        return new Map(this.matches.flatMap((m, ind) => m.teamIds.map(id => [id, ind])));
    }

    @computed get unassignedTeams() {
        return this.phase.teams?.filter(t => !this.assignedTeamsMap.has(t.id)) ?? [];
    }

    @computed get configuredMatchesCount() {
        return this.matches.filter(m => m.configurationState === KnockoutPhaseMatchConfigurationState.Configured)
            .length;
    }

    @computed get validationError(): ValidationErrors | undefined {
        if (
            this.matches.some(
                m =>
                    m.configurationState === KnockoutPhaseMatchConfigurationState.DuplicateTeams ||
                    m.configurationState === KnockoutPhaseMatchConfigurationState.InvalidTeamsCount,
            )
        ) {
            return ValidationErrors.OneOfMatchesMisconfigured;
        }

        if (!MathHelpers.isPowerOfTwo(this.configuredMatchesCount)) {
            return ValidationErrors.ConfiguredMatchesCountNotAPowerOfTwo;
        }
    }

    @computed get canAddMoreNthPlaceMatches() {
        return (
            this.validationError === undefined && this.consecutiveNthPlaceMatches + 1 < this.maxConsecutivePlaceMatches
        );
    }

    @action.bound
    moveTeam(teamId: string, destination: { matchIndex?: number; index: number }) {
        const sourceMatchIndex = this.assignedTeamsMap.get(teamId);

        const sourceMatch = sourceMatchIndex !== undefined ? this.matches[sourceMatchIndex] : undefined;
        const destinationMatch =
            destination.matchIndex !== undefined ? this.matches[destination.matchIndex] : undefined;

        if (sourceMatch) {
            sourceMatch.setTeams(sourceMatch.teamIds.filter(t => t !== teamId));
        }

        if (destinationMatch) {
            const destinationMatchTeamIds = [...destinationMatch.teamIds];
            destinationMatchTeamIds.splice(destination.index, 0, teamId);

            destinationMatch.setTeams(destinationMatchTeamIds);
        }
    }

    @action.bound
    incrementNthPlaceMatches() {
        if (this.consecutiveNthPlaceMatches < this.maxConsecutivePlaceMatches) this.consecutiveNthPlaceMatches++;
    }

    @action.bound
    decrementNthPlaceMatches() {
        if (this.consecutiveNthPlaceMatches > 0) this.consecutiveNthPlaceMatches--;
    }

    generateSchedule() {
        return this.phase.generateSchedule(
            this.matches.filter(m => m.configurationState === KnockoutPhaseMatchConfigurationState.Configured),
            this.consecutiveNthPlaceMatches,
        );
    }
}

export default KnockoutPhaseConfigurator;
