import { handleResponse } from "@leancode/validation";
import { AddCompetitionGroup } from "Contracts/PlayooLeagueClient";
import api from "Services/Api";
import { ArrayStore } from "Utils/mobx";
import newId from "Utils/newId";
import retryQuery from "Utils/retryQuery";
import CompetitionGroup from "./CompetitionGroup";

class CompetitionGroupStore extends ArrayStore<CompetitionGroup> {
    async fetchCompetitionGroups() {
        const competitionGroups = await retryQuery(() => api.allCompetitionGroups({}));

        this.set(competitionGroups.map(CompetitionGroup.fromDTO));
    }

    async addCompetitionGroup(name: string) {
        const id = newId();

        const response = await api.addCompetitionGroup({
            GroupId: id,
            Name: name,
        });

        handleResponse(response, AddCompetitionGroup).handle("success", () => {
            this.add(new CompetitionGroup(id, name));
        });

        return handleResponse(response, AddCompetitionGroup);
    }
}

export default CompetitionGroupStore;
