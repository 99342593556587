import { handleResponse } from "@leancode/validation";
import { message } from "antd";
import {
    CompetitionTeamDetailsDTO,
    CompetitionTeamDTO,
    InviteTeamAdmin,
    MergeTeam,
    RemoveAdminFromTeam,
    RemovePlayerFromTeam,
    UpdateTeamLogo,
    UpdateTeamPhoto,
} from "Contracts/PlayooLeagueClient";
import ExternalReferencesStore from "Domain/CompetitionPhases/ExternalReferences/ExternalReferencesStore";
import HistorySeason from "Domain/Season/HistorySeason";
import { l } from "Languages";
import { action, computed, observable, runInAction } from "mobx";
import api from "Services/Api";
import blobStorageClient from "Services/BlobStorageClient";
import extractExtension from "Utils/extractExtension";
import newId from "Utils/newId";
import retryQuery from "Utils/retryQuery";
import CompetitionTeamPlayer from "./CompetitionTeamPlayer";
import Invitation from "./Invitation";
import UserProfile from "./UserProfile";

class CompetitionTeam {
    private readonly externalReferencesStore: ExternalReferencesStore;

    readonly id: string;
    readonly competitionId: string;

    @observable sharedId?: string;
    @observable phaseId?: string;
    @observable externalReferenceId?: string;

    @observable name: string;
    @observable shortName?: string;
    @observable logo?: string;
    @observable photoUri?: string;
    @observable ageGroup?: string;
    @observable players?: CompetitionTeamPlayer[];
    @observable adminInvitations?: Invitation[];
    @observable admins?: UserProfile[];
    @observable history?: HistorySeason[];

    constructor(
        init: CompetitionTeamInit,
        { externalReferencesStore }: { externalReferencesStore: ExternalReferencesStore },
    ) {
        this.id = init.id;
        this.sharedId = init.sharedId;
        this.competitionId = init.competitionId;
        this.phaseId = init.phaseId;
        this.name = init.name;
        this.shortName = init.shortName;
        this.logo = init.logo;
        this.ageGroup = init.ageGroup;
        this.players = init.players;
        this.photoUri = init.photoUri;
        this.externalReferenceId = init.externalReferenceId;

        this.externalReferencesStore = externalReferencesStore;
    }

    @computed get externalReference() {
        return this.externalReferenceId ? this.externalReferencesStore.getById(this.externalReferenceId) : undefined;
    }

    @computed get isPlaceholder() {
        return this.sharedId === undefined;
    }

    @computed get isPlaceholderForWholeCompetition() {
        return this.isPlaceholder && this.phaseId === undefined;
    }

    @computed get isPlaceholderForSinglePhaseOnly() {
        return this.isPlaceholder && this.phaseId !== undefined;
    }

    @computed get isPlaceholderForExternalReference() {
        return this.isPlaceholderForSinglePhaseOnly && this.externalReferenceId !== undefined;
    }

    @computed get isNonReferenceRelatedPlaceholderForPhase() {
        return this.isPlaceholderForSinglePhaseOnly && !this.isPlaceholderForExternalReference;
    }

    @computed get displayName() {
        if (this.externalReference) {
            return this.externalReference.displayName;
        }

        return this.isPlaceholderForWholeCompetition
            ? `${l("CompetitionTeams_PlaceholderTeam")} ${this.name}`
            : this.name;
    }

    static createPlaceholderTeamForExternalReference(
        {
            placeholderId,
            referenceId,
            phaseId,
            competitionId,
        }: {
            placeholderId: string;
            referenceId: string;
            phaseId: string;
            competitionId: string;
        },
        externalReferencesStore: ExternalReferencesStore,
    ) {
        return new CompetitionTeam(
            {
                id: placeholderId,
                competitionId,
                name: "",
                sharedId: undefined,
                phaseId: phaseId,
                externalReferenceId: referenceId,
            },
            { externalReferencesStore },
        );
    }

    static createPlaceholderTeamForPhase(
        {
            placeholderId,
            name,
            phaseId,
            competitionId,
        }: {
            placeholderId: string;
            name: string;
            phaseId: string;
            competitionId: string;
        },
        externalReferencesStore: ExternalReferencesStore,
    ) {
        return new CompetitionTeam(
            {
                id: placeholderId,
                competitionId,
                name: name,
                sharedId: undefined,
                phaseId: phaseId,
                externalReferenceId: undefined,
            },
            { externalReferencesStore },
        );
    }

    @action.bound
    rename(name: string) {
        this.name = name;
    }

    @action.bound
    updateFromDTO(dto: CompetitionTeamDTO | CompetitionTeamDetailsDTO) {
        this.sharedId = dto.SharedId ?? undefined;
        this.name = dto.Name;
        this.sharedId = dto.SharedId ?? undefined;
        this.shortName = dto.ShortName ?? undefined;
        this.logo = dto.LogoUri ?? undefined;
        this.ageGroup = dto.AgeGroup ?? undefined;
        this.phaseId = dto.PhaseId ?? undefined;
        this.externalReferenceId = dto.ExternalReferenceId ?? undefined;

        if ("Players" in dto) {
            this.photoUri = dto.PhotoUri ?? undefined;
            this.players = dto.Players.map(p => CompetitionTeamPlayer.fromDTO(p, this.id));
            this.adminInvitations = dto.PendingTeamAdminInvitations.map(Invitation.fromDTO);
            this.admins = dto.Admins.map(UserProfile.fromDTO);
            this.history = dto.History.map(HistorySeason.fromDTO);
        }

        return this;
    }

    async fetchDetails() {
        const teamDetails = await retryQuery(() => api.teamDetails({ TeamId: this.id }));

        this.updateFromDTO(teamDetails);
    }

    static fromDTO(
        dto: CompetitionTeamDTO | CompetitionTeamDetailsDTO,
        competitionId: string,
        externalReferencesStore: ExternalReferencesStore,
    ): CompetitionTeam {
        return new CompetitionTeam(
            {
                id: dto.Id,
                competitionId: competitionId,
                name: dto.Name,
            },
            { externalReferencesStore: externalReferencesStore },
        ).updateFromDTO(dto);
    }

    async edit({ name, shortName, ageGroup }: EditTeamData) {
        const response = await api.updateTeam({
            TeamId: this.id,
            Name: name,
            ShortName: shortName,
            AgeGroup: ageGroup,
        });

        if (response.isSuccess && response.result.WasSuccessful) {
            runInAction(() => {
                this.name = name;
                this.shortName = shortName;
                this.ageGroup = ageGroup;
            });
        }

        return response;
    }

    async updateLogo(file: File) {
        const extension = extractExtension(file);
        const blobName = `${this.sharedId}/${newId()}.${extension}`;

        const messageKey = `logo-update-${this.id}`;
        message.loading({
            content: l("CompetitionTeams_UpdateLogo_InProgress", this.displayName),
            duration: 0,
            key: messageKey,
        });

        const displayErrorMessage = () =>
            message.error({
                content: l("CompetitionTeams_UpdateLogo_Failed", this.displayName),
                key: messageKey,
            });

        try {
            const token = await retryQuery(() => api.teamLogosCreateToken({}));

            await blobStorageClient.uploadBlob(blobName, file, token);

            const logoUri = blobStorageClient.blobUriFromToken(token, blobName);

            const response = await api.updateTeamLogo({
                TeamId: this.id,
                LogoUri: logoUri,
            });

            handleResponse(response, UpdateTeamLogo)
                .handle(["success"], () => {
                    message.success({
                        content: l("CompetitionTeams_UpdateLogo_Success", this.displayName),
                        key: messageKey,
                    });

                    runInAction(() => {
                        this.logo = logoUri;
                    });
                })
                .handle(["LogoUriTooLong", "TeamIsAPlaceholder", "TeamNotFound", "failure"], () =>
                    displayErrorMessage(),
                );
        } catch (e) {
            displayErrorMessage();
        }
    }

    async addNewPlayer({
        firstName,
        lastName,
        shirtNumber,
    }: {
        firstName: string;
        lastName: string;
        shirtNumber?: number;
    }) {
        const response = await api.addNewPlayerToTeam({
            TeamId: this.id,
            FirstName: firstName,
            LastName: lastName,
            ShirtNumber: shirtNumber,
        });

        return response;
    }

    async addExistingPlayers(playerIds: string[]) {
        const response = await api.addExistingPlayersToTeam({
            TeamId: this.id,
            UserIds: playerIds,
        });

        return response;
    }

    async removePlayer(playerId: string) {
        const response = await api.removePlayerFromTeam({
            TeamId: this.id,
            UserId: playerId,
        });

        handleResponse(response, RemovePlayerFromTeam)
            .handle(["TeamNotFound", "UserNotFound", "failure"], () => {
                message.error(l("CompetitionTeams_RemovePlayer_Failure"));
            })
            .handle(["success"], () => {
                runInAction(() => {
                    this.players = this.players?.filter(p => p.id !== playerId);
                });

                message.success(l("CompetitionTeams_RemovePlayer_Success"));
            })
            .check();

        return handleResponse(response, RemovePlayerFromTeam);
    }

    async addAdmin(countryCode: string, phoneNumber: string) {
        const response = await api.inviteTeamAdmin({
            TeamId: this.id,
            PhoneNumber: countryCode + phoneNumber,
        });

        if (response.isSuccess && response.result.WasSuccessful) {
            await this.fetchDetails();
        }

        return handleResponse(response, InviteTeamAdmin);
    }

    async cancelAdminInvitation(invitationId: string) {
        const invitation = this.adminInvitations?.find(i => i.id === invitationId);

        const result = await invitation?.cancel();

        result
            ?.handle("success", () => {
                message.success(l("CompetitionTeams_Admin_CancelInvitation_Success"));

                runInAction(() => {
                    this.adminInvitations = this.adminInvitations?.filter(i => i.id !== invitationId);
                });
            })
            .handle(["InvitationNotFound", "failure"], () =>
                message.error(l("CompetitionTeams_Admin_CancelInvitation_Failure")),
            )
            .handle("InvitationNotPending", () => {
                message.error(l("CompetitionTeams_Admin_CancelInvitation_InvitationNotPending"));
            })
            .check();

        return result;
    }

    async removeAdmin(userId: string) {
        const response = await api.removeAdminFromTeam({
            TeamId: this.id,
            UserId: userId,
        });

        handleResponse(response, RemoveAdminFromTeam)
            .handle(["TeamNotFound", "UserNotFound", "failure"], () =>
                message.error(l("CompetitionTeams_Admin_Remove_Failure")),
            )
            .handle("success", () => {
                runInAction(() => {
                    this.admins = this.admins?.filter(a => a.id !== userId);
                });

                message.success(l("CompetitionTeams_Admin_Remove_Success"));
            })
            .check();

        return handleResponse(response, InviteTeamAdmin);
    }

    async updatePhoto(file: File) {
        const extension = extractExtension(file);
        const blobName = `${newId()}.${extension}`;

        const messageKey = `update-team-photo-${blobName}`;
        message.loading({
            content: l("CompetitionTeams_Photo_Update_InProgress"),
            duration: 0,
            key: messageKey,
        });

        const displayErrorMessage = () =>
            message.error({
                content: l("CompetitionTeams_Photo_Update_Failure"),
                key: messageKey,
            });

        try {
            const token = await retryQuery(() => api.teamPhotosCreateToken({}));

            await blobStorageClient.uploadBlob(blobName, file, token);

            const photoUri = blobStorageClient.blobUriFromToken(token, blobName);

            const response = await api.updateTeamPhoto({
                TeamId: this.id,
                PhotoUri: photoUri,
            });

            handleResponse(response, UpdateTeamPhoto)
                .handle(["success"], () => {
                    message.success({
                        content: l("CompetitionTeams_Photo_Update_Success"),
                        key: messageKey,
                    });

                    runInAction(() => {
                        this.photoUri = photoUri;
                    });
                })
                .handle(["TeamNotFound", "PhotoUriTooLong", "PhotoUriNotValid", "TeamIsAPlaceholder", "failure"], () =>
                    displayErrorMessage(),
                )
                .check();
        } catch (e) {
            displayErrorMessage();
        }
    }

    async removePhoto() {
        const response = await api.updateTeamPhoto({
            TeamId: this.id,
            PhotoUri: undefined,
        });

        handleResponse(response, UpdateTeamPhoto)
            .handle(["success"], () => {
                message.success(l("CompetitionTeams_Photo_Remove_Success"));

                runInAction(() => {
                    this.photoUri = undefined;
                });
            })
            .handle(["TeamNotFound", "PhotoUriTooLong", "PhotoUriNotValid", "TeamIsAPlaceholder", "failure"], () =>
                message.error(l("CompetitionTeams_Photo_Remove_Failure")),
            )
            .check();
    }

    async mergeTeam(teamSharedId: string, onSucces: () => void) {
        const response = await api.mergeTeam({
            TeamSharedId: this.sharedId ?? "",
            TeamToBeMergedInSharedId: teamSharedId,
        });

        handleResponse(response, MergeTeam)
            .handle(["TeamsCompetitionsOverlap"], () => message.error(l("CompetitionTeams_Merge_Error_Overlap")))
            .handle(["TeamNotFound", "TeamToBeMergedInNotFound", "failure"], () =>
                message.error(l("CompetitionTeams_Merge_Failure")),
            )
            .handle(["success"], () => {
                message.success(l("CompetitionTeams_Merge_Success"));
                onSucces();
            })
            .check();
    }
}

type CompetitionTeamInit = Pick<
    CompetitionTeam,
    | "id"
    | "sharedId"
    | "competitionId"
    | "name"
    | "shortName"
    | "logo"
    | "players"
    | "ageGroup"
    | "phaseId"
    | "photoUri"
    | "externalReferenceId"
>;

export type EditTeamData = {
    name: string;
    shortName?: string;
    ageGroup?: string;
};

export default CompetitionTeam;
