import React, { useCallback, useState } from "react";
import Modal from "Components/Modal";
import { l } from "Languages";
import mkCx from "@leancode/cx";
import styles from "./styles.scss";
import { Button } from "antd";
import Competition from "Domain/Competitions/Competition";
import { observer } from "mobx-react-lite";
import TablePhase from "Domain/CompetitionPhases/TablePhase/TablePhase";

const cx = mkCx(styles);

type Draw = { placeholder: { id: string; name: string }; replacement: { id: string; name: string } };

type TableDrawDialogDialogProps = {
    competition: Competition;
    phase: TablePhase;
    onClose?: () => void;
};

const TableDrawDialog: React.FunctionComponent<TableDrawDialogDialogProps> = observer(
    ({ competition, phase, onClose }) => {
        const [drawnReplacements, setDrawnReplacements] = useState<Draw[]>();
        const [isDrawing, setIsDrawing] = useState(false);

        const save = () => {
            phase.replacePlaceholderTeams(
                drawnReplacements?.map(dr => ({
                    placeholderTeamId: dr.placeholder.id,
                    replacementId: dr.replacement.id,
                })) ?? [],
            );
            setDrawnReplacements(undefined);

            onClose?.();
        };

        const draw = useCallback(() => {
            // UX requirement, without it drawing is immediate
            setIsDrawing(true);
            setTimeout(() => setIsDrawing(false), 1000);

            if (!phase.teams || !competition.teams) {
                setDrawnReplacements([]);
                return;
            }

            const placeholders = phase.teams.filter(t => t.isPlaceholder);
            const assignableTeams = competition.allTeams.filter(
                t => !phase.teams?.find(pt => pt.id === t.id) && !t.isPlaceholder,
            );

            const drawn: Draw[] = [];
            placeholders.forEach(placeholder => {
                const drawable = assignableTeams.filter(t => !drawn.map(d => d.replacement.id).includes(t.id));
                if (drawable.length === 0) return;

                const drawnReplacement = drawable[Math.floor(Math.random() * drawable.length)];
                drawn.push({
                    placeholder: { id: placeholder.id, name: placeholder.name },
                    replacement: { id: drawnReplacement.id, name: drawnReplacement.name },
                });
            });

            setDrawnReplacements(drawn);
        }, [competition.allTeams, competition.teams, phase.teams]);

        return (
            <Modal
                title={l("CompetitionDetails_DrawTeams_ModalTitle")}
                onCancel={onClose}
                okButtonProps={{ disabled: !drawnReplacements || drawnReplacements.length === 0 }}
                okText={l("CompetitionDetails_Draw_Save")}
                cancelText={l("Common_Cancel")}
                onOk={save}>
                {drawnReplacements && !isDrawing ? (
                    <div>
                        {drawnReplacements.length === 0
                            ? l("CompetitionDetails_Draw_NoPlaceholdersOrTeams")
                            : drawnReplacements.map(dr => (
                                  <div key={dr.placeholder.id}>
                                      {dr.placeholder.name} → {dr.replacement.name}
                                  </div>
                              ))}
                    </div>
                ) : (
                    <div className={cx("draw-button")}>
                        <Button onClick={draw} loading={isDrawing} disabled={isDrawing}>
                            {l("CompetitionDetails_Draw_Generate")}
                        </Button>
                    </div>
                )}
            </Modal>
        );
    },
);

export default TableDrawDialog;
