import { Spin } from "antd";
import Form from "antd/lib/form";
import { FormInstance } from "antd/lib/form/util";
import { Select } from "Components/Select";
import { l } from "Languages";
import React from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";

type PhaseScheduleConfigurationFormProps = {
    form: FormInstance;
    onFinish: (values: PhaseScheduleConfigurationFormFields) => Promise<void>;
};

const PhaseScheduleConfigurationForm: React.FunctionComponent<PhaseScheduleConfigurationFormProps> = ({
    form,
    onFinish,
}) => {
    const [isRunning, runInTask] = useRunInTask();

    return (
        <Spin spinning={isRunning}>
            <Form
                layout="vertical"
                form={form}
                onFinish={(values: PhaseScheduleConfigurationFormFields) => runInTask(() => onFinish(values))}
                initialValues={{ matchesPerPair: 1 }}>
                <Form.Item
                    label={l("CompetitionDetails_GeneratePhaseSchedule_Form_MatchesPerPair")}
                    name={guard<FormField>("matchesPerPair")}>
                    <Select
                        selectOptions={[
                            {
                                label: l("CompetitionDetails_GeneratePhaseSchedule_Form_MatchesPerPair_One"),
                                value: 1,
                                key: "1",
                            },
                            {
                                label: l("CompetitionDetails_GeneratePhaseSchedule_Form_MatchesPerPair_Two"),
                                value: 2,
                                key: "2",
                            },
                        ]}
                    />
                </Form.Item>
            </Form>
        </Spin>
    );
};

export type PhaseScheduleConfigurationFormFields = {
    matchesPerPair: 1 | 2;
};

type FormField = keyof PhaseScheduleConfigurationFormFields;

export default PhaseScheduleConfigurationForm;
