import { Form, Input } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import CompetitionSportsField from "Domain/Competitions/CompetitionSportsField";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback } from "react";
import guard from "Utils/guard";

type SportsFieldNameFormDialogProps = {
    sportsField: CompetitionSportsField;

    onClose: () => void;
};

const SportsFieldNameFormDialog: React.FunctionComponent<SportsFieldNameFormDialogProps> = ({
    sportsField,
    onClose,
}) => {
    const [form] = useForm();

    const onFinish = useCallback(
        (values: FormFields) => {
            sportsField.setName(values.name);

            onClose();
        },
        [sportsField, onClose],
    );

    return useObserver(() => (
        <Modal
            title={l("CompetitionPhases_PlanSchedule_SportsField_SetName")}
            onCancel={onClose}
            okText={l("Common_Save")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}>
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                initialValues={{ [guard<FormField>("name")]: sportsField.name }}>
                <Form.Item
                    name={guard<FormField>("name")}
                    label={l("CompetitionPhases_PlanSchedule_SportsField_Name")}
                    rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    ));
};

export type FormFields = {
    name: string;
};

type FormField = keyof FormFields;

export default SportsFieldNameFormDialog;
