import { EditOutlined, ExclamationCircleOutlined, EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, message, Modal, Skeleton, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import NavigationLink from "Components/NavigationLink";
import PageContent from "Components/PageContent";
import Photo from "Components/Photo";
import Spacing from "Components/Spacing";
import videosStore from "Domain/Videos";
import Video from "Domain/Videos/Video";
import VideoFormDialog, { FormFields, VideoFormValidationErrors } from "DomainComponents/Videos/VideoFormDialog";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { buildURL } from "react-imgix";
import routes, { PageComponent } from "Router/routes";
import { formatDate } from "Utils/formatting";
import useRunInTask from "Utils/Hooks/useRunInTask";

type FormState =
    | {
          mode: "create";
      }
    | {
          mode: "edit";
          video: Video;
      }
    | { mode: "off" };

const VideosPage: PageComponent<typeof routes.videos> = () => {
    const [videosFetchInProgress, runVideosFetchInTask] = useRunInTask();
    const [formState, setFormState] = useState<FormState>({ mode: "off" });

    useEffect(() => {
        runVideosFetchInTask(() => videosStore.fetchVideos());
    }, [runVideosFetchInTask]);

    const videos = useObserver(() => videosStore.items);

    const columns = useMemo(
        (): ColumnProps<Video>[] => [
            {
                title: l("Videos_Thumbnail"),
                render: (_, video) => <Photo photo={buildURL(video.thumbnailUri, { "max-h": 32, "max-w": 32 })} />,
            },
            {
                title: l("Videos_Title"),
                render: (_, video) => video.title,
            },
            {
                title: l("Videos_DateRecorded"),
                render: (_, video) => formatDate(video.dateRecorded),
            },
            {
                render: (_, video) => (
                    <Spacing childrenGutterX>
                        <NavigationLink to={video.uri} external type="button">
                            <EyeOutlined /> {l("Videos_Show")}
                        </NavigationLink>
                        <Button
                            onClick={() =>
                                setFormState({
                                    mode: "edit",
                                    video: video,
                                })
                            }>
                            <EditOutlined /> {l("Common_Edit")}
                        </Button>
                    </Spacing>
                ),
            },
        ],
        [],
    );

    const closeVideoFormDialog = useCallback(() => {
        setFormState({ mode: "off" });
    }, []);

    const onAddVideo = useCallback(
        async (values: FormFields) => {
            if (typeof values.thumbnail === "string") {
                return;
            }

            const handler = await videosStore.addVideo({
                thumbnail: values.thumbnail.file,
                title: values.title,
                uri: values.uri,
                dateRecorded: values.dateRecorded,
            });

            return handler
                .handle(["TitleMissingOrEmpty", "TitleTooLong"], error => ({
                    title:
                        error === "TitleMissingOrEmpty"
                            ? l("Common_Validation_FieldRequired")
                            : l("Common_Validation_FieldTooLong"),
                }))
                .handle(["UriMissingOrEmpty", "UriNotValid", "UriTooLong"], error => ({
                    uri: (() => {
                        switch (error) {
                            case "UriMissingOrEmpty":
                                return l("Common_Validation_FieldRequired");

                            case "UriTooLong":
                                return l("Common_Validation_FieldTooLong");

                            case "UriNotValid":
                                return l("Videos_Form_Uri_NotValid");
                        }
                    })(),
                }))
                .handle(
                    [
                        "VideoIdAlreadyInUse",
                        "ThumbnailUriMissingOrEmpty",
                        "ThumbnailUriNotValid",
                        "ThumbnailUriTooLong",
                        "failure",
                    ],
                    () => {
                        message.error(l("Videos_Add_Failure"));
                    },
                )
                .handle("success", () => {
                    message.success(l("Videos_Add_Success"));

                    closeVideoFormDialog();
                })
                .check({
                    reducer: (prev, curr) => {
                        return {
                            ...prev,
                            ...(curr || {}),
                        };
                    },
                    initialValue: {} as VideoFormValidationErrors,
                });
        },
        [closeVideoFormDialog],
    );

    const onEditVideo = useCallback(
        async (values: FormFields, video: Video) => {
            const handler = await video.edit({
                thumbnail: typeof values.thumbnail === "string" ? values.thumbnail : values.thumbnail.file,
                title: values.title,
                uri: values.uri,
                dateRecorded: values.dateRecorded,
            });

            let wasEditionSuccessful = false;

            const errors = handler
                .handle(["TitleMissingOrEmpty", "TitleTooLong"], error => ({
                    title:
                        error === "TitleMissingOrEmpty"
                            ? l("Common_Validation_FieldRequired")
                            : l("Common_Validation_FieldTooLong"),
                }))
                .handle(["UriMissingOrEmpty", "UriNotValid", "UriTooLong"], error => ({
                    uri: (() => {
                        switch (error) {
                            case "UriMissingOrEmpty":
                                return l("Common_Validation_FieldRequired");

                            case "UriTooLong":
                                return l("Common_Validation_FieldTooLong");

                            case "UriNotValid":
                                return l("Videos_Form_Uri_NotValid");
                        }
                    })(),
                }))
                .handle(
                    [
                        "VideoNotFound",
                        "ThumbnailUriMissingOrEmpty",
                        "ThumbnailUriNotValid",
                        "ThumbnailUriTooLong",
                        "failure",
                    ],
                    () => {
                        message.error(l("Videos_Edit_Failure"));
                    },
                )
                .handle("success", () => {
                    wasEditionSuccessful = true;
                })
                .check({
                    reducer: (prev, curr) => {
                        return {
                            ...prev,
                            ...(curr || {}),
                        };
                    },
                    initialValue: {} as VideoFormValidationErrors,
                });

            if (wasEditionSuccessful) {
                await videosStore.fetchVideos();

                message.success(l("Videos_Edit_Success"));
                closeVideoFormDialog();
            }

            return errors;
        },
        [closeVideoFormDialog],
    );

    const onDeleteVideo = useCallback(
        async (videoId: string) => {
            const handler = await videosStore.deleteVideo(videoId);

            handler
                .handle(["VideoNotFound", "failure"], () => {
                    message.error(l("Videos_Delete_Failure"));
                })
                .handle("success", () => {
                    message.success(l("Videos_Delete_Success"));

                    closeVideoFormDialog();
                })
                .check();
        },
        [closeVideoFormDialog],
    );

    const showDeleteVideoConfirmation = useCallback(
        (videoId: string) => {
            Modal.confirm({
                onOk: () => onDeleteVideo(videoId),
                icon: <ExclamationCircleOutlined />,
                title: l("Videos_Delete_Confirmation_Title"),
                content: l("Videos_Delete_Confirmation_Content"),
                okText: l("Common_Remove"),
                okButtonProps: {
                    danger: true,
                },
                cancelText: l("Common_Cancel"),
                centered: true,
                maskClosable: true,
            });
        },
        [onDeleteVideo],
    );

    return (
        <>
            <PageContent>
                <PageContent.Card title={l("Videos")}>
                    <Skeleton loading={videosFetchInProgress} active={videosFetchInProgress}>
                        <Table columns={columns} dataSource={videos} />
                    </Skeleton>
                    <PageContent.ActionButton onClick={() => setFormState({ mode: "create" })}>
                        <PlusOutlined /> {l("Videos_Add")}
                    </PageContent.ActionButton>
                </PageContent.Card>
            </PageContent>
            {formState.mode === "create" && <VideoFormDialog onClose={closeVideoFormDialog} onSave={onAddVideo} />}
            {formState.mode === "edit" && (
                <VideoFormDialog
                    video={formState.video}
                    onClose={closeVideoFormDialog}
                    onSave={values => onEditVideo(values, formState.video)}
                    onDeleteVideo={() => showDeleteVideoConfirmation(formState.video.id)}
                />
            )}
        </>
    );
};

export default VideosPage;
