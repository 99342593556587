import mkCx, { rawClassName } from "@leancode/cx";
import Button, { ButtonProps } from "antd/lib/button";
import Card, { CardProps } from "antd/lib/card";
import Skeleton, { SkeletonProps } from "antd/lib/skeleton";
import React, { PropsWithChildren } from "react";
import { Link, LinkProps } from "react-router-dom";
import styles from "./styles.scss";

const cx = mkCx(styles);

function PageContent({ children, wide }: PropsWithChildren<{ wide?: boolean }>) {
    return <div className={cx("page-content", { "-wide": wide })}>{children}</div>;
}

PageContent.Card = (cardProps: CardProps) => {
    return <Card className={cx("card")} {...cardProps} />;
};

PageContent.Header = ({ children }: PropsWithChildren<{}>) => {
    return <div className={cx("header")}>{children}</div>;
};

PageContent.HeaderSkeleton = ({ ...skeletonProps }: SkeletonProps) => {
    return (
        <Skeleton
            paragraph={{ rows: 1 }}
            {...skeletonProps}
            className={cx("header-skeleton", rawClassName(skeletonProps.className))}
        />
    );
};

PageContent.ActionButton = (buttonProps: ButtonProps) => {
    return <Button className={cx("action-button")} type="dashed" {...buttonProps} />;
};

PageContent.Link = (linkProps: LinkProps) => {
    return (
        <Link className={cx(rawClassName("ant-btn"), rawClassName("ant-btn-dashed"), "action-button")} {...linkProps} />
    );
};

export default PageContent;
