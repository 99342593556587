import ExternalReferencesStore from "Domain/CompetitionPhases/ExternalReferences/ExternalReferencesStore";
import { action, computed, observable, runInAction } from "mobx";
import api from "Services/Api";
import newId from "Utils/newId";
import retryQuery from "Utils/retryQuery";
import CompetitionTeam from "./CompetitionTeam";

class CompetitionTeamsCreator {
    private readonly externalReferencesStore: ExternalReferencesStore;

    @observable previousSeason?: { name: string; teams: CompetitionTeam[] };
    @observable otherTeams?: CompetitionTeam[];
    @observable newTeams: CompetitionTeam[] = [];
    @observable placeholderTeamsIds: string[] = [];

    @observable selectedTeamsIds: string[] = [];

    constructor(externalReferencesStore: ExternalReferencesStore) {
        this.externalReferencesStore = externalReferencesStore;
    }

    async fetchExistingTeamsAddableToCompetition(competitionId: string) {
        const response = await retryQuery(() =>
            api.teamsAutocompleteData({
                CompetitionId: competitionId,
            }),
        );

        runInAction(() => {
            this.otherTeams = response.OtherTeams.map(t =>
                CompetitionTeam.fromDTO(t, competitionId, this.externalReferencesStore),
            );
            this.previousSeason = response.PreviousSeason
                ? {
                      name: response.PreviousSeason.SeasonName,
                      teams: response.PreviousSeason.Teams.map(t =>
                          CompetitionTeam.fromDTO(t, competitionId, this.externalReferencesStore),
                      ),
                  }
                : undefined;
        });
    }

    @action.bound
    setSelectedTeamsIds(teamsIds: string[]) {
        this.selectedTeamsIds = teamsIds;
    }

    @action.bound
    selectTeam(teamId: string) {
        this.selectedTeamsIds = [...this.selectedTeamsIds, teamId];
    }

    @action.bound
    addNewTeam(name: string, shortName?: string, ageGroup?: string) {
        const teamId = newId();

        const team = new CompetitionTeam(
            {
                id: teamId,
                sharedId: teamId,
                competitionId: "",
                name: name,
                shortName: shortName,
                ageGroup: ageGroup,
            },
            {
                externalReferencesStore: this.externalReferencesStore,
            },
        );

        this.newTeams = [...this.newTeams, team];
        this.selectTeam(team.id);

        return team;
    }

    @action.bound
    addPlaceholderTeam() {
        const placeholderTeamId = newId();

        this.placeholderTeamsIds = [...this.placeholderTeamsIds, placeholderTeamId];
        this.selectTeam(placeholderTeamId);
    }

    @action.bound
    clear() {
        this.newTeams = [];
    }

    @computed get selection(): CompetitionTeamsSelectionResult {
        const selectedTeamsIds = new Set(this.selectedTeamsIds);

        const newTeams = this.newTeams.filter(t => selectedTeamsIds.has(t.id));
        const existingTeams = this.existingTeams.filter(t => selectedTeamsIds.has(t.id));
        const placeholderTeamsIds = this.placeholderTeamsIds.filter(t => selectedTeamsIds.has(t));

        return {
            newTeams: newTeams,
            existingTeams: existingTeams,
            placeholderTeamsIds: placeholderTeamsIds,
        };
    }

    @computed private get existingTeams(): CompetitionTeam[] {
        return [...(this.otherTeams ?? []), ...(this.previousSeason ? this.previousSeason.teams : [])];
    }
}

export type CompetitionTeamsSelectionResult = {
    newTeams: CompetitionTeam[];
    existingTeams: CompetitionTeam[];
    placeholderTeamsIds: string[];
};

export default CompetitionTeamsCreator;
