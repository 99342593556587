import {
    CloseOutlined,
    EditOutlined,
    PauseOutlined,
    PicLeftOutlined,
    PlaySquareOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { message, Skeleton, Table, Tag } from "antd";
import { ColumnProps } from "antd/lib/table";
import DropdownMenu from "Components/DropdownMenu";
import NavigationLink from "Components/NavigationLink";
import PageContent from "Components/PageContent";
import Spacing from "Components/Spacing";
import registrationsStore from "Domain/Registrations";
import Registration from "Domain/Registrations/Registration";
import { l } from "Languages";
import { Observer, useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo } from "react";
import routes, { PageComponent } from "Router/routes";
import { formatDate } from "Utils/formatting";
import useRunInTask from "Utils/Hooks/useRunInTask";

const RegistrationsPage: PageComponent<typeof routes.registrations> = ({ history: { push } }) => {
    const [registrationsFetchInProgress, runRegistrationsFetchInTask] = useRunInTask();
    const [registrationUpdateInProgress, runRegistrationUpdateInTask] = useRunInTask();

    useEffect(() => {
        runRegistrationsFetchInTask(() => registrationsStore.fetchRegistrations());
    }, [runRegistrationsFetchInTask]);

    const openRegistration = useCallback(
        (registration: Registration) => {
            runRegistrationUpdateInTask(async () => {
                const result = await registration.open();

                result
                    .handle(["RegistrationNotFound", "failure"], () => {
                        message.error(l("Registrations_Open_Failure"));
                    })
                    .handle("success", () => {
                        message.success(l("Registrations_Open_Success"));
                    })
                    .check();
            });
        },
        [runRegistrationUpdateInTask],
    );

    const highlightRegistration = useCallback(
        (registration: Registration) => {
            runRegistrationUpdateInTask(async () => {
                const result = await registration.updateHighlightState(true);

                result
                    .handle(["RegistrationNotFound", "failure"], () => {
                        message.error(l("Registrations_Highlight_Failure"));
                    })
                    .handle("success", () => {
                        message.success(l("Registrations_Highlight_Success"));
                    })
                    .check();
            });
        },
        [runRegistrationUpdateInTask],
    );

    const cancelRegistrationHighlight = useCallback(
        (registration: Registration) => {
            runRegistrationUpdateInTask(async () => {
                const result = await registration.updateHighlightState(false);

                result
                    .handle(["RegistrationNotFound", "failure"], () => {
                        message.error(l("Registrations_CancelHighlight_Failure"));
                    })
                    .handle("success", () => {
                        message.success(l("Registrations_CancelHighlight_Success"));
                    })
                    .check();
            });
        },
        [runRegistrationUpdateInTask],
    );

    const closeRegistration = useCallback(
        (registration: Registration) => {
            runRegistrationUpdateInTask(async () => {
                const result = await registration.close();

                result
                    .handle(["RegistrationNotFound", "failure"], () => {
                        message.error(l("Registrations_Close_Failure"));
                    })
                    .handle("success", () => {
                        message.success(l("Registrations_Close_Success"));
                    })
                    .check();
            });
        },
        [runRegistrationUpdateInTask],
    );

    const registrations = useObserver(() => registrationsStore.items);

    const columns = useMemo(
        (): ColumnProps<Registration>[] => [
            {
                title: l("Registrations_Title"),
                render: (_, registration) => (
                    <NavigationLink to={routes.registrations.details({ registrationId: registration.id })}>
                        {registration.title}
                    </NavigationLink>
                ),
            },
            {
                title: l("Registrations_StartDate"),
                render: (_, registration) => formatDate(registration.startDate),
            },
            {
                render: (_, registration) => (
                    <Observer>
                        {() => (
                            <Tag>{registration.isOpen ? l("Registrations_IsOpen") : l("Registrations_IsClosed")}</Tag>
                        )}
                    </Observer>
                ),
            },
            {
                render: (_, registration) => (
                    <Observer>
                        {() => (registration.isHighlighted ? <Tag>{l("Registrations_IsHighlighted")}</Tag> : <></>)}
                    </Observer>
                ),
            },
            {
                render: (_, registration) => (
                    <Observer>
                        {() => (
                            <DropdownMenu
                                menuItems={[
                                    {
                                        children: (
                                            <>
                                                <EditOutlined /> {l("Common_Edit")}
                                            </>
                                        ),
                                        onClick: () =>
                                            push(routes.registrations.edit({ registrationId: registration.id })),
                                    },
                                    {
                                        children: registration.isOpen ? (
                                            <>
                                                <PauseOutlined /> {l("Registrations_Close")}
                                            </>
                                        ) : (
                                            <>
                                                <PlaySquareOutlined /> {l("Registrations_Open")}
                                            </>
                                        ),
                                        onClick: () => {
                                            registration.isOpen
                                                ? closeRegistration(registration)
                                                : openRegistration(registration);
                                        },
                                    },
                                    {
                                        children: registration.isHighlighted ? (
                                            <>
                                                <CloseOutlined /> {l("Registrations_CancelHighlight")}
                                            </>
                                        ) : (
                                            <>
                                                <PicLeftOutlined /> {l("Registrations_Highlight")}
                                            </>
                                        ),
                                        onClick: () => {
                                            registration.isHighlighted
                                                ? cancelRegistrationHighlight(registration)
                                                : highlightRegistration(registration);
                                        },
                                    },
                                ]}
                            />
                        )}
                    </Observer>
                ),
            },
        ],
        [closeRegistration, openRegistration, cancelRegistrationHighlight, highlightRegistration, push],
    );

    return (
        <PageContent>
            <PageContent.Card title={l("Registrations")}>
                <Skeleton loading={registrationsFetchInProgress} active={registrationsFetchInProgress}>
                    <Spacing childrenGutterY>
                        <Table
                            columns={columns}
                            dataSource={registrations}
                            pagination={false}
                            loading={registrationUpdateInProgress}
                        />
                        <PageContent.Link to={routes.registrations.create()}>
                            <PlusOutlined /> {l("Registrations_Add")}
                        </PageContent.Link>
                    </Spacing>
                </Skeleton>
            </PageContent.Card>
        </PageContent>
    );
};

export default RegistrationsPage;
