import PasswordRecoveryPage from "Pages/Authentication/PasswordRecovery";
import PasswordResetPage from "Pages/Authentication/ResetPassword";
import SignInPage from "Pages/Authentication/SignIn";
import SignUpPage from "Pages/SignUp";
import SignUpFormPage from "Pages/SignUp/SignUpForm";
import SignUpSuccessfulPage from "Pages/SignUp/SignUpSuccessful";
import TenantExistsPage from "Pages/SignUp/TenantExists";
import React from "react";
import { Route, Switch } from "react-router";
import routes from "./routes";

const UnauthenticatedAppRouter: React.FunctionComponent = () => {
    return (
        <Switch>
            <Route exact path={routes.recoverPassword.pattern} component={PasswordRecoveryPage} />
            <Route exact path={routes.resetPassword.pattern} component={PasswordResetPage} />
            <Route exact path={routes.signUp.pattern} component={SignUpPage} />
            <Route exact path={routes.signUp.tenantExists.pattern} component={TenantExistsPage} />
            <Route exact path={routes.signUp.form.pattern} component={SignUpFormPage} />
            <Route exact path={routes.signUp.success.pattern} component={SignUpSuccessfulPage} />
            <Route component={SignInPage} />
        </Switch>
    );
};

export default UnauthenticatedAppRouter;
