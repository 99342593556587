import { Skeleton } from "antd";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import { PageHeaderProps } from "antd/lib/page-header";
import PageContent from "Components/PageContent";
import competitionPhaseStore from "Domain/CompetitionPhases";
import CustomPhase from "Domain/CompetitionPhases/CustomPhase/CustomPhase";
import GroupsPhase from "Domain/CompetitionPhases/GroupsPhase/GroupsPhase";
import KnockoutPhase from "Domain/CompetitionPhases/KnockoutPhase/KnockoutPhase";
import TablePhase from "Domain/CompetitionPhases/TablePhase/TablePhase";
import competitionStore from "Domain/Competitions";
import seasonStore from "Domain/Season";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import routes, { PageComponent } from "Router/routes";
import { formatPhaseType } from "Utils/formatting";
import CustomPhaseDetails from "./CustomPhase";
import GroupsPhaseDetails from "./GroupsPhase";
import KnockoutPhaseDetails from "./KnockoutPhase";
import TablePhaseDetails from "./TablePhase";

const CompetitionPhaseDetailsPage: PageComponent<typeof routes.competitionPhase> = ({
    match: {
        params: { phaseId },
    },
    history: { push },
    location,
}) => {
    useEffect(() => {
        competitionStore.fetchCompetitionPhaseDetails(phaseId);
    }, [phaseId]);

    const phase = useObserver(() => competitionPhaseStore.getById(phaseId));
    const competition = useObserver(() => phase && competitionStore.getById(phase.competitionId));
    const season = useObserver(() => (competition?.seasonId ? seasonStore.getById(competition.seasonId) : undefined));

    const breadcrumb: BreadcrumbProps["routes"] = useMemo(
        () =>
            phase && competition
                ? [
                      {
                          breadcrumbName: season ? season.name : l("Competitions_SeasonSelection_NoSeason"),
                          path: routes.competitions({ seasonId: competition.seasonId }),
                      },
                      {
                          breadcrumbName: competition.name,
                          path: routes.competitionDetails.phases({ competitionId: competition.id }),
                      },
                      {
                          breadcrumbName: phase?.name ?? l("CompetitionPhases_Details_NoTitlePhase"),
                          path: routes.competitionPhase({ phaseId: phase?.id }),
                      },
                  ]
                : [],
        [competition, phase, season],
    );

    const areDetailsFetched = phase?.teams !== undefined && competition?.teams !== undefined;

    const headerProps: PageHeaderProps = {
        title: phase?.name ?? l("CompetitionPhases_Details_NoTitlePhase"),
        subTitle: phase && formatPhaseType(phase.type),
        onBack: () => competition && push(routes.competitionDetails.phases({ competitionId: competition.id })),
        breadcrumb: {
            routes: breadcrumb,
            itemRender: ({ path, breadcrumbName }) =>
                path === location.pathname ? breadcrumbName : <Link to={path}>{breadcrumbName}</Link>,
        },
    };

    return useObserver(() =>
        !areDetailsFetched ? (
            <PageContent>
                <PageContent.Card>
                    <Skeleton />
                </PageContent.Card>
            </PageContent>
        ) : phase && competition ? (
            <>
                {phase instanceof TablePhase && (
                    <TablePhaseDetails phase={phase} competition={competition} headerProps={headerProps} />
                )}
                {phase instanceof GroupsPhase && (
                    <GroupsPhaseDetails phase={phase} competition={competition} headerProps={headerProps} />
                )}
                {phase instanceof CustomPhase && (
                    <CustomPhaseDetails phase={phase} competition={competition} headerProps={headerProps} />
                )}
                {phase instanceof KnockoutPhase && (
                    <KnockoutPhaseDetails phase={phase} competition={competition} headerProps={headerProps} />
                )}
            </>
        ) : null,
    );
};

export default CompetitionPhaseDetailsPage;
