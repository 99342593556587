import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export default function useQueryParams() {
    const location = useLocation();
    const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
    return params;
}

export function useQueryParamsGuarded<T extends (...args: any) => any>() {
    return useQueryParams() as URLSearchParams & { get(name: keyof NonNullable<QueryParamsFor<T>>): string | null };
}

type QueryParamsFor<T extends (...args: any[]) => string> = Parameters<T>[1];
