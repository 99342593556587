import { InfoCircleFilled } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Collapse } from "antd";
import { Select } from "Components/Select";
import GroupsPhasePlannerFilter from "Domain/CompetitionPhases/SchedulePlanner/Filters/GroupsPhasePlannerFilters";
import { l } from "Languages";
import React from "react";
import styles from "./styles.scss";

const cx = mkCx(styles);

type GroupsPhaseUnassignedMatchesFilterProps = {
    filter: GroupsPhasePlannerFilter;
};

const GroupsPhaseUnassignedMatchesFilter: React.FunctionComponent<GroupsPhaseUnassignedMatchesFilterProps> = ({
    filter,
}) => {
    return (
        <Collapse>
            <Collapse.Panel
                key="filters"
                header={
                    <div>
                        {l("CompetitionPhases_PlanSchedule_UnassignedMatchesFilter")}{" "}
                        {filter.isFiltered && <InfoCircleFilled className={cx("matches-list-narrowed-icon")} />}
                    </div>
                }>
                <Select
                    className={cx("groups-select")}
                    value={filter.selectedGroupIds}
                    onChange={(groupIds: string[]) => filter.setGroups(new Set(groupIds))}
                    mode="multiple"
                    selectOptions={filter.groups.map(g => ({
                        value: g.id,
                        label: g.name,
                        key: g.id,
                    }))}
                    placeholder={l("CompetitionPhases_PlanSchedule_UnassignedMatchesFilter_Groups_Placeholder")}
                    showArrow
                    allowClear
                />
            </Collapse.Panel>
        </Collapse>
    );
};

export default GroupsPhaseUnassignedMatchesFilter;
