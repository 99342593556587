import { handleResponse } from "@leancode/validation";
import { Button, Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/util";
import UnauthorizedAppLayout from "Components/AuthenticationLayout";
import { ResetPassword } from "Contracts/PlayooLeagueClient";
import authentication from "Domain/Account/Authentication";
import isPromise from "is-promise";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import routes, { PageComponent } from "Router/routes";
import api from "Services/Api";
import guard from "Utils/guard";
import { useQueryParamsGuarded } from "Utils/Hooks/useQueryParams";
import useRunInTask from "Utils/Hooks/useRunInTask";
import { l } from "../../../Languages";

const PasswordReset: PageComponent<typeof routes.resetPassword> = () => {
    const [form] = useForm();
    const { replace } = useHistory();
    const [isRunning, runInTask] = useRunInTask();

    const queryParams = useQueryParamsGuarded<typeof routes.resetPassword>();
    const userId = queryParams.get("userId");
    const token = queryParams.get("token");
    const email = queryParams.get("email");

    const signIn = useCallback(
        async (email, password) => {
            await authentication.signIn(email, password);

            replace(routes.index());
        },
        [replace],
    );

    const onFinish = useCallback(
        (values: FormFields) =>
            runInTask(async () => {
                if (!token || !userId || !email) {
                    message.error(l("SignIn_ResetPassword_LinkExpired"));
                    return;
                }

                const response = await api.resetPassword({
                    NewPassword: values.password,
                    Token: token,
                    UserId: userId,
                });

                await Promise.all(
                    handleResponse(response, ResetPassword)
                        .handle(["NewPasswordMissingOrEmpty", "NewPasswordTooWeak"], () =>
                            form.setFields([
                                {
                                    name: guard<FormField>("password"),
                                    value: values.password,
                                    errors: [l("SignIn_Too_Short_Password_Error")],
                                },
                            ]),
                        )
                        .handle(["InvalidToken", "TokenMissingOrEmpty", "UserDoesNotExist"], () => {
                            message.error(l("SignIn_ResetPassword_LinkExpired"));
                        })
                        .handle("failure", () => {
                            message.error(l("SignIn_ResetPassword_Failure"));
                        })
                        .handle("success", async () => {
                            await signIn(email, values.password);

                            message.success(l("SignIn_Password_Reseted"));
                        })
                        .check({
                            reducer: (prev, curr) => {
                                return [...prev, isPromise(curr) ? curr : Promise.resolve(curr)];
                            },
                            initialValue: [] as Promise<void>[],
                        }),
                );
            }),
        [token, userId, email, signIn, form, runInTask],
    );

    return (
        <UnauthorizedAppLayout>
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item
                    name={guard<FormField>("password")}
                    label={l("SignIn_New_Password")}
                    rules={[
                        { required: true, message: l("Common_Validation_FieldRequired") },
                        { min: 8, message: l("SignIn_Too_Short_Password_Error") },
                    ]}>
                    <Input type="password" />
                </Form.Item>
                <Form.Item
                    name={guard<FormField>("passwordRepeat")}
                    label={l("SignIn_Repeate_New_Password")}
                    rules={[
                        { required: true, message: l("Common_Validation_FieldRequired") },
                        {
                            message: l("SignIn_Diffrent_Passwords_Error"),
                            validator: (rule, passwordRepeat: FormFields["passwordRepeat"] | undefined, callback) => {
                                const password: FormFields["password"] | undefined = form.getFieldValue(
                                    guard<FormField>("password"),
                                );

                                if (password !== passwordRepeat) {
                                    callback(rule.message as string);
                                } else {
                                    callback();
                                }
                            },
                        },
                    ]}>
                    <Input type="password" />
                </Form.Item>
                <UnauthorizedAppLayout.Actions>
                    <Button htmlType="submit" type="primary" disabled={isRunning} loading={isRunning}>
                        {l("SignIn_Send_Reset_Password")}
                    </Button>
                </UnauthorizedAppLayout.Actions>
            </Form>
        </UnauthorizedAppLayout>
    );
};

type FormFields = {
    password: string;
    passwordRepeat: string;
};

type FormField = keyof FormFields;

export default PasswordReset;
