import { Form, Input, Spin } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import { l } from "Languages";
import React, { useCallback } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";

export type CompetitionTeamFormValidationErrors = Partial<Record<FormField, string>>;

type CompetitionTeamFormDialogProps = {
    team?: CompetitionTeam;
    initialValues?: Partial<FormFields>;

    onSave: (fields: FormFields) => Promise<CompetitionTeamFormValidationErrors | undefined>;
    onClose: () => void;
};

const CompetitionTeamFormDialog: React.FunctionComponent<CompetitionTeamFormDialogProps> = ({
    team,
    initialValues,
    onSave,
    onClose,
}) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const onFinish = useCallback(
        (values: FormFields) =>
            runInTask(async () => {
                const validationErrors = await onSave(values);

                for (const field in validationErrors) {
                    form.setFields([
                        {
                            name: field,
                            errors: [validationErrors[field]],
                            value: values[field],
                        },
                    ]);
                }
            }),
        [onSave, form, runInTask],
    );

    return (
        <Modal
            title={team ? l("CompetitionTeams_Edit_Title") : l("CompetitionTeams_CreateNew_Title")}
            onCancel={() => onClose()}
            okText={team ? l("Common_Save") : l("Common_Add")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}>
            <Spin spinning={isRunning}>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        [guard<FormField>("name")]: initialValues?.name ?? team?.name ?? undefined,
                        [guard<FormField>("shortName")]: initialValues?.shortName ?? team?.shortName ?? undefined,
                        [guard<FormField>("ageGroup")]: initialValues?.ageGroup ?? team?.ageGroup ?? undefined,
                    }}>
                    <Form.Item
                        label={l("CompetitionTeams_CreateNew_Name")}
                        name={guard<FormField>("name")}
                        rules={[
                            { required: true, message: l("Common_Validation_FieldRequired") },
                            { max: 250, message: l("Common_Validation_FieldTooLong") },
                        ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={l("CompetitionTeams_CreateNew_ShortName")}
                        name={guard<FormField>("shortName")}
                        rules={[{ max: 10, message: l("Common_Validation_FieldTooLong") }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={l("CompetitionTeams_CreateNew_AgeGroup")}
                        name={guard<FormField>("ageGroup")}
                        rules={[{ max: 50, message: l("Common_Validation_FieldTooLong") }]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

export type FormFields = {
    name: string;
    shortName?: string;
    ageGroup?: string;
};

type FormField = keyof FormFields;

export default CompetitionTeamFormDialog;
