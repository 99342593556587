/* eslint-disable */
import { CQRS } from "@leancode/cqrs-client/CQRS";
import { IRemoteCommand, IRemoteQuery } from "@leancode/cqrs-client/ClientType";
import { ApiDateTime, ApiDate, ApiDateTimeOffset } from "Utils/DTO";

interface AuthorizeWhenAttribute { }

export interface AddArticle extends IRemoteCommand, ICmsContentRelated {
    Id: string;
    Title: string;
    Content: string;
    Summary: string;
    ThumbnailPhotoUri?: string | null;
    Tags?: string[] | null;
    ReferenceObjectType?: string | null;
    ReferenceObjectId?: string | null;
}

export interface AddBanner extends IRemoteCommand {
    Id: string;
    Type: BannerTypeDTO;
    PictureUri: string;
    LinkUri?: string | null;
}

export interface AddPartner extends IRemoteCommand {
    Id: string;
}

export interface AllArticles extends IRemoteQuery<ArticleDTO[]> {
}

export interface AllBanners extends IRemoteQuery<BannerDTO[]> {
}

export interface AllPartners extends IRemoteQuery<PartnerDTO[]> {
}

export interface ArticleById extends IRemoteQuery<ArticleDTO>, IArticleRelated {
    ArticleId: string;
}

export interface ArticleDetails extends IRemoteQuery<ArticleDetailsDTO>, IArticleRelated {
    ArticleId: string;
}

export interface ArticleDetailsDTO extends ArticleDTO {
    Content: string;
    Summary: string;
    ThumbnailPhotoUri?: string | null;
}

export interface ArticleDTO {
    Id: string;
    Title: string;
    State: ArticleStateDTO;
    Tags: string[];
    DatePublished?: ApiDateTime | null;
    DateModified: ApiDateTime;
    ReferenceObjectType: string;
    ReferenceObjectId?: string | null;
}

export enum ArticleStateDTO {
    Draft = 0,
    Published = 1,
}

export interface AuthorizeWhenCreatedOrInvitedUser extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenCreatedOrInvitedUsers extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenIsCompetitionTeamsAdmin extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenIsInvitationsRecipient extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsArticle extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsBanner extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetition extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetitionGroup extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetitionGroupOrCompetitionGroupNotSelected extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetitionOrCompetitionNotSelected extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetitionPhase extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetitionTeam extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsCompetitionTeams extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsInvitation extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsMatch extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsPartner extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsPhoto extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsRegistration extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsSeason extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsSeasonOrSeasonNotSelected extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsSharedTeams extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenOwnsVideo extends AuthorizeWhenAttribute {
}

export interface AuthorizeWhenRefereesMatch extends AuthorizeWhenAttribute {
}

export interface AzureStorageTokenDTO {
    StorageUri: string;
    ContainerName: string;
    SharedAccessSignatureToken: string;
}

export interface BannerDTO {
    Id: string;
    Type: BannerTypeDTO;
    PictureUri: string;
    LinkUri?: string | null;
}

export interface BannersCreateToken extends IRemoteQuery<AzureStorageTokenDTO> {
}

export enum BannerTypeDTO {
    BelowAppBar = 0,
    Main = 1,
    SmallNews = 2,
    LargeNews = 3,
    RightColumn = 4,
}

export interface DeleteArticle extends IRemoteCommand, IArticleRelated {
    ArticleId: string;
}

export interface DeleteBanner extends IRemoteCommand, IBannerRelated {
    BannerId: string;
}

export interface DeletePartner extends IRemoteCommand, IPartnerRelated {
    PartnerId: string;
}

export interface EditArticle extends IRemoteCommand, ICmsContentRelated, IArticleRelated {
    ArticleId: string;
    Title: string;
    Content: string;
    Summary: string;
    ThumbnailPhotoUri?: string | null;
    Tags?: string[] | null;
    DatePublished?: ApiDateTime | null;
    ReferenceObjectType?: string | null;
    ReferenceObjectId?: string | null;
}

export interface EditBanner extends IRemoteCommand, IBannerRelated {
    BannerId: string;
    PictureUri: string;
    LinkUri?: string | null;
}

export interface IArticleRelated {
    ArticleId: string;
}

export interface IBannerRelated {
    BannerId: string;
}

export interface ICmsContentRelated extends IMemberwiseCloneable<ICmsContentRelated> {
    Content: string;
}

export interface ICompetitionGroupMaybeRelated {
    GroupId?: string | null;
}

export interface ICompetitionGroupRelated {
    GroupId: string;
}

export interface ICompetitionMaybeRelated {
    CompetitionId?: string | null;
}

export interface ICompetitionPhaseRelated {
    PhaseId: string;
}

export interface ICompetitionRelated {
    CompetitionId: string;
}

export interface ICompetitionTeamRelated {
    TeamId: string;
}

export interface ICompetitionTeamsRelated {
}

export interface ICreatedOrInvitedUser {
}

export interface ICreatedOrInvitedUsers {
}

export interface IInvitationRelated {
    InvitationId: string;
}

export interface IIsCompetitionTeamsAdmin {
}

export interface IIsInvitationsRecipient {
}

export interface IMatchRelated {
    MatchId: string;
}

export interface IMemberwiseCloneable<T extends IMemberwiseCloneable<T>> {
}

export interface IOwnsArticle {
}

export interface IOwnsBanner {
}

export interface IOwnsCompetition {
}

export interface IOwnsCompetitionGroup {
}

export interface IOwnsCompetitionGroupOrCompetitionGroupNotSelected {
}

export interface IOwnsCompetitionOrCompetitionNotSelected {
}

export interface IOwnsCompetitionPhase {
}

export interface IOwnsCompetitionTeam {
}

export interface IOwnsCompetitionTeams {
}

export interface IOwnsInvitation {
}

export interface IOwnsMatch {
}

export interface IOwnsPartner {
}

export interface IOwnsPhoto {
}

export interface IOwnsRegistration {
}

export interface IOwnsSeason {
}

export interface IOwnsSeasonOrSeasonNotSelected {
}

export interface IOwnsSharedTeams {
}

export interface IOwnsVideo {
}

export interface IPartnerRelated {
    PartnerId: string;
}

export interface IPhotoRelated {
    PhotoId: string;
}

export interface IRefereesMatch {
}

export interface IRegistrationRelated {
    RegistrationId: string;
}

export interface ISeasonMaybeRelated {
    SeasonId?: string | null;
}

export interface ISeasonRelated {
    SeasonId: string;
}

export interface ISharedTeamsRelated {
}

export interface IUserProfileRelated {
    UserId: string;
}

export interface IUserProfilesRelated {
}

export interface IVideoRelated {
    VideoId: string;
}

export interface PartnerDTO {
    Id: string;
    LogoUri?: string | null;
    LinkUri?: string | null;
}

export interface PartnerLogosCreateToken extends IRemoteQuery<AzureStorageTokenDTO> {
}

export interface PublishArticle extends IRemoteCommand, IArticleRelated {
    ArticleId: string;
}

export interface TinyDriveToken extends IRemoteQuery<string> {
}

export interface UnpublishArticle extends IRemoteCommand, IArticleRelated {
    ArticleId: string;
}

export interface UpdatePartnerLink extends IRemoteCommand, IPartnerRelated {
    PartnerId: string;
    LinkUri?: string | null;
}

export interface UpdatePartnerLogo extends IRemoteCommand, IPartnerRelated {
    PartnerId: string;
    LogoUri: string;
}

export const AddArticle = {
    ErrorCodes: {
        ArticleIdAlreadyInUse: 101,
        TitleMissingOrEmpty: 102,
        TitleTooLong: 103,
        ContentMissingOrEmpty: 104,
        SummaryMissingOrEmpty: 105,
        ThumbnailPhotoUriTooLong: 106,
        ReferenceObjectTypeTooLong: 107,
        OneOfTagsTooLong: 108,
        OneOfTagsEmpty: 109,
        SummaryTooLong: 110,
    },
};

export const AddBanner = {
    ErrorCodes: {
        BannerIdAlreadyInUse: 1,
        BannerAlreadyAddedForSingleBannerType: 2,
        PictureUriMissingOrEmpty: 3,
        PictureUriTooLong: 4,
        PictureUriNotValid: 5,
        LinkUriTooLong: 6,
        LinkUriNotValid: 7,
    },
};

export const AddPartner = {
    ErrorCodes: {
        PartnerIdAlreadyInUse: 1,
    },
};

export const DeleteArticle = {
    ErrorCodes: {
        ArticleNotFound: 1,
    },
};

export const DeleteBanner = {
    ErrorCodes: {
        BannerNotFound: 1,
    },
};

export const DeletePartner = {
    ErrorCodes: {
        PartnerNotFound: 1,
    },
};

export const EditArticle = {
    ErrorCodes: {
        ArticleNotFound: 101,
        TitleMissingOrEmpty: 102,
        TitleTooLong: 103,
        ContentMissingOrEmpty: 104,
        SummaryMissingOrEmpty: 105,
        ThumbnailPhotoUriTooLong: 106,
        ReferenceObjectTypeTooLong: 107,
        OneOfTagsTooLong: 108,
        OneOfTagsEmpty: 109,
        SummaryTooLong: 110,
        DatePublishedInvalid: 111,
        DatePublishedRequired: 112,
    },
};

export const EditBanner = {
    ErrorCodes: {
        BannerNotFound: 1,
        PictureUriMissingOrEmpty: 2,
        PictureUriTooLong: 3,
        PictureUriNotValid: 4,
        LinkUriTooLong: 5,
        LinkUriNotValid: 6,
    },
};

export const PublishArticle = {
    ErrorCodes: {
        ArticleNotFound: 1,
        ArticleAlreadyPublished: 2,
    },
};

export const UnpublishArticle = {
    ErrorCodes: {
        ArticleNotFound: 1,
        ArticleIsNotPublished: 2,
    },
};

export const UpdatePartnerLink = {
    ErrorCodes: {
        PartnerNotFound: 1,
        LinkUriTooLong: 2,
        LinkUriNotValid: 3,
    },
};

export const UpdatePartnerLogo = {
    ErrorCodes: {
        PartnerNotFound: 1,
        LogoUriMissingOrEmpty: 2,
        LogoUriTooLong: 3,
        LogoUriNotValid: 4,
    },
};

export default function (cqrsClient: CQRS) {
    return {
        addArticle: (dto: AddArticle) => cqrsClient.executeCommand<typeof AddArticle["ErrorCodes"]>("PlayooLeague.CMS.Contracts.Admin.Articles.AddArticle", dto),
        addBanner: (dto: AddBanner) => cqrsClient.executeCommand<typeof AddBanner["ErrorCodes"]>("PlayooLeague.CMS.Contracts.Admin.Banners.AddBanner", dto),
        addPartner: (dto: AddPartner) => cqrsClient.executeCommand<typeof AddPartner["ErrorCodes"]>("PlayooLeague.CMS.Contracts.Admin.Partners.AddPartner", dto),
        allArticles: (dto: AllArticles) => cqrsClient.executeQuery<ArticleDTO[]>("PlayooLeague.CMS.Contracts.Admin.Articles.AllArticles", dto),
        allBanners: (dto: AllBanners) => cqrsClient.executeQuery<BannerDTO[]>("PlayooLeague.CMS.Contracts.Admin.Banners.AllBanners", dto),
        allPartners: (dto: AllPartners) => cqrsClient.executeQuery<PartnerDTO[]>("PlayooLeague.CMS.Contracts.Admin.Partners.AllPartners", dto),
        articleById: (dto: ArticleById) => cqrsClient.executeQuery<ArticleDTO>("PlayooLeague.CMS.Contracts.Admin.Articles.ArticleById", dto),
        articleDetails: (dto: ArticleDetails) => cqrsClient.executeQuery<ArticleDetailsDTO>("PlayooLeague.CMS.Contracts.Admin.Articles.ArticleDetails", dto),
        bannersCreateToken: (dto: BannersCreateToken) => cqrsClient.executeQuery<AzureStorageTokenDTO>("PlayooLeague.CMS.Contracts.Admin.Storage.BannersCreateToken", dto),
        deleteArticle: (dto: DeleteArticle) => cqrsClient.executeCommand<typeof DeleteArticle["ErrorCodes"]>("PlayooLeague.CMS.Contracts.Admin.Articles.DeleteArticle", dto),
        deleteBanner: (dto: DeleteBanner) => cqrsClient.executeCommand<typeof DeleteBanner["ErrorCodes"]>("PlayooLeague.CMS.Contracts.Admin.Banners.DeleteBanner", dto),
        deletePartner: (dto: DeletePartner) => cqrsClient.executeCommand<typeof DeletePartner["ErrorCodes"]>("PlayooLeague.CMS.Contracts.Admin.Partners.DeletePartner", dto),
        editArticle: (dto: EditArticle) => cqrsClient.executeCommand<typeof EditArticle["ErrorCodes"]>("PlayooLeague.CMS.Contracts.Admin.Articles.EditArticle", dto),
        editBanner: (dto: EditBanner) => cqrsClient.executeCommand<typeof EditBanner["ErrorCodes"]>("PlayooLeague.CMS.Contracts.Admin.Banners.EditBanner", dto),
        partnerLogosCreateToken: (dto: PartnerLogosCreateToken) => cqrsClient.executeQuery<AzureStorageTokenDTO>("PlayooLeague.CMS.Contracts.Admin.Storage.PartnerLogosCreateToken", dto),
        publishArticle: (dto: PublishArticle) => cqrsClient.executeCommand<typeof PublishArticle["ErrorCodes"]>("PlayooLeague.CMS.Contracts.Admin.Articles.PublishArticle", dto),
        tinyDriveToken: (dto: TinyDriveToken) => cqrsClient.executeQuery<string>("PlayooLeague.CMS.Contracts.Admin.Articles.TinyDriveToken", dto),
        unpublishArticle: (dto: UnpublishArticle) => cqrsClient.executeCommand<typeof UnpublishArticle["ErrorCodes"]>("PlayooLeague.CMS.Contracts.Admin.Articles.UnpublishArticle", dto),
        updatePartnerLink: (dto: UpdatePartnerLink) => cqrsClient.executeCommand<typeof UpdatePartnerLink["ErrorCodes"]>("PlayooLeague.CMS.Contracts.Admin.Partners.UpdatePartnerLink", dto),
        updatePartnerLogo: (dto: UpdatePartnerLogo) => cqrsClient.executeCommand<typeof UpdatePartnerLogo["ErrorCodes"]>("PlayooLeague.CMS.Contracts.Admin.Partners.UpdatePartnerLogo", dto),
    };
}
