import ConfigProvider from "antd/es/config-provider";
import { setAppLanguage } from "Languages";
import antdLocale from "Languages/antdLocale";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Router } from "react-router";
import { ApplicationRouter } from "Router/ApplicationRouter";
import { history } from "./BrowserHistory";
import "./Styles/antd.less?global";
import "mobx-react-lite/batchingForReactDom";

declare var window: {
    __MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__?: boolean;
    appVersion?: string;
};

window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

setAppLanguage();

const render = () => {
    ReactDOM.render(
        <Router history={history}>
            <ConfigProvider locale={antdLocale}>
                <ApplicationRouter />
            </ConfigProvider>
        </Router>,
        document.getElementById("root"),
    );
};

render();

declare var module: { hot: any };

if (module.hot) {
    module.hot.accept();
}
