import { Checkbox, List } from "antd";
import Modal from "Components/Modal";
import SchedulePlanningWizard from "Domain/CompetitionPhases/SchedulePlanner/SchedulePlanningWizard/SchedulePlanningWizard";
import { l } from "Languages";
import { observer, Observer } from "mobx-react-lite";
import React, { useCallback } from "react";

type SportsFieldsSelctionFormDialogProps = {
    wizard: SchedulePlanningWizard;

    onClose: () => void;
};

const SportsFieldsSelectionFormDialog: React.FunctionComponent<SportsFieldsSelctionFormDialogProps> = observer(
    ({ wizard, onClose }) => {
        const onStartAssigningMatches = useCallback(() => {
            if (wizard.canStartAssigningMatchesToSlots) {
                wizard.startAssigningMatchesToSlots();
            }
        }, [wizard]);

        return (
            <Modal
                title={l("CompetitionPhases_PlanSchedule_Wizard_Title")}
                okText={l("CompetitionPhases_PlanSchedule_Wizard_SportsFields_MoveToSlotsFilling")}
                cancelText={l("Common_Cancel")}
                onOk={onStartAssigningMatches}
                onCancel={onClose}
                okButtonProps={{
                    disabled: !wizard.canStartAssigningMatchesToSlots,
                }}>
                <List
                    header={<b>{l("CompetitionPhases_PlanSchedule_Wizard_SportsFields_SportsFields")}</b>}
                    dataSource={wizard.sportsFields}
                    renderItem={sf => (
                        <Observer>
                            {() => (
                                <List.Item>
                                    <Checkbox
                                        checked={wizard.selectedSportsFieldIds.has(sf.id)}
                                        onChange={e =>
                                            e.target.checked
                                                ? wizard.selectSportsField(sf.id)
                                                : wizard.unselectSportsField(sf.id)
                                        }>
                                        {sf.name}
                                    </Checkbox>
                                </List.Item>
                            )}
                        </Observer>
                    )}
                />
            </Modal>
        );
    },
);

export default SportsFieldsSelectionFormDialog;
