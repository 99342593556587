import { Editor, IAllProps as EditorProps } from "@tinymce/tinymce-react";
import config from "config";
import { tinyDriveTokenStore } from "Domain/Articles";
import React, { RefObject } from "react";

type TinyMceEditorProps = EditorProps;

export default React.forwardRef<Editor, TinyMceEditorProps>(
    (() => {
        const TinyMceEditor = (props: TinyMceEditorProps, ref: RefObject<Editor>) => {
            return (
                <Editor
                    ref={ref}
                    apiKey={config.tinyDriveApiKey}
                    init={{
                        language: "pl",
                        menubar: false,
                        plugins: [
                            "advlist autolink lists link image",
                            "charmap print preview anchor help",
                            "searchreplace visualblocks code",
                            "insertdatetime media table paste wordcount",
                            "tinydrive imagetools autoresize hr",
                        ],
                        toolbar:
                            "undo redo | styleselect | bold italic | blockquote | separator | alignleft aligncenter alignright alignjustify | bullist numlist hr | image media link table | forecolor",
                        tinydrive_token_provider: async (success, failure) => {
                            try {
                                const token = await tinyDriveTokenStore.getToken();
                                success({ token });
                            } catch (e) {
                                failure("Failed to upload file.");
                            }
                        },
                        style_formats: [
                            {
                                title: "Headers",
                                items: [
                                    { title: "Header 1", format: "h1" },
                                    { title: "Header 2", format: "h2" },
                                ],
                            },
                            {
                                title: "Blocks",
                                items: [
                                    { title: "Paragraph", format: "p" },
                                    { title: "Blockquote", format: "blockquote" },
                                    { title: "Pre", format: "pre" },
                                ],
                            },
                        ],
                        formats: {
                            alignleft: [
                                {
                                    selector: "img, table",
                                    classes: "float-left",
                                    styles: { float: "left" },
                                },
                                {
                                    selector: "p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li",
                                    styles: {
                                        "text-align": "left",
                                    },
                                },
                            ],
                            alignright: [
                                {
                                    selector: "img, table",
                                    classes: "float-right",
                                    styles: { float: "right" },
                                },
                                {
                                    selector: "p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li",
                                    styles: {
                                        "text-align": "right",
                                    },
                                },
                            ],
                        },
                        content_style:
                            "img {max-width: 100%; height: auto;} " +
                            ".float-right { margin: 0px 0px 16px 16px; } " +
                            ".float-left { margin: 0px 16px 16px 0px; } ",
                        valid_children:
                            "-h1[img|iframe],-h2[img|iframe],-blockquote[img|iframe],-pre[img|iframe],-p[img|iframe]",
                    }}
                    {...props}
                />
            );
        };

        return TinyMceEditor;
    })(),
);
