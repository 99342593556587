import mkCx from "@leancode/cx";
import { Checkbox, List, TimePicker } from "antd";
import Modal from "Components/Modal";
import { SportsFieldSchedule } from "Domain/CompetitionPhases/SchedulePlanner/MatchdayConfiguration";
import { AvailableSportsFieldsConfiguration } from "Domain/CompetitionPhases/SchedulePlanner/SportsFieldConfiguration";
import { l } from "Languages";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { timeFormat } from "Utils/formatting";
import styles from "./styles.scss";

const cx = mkCx(styles);

type AutomaticScheduleGenerationFormDialogProps = {
    schedulePlan: SportsFieldSchedule[];

    onFinish: (configuration: AvailableSportsFieldsConfiguration) => void;

    onClose: () => void;
};

type SportsFieldsClosingTimesState = Record<string, moment.Moment | undefined>;

const AutomaticScheduleGenerationFormDialog: React.FunctionComponent<AutomaticScheduleGenerationFormDialogProps> = ({
    schedulePlan,
    onFinish,
    onClose,
}) => {
    const sportsFields = useMemo(() => schedulePlan.map(sp => sp.sportsField), [schedulePlan]);

    const [selectedSportsFieldsIds, setSelectedSportsFieldsIds] = useState<Set<string>>(
        new Set(sportsFields.map(sf => sf.id)),
    );

    const [sportsFieldsClosingTimes, setSportsFieldsClosingTimes] = useState<SportsFieldsClosingTimesState>(
        sportsFields.reduce(
            (acc, curr) => ({
                ...acc,
                [curr.id]: undefined,
            }),
            {} as SportsFieldsClosingTimesState,
        ),
    );

    const onChange = useCallback(
        (sportsFieldId: string, isSelected: boolean) => {
            const sportsFieldsIds = new Set(selectedSportsFieldsIds);

            if (isSelected) {
                sportsFieldsIds.add(sportsFieldId);
            } else {
                sportsFieldsIds.delete(sportsFieldId);
            }

            setSelectedSportsFieldsIds(sportsFieldsIds);
        },
        [selectedSportsFieldsIds],
    );

    const onSave = useCallback(() => {
        onFinish(
            new Map(
                Array.from(selectedSportsFieldsIds).map(sportsFieldId => [
                    sportsFieldId,
                    { closingTime: sportsFieldsClosingTimes[sportsFieldId] },
                ]),
            ),
        );
    }, [onFinish, selectedSportsFieldsIds, sportsFieldsClosingTimes]);

    const onClosingTimeChange = useCallback(
        (sportsFieldSchedule: SportsFieldSchedule, closingTime: moment.Moment | null) => {
            const sportsFieldStartTime = sportsFieldSchedule.startTime;

            if (!sportsFieldStartTime) {
                return;
            }

            const closingTimeWithMatchdayDate = closingTime
                ? moment({
                      year: sportsFieldStartTime.year(),
                      month: sportsFieldStartTime.month(),
                      date: sportsFieldStartTime.date(),
                      hour: closingTime.hour(),
                      minute: closingTime.minute(),
                  })
                : undefined;

            setSportsFieldsClosingTimes({
                ...sportsFieldsClosingTimes,
                [sportsFieldSchedule.sportsField.id]: closingTimeWithMatchdayDate,
            });
        },
        [sportsFieldsClosingTimes],
    );

    return (
        <Modal
            title={l("CompetitionPhases_PlanSchedule_AutomaticSchedulePlanning_Form_Title")}
            onCancel={onClose}
            okText={l("CompetitionPhases_PlanSchedule_AutomaticSchedulePlanning_Form_OkText")}
            cancelText={l("Common_Cancel")}
            onOk={onSave}
            okButtonProps={{
                disabled: selectedSportsFieldsIds.size === 0,
            }}>
            <List
                dataSource={schedulePlan}
                renderItem={sf => (
                    <List.Item className={cx("list-item")}>
                        <Checkbox
                            checked={selectedSportsFieldsIds.has(sf.sportsField.id)}
                            onChange={e => onChange(sf.sportsField.id, e.target.checked)}>
                            {sf.sportsField.name}
                        </Checkbox>
                        <div>
                            <span>
                                {l("CompetitionPhases_PlanSchedule_AutomaticSchedulePlanning_Form_ClosingTime")}{" "}
                            </span>
                            <TimePicker
                                className={cx("closing-time-picker")}
                                format={timeFormat}
                                value={sportsFieldsClosingTimes[sf.sportsField.id]}
                                onSelect={time => onClosingTimeChange(sf, time)}
                                onChange={time => onClosingTimeChange(sf, time)}
                            />
                        </div>
                    </List.Item>
                )}
            />
        </Modal>
    );
};

export default AutomaticScheduleGenerationFormDialog;
