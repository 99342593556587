import { Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import competitionGroupStore from "Domain/CompetitionGroups";
import CompetitionGroup from "Domain/CompetitionGroups/CompetitionGroup";
import { l } from "Languages";
import React, { useCallback } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";

type CompetitionGroupFormDialogProps = {
    competitionGroup?: CompetitionGroup;

    onClose?: (savedFormFields?: FormFields) => void;
};

const CompetitionGroupFormDialog: React.FunctionComponent<CompetitionGroupFormDialogProps> = ({
    competitionGroup,
    onClose,
}) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const setNameError = useCallback(
        (value: FormFields["name"], error: string) =>
            form.setFields([
                {
                    name: guard<FormField>("name"),
                    errors: [error],
                    value: value,
                },
            ]),
        [form],
    );

    const onFinish = useCallback(
        (values: FormFields) => {
            runInTask(async () => {
                if (competitionGroup) {
                    const result = await competitionGroup.edit(values.name);

                    result
                        .handle("NameMissingOrEmpty", () =>
                            setNameError(values.name, l("Common_Validation_FieldRequired")),
                        )
                        .handle("NameTooLong", () => setNameError(values.name, l("Common_Validation_FieldTooLong")))
                        .handle(["CompetitionGroupNotFound", "failure"], () =>
                            message.error(l("CompetitionGroups_Edit_Failure")),
                        )
                        .handle(["success"], () => {
                            message.success(l("CompetitionGroups_Edit_Success"));
                            onClose?.(values);
                        })
                        .check();
                } else {
                    const result = await competitionGroupStore.addCompetitionGroup(values.name);

                    result
                        .handle("NameMissingOrEmpty", () =>
                            setNameError(values.name, l("Common_Validation_FieldRequired")),
                        )
                        .handle("NameTooLong", () => setNameError(values.name, l("Common_Validation_FieldTooLong")))
                        .handle(["GroupIdAlreadyInUse", "failure"], () =>
                            message.error(l("CompetitionGroups_Add_Failure")),
                        )
                        .handle(["success"], () => {
                            message.success(l("CompetitionGroups_Add_Success"));
                            onClose?.(values);
                        })
                        .check();
                }
            });
        },
        [onClose, runInTask, setNameError, competitionGroup],
    );

    return (
        <Modal
            title={competitionGroup ? l("CompetitionGroups_Form_Title_Edit") : l("CompetitionGroups_Form_Title_Add")}
            onCancel={() => onClose?.()}
            okText={competitionGroup ? l("Common_Save") : l("Common_Add")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}
            okButtonProps={{
                loading: isRunning,
                disabled: isRunning,
            }}>
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                initialValues={
                    competitionGroup
                        ? {
                              [guard<FormField>("name")]: competitionGroup.name,
                          }
                        : undefined
                }>
                <Form.Item
                    label={l("CompetitionGroups_Form_Name")}
                    name={guard<FormField>("name")}
                    rules={[
                        { required: true, message: l("Common_Validation_FieldRequired") },
                        { max: 250, message: l("Common_Validation_FieldTooLong") },
                    ]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export type FormFields = {
    name: string;
};

type FormField = keyof FormFields;

export default CompetitionGroupFormDialog;
