import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import ImagesDraggerUpload from "Components/ImagesDraggerUpload";
import Photo from "Components/Photo";
import Spacing from "Components/Spacing";
import { l } from "Languages";
import React, { useCallback } from "react";

type TeamPhotoProps = {
    photoUri?: string;

    onUpdateTeamPhoto: (file: File) => void;
    onRemovePhoto: () => Promise<void>;
};

const TeamPhoto: React.FunctionComponent<TeamPhotoProps> = ({ onRemovePhoto, photoUri, onUpdateTeamPhoto }) => {
    const showRemovePhotoConfirmation = useCallback(() => {
        Modal.confirm({
            onOk: onRemovePhoto,
            icon: <ExclamationCircleOutlined />,
            title: l("CompetitionTeams_Photo_Remove_Confirmation_Title"),
            content: l("CompetitionTeams_Photo_Remove_Confirmation_Content"),
            okText: l("Common_Remove"),
            okButtonProps: {
                danger: true,
            },
            cancelText: l("Common_Cancel"),
            centered: true,
            maskClosable: true,
        });
    }, [onRemovePhoto]);

    return (
        <Spacing childrenGutterY>
            <ImagesDraggerUpload
                text={photoUri ? l("CompetitionTeams_Photo_Upload_Update") : l("CompetitionTeams_Photo_Upload_AddNew")}
                onUpload={files => files[0] && onUpdateTeamPhoto(files[0])}
            />
            {photoUri && (
                <>
                    <div>
                        <Button onClick={showRemovePhotoConfirmation}>{l("Common_Remove")}</Button>
                    </div>
                    <Photo photo={photoUri} fullWidth />
                </>
            )}
        </Spacing>
    );
};

export default TeamPhoto;
