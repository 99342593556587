import CompetitionSportsField from "Domain/Competitions/CompetitionSportsField";
import { Match } from "Domain/Matches/Match";
import { action, computed, observable } from "mobx";
import moment from "moment";
import Break from "./Break";
import { areSlotsOverlapping } from "./predicates";
import { isBreak, isMatch, Slot } from "./Slot";
import SlotsConfiguration from "./SlotsConfiguration";

export type SportsFieldMatchesPlan = {
    sportsField: CompetitionSportsField;
    startTime?: moment.Moment;
    slots: Slot[];
};

export type SportsFieldSchedule = {
    sportsField: CompetitionSportsField;
    startTime?: moment.Moment;
    slots: PlannedSlot[];
};

export type PlannedSlot = {
    slot: Slot;
    startTime?: moment.Moment;
};

export type MatchConflicts = Map<string, Match[]>;

class MatchdayConfiguration {
    private readonly slotsConfiguration: SlotsConfiguration;
    private readonly allMatches: Match[];

    @observable day?: moment.Moment;

    @observable sportsFieldsPlan: SportsFieldMatchesPlan[];

    constructor(
        day: moment.Moment | undefined,
        sportsFieldsPlan: SportsFieldMatchesPlan[],
        slotsConfiguration: SlotsConfiguration,
        allMatches: Match[],
    ) {
        this.day = day;
        this.sportsFieldsPlan = sportsFieldsPlan;
        this.slotsConfiguration = slotsConfiguration;
        this.allMatches = allMatches;
    }

    @computed get schedule(): SportsFieldSchedule[] {
        return this.sportsFieldsPlan.map(plan => ({
            ...plan,
            slots: plan.slots.reduce((acc, currentSlot) => {
                const previousSlot = acc[acc.length - 1];
                const previousSlotStartTime = previousSlot?.startTime ?? plan.startTime;
                const previousSlotDuration = previousSlot ? this.getSlotDurationInMinutes(previousSlot.slot) : 0;

                return [
                    ...acc,
                    {
                        slot: currentSlot,
                        startTime:
                            previousSlotStartTime && previousSlotDuration !== undefined
                                ? moment(previousSlotStartTime).add(previousSlotDuration, "minutes")
                                : undefined,
                    },
                ];
            }, [] as PlannedSlot[]),
        }));
    }

    @computed private get assignedSlotsMap(): Map<string, number> {
        return new Map(
            this.sportsFieldsPlan.flatMap((sf, sportsFieldIndex) => sf.slots.map(s => [s.id, sportsFieldIndex])),
        );
    }

    @computed get assignedMatchesIds(): string[] {
        return this.sportsFieldsPlan.flatMap(sf => sf.slots.filter(s => isMatch(s)).map(s => s.id));
    }

    @computed private get allMatchSlots(): PlannedSlot[] {
        return this.schedule.flatMap(sportsField => sportsField.slots.filter(ps => isMatch(ps.slot)));
    }

    @computed get matchConflicts(): MatchConflicts {
        const allMatchSlots = this.allMatchSlots;

        return new Map<string, Match[]>(
            allMatchSlots.map(currentSlot => {
                const currentSlotEndTime = this.getSlotEndTime(currentSlot);

                const matchesOverlappingWithSlot = allMatchSlots
                    .filter(
                        ps =>
                            isMatch(ps.slot) &&
                            isMatch(currentSlot.slot) &&
                            ps.startTime &&
                            currentSlot.startTime &&
                            ps.slot.id !== currentSlot.slot.id &&
                            areSlotsOverlapping(
                                {
                                    startTime: ps.startTime,
                                    endTime: this.getSlotEndTime(ps),
                                },
                                {
                                    startTime: currentSlot.startTime,
                                    endTime: currentSlotEndTime,
                                },
                            ) &&
                            this.teamsIntersectForMatches(ps.slot, currentSlot.slot),
                    )
                    .map(ps => ps.slot) as Match[];

                return [currentSlot.slot.id, matchesOverlappingWithSlot];
            }),
        );
    }

    @action.bound
    moveSlot(slotId: string, destination: { sportsFieldId?: string; index: number }) {
        const sourceSportsFieldIndex = this.assignedSlotsMap.get(slotId);

        const sourceSportsField =
            sourceSportsFieldIndex !== undefined ? this.sportsFieldsPlan[sourceSportsFieldIndex] : undefined;
        const destinationSportsField = destination.sportsFieldId
            ? this.sportsFieldsPlan.find(plan => plan.sportsField.id === destination.sportsFieldId)
            : undefined;

        const slot = sourceSportsField?.slots.find(s => s.id === slotId) ?? this.allMatches.find(m => m.id === slotId);

        if (sourceSportsField) {
            sourceSportsField.slots = sourceSportsField.slots.filter(s => s.id !== slotId);
        }

        if (destinationSportsField && slot) {
            const destinationSportsFieldSlots = [...destinationSportsField.slots];
            destinationSportsFieldSlots.splice(destination.index, 0, slot);

            destinationSportsField.slots = destinationSportsFieldSlots;
        }
    }

    @action.bound
    setStartTime(startTime: moment.Moment) {
        if (this.day) {
            throw new Error("Start time can be set only when day is not known yet.");
        }
        this.day = startTime;

        this.sportsFieldsPlan.forEach(sp => {
            sp.startTime = startTime;
        });
    }

    @action.bound
    setSportsFieldStartTime(sportsFieldId: string, startTime: moment.Moment) {
        if (!this.day) {
            throw new Error("Matchday is not configured. Cannot set sports field start time.");
        }

        const startTimeWithMatchdayDate = moment({
            year: this.day.year(),
            month: this.day.month(),
            date: this.day.date(),
            hour: startTime.hour(),
            minute: startTime.minute(),
        });

        const sportsField = this.sportsFieldsPlan.find(sp => sp.sportsField.id === sportsFieldId);

        if (sportsField) {
            sportsField.startTime = startTimeWithMatchdayDate;
        }
    }

    @action.bound
    addSportsField(sportsField: CompetitionSportsField) {
        const startTimeForLastSportsField = this.sportsFieldsPlan[this.sportsFieldsPlan.length - 1]?.startTime;

        this.sportsFieldsPlan.push({
            sportsField: sportsField,
            startTime: startTimeForLastSportsField,
            slots: [],
        });
    }

    @action.bound
    addBreakToSportsField(sportsFieldId: string, breakDuration: number, insertAfterIndex?: number) {
        const sportsFieldPlan = this.sportsFieldsPlan.find(sf => sf.sportsField.id === sportsFieldId);

        if (!sportsFieldPlan) {
            throw new Error("Sports field not found. Cannot add break.");
        }

        if (insertAfterIndex !== undefined && insertAfterIndex < sportsFieldPlan.slots.length)
            sportsFieldPlan.slots.splice(insertAfterIndex + 1, 0, new Break(breakDuration));
        else sportsFieldPlan.slots.push(new Break(breakDuration));
    }

    @action.bound
    removeBreak(breakId: string) {
        const sportsFieldIndex = this.assignedSlotsMap.get(breakId);

        if (sportsFieldIndex === undefined) {
            throw new Error("Sports field not found. Cannot remove break");
        }

        this.sportsFieldsPlan[sportsFieldIndex].slots = this.sportsFieldsPlan[sportsFieldIndex].slots.filter(
            s => s.id !== breakId,
        );
    }

    @action.bound
    clearSportsFields() {
        this.sportsFieldsPlan.forEach(plan => {
            plan.slots = [];
        });
    }

    private getSlotDurationInMinutes(slot: Slot) {
        if (isBreak(slot)) {
            return slot.durationInMinutes;
        } else {
            return this.slotsConfiguration.distanceBetweenMatchesInMinutes;
        }
    }

    private getSlotEndTime(plannedSlot: PlannedSlot): moment.Moment | undefined {
        if (!plannedSlot.startTime) {
            return undefined;
        }

        const slotDurationInMinutes = this.getSlotDurationInMinutes(plannedSlot.slot);

        return moment(plannedSlot.startTime).add(slotDurationInMinutes, "minutes");
    }

    private teamsIntersectForMatches(match1: Match, match2: Match) {
        const match1Teams = [...(match1.team1Id ? [match1.team1Id] : []), ...(match1.team2Id ? [match1.team2Id] : [])];

        const match2Teams = new Set<string>([
            ...(match2.team1Id ? [match2.team1Id] : []),
            ...(match2.team2Id ? [match2.team2Id] : []),
        ]);

        return match1Teams.some(t => match2Teams.has(t));
    }
}

export default MatchdayConfiguration;
