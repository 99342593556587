import { handleResponse } from "@leancode/validation";
import { AddBanner, BannerTypeDTO, DeleteBanner } from "Contracts/CmsClient";
import { computed } from "mobx";
import cmsApi from "Services/CmsApi";
import newId from "Utils/newId";
import Banner from "./Banner";
import BannersStore from "./BannersStore";

class HomePageBannersConfiguration {
    private readonly bannersStore: BannersStore;

    constructor(bannersStore: BannersStore) {
        this.bannersStore = bannersStore;
    }

    private firstOfType(type: BannerTypeDTO) {
        return this.bannersStore.items.find(b => b.type === type);
    }

    @computed get belowAppBarBanner() {
        return this.firstOfType(BannerTypeDTO.BelowAppBar);
    }

    @computed get mainBanner() {
        return this.firstOfType(BannerTypeDTO.Main);
    }

    @computed get smallNewsBanner() {
        return this.firstOfType(BannerTypeDTO.SmallNews);
    }

    @computed get largeNewsBanner() {
        return this.firstOfType(BannerTypeDTO.LargeNews);
    }

    @computed get rightColumnBanners() {
        return this.bannersStore.items.filter(b => b.type === BannerTypeDTO.RightColumn);
    }

    fetch() {
        return this.bannersStore.fetchBanners();
    }

    async addBanner({ type, picture, linkUri }: AddBannerData) {
        const bannerId = newId();

        const pictureUri = await Banner.uploadPicture(bannerId, picture);

        const response = await cmsApi.addBanner({
            Id: bannerId,
            LinkUri: linkUri,
            PictureUri: pictureUri,
            Type: type,
        });

        if (response.isSuccess && response.result.WasSuccessful) {
            await this.fetch();
        }

        return handleResponse(response, AddBanner);
    }

    async deleteBanner(bannerId: string) {
        const response = await cmsApi.deleteBanner({
            BannerId: bannerId,
        });

        const handler = handleResponse(response, DeleteBanner);

        handler.handle("success", () => {
            const banner = this.bannersStore.getById(bannerId);

            if (banner) {
                this.bannersStore.remove(banner);
            }
        });

        return handler;
    }
}

type AddBannerData = {
    type: BannerTypeDTO;
    picture: File;
    linkUri?: string;
};

export default HomePageBannersConfiguration;
