import { handleResponse } from "@leancode/validation";
import { message } from "antd";
import { DeleteArticle } from "Contracts/CmsClient";
import { AddArticle } from "Contracts/PlayooLeagueClient";
import { l } from "Languages";
import _ from "lodash";
import { computed } from "mobx";
import api from "Services/Api";
import cmsApi from "Services/CmsApi";
import { MapStore } from "Utils/mobx";
import newId from "Utils/newId";
import retryQuery from "Utils/retryQuery";
import Article from "./Article";

class ArticlesStore extends MapStore<Article> {
    @computed get articles() {
        return Array.from(this.items.values());
    }

    @computed get allTags() {
        return _.uniq([Article.newsTag, ...this.articles.flatMap(a => a.tags)]);
    }

    async fetchArticles() {
        const articles = await retryQuery(() => api.allArticles({}));

        return this.set(articles.map(a => Article.fromDTO(a)));
    }

    async fetchArticleDetails(articleId: string) {
        const article = await retryQuery(() => api.articleDetails({ ArticleId: articleId }));

        return this.put(Article.fromDTO(article));
    }

    async addArticle({ title, isNews, content, summary, thumbnailPhotoUri, tags, competitionId }: CreateArticleData) {
        const response = await api.addArticle({
            Id: newId(),
            Title: title,
            IsNews: isNews,
            Content: Article.prepareContent(content),
            Summary: summary,
            ThumbnailPhotoUri: thumbnailPhotoUri,
            Tags: tags,
            CompetitionId: competitionId,
        });

        return handleResponse(response, AddArticle);
    }

    async deleteArticle(articleId: string) {
        const article = this.getById(articleId);

        if (!article) {
            throw new Error("Article not found.");
        }

        const response = await cmsApi.deleteArticle({
            ArticleId: articleId,
        });

        handleResponse(response, DeleteArticle)
            .handle(["ArticleNotFound", "failure"], () => {
                message.error(l("Articles_Delete_Failure"));
            })
            .handle("success", () => {
                message.success(l("Articles_Delete_Success"));

                this.remove(article);
            })
            .check();
    }
}

export type CreateArticleData = {
    isNews: boolean;
    title: string;
    content: string;
    summary: string;
    thumbnailPhotoUri?: string;
    tags?: string[];
    competitionId?: string;
};

export default ArticlesStore;
