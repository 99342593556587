import ImageCrop from "Components/ImageCrop";
import ImagesDraggerUpload from "Components/ImagesDraggerUpload";
import Spacing from "Components/Spacing";
import { l } from "Languages";
import React, { useCallback, useMemo } from "react";
import GridGallery, { GridGalleryImage } from "react-grid-gallery";

type PhotosGalleryProps = {
    pictures: string[];
    selectedPictureUri?: string;

    onPictureUpload?: (file: File) => void;
    onSelectPicture?: (pictureUri: string | undefined) => void;
};

const thumbnailRowHeight = 150;

const UserProfilePicturesGallery: React.FunctionComponent<PhotosGalleryProps> = ({
    pictures,
    selectedPictureUri,
    onPictureUpload,
    onSelectPicture,
}) => {
    const images: GridGalleryImage[] = useMemo(
        () =>
            pictures.map(p => ({
                src: p,
                thumbnail: p,
                isSelected: selectedPictureUri === p,
            })),
        [pictures, selectedPictureUri],
    );

    const onSelectImage = useCallback(
        (index: number) => {
            const isPictureCurrentlySelected = pictures[index] === selectedPictureUri;

            const pictureUriToSet = isPictureCurrentlySelected ? undefined : pictures[index];

            onSelectPicture?.(pictureUriToSet);
        },
        [onSelectPicture, pictures, selectedPictureUri],
    );

    return (
        <Spacing childrenGutterY>
            {onPictureUpload && (
                <ImageCrop>
                    <ImagesDraggerUpload
                        text={l("CompetitionTeamPlayers_ProfilePictures_Upload")}
                        onUpload={files => files[0] && onPictureUpload(files[0])}
                    />
                </ImageCrop>
            )}
            <GridGallery
                showLightboxThumbnails
                backdropClosesModal
                imageCountSeparator={l("Photos_Gallery_ImageCountSeparator")}
                rowHeight={thumbnailRowHeight}
                images={images}
                onSelectImage={onSelectImage}
            />
        </Spacing>
    );
};

export default UserProfilePicturesGallery;
