import mkCx from "@leancode/cx";
import { Button, Select as AntdSelect, Skeleton, Tag } from "antd";
import { Select, SelectProps } from "Components/Select";
import Spacing from "Components/Spacing";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import TeamPlayersCreator, {
    ForeignPlayerDescription,
    PlayerDescription,
    TeamPlayersSelectionResult,
} from "Domain/Competitions/TeamPlayersCreator";
import PlayerFormDialog from "DomainComponents/PlayersFormDialog";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useRunInTask from "Utils/Hooks/useRunInTask";
import styles from "./styles.scss";

const { OptGroup, Option } = AntdSelect;

const cx = mkCx(styles);

type PlayersCreatorSelectProps = {
    team: CompetitionTeam;

    saveInProgress?: boolean;

    onSave: (selection: TeamPlayersSelectionResult) => void;
    onCancel: () => void;
} & SelectProps;

const PlayersCreatorSelect: React.FunctionComponent<PlayersCreatorSelectProps> = ({
    team,
    saveInProgress,
    onCancel,
    onSave,
    ...selectProps
}) => {
    const creator = useMemo(() => new TeamPlayersCreator(), []);
    const [fetchInProgress, runFetchInTask] = useRunInTask();

    const [showCreateNewPlayerDialog, setShowCreateNewPlayerDialog] = useState(false);
    const [searchPhrase, setSearchPhrase] = useState("");

    useEffect(() => {
        runFetchInTask(() => creator.fetchExistingPlayersAddableToTeam(team.id));
    }, [team.id, creator, runFetchInTask]);

    const selectRef = useRef<AntdSelect | null>(null);

    const searchablePlayerName = useCallback(
        (player: PlayerDescription) => `${player.lastName} ${player.firstName} ${player.firstName} ${player.lastName}`,
        [],
    );

    const searchableForeignPlayer = useCallback(
        (player: ForeignPlayerDescription) =>
            `${searchablePlayerName(player)} ${player.teamName} ${player.competitionName} ${searchablePlayerName(
                player,
            )} ${player.competitionName} ${player.teamName} ${searchablePlayerName(player)}`,
        [searchablePlayerName],
    );

    return useObserver(() => (
        <Skeleton loading={fetchInProgress} active={fetchInProgress} paragraph={{ rows: 1 }}>
            <Spacing childrenGutterY className={cx("container")}>
                <Select
                    optionFilterProp="searchField" // HACK: filterOption doesn't work correctly with OptGroups, so we use Option's searchField prop for filtering
                    onSearch={phrase => setSearchPhrase(phrase)}
                    searchValue={searchPhrase}
                    showArrow
                    allowClear
                    showAction={["focus", "click"]}
                    ref={selectRef}
                    value={creator.selectedPlayersIds}
                    onChange={(selectedPlayersIds: string[] | string) => {
                        creator.setSelectedPlayersIds(
                            typeof selectedPlayersIds === "string" ? [selectedPlayersIds] : selectedPlayersIds,
                        );

                        setSearchPhrase("");
                    }}
                    mode="multiple"
                    className={cx("select")}
                    createNewItemLabel={l("CompetitionTeamPlayers_Select_AddNewPlayer")}
                    onCreate={() => setShowCreateNewPlayerDialog(true)}
                    {...selectProps}>
                    {creator.newPlayers.length > 0 && (
                        <OptGroup label={l("CompetitionTeamPlayers_Select_NewPlayers")}>
                            {creator.newPlayers.map(p => (
                                <Option value={p.id} key={p.id} searchField={searchablePlayerName(p)}>
                                    {p.lastName} {p.firstName}
                                </Option>
                            ))}
                        </OptGroup>
                    )}
                    {creator.playersFromPreviousCompetitions && creator.playersFromPreviousCompetitions.length > 0 && (
                        <OptGroup
                            label={l("CompetitionTeamPlayers_Select_PreviousCompetitionsPlayers", team.displayName)}>
                            {creator.playersFromPreviousCompetitions.map(p => (
                                <Option value={p.id} key={p.id} searchField={searchablePlayerName(p)}>
                                    {p.lastName} {p.firstName}
                                </Option>
                            ))}
                        </OptGroup>
                    )}
                    {creator.otherPlayers && creator.otherPlayers.length > 0 && (
                        <OptGroup label={l("CompetitionTeamPlayers_Select_OtherTeamsPlayers")}>
                            {creator.otherPlayers.map(p => (
                                <Option value={p.id} key={p.id} searchField={searchableForeignPlayer(p)}>
                                    {p.lastName} {p.firstName}
                                    <Tag className={cx("team-tag")}>{p.teamName}</Tag>
                                    <Tag>{p.competitionName}</Tag>
                                </Option>
                            ))}
                        </OptGroup>
                    )}
                </Select>
                <Spacing childrenGutterX>
                    <Button onClick={onCancel}>{l("Common_Cancel")}</Button>
                    <Button
                        loading={saveInProgress}
                        disabled={saveInProgress || creator.selectedPlayersIds.length === 0}
                        onClick={() => onSave(creator.selection)}
                        type="primary">
                        {l("Common_Add")}
                    </Button>
                </Spacing>
                {showCreateNewPlayerDialog && (
                    <PlayerFormDialog
                        initialValues={(() => {
                            const [firstName, ...splittedLastName] = searchPhrase.split(" ");

                            return {
                                firstName: firstName,
                                lastName: splittedLastName.join(" "),
                            };
                        })()}
                        onSave={async values => {
                            selectRef.current && selectRef.current.focus();

                            values &&
                                creator.addNewPlayer(
                                    values.firstName,
                                    values.lastName,
                                    values.shirtNumber ? parseInt(values.shirtNumber) : undefined,
                                );
                            setSearchPhrase("");
                            setShowCreateNewPlayerDialog(false);

                            return undefined;
                        }}
                        onClose={() => setShowCreateNewPlayerDialog(false)}
                    />
                )}
            </Spacing>
        </Skeleton>
    ));
};

export default PlayersCreatorSelect;
