import mkCx from "@leancode/cx";
import { Button } from "antd";
import Modal from "Components/Modal";
import { Select } from "Components/Select";
import Spacing from "Components/Spacing";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import { l } from "Languages";
import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import styles from "./styles.scss";

const cx = mkCx(styles);

type SwapTeamsInGroupPhaseSelectProps = {
    teamsAvailableForSwap: CompetitionTeam[];
    team1Id: string;
    team1Name: string;

    isSaving: boolean;

    onCancel: () => void;
    onSave: (team1Id: string, team2Id: string) => void;

    className?: string;
};

const SwapTeamsInGroupPhaseSelect: React.FunctionComponent<SwapTeamsInGroupPhaseSelectProps> = observer(
    ({ teamsAvailableForSwap, team1Id, team1Name, isSaving, onCancel, onSave, className }) => {
        const [team2Id, setTeam2Id] = useState<string>();
        const [isModalOpen, setIsModalOpen] = useState(false);

        const team2Name = useMemo(() => teamsAvailableForSwap.find(t => t.id === team2Id)?.name, [
            team2Id,
            teamsAvailableForSwap,
        ]);

        return (
            <>
                <Spacing childrenGutterY className={className}>
                    <Select
                        className={cx("select")}
                        value={team2Id}
                        onChange={(teamId: string) => setTeam2Id(teamId)}
                        showArrow
                        selectOptions={teamsAvailableForSwap.map(t => ({
                            value: t.id,
                            label: t.displayName,
                            key: t.id,
                        }))}
                    />
                    <Spacing childrenGutterX className={cx("actions")}>
                        <Button onClick={onCancel}>{l("Common_Cancel")}</Button>
                        <Button disabled={!team2Id} onClick={() => setIsModalOpen(true)} type="primary">
                            {l("CompetitionPhases_SwapTeams_Swap")}
                        </Button>
                    </Spacing>
                </Spacing>
                {isModalOpen && (
                    <Modal
                        confirmLoading={isSaving}
                        title={l("CompetitionPhases_SwapTeams_ModalTitle")}
                        onCancel={() => setIsModalOpen(false)}
                        okText={l("CompetitionPhases_SwapTeams_Swap")}
                        cancelText={l("Common_Cancel")}
                        onOk={() => team2Id && onSave(team1Id, team2Id)}>
                        {l("CompetitionPhases_SwapTeams_ModalText", team1Name, team2Name)}
                    </Modal>
                )}
            </>
        );
    },
);

export default SwapTeamsInGroupPhaseSelect;
