import { MatchPlayerDTO } from "Contracts/PlayooLeagueClient";
import { computed, observable } from "mobx";

class MatchPlayer {
    readonly id: string;

    @observable firstName: string;
    @observable lastName: string;
    @observable shirtNumber?: number;
    @observable pictureUri?: string;
    @observable isGoalkeeper: boolean;

    constructor(id: string, { firstName, lastName, shirtNumber, pictureUri, isGoalkeeper }: MatchPlayerInit) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.shirtNumber = shirtNumber;
        this.pictureUri = pictureUri;
        this.isGoalkeeper = isGoalkeeper;
    }

    static fromDTO(dto: MatchPlayerDTO) {
        return new MatchPlayer(dto.Id, {
            firstName: dto.FirstName,
            lastName: dto.LastName,
            shirtNumber: dto.ShirtNumber ?? undefined,
            pictureUri: dto.PictureUri ?? undefined,
            isGoalkeeper: dto.IsGoalkeeper,
        });
    }

    @computed get fullName() {
        return `${this.lastName} ${this.firstName}`;
    }
}

export type MatchPlayerInit = Pick<
    MatchPlayer,
    "firstName" | "lastName" | "pictureUri" | "shirtNumber" | "isGoalkeeper"
>;

export default MatchPlayer;
