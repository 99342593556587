import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Button, Checkbox, Input } from "antd";
import NavigationLink from "Components/NavigationLink";
import Spacing from "Components/Spacing";
import Upload from "Components/Upload";
import RegistrationsBlobStorage from "Domain/Registrations/RegistrationsBlobStorage";
import { l } from "Languages";
import React, { useCallback } from "react";
import newId from "Utils/newId";
import styles from "./styles.scss";

const cx = mkCx(styles);

export type RegistrationDocument = {
    id: string;
    name: string;
    uri: string;
    requiresAcceptance: boolean;
};

export type DocumentsUploadProps = {
    registrationId: string;

    value?: RegistrationDocument[];
    onChange?: (documents: RegistrationDocument[]) => void;
};

const DocumentsUpload: React.FunctionComponent<DocumentsUploadProps> = ({
    registrationId,
    value: documents,
    onChange,
}) => {
    const uploadDocument = useCallback(
        async (file: File) => {
            const documentId = newId();

            const documentUri = await RegistrationsBlobStorage.uploadRegistrationDocument(
                documentId,
                registrationId,
                file,
            );

            if (documentUri) {
                onChange?.([
                    ...(documents ?? []),
                    { id: documentId, name: file.name, uri: documentUri, requiresAcceptance: false },
                ]);
            }
        },
        [registrationId, documents, onChange],
    );

    const onDocumentNameChange = useCallback(
        (newName: string, index: number) => {
            const document = documents?.[index];

            if (!document || !documents) {
                return;
            }

            const newDocuments = [...documents];
            newDocuments.splice(index, 1, { ...document, name: newName });

            onChange?.(newDocuments);
        },
        [documents, onChange],
    );

    const onRequiresAcceptanceChange = useCallback(
        (requiresAcceptance: boolean, index: number) => {
            const document = documents?.[index];

            if (!document || !documents) {
                return;
            }

            const newDocuments = [...documents];
            newDocuments.splice(index, 1, { ...document, requiresAcceptance: requiresAcceptance });

            onChange?.(newDocuments);
        },
        [documents, onChange],
    );

    const onDeleteDocument = useCallback(
        (index: number) => {
            if (!documents) {
                return;
            }

            const newDocuments = [...documents];
            newDocuments.splice(index, 1);

            onChange?.(newDocuments);
        },
        [documents, onChange],
    );

    return (
        <>
            <Upload onUpload={uploadDocument}>
                <Button type="link">{l("Registrations_Form_Documents_LoadFile")}</Button>
            </Upload>
            <Spacing childrenGutterY>
                {documents?.map((d, ind) => (
                    <Spacing childrenGutterX key={d.uri} className={cx("document")}>
                        <Input value={d.name} type="text" onChange={e => onDocumentNameChange(e.target.value, ind)} />
                        <Checkbox
                            className={cx("requires-acceptance-checkbox")}
                            checked={d.requiresAcceptance}
                            onChange={e => onRequiresAcceptanceChange(e.target.checked, ind)}>
                            {l("Registrations_Form_Documents_RequiresAcceptance")}
                        </Checkbox>
                        <NavigationLink to={d.uri} external type="icon-button">
                            <EyeOutlined />
                        </NavigationLink>
                        <Button
                            type="dashed"
                            shape="circle"
                            icon={<DeleteOutlined />}
                            onClick={() => onDeleteDocument(ind)}
                        />
                    </Spacing>
                ))}
            </Spacing>
        </>
    );
};

export default DocumentsUpload;
