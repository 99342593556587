import mkCx from "@leancode/cx";
import { Button, message, Skeleton, Typography } from "antd";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import PageHeader from "antd/lib/page-header";
import DropdownMenu from "Components/DropdownMenu";
import PageContent from "Components/PageContent";
import Spacing from "Components/Spacing";
import competitionPhaseStore from "Domain/CompetitionPhases";
import GreedyMatchdayScheduler, {
    PlanResult,
} from "Domain/CompetitionPhases/SchedulePlanner/AutomaticSchedulePlanning/GreedyMatchdayScheduler";
import PlannerFilterFactory from "Domain/CompetitionPhases/SchedulePlanner/Filters/PlannerFiltersFactory";
import SchedulePlanner, {
    SchedulePlannerValidationErrors,
} from "Domain/CompetitionPhases/SchedulePlanner/SchedulePlanner";
import SchedulePlanningWizard from "Domain/CompetitionPhases/SchedulePlanner/SchedulePlanningWizard/SchedulePlanningWizard";
import { AvailableSportsFieldsConfiguration } from "Domain/CompetitionPhases/SchedulePlanner/SportsFieldConfiguration";
import competitionStore from "Domain/Competitions";
import seasonStore from "Domain/Season";
import SchedulePlannerBoard from "DomainComponents/SchedulePlanner/SchedulePlannerBoard";
import SchedulePlannerForm from "DomainComponents/SchedulePlanner/SchedulePlannerForm";
import SchedulePlanningWizardForm from "DomainComponents/SchedulePlanner/SchedulePlanningWizardForm";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import routes, { PageComponent } from "Router/routes";
import useRunInTask from "Utils/Hooks/useRunInTask";
import styles from "./styles.scss";

const cx = mkCx(styles);

const getSchedulePlannerErrorMessage = (validationError: SchedulePlannerValidationErrors): string | undefined => {
    switch (validationError) {
        case SchedulePlannerValidationErrors.ScheduleNotPlannedForAnySportsField:
            return l("CompetitionPhases_PlanSchedule_Errors_ScheduleNotPlannedForAnySportsField");
        case SchedulePlannerValidationErrors.MatchDatesNotGenerated:
            return l("CompetitionPhases_PlanSchedule_Errors_MatchDatesNotGenerated");
    }
};

const PlanSchedulePage: PageComponent<typeof routes.competitionPhase.planSchedule> = ({
    match: {
        params: { phaseId },
    },
    history: { push },
    location,
}) => {
    const [schedulePlanner, setSchedulePlanner] = useState<SchedulePlanner>();
    const [schedulePlanningWizard, setSchedulePlanningWizard] = useState<SchedulePlanningWizard>();
    const [isSaveInProgress, runSaveInTask] = useRunInTask();

    const phase = useObserver(() => competitionPhaseStore.getById(phaseId));
    const competition = useObserver(() => phase && competitionStore.getById(phase.competitionId));
    const season = useObserver(() => (competition?.seasonId ? seasonStore.getById(competition.seasonId) : undefined));

    useEffect(() => {
        competitionStore.fetchCompetitionPhaseDetails(phaseId);
    }, [phaseId]);

    const areDetailsFetched = phase?.teams !== undefined && competition?.teams !== undefined;

    useEffect(() => {
        if (phase && competition && areDetailsFetched) {
            setSchedulePlanner(new SchedulePlanner(competition, phase, PlannerFilterFactory.forPhase(phase)));
        }
    }, [phase, competition, areDetailsFetched]);

    const onSaveSchedule = useCallback(
        () =>
            runSaveInTask(async () => {
                if (schedulePlanner) {
                    const { sportsFieldsSaveHandler, schedulePlanSaveHandler } = await schedulePlanner?.save();

                    sportsFieldsSaveHandler
                        .handle(
                            [
                                "CompetitionNotFound",
                                "OneOfSportsFieldsIdAlreadyInUse",
                                "SportsFieldsDeletionNotSupported",
                                "SportsFieldsMissingOrEmpty",
                                "OneOfSportsFieldsNameMissingOrEmpty",
                                "OneOfSportsFieldsNameTooLong",
                                "failure",
                            ],
                            () => {
                                message.error(l("CompetitionPhases_PlanSchedule_Failure"));
                            },
                        )
                        .check();

                    schedulePlanSaveHandler
                        ?.handle(
                            [
                                "PhaseNotFoundInCompetition",
                                "OneOfMatchesNotFound",
                                "OneOfSportsFieldsNotFound",
                                "DefaultMatchDurationInMinutesInvalid",
                                "DefaultPauseBetweenMatchesInMinutesInvalid",
                                "OneOfMatchesIsAssignedToMultipleSlots",
                                "failure",
                            ],
                            () => {
                                message.error(l("CompetitionPhases_PlanSchedule_Failure"));
                            },
                        )
                        .handle("success", () => {
                            message.success(l("CompetitionPhases_PlanSchedule_Success"));

                            push(routes.competitionPhase.schedule({ phaseId: phaseId }));
                        })
                        .check();
                }
            }),
        [schedulePlanner, runSaveInTask, phaseId, push],
    );

    const onPlanScheduleAutomatically = useCallback(
        (availableSportsFieldsConfiguation: AvailableSportsFieldsConfiguration) => {
            if (!schedulePlanner) {
                throw new Error("Schedule planner not instantiated.");
            }

            const automaticPlanner = new GreedyMatchdayScheduler(schedulePlanner.slotsConfiguration);

            const result = automaticPlanner.plan(
                schedulePlanner.currentMatchdayConfiguration,
                schedulePlanner.filteredUnassignedMatches,
                availableSportsFieldsConfiguation,
            );

            if (result === PlanResult.PartiallyPlanned) {
                message.info(l("CompetitionPhases_PlanSchedule_AutomaticSchedulePlanning_SchedulePartiallyPlanned"));
            }
        },
        [schedulePlanner],
    );

    const onOpenSchedulePlanningWizard = useCallback(() => {
        if (!schedulePlanner) {
            return;
        }

        setSchedulePlanningWizard(new SchedulePlanningWizard(schedulePlanner));
    }, [schedulePlanner]);

    const breadcrumb: BreadcrumbProps["routes"] = useMemo(
        () =>
            phase && competition
                ? [
                      {
                          breadcrumbName: season ? season.name : l("Competitions_SeasonSelection_NoSeason"),
                          path: routes.competitions({ seasonId: competition.seasonId }),
                      },
                      {
                          breadcrumbName: competition.name,
                          path: routes.competitionDetails.phases({ competitionId: competition.id }),
                      },
                      {
                          breadcrumbName: phase?.name ?? l("CompetitionPhases_Details_NoTitlePhase"),
                          path: routes.competitionPhase({ phaseId: phase.id }),
                      },
                      {
                          breadcrumbName: l("CompetitionPhases_PlanSchedule_Header"),
                          path: routes.competitionPhase.planSchedule({ phaseId: phase.id }),
                      },
                  ]
                : [],
        [competition, phase, season],
    );

    return useObserver(() => (
        <>
            <PageContent wide>
                <PageContent.Header>
                    <PageContent.HeaderSkeleton active={!areDetailsFetched} loading={!areDetailsFetched}>
                        <PageHeader
                            title={l("CompetitionPhases_PlanSchedule_Header")}
                            onBack={() => push(routes.competitionPhase({ phaseId: phaseId }))}
                            breadcrumb={{
                                routes: breadcrumb,
                                itemRender: ({ path, breadcrumbName }) =>
                                    path === location.pathname ? (
                                        breadcrumbName
                                    ) : (
                                        <Link to={path}>{breadcrumbName}</Link>
                                    ),
                            }}
                            extra={
                                schedulePlanner ? (
                                    <Spacing className={cx("header-extra-container")}>
                                        <Spacing childrenGutterX>
                                            <Button onClick={() => push(routes.competitionPhase({ phaseId: phaseId }))}>
                                                {l("Common_Cancel")}
                                            </Button>
                                            <Button
                                                type="primary"
                                                onClick={onSaveSchedule}
                                                loading={isSaveInProgress}
                                                disabled={
                                                    isSaveInProgress || schedulePlanner.validationError !== undefined
                                                }>
                                                {l("Common_Save")}
                                            </Button>
                                        </Spacing>
                                        {schedulePlanner.validationError !== undefined && (
                                            <Typography.Text type="danger">
                                                {getSchedulePlannerErrorMessage(schedulePlanner.validationError)}
                                            </Typography.Text>
                                        )}
                                    </Spacing>
                                ) : undefined
                            }
                        />
                    </PageContent.HeaderSkeleton>
                </PageContent.Header>
                <PageContent.Card>
                    <Skeleton active={!areDetailsFetched} loading={!areDetailsFetched}>
                        {schedulePlanner && (
                            <Spacing childrenGutterY>
                                <div className={cx("planner-control")}>
                                    <SchedulePlannerForm planner={schedulePlanner} />
                                    <Spacing childrenGutterX>
                                        <Button
                                            onClick={onOpenSchedulePlanningWizard}
                                            disabled={
                                                !schedulePlanner.hasAnyNonCancelledUnassignedMatches ||
                                                !schedulePlanner.canInferMatchDates
                                            }>
                                            {l("CompetitionPhases_PlanSchedule_OpenWizard")}
                                        </Button>
                                        <DropdownMenu
                                            menuItems={[
                                                {
                                                    children: l("CompetitionPhases_PlanSchedule_ClearSportsFields"),
                                                    onClick:
                                                        schedulePlanner.currentMatchdayConfiguration.clearSportsFields,
                                                },
                                            ]}
                                        />
                                    </Spacing>
                                </div>
                                <SchedulePlannerBoard
                                    phases={phase ? [phase] : undefined}
                                    matchConflicts={schedulePlanner.currentMatchdayConfiguration.matchConflicts}
                                    canPlanScheduleAutomatically={schedulePlanner.canPlanCurrentMatchdayAutomatically}
                                    sportsFields={schedulePlanner.sportsFields}
                                    schedulePlan={schedulePlanner.currentMatchdayConfiguration.schedule}
                                    unassignedMatches={schedulePlanner.filteredUnassignedMatches}
                                    onAddSportsField={schedulePlanner.addSportsField}
                                    onChange={schedulePlanner.currentMatchdayConfiguration.moveSlot}
                                    onSportsFieldStartTimeChange={
                                        schedulePlanner.currentMatchdayConfiguration.setSportsFieldStartTime
                                    }
                                    onAddBreak={schedulePlanner.currentMatchdayConfiguration.addBreakToSportsField}
                                    onRemoveBreak={schedulePlanner.currentMatchdayConfiguration.removeBreak}
                                    onPlanScheduleAutomatically={onPlanScheduleAutomatically}
                                    filter={schedulePlanner.filter}
                                />
                            </Spacing>
                        )}
                    </Skeleton>
                </PageContent.Card>
            </PageContent>
            {schedulePlanningWizard && (
                <SchedulePlanningWizardForm
                    wizard={schedulePlanningWizard}
                    onClose={() => setSchedulePlanningWizard(undefined)}
                />
            )}
        </>
    ));
};

export default PlanSchedulePage;
