import { BlobServiceClient, BlockBlobParallelUploadOptions } from "@azure/storage-blob";
import { AzureStorageTokenDTO, UploadLinksDTO } from "Contracts/PlayooLeagueClient";

class BlobStorageClient {
    public uploadBlob(blobName: string, file: File, token: AzureStorageTokenDTO) {
        const blobServiceClient = new BlobServiceClient(token.StorageUri + token.SharedAccessSignatureToken);

        const containerClient = blobServiceClient.getContainerClient(token.ContainerName);

        const blockBlobClient = containerClient.getBlockBlobClient(blobName);

        const options: BlockBlobParallelUploadOptions | undefined = {
            blobHTTPHeaders: {
                blobContentType: file.type,
            },
        };

        return blockBlobClient.uploadData(file, options);
    }

    public async uploadForPresignedLink(file: File, uploadLinks: UploadLinksDTO) {
        const uploadResponse = await fetch(uploadLinks.PresignedLink, {
            method: "PUT",
            body: file,
            headers: { ...uploadLinks.RequiredHeaders, "Content-Type": file.type },
        });

        if (!uploadResponse.ok) {
            throw new Error(`Could not upload document, storage responded with status code ${uploadResponse.status}`);
        }
    }

    public blobUriFromToken(token: AzureStorageTokenDTO, fileName: string) {
        return `${token.StorageUri}/${token.ContainerName}/${fileName}`;
    }
}

const blobStorageClient = new BlobStorageClient();

export default blobStorageClient;
