import { DownOutlined, EditOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Typography } from "antd";
import Button from "antd/es/button";
import Table, { ColumnProps } from "antd/lib/table";
import DropdownMenu from "Components/DropdownMenu";
import GroupsPhase from "Domain/CompetitionPhases/GroupsPhase/GroupsPhase";
import TablePhase from "Domain/CompetitionPhases/TablePhase/TablePhase";
import TieBreakingOrderTeam from "Domain/CompetitionPhases/TieBreakingOrderTeam";
import { l } from "Languages";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import styles from "./styles.scss";

const cx = mkCx(styles);

type TableOrderModifiersProps = {
    phase: TablePhase | GroupsPhase;
};

const TableOrderModifiers: React.FunctionComponent<TableOrderModifiersProps> = observer(({ phase }) => {
    useEffect(() => {
        phase.sortTieBreakingOrder();
    }, [phase]);

    const [isEditing, setIsEditing] = useState(false);

    const order = phase.teamsTieBreakingOrder?.slice() ?? [];

    const columns = useMemo(
        (): ColumnProps<TieBreakingOrderTeam>[] => [
            {
                title: l("CompetitionPhases_TableOrderModifiers_TeamName"),
                render: (_, t, index) => (
                    <div className={cx("spaced-line")}>
                        <div>{t.team?.displayName}</div>
                        {isEditing && (
                            <div className={cx("actions")}>
                                <DownOutlined
                                    className={cx(index === 0 && "disabled")}
                                    rotate={180}
                                    onClick={() => index !== 0 && phase.changeTieBreakingOrder(index, "up")}
                                />
                                <DownOutlined
                                    className={cx(index === order.length - 1 && "disabled")}
                                    onClick={() =>
                                        index !== order.length - 1 && phase.changeTieBreakingOrder(index, "down")
                                    }
                                />
                            </div>
                        )}
                    </div>
                ),
            },
        ],
        [isEditing, order.length, phase],
    );

    return (
        <>
            <div className={cx("spaced-line")}>
                <Typography.Title level={4}>{l("CompetitionPhases_TableOrderModifiers")}</Typography.Title>
                {isEditing ? (
                    <div className={cx("actions")}>
                        <Button
                            onClick={() => {
                                setIsEditing(false);
                                phase.sortTieBreakingOrder();
                            }}>
                            {l("Common_Cancel")}
                        </Button>
                        <Button
                            type="primary"
                            onClick={async () => {
                                const success = await phase.saveTieBreakingOrder();
                                if (success) setIsEditing(false);
                            }}>
                            {l("Common_Save")}
                        </Button>
                    </div>
                ) : (
                    <DropdownMenu
                        menuItems={[
                            {
                                children: (
                                    <>
                                        <EditOutlined /> {l("CompetitionPhases_TableOrderModifiers_Manage")}
                                    </>
                                ),
                                onClick: () => setIsEditing(true),
                            },
                        ]}
                    />
                )}
            </div>
            <Table rowKey={m => m.id} columns={columns} dataSource={order} pagination={false} />
        </>
    );
});

export default TableOrderModifiers;
