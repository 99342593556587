import {
    AlignLeftOutlined,
    BarcodeOutlined,
    CalendarOutlined,
    GroupOutlined,
    LogoutOutlined,
    MenuUnfoldOutlined,
    ScheduleOutlined,
    UngroupOutlined,
    UserOutlined,
} from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Button, Layout, Menu } from "antd";
import { ActiveTabKeySetter } from "Components/ActiveTabKeySetter";
import ViriumLogo from "Components/ViriumLogo";
import authentication from "Domain/Account/Authentication";
import { l } from "Languages";
import React, { useState } from "react";
import { Link, Route, Switch } from "react-router-dom";
import routes from "Router/routes";
import useIsMobile from "Utils/Hooks/useIsMobile";
import styles from "./styles.scss";

const cx = mkCx(styles);

type MenuKeys =
    | "competitions"
    | "access-code"
    | "articles"
    | "competition-groups"
    | "tenant"
    | "registrations"
    | "videos"
    | "landing"
    | "seasons";

const AppLayout: React.FunctionComponent = ({ children }) => {
    const [selectedKey, setSelectedKey] = useState<MenuKeys>();
    const isMobile = useIsMobile();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <>
            <Switch>
                <Route path={routes.seasons.pattern}>
                    <ActiveTabKeySetter setKey={() => setSelectedKey("seasons")} />
                </Route>
                <Route path={routes.articles.pattern}>
                    <ActiveTabKeySetter setKey={() => setSelectedKey("articles")} />
                </Route>
                <Route exact path={routes.accessCode.pattern}>
                    <ActiveTabKeySetter setKey={() => setSelectedKey("access-code")} />
                </Route>
                <Route exact path={routes.competitionGroups.pattern}>
                    <ActiveTabKeySetter setKey={() => setSelectedKey("competition-groups")} />
                </Route>
                <Route path={routes.tenant.pattern}>
                    <ActiveTabKeySetter setKey={() => setSelectedKey("tenant")} />
                </Route>
                <Route path={routes.registrations.pattern}>
                    <ActiveTabKeySetter setKey={() => setSelectedKey("registrations")} />
                </Route>
                <Route path={routes.videos.pattern}>
                    <ActiveTabKeySetter setKey={() => setSelectedKey("videos")} />
                </Route>
                <Route path={routes.landing.pattern}>
                    <ActiveTabKeySetter setKey={() => setSelectedKey("landing")} />
                </Route>
                <Route>
                    <ActiveTabKeySetter setKey={() => setSelectedKey("competitions")} />
                </Route>
            </Switch>
            <Layout className={cx("app-layout")}>
                <Layout.Sider
                    onCollapse={() => setIsMenuOpen(false)}
                    collapsed={isMobile ? !isMenuOpen : false}
                    theme="light"
                    className={cx("menu")}
                    width={isMobile ? "100%" : "12.5rem"}
                    collapsible={isMobile && isMenuOpen}>
                    {!isMobile && (
                        <div className={cx("logo-container")}>
                            <ViriumLogo />
                        </div>
                    )}
                    <Menu selectedKeys={selectedKey ? [selectedKey] : []} onClick={() => setIsMenuOpen(false)}>
                        <Menu.Item key="competitions">
                            <Link to={routes.index()}>
                                <CalendarOutlined /> {l("Menu_Competitions")}
                            </Link>
                            landing: segment`/landing
                        </Menu.Item>
                        <Menu.Item key="seasons">
                            <Link to={routes.seasons()}>
                                <UngroupOutlined /> {l("Seasons")}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="articles">
                            <Link to={routes.articles()}>
                                <AlignLeftOutlined /> {l("Articles")}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="videos">
                            <Link to={routes.videos()}>
                                <ScheduleOutlined /> {l("Videos")}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="competition-groups">
                            <Link to={routes.competitionGroups()}>
                                <GroupOutlined /> {l("CompetitionGroups")}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="access-code">
                            <Link to={routes.accessCode()}>
                                <BarcodeOutlined /> {l("Menu_AccessCode")}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="tenant">
                            <Link to={routes.tenant()}>
                                <UserOutlined /> {l("Tenant")}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="registrations">
                            <Link to={routes.registrations()}>
                                <ScheduleOutlined /> {l("Registrations")}
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="landing">
                            <Link to={routes.landing()}>
                                <ScheduleOutlined /> {l("Landing")}
                            </Link>
                        </Menu.Item>
                        <Menu.Item onClick={() => authentication.signOut()}>
                            <LogoutOutlined /> {l("AppBar_SignOut")}
                        </Menu.Item>
                    </Menu>
                </Layout.Sider>
                <Layout.Content className={cx("content")}>
                    {isMobile && (
                        <Button type="primary" onClick={() => setIsMenuOpen(true)} className={cx("expand-menu-button")}>
                            <MenuUnfoldOutlined />
                        </Button>
                    )}
                    {children}
                </Layout.Content>
            </Layout>
        </>
    );
};

export default AppLayout;
