import { PhaseTypeDTO } from "Contracts/PlayooLeagueClient";
import _ from "lodash";
import newId from "Utils/newId";
import { CompetitionPhase } from "../CompetitionPhase";
import { LinkablePhase } from "./ExternalReference";
import { ExternalReferenceType } from "./ExternalReferenceBase";
import NthBestForPlaceInGroupReference from "./NthBestForPlaceInGroupReference";
import PlaceInGroupReference from "./PlaceInGroupReference";
import PlaceInTableReference from "./PlaceInTableReference";

export type PlaceInGroupRelatedReferences = {
    placeInGroup: number;
    placeInGroupReferences: PlaceInGroupReference[];
    nthBestForPlaceInGroupReferences: NthBestForPlaceInGroupReference[];
    addedPlaceInGroupReferences: PlaceInGroupReference[];
    addedNthBestForPlaceInGroupReferences: NthBestForPlaceInGroupReference[];
};

export type PlaceInTableRelatedReferences = {
    placeInTableReference?: PlaceInTableReference;
    addedPlaceInTableReference?: PlaceInTableReference;
};

export type RelatedReferences = PlaceInGroupRelatedReferences & PlaceInTableRelatedReferences;

class AvailablePhaseReferences {
    private readonly phase: CompetitionPhase;
    private readonly linkedPhase: LinkablePhase;

    constructor(phase: CompetitionPhase, previousPhase: LinkablePhase) {
        if (phase.linkedPhaseId !== previousPhase.id) {
            throw new Error("Phase is not linked to previous phase.");
        }

        this.phase = phase;
        this.linkedPhase = previousPhase;
    }

    get value(): RelatedReferences[] | undefined {
        const highestPossiblePlaceInGroup =
            "groups" in this.linkedPhase
                ? _.maxBy(this.linkedPhase.groups, g => g.teamIds.length)?.teamIds.length
                : this.linkedPhase.teamsIds?.length ?? 0;

        if (highestPossiblePlaceInGroup === undefined) {
            return undefined;
        }

        const currentlyAddedPlaceInGroupReferences = this.currentlyAddedPlaceInGroupReferences;
        const currentlyAddedNthBestForPlaceInGroupReferences = this.currentlyAddedNthBestForPlaceInGroupReferences;
        const currentlyAddedPlaceInTableReferences = this.currentlyAddedPlaceInTableReferences;

        const phasePlaceInGroupReferences = (this.phase.externalReferences?.filter(
            r => r.type === ExternalReferenceType.PlaceInGroup,
        ) ?? []) as PlaceInGroupReference[];
        const phaseNthBestPlaceInGroupReferences = (this.phase.externalReferences?.filter(
            r => r.type === ExternalReferenceType.NthBestForPlaceInGroup,
        ) ?? []) as NthBestForPlaceInGroupReference[];
        const phasePlaceInTableReferences = (this.phase.externalReferences?.filter(
            r => r.type === ExternalReferenceType.PlaceInTable,
        ) ?? []) as PlaceInTableReference[];

        return _.range(1, highestPossiblePlaceInGroup + 1).map(placeInGroup => {
            const groupsWithPlaceInGroupPossible =
                "groups" in this.linkedPhase
                    ? this.linkedPhase.groups?.filter(g => g.teamIds.length >= placeInGroup) ?? []
                    : [];

            const addedPlaceInGroupReferences = currentlyAddedPlaceInGroupReferences.filter(
                r => r.placeInGroup === placeInGroup,
            );

            const placeInGroupReferences = groupsWithPlaceInGroupPossible
                .map(
                    g =>
                        phasePlaceInGroupReferences.find(r => r.placeInGroup === placeInGroup && r.groupId === g.id) ??
                        new PlaceInGroupReference({
                            id: newId(),
                            placeInGroup: placeInGroup,
                            groupId: g.id,
                            groupName: g.name,
                            phaseId: this.phase.id,
                            referencedPhaseId: this.linkedPhase.id,
                        }),
                )
                .filter(
                    r =>
                        this.phase.type !== PhaseTypeDTO.Groups ||
                        !addedPlaceInGroupReferences.some(added => added.groupId === r.groupId),
                );

            const addedNthBestForPlaceInGroupReferences = currentlyAddedNthBestForPlaceInGroupReferences.filter(
                r => r.placeInGroup === placeInGroup,
            );

            const nthBestForPlaceInGroupReferences = _.range(1, groupsWithPlaceInGroupPossible.length + 1)
                .map(
                    place =>
                        phaseNthBestPlaceInGroupReferences.find(
                            r => r.placeInGroup === placeInGroup && r.place === place,
                        ) ??
                        new NthBestForPlaceInGroupReference({
                            id: newId(),
                            phaseId: this.phase.id,
                            place,
                            placeInGroup,
                            referencedPhaseId: this.linkedPhase.id,
                        }),
                )
                .filter(
                    r =>
                        this.phase.type !== PhaseTypeDTO.Groups ||
                        !addedNthBestForPlaceInGroupReferences.some(added => added.place === r.place),
                );

            const addedPlaceInTableReference =
                this.linkedPhase.type === PhaseTypeDTO.Table
                    ? currentlyAddedPlaceInTableReferences.filter(r => r.placeInTable === placeInGroup)?.[0]
                    : undefined;

            const placeInTableReference =
                this.linkedPhase.type === PhaseTypeDTO.Table &&
                !(this.phase.type === PhaseTypeDTO.Groups && addedPlaceInTableReference)
                    ? phasePlaceInTableReferences.find(r => r.placeInTable === placeInGroup) ??
                      new PlaceInTableReference({
                          id: newId(),
                          placeInTable: placeInGroup,
                          phaseId: this.phase.id,
                          referencedPhaseId: this.linkedPhase.id,
                      })
                    : undefined;

            return {
                placeInGroup,
                placeInGroupReferences,
                nthBestForPlaceInGroupReferences,
                placeInTableReference,
                addedPlaceInGroupReferences,
                addedNthBestForPlaceInGroupReferences,
                addedPlaceInTableReference,
            };
        });
    }

    private get currentlyAddedPlaceInGroupReferences() {
        return (
            this.phase.externalReferences?.filter(
                (t): t is PlaceInGroupReference => t !== undefined && t.type === ExternalReferenceType.PlaceInGroup,
            ) ?? []
        );
    }

    private get currentlyAddedNthBestForPlaceInGroupReferences() {
        return (
            this.phase.externalReferences?.filter(
                (t): t is NthBestForPlaceInGroupReference =>
                    t !== undefined && t.type === ExternalReferenceType.NthBestForPlaceInGroup,
            ) ?? []
        );
    }

    private get currentlyAddedPlaceInTableReferences() {
        return (
            this.phase.externalReferences?.filter(
                (t): t is PlaceInTableReference => t !== undefined && t.type === ExternalReferenceType.PlaceInTable,
            ) ?? []
        );
    }
}

export default AvailablePhaseReferences;
