import { PhaseTypeDTO } from "Contracts/PlayooLeagueClient";
import { CompetitionPhase } from "../CompetitionPhase";
import GroupsPhase from "../GroupsPhase/GroupsPhase";
import TablePhase from "../TablePhase/TablePhase";
import NthBestForPlaceInGroupReference from "./NthBestForPlaceInGroupReference";
import PlaceInGroupReference from "./PlaceInGroupReference";
import PlaceInTableReference from "./PlaceInTableReference";

export type ExternalReference = PlaceInGroupReference | NthBestForPlaceInGroupReference | PlaceInTableReference;

export type LinkablePhase = GroupsPhase | TablePhase;
export const linkablePhases = [PhaseTypeDTO.Groups, PhaseTypeDTO.Table];
export const isLinkablePhase = (phase: CompetitionPhase) => phase instanceof GroupsPhase || phase instanceof TablePhase;
