/* eslint-disable react/jsx-no-undef */
import {
    ArrowRightOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    PauseCircleOutlined,
    PlayCircleOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Modal, Table, Tag, Button } from "antd";
import { ColumnProps } from "antd/lib/table";
import DropdownMenu from "Components/DropdownMenu";
import EmptyState from "Components/EmptyState";
import NavigationLink from "Components/NavigationLink";
import PageContent from "Components/PageContent";
import { CompetitionPhase } from "Domain/CompetitionPhases/CompetitionPhase";
import Competition from "Domain/Competitions/Competition";
import { l } from "Languages";
import { Observer, useObserver } from "mobx-react-lite";
import CreateCompetitionPhaseDialog from "Pages/Competitions/CompetitionDetails/CompetitionPhases/CreateCompetitionPhaseDialog";
import React, { useCallback, useMemo, useState } from "react";
import routes from "Router/routes";
import { formatPhaseType } from "Utils/formatting";
import RenamePhaseFormDialog from "./RenamePhaseFormDialog";
import styles from "./styles.scss";

const cx = mkCx(styles);

type CompetitionPhasesProps = {
    competition: Competition;
};

const CompetitionPhases: React.FunctionComponent<CompetitionPhasesProps> = ({ competition }) => {
    const [showCreatePhaseDialog, setShowCreatePhaseDialog] = useState(false);
    const [phaseToEditName, setPhaseToEditName] = useState<CompetitionPhase>();

    const showRemovePhaseConfirmation = useCallback(
        (phaseId: string) => {
            Modal.confirm({
                onOk: () => competition.removePhase(phaseId),
                icon: <ExclamationCircleOutlined />,
                title: l("CompetitionDetails_Phases_Remove_Confirm_Title"),
                content: l("CompetitionDetails_Phases_Remove_Confirm_Content"),
                okText: l("Common_Remove"),
                okButtonProps: {
                    danger: true,
                },
                cancelText: l("Common_Cancel"),
                centered: true,
                maskClosable: true,
            });
        },
        [competition],
    );

    const columns = useMemo(
        (): ColumnProps<CompetitionPhase>[] => [
            {
                title: l("Common_Ordinal"),
                render: (_, phase, ind) => ind + 1,
            },
            {
                title: l("CompetitionDetails_Phases_Type"),
                render: (_, phase) => (
                    <NavigationLink to={routes.competitionPhase({ phaseId: phase.id })}>
                        {formatPhaseType(phase.type)}
                    </NavigationLink>
                ),
            },
            {
                title: l("CompetitionDetails_Phases_Name"),
                render: (_, phase) => phase.name,
            },
            {
                render: (_, phase) => (
                    <Observer>
                        {() => <Tag visible={phase.isOngoing}>{l("CompetitionDetails_Phases_Ongoing")}</Tag>}
                    </Observer>
                ),
            },
            {
                title: l("Common_Actions"),
                width: "120px",
                render: (_, phase) => (
                    <Observer>
                        {() => (
                            <div className={cx("actions-cell")}>
                                <DropdownMenu
                                    menuItems={[
                                        {
                                            children: (
                                                <>
                                                    <EditOutlined /> {l("Common_Edit")}
                                                </>
                                            ),
                                            onClick: () => setPhaseToEditName(phase),
                                        },
                                        {
                                            children: (
                                                <>
                                                    <PlayCircleOutlined /> {l("CompetitionDetails_Phases_Start")}
                                                </>
                                            ),
                                            onClick: () => competition.beginPhase(phase.id),
                                            disabled: phase.isOngoing,
                                        },
                                        {
                                            children: (
                                                <>
                                                    <PauseCircleOutlined /> {l("CompetitionDetails_Phases_Finish")}
                                                </>
                                            ),
                                            onClick: () => competition.endPhase(phase.id),
                                            disabled: !phase.isOngoing,
                                        },
                                        {
                                            children: (
                                                <>
                                                    <DeleteOutlined /> {l("CompetitionDetails_Phases_Remove")}
                                                </>
                                            ),
                                            onClick: () => showRemovePhaseConfirmation(phase.id),
                                            disabled: competition.isPhaseReferencedByOtherPhase(phase.id),
                                        },
                                    ]}
                                />
                                <NavigationLink to={routes.competitionPhase({ phaseId: phase.id })}>
                                    <Button>
                                        <ArrowRightOutlined />
                                    </Button>
                                </NavigationLink>
                            </div>
                        )}
                    </Observer>
                ),
            },
        ],
        [competition, showRemovePhaseConfirmation],
    );

    return useObserver(() => (
        <>
            <Table
                dataSource={competition.phases}
                columns={columns}
                pagination={false}
                rowKey="id"
                locale={{
                    emptyText: <EmptyState text={l("CompetitionDetails_NoPhases")} />,
                }}
            />
            <PageContent.ActionButton onClick={() => setShowCreatePhaseDialog(true)}>
                <PlusOutlined /> {l("CompetitionDetails_AddNewPhase")}
            </PageContent.ActionButton>
            {showCreatePhaseDialog && competition.phases && (
                <CreateCompetitionPhaseDialog
                    competition={competition}
                    onClose={() => setShowCreatePhaseDialog(false)}
                />
            )}
            {phaseToEditName && (
                <RenamePhaseFormDialog phase={phaseToEditName} onClose={() => setPhaseToEditName(undefined)} />
            )}
        </>
    ));
};

export default CompetitionPhases;
