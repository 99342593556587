import { EditOutlined, PlusOutlined, RetweetOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Button, Skeleton, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import DropdownMenu from "Components/DropdownMenu";
import EmptyState from "Components/EmptyState";
import PageContent from "Components/PageContent";
import ReorderButtons from "Components/ReorderButtons";
import Spacing from "Components/Spacing";
import competitionGroupStore from "Domain/CompetitionGroups";
import CompetitionGroup from "Domain/CompetitionGroups/CompetitionGroup";
import ReorderCompetitionGroupStore from "Domain/CompetitionGroups/ReorderCompetitionGroupStore";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import routes, { PageComponent } from "Router/routes";
import useRunInTask from "Utils/Hooks/useRunInTask";
import CompetitionGroupFormDialog from "./CompetitionGroupFormDialog";
import styles from "./styles.scss";

const cx = mkCx(styles);

type CompetitionGroupFormState =
    | {
          mode: "edit";
          competitionGroup: CompetitionGroup;
      }
    | { mode: "create" | "off" };

const CompetitionGroupsPage: PageComponent<typeof routes.seasons> = () => {
    const [competitionGroupsFetchInProgress, runCompetitionGroupsFetchInTask] = useRunInTask();
    const [competitionGroupFormState, setCompetitionGroupFormState] = useState<CompetitionGroupFormState>({
        mode: "off",
    });

    const reorderGroupsStore = useMemo(() => new ReorderCompetitionGroupStore(competitionGroupStore), []);

    const columns = useMemo(
        (): ColumnProps<CompetitionGroup>[] => [
            {
                render: (_, group) => group.name,
            },
            {
                render: (_, group, index) => {
                    return reorderGroupsStore.isReordering ? (
                        <ReorderButtons
                            isFirst={index === 0}
                            isLast={index === competitionGroupStore.items.length - 1}
                            onMoveUp={() => reorderGroupsStore.moveItemUp(group.id)}
                            onMoveDown={() => reorderGroupsStore.moveItemDown(group.id)}
                        />
                    ) : (
                        <DropdownMenu
                            menuItems={[
                                {
                                    children: (
                                        <>
                                            <EditOutlined /> {l("CompetitionGroups_Edit")}
                                        </>
                                    ),
                                    onClick: () =>
                                        setCompetitionGroupFormState({ mode: "edit", competitionGroup: group }),
                                },
                            ]}
                        />
                    );
                },
                align: "right",
            },
        ],
        [reorderGroupsStore],
    );

    useEffect(() => {
        runCompetitionGroupsFetchInTask(() => competitionGroupStore.fetchCompetitionGroups());
    }, [runCompetitionGroupsFetchInTask]);

    return useObserver(() => (
        <>
            <PageContent>
                <PageContent.Card
                    title={l("CompetitionGroups")}
                    extra={
                        <Spacing childrenGutterY className={cx("header-more")}>
                            <DropdownMenu
                                menuItems={[
                                    {
                                        children: (
                                            <>
                                                <RetweetOutlined /> {l("CompetitionGroups_Reorder")}
                                            </>
                                        ),
                                        onClick: () => reorderGroupsStore.startCompetitionGroupsReorder(),
                                        disabled: competitionGroupStore.items.length <= 1,
                                    },
                                ]}
                            />
                            {reorderGroupsStore.isReordering && (
                                <Spacing childrenGutterX>
                                    <Button onClick={() => reorderGroupsStore.stopReorder()}>
                                        {l("Common_Cancel")}
                                    </Button>
                                    <Button
                                        type="primary"
                                        onClick={() => reorderGroupsStore.saveCompetitionGroupsOrder()}>
                                        {l("Common_Save")}
                                    </Button>
                                </Spacing>
                            )}
                        </Spacing>
                    }>
                    <Skeleton loading={competitionGroupsFetchInProgress} active={competitionGroupsFetchInProgress}>
                        <Spacing childrenGutterY>
                            <Table
                                columns={columns}
                                dataSource={reorderGroupsStore.draft ?? competitionGroupStore.items}
                                pagination={false}
                                showHeader={false}
                                locale={{ emptyText: <EmptyState text={l("CompetitionGroups_EmptyState")} /> }}
                            />
                            {!reorderGroupsStore.isReordering && (
                                <PageContent.ActionButton
                                    onClick={() => setCompetitionGroupFormState({ mode: "create" })}>
                                    <PlusOutlined /> {l("CompetitionGroups_Add")}
                                </PageContent.ActionButton>
                            )}
                        </Spacing>
                    </Skeleton>
                </PageContent.Card>
            </PageContent>
            {competitionGroupFormState.mode !== "off" && (
                <CompetitionGroupFormDialog
                    competitionGroup={
                        competitionGroupFormState.mode === "edit"
                            ? competitionGroupFormState.competitionGroup
                            : undefined
                    }
                    onClose={() => {
                        setCompetitionGroupFormState({ mode: "off" });
                    }}
                />
            )}
        </>
    ));
};

export default CompetitionGroupsPage;
