import { ExclamationCircleOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import { CompetitionPhase } from "Domain/CompetitionPhases/CompetitionPhase";
import { l } from "Languages";
import React, { useCallback } from "react";

const useScheduleDeletion = (phase: CompetitionPhase) => {
    const deleteSchedule = useCallback(async () => {
        const result = await phase.deleteSchedule();

        result
            .handle(["PhaseNotFound", "ScheduleNotGenerated", "failure"], () => {
                message.error(l("CompetitionPhases_Details_DeleteSchedule_Failure"));
            })
            .handle("success", () => {
                message.success(l("CompetitionPhases_Details_DeleteSchedule_Success"));
            })
            .check();
    }, [phase]);

    const showDeleteScheduleConfirmation = useCallback(() => {
        Modal.confirm({
            onOk: deleteSchedule,
            icon: <ExclamationCircleOutlined />,
            title: l("CompetitionPhases_Details_DeleteSchedule_Confirmation_Title"),
            content: l("CompetitionPhases_Details_DeleteSchedule_Confirmation_Content"),
            okText: l("CompetitionPhases_Details_DeleteSchedule_Confirmation_OkText"),
            okButtonProps: {
                danger: true,
            },
            cancelText: l("Common_Cancel"),
            centered: true,
            maskClosable: true,
        });
    }, [deleteSchedule]);

    return showDeleteScheduleConfirmation;
};

export default useScheduleDeletion;
