import moment from "moment";

export type SlotRange = {
    startTime?: moment.Moment;
    endTime?: moment.Moment;
};

export const areSlotsOverlapping = (slot1Range: SlotRange, slot2Range: SlotRange) =>
    !(
        slot1Range.startTime?.isSameOrAfter(slot2Range.endTime, "minutes") ||
        slot1Range.endTime?.isSameOrBefore(slot2Range.startTime, "minutes")
    );
