import {
    MatchEventDTO,
    MatchEventSideDTO,
    MatchEventSubtypeDTO,
    MatchEventTypeDTO,
} from "Contracts/PlayooLeagueClient";
import { l } from "Languages";
import { computed, observable } from "mobx";

class MatchEvent {
    @observable secondOfMatch?: number;
    @observable side: MatchEventSideDTO;
    @observable type: MatchEventTypeDTO;
    @observable subtype?: MatchEventSubtypeDTO;

    @observable penaltyValue?: number;

    @observable playerId?: string;
    @observable otherPlayerId?: string;
    @observable goalkeeperPlayerId?: string;

    constructor({
        secondOfMatch,
        side,
        type,
        goalkeeperPlayerId,
        otherPlayerId,
        penaltyValue,
        playerId,
        subtype,
    }: MatchEventInit) {
        this.secondOfMatch = secondOfMatch;
        this.side = side;
        this.type = type;
        this.subtype = subtype;
        this.penaltyValue = penaltyValue;
        this.playerId = playerId;
        this.otherPlayerId = otherPlayerId;
        this.goalkeeperPlayerId = goalkeeperPlayerId;
    }

    static fromDTO(dto: MatchEventDTO): MatchEvent {
        return new MatchEvent({
            secondOfMatch: dto.SecondOfMatch ?? undefined,
            side: dto.Side,
            type: dto.Type,
            subtype: dto.Subtype ?? undefined,
            penaltyValue: dto.PenaltyValue ?? undefined,
            playerId: dto.PlayerId ?? undefined,
            otherPlayerId: dto.OtherPlayerId ?? undefined,
            goalkeeperPlayerId: dto.GoalkeeperPlayerId ?? undefined,
        });
    }
    toDTO(): MatchEventDTO {
        return {
            Side: this.side,
            Type: this.type,
            Subtype: this.subtype,
            SecondOfMatch: this.secondOfMatch,
            PlayerId: this.playerId,
            OtherPlayerId: this.otherPlayerId,
            GoalkeeperPlayerId: this.goalkeeperPlayerId,
            PenaltyValue: this.penaltyValue,
        };
    }

    @computed get minuteOfMatch() {
        return this.secondOfMatch !== undefined ? Math.floor(this.secondOfMatch / 60) : undefined;
    }

    @computed get secondOfMatchMinute() {
        if (!this.secondOfMatch || !this.minuteOfMatch) {
            return undefined;
        }

        return this.secondOfMatch - this.minuteOfMatch * 60;
    }

    formatType(): string {
        switch (this.type) {
            case MatchEventTypeDTO.Goal:
                return l("MatchEvent_Type_Goal");
            case MatchEventTypeDTO.Foul:
                return l("MatchEvent_Type_Foul");
            case MatchEventTypeDTO.RedCard:
                return l("MatchEvent_Type_RedCard");
            case MatchEventTypeDTO.YellowCard:
                return l("MatchEvent_Type_YellowCard");
            case MatchEventTypeDTO.Shot:
                return l("MatchEvent_Type_Shot");
            case MatchEventTypeDTO.MinutesPenalty:
                return l("MatchEvent_Type_MinutesPenalty");
        }
    }

    formatSubtype(): string | undefined {
        if (this.type === MatchEventTypeDTO.Goal) {
            switch (this.subtype) {
                case MatchEventSubtypeDTO.FromNear:
                    return l("MatchEvent_Subtype_Goal_FromNear");
                case MatchEventSubtypeDTO.FromDistance:
                    return l("MatchEvent_Subtype_Goal_FromDistance");
                case MatchEventSubtypeDTO.FollowUp:
                    return l("MatchEvent_Subtype_Goal_FollowUp");
                case MatchEventSubtypeDTO.Head:
                    return l("MatchEvent_Subtype_Goal_Head");
                case MatchEventSubtypeDTO.Penalty:
                    return l("MatchEvent_Subtype_Goal_Penalty");
                case MatchEventSubtypeDTO.FreeKick:
                    return l("MatchEvent_Subtype_Goal_FreeKick");
                case MatchEventSubtypeDTO.Own:
                    return l("MatchEvent_Subtype_Goal_Own");
            }
        } else if (this.type === MatchEventTypeDTO.Shot) {
            switch (this.subtype) {
                case MatchEventSubtypeDTO.OnTarget:
                    return l("MatchEvent_Subtype_Shot_OnTarget");
                case MatchEventSubtypeDTO.OffTarget:
                    return l("MatchEvent_Subtype_Shot_OffTarget");
                case MatchEventSubtypeDTO.GoalPost:
                    return l("MatchEvent_Subtype_Shot_GoalPost");
                case MatchEventSubtypeDTO.Crossbar:
                    return l("MatchEvent_Subtype_Shot_Crossbar");
            }
        }
    }
}

export type MatchEventInit = Pick<
    MatchEvent,
    "secondOfMatch" | "side" | "type" | "subtype" | "playerId" | "otherPlayerId" | "goalkeeperPlayerId" | "penaltyValue"
>;

export default MatchEvent;
