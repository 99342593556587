import { isValidImageFileExtension } from "Utils/validationHelpers";
import extractExtension from "./extractExtension";

const imageFileToBase64 = (file: File) => {
    const extension = extractExtension(file);

    if (!extension || !isValidImageFileExtension(extension)) {
        return undefined;
    }

    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
            typeof reader.result === "string" ? resolve(reader.result) : reject("Cannot convert to Base64.");
        reader.onerror = error => reject(error);
    });
};

export default imageFileToBase64;
