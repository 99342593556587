import { PlusOutlined } from "@ant-design/icons";
import mkCx, { rawClassName } from "@leancode/cx";
import { message } from "antd";
import { BannerTypeDTO } from "Contracts/CmsClient";
import homePageBannersConfiguration from "Domain/Banners";
import Banner from "Domain/Banners/Banner";
import { l } from "Languages";
import React, { useCallback, useState } from "react";
import BannerFormDialog, { BannerFormFields, BannerFormValidationErrors } from "../BannerFormDialog";
import styles from "./styles.scss";

const cx = mkCx(styles);

type BannerTileProps = {
    type: BannerTypeDTO;
    banner?: Banner;

    bannerClassName?: string;
};

const BannerTile: React.FunctionComponent<BannerTileProps> = ({ type, banner, bannerClassName }) => {
    const [showBannerFormDialog, setShowBannerFormDialog] = useState(false);

    const addBanner = useCallback(
        async (values: BannerFormFields) => {
            if (typeof values.picture === "string") {
                return;
            }

            const handler = await homePageBannersConfiguration.addBanner({
                type: type,
                linkUri: values.linkUri,
                picture: values.picture.file,
            });

            return handler
                .handle(["PictureUriMissingOrEmpty", "PictureUriTooLong"], error => ({
                    picture:
                        error === "PictureUriMissingOrEmpty"
                            ? l("Common_Validation_FieldRequired")
                            : l("Common_Validation_FieldTooLong"),
                }))
                .handle(["LinkUriTooLong", "LinkUriNotValid"], error => ({
                    linkUri:
                        error === "LinkUriTooLong"
                            ? l("Common_Validation_FieldTooLong")
                            : l("Landing_Banners_Form_Validation_LinkNotValid"),
                }))
                .handle(
                    ["PictureUriNotValid", "BannerIdAlreadyInUse", "BannerAlreadyAddedForSingleBannerType", "failure"],
                    () => {
                        message.error(l("Landing_Banners_Add_Failure"));
                    },
                )
                .handle("success", () => {
                    message.success(l("Landing_Banners_Add_Success"));

                    setShowBannerFormDialog(false);
                })
                .check({
                    reducer: (prev, curr) => {
                        return {
                            ...prev,
                            ...(curr || {}),
                        };
                    },
                    initialValue: {} as BannerFormValidationErrors,
                });
        },
        [type],
    );

    const editBanner = useCallback(async (banner: Banner, values: BannerFormFields) => {
        let picture: string | File;

        if (typeof values.picture === "string") {
            picture = values.picture;
        } else {
            picture = values.picture.file;
        }

        const handler = await banner.edit(picture, values.linkUri);

        return handler
            .handle(["PictureUriMissingOrEmpty", "PictureUriTooLong"], error => ({
                picture:
                    error === "PictureUriMissingOrEmpty"
                        ? l("Common_Validation_FieldRequired")
                        : l("Common_Validation_FieldTooLong"),
            }))
            .handle(["LinkUriTooLong", "LinkUriNotValid"], error => ({
                linkUri:
                    error === "LinkUriTooLong"
                        ? l("Common_Validation_FieldTooLong")
                        : l("Landing_Banners_Form_Validation_LinkNotValid"),
            }))
            .handle(["PictureUriNotValid", "BannerNotFound", "failure"], () => {
                message.error(l("Landing_Banners_Edit_Failure"));
            })
            .handle("success", () => {
                message.success(l("Landing_Banners_Edit_Success"));

                setShowBannerFormDialog(false);
            })
            .check({
                reducer: (prev, curr) => {
                    return {
                        ...prev,
                        ...(curr || {}),
                    };
                },
                initialValue: {} as BannerFormValidationErrors,
            });
    }, []);

    const onSave = useCallback(
        async (values: BannerFormFields) => {
            if (banner) {
                return editBanner(banner, values);
            } else {
                return addBanner(values);
            }
        },
        [addBanner, banner, editBanner],
    );

    const onDelete = useCallback(async () => {
        if (!banner) {
            return;
        }

        const handler = await homePageBannersConfiguration.deleteBanner(banner.id);

        handler
            .handle(["BannerNotFound", "failure"], () => {
                message.error(l("Landing_Banners_Delete_Failure"));
            })
            .handle("success", () => {
                message.success(l("Landing_Banners_Delete_Success"));

                setShowBannerFormDialog(false);
            })
            .check();
    }, [banner]);

    return (
        <>
            {banner ? (
                <img
                    src={banner.pictureUri}
                    className={cx("banner", rawClassName(bannerClassName))}
                    onClick={() => setShowBannerFormDialog(true)}
                />
            ) : (
                <div
                    className={cx("add-banner-button", rawClassName("ant-btn"), rawClassName("ant-btn-dashed"))}
                    onClick={() => setShowBannerFormDialog(true)}>
                    <PlusOutlined />
                    <span>{l("Landing_Banners_Add")}</span>
                </div>
            )}
            {showBannerFormDialog && (
                <BannerFormDialog
                    onSave={onSave}
                    onDeleteBanner={banner ? onDelete : undefined}
                    onClose={() => setShowBannerFormDialog(false)}
                    banner={banner}
                />
            )}
        </>
    );
};

export default BannerTile;
