import mkCx from "@leancode/cx";
import { Typography } from "antd";
import KnockoutPhaseMatchConfiguration from "Domain/CompetitionPhases/KnockoutPhase/KnockoutPhaseMatchConfiguration";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import { l } from "Languages";
import React, { useCallback } from "react";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import DroppableColumn from "../../Components/DroppableColumn";
import TeamItemDraggable from "../TeamItemDraggable";
import styles from "./styles.scss";

const cx = mkCx(styles);

const unassignedTeamsKey = "unassigned";

type GroupsBoardProps = {
    unassignedTeams: CompetitionTeam[];
    matches: KnockoutPhaseMatchConfiguration[];

    onChange: (teamId: string, destination: { matchIndex?: number; index: number }) => void;
};

const KnockoutPhaseFirstStageBoard: React.FunctionComponent<GroupsBoardProps> = ({
    unassignedTeams,
    matches,
    onChange,
}) => {
    const onDragEnd = useCallback(
        (result: DropResult) => {
            if (!result.destination) {
                return;
            }

            const matchIndex =
                result.destination.droppableId === unassignedTeamsKey
                    ? undefined
                    : parseInt(result.destination.droppableId);

            onChange(result.draggableId, { matchIndex: matchIndex, index: result.destination.index });
        },
        [onChange],
    );

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className={cx("columns")}>
                    <div className={cx("column")}>
                        <DroppableColumn
                            columnId={unassignedTeamsKey}
                            label={l("CompetitionPhases_Knockout_Board_UnassignedTeams")}>
                            {unassignedTeams.map((t, ind) => (
                                <TeamItemDraggable key={t.id} team={t} index={ind} />
                            ))}
                        </DroppableColumn>
                    </div>
                    <div className={cx("column", "matches")}>
                        <Typography.Title level={4}>{l("CompetitionPhases_Knockout_Board_Matches")}</Typography.Title>
                        {matches.map((m, ind) => (
                            <DroppableColumn
                                className={cx("match-droppable-column")}
                                key={ind}
                                label={l("CompetitionPhases_Knockout_MatchNumber", ind + 1)}
                                columnId={ind.toString()}>
                                {m.teams.map((t, ind) => (
                                    <TeamItemDraggable key={t.id} team={t} index={ind} />
                                ))}
                            </DroppableColumn>
                        ))}
                    </div>
                </div>
            </DragDropContext>
        </>
    );
};

export default KnockoutPhaseFirstStageBoard;
