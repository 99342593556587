import { useCallback, useState } from "react";

export default function useRunInTask() {
    const [isRunning, setIsRunning] = useState(false);

    const run = useCallback(<T>(block: () => Promise<T>) => {
        setIsRunning(true);
        return block().finally(() => {
            setIsRunning(false);
        });
    }, []);

    return [isRunning, run] as const;
}
