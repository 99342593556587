import _ from "lodash";
import moment from "moment";
import MatchdayConfiguration, { SportsFieldSchedule } from "./MatchdayConfiguration";
import { isMatch } from "./Slot";
import SlotsConfiguration from "./SlotsConfiguration";
import { AvailableSportsFieldsConfiguration } from "./SportsFieldConfiguration";

export type EmptySlot = {
    sportsFieldId: string;
    startTime: moment.Moment;
};

class SlotsIterator {
    private readonly slotsConfiguration: SlotsConfiguration;

    constructor(slotsConfiguration: SlotsConfiguration) {
        this.slotsConfiguration = slotsConfiguration;
    }

    getNextSlot(
        matchdayConfiguration: MatchdayConfiguration,
        availableSportsFieldsConfiguration: AvailableSportsFieldsConfiguration,
    ): EmptySlot | undefined {
        const sportsFieldPlansAvailableForScheduler = matchdayConfiguration.schedule.filter(plan =>
            availableSportsFieldsConfiguration.has(plan.sportsField.id),
        );

        const sportsFieldPlansWithFreeSlots = sportsFieldPlansAvailableForScheduler.filter(sf => {
            const nextEmptySlotStartTime = this.getNextEmptySlotStartTimeOnSportsField(sf);
            const matchEndTimeOnNextEmptySlot = moment(nextEmptySlotStartTime).add(
                this.slotsConfiguration.matchDurationInMinutes,
                "minutes",
            );
            const sportsFieldClosingTime = availableSportsFieldsConfiguration.get(sf.sportsField.id)?.closingTime;

            return sportsFieldClosingTime?.isSameOrAfter(matchEndTimeOnNextEmptySlot, "minutes") ?? true;
        });

        const sportsFieldWithNearestSlot = _.minBy(sportsFieldPlansWithFreeSlots, sf =>
            this.getNextEmptySlotStartTimeOnSportsField(sf),
        );

        if (!sportsFieldWithNearestSlot) {
            return undefined;
        }

        return {
            sportsFieldId: sportsFieldWithNearestSlot.sportsField.id,
            startTime: this.getNextEmptySlotStartTimeOnSportsField(sportsFieldWithNearestSlot),
        };
    }

    private getNextEmptySlotStartTimeOnSportsField(plan: SportsFieldSchedule) {
        return getNextEmptySlotStartTimeOnSportsField(plan, this.slotsConfiguration.distanceBetweenMatchesInMinutes);
    }
}

export default SlotsIterator;

export function getNextEmptySlotStartTimeOnSportsField(
    plan: SportsFieldSchedule,
    distanceBetweenMatchesInMinutes?: number,
) {
    const lastSlot = plan.slots[plan.slots.length - 1];

    if (!lastSlot) {
        if (!plan.startTime) {
            throw new Error("Matchday is not configured.");
        }

        return plan.startTime;
    }

    const slotDurationInMinutes = isMatch(lastSlot.slot)
        ? distanceBetweenMatchesInMinutes
        : lastSlot.slot.durationInMinutes;

    return moment(lastSlot.startTime).add(slotDurationInMinutes, "minutes");
}
