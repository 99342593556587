import { MatchDetailsDTO, PhaseTypeDTO } from "Contracts/PlayooLeagueClient";
import Article from "Domain/Articles/Article";
import CustomPhaseMatch from "Domain/CompetitionPhases/CustomPhase/CustomPhaseMatch";
import GroupsPhaseMatch from "Domain/CompetitionPhases/GroupsPhase/GroupsPhaseMatch";
import KnockoutPhaseMatch from "Domain/CompetitionPhases/KnockoutPhase/KnockoutPhaseMatch";
import { CompetitionTeamStore } from "Domain/Competitions/CompetitionTeamStore";
import { dateTimeOffsetFromDTOOptional } from "Utils/DTO";
import TablePhaseMatch from "../CompetitionPhases/TablePhase/TablePhaseMatch";
import { Match } from "./Match";
import { MatchInit } from "./MatchBase";
import MatchEvent from "./MatchEvent";
import MatchPlayer from "./MatchPlayer";
import MatchResult from "./MatchResult";

export class MatchFactory {
    static fromDTO(dto: MatchDetailsDTO, competitionTeamStore: CompetitionTeamStore): Match | undefined {
        const commonMatchInit: MatchInit = {
            team1Id: dto.Team1Id ?? undefined,
            team2Id: dto.Team2Id ?? undefined,
            date: dateTimeOffsetFromDTOOptional(dto.Date),
            article: dto.Article ? Article.fromDTO(dto.Article) : undefined,
            events: dto.Events?.map(MatchEvent.fromDTO),
            team1Lineup: dto.Team1Lineup?.map(MatchPlayer.fromDTO),
            team2Lineup: dto.Team2Lineup?.map(MatchPlayer.fromDTO),
            team1MvpId: dto.Team1MvpId ?? undefined,
            team2MvpId: dto.Team2MvpId ?? undefined,
            matchMvpId: dto.MatchMvpId ?? undefined,
            status: dto.Status,
            result: dto.Result ? MatchResult.fromDTO(dto.Result) : undefined,
            sportsFieldId: dto.SportsFieldId ?? undefined,
            name: dto.Name ?? undefined,
        };

        switch (dto.Phase.Type) {
            case PhaseTypeDTO.Table:
                return new TablePhaseMatch(
                    dto.Id,
                    dto.Phase.Id,
                    {
                        ...commonMatchInit,
                        matchday: dto.MatchInPhaseDesignator ?? 0,
                    },
                    competitionTeamStore,
                );

            case PhaseTypeDTO.Groups:
                if (!dto.LocalGroupId || !dto.GroupName) {
                    throw new Error("Groups phase match must be assigned to one of groups.");
                }

                return new GroupsPhaseMatch(
                    dto.Id,
                    dto.Phase.Id,
                    {
                        ...commonMatchInit,
                        matchday: dto.MatchInPhaseDesignator ?? 0,
                        groupId: dto.LocalGroupId,
                        groupName: dto.GroupName,
                    },
                    competitionTeamStore,
                );

            case PhaseTypeDTO.Custom:
                return new CustomPhaseMatch(
                    dto.Id,
                    dto.Phase.Id,
                    {
                        ...commonMatchInit,
                        matchGroupId: dto.LocalGroupId ?? undefined,
                        name: dto.Name ?? undefined,
                    },
                    competitionTeamStore,
                );

            case PhaseTypeDTO.Knockout:
                return new KnockoutPhaseMatch(
                    dto.Id,
                    dto.Phase.Id,
                    {
                        ...commonMatchInit,
                        nodeId: dto.MatchInPhaseDesignator,
                    },
                    competitionTeamStore,
                );
        }
    }
}

export default MatchFactory;
