import { DeleteOutlined, EditOutlined, MoreOutlined, RollbackOutlined, SyncOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Button, Tooltip, Typography } from "antd";
import { MenuItemProps } from "antd/lib/menu/MenuItem";
import DropdownMenu from "Components/DropdownMenu";
import Spacing from "Components/Spacing";
import TeamLogo from "Components/TeamLogo";
import { ExternalReference } from "Domain/CompetitionPhases/ExternalReferences/ExternalReference";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import ReplacePlaceholderTeamForPhaseSelect from "DomainComponents/ReplacePlaceholderTeamForPhaseSelect";
import SwapTeamsInGroupPhaseSelect from "DomainComponents/SwapTeamsInGroupPhaseSelect";
import { TeamItemDraggableProps } from "DomainComponents/TeamItemDraggable";
import { l } from "Languages";
import { observer } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import useRunInTask from "Utils/Hooks/useRunInTask";
import styles from "./styles.scss";

const cx = mkCx(styles);

type GroupsPhaseTeamItemDraggableProps = TeamItemDraggableProps & {
    availableTeamsForPlaceholderReplacement: CompetitionTeam[];
    availableTeamsForTeamSwap: CompetitionTeam[];
    externalReferenceForAdvancingTeam?: ExternalReference;
    predictedAdvancingTeam?: CompetitionTeam;
    isPredictedAdvancingTeamAlreadyInPhase?: boolean;

    onDelete?: () => void;
    onRenamePlaceholderForPhase?: () => void;
    onReplacePlaceholderForPhase?: (replacementId: string) => Promise<boolean>;
    onTeamsSwapForPhase?: (team1Id: string, team2Id: string) => Promise<boolean>;
    onAdvanceTeam?: () => void;
    onRevokeTeamAdvancing?: () => void;
};

const GroupsPhaseTeamItemDraggable: React.FunctionComponent<GroupsPhaseTeamItemDraggableProps> = observer(
    ({
        availableTeamsForPlaceholderReplacement,
        availableTeamsForTeamSwap,
        externalReferenceForAdvancingTeam,
        predictedAdvancingTeam,
        isPredictedAdvancingTeamAlreadyInPhase,
        onDelete,
        onRenamePlaceholderForPhase,
        onReplacePlaceholderForPhase,
        onTeamsSwapForPhase,
        onAdvanceTeam,
        onRevokeTeamAdvancing,
        team,
        index,
        isDragDisabled,
    }) => {
        const [isPlaceholderForPhaseReplacementInProgress, runPlaceholderTeamReplacementInTask] = useRunInTask();
        const [isPlaceholderForPhaseReplaced, setIsPlaceholderForPhaseReplaced] = useState<boolean>(false);

        const [isTeamSwapInProgress, runTeamSwapInTask] = useRunInTask();
        const [isTeamsSwapSelectOpen, setIsTeamsSwapSelectOpen] = useState<boolean>(false);

        const dropdownMenuItems: MenuItemProps[] = useMemo(
            () => [
                ...(onReplacePlaceholderForPhase
                    ? [
                          {
                              children: (
                                  <>
                                      <SyncOutlined /> {l("CompetitionPhases_PlaceholderTeams_Replace")}
                                  </>
                              ),
                              onClick: () => setIsPlaceholderForPhaseReplaced(true),
                          },
                      ]
                    : []),
                ...(onRenamePlaceholderForPhase
                    ? [
                          {
                              children: (
                                  <>
                                      <EditOutlined /> {l("CompetitionPhases_PlaceholderTeams_Edit")}
                                  </>
                              ),
                              onClick: onRenamePlaceholderForPhase,
                          },
                      ]
                    : []),
                ...(onDelete
                    ? [
                          {
                              children: (
                                  <>
                                      <DeleteOutlined /> {l("CompetitionDetails_Teams_Remove")}
                                  </>
                              ),
                              onClick: onDelete,
                          },
                      ]
                    : []),
                ...(onRevokeTeamAdvancing
                    ? [
                          {
                              children: (
                                  <>
                                      <RollbackOutlined />{" "}
                                      {l("CompetitionPhases_ExternalReferences_RevokeTeamAdvancing")}
                                  </>
                              ),
                              onClick: onRevokeTeamAdvancing,
                          },
                      ]
                    : []),
                ...(onTeamsSwapForPhase
                    ? [
                          {
                              children: (
                                  <>
                                      <SyncOutlined /> {l("CompetitionPhases_SwapTeams_SwapTeams")}
                                  </>
                              ),
                              onClick: () => setIsTeamsSwapSelectOpen(true),
                          },
                      ]
                    : []),
            ],
            [
                onDelete,
                onRenamePlaceholderForPhase,
                onReplacePlaceholderForPhase,
                onRevokeTeamAdvancing,
                onTeamsSwapForPhase,
            ],
        );

        const dropdownMenu = useMemo(
            () =>
                dropdownMenuItems.length > 0 ? (
                    <DropdownMenu icon={<MoreOutlined />} menuItems={dropdownMenuItems} />
                ) : undefined,
            [dropdownMenuItems],
        );

        const replacePlaceholderForPhase = useCallback(
            async (replacementId: string) => {
                if (!onReplacePlaceholderForPhase) {
                    return;
                }

                const success = await runPlaceholderTeamReplacementInTask(() =>
                    onReplacePlaceholderForPhase(replacementId),
                );

                if (success) {
                    setIsPlaceholderForPhaseReplaced(false);
                }
            },
            [onReplacePlaceholderForPhase, runPlaceholderTeamReplacementInTask],
        );

        const swapTeamsForPhase = useCallback(
            async (team1Id: string, team2Id: string) => {
                if (!onTeamsSwapForPhase) {
                    return;
                }

                const success = await runTeamSwapInTask(() => onTeamsSwapForPhase(team1Id, team2Id));

                if (success) {
                    setIsTeamsSwapSelectOpen(false);
                }
            },
            [onTeamsSwapForPhase, runTeamSwapInTask],
        );

        return (
            <Draggable draggableId={team.id} index={index} isDragDisabled={isDragDisabled}>
                {provided => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Spacing childrenGutterX className={cx("team-item")}>
                            <div className={cx("content")}>
                                {!isPlaceholderForPhaseReplaced && !isTeamsSwapSelectOpen && (
                                    <div className={cx("team-container")}>
                                        <div className={cx("team")}>
                                            <TeamLogo photo={team.logo} small />
                                            <div className={cx("team-name")}>{team.displayName}</div>
                                        </div>
                                        {predictedAdvancingTeam && (
                                            <div className={cx("external-reference-info")}>
                                                <Typography.Text
                                                    type="secondary"
                                                    className={cx("external-reference-info-display-name")}>
                                                    {predictedAdvancingTeam.displayName}
                                                </Typography.Text>
                                                {onAdvanceTeam &&
                                                    (isPredictedAdvancingTeamAlreadyInPhase ? (
                                                        <Tooltip
                                                            title={l(
                                                                "CompetitionPhases_ExternalReferences_AdvanceTeam_TeamAlreadyAddedToPhase",
                                                            )}>
                                                            <Button
                                                                size="small"
                                                                className={cx("advance-team-button")}
                                                                onClick={onAdvanceTeam}
                                                                disabled>
                                                                {l("CompetitionPhases_ExternalReferences_AdvanceTeam")}
                                                            </Button>
                                                        </Tooltip>
                                                    ) : (
                                                        <Button
                                                            size="small"
                                                            className={cx("advance-team-button")}
                                                            onClick={onAdvanceTeam}>
                                                            {l("CompetitionPhases_ExternalReferences_AdvanceTeam")}
                                                        </Button>
                                                    ))}
                                            </div>
                                        )}
                                        {externalReferenceForAdvancingTeam && (
                                            <div className={cx("external-reference-info")}>
                                                <Typography.Text
                                                    type="secondary"
                                                    className={cx("external-reference-info-display-name")}>
                                                    {externalReferenceForAdvancingTeam.displayName}
                                                </Typography.Text>
                                            </div>
                                        )}
                                    </div>
                                )}

                                {isPlaceholderForPhaseReplaced && (
                                    <ReplacePlaceholderTeamForPhaseSelect
                                        className={cx("action-select")}
                                        saveInProgress={isPlaceholderForPhaseReplacementInProgress}
                                        onCancel={() => setIsPlaceholderForPhaseReplaced(false)}
                                        availableReplacementTeams={availableTeamsForPlaceholderReplacement}
                                        onSave={replacePlaceholderForPhase}
                                    />
                                )}
                                {isTeamsSwapSelectOpen && (
                                    <SwapTeamsInGroupPhaseSelect
                                        team1Name={team.name}
                                        className={cx("action-select")}
                                        isSaving={isTeamSwapInProgress}
                                        onCancel={() => setIsTeamsSwapSelectOpen(false)}
                                        teamsAvailableForSwap={availableTeamsForTeamSwap}
                                        onSave={swapTeamsForPhase}
                                        team1Id={team.id}
                                    />
                                )}
                            </div>
                            {dropdownMenu}
                        </Spacing>
                    </div>
                )}
            </Draggable>
        );
    },
);

export default GroupsPhaseTeamItemDraggable;
