import { ApiResponse } from "@leancode/cqrs-client/ClientType";
import polly from "polly-js";

export default function retryQuery<TResult>(query: () => Promise<ApiResponse<TResult>>) {
    return polly()
        .waitAndRetry([500, 1000, 3000])
        .executeForPromise(async () => {
            const response = await query();

            if (!response.isSuccess) {
                throw response.error;
            }
            return response.result;
        });
}
