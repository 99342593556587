import { CompetitionTeamStore } from "Domain/Competitions/CompetitionTeamStore";
import _ from "lodash";
import { action, computed, observable } from "mobx";
import { notUndefined } from "Utils/predicates";

export enum KnockoutPhaseMatchConfigurationState {
    Configured,
    NotConfigured,
    DuplicateTeams,
    InvalidTeamsCount,
}

class KnockoutPhaseMatchConfiguration {
    private readonly competitionTeamStore: CompetitionTeamStore;
    @observable teamIds: string[] = [];

    constructor(competitionTeamStore: CompetitionTeamStore) {
        this.competitionTeamStore = competitionTeamStore;
    }

    @computed get teams() {
        return this.teamIds.map(id => this.competitionTeamStore.getById(id)).filter(notUndefined);
    }

    @action.bound
    setTeams(teamIds: string[]) {
        this.teamIds = teamIds;
    }

    @computed get configurationState(): KnockoutPhaseMatchConfigurationState {
        if (this.teamIds.length === 0) {
            return KnockoutPhaseMatchConfigurationState.NotConfigured;
        }

        if (this.teamIds.length !== 2) {
            return KnockoutPhaseMatchConfigurationState.InvalidTeamsCount;
        }

        if (_.uniq(this.teamIds).length !== this.teamIds.length) {
            return KnockoutPhaseMatchConfigurationState.DuplicateTeams;
        }

        return KnockoutPhaseMatchConfigurationState.Configured;
    }
}

export default KnockoutPhaseMatchConfiguration;
