import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    PlusOutlined,
    SwapOutlined,
    SyncOutlined,
} from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Button, message, Modal, Skeleton, Table, Typography } from "antd";
import { ColumnProps } from "antd/lib/table";
import DropdownMenu from "Components/DropdownMenu";
import EmptyState from "Components/EmptyState";
import NavigationLink from "Components/NavigationLink";
import PageContent from "Components/PageContent";
import { Select } from "Components/Select";
import Spacing from "Components/Spacing";
import TeamLogo from "Components/TeamLogo";
import getTeamsAndReferencesOptions from "Components/TeamsAndReferencesOptions";
import { CompetitionPhase } from "Domain/CompetitionPhases/CompetitionPhase";
import KnockoutPhaseTeamsConfigurator from "Domain/CompetitionPhases/KnockoutPhase/KnockoutPhaseTeamsConfigurator";
import Competition from "Domain/Competitions/Competition";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import PlaceholderTeamForPhaseFormDialog from "DomainComponents/PlaceholderTeamForPhaseFormDialog";
import ReplacePlaceholderTeamForPhaseSelect from "DomainComponents/ReplacePlaceholderTeamForPhaseSelect";
import { l } from "Languages";
import { observer, Observer, useObserver } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import routes from "Router/routes";
import useRunInTask from "Utils/Hooks/useRunInTask";
import styles from "./styles.scss";

const cx = mkCx(styles);

type PlaceholderTeamForPhaseFormState =
    | {
          mode: "edit";
          placeholderTeam: CompetitionTeam;
      }
    | { mode: "create" | "off" };

type CompetitionPhaseTeamsProps = {
    phase: CompetitionPhase;
    competition: Competition;
    teamsInCompetition: CompetitionTeam[];

    onDeletePlaceholderTeamForPhase: (teamId: string) => void;

    phaseConfigurator?: KnockoutPhaseTeamsConfigurator;
};

const CompetitionPhaseTeams: React.FunctionComponent<CompetitionPhaseTeamsProps> = observer(
    ({ phase, competition, teamsInCompetition, onDeletePlaceholderTeamForPhase, phaseConfigurator }) => {
        const [isTeamsSelectActive, setIsTeamsSelectActive] = useState(false);
        const [selectedTeamsIds, setSelectedTeamsIds] = useState<string[]>([]);
        const [isTeamsAdditionToPhaseInProgress, runTeamsAdditionToPhaseInTask] = useRunInTask();

        const [isPlaceholderTeamForPhaseSaveInProgress, runPlaceholderForPhaseSaveInTask] = useRunInTask();
        const [
            placeholderTeamForPhaseFormState,
            setPlaceholderTeamForPhaseFormState,
        ] = useState<PlaceholderTeamForPhaseFormState>({ mode: "off" });

        const [replacedPlaceholderTeamId, setReplacedPlaceholderTeamId] = useState<string | undefined>();
        const [placeholderTeamReplacementInProgress, runPlaceholderTeamReplacementInTask] = useRunInTask();

        const phaseTeamsIds = useObserver(() => phase.teamsIds);

        const teamsAddableToPhase = useMemo(() => {
            const teamsInCurrentPhase = new Set(phaseTeamsIds);

            return teamsInCompetition?.filter(t => !teamsInCurrentPhase.has(t.id));
        }, [phaseTeamsIds, teamsInCompetition]);

        const removeTeam = useCallback(
            async (team: CompetitionTeam) => {
                if (team.externalReferenceId) {
                    const response = await competition.deleteTeam(team.id);

                    if (response.isSuccess) {
                        message.success(l("CompetitionDetails_Teams_Remove_Success"));
                        phase.handlePhaseTeamDeletion(team.id);
                        return;
                    }

                    message.error(l("CompetitionDetails_Teams_Remove_Failure"));
                    return;
                }
                if (team.isPlaceholderForSinglePhaseOnly) {
                    onDeletePlaceholderTeamForPhase(team.id);
                    return;
                }

                phase.removeTeam(team.id);
            },
            [competition, onDeletePlaceholderTeamForPhase, phase],
        );

        const availableReplacementTeamsForReplacedTeam = useMemo(() => {
            if (replacedPlaceholderTeamId === undefined) {
                return undefined;
            }

            return teamsInCompetition.filter(t => !t.isPlaceholder && !phase.teamsIds?.some(pt => t.id === pt));
        }, [phase.teamsIds, replacedPlaceholderTeamId, teamsInCompetition]);

        const replacePlaceholderTeamForPhase = useCallback(
            (replacementTeamId: string) => {
                if (!replacedPlaceholderTeamId) {
                    return;
                }

                runPlaceholderTeamReplacementInTask(async () => {
                    const success = await phase.replacePlaceholderTeams([
                        { placeholderTeamId: replacedPlaceholderTeamId, replacementId: replacementTeamId },
                    ]);

                    if (success) {
                        setReplacedPlaceholderTeamId(undefined);
                    }
                });
            },
            [phase, replacedPlaceholderTeamId, runPlaceholderTeamReplacementInTask],
        );

        const showRevokeTeamAdvancingConfirmation = useCallback(
            (teamId: string) => {
                Modal.confirm({
                    onOk: () =>
                        phase.revokeTeamAdvancing(phase.externalReferencesForAdvancingTeams?.get(teamId)?.id ?? ""),
                    icon: <ExclamationCircleOutlined />,
                    title: l("CompetitionPhases_ExternalReferences_RevokeTeamAdvancing_Confirmation_Title"),
                    content: l("CompetitionPhases_ExternalReferences_RevokeTeamAdvancing_Confirmation_Content"),
                    okText: l("CompetitionPhases_ExternalReferences_RevokeTeamAdvancing"),
                    okButtonProps: {
                        danger: true,
                    },
                    cancelText: l("Common_Cancel"),
                    centered: true,
                    maskClosable: true,
                });
            },
            [phase],
        );

        const columns = useMemo(
            (): ColumnProps<CompetitionTeam>[] => [
                {
                    title: l("Common_Ordinal"),
                    render: (_, __, index) => index + 1,
                },
                {
                    title: l("CompetitionDetails_Teams_Logo"),
                    render: (_, team) => ({
                        children: <TeamLogo photo={team.logo} />,
                        props: {
                            colSpan: replacedPlaceholderTeamId === team.id ? 0 : 1,
                        },
                    }),
                },
                {
                    title: l("CompetitionDetails_Teams_Name"),
                    render: (_, team) => ({
                        children:
                            replacedPlaceholderTeamId === team.id && availableReplacementTeamsForReplacedTeam ? (
                                <ReplacePlaceholderTeamForPhaseSelect
                                    availableReplacementTeams={availableReplacementTeamsForReplacedTeam}
                                    onCancel={() => setReplacedPlaceholderTeamId(undefined)}
                                    onSave={replacePlaceholderTeamForPhase}
                                    saveInProgress={placeholderTeamReplacementInProgress}
                                />
                            ) : team.isPlaceholder ? (
                                <>
                                    {team.displayName}
                                    {team.externalReference?.predictedAdvancingTeamId && (
                                        <Typography.Text type="secondary">
                                            {" "}
                                            (
                                            {phase.competitionTeamStore.getById(
                                                team.externalReference.predictedAdvancingTeamId,
                                            )?.displayName ?? ""}
                                            )
                                        </Typography.Text>
                                    )}
                                </>
                            ) : (
                                <NavigationLink to={routes.competitionTeam({ teamId: team.id })}>
                                    {team.displayName}
                                </NavigationLink>
                            ),
                        props: {
                            colSpan: replacedPlaceholderTeamId === team.id ? 3 : 1,
                        },
                    }),
                },
                {
                    title: l("Common_Actions"),
                    render: (_, team) => ({
                        children: (
                            <Observer>
                                {() => (
                                    <DropdownMenu
                                        menuItems={[
                                            ...(team.isPlaceholderForSinglePhaseOnly
                                                ? [
                                                      {
                                                          children: (
                                                              <>
                                                                  <SyncOutlined />{" "}
                                                                  {l("CompetitionPhases_PlaceholderTeams_Replace")}
                                                              </>
                                                          ),
                                                          onClick: () => setReplacedPlaceholderTeamId(team.id),
                                                      },
                                                      ...[
                                                          team.externalReferenceId
                                                              ? {
                                                                    children: (
                                                                        <>
                                                                            <SwapOutlined />{" "}
                                                                            {l(
                                                                                "CompetitionPhases_PlaceholderTeams_Advance",
                                                                            )}
                                                                        </>
                                                                    ),
                                                                    onClick: () =>
                                                                        Modal.confirm({
                                                                            onOk: () =>
                                                                                phase.advanceTeamsForPhaseReferenceBase(
                                                                                    [team.id],
                                                                                ),
                                                                            icon: <ExclamationCircleOutlined />,
                                                                            title: l(
                                                                                "CompetitionPhases_ExternalReferences_AdvanceTeam_Confirmation_Title",
                                                                            ),
                                                                            content: l(
                                                                                "CompetitionPhases_ExternalReferences_AdvanceTeam_Confirmation_Content",
                                                                            ),
                                                                            okText: l(
                                                                                "CompetitionPhases_ExternalReferences_AdvanceTeam",
                                                                            ),
                                                                            okButtonProps: {
                                                                                danger: true,
                                                                            },
                                                                            cancelText: l("Common_Cancel"),
                                                                            centered: true,
                                                                            maskClosable: true,
                                                                        }),
                                                                }
                                                              : {
                                                                    children: (
                                                                        <>
                                                                            <EditOutlined />{" "}
                                                                            {l(
                                                                                "CompetitionPhases_PlaceholderTeams_Edit",
                                                                            )}
                                                                        </>
                                                                    ),
                                                                    onClick: () =>
                                                                        setPlaceholderTeamForPhaseFormState({
                                                                            mode: "edit",
                                                                            placeholderTeam: team,
                                                                        }),
                                                                },
                                                      ],
                                                  ]
                                                : []),

                                            ...(phase.externalReferencesForAdvancingTeams?.get(team.id)
                                                ? [
                                                      {
                                                          children: (
                                                              <>
                                                                  <ExclamationCircleOutlined />{" "}
                                                                  {l(
                                                                      "CompetitionPhases_ExternalReferences_RevokeTeamAdvancing",
                                                                  )}
                                                              </>
                                                          ),
                                                          onClick: () => showRevokeTeamAdvancingConfirmation(team.id),
                                                      },
                                                  ]
                                                : [
                                                      {
                                                          children: (
                                                              <>
                                                                  <DeleteOutlined />{" "}
                                                                  {l("CompetitionDetails_Teams_Remove")}
                                                              </>
                                                          ),
                                                          onClick: () => removeTeam(team),
                                                      },
                                                  ]),
                                        ]}
                                    />
                                )}
                            </Observer>
                        ),
                        props: {
                            colSpan: replacedPlaceholderTeamId === team.id ? 0 : 1,
                        },
                    }),
                },
            ],
            [
                availableReplacementTeamsForReplacedTeam,
                phase,
                placeholderTeamReplacementInProgress,
                removeTeam,
                replacePlaceholderTeamForPhase,
                replacedPlaceholderTeamId,
                showRevokeTeamAdvancingConfirmation,
            ],
        );

        const closeTeamsSelect = useCallback(() => {
            setIsTeamsSelectActive(false);
            setSelectedTeamsIds([]);
        }, []);

        const closePlaceholderTeamForPhaseFormDialog = useCallback(() => {
            setPlaceholderTeamForPhaseFormState({ mode: "off" });
        }, []);

        const addTeamsToPhase = useCallback(async () => {
            const onSuccess = () => {
                message.success(l("CompetitionPhases_Details_Teams_AddTeam_Success"));
                closeTeamsSelect();
                phaseConfigurator?.cancelAddingTeams();
            };

            if (phaseConfigurator) {
                const success = await runTeamsAdditionToPhaseInTask(() => phaseConfigurator.saveTeamsAddition());

                if (!success) {
                    message.error(l("CompetitionPhases_Details_Teams_AddTeam_Failure"));
                    return;
                }

                onSuccess();

                return;
            }

            const result = await runTeamsAdditionToPhaseInTask(() => phase.addTeamsToPhase(selectedTeamsIds));

            result
                .handle("success", () => onSuccess())
                .handle(
                    [
                        "CompetitionNotFound",
                        "PhaseNotFound",
                        "TeamAlreadyInPhase",
                        "TeamIdsNullOrEmpty",
                        "TeamNotFound",
                        "failure",
                    ],
                    () => message.error(l("CompetitionPhases_Details_Teams_AddTeam_Failure")),
                );
        }, [runTeamsAdditionToPhaseInTask, phase, selectedTeamsIds, closeTeamsSelect, phaseConfigurator]);

        const addPlaceholderTeamToPhase = useCallback(
            async (name: string) => {
                const placeholder = await runPlaceholderForPhaseSaveInTask(() => phase.addPlaceholderTeam(name));

                if (placeholder) {
                    closePlaceholderTeamForPhaseFormDialog();
                    closeTeamsSelect();
                }
            },
            [runPlaceholderForPhaseSaveInTask, phase, closePlaceholderTeamForPhaseFormDialog, closeTeamsSelect],
        );

        const renamePlaceholderTeamForPhase = useCallback(
            async (team: CompetitionTeam, name: string) => {
                const success = await runPlaceholderForPhaseSaveInTask(() =>
                    phase.renamePlaceholderTeam(team.id, name),
                );

                if (success) {
                    closePlaceholderTeamForPhaseFormDialog();
                }
            },
            [runPlaceholderForPhaseSaveInTask, phase, closePlaceholderTeamForPhaseFormDialog],
        );

        const loadedTeamsCount = useObserver(() => phase.teams?.length);
        const areTeamsLoaded = phaseTeamsIds && phaseTeamsIds.length === loadedTeamsCount;

        const phaseTeams = useObserver(() => phase.teams);

        const phaseTeamsOrderedByOrderInCompetition = teamsInCompetition.filter(t =>
            phaseTeams?.some(pt => pt.id === t.id),
        );

        const showAdvanceAllTeamsConfirmation = useCallback(() => {
            Modal.confirm({
                onOk: () => phase.advanceTeamsForAllGroupsPhaseReferences(),
                icon: <ExclamationCircleOutlined />,
                title: l("CompetitionPhases_ExternalReferences_AdvanceTeams_Confirmation_Title"),
                content: l("CompetitionPhases_ExternalReferences_AdvanceTeams_Confirmation_Content"),
                okText: l("CompetitionPhases_ExternalReferences_AdvanceTeams"),
                okButtonProps: {
                    danger: true,
                },
                cancelText: l("Common_Cancel"),
                centered: true,
                maskClosable: true,
            });
        }, [phase]);

        return (
            <>
                <Skeleton loading={!areTeamsLoaded} active={!areTeamsLoaded}>
                    {phase.teams?.some(t => t.externalReferenceId) && (
                        <Button type="primary" onClick={showAdvanceAllTeamsConfirmation}>
                            {l("CompetitionPhases_ExternalReferences_AdvanceTeams")}
                        </Button>
                    )}
                    <Table
                        rowKey={t => t.id}
                        dataSource={[
                            ...phaseTeamsOrderedByOrderInCompetition,
                            ...(phase.placeholderTeamsForPhase ?? []),
                        ]}
                        columns={columns}
                        pagination={false}
                        locale={{
                            emptyText: <EmptyState text={l("CompetitionPhases_Details_Teams_NoTeams")} />,
                        }}
                    />
                    {isTeamsSelectActive ? (
                        <Spacing childrenGutterY className={cx("teams-adding")}>
                            <Select
                                notFoundContent={
                                    <EmptyState text={l("CompetitionPhases_Details_Teams_AddTeam_NoTeams")} />
                                }
                                value={selectedTeamsIds}
                                onChange={(teamsIds: string[]) => {
                                    phaseConfigurator?.teamsSelection?.setSelectedIds(teamsIds);
                                    setSelectedTeamsIds(teamsIds);
                                }}
                                className={cx("select")}
                                mode="multiple"
                                selectOptions={
                                    phaseConfigurator?.teamsSelection
                                        ? undefined
                                        : teamsAddableToPhase?.map(t => ({
                                              value: t.id,
                                              label: t.displayName,
                                              key: t.id,
                                          }))
                                }
                                showArrow
                                allowClear
                                additionalOptions={[
                                    {
                                        content: (
                                            <>
                                                <PlusOutlined /> {l("CompetitionPhases_PlaceholderTeams_Add")}
                                            </>
                                        ),
                                        onClick: () => setPlaceholderTeamForPhaseFormState({ mode: "create" }),
                                    },
                                ]}>
                                {phaseConfigurator?.teamsSelection &&
                                    getTeamsAndReferencesOptions(phaseConfigurator.teamsSelection)}
                            </Select>
                            <Spacing childrenGutterX>
                                <Button onClick={closeTeamsSelect}>{l("Common_Cancel")}</Button>
                                <Button
                                    type="primary"
                                    onClick={addTeamsToPhase}
                                    loading={isTeamsAdditionToPhaseInProgress}
                                    disabled={isTeamsAdditionToPhaseInProgress || selectedTeamsIds.length === 0}>
                                    {l("Common_Add")}
                                </Button>
                            </Spacing>
                        </Spacing>
                    ) : (
                        <PageContent.ActionButton
                            onClick={() => {
                                setIsTeamsSelectActive(true);
                                phaseConfigurator?.startAddingTeams();
                            }}>
                            <PlusOutlined /> {l("CompetitionPhases_Details_Teams_AddTeam")}
                        </PageContent.ActionButton>
                    )}
                </Skeleton>
                {placeholderTeamForPhaseFormState.mode === "create" && (
                    <PlaceholderTeamForPhaseFormDialog
                        onClose={closePlaceholderTeamForPhaseFormDialog}
                        saveInProgress={isPlaceholderTeamForPhaseSaveInProgress}
                        onSave={addPlaceholderTeamToPhase}
                    />
                )}
                {placeholderTeamForPhaseFormState.mode === "edit" && (
                    <PlaceholderTeamForPhaseFormDialog
                        placeholderTeam={placeholderTeamForPhaseFormState.placeholderTeam}
                        onClose={closePlaceholderTeamForPhaseFormDialog}
                        saveInProgress={isPlaceholderTeamForPhaseSaveInProgress}
                        onSave={name =>
                            renamePlaceholderTeamForPhase(placeholderTeamForPhaseFormState.placeholderTeam, name)
                        }
                    />
                )}
            </>
        );
    },
);

export default CompetitionPhaseTeams;
