import { Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import tenantSettingsStore from "Domain/Tenant";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";
import { emailRegex } from "Utils/validateEmail";

type AddManagerUserFormDialogProps = {
    onClose: () => void;
};

const AddManagerUserFormDialog: React.FunctionComponent<AddManagerUserFormDialogProps> = ({ onClose }) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const onFinish = useCallback(
        (values: FormFields) =>
            runInTask(async () => {
                const result = await tenantSettingsStore.addManagerUser(values.email);

                result
                    .handle(
                        [
                            "EmailAddressAlreadyRegistered",
                            "EmailAddressMissingOrEmpty",
                            "EmailAddressNotValid",
                            "EmailAddressTooLong",
                        ],
                        error => {
                            const errorMessage = (() => {
                                switch (error) {
                                    case "EmailAddressAlreadyRegistered":
                                        return l("Tenant_ManagerUsers_Add_Validation_EmailAlreadyRegistered");
                                    case "EmailAddressMissingOrEmpty":
                                        return l("Common_Validation_FieldRequired");
                                    case "EmailAddressNotValid":
                                        return l("Common_Validation_InvalidEmail");
                                    case "EmailAddressTooLong":
                                        return l("Common_Validation_FieldTooLong");
                                }
                            })();

                            form.setFields([
                                {
                                    name: guard<FormField>("email"),
                                    value: values.email,
                                    errors: [errorMessage],
                                },
                            ]);
                        },
                    )
                    .handle("failure", () => {
                        message.error(l("Tenant_ManagerUsers_Add_Failure"));
                    })
                    .handle("success", () => {
                        message.success(l("Tenant_ManagerUsers_Add_Success"));

                        onClose();
                    });
            }),
        [onClose, form, runInTask],
    );

    return useObserver(() => (
        <Modal
            title={l("Tenant_ManagerUsers_Add_Form_Title")}
            onCancel={onClose}
            okText={l("Common_Add")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}
            okButtonProps={{
                disabled: isRunning,
                loading: isRunning,
            }}>
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Form.Item
                    name={guard<FormField>("email")}
                    label={l("Tenant_ManagerUsers_Add_Form_Email")}
                    rules={[
                        { required: true, message: l("Common_Validation_FieldRequired") },
                        {
                            pattern: emailRegex,
                            message: l("Common_Validation_InvalidEmail"),
                        },
                        {
                            max: 250,
                            message: l("Common_Validation_FieldTooLong"),
                        },
                    ]}
                    validateTrigger="onBlur">
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    ));
};

export type FormFields = {
    email: string;
};

type FormField = keyof FormFields;

export default AddManagerUserFormDialog;
