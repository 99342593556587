import moment from "moment";
import { newtype, Newtype } from "Utils/Newtype";

export const dateTimeToDTO = (date: moment.Moment) => ApiDateTime(date.toISOString());
export const dateTimeFromDTO = (dto: ApiDateTime): moment.Moment => moment.utc(ApiDateTime.unwrap(dto)).local();

export const dateTimeToDTOOptional = (date: moment.Moment | undefined): ApiDateTime | undefined =>
    date && dateTimeToDTO(date);
export const dateTimeFromDTOOptional = (dto: ApiDateTime | null | undefined): moment.Moment | undefined =>
    (dto && dateTimeFromDTO(dto)) ?? undefined;

export const dateTimeOffsetToDTO = (date: moment.Moment) => ApiDateTimeOffset(date.toISOString(true));
export const dateTimeOffsetFromDTO = (dto: ApiDateTimeOffset): moment.Moment => moment(ApiDateTimeOffset.unwrap(dto));

export const dateTimeOffsetToDTOOptional = (date: moment.Moment | undefined): ApiDateTimeOffset | undefined =>
    date && dateTimeOffsetToDTO(date);
export const dateTimeOffsetFromDTOOptional = (dto: ApiDateTimeOffset | null | undefined): moment.Moment | undefined =>
    (dto && dateTimeOffsetFromDTO(dto)) ?? undefined;

export const dateFromDTO = (dto: ApiDate): moment.Moment => moment.utc(ApiDate.unwrap(dto)).local();
export const dateToDTO = (date: moment.Moment): ApiDate =>
    ApiDate(moment.utc({ year: date.year(), month: date.month(), day: date.date() }).format("YYYY-MM-DD"));

export const dateToDTOOptional = (date?: moment.Moment): ApiDate | undefined => date && dateToDTO(date);
export const dateFromDTOOptional = (dto: ApiDate | undefined | null) => (dto && dateFromDTO(dto)) ?? undefined;

export interface ApiDateTime extends Newtype<{ readonly apiDateTime: unique symbol }, string> {}
const ApiDateTime = newtype<ApiDateTime>("ApiDateTime");

export interface ApiDate extends Newtype<{ readonly apiDate: unique symbol }, string> {}
const ApiDate = newtype<ApiDate>("ApiDate");

export interface ApiDateTimeOffset extends Newtype<{ readonly apiDateTimeOffset: unique symbol }, string> {}
const ApiDateTimeOffset = newtype<ApiDateTimeOffset>("ApiDateTimeOffset");
