import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Skeleton, Typography } from "antd";
import DropdownMenu from "Components/DropdownMenu";
import PageContent from "Components/PageContent";
import tenantSettingsStore from "Domain/Tenant";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import routes, { PageComponent } from "Router/routes";
import useRunInTask from "Utils/Hooks/useRunInTask";
import AccessCodeFormDialog from "./AccessCodeFormDialog";

const AccessCodesPage: PageComponent<typeof routes.accessCode> = () => {
    const [isRunning, runInTask] = useRunInTask();
    const [showAccessCodeForm, setShowAccessCodeForm] = useState(false);

    useEffect(() => {
        runInTask(() => tenantSettingsStore.fetchSettings());
    }, [runInTask]);

    return useObserver(() => (
        <>
            <PageContent>
                <PageContent.Card
                    title={l("AccessCodes_Title")}
                    extra={
                        <DropdownMenu
                            menuItems={[
                                {
                                    children: (
                                        <>
                                            <EditOutlined /> {l("AccessCodes_Change")}
                                        </>
                                    ),
                                    onClick: () => setShowAccessCodeForm(true),
                                },
                            ]}
                        />
                    }>
                    <Skeleton
                        active={isRunning && !tenantSettingsStore.accessCode}
                        loading={isRunning && !tenantSettingsStore.accessCode}>
                        {tenantSettingsStore.accessCode ? (
                            <Typography.Title>{tenantSettingsStore.accessCode}</Typography.Title>
                        ) : (
                            <PageContent.ActionButton onClick={() => setShowAccessCodeForm(true)}>
                                <PlusOutlined /> {l("AccessCodes_AddNew")}
                            </PageContent.ActionButton>
                        )}
                    </Skeleton>
                </PageContent.Card>
            </PageContent>
            {showAccessCodeForm && (
                <AccessCodeFormDialog
                    accessCode={tenantSettingsStore.accessCode}
                    onClose={() => setShowAccessCodeForm(false)}
                />
            )}
        </>
    ));
};

export default AccessCodesPage;
