import { DatePicker, Form, Input, Skeleton } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import { Select } from "Components/Select";
import CompetitionSportsField from "Domain/Competitions/CompetitionSportsField";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import moment from "moment";
import React, { useCallback } from "react";
import { dateWithTimeFormat, timeFormat } from "Utils/formatting";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";

export type CustomPhaseMatchFormDialogProps = {
    teams?: CompetitionTeam[];
    sportsFields?: CompetitionSportsField[];

    initialValues?: Partial<FormFields>;

    onSave: (formFields: FormFields) => Promise<void>;
    onClose: () => void;
};

const EditKnockoutPhaseMatchFormDialog: React.FunctionComponent<CustomPhaseMatchFormDialogProps> = ({
    teams,
    sportsFields,
    initialValues,
    onSave,
    onClose,
}) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const onFinish = useCallback(
        (values: FormFields) => {
            runInTask(() => onSave(values));
        },
        [runInTask, onSave],
    );

    return useObserver(() => (
        <Modal
            title={l("MatchDetails_EditMatch_Form_Title")}
            onCancel={onClose}
            okText={l("Common_Save")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}
            okButtonProps={{
                loading: isRunning,
                disabled: isRunning,
            }}>
            {teams && sportsFields ? (
                <Form layout="vertical" form={form} initialValues={initialValues} onFinish={onFinish}>
                    <Form.Item
                        name={guard<FormField>("name")}
                        label={l("CompetitionPhases_Knockout_EditMatch_Form_Name")}>
                        <Input maxLength={250} />
                    </Form.Item>
                    <Form.Item
                        name={guard<FormField>("sportsFieldId")}
                        label={l("CompetitionPhases_Knockout_EditMatch_Form_SportsField")}>
                        <Select
                            showSearch
                            showArrow
                            allowClear
                            selectOptions={sportsFields.map(sf => ({ label: sf.name, value: sf.id, key: sf.id }))}
                        />
                    </Form.Item>
                    <Form.Item
                        name={guard<FormField>("date")}
                        trigger="onSelect"
                        label={l("CompetitionPhases_Knockout_EditMatch_Form_Date")}>
                        <DatePicker showTime={{ format: timeFormat }} format={dateWithTimeFormat} />
                    </Form.Item>
                </Form>
            ) : (
                <Skeleton active loading />
            )}
        </Modal>
    ));
};

export type FormFields = {
    name?: string;
    sportsFieldId?: string;
    date?: moment.Moment | null;
};

type FormField = keyof FormFields;

export default EditKnockoutPhaseMatchFormDialog;
