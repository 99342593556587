import { DeleteOutlined } from "@ant-design/icons";
import { PageHeader } from "antd";
import { PageHeaderProps } from "antd/lib/page-header";
import DropdownMenu from "Components/DropdownMenu";
import PageContent from "Components/PageContent";
import RoutedTabs from "Components/RoutedTabs";
import CustomPhase from "Domain/CompetitionPhases/CustomPhase/CustomPhase";
import Competition from "Domain/Competitions/Competition";
import { l } from "Languages";
import { Observer, useObserver } from "mobx-react-lite";
import React, { FunctionComponent } from "react";
import routes from "Router/routes";
import CompetitionPhaseTeams from "../CompetitionPhaseTeams";
import usePlaceholderTeamForPhaseDeletion from "../usePlaceholderTeamForPhaseDeletion";
import useScheduleDeletion from "../useScheduleDeletion";
import CustomPhaseSchedule from "./CustomPhaseSchedule";
import MatchGroups from "./MatchGroups";

type CustomPhaseDetailsProps = {
    phase: CustomPhase;
    competition: Competition;

    headerProps: PageHeaderProps;
};

const CustomPhaseDetails: FunctionComponent<CustomPhaseDetailsProps> = ({ phase, competition, headerProps }) => {
    const canNavigateToScheduleTab = useObserver(() => phase.schedule || phase.teams);

    const showDeleteScheduleConfirmation = useScheduleDeletion(phase);
    const showDeletePlaceholderForPhaseConfirmation = usePlaceholderTeamForPhaseDeletion(competition, phase.id);

    return useObserver(() => (
        <>
            <PageContent wide>
                <PageContent.Header>
                    <PageHeader
                        {...headerProps}
                        extra={
                            <Observer>
                                {() =>
                                    phase.schedule ? (
                                        <DropdownMenu
                                            menuItems={[
                                                {
                                                    children: (
                                                        <>
                                                            <DeleteOutlined />{" "}
                                                            {l("CompetitionPhases_Details_DeleteSchedule")}
                                                        </>
                                                    ),
                                                    onClick: showDeleteScheduleConfirmation,
                                                },
                                            ]}
                                        />
                                    ) : (
                                        <></>
                                    )
                                }
                            </Observer>
                        }
                    />
                </PageContent.Header>
                <PageContent.Card>
                    <RoutedTabs<"schedule" | "teams" | "match-groups">
                        tabs={[
                            {
                                key: "schedule",
                                label: l("CompetitionPhases_Details_Schedule"),
                                navigateTo: routes.competitionPhase.schedule({ phaseId: phase.id }),
                                routePattern: routes.competitionPhase.schedule.pattern,
                                content: competition.sportsFields && (
                                    <CustomPhaseSchedule
                                        phase={phase}
                                        sportsFields={competition.sportsFields}
                                        competition={competition}
                                    />
                                ),
                                disabled: !canNavigateToScheduleTab,
                            },
                            {
                                key: "teams",
                                label: l("CompetitionPhases_Details_Teams"),
                                navigateTo: routes.competitionPhase.teams({ phaseId: phase.id }),
                                routePattern: routes.competitionPhase.teams.pattern,
                                content: competition?.teams && (
                                    <CompetitionPhaseTeams
                                        competition={competition}
                                        phase={phase}
                                        teamsInCompetition={competition.teams}
                                        onDeletePlaceholderTeamForPhase={showDeletePlaceholderForPhaseConfirmation}
                                    />
                                ),
                            },
                            {
                                key: "match-groups",
                                label: l("CompetitionPhases_Details_MatchGroups"),
                                navigateTo: routes.competitionPhase.matchGroups({ phaseId: phase.id }),
                                routePattern: routes.competitionPhase.matchGroups.pattern,
                                content: <MatchGroups phase={phase} />,
                            },
                        ]}
                        defaultKey={canNavigateToScheduleTab ? "schedule" : "teams"}
                    />
                </PageContent.Card>
            </PageContent>
        </>
    ));
};

export default CustomPhaseDetails;
