import { TablePointsModifierDTO } from "Contracts/PlayooLeagueClient";
import { CompetitionTeamStore } from "Domain/Competitions/CompetitionTeamStore";
import { computed, observable } from "mobx";

class TablePointsModifier {
    private readonly competitionTeamStore: CompetitionTeamStore;

    readonly id: string;
    readonly phaseId: string;

    @observable teamId: string;
    @observable points: number;
    @observable description?: string;

    constructor(
        id: string,
        { phaseId, teamId, points, description }: TablePointsModifierInit,
        competitionTeamStore: CompetitionTeamStore,
    ) {
        this.id = id;
        this.phaseId = phaseId;
        this.teamId = teamId;
        this.points = points;
        this.description = description;

        this.competitionTeamStore = competitionTeamStore;
    }

    @computed get team() {
        return this.competitionTeamStore.getById(this.teamId);
    }

    static fromDTO(phaseId: string, dto: TablePointsModifierDTO, competitionTeamStore: CompetitionTeamStore) {
        return new TablePointsModifier(
            dto.Id,
            {
                phaseId,
                points: dto.Points,
                teamId: dto.TeamId,
                description: dto.Description ?? undefined,
            },
            competitionTeamStore,
        );
    }
}

type TablePointsModifierInit = Pick<TablePointsModifier, "phaseId" | "teamId" | "points" | "description">;

export default TablePointsModifier;
