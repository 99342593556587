import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, message, Modal as AntdModal, Select as AntdSelect, Skeleton, Tag } from "antd";
import { useForm } from "antd/lib/form/util";
import EmptyState from "Components/EmptyState";
import Modal from "Components/Modal";
import { Select } from "Components/Select";
import Spacing from "Components/Spacing";
import { AccountStateDTO, PlayerAutocompleteDataDTO } from "Contracts/PlayooLeagueClient";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import CompetitionTeamPlayer from "Domain/Competitions/CompetitionTeamPlayer";
import { l } from "Languages";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import api from "Services/Api";
import guard from "Utils/guard";
import retryQuery from "Utils/retryQuery";

const { Option } = AntdSelect;

type MergeProfilesFormDialogProps = {
    player: CompetitionTeamPlayer;
    team: CompetitionTeam;

    competitionName: string;
    seasonName?: string;

    onClose: () => void;
};

const MergeProfilesFormDialog: React.FunctionComponent<MergeProfilesFormDialogProps> = ({
    player,
    seasonName,
    competitionName,
    team,
    onClose,
}) => {
    const [form] = useForm();

    const [playersToMergeFromOtherTeams, setPlayersToMergeFromOtherTeams] = useState<PlayerAutocompleteDataDTO[]>();

    const teamPlayersWithSameFirstAndLastName = useMemo(
        () =>
            team.players?.filter(
                p => p.id !== player.id && p.firstName === player.firstName && p.lastName === player.lastName,
            ),
        [player.firstName, player.id, player.lastName, team.players],
    );

    useEffect(() => {
        (async () => {
            const players = await retryQuery(() =>
                api.playersAutocompleteData({
                    TeamId: player.teamId,
                    FirstName: player.firstName,
                    LastName: player.lastName,
                }),
            );

            setPlayersToMergeFromOtherTeams([
                ...players.OtherPlayers.filter(p => player.accountState !== AccountStateDTO.Linked || !p.HasAccount),
                ...players.PlayersFromPreviousCompetitions.filter(
                    p => player.accountState !== AccountStateDTO.Linked || !p.HasAccount,
                ),
            ]);
        })();
    }, [player.accountState, player.firstName, player.lastName, player.teamId]);

    const mergeProfiles = useCallback(
        async (profileId: string) => {
            const handler = await player.mergeProfile(profileId);

            let success = false;

            handler
                .handle(
                    [
                        "BothProfilesAreOwned",
                        "ProfileCannotBeMergedWithItself",
                        "User1NotFound",
                        "User2NotFound",
                        "failure",
                    ],
                    () => {
                        message.error(l("CompetitionTeamPlayers_MergeProfiles_Failure"));
                    },
                )
                .handle("success", () => {
                    success = true;
                })
                .check();

            if (success) {
                await team.fetchDetails();

                message.success(l("CompetitionTeamPlayers_MergeProfiles_Success"));
                onClose();
            }
        },
        [onClose, player, team],
    );

    const showMergeProfilesConfirmation = useCallback(
        (profileId: string) => {
            AntdModal.confirm({
                onOk: () => mergeProfiles(profileId),
                icon: <ExclamationCircleOutlined />,
                title: l("CompetitionTeamPlayers_MergeProfiles_Confirmation_Title"),
                content: l("CompetitionTeamPlayers_MergeProfiles_Confirmation_Content"),
                okText: l("CompetitionTeamPlayers_MergeProfiles_Confirmation_OkText"),
                okButtonProps: {
                    danger: true,
                },
                cancelText: l("Common_Cancel"),
                centered: true,
                maskClosable: true,
            });
        },
        [mergeProfiles],
    );

    const onFinish = useCallback((values: FormFields) => showMergeProfilesConfirmation(values.playerId), [
        showMergeProfilesConfirmation,
    ]);

    return (
        <Modal
            title={
                <Spacing childrenGutterX>
                    <span>{`${l("CompetitionTeamPlayers_MergeProfiles_Form_Title1")} ${player.firstName} ${
                        player.lastName
                    }`}</span>
                    <span>
                        <Tag>{team.displayName}</Tag> <Tag>{competitionName}</Tag>{" "}
                        {seasonName && <Tag>{seasonName}</Tag>}
                        <span>{l("CompetitionTeamPlayers_MergeProfiles_Form_Title2")}</span>
                    </span>
                </Spacing>
            }
            onCancel={onClose}
            okText={l("CompetitionTeamPlayers_MergeProfiles_Form_OkText")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}>
            {playersToMergeFromOtherTeams && teamPlayersWithSameFirstAndLastName ? (
                <Form layout="vertical" form={form} onFinish={onFinish}>
                    <Form.Item
                        label={l("CompetitionTeamPlayers_MergeProfiles_Form_Player")}
                        name={guard<FormField>("playerId")}
                        rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                        <Select
                            notFoundContent={
                                <EmptyState
                                    text={l(
                                        "CompetitionTeamPlayers_MergeProfiles_Form_Player_EmptyState",
                                        `${player.firstName} ${player.lastName}`,
                                    )}
                                />
                            }>
                            {teamPlayersWithSameFirstAndLastName.map(p => (
                                <Option key={p.id} value={p.id}>
                                    {p.firstName} {p.lastName} <Tag>{team.displayName}</Tag>{" "}
                                    <Tag>{competitionName}</Tag> {seasonName && <Tag>{seasonName}</Tag>}
                                </Option>
                            ))}
                            {playersToMergeFromOtherTeams.map(p => (
                                <Option key={p.Id} value={p.Id}>
                                    {p.FirstName} {p.LastName} <Tag>{p.TeamName}</Tag> <Tag>{p.CompetitionName}</Tag>{" "}
                                    {p.SeasonName && <Tag>{p.SeasonName}</Tag>}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            ) : (
                <Skeleton active loading paragraph={{ rows: 1 }} />
            )}
        </Modal>
    );
};

export type FormFields = {
    playerId: string;
};

type FormField = keyof FormFields;

export default MergeProfilesFormDialog;
