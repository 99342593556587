import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import { action, computed, observable } from "mobx";
import { notUndefined } from "Utils/predicates";
import AvailablePhaseReferences, { RelatedReferences } from "../ExternalReferences/AvailablePhaseReferences";
import { LinkablePhase } from "../ExternalReferences/ExternalReference";
import { ExternalReferenceType } from "../ExternalReferences/ExternalReferenceBase";
import NthBestForPlaceInGroupReference from "../ExternalReferences/NthBestForPlaceInGroupReference";
import PlaceInGroupReference from "../ExternalReferences/PlaceInGroupReference";
import PlaceInTableReference from "../ExternalReferences/PlaceInTableReference";
import AvailableTeamsForGroupsPhase from "./AvailableTeamsForGroupsPhase";
import GroupsPhase from "./GroupsPhase";

type PlaceInGroup = number;

type GroupsPhaseReferenceTypes =
    | ExternalReferenceType.PlaceInGroup
    | ExternalReferenceType.NthBestForPlaceInGroup
    | ExternalReferenceType.PlaceInTable;

class GroupsPhaseTeamsSelection {
    readonly groupId: string;

    @observable availableTeams: AvailableTeamsForGroupsPhase;
    @observable selectedIds: string[] = [];

    @observable availableReferences?: RelatedReferences[];
    @observable selectedReferenceTypes?: Map<
        PlaceInGroup,
        {
            type: GroupsPhaseReferenceTypes;
            selectionDisabled: boolean;
        }
    >;

    constructor(
        groupId: string,
        availableTeams: AvailableTeamsForGroupsPhase,
        phase: GroupsPhase,
        linkedPhase?: LinkablePhase,
    ) {
        this.groupId = groupId;
        this.availableTeams = availableTeams;

        if (linkedPhase && phase.linkedPhaseId) {
            this.availableReferences = new AvailablePhaseReferences(phase, linkedPhase).value;
            this.selectedReferenceTypes = new Map(
                this.availableReferences?.map(ar => [
                    ar.placeInGroup,
                    {
                        type:
                            ar.addedNthBestForPlaceInGroupReferences.length > 0
                                ? ExternalReferenceType.NthBestForPlaceInGroup
                                : ar.placeInGroupReferences.length > 0 || ar.nthBestForPlaceInGroupReferences.length > 0
                                ? ExternalReferenceType.PlaceInGroup
                                : ExternalReferenceType.PlaceInTable,
                        selectionDisabled:
                            ar.addedNthBestForPlaceInGroupReferences.length > 0 ||
                            ar.addedPlaceInGroupReferences.length > 0,
                    },
                ]),
            );
        }
    }

    @action.bound
    toggleReferenceType(placeInGroup: number) {
        const typeSelectionForPlaceInGroup = this.selectedReferenceTypes?.get(placeInGroup);

        if (!typeSelectionForPlaceInGroup || typeSelectionForPlaceInGroup.selectionDisabled) {
            throw new Error("Place in group out of range or selection is disabled..");
        }

        this.selectedReferenceTypes?.set(placeInGroup, {
            type:
                typeSelectionForPlaceInGroup.type === ExternalReferenceType.PlaceInGroup
                    ? ExternalReferenceType.NthBestForPlaceInGroup
                    : ExternalReferenceType.PlaceInGroup,
            selectionDisabled: false,
        });

        this.syncSelection();
    }

    syncSelection() {
        const allAvailableIds = [
            ...this.availableTeams.teams.map(t => t.id),
            ...(this.availableReferences?.flatMap(r =>
                this.selectedReferenceTypes?.get(r.placeInGroup)?.type === ExternalReferenceType.PlaceInGroup
                    ? r.placeInGroupReferences.map(r => r.id)
                    : [],
            ) ?? []),
            ...(this.availableReferences?.flatMap(r =>
                this.selectedReferenceTypes?.get(r.placeInGroup)?.type === ExternalReferenceType.NthBestForPlaceInGroup
                    ? r.nthBestForPlaceInGroupReferences.map(r => r.id)
                    : [],
            ) ?? []),
            ...(this.availableReferences?.map(r =>
                this.selectedReferenceTypes?.get(r.placeInGroup)?.type === ExternalReferenceType.PlaceInTable
                    ? r.placeInTableReference?.id
                    : [],
            ) ?? []),
        ].filter(notUndefined);

        const idsToDelete = this.selectedIds.filter(id => !allAvailableIds.some(t => t === id));

        this.setSelectedIds(this.selectedIds.filter(selectedId => !idsToDelete.some(t => t === selectedId)));
    }

    @action.bound
    setSelectedIds(ids: string[]) {
        this.selectedIds = ids;
    }

    @computed get teamsSelection(): CompetitionTeam[] {
        return this.availableTeams.teams.filter(t => this.selectedIds.some(selectedId => selectedId === t.id));
    }

    @computed get placeInGroupReferencesSelection(): PlaceInGroupReference[] | undefined {
        if (!this.selectedReferenceTypes) {
            return undefined;
        }

        return Array.from(this.selectedReferenceTypes.entries())
            .filter(s => s[1].type === ExternalReferenceType.PlaceInGroup)
            .flatMap(([placeInGroup]) => {
                const referencesForPlaceInGroup = this.availableReferences?.find(
                    ar => ar.placeInGroup === placeInGroup,
                );

                return (
                    referencesForPlaceInGroup?.placeInGroupReferences.filter(r =>
                        this.selectedIds.some(selectedId => selectedId === r.id),
                    ) ?? []
                );
            });
    }

    @computed get nthBestForPlaceInGroupReferencesSelection(): NthBestForPlaceInGroupReference[] | undefined {
        if (!this.selectedReferenceTypes) {
            return undefined;
        }

        return Array.from(this.selectedReferenceTypes.entries())
            .filter(s => s[1].type === ExternalReferenceType.NthBestForPlaceInGroup)
            .flatMap(([placeInGroup]) => {
                const referencesForPlaceInGroup = this.availableReferences?.find(
                    ar => ar.placeInGroup === placeInGroup,
                );

                return (
                    referencesForPlaceInGroup?.nthBestForPlaceInGroupReferences.filter(r =>
                        this.selectedIds.some(selectedId => selectedId === r.id),
                    ) ?? []
                );
            });
    }

    @computed get placeInTableReferencesSelection(): PlaceInTableReference[] | undefined {
        if (!this.selectedReferenceTypes) {
            return undefined;
        }

        return Array.from(this.selectedReferenceTypes.entries())
            .filter(s => s[1].type === ExternalReferenceType.PlaceInTable)
            .map(s => {
                return this.availableReferences?.find(
                    ar =>
                        ar.placeInGroup === s[0] &&
                        ar.placeInTableReference &&
                        this.selectedIds.some(sId => sId === ar.placeInTableReference?.id),
                )?.placeInTableReference;
            })
            .filter(notUndefined);
    }
}

export default GroupsPhaseTeamsSelection;
