import { Form, Input, Spin } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import PhoneNumberInput from "Components/PhoneNumberInput";
import CompetitionReferee from "Domain/Competitions/CompetitionReferee";
import { l } from "Languages";
import React, { useCallback } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";
import { isValidPhoneNumber } from "Utils/validationHelpers";

export type RefereeFormValidationErrors = Partial<Record<FormField, string>>;

type RefereeFormDialogProps = {
    referee?: CompetitionReferee;
    initialValues?: Partial<FormFields>;

    onSave: (fields: FormFields) => Promise<RefereeFormValidationErrors | undefined>;
    onClose: () => void;
    onPhoneNumberUniquenessCheck: (countryCode: string, phoneNumber: string) => boolean;
};

const RefereeFormDialog: React.FunctionComponent<RefereeFormDialogProps> = ({
    referee,
    initialValues,
    onSave,
    onClose,
    onPhoneNumberUniquenessCheck,
}) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const onFinish = useCallback(
        (values: FormFields) =>
            runInTask(async () => {
                const validationErrors = await onSave(values);

                for (const field in validationErrors) {
                    form.setFields([
                        {
                            name: field,
                            errors: [validationErrors[field]],
                            value: values[field],
                        },
                    ]);
                }
            }),
        [onSave, form, runInTask],
    );

    return (
        <Modal
            title={l("CompetitionDetails_Referees_Form_Title")}
            onCancel={() => onClose()}
            okText={referee ? l("Common_Save") : l("Common_Add")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}>
            <Spin spinning={isRunning}>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        [guard<FormField>("firstName")]: initialValues?.firstName ?? referee?.firstName ?? undefined,
                        [guard<FormField>("lastName")]: initialValues?.lastName ?? referee?.lastName ?? undefined,
                        [guard<FormField>("countryCode")]: "+48",
                        [guard<FormField>("phoneNumber")]:
                            initialValues?.phoneNumber ?? referee?.phoneNumber ?? undefined,
                    }}>
                    <Form.Item
                        label={l("CompetitionDetails_Referees_Form_FirstName")}
                        name={guard<FormField>("firstName")}
                        rules={[
                            { required: true, message: l("Common_Validation_FieldRequired") },
                            { max: 250, message: l("Common_Validation_FieldTooLong") },
                        ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={l("CompetitionDetails_Referees_Form_LastName")}
                        name={guard<FormField>("lastName")}
                        rules={[
                            { required: true, message: l("Common_Validation_FieldRequired") },
                            { max: 250, message: l("Common_Validation_FieldTooLong") },
                        ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={l("Common_Form_CountryCode")} name={guard<FormField>("countryCode")}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label={l("Common_Form_PhoneNumber")}
                        name={guard<FormField>("phoneNumber")}
                        rules={[
                            { required: true, message: l("Common_Validation_FieldRequired") },
                            {
                                message: l("Common_Validation_InvalidPhoneNumber"),
                                validator: (rule, value: FormFields["phoneNumber"] | undefined, callback) => {
                                    if (value && !isValidPhoneNumber(value)) {
                                        callback(rule.message as string);
                                    } else {
                                        callback();
                                    }
                                },
                                validateTrigger: "onBlur",
                            },
                            {
                                message: l("CompetitionDetails_Referees_Form_PhoneNumberNotUnique"),
                                validator: (rule, value: FormFields["phoneNumber"] | undefined, callback) => {
                                    const countryCode: FormFields["countryCode"] | undefined = form.getFieldValue(
                                        guard<FormField>("countryCode"),
                                    );

                                    const isPhoneNumberUnique = onPhoneNumberUniquenessCheck(
                                        countryCode ?? "",
                                        value ?? "",
                                    );

                                    if (value && !isPhoneNumberUnique) {
                                        callback(rule.message as string);
                                    } else {
                                        callback();
                                    }
                                },
                                validateTrigger: "onBlur",
                            },
                        ]}>
                        <PhoneNumberInput />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

export type FormFields = {
    firstName: string;
    lastName: string;
    countryCode: string;
    phoneNumber: string;
};

type FormField = keyof FormFields;

export default RefereeFormDialog;
