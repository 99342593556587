import mkCx, { rawClassName } from "@leancode/cx";
import KnockoutPhase from "Domain/CompetitionPhases/KnockoutPhase/KnockoutPhase";
import { formatTeamParticipatingInMatch } from "Domain/Matches/formatting";
import { Match } from "Domain/Matches/Match";
import React, { HTMLAttributes, useMemo } from "react";
import styles from "./styles.scss";

const cx = mkCx(styles);

type TeamParticipatingInMatchProps = {
    match: Match;
    side: "team1" | "team2";
    knockoutPhase?: KnockoutPhase;

    danger?: boolean;

    reserveSpaceForHighlight?: boolean;

    className?: string;
} & HTMLAttributes<HTMLSpanElement>;

const TeamParticipatingInMatch: React.FunctionComponent<TeamParticipatingInMatchProps> = ({
    match,
    side,
    knockoutPhase,
    danger,
    reserveSpaceForHighlight,
    className,
    ...spanProps
}) => {
    const isTeamDeleted = useMemo(
        () => (side === "team1" && match.isTeam1Deleted) || (side === "team2" && match.isTeam2Deleted),
        [match.isTeam1Deleted, match.isTeam2Deleted, side],
    );

    const formattedTeam = formatTeamParticipatingInMatch(match, side, knockoutPhase);

    return (
        <span
            {...spanProps}
            title={formattedTeam}
            className={cx(
                reserveSpaceForHighlight && "reserve-space-for-highlight",
                (danger || isTeamDeleted) && "danger",
                rawClassName(className),
            )}>
            {formattedTeam}
        </span>
    );
};

export default TeamParticipatingInMatch;
