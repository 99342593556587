import { CompetitionPlayerRankingsDTO } from "Contracts/PlayooLeagueClient";
import { action, observable } from "mobx";

class CompetitionRankingsConfiguration {
    @observable goals: boolean;
    @observable assists: boolean;
    @observable canadian: boolean;
    @observable goalkeeping: boolean;
    @observable matchMvps: boolean;
    @observable teamMvps: boolean;
    @observable yellowCards: boolean;
    @observable redCards: boolean;

    constructor({
        assists,
        canadian,
        goalkeeping,
        goals,
        matchMvps,
        teamMvps,
        yellowCards,
        redCards,
    }: CompetitionRankingsConfigurationInit) {
        this.goals = goals;
        this.assists = assists;
        this.canadian = canadian;
        this.goalkeeping = goalkeeping;
        this.matchMvps = matchMvps;
        this.teamMvps = teamMvps;
        this.yellowCards = yellowCards;
        this.redCards = redCards;
    }

    @action.bound
    updateSetting(setting: AvailableRankings, value: boolean) {
        this[setting] = value;
    }

    static fromDTO(dto: CompetitionPlayerRankingsDTO) {
        return new CompetitionRankingsConfiguration({
            assists: dto.Assists,
            canadian: dto.Canadian,
            goalkeeping: dto.Goalkeeping,
            goals: dto.Goals,
            matchMvps: dto.MatchMvps,
            teamMvps: dto.TeamMvps,
            yellowCards: dto.YellowCards,
            redCards: dto.RedCards,
        });
    }

    toDTO(): CompetitionPlayerRankingsDTO {
        return {
            Assists: this.assists,
            Canadian: this.canadian,
            Goalkeeping: this.goalkeeping,
            Goals: this.goals,
            MatchMvps: this.matchMvps,
            TeamMvps: this.teamMvps,
            YellowCards: this.yellowCards,
            RedCards: this.redCards,
        };
    }
}

type CompetitionRankingsConfigurationInit = Pick<
    CompetitionRankingsConfiguration,
    "goals" | "assists" | "canadian" | "goalkeeping" | "matchMvps" | "teamMvps" | "yellowCards" | "redCards"
>;

export type AvailableRankings = keyof CompetitionRankingsConfigurationInit;

export default CompetitionRankingsConfiguration;
