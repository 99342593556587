import { Button, Checkbox, List, Skeleton } from "antd";
import Spacing from "Components/Spacing";
import Competition from "Domain/Competitions/Competition";
import CompetitionRankingsConfiguration, {
    AvailableRankings,
} from "Domain/Competitions/CompetitionRankingsConfiguration";
import CompetitionSportsFieldsTable from "DomainComponents/CompetitionSportsFieldsTable";
import { l } from "Languages";
import { Observer, useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import useRunInTask from "Utils/Hooks/useRunInTask";

type CompetitionSettingsProps = {
    competition: Competition;
};

const availableRankings: { type: AvailableRankings; label: string }[] = [
    { type: "goals", label: l("CompetitionSettings_PlayerRankings_Goals") },
    { type: "assists", label: l("CompetitionSettings_PlayerRankings_Assists") },
    { type: "canadian", label: l("CompetitionSettings_PlayerRankings_Canadian") },
    { type: "goalkeeping", label: l("CompetitionSettings_PlayerRankings_Goalkeeping") },
    { type: "matchMvps", label: l("CompetitionSettings_PlayerRankings_MatchMvps") },
    { type: "teamMvps", label: l("CompetitionSettings_PlayerRankings_TeamMvps") },
    { type: "yellowCards", label: l("CompetitionSettings_PlayerRankings_YellowCards") },
    { type: "redCards", label: l("CompetitionSettings_PlayerRankings_RedCards") },
];

const CompetitionSettings: React.FunctionComponent<CompetitionSettingsProps> = ({ competition }) => {
    const [draft, setDraft] = useState<CompetitionRankingsConfiguration>();
    const [settingsSaveInProgress, runSettingsSaveInTask] = useRunInTask();

    useEffect(() => {
        if (!competition.settings) {
            competition.fetchSettings();
        }
    }, [competition]);

    const rankingsConfiguration = useObserver(() => competition.settings?.rankingsConfiguration);

    const initializeDraftFromActualConfiguration = useCallback(
        () =>
            setDraft(
                rankingsConfiguration
                    ? new CompetitionRankingsConfiguration({
                          goals: rankingsConfiguration.goals,
                          assists: rankingsConfiguration.assists,
                          canadian: rankingsConfiguration.canadian,
                          goalkeeping: rankingsConfiguration.goalkeeping,
                          matchMvps: rankingsConfiguration.matchMvps,
                          teamMvps: rankingsConfiguration.teamMvps,
                          yellowCards: rankingsConfiguration.yellowCards,
                          redCards: rankingsConfiguration.redCards,
                      })
                    : undefined,
            ),
        [rankingsConfiguration],
    );

    useEffect(() => {
        initializeDraftFromActualConfiguration();
    }, [rankingsConfiguration, initializeDraftFromActualConfiguration]);

    const settingsUnchanged = useObserver(
        () =>
            draft &&
            rankingsConfiguration &&
            availableRankings.reduce(
                (acc, setting) => acc && draft?.[setting.type] === rankingsConfiguration[setting.type],
                true,
            ),
    );

    return useObserver(() => (
        <Skeleton loading={!draft} active={!draft}>
            <Spacing childrenGutterY>
                <List
                    header={<b>{l("CompetitionSettings_PlayerRankings")}</b>}
                    dataSource={availableRankings}
                    renderItem={setting => (
                        <Observer>
                            {() => (
                                <List.Item>
                                    <Checkbox
                                        checked={draft?.[setting.type]}
                                        onChange={e => draft?.updateSetting(setting.type, e.target.checked)}>
                                        {setting.label}
                                    </Checkbox>
                                </List.Item>
                            )}
                        </Observer>
                    )}
                />
                {!settingsUnchanged && draft && (
                    <Spacing childrenGutterX>
                        <Button onClick={() => initializeDraftFromActualConfiguration()}>{l("Common_Cancel")}</Button>
                        <Button
                            type="primary"
                            loading={settingsSaveInProgress}
                            onClick={() => runSettingsSaveInTask(() => competition.updateSettings(draft))}>
                            {l("Common_Save")}
                        </Button>
                    </Spacing>
                )}
                <div>
                    <b>{l("CompetitionDetails_SportsFields")}</b>
                </div>
                <CompetitionSportsFieldsTable competition={competition} />
            </Spacing>
        </Skeleton>
    ));
};

export default CompetitionSettings;
