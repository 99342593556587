import { HistorySeasonDTO } from "Contracts/PlayooLeagueClient";
import HistoryCompetition from "Domain/Competitions/HistoryCompetition";
import { observable } from "mobx";

class HistorySeason {
    readonly id: string;

    @observable name: string;
    @observable competitions: HistoryCompetition[];

    constructor(id: string, name: string, competitions: HistoryCompetition[]) {
        this.id = id;
        this.name = name;
        this.competitions = competitions;
    }

    static fromDTO(dto: HistorySeasonDTO): HistorySeason {
        return new HistorySeason(dto.Id, dto.Name, dto.Competitions.map(HistoryCompetition.fromDTO));
    }
}

export default HistorySeason;
