import mkCx from "@leancode/cx";
import { Button, Form, Input, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import UnauthorizedAppLayout from "Components/AuthenticationLayout";
import NavigationLink from "Components/NavigationLink";
import authentication from "Domain/Account/Authentication";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import routes from "Router/routes";
import guard from "Utils/guard";
import styles from "./styles.scss";

const cx = mkCx(styles);

const SignIn: React.FunctionComponent = () => {
    const [form] = useForm();

    const onFinish = useCallback(
        async (values: FormFields) => {
            const result = await authentication.signIn(values.username, values.password);

            switch (result) {
                case "network_error":
                    form.setFields([
                        {
                            name: guard<FormField>("password"),
                            value: values.password,
                            errors: [l("SignIn_NetworkError")],
                        },
                    ]);
                    break;

                case "invalid_password":
                    form.setFields([
                        {
                            name: guard<FormField>("password"),
                            value: values.password,
                            errors: [l("SignIn_IncorrectCredentials")],
                        },
                    ]);
                    break;
            }
        },
        [form],
    );

    return useObserver(() => (
        <UnauthorizedAppLayout>
            <Spin spinning={authentication.signInState === "signing_in"}>
                <Form form={form} onFinish={onFinish} layout="vertical">
                    <Form.Item
                        name={guard<FormField>("username")}
                        label={l("SignIn_Login")}
                        rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={guard<FormField>("password")}
                        label={l("SignIn_Password")}
                        rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                        <Input type="password" />
                    </Form.Item>
                    <UnauthorizedAppLayout.Actions>
                        <Button htmlType="submit" className={cx("sign-in-button")} type="primary">
                            {l("SignIn_SignIn")}
                        </Button>
                        <Link to={routes.recoverPassword()}>{l("SignIn_Password_Recovery")}</Link>
                        <div>{l("SignIn_Or")}</div>
                        <NavigationLink type="button" to={routes.signUp()}>
                            {l("SignIn_SignUp")}
                        </NavigationLink>
                    </UnauthorizedAppLayout.Actions>
                </Form>
            </Spin>
        </UnauthorizedAppLayout>
    ));
};

type FormFields = {
    username: string;
    password: string;
};

type FormField = keyof FormFields;

export default SignIn;
