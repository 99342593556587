import { CopyOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Button, Tag } from "antd";
import Break from "Domain/CompetitionPhases/SchedulePlanner/Break";
import { l } from "Languages";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import styles from "./styles.scss";

const cx = mkCx(styles);

type BreakItemDraggableProps = {
    index: number;

    scheduleBreak: Break;

    onRemove: () => void;

    onDuplicate: () => void;
};

const BreakItemDraggable: React.FunctionComponent<BreakItemDraggableProps> = ({
    index,
    scheduleBreak,
    onRemove,
    onDuplicate,
}) => {
    return (
        <Draggable draggableId={scheduleBreak.id} index={index}>
            {provided => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <div className={cx("break-item")}>
                        <div className={cx("remove-button-container")} onClick={onRemove}>
                            <Button type="dashed" danger size="small">
                                {l("Common_Remove")}
                            </Button>
                        </div>
                        <div>
                            <div>{l("CompetitionPhases_PlanSchedule_Break")}</div>
                            <Tag>{scheduleBreak.formatDuration()}</Tag>
                        </div>
                        <Button type="ghost" onClick={onDuplicate} className={cx("duplicate")}>
                            <CopyOutlined />
                        </Button>
                    </div>
                </div>
            )}
        </Draggable>
    );
};

export default BreakItemDraggable;
