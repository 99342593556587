import mkCx from "@leancode/cx";
import { Form, InputNumber } from "antd";
import { useForm } from "antd/lib/form/Form";
import { Store } from "antd/lib/form/interface";
import SeasonSchedulePlanner from "Domain/CompetitionPhases/SchedulePlanner/SeasonSchedulePlanner";
import { l } from "Languages";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { FieldData } from "rc-field-form/lib/interface";
import React, { useCallback, useState } from "react";
import guard from "Utils/guard";
import AddMatchdayFormDialog from "./AddMatchdayFormDialog";
import MatchdaySelect from "./MatchdaySelect";
import styles from "./styles.scss";

const cx = mkCx(styles);

type SchedulePlannerFormProps = {
    planner: Pick<
        SeasonSchedulePlanner,
        "matchdays" | "slotsConfiguration" | "currentMatchdayConfiguration" | "setCurrentMatchday" | "addMatchday"
    >;
};

const SchedulePlannerForm: React.FunctionComponent<SchedulePlannerFormProps> = observer(({ planner }) => {
    const [form] = useForm();

    const [showAddMatchdayDialog, setShowAddMatchdayDialog] = useState(false);

    const onValuesChange = useCallback(
        (_, allFields: Store) => {
            let matchDurationInMinutes: FormFields["matchDurationInMinutes"] =
                allFields[guard<FormField>("matchDurationInMinutes")];
            let pauseBetweenMatchesInMinutes: FormFields["pauseBetweenMatchesInMinutes"] =
                allFields[guard<FormField>("pauseBetweenMatchesInMinutes")];

            if (!(typeof matchDurationInMinutes === "number")) {
                matchDurationInMinutes = undefined;
            }

            if (!(typeof pauseBetweenMatchesInMinutes === "number")) {
                pauseBetweenMatchesInMinutes = undefined;
            }

            planner.slotsConfiguration?.update({
                matchDurationInMinutes,
                pauseBetweenMatchesInMinutes,
            });
        },
        [planner],
    );

    if (!planner.currentMatchdayConfiguration || !planner.slotsConfiguration) return null;

    const fields: FieldData[] = [
        {
            name: guard<FormField>("currentMatchday"),
            value: planner.currentMatchdayConfiguration.day,
        },
        {
            name: guard<FormField>("matchDurationInMinutes"),
            value: planner.slotsConfiguration.matchDurationInMinutes,
        },
        {
            name: guard<FormField>("pauseBetweenMatchesInMinutes"),
            value: planner.slotsConfiguration.pauseBetweenMatchesInMinutes,
        },
    ];

    return (
        <>
            <Form form={form} fields={fields} layout="inline" onValuesChange={onValuesChange}>
                <Form.Item
                    name={guard<FormField>("currentMatchday")}
                    label={l("CompetitionPhases_PlanSchedule_Form_Matchday")}
                    className={cx("form-item")}>
                    <MatchdaySelect
                        matchdays={planner.matchdays}
                        onAddMatchday={() => setShowAddMatchdayDialog(true)}
                        onSetInitialMatchdayDate={planner.currentMatchdayConfiguration.setStartTime}
                        onChange={planner.setCurrentMatchday}
                    />
                </Form.Item>
                <Form.Item
                    name={guard<FormField>("matchDurationInMinutes")}
                    label={l("CompetitionPhases_PlanSchedule_Form_MatchDurationInMinutes")}
                    className={cx("form-item")}>
                    <InputNumber min={0} precision={0} />
                </Form.Item>
                <Form.Item
                    name={guard<FormField>("pauseBetweenMatchesInMinutes")}
                    label={l("CompetitionPhases_PlanSchedule_Form_PauseBetweenMatchesInMinutes")}
                    className={cx("form-item")}>
                    <InputNumber min={0} precision={0} />
                </Form.Item>
            </Form>
            {showAddMatchdayDialog && (
                <AddMatchdayFormDialog
                    existingMatchdays={planner.matchdays}
                    onClose={() => setShowAddMatchdayDialog(false)}
                    onAddMatchday={date => {
                        planner.addMatchday(date);
                        setShowAddMatchdayDialog(false);
                    }}
                />
            )}
        </>
    );
});

type FormFields = {
    currentMatchday?: moment.Moment | null;
    matchDurationInMinutes?: number | string | null;
    pauseBetweenMatchesInMinutes?: number | string | null;
};

type FormField = keyof FormFields;

export default SchedulePlannerForm;
