import { message, Skeleton, Tabs } from "antd";
import { useForm } from "antd/lib/form/Form";
import Modal from "Components/Modal";
import { PhaseTypeDTO } from "Contracts/PlayooLeagueClient";
import competitionStore from "Domain/Competitions";
import competitionTeamStore from "Domain/Competitions/CompetitionTeamStore";
import { Match } from "Domain/Matches/Match";
import { MatchReportModifyData, PresentPlayer } from "Domain/Matches/MatchBase";
import MatchPlayer from "Domain/Matches/MatchPlayer";
import MatchResult from "Domain/Matches/MatchResult";
import isPromise from "is-promise";
import { l } from "Languages";
import _ from "lodash";
import { useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useRunInTask from "Utils/Hooks/useRunInTask";
import LineupSelect from "./LineupSelect";
import MatchReportForm, { FormFields } from "./MatchReportForm";

type MatchReportDialogProps = {
    match: Match;

    onClose: () => void;
};

type TabKey = "team1" | "team2" | "report";

const MatchReportDialog: React.FunctionComponent<MatchReportDialogProps> = ({ match, onClose }) => {
    const mode = match.canAddReport ? "create" : "edit";

    const [isRunning, runInTask] = useRunInTask();
    const [activeTab, setActiveTab] = useState<TabKey>("report");

    const [selectedTeam1Players, setSelectedTeam1Players] = useState<PresentPlayer[]>([]);
    const [selectedTeam2Players, setSelectedTeam2Players] = useState<PresentPlayer[]>([]);

    const team1 = useObserver(() => match && (match.team1Id ? competitionTeamStore.getById(match.team1Id) : undefined));
    const team2 = useObserver(() => match && (match.team2Id ? competitionTeamStore.getById(match.team2Id) : undefined));

    useEffect(() => {
        team1 && team1.fetchDetails();
        team2 && team2.fetchDetails();
    }, [team1, team2]);

    const team1Players = team1?.players;
    const team2Players = team2?.players;

    const selectableTeam1Players = useMemo(
        () => _.uniqBy([...(team1Players ?? []), ...(match.team1Lineup ?? [])], p => p.id),
        [team1Players, match.team1Lineup],
    );
    const selectableTeam2Players = useMemo(
        () => _.uniqBy([...(team2Players ?? []), ...(match.team2Lineup ?? [])], p => p.id),
        [team2Players, match.team2Lineup],
    );

    const areTeamPlayersAvailable = !!team1Players && !!team2Players;

    useEffect(() => {
        if (mode === "create") {
            setSelectedTeam1Players([...(team1Players?.map(p => ({ id: p.id, isGoalkeeper: false })) ?? [])]);
            setSelectedTeam2Players([...(team2Players?.map(p => ({ id: p.id, isGoalkeeper: false })) ?? [])]);
        } else {
            setSelectedTeam1Players([
                ...(match.team1Lineup?.map(p => ({ id: p.id, isGoalkeeper: p.isGoalkeeper })) ?? []),
            ]);
            setSelectedTeam2Players([
                ...(match.team2Lineup?.map(p => ({ id: p.id, isGoalkeeper: p.isGoalkeeper })) ?? []),
            ]);
        }
    }, [team1Players, team2Players, match, mode]);

    const [matchReportForm] = useForm();

    const onMatchReportFormFinish = useCallback(
        async (values: FormFields) =>
            runInTask(async () => {
                const matchReportData: MatchReportModifyData = {
                    result: new MatchResult({
                        fullTimeScore: {
                            team1: parseInt(values.finalScore.team1),
                            team2: parseInt(values.finalScore.team2),
                        },
                        winner: values.winner,
                        halfTimeScore:
                            values.halfTimeScore && values.halfTimeScore.team1 && values.halfTimeScore.team2
                                ? {
                                      team1: parseInt(values.halfTimeScore.team1),
                                      team2: parseInt(values.halfTimeScore.team2),
                                  }
                                : undefined,
                        penaltyScore:
                            values.penaltiesPlayed &&
                            values.penaltyScore &&
                            values.penaltyScore.team1 &&
                            values.penaltyScore.team2
                                ? {
                                      team1: parseInt(values.penaltyScore.team1),
                                      team2: parseInt(values.penaltyScore.team2),
                                  }
                                : undefined,
                    }),
                    team1PresentPlayers: selectedTeam1Players,
                    team2PresentPlayers: selectedTeam2Players,
                    matchMvp: values.matchMvp,
                    team1Mvp: values.team1Mvp,
                    team2Mvp: values.team2Mvp,
                };

                if (mode === "create") {
                    const result = await match.publishMatchReport(matchReportData);

                    await result
                        .handle(
                            [
                                "EventNotValid",
                                "EventsNullOrMissing",
                                "KnockoutPhaseMatchesCannotEndWithADraw",
                                "MatchCannotHaveReportAdded",
                                "MatchNotFound",
                                "PlayerIdNotInPresentPlayers",
                                "PlayerNotFound",
                                "PresentPlayersNullOrMissing",
                                "ResultNotValid",
                                "ResultNullOrMissing",
                                "EventsOutOfOrder",
                                "PlayersHaveDuplicates",
                                "failure",
                            ],
                            () => {
                                message.error(l("MatchDetails_PublishMatchReport_Failure"));
                            },
                        )
                        .handle("success", async () => {
                            await competitionStore.fetchMatchDetails(match.id);

                            message.success(l("MatchDetails_PublishMatchReport_Success"));

                            onClose();
                        })
                        .check({
                            reducer: (prev, curr) => {
                                return [...prev, isPromise(curr) ? curr : Promise.resolve(curr)];
                            },
                            initialValue: [] as Promise<void>[],
                        });
                } else {
                    const result = await match.editMatchReport(matchReportData);

                    await result
                        .handle("success", async () => {
                            await competitionStore.fetchMatchDetails(match.id);

                            message.success(l("MatchDetails_EditMatchReport_Success"));

                            onClose();
                        })
                        .handle(
                            [
                                "EventNotValid",
                                "EventsNullOrMissing",
                                "EventsOutOfOrder",
                                "KnockoutPhaseMatchesCannotHaveWinnerChanged",
                                "MatchNotFound",
                                "MatchReportNotFound",
                                "PlayerNotFoundInPresentPlayersForTeam",
                                "ResultNotValid",
                                "ResultNullOrMissing",
                                "PlayerNotFound",
                                "PlayersHaveDuplicates",
                                "failure",
                            ],
                            () => {
                                message.error(l("MatchDetails_EditMatchReport_Failure"));
                            },
                        )
                        .check({
                            reducer: (prev, curr) => {
                                return [...prev, isPromise(curr) ? curr : Promise.resolve(curr)];
                            },
                            initialValue: [] as Promise<void>[],
                        });
                }
            }),
        [onClose, match, runInTask, selectedTeam1Players, selectedTeam2Players, mode],
    );

    const reportSelectedTeam1Players = useMemo(
        () =>
            selectableTeam1Players.filter(p => selectedTeam1Players.some(selectedPlayer => selectedPlayer.id === p.id)),
        [selectableTeam1Players, selectedTeam1Players],
    );

    const reportSelectedTeam2Players = useMemo(
        () =>
            selectableTeam2Players.filter(p => selectedTeam2Players.some(selectedPlayer => selectedPlayer.id === p.id)),
        [selectableTeam2Players, selectedTeam2Players],
    );

    return useObserver(() => (
        <Modal
            onCancel={onClose}
            onOk={matchReportForm.submit}
            title={
                mode === "create" ? l("MatchDetails_PublishMatchReport_Title") : l("MatchDetails_EditMatchReport_Title")
            }
            cancelText={l("Common_Cancel")}
            okText={mode === "create" ? l("MatchDetails_PublishMatchReport_OkText") : l("Common_Save")}
            okButtonProps={{
                loading: isRunning,
                disabled: isRunning,
            }}>
            <Skeleton loading={!areTeamPlayersAvailable} active={!areTeamPlayersAvailable}>
                {team1?.players && team2?.players && selectedTeam1Players && selectedTeam2Players && (
                    <Tabs activeKey={activeTab} onChange={(key: TabKey) => setActiveTab(key)}>
                        <Tabs.TabPane key="report" tab={l("MatchDetails_PublishMatchReport_MatchReport")} forceRender>
                            <MatchReportForm
                                canMatchEndWithDraw={!(match.phaseType === PhaseTypeDTO.Knockout)}
                                canEditResult={mode === "create"}
                                canEditWinner={!(mode === "edit" && match.phaseType === PhaseTypeDTO.Knockout)}
                                team1Name={team1.displayName}
                                team2Name={team2.displayName}
                                team1Players={reportSelectedTeam1Players}
                                team2Players={reportSelectedTeam2Players}
                                form={matchReportForm}
                                onFinish={onMatchReportFormFinish}
                                onFinishFailed={() => setActiveTab("report")}
                                initialValues={
                                    match.canEditReport && match.result
                                        ? {
                                              finalScore: {
                                                  team1: match.result.fullTimeScore.team1.toString(),
                                                  team2: match.result.fullTimeScore.team2.toString(),
                                              },
                                              halfTimeScore: match.result.halfTimeScore
                                                  ? {
                                                        team1: match.result.halfTimeScore.team1.toString(),
                                                        team2: match.result.halfTimeScore.team2.toString(),
                                                    }
                                                  : undefined,
                                              penaltyScore: match.result.penaltyScore
                                                  ? {
                                                        team1: match.result.penaltyScore.team1.toString(),
                                                        team2: match.result.penaltyScore.team2.toString(),
                                                    }
                                                  : undefined,
                                              penaltiesPlayed: !!match.result.penaltyScore,
                                              matchMvp: match.matchMvpId,
                                              team1Mvp: match.team1MvpId,
                                              team2Mvp: match.team2MvpId,
                                              winner: match.result.winner,
                                          }
                                        : undefined
                                }
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane key="team1" tab={team1.displayName}>
                            {team1.players && (
                                <LineupSelect
                                    teamPlayers={selectableTeam1Players}
                                    selectedPlayers={selectedTeam1Players}
                                    onToggleSelectPlayer={playerId => {
                                        const index = selectedTeam1Players?.findIndex(p => p.id === playerId);

                                        if (index > -1) {
                                            setSelectedTeam1Players(
                                                selectedTeam1Players.filter(p => p.id !== playerId),
                                            );
                                        } else {
                                            const player = selectableTeam1Players.find(p => p.id === playerId);

                                            player &&
                                                setSelectedTeam1Players([
                                                    ...selectedTeam1Players,
                                                    {
                                                        id: player.id,
                                                        isGoalkeeper: (player as MatchPlayer).isGoalkeeper ?? false,
                                                    },
                                                ]);
                                        }
                                    }}
                                    onToggleGoalkeeper={playerId => {
                                        const foundPlayer = selectedTeam1Players?.find(p => p.id === playerId);

                                        if (foundPlayer) {
                                            setSelectedTeam1Players([
                                                ...selectedTeam1Players.filter(p => p.id !== playerId),
                                                { ...foundPlayer, isGoalkeeper: !foundPlayer.isGoalkeeper },
                                            ]);
                                        }
                                    }}
                                    onSelectAll={() =>
                                        setSelectedTeam1Players(
                                            selectableTeam1Players.map(p => ({
                                                id: p.id,
                                                isGoalkeeper: (p as MatchPlayer).isGoalkeeper ?? false,
                                            })),
                                        )
                                    }
                                    onDeselectAll={() => setSelectedTeam1Players([])}
                                />
                            )}
                        </Tabs.TabPane>
                        <Tabs.TabPane key="team2" tab={team2.displayName}>
                            {team2.players && (
                                <LineupSelect
                                    teamPlayers={selectableTeam2Players}
                                    selectedPlayers={selectedTeam2Players}
                                    onToggleSelectPlayer={playerId => {
                                        const index = selectedTeam2Players?.findIndex(p => p.id === playerId);

                                        if (index > -1) {
                                            setSelectedTeam2Players(
                                                selectedTeam2Players.filter(p => p.id !== playerId),
                                            );
                                        } else {
                                            const player = selectableTeam2Players.find(p => p.id === playerId);

                                            player &&
                                                setSelectedTeam2Players([
                                                    ...selectedTeam2Players,
                                                    {
                                                        id: player.id,
                                                        isGoalkeeper: (player as MatchPlayer).isGoalkeeper ?? false,
                                                    },
                                                ]);
                                        }
                                    }}
                                    onToggleGoalkeeper={playerId => {
                                        const foundPlayer = selectedTeam2Players?.find(p => p.id === playerId);

                                        if (foundPlayer) {
                                            setSelectedTeam2Players([
                                                ...selectedTeam2Players.filter(p => p.id !== playerId),
                                                { ...foundPlayer, isGoalkeeper: !foundPlayer.isGoalkeeper },
                                            ]);
                                        }
                                    }}
                                    onSelectAll={() =>
                                        setSelectedTeam2Players(
                                            selectableTeam2Players.map(p => ({
                                                id: p.id,
                                                isGoalkeeper: (p as MatchPlayer).isGoalkeeper ?? false,
                                            })),
                                        )
                                    }
                                    onDeselectAll={() => setSelectedTeam2Players([])}
                                />
                            )}
                        </Tabs.TabPane>
                    </Tabs>
                )}
            </Skeleton>
        </Modal>
    ));
};

export default MatchReportDialog;
