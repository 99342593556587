import { ApplicationDTO } from "Contracts/RegistrationsClient";
import moment from "moment";
import { dateTimeFromDTO } from "Utils/DTO";

class Application {
    readonly id: string;

    readonly teamName: string;
    readonly teamCaptainOrCoachFullName: string;
    readonly phoneNumber: string;
    readonly email: string;
    readonly additionalInformation?: string;
    readonly submittedAt: moment.Moment;

    readonly maxAllowedBirthYears: (number | undefined)[];

    constructor({
        id,
        email,
        maxAllowedBirthYears,
        phoneNumber,
        teamCaptainOrCoachFullName,
        teamName,
        additionalInformation,
        submittedAt,
    }: ApplicationInit) {
        this.id = id;
        this.email = email;
        this.phoneNumber = phoneNumber;
        this.teamCaptainOrCoachFullName = teamCaptainOrCoachFullName;
        this.teamName = teamName;
        this.additionalInformation = additionalInformation;
        this.maxAllowedBirthYears = maxAllowedBirthYears;
        this.submittedAt = submittedAt;
    }

    static fromDTO(dto: ApplicationDTO) {
        return new Application({
            id: dto.Id,
            email: dto.Email,
            phoneNumber: dto.PhoneNumber,
            teamCaptainOrCoachFullName: dto.TeamCaptainOrCoachFullName,
            teamName: dto.TeamName,
            additionalInformation: dto.AdditionalInformation ?? undefined,
            maxAllowedBirthYears: dto.AgeGroups.map(ag => ag.MaxAllowedBirthYear ?? undefined),
            submittedAt: dateTimeFromDTO(dto.SubmittedAt),
        });
    }
}

type ApplicationInit = Pick<
    Application,
    | "id"
    | "email"
    | "phoneNumber"
    | "teamName"
    | "teamCaptainOrCoachFullName"
    | "maxAllowedBirthYears"
    | "additionalInformation"
    | "submittedAt"
>;

export default Application;
