import mkCx from "@leancode/cx";
import { Button } from "antd";
import { Select } from "Components/Select";
import Spacing from "Components/Spacing";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import { l } from "Languages";
import React, { useEffect, useState } from "react";
import styles from "./styles.scss";

const cx = mkCx(styles);

type ReplacePlaceholderTeamForPhaseSelectProps = {
    availableReplacementTeams: CompetitionTeam[];

    saveInProgress: boolean;

    onCancel: () => void;
    onSave: (replacementId: string) => void;

    className?: string;
};

const ReplacePlaceholderTeamForPhaseSelect: React.FunctionComponent<ReplacePlaceholderTeamForPhaseSelectProps> = ({
    availableReplacementTeams,
    saveInProgress,
    onCancel,
    onSave,
    className,
}) => {
    const [selectedReplacementTeamId, setSelectedReplacementTeamId] = useState<string>();

    useEffect(() => {
        if (selectedReplacementTeamId) {
            const isTeamStillAvailable = availableReplacementTeams.some(t => t.id === selectedReplacementTeamId);

            !isTeamStillAvailable && setSelectedReplacementTeamId(undefined);
        }
    }, [availableReplacementTeams, selectedReplacementTeamId]);

    return (
        <Spacing childrenGutterY className={className}>
            <Select
                className={cx("select")}
                value={selectedReplacementTeamId}
                onChange={(teamId: string) => setSelectedReplacementTeamId(teamId)}
                showArrow
                selectOptions={availableReplacementTeams.map(t => ({
                    value: t.id,
                    label: t.displayName,
                    key: t.id,
                }))}
            />
            <Spacing childrenGutterX className={cx("actions")}>
                <Button onClick={onCancel}>{l("Common_Cancel")}</Button>
                <Button
                    loading={saveInProgress}
                    disabled={!selectedReplacementTeamId || saveInProgress}
                    onClick={() => selectedReplacementTeamId && onSave(selectedReplacementTeamId)}
                    type="primary">
                    {l("CompetitionPhases_PlaceholderTeams_Replace_Select_Replace")}
                </Button>
            </Spacing>
        </Spacing>
    );
};

export default ReplacePlaceholderTeamForPhaseSelect;
