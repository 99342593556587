import { MatchResultDTO, MatchScoreDTO, MatchWinnerDTO } from "Contracts/PlayooLeagueClient";
import { observable } from "mobx";

export type Score = {
    team1: number;
    team2: number;
};

export const scoreFromDTO = (dto: MatchScoreDTO): Score => {
    return {
        team1: dto.Team1,
        team2: dto.Team2,
    };
};

const scoreToDTO = (score: Score): MatchScoreDTO => {
    return {
        Team1: score.team1,
        Team2: score.team2,
    };
};

class MatchResult {
    @observable halfTimeScore?: Score;
    @observable fullTimeScore: Score;
    @observable afterExtraTimeScore?: Score;
    @observable penaltyScore?: Score;
    @observable winner: MatchWinnerDTO;

    constructor({ fullTimeScore, winner, afterExtraTimeScore, halfTimeScore, penaltyScore }: MatchResultInit) {
        this.halfTimeScore = halfTimeScore;
        this.fullTimeScore = fullTimeScore;
        this.afterExtraTimeScore = afterExtraTimeScore;
        this.penaltyScore = penaltyScore;
        this.winner = winner;
    }

    static fromDTO(dto: MatchResultDTO) {
        return new MatchResult({
            halfTimeScore: dto.HalfTimeScore ? scoreFromDTO(dto.HalfTimeScore) : undefined,
            fullTimeScore: scoreFromDTO(dto.FullTimeScore),
            afterExtraTimeScore: dto.AfterExtraTimeScore ? scoreFromDTO(dto.AfterExtraTimeScore) : undefined,
            penaltyScore: dto.PenaltyScore ? scoreFromDTO(dto.PenaltyScore) : undefined,
            winner: dto.Winner,
        });
    }

    toDTO(): MatchResultDTO {
        return {
            FullTimeScore: scoreToDTO(this.fullTimeScore),
            Winner: this.winner,
            AfterExtraTimeScore: this.afterExtraTimeScore ? scoreToDTO(this.afterExtraTimeScore) : undefined,
            HalfTimeScore: this.halfTimeScore ? scoreToDTO(this.halfTimeScore) : undefined,
            PenaltyScore: this.penaltyScore ? scoreToDTO(this.penaltyScore) : undefined,
        };
    }
}

export type MatchResultInit = Pick<
    MatchResult,
    "halfTimeScore" | "fullTimeScore" | "penaltyScore" | "afterExtraTimeScore" | "winner"
>;

export default MatchResult;
