import { message, PageHeader } from "antd";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import PageContent from "Components/PageContent";
import registrationsStore from "Domain/Registrations";
import RegistrationAgeGroup from "Domain/Registrations/RegistrationAgeGroup";
import RegistrationForm, {
    RegistrationDescription,
    RegistrationFormValidationErrors,
} from "DomainComponents/RegistrationForm";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import routes, { PageComponent } from "Router/routes";
import { mkGuard } from "Utils/guard";
import newId from "Utils/newId";

type RegistrationFormValidationErrorKey = keyof RegistrationFormValidationErrors;

const guard = mkGuard<RegistrationFormValidationErrorKey>();

const CreateRegistrationPage: PageComponent<typeof routes.registrations.create> = ({ history: { push } }) => {
    const breadcrumb: BreadcrumbProps["routes"] = useMemo(
        () => [
            {
                breadcrumbName: l("Registrations"),
                path: routes.registrations(),
            },
            {
                breadcrumbName: l("Registrations_Add"),
                path: routes.registrations.create(),
            },
        ],
        [],
    );

    const [registrationId] = useState(newId());

    const addRegistration = useCallback(
        async (registration: RegistrationDescription): Promise<RegistrationFormValidationErrors | undefined> => {
            const result = await registrationsStore.addRegistration({
                id: registrationId,
                sportsVenueName: registration.sportsVenueName,
                address: registration.address,
                city: registration.city,
                articleContent: registration.articleContent,
                articleTitle: registration.articleTitle,
                logoUri: registration.logoUri,
                maxAllowedBirthYears: registration.maxAllowedBirthYears,
                startDate: registration.startDate,
                title: registration.title,
                thumbnailPhotoUri: registration.thumbnailPhotoUri,
                backgroundPhotoUri: registration.backgroundPhotoUri,
                documents: registration.documents,
                endDate: registration.endDate,
                entryFee: registration.entryFee,
                extraFormFieldDescription: registration.extraFormFieldDescription,
                maxTeamsCount: registration.maxTeamsCount,
                summary: registration.summary,
            });

            return result
                .handle(
                    [
                        "ArticleDescriptionInvalid",
                        "AgeGroupsContainsDuplicates",
                        "ArticleIdAlreadyInUse",
                        "RegistrationIdAlreadyInUse",
                        "OneOfDocumentsIdAlreadyInUse",
                        "OneOfAgeGroupsIdAlreadyInUse",
                        "OneOfDocumentsUriMissingOrEmpty",
                        "OneOfDocumentsUriTooLong",
                        "failure",
                    ],
                    () => {
                        message.error(l("Registrations_Add_Failure"));
                    },
                )
                .handle("ArticleContentMissingOrEmpty", () => ({
                    [guard("articleContent")]: l("Common_Validation_FieldRequired"),
                }))
                .handle(["SummaryTooLong"], () => ({
                    [guard("summary")]: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["TitleTooLong", "TitleMissingOrEmpty"], error => ({
                    [guard("title")]:
                        error === "TitleTooLong"
                            ? l("Common_Validation_FieldTooLong")
                            : l("Common_Validation_FieldRequired"),
                }))
                .handle(["AddressTooLong"], () => ({
                    [guard("address")]: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["CityTooLong"], () => ({
                    [guard("city")]: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["SportsVenueNameTooLong"], () => ({
                    [guard("sportsVenueName")]: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["LogoUriTooLong"], () => ({
                    [guard("logoUri")]: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["ThumbnailPhotoUriTooLong"], () => ({
                    [guard("thumbnailPhotoUri")]: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["BackgroundPhotoUriTooLong"], () => ({
                    [guard("backgroundPhotoUri")]: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["AgeGroupsMissingOrEmpty"], () => ({
                    [guard("ageGroups")]: l("Common_Validation_FieldRequired"),
                }))
                .handle(["ArticleTitleTooLong", "ArticleTitleMissingOrEmpty"], error => ({
                    [guard("articleTitle")]:
                        error === "ArticleTitleTooLong"
                            ? l("Common_Validation_FieldTooLong")
                            : l("Common_Validation_FieldRequired"),
                }))
                .handle(["ExtraFormFieldDescriptionTooLong"], () => ({
                    [guard("extraFormFieldDescription")]: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["OneOfDocumentsNameMissingOrEmpty"], () => ({
                    [guard("documents")]: l("Registrations_Form_Documents_Validation_OneOfDocumentsNameMissingOrEmpty"),
                }))
                .handle(["OneOfDocumentsNameTooLong"], () => ({
                    [guard("documents")]: l("Registrations_Form_Documents_Validation_OneOfDocumentsNameTooLong"),
                }))
                .handle(["EntryFeeTooLong"], () => ({
                    [guard("entryFee")]: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["MaxTeamsCountNotPositive"], () => ({
                    [guard("maxTeamsCount")]: l("Common_Validation_Number"),
                }))
                .handle("success", () => {
                    message.success(l("Registrations_Add_Success"));

                    push(routes.registrations());
                })
                .check({
                    reducer: (prev, cur) => {
                        return {
                            ...prev,
                            ...(cur || {}),
                        };
                    },
                    initialValue: {} as RegistrationFormValidationErrors,
                });
        },
        [registrationId, push],
    );

    return useObserver(() => (
        <PageContent>
            <PageContent.Header>
                <PageHeader
                    title={l("Registrations_Add")}
                    onBack={() => push(routes.registrations())}
                    breadcrumb={{
                        routes: breadcrumb,
                        itemRender: ({ path, breadcrumbName }) =>
                            path === location.pathname ? breadcrumbName : <Link to={path}>{breadcrumbName}</Link>,
                    }}
                />
            </PageContent.Header>
            <PageContent.Card>
                <RegistrationForm
                    registrationId={registrationId}
                    mode="create"
                    selectableMaxBirthYears={RegistrationAgeGroup.allPossibleMaxBirthYearsForCurrentYear()}
                    onCancel={() => push(routes.registrations())}
                    onSave={addRegistration}
                />
            </PageContent.Card>
        </PageContent>
    ));
};

export default CreateRegistrationPage;
