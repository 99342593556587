import { CompetitionSportsFieldDTO } from "Contracts/PlayooLeagueClient";
import { action, observable } from "mobx";

class CompetitionSportsField {
    readonly id: string;

    @observable name: string;

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }

    @action.bound
    setName(name: string) {
        this.name = name;
    }

    static fromDTO(dto: CompetitionSportsFieldDTO) {
        return new CompetitionSportsField(dto.Id, dto.Name);
    }

    toDTO(): CompetitionSportsFieldDTO {
        return {
            Id: this.id,
            Name: this.name,
        };
    }

    clone() {
        return new CompetitionSportsField(this.id, this.name);
    }
}

export default CompetitionSportsField;
