import { Empty } from "antd";
import React from "react";

type EmptyStateProps = {
    text: string;
};

const EmptyState: React.FunctionComponent<EmptyStateProps> = ({ text }) => {
    return <Empty description={text} image={Empty.PRESENTED_IMAGE_SIMPLE} />;
};

export default EmptyState;
