import { PhaseTypeDTO } from "Contracts/PlayooLeagueClient";
import { l } from "Languages";
import moment from "moment";

export const formatPhaseType = (phaseType: PhaseTypeDTO) => {
    switch (phaseType) {
        case PhaseTypeDTO.Table:
            return l("CompetitionPhases_Table");

        case PhaseTypeDTO.Custom:
            return l("CompetitionPhases_Custom");

        case PhaseTypeDTO.Knockout:
            return l("CompetitionPhases_Knockout");

        case PhaseTypeDTO.Groups:
            return l("CompetitionPhases_Groups");

        default:
            break;
    }
};

export const dateFormat = "DD.MM.YYYY";
export const timeFormat = "HH:mm";
export const dateWithTimeFormat = `${dateFormat} ${timeFormat}`;

export const formatDate = (date: moment.Moment) => date.format(dateFormat);
export const formatTime = (date: moment.Moment) => date.format(timeFormat);
export const formatDateWithTime = (date: moment.Moment) => date.format(dateWithTimeFormat);
