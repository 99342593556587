import { AgeGroupDTO } from "Contracts/RegistrationsClient";
import { computed, observable } from "mobx";
import moment from "moment";

class RegistrationAgeGroup {
    private static readonly youngestAgeGroupPlayersAge = 4;
    private static readonly oldestAgeGroupPlayersAge = 21;

    private readonly id: string;

    @observable maxAllowedBirthYear?: number;

    @computed get isOpen() {
        return this.maxAllowedBirthYear === undefined;
    }

    constructor(id: string, maxBirthYear?: number) {
        this.id = id;
        this.maxAllowedBirthYear = maxBirthYear;
    }

    toDTO(): AgeGroupDTO {
        return {
            Id: this.id,
            MaxAllowedBirthYear: this.maxAllowedBirthYear,
        };
    }

    static fromDTO(dto: AgeGroupDTO) {
        return new RegistrationAgeGroup(dto.Id, dto.MaxAllowedBirthYear ?? undefined);
    }

    static allPossibleMaxBirthYearsForCurrentYear(): (number | undefined)[] {
        const currentYear = moment().year();

        return [
            ..._.range(
                currentYear - this.youngestAgeGroupPlayersAge,
                currentYear - this.oldestAgeGroupPlayersAge - 1,
                -1,
            ),
            undefined,
        ];
    }
}

export default RegistrationAgeGroup;
