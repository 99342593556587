import { DatePicker, Form, Input, Skeleton } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import { Select } from "Components/Select/index";
import competitionGroupStore from "Domain/CompetitionGroups";
import seasonStore from "Domain/Season";
import SeasonFormDialog from "DomainComponents/SeasonFormDialog";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";
import useCompetitionModal, {
    competitionFromFormState,
    CompetitionsFormState,
    FormFields,
} from "./useCompetitionModal";

type CompetitionFormDialogProps = {
    formState: CompetitionsFormState;
    initialValues?: Partial<FormFields>;

    onClose?: (savedFormFields?: FormFields) => void;
};

const CompetitionFormDialog: React.FunctionComponent<CompetitionFormDialogProps> = ({
    initialValues,
    formState,
    onClose,
}) => {
    const [form] = useForm();
    const [competitionGroupsFetchInProgress, runCompetitionGroupsFetchInProgressInTask] = useRunInTask();
    const [showCreateSeasonDialog, setShowCreateSeasonDialog] = useState(false);

    const competition = competitionFromFormState(formState);

    useEffect(() => {
        if (seasonStore.seasons.length === 0) {
            seasonStore.fetchSeasons();
        }

        if (competitionGroupStore.items.length === 0) {
            runCompetitionGroupsFetchInProgressInTask(() => competitionGroupStore.fetchCompetitionGroups());
        }
    }, [runCompetitionGroupsFetchInProgressInTask]);

    const seasons = useObserver(() => seasonStore.seasons);
    const competitionGroups = useObserver(() => competitionGroupStore.items);

    const setNameError = useCallback(
        (value: FormFields["name"], error: string) =>
            form.setFields([
                {
                    name: guard<FormField>("name"),
                    errors: [error],
                    value: value,
                },
            ]),
        [form],
    );

    const { modal, onFinish, isSaving } = useCompetitionModal(formState, { onClose, setNameError });

    return (
        <>
            <Modal
                title={modal.title}
                onCancel={() => onClose?.()}
                okText={modal.okText}
                cancelText={l("Common_Cancel")}
                okButtonProps={{
                    disabled: isSaving,
                    loading: isSaving,
                }}
                onOk={form.submit}>
                <Skeleton loading={competitionGroupsFetchInProgress} active={competitionGroupsFetchInProgress}>
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={onFinish}
                        initialValues={{
                            [guard<FormField>("name")]:
                                formState.mode === "copy"
                                    ? undefined
                                    : initialValues?.name ?? competition?.name ?? undefined,
                            [guard<FormField>("seasonId")]:
                                initialValues?.seasonId ?? competition?.seasonId ?? undefined,
                            [guard<FormField>("groupId")]: initialValues?.groupId ?? competition?.groupId ?? undefined,
                        }}>
                        <Form.Item
                            label={l("Competitions_Create_Name")}
                            name={guard<FormField>("name")}
                            rules={[
                                { required: true, message: l("Common_Validation_FieldRequired") },
                                { max: 250, message: l("Common_Validation_FieldTooLong") },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Form.Item label={l("Competitions_Create_Season")} name={guard<FormField>("seasonId")}>
                            <Select
                                allowClear
                                showSearch
                                createNewItemLabel={l("Competitions_Create_AddNewSeason")}
                                onCreate={() => setShowCreateSeasonDialog(true)}
                                selectOptions={seasons.map(s => ({ label: s.name, value: s.id, key: s.id }))}
                            />
                        </Form.Item>
                        <Form.Item label={l("Competitions_Create_CompetitionGroup")} name={guard<FormField>("groupId")}>
                            <Select
                                allowClear
                                showSearch
                                selectOptions={competitionGroups.map(c => ({ label: c.name, value: c.id, key: c.id }))}
                            />
                        </Form.Item>
                        {formState.mode === "copy" && (
                            <Form.Item
                                rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}
                                label={l("Competitions_Copy_FirstMatchDay")}
                                name={guard<FormField>("firstMatchDay")}>
                                <DatePicker />
                            </Form.Item>
                        )}
                    </Form>
                </Skeleton>
            </Modal>
            {showCreateSeasonDialog && (
                <SeasonFormDialog
                    onClose={async savedFormFields => {
                        if (savedFormFields) {
                            await seasonStore.fetchSeasons();
                            form.setFields([{ value: savedFormFields?.id, name: guard<FormField>("seasonId") }]);
                        }
                        setShowCreateSeasonDialog(false);
                    }}
                />
            )}
        </>
    );
};

type FormField = keyof FormFields;

export default CompetitionFormDialog;
