import { Button } from "antd";
import React from "react";
import mkCx, { rawClassName } from "@leancode/cx";
import styles from "./styles.scss";
import { ButtonProps } from "antd/lib/button";

const cx = mkCx(styles);

type IconButtonProps = ButtonProps;

const IconButton: React.FunctionComponent<IconButtonProps> = ({ children, ...buttonProps }) => {
    return (
        <Button {...buttonProps} className={cx("button", rawClassName(buttonProps.className))}>
            {children}
        </Button>
    );
};

export default IconButton;
