import { handleResponse } from "@leancode/validation";
import {
    CompetitionPhaseDetailsDTO,
    CompetitionPhaseDTO,
    GenerateKnockoutPhaseMatchSchedule,
    PhaseTypeDTO,
} from "Contracts/PlayooLeagueClient";
import { CompetitionTeamStore } from "Domain/Competitions/CompetitionTeamStore";
import MatchResult from "Domain/Matches/MatchResult";
import MatchStore from "Domain/Matches/MatchStore";
import { l } from "Languages";
import _ from "lodash";
import { computed, observable } from "mobx";
import moment from "moment";
import api from "Services/Api";
import { dateTimeOffsetFromDTOOptional } from "Utils/DTO";
import MathHelpers from "Utils/MathHelpers";
import CompetitionPhaseBase from "../CompetitionPhaseBase";
import ExternalReferencesStore from "../ExternalReferences/ExternalReferencesStore";
import NthBestForPlaceInGroupReference from "../ExternalReferences/NthBestForPlaceInGroupReference";
import PlaceInGroupReference from "../ExternalReferences/PlaceInGroupReference";
import PlaceInTableReference from "../ExternalReferences/PlaceInTableReference";
import KnockoutPhaseMatch, { AdvancingTeamPlaceholder, KnockoutMatchNodeData } from "./KnockoutPhaseMatch";
import KnockoutPhaseMatchConfiguration from "./KnockoutPhaseMatchConfiguration";

class KnockoutPhase extends CompetitionPhaseBase<KnockoutPhaseMatch> {
    get displayName(): string {
        return this.name ?? l("CompetitionPhases_Knockout");
    }

    @observable mainTreeLeafCount: number = 0;

    private constructor(
        id: string,
        competitionId: string,
        dependencies: {
            competitionTeamStore: CompetitionTeamStore;
            matchStore: MatchStore;
            externalReferencesStore: ExternalReferencesStore;
        },
    ) {
        super(id, competitionId, PhaseTypeDTO.Knockout, dependencies);
    }

    static fromDTO(
        dto: CompetitionPhaseDTO | CompetitionPhaseDetailsDTO,
        competitionId: string,
        dependencies: {
            competitionTeamStore: CompetitionTeamStore;
            matchStore: MatchStore;
            externalReferencesStore: ExternalReferencesStore;
        },
    ): KnockoutPhase {
        return new KnockoutPhase(dto.Id, competitionId, dependencies).updateFromDTO(dto);
    }

    updateFromDTO(dto: CompetitionPhaseDTO | CompetitionPhaseDetailsDTO): this {
        this.name = dto.Name ?? undefined;
        this.isOngoing = dto.IsOngoing;
        this.linkedPhaseId = dto.LinkedPhaseId ?? undefined;

        if ("Teams" in dto) {
            this.teamsIds = dto.Teams.map(t => t.TeamId);

            if (dto.KnockoutPhaseDetails) {
                this.matchStore.put(
                    ...dto.KnockoutPhaseDetails.Schedule.map(m => {
                        if (m.MatchInPhaseDesignator > 0) this.mainTreeLeafCount++;

                        return new KnockoutPhaseMatch(
                            m.Id,
                            this.id,
                            {
                                nodeId: m.MatchInPhaseDesignator,
                                article: undefined,
                                date: dateTimeOffsetFromDTOOptional(m.Date),
                                team1Id: m.Team1Id ?? undefined,
                                team2Id: m.Team2Id ?? undefined,
                                status: m.Status,
                                sportsFieldId: m.SportsFieldId ?? undefined,
                                result: m.Result ? MatchResult.fromDTO(m.Result) : undefined,
                                name: m.Name ?? undefined,
                            },
                            this.competitionTeamStore,
                        );
                    }),
                );

                this.mainTreeLeafCount = (this.mainTreeLeafCount + 1) / 2;

                this.matchIds =
                    dto.KnockoutPhaseDetails.Schedule.length > 0
                        ? dto.KnockoutPhaseDetails.Schedule.map(m => m.Id)
                        : undefined;

                dto.PlaceInGroupReferences.forEach(r =>
                    this.externalReferencesStore.put(PlaceInGroupReference.fromDTO(r, this.id)),
                );

                dto.NthBestForPlaceInGroupReferences.forEach(r =>
                    this.externalReferencesStore.put(NthBestForPlaceInGroupReference.fromDTO(r, this.id)),
                );

                dto.PlaceInTableReferences.forEach(r =>
                    this.externalReferencesStore.put(PlaceInTableReference.fromDTO(r, this.id)),
                );

                this.externalReferencesIds = [
                    ...dto.PlaceInGroupReferences.map(r => r.Id),
                    ...dto.NthBestForPlaceInGroupReferences.map(r => r.Id),
                    ...dto.PlaceInTableReferences.map(r => r.Id),
                ];

                this.defaultMatchDurationInMinutes = dto.DefaultMatchDurationInMinutes ?? undefined;
                this.defaultPauseBetweenMatchesInMinutes = dto.DefaultPauseBetweenMatchesInMinutes ?? undefined;
            }
        }

        return this;
    }

    static getMaxValidNthPlaceMatchForFirstStageMatches(firstStageMatchesLength: number) {
        return firstStageMatchesLength - 1;
    }

    async generateSchedule(firstStageMatches: KnockoutPhaseMatchConfiguration[], consecutiveNthPlaceMatches: number) {
        if (!MathHelpers.isPowerOfTwo(firstStageMatches.length)) {
            throw new Error("First round matches count must be power of two for knockout phase.");
        }

        if (
            consecutiveNthPlaceMatches >
            KnockoutPhase.getMaxValidNthPlaceMatchForFirstStageMatches(firstStageMatches.length)
        ) {
            throw new Error("Cannot automatically add third place match if phase contains only final.");
        }

        const response = await api.generateKnockoutPhaseMatchSchedule({
            PhaseId: this.id,
            InitialTeamOrder: firstStageMatches.reduce((prev, acc) => [...prev, ...acc.teamIds], []),
            AdditionalNthPlaceTrees: consecutiveNthPlaceMatches,
        });

        return handleResponse(response, GenerateKnockoutPhaseMatchSchedule);
    }

    @computed get allNodesData() {
        return this.schedule?.map(m => m.nodeData) ?? [];
    }

    @computed get treeGroupedNodes() {
        return _.chain(this.allNodesData.map(n => ({ ...n, treeId: n.treeId ?? -1 }))).groupBy(n => n.treeId);
    }

    // the way searching works depends on backend returning an unaltered tree,
    // if deleting nth place matches gets implemented in the future it may require different handling
    private getLeftSourceNodeOfNode(nodeData: KnockoutMatchNodeData): AdvancingTeamPlaceholder | undefined {
        // main tree
        if (nodeData.treeId === undefined) {
            const tree = this.treeGroupedNodes.get(-1).value();
            // nodes at the edge of the tree
            if (tree.length < nodeData.nodeNumber * 2) return undefined;

            return {
                advancingFromNode: { treeId: nodeData.treeId, nodeNumber: nodeData.nodeNumber * 2 },
                advancingAs: "winner",
            };
        }

        const currentTree = this.treeGroupedNodes.get(nodeData.treeId).value();

        // source is in the same tree
        if ((currentTree.length + 1) / 2 > nodeData.nodeNumber) {
            return {
                advancingFromNode: { treeId: nodeData.treeId, nodeNumber: nodeData.nodeNumber * 2 },
                advancingAs: "winner",
            };
        }

        let parentTreeId = nodeData.treeId - 1;
        for (; parentTreeId >= -1; parentTreeId--) {
            if (this.treeGroupedNodes.get(parentTreeId).value().length > currentTree.length) {
                break;
            }
        }

        return {
            advancingFromNode: {
                treeId: parentTreeId === -1 ? undefined : parentTreeId,
                nodeNumber: nodeData.nodeNumber * 2,
            },
            advancingAs: "loser",
        };
    }

    getAdvancingTeamPlaceholder(
        match: KnockoutPhaseMatch,
        side: "team1" | "team2",
    ): AdvancingTeamPlaceholder | undefined {
        const sourceNode = this.getLeftSourceNodeOfNode(match.nodeData);

        if (!sourceNode) return undefined;

        if (side === "team2")
            return {
                ...sourceNode,
                advancingFromNode: {
                    treeId: sourceNode.advancingFromNode.treeId,
                    nodeNumber: sourceNode.advancingFromNode.nodeNumber + 1,
                },
            };
        return sourceNode;
    }

    @computed get isLegacyPhase() {
        return (this.treeGroupedNodes.get(0).value()?.length ?? 0) > 1;
    }
}

export type NthPlaceMatchData = {
    nodeNumber: number;
    team1Id: string;
    team2Id: string;
    date?: moment.Moment;
    sportsFieldId?: string;
};

export type PossibleNthPlaceMatch = {
    nodeNumber: number;
    isAlreadyAdded: boolean;
};

export default KnockoutPhase;
