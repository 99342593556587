import { Form, Input } from "antd";
import { l } from "Languages";
import React from "react";
import { isValidInteger } from "Utils/validationHelpers";

type MatchdayFormItem = {
    formItemKey: string;
    label: string;
};

const MatchdayFormItem: React.FunctionComponent<MatchdayFormItem> = ({ formItemKey, label }) => {
    return (
        <Form.Item
            name={formItemKey}
            label={label}
            rules={[
                {
                    required: true,
                    message: l("Common_Validation_FieldRequired"),
                },
                {
                    validator: (rule, value: string | undefined, callback) => {
                        if (value && (!isValidInteger(value) || (isValidInteger(value) && parseInt(value) < 1))) {
                            callback(rule.message as string);
                        } else {
                            callback();
                        }
                    },
                    message: l("Common_Validation_Number"),
                },
            ]}>
            <Input type="number" />
        </Form.Item>
    );
};

export default MatchdayFormItem;
