import { Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/util";
import Paragraph from "antd/lib/typography/Paragraph";
import Modal from "Components/Modal";
import Spacing from "Components/Spacing";
import TeamLogo from "Components/TeamLogo";
import TeamParticipatingInMatch from "Components/TeamParticipatingInMatch";
import GroupsPhaseMatch from "Domain/CompetitionPhases/GroupsPhase/GroupsPhaseMatch";
import TablePhaseMatch from "Domain/CompetitionPhases/TablePhase/TablePhaseMatch";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";
import { isValidInteger } from "Utils/validationHelpers";
import MatchdayFormItem from "../FormItems/MatchdayFormItem";

type MatchdayFormDialogProps = {
    match: TablePhaseMatch | GroupsPhaseMatch;

    onClose?: (savedFormFields?: FormFields) => void;
};

const MatchdayFormDialog: React.FunctionComponent<MatchdayFormDialogProps> = ({ match, onClose }) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const onFinish = useCallback(
        (values: FormFields) => {
            runInTask(async () => {
                const result = await match.updateMatchday(parseInt(values.matchday));

                result
                    .handle(["MatchNotFound", "MatchNotInTableOrGroupsPhase", "MatchdayNotPositive", "failure"], () => {
                        message.error(l("CompetitionPhases_UpdateMatchday_Failure"));
                    })
                    .handle("success", () => {
                        message.success(l("CompetitionPhases_UpdateMatchday_Success"));

                        onClose?.(values);
                    })
                    .check();
            });
        },
        [runInTask, match, onClose],
    );

    return useObserver(() => (
        <Modal
            title={l("CompetitionPhases_UpdateMatchday")}
            onCancel={() => onClose?.()}
            okText={l("Common_Save")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}
            okButtonProps={{
                loading: isRunning,
                disabled: isRunning,
            }}>
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                initialValues={{ [guard<FormField>("matchday")]: match.matchday }}>
                <Paragraph>
                    <Spacing childrenGutterX>
                        <span>
                            <TeamParticipatingInMatch match={match} side="team1" />{" "}
                            <TeamLogo photo={match.team1?.logo} />
                        </span>
                        <b>{l("Common_Versus")}</b>
                        <span>
                            <TeamLogo photo={match.team2?.logo} />{" "}
                            <TeamParticipatingInMatch match={match} side="team2" />
                        </span>
                    </Spacing>
                </Paragraph>
                <Form.Item
                    name={guard<FormField>("matchday")}
                    label={l("CompetitionPhases_UpdateMatchday_Matchday")}
                    rules={[
                        {
                            required: true,
                            message: l("Common_Validation_FieldRequired"),
                        },
                        {
                            validator: (rule, value: FormFields["matchday"], callback) => {
                                if (
                                    value &&
                                    (!isValidInteger(value) || (isValidInteger(value) && parseInt(value) < 1))
                                ) {
                                    callback(rule.message as string);
                                } else {
                                    callback();
                                }
                            },
                            message: l("Common_Validation_Number"),
                        },
                    ]}>
                    <Input type="number" />
                </Form.Item>
                <MatchdayFormItem
                    formItemKey={guard<FormField>("matchday")}
                    label={l("CompetitionPhases_UpdateMatchday_Matchday")}
                />
            </Form>
        </Modal>
    ));
};

export type FormFields = {
    matchday: string;
};

type FormField = keyof FormFields;

export default MatchdayFormDialog;
