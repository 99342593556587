import { handleResponse } from "@leancode/validation";
import { AddVideo, DeleteVideo } from "Contracts/PlayooLeagueClient";
import moment from "moment";
import api from "Services/Api";
import { dateTimeToDTO } from "Utils/DTO";
import { ArrayStore } from "Utils/mobx";
import newId from "Utils/newId";
import retryQuery from "Utils/retryQuery";
import Video from "./Video";

class VideosStore extends ArrayStore<Video> {
    async fetchVideos() {
        const videos = await retryQuery(() => api.allVideos({}));

        this.set(videos.map(Video.fromDTO));
    }

    async addVideo({ title, thumbnail, uri, dateRecorded }: AddVideoData) {
        const thumbnailUri = await Video.uploadThumbnail(thumbnail);

        const response = await api.addVideo({
            VideoId: newId(),
            Title: title,
            ThumbnailUri: thumbnailUri,
            Uri: uri,
            DateRecorded: dateTimeToDTO(dateRecorded),
        });

        if (response.isSuccess && response.result.WasSuccessful) {
            await this.fetchVideos();
        }

        return handleResponse(response, AddVideo);
    }

    async deleteVideo(videoId: string) {
        const video = this.getById(videoId);

        if (!video) {
            throw new Error("Video not found. Cannot delete.");
        }

        const response = await api.deleteVideo({
            VideoId: videoId,
        });

        const handler = handleResponse(response, DeleteVideo);

        handler.handle("success", () => {
            this.remove(video);
        });

        return handler;
    }
}

type AddVideoData = {
    title: string;
    thumbnail: File;
    uri: string;
    dateRecorded: moment.Moment;
};

export default VideosStore;
