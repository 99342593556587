import mkCx, { rawClassName } from "@leancode/cx";
import React from "react";
import { Droppable } from "react-beautiful-dnd";
import styles from "./styles.scss";

const cx = mkCx(styles);

type DroppableColumnProps = {
    columnId: string;
    label: React.ReactNode;

    isDropDisabled?: boolean;

    headerExtra?: React.ReactNode;
    extra?: React.ReactNode;

    className?: string;
    headerClassName?: string;
};

const DroppableColumn: React.FunctionComponent<DroppableColumnProps> = ({
    label,
    headerExtra,
    extra,
    columnId,
    children,
    isDropDisabled,
    className,
    headerClassName,
}) => {
    return (
        <div className={cx("column", rawClassName(className))}>
            <div className={cx("header", rawClassName(headerClassName))}>
                {label}
                {headerExtra}
            </div>
            <Droppable droppableId={columnId} isDropDisabled={isDropDisabled}>
                {(provided, snapshot) => (
                    <div
                        className={cx("droppable", snapshot.isDraggingOver && "-drag-over")}
                        ref={provided.innerRef}
                        {...provided.droppableProps}>
                        {children}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
            {extra}
        </div>
    );
};

export default DroppableColumn;
