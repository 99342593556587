import Competition from "Domain/Competitions/Competition";
import { computed } from "mobx";
import KnockoutPhase from "./KnockoutPhase";

class AvailableTeamsForKnockoutPhase {
    private readonly phase: KnockoutPhase;
    private readonly competition: Competition;

    constructor(phase: KnockoutPhase, competition: Competition) {
        this.phase = phase;
        this.competition = competition;
    }

    @computed get teams() {
        const teamsInPhaseIds = new Set(this.phase.teamsIds);

        const teamsInPhase = this.competition.teams?.filter(t => !teamsInPhaseIds.has(t.id));

        return teamsInPhase?.sort((t1, t2) => t1.displayName.localeCompare(t2.displayName)) ?? [];
    }

    @computed get nonPlaceholderTeams() {
        return this.teams.filter(t => !t.isPlaceholder);
    }
}

export default AvailableTeamsForKnockoutPhase;
