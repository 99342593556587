import config from "config";

class Regulations {
    static regulationsLink() {
        return `${config.apiHost}/manager/regulations`;
    }

    static privacyPolicyLink() {
        return `${config.apiHost}/manager/privacy-policy`;
    }
}

export default Regulations;
