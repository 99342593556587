import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { UploadProps } from "antd/lib/upload";
import { l } from "Languages";
import React, { useState } from "react";
import extractFile from "Utils/extractFile";
import { notUndefined } from "Utils/predicates";
import { acceptedImageExtensions, isValidImageFileExtension } from "Utils/validationHelpers";

type ImagesDraggerUploadProps = {
    text?: string;

    onUpload: (files: File[]) => void;

    multiple?: boolean;
} & UploadProps;

const ImagesDraggerUpload: React.FunctionComponent<ImagesDraggerUploadProps> = ({
    text,
    onUpload,
    multiple,
    ...uploadProps
}) => {
    const [uploadedFilesIds, setUploadedFilesIds] = useState<Set<string>>(new Set());

    return (
        <Upload.Dragger
            accept={acceptedImageExtensions.map(e => `.${e}`).join(",")}
            multiple={multiple}
            showUploadList={false}
            customRequest={({ file, onSuccess }) => {
                setImmediate(() => onSuccess({}, file));
            }} // HACK: prevent Upload control to automatically upload file to server
            onChange={info => {
                if (info.file.status === "done" && !isValidImageFileExtension(info.file.name)) {
                    message.error(l("ImageUpload_InvalidFormat"));
                }

                if (info.fileList.every(f => f.status === "done" || f.status === "error")) {
                    const files = info.fileList
                        .filter(
                            uf =>
                                uf.status === "done" &&
                                !uploadedFilesIds.has(uf.uid) &&
                                isValidImageFileExtension(info.file.name),
                        )
                        .map(uf => extractFile(uf))
                        .filter(notUndefined);

                    setUploadedFilesIds(new Set([...uploadedFilesIds, ...info.fileList.map(uf => uf.uid)]));

                    onUpload?.(files);
                }
            }}
            {...uploadProps}>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">{text}</p>
        </Upload.Dragger>
    );
};

export default ImagesDraggerUpload;
