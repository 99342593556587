import { Table, Typography } from "antd";
import { ColumnProps } from "antd/lib/table";
import DropdownMenu from "Components/DropdownMenu";
import Photo from "Components/Photo";
import tenantSettingsStore from "Domain/Tenant";
import { l } from "Languages";
import React, { useMemo } from "react";

type Tenant = {
    name: string;
    logoUri?: string;
    landingLogoUri?: string;
};

type TenantDetailsTableProps = {
    tenant: Tenant;

    onEditTenantName: () => void;
};

const TenantDetailsTable: React.FunctionComponent<TenantDetailsTableProps> = ({ tenant, onEditTenantName }) => {
    const columns = useMemo(
        (): ColumnProps<Tenant>[] => [
            {
                title: l("Tenant_Details_Name"),
                render: (_, tenant) => tenant.name,
            },
            {
                title: l("Tenant_Details_Logo"),
                render: () => (
                    <Photo
                        photo={tenant.logoUri}
                        editable
                        onChange={file => {
                            tenantSettingsStore.updateLogo(file);
                        }}
                    />
                ),
                align: "center",
            },
            {
                title: l("Tenant_Details_LandingLogo"),
                render: () => (
                    <Photo
                        photo={tenant.landingLogoUri}
                        editable
                        cropDisabled
                        onChange={file => {
                            tenantSettingsStore.updateLandingLogo(file);
                        }}
                    />
                ),
                align: "center",
            },
            {
                render: () => {
                    return (
                        <DropdownMenu
                            menuItems={[
                                {
                                    children: l("Tenant_UpdateName"),
                                    onClick: onEditTenantName,
                                },
                            ]}
                        />
                    );
                },
            },
        ],
        [onEditTenantName, tenant],
    );

    return (
        <div>
            <Typography.Title level={4}>{l("Tenant_Details")}</Typography.Title>
            <Table columns={columns} dataSource={[tenant]} pagination={false} />
        </div>
    );
};

export default TenantDetailsTable;
