import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    MergeCellsOutlined,
    PlusOutlined,
    SyncOutlined,
} from "@ant-design/icons";
import { handleResponse } from "@leancode/validation";
import { message, Modal, Table } from "antd";
import { ColumnProps } from "antd/lib/table/Column";
import DropdownMenu from "Components/DropdownMenu";
import NavigationLink from "Components/NavigationLink";
import PageContent from "Components/PageContent";
import TeamLogo from "Components/TeamLogo";
import { DeleteTeam, ReplaceTeamWithCopy, ReplaceTeamWithNew, UpdateTeam } from "Contracts/PlayooLeagueClient";
import competitionStore from "Domain/Competitions";
import Competition from "Domain/Competitions/Competition";
import CompetitionTeam, { EditTeamData } from "Domain/Competitions/CompetitionTeam";
import { CompetitionTeamsSelectionResult } from "Domain/Competitions/CompetitionTeamsCreator";
import CompetitionTeamFormDialog, {
    CompetitionTeamFormValidationErrors,
} from "DomainComponents/CompetitionTeamFormDialog";
import CompetitionTeamsCreatorSelect from "DomainComponents/CompetitionTeamsCreatorSelect";
import { l } from "Languages";
import { Observer, useObserver } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import routes from "Router/routes";
import { concat, from } from "rxjs";
import { concatMap, map, mapTo, mergeMap, reduce } from "rxjs/operators";
import useRunInTask from "Utils/Hooks/useRunInTask";
import MergeTeamsFormDialog from "./MergeTeamsFormDialog";

type CompetitionTeamsProps = {
    competition: Competition;
};

const CompetitionTeams: React.FunctionComponent<CompetitionTeamsProps> = ({ competition }) => {
    const [isCompetitionsCreatorOpen, setIsCompetitionsCreatorOpen] = useState(false);
    const [editedTeam, setEditedTeam] = useState<CompetitionTeam | undefined>(undefined);
    const [saveInProgress, setSaveInProgress] = useState(false);

    const [replacedTeamId, setReplacedTeamId] = useState<string | undefined>();
    const [teamReplacementInProgress, runTeamReplacementInTask] = useRunInTask();

    const [teamForMerge, setTeamForMerge] = useState<CompetitionTeam | undefined>();

    const replaceTeam = useCallback(
        async (replacedTeam: CompetitionTeam, replacementTeam: CompetitionTeam, isExisting: boolean) => {
            const replacementTeamName = replacementTeam.displayName;
            const replacedTeamName = replacedTeam.displayName;

            if (isExisting) {
                const response = await competition.replaceTeamWithCopy(replacedTeam.id, replacementTeam.id);

                if (response.isSuccess && response.result.WasSuccessful) {
                    await competition.fetchDetails();
                }

                handleResponse(response, ReplaceTeamWithCopy)
                    .handle("ReplacementTeamAlreadyCompetes", () =>
                        message.error(
                            l("CompetitionDetails_Teams_Replace_Failure_TeamAlreadyCompetes", replacementTeamName),
                        ),
                    )
                    .handle(
                        ["ReplacedTeamNotFound", "ReplacementTeamIsAPlaceholder", "ReplacementTeamNotFound", "failure"],
                        () =>
                            message.error(
                                l("CompetitionDetails_Teams_Replace_Failure", replacementTeamName, replacementTeamName),
                            ),
                    )
                    .handle("success", () => {
                        message.success(
                            l("CompetitionDetails_Teams_Replace_Success", replacedTeamName, replacementTeamName),
                        );

                        setReplacedTeamId(undefined);
                    })
                    .check();
            } else {
                const response = await competition.replaceTeamWithNew(replacedTeam.id, replacementTeam);

                if (response.isSuccess && response.result.WasSuccessful) {
                    await competition.fetchDetails();
                }

                handleResponse(response, ReplaceTeamWithNew)
                    .handle(
                        [
                            "NameNullOrEmpty",
                            "NameTooLong",
                            "ShortNameTooLong",
                            "AgeGroupTooLong",
                            "TeamNotFound",
                            "failure",
                        ],
                        () =>
                            message.error(
                                l("CompetitionDetails_Teams_Replace_Failure", replacedTeamName, replacementTeamName),
                            ),
                    )
                    .handle("success", () => {
                        message.success(
                            l("CompetitionDetails_Teams_Replace_Success", replacedTeamName, replacementTeamName),
                        );

                        setReplacedTeamId(undefined);
                    })
                    .check();
            }
        },
        [setReplacedTeamId, competition],
    );

    const saveTeamReplacementSelection = useCallback(
        (replacedTeamId: string) => ({ newTeams, existingTeams }: CompetitionTeamsSelectionResult) => {
            const replacedTeam = competition.teams?.find(t => t.id === replacedTeamId);
            const replacementTeam = newTeams[0] ?? existingTeams[0];

            if (!replacedTeam || !replacementTeam) {
                return;
            }

            if (replacedTeam.isPlaceholderForWholeCompetition) {
                return runTeamReplacementInTask(() =>
                    replaceTeam(replacedTeam, replacementTeam, existingTeams.length === 1),
                );
            }

            Modal.confirm({
                onOk: () => replaceTeam(replacedTeam, replacementTeam, existingTeams.length === 1),
                icon: <ExclamationCircleOutlined />,
                title: l("CompetitionDetails_Teams_Replace_Confirm_Title"),
                content: l(
                    "CompetitionDetails_Teams_Replace_Confirm_Content",
                    replacedTeam.displayName,
                    replacementTeam.displayName,
                ),
                okText: l("CompetitionDetails_Teams_Replace_Confirm_OkText"),
                okButtonProps: {
                    danger: true,
                },
                cancelText: l("Common_Cancel"),
                centered: true,
                maskClosable: true,
            });
        },
        [competition.teams, replaceTeam, runTeamReplacementInTask],
    );

    const deleteTeam = useCallback(
        async (teamId: string) => {
            const response = await competition.deleteTeam(teamId);

            if (response.isSuccess && response.result.WasSuccessful) {
                await competition.fetchDetails();
            }

            handleResponse(response, DeleteTeam)
                .handle(["TeamNotFound", "failure"], () => message.error(l("CompetitionDetails_Teams_Delete_Failure")))
                .handle("success", () => message.success(l("CompetitionDetails_Teams_Delete_Success")))
                .check();
        },
        [competition],
    );

    const showDeleteTeamConfirmation = useCallback(
        (teamId: string) => {
            Modal.confirm({
                onOk: () => deleteTeam(teamId),
                icon: <ExclamationCircleOutlined />,
                title: l("CompetitionDetails_Teams_Delete_Confirm_Title"),
                content: l("CompetitionDetails_Teams_Delete_Confirm_Content"),
                okText: l("CompetitionDetails_Teams_Delete_Confirm_OkText"),
                okButtonProps: {
                    danger: true,
                },
                cancelText: l("Common_Cancel"),
                centered: true,
                maskClosable: true,
            });
        },
        [deleteTeam],
    );

    const columns = useMemo(
        (): ColumnProps<CompetitionTeam>[] => [
            {
                title: l("Common_Ordinal"),
                render: (_, __, index) => index + 1,
            },
            {
                title: l("CompetitionDetails_Teams_Logo"),
                render: (_, team) => ({
                    children: (
                        <Observer>
                            {() => (
                                <TeamLogo
                                    photo={team.logo}
                                    onChange={file => team.updateLogo(file)}
                                    editable={!team.isPlaceholderForWholeCompetition}
                                />
                            )}
                        </Observer>
                    ),
                    props: {
                        colSpan: replacedTeamId === team.id ? 0 : 1,
                    },
                }),
            },
            {
                title: l("CompetitionDetails_Teams_Name"),
                render: (_, team) => ({
                    children:
                        replacedTeamId === team.id ? (
                            <CompetitionTeamsCreatorSelect
                                competitionId={competition.id}
                                saveText={l("CompetitionDetails_Teams_Replace_Confirm_OkText")}
                                onCancel={() => setReplacedTeamId(undefined)}
                                onSave={saveTeamReplacementSelection(team.id)}
                                mode={undefined}
                                showSearch
                                saveInProgress={teamReplacementInProgress}
                            />
                        ) : team.isPlaceholderForWholeCompetition ? (
                            team.displayName
                        ) : (
                            <Observer>
                                {() => (
                                    <NavigationLink to={routes.competitionTeam({ teamId: team.id })}>
                                        {team.displayName}
                                    </NavigationLink>
                                )}
                            </Observer>
                        ),
                    props: {
                        colSpan: replacedTeamId === team.id ? 3 : 1,
                    },
                }),
            },
            {
                title: l("Common_Actions"),
                render: (_, team) => ({
                    children: (
                        <DropdownMenu
                            menuItems={[
                                ...(!team.isPlaceholderForWholeCompetition
                                    ? [
                                          {
                                              children: (
                                                  <>
                                                      <EditOutlined /> {l("CompetitionDetails_Teams_Edit")}
                                                  </>
                                              ),
                                              onClick: () => setEditedTeam(team),
                                          },
                                      ]
                                    : []),
                                {
                                    children: (
                                        <>
                                            <SyncOutlined /> {l("CompetitionDetails_Teams_Replace")}
                                        </>
                                    ),
                                    onClick: () => setReplacedTeamId(team.id),
                                },
                                {
                                    children: (
                                        <>
                                            <DeleteOutlined /> {l("CompetitionDetails_Teams_Delete")}
                                        </>
                                    ),
                                    onClick: () => showDeleteTeamConfirmation(team.id),
                                },
                                {
                                    children: (
                                        <>
                                            <MergeCellsOutlined /> {l("CompetitionDetails_Teams_Merge")}
                                        </>
                                    ),
                                    onClick: () => setTeamForMerge(team),
                                },
                            ]}
                        />
                    ),
                    props: {
                        colSpan: replacedTeamId === team.id ? 0 : 1,
                    },
                }),
            },
        ],
        [
            replacedTeamId,
            competition.id,
            saveTeamReplacementSelection,
            showDeleteTeamConfirmation,
            teamReplacementInProgress,
        ],
    );

    const editTeam = useCallback(
        async (values: EditTeamData): Promise<CompetitionTeamFormValidationErrors | undefined> => {
            if (!editedTeam) {
                return;
            }

            const response = await editedTeam.edit(values);

            if (response.isSuccess && response.result.WasSuccessful) {
                await competition.fetchDetails();
                setEditedTeam(undefined);
            }

            const handler = handleResponse(response, UpdateTeam);

            return handler
                .handle(["NameNullOrEmpty", "NameTooLong"], error => ({
                    name:
                        error === "NameNullOrEmpty"
                            ? l("Common_Validation_FieldRequired")
                            : l("Common_Validation_FieldTooLong"),
                }))
                .handle(["ShortNameTooLong"], () => ({
                    shortName: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["AgeGroupTooLong"], () => ({
                    ageGroup: l("Common_Validation_FieldTooLong"),
                }))
                .handle(["TeamIsAPlaceholder", "TeamNotFound", "failure"], () => {
                    message.error(l("CompetitionDetails_Teams_Edit_Failure"));
                })
                .handle("success", () => {
                    message.success(l("CompetitionDetails_Teams_Edit_Success"));
                })
                .check({
                    reducer: (prev, cur) => {
                        return {
                            ...prev,
                            ...(cur || {}),
                        };
                    },
                    initialValue: {} as CompetitionTeamFormValidationErrors,
                });
        },
        [editedTeam, competition],
    );

    const saveSelection = useCallback(
        async ({ existingTeams, newTeams, placeholderTeamsIds }: CompetitionTeamsSelectionResult) => {
            setSaveInProgress(true);

            const result = await concat(
                from(
                    existingTeams.length > 0 ? competition.addTeamsFromExisting(existingTeams.map(t => t.id)) : [],
                ).pipe(map(r => r.isSuccess && r.result.WasSuccessful)),
                from(newTeams).pipe(
                    concatMap(t => competition.addNewTeam(t)),
                    map(r => r.isSuccess && r.result.WasSuccessful),
                ),
                from(placeholderTeamsIds).pipe(
                    concatMap(id => competition.addPlaceholderTeam(id)),
                    map(r => r.isSuccess && r.result.WasSuccessful),
                ),
            )
                .pipe(
                    reduce<boolean, [number, number]>(
                        ([successCount, allCount], isSuccess) => [successCount + (isSuccess ? 1 : 0), allCount + 1],
                        [0, 0],
                    ),
                    mergeMap(response =>
                        from(competitionStore.fetchCompetitionDetails(competition.id)).pipe(mapTo(response)),
                    ),
                )
                .toPromise();

            const [successCount, allCount] = result;

            if (allCount === 0) {
                return;
            }
            if (successCount === allCount) {
                message.success(l("CompetitionDetails_Teams_Success"));
            } else if (successCount === 0) {
                message.error(l("CompetitionDetails_Teams_Failure"));
            } else {
                message.error(l("CompetitionDetails_Teams_PartialFailure"));
            }

            setIsCompetitionsCreatorOpen(false);
            setSaveInProgress(false);
        },
        [competition],
    );

    return useObserver(() => (
        <>
            <Table rowKey={t => t.id} dataSource={competition.teams} columns={columns} pagination={false} />
            {isCompetitionsCreatorOpen ? (
                <CompetitionTeamsCreatorSelect
                    withPlaceholderTeams
                    saveText={l("Common_Add")}
                    saveInProgress={saveInProgress}
                    competitionId={competition?.id}
                    onSave={saveSelection}
                    onCancel={() => setIsCompetitionsCreatorOpen(false)}
                />
            ) : (
                <PageContent.ActionButton onClick={() => setIsCompetitionsCreatorOpen(true)}>
                    <PlusOutlined /> {l("CompetitionDetails_Teams_Add")}
                </PageContent.ActionButton>
            )}
            {editedTeam && (
                <CompetitionTeamFormDialog
                    team={editedTeam}
                    onSave={editTeam}
                    onClose={() => setEditedTeam(undefined)}
                />
            )}
            {teamForMerge && <MergeTeamsFormDialog team={teamForMerge} onClose={() => setTeamForMerge(undefined)} />}
        </>
    ));
};

export default CompetitionTeams;
