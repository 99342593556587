import { handleResponse } from "@leancode/validation";
import {
    AddMatchArticle,
    EditMatchArticle,
    MatchEventSideDTO,
    MatchEventSubtypeDTO,
    MatchEventTypeDTO,
    MatchStatusDTO,
    MatchWinnerDTO,
    PhaseTypeDTO,
    PresentPlayerDTO,
    PublishMatchReport,
    RecreateMatch,
    SetMatchDate,
    UpdateMatchReport,
} from "Contracts/PlayooLeagueClient";
import Article from "Domain/Articles/Article";
import { CompetitionTeamStore } from "Domain/Competitions/CompetitionTeamStore";
import Photo from "Domain/Photos/Photo";
import _ from "lodash";
import { computed, observable, runInAction } from "mobx";
import moment from "moment";
import api from "Services/Api";
import { dateTimeOffsetToDTOOptional, dateTimeToDTOOptional } from "Utils/DTO";
import newId from "Utils/newId";
import retryQuery from "Utils/retryQuery";
import MatchEvent from "./MatchEvent";
import MatchPlayer from "./MatchPlayer";
import MatchResult, { Score } from "./MatchResult";

abstract class MatchBase {
    abstract readonly phaseType: PhaseTypeDTO;
    readonly competitionTeamStore: CompetitionTeamStore;

    readonly id: string;
    readonly phaseId: string;
    @observable team1Id?: string;
    @observable team2Id?: string;
    @observable date?: moment.Moment;

    @observable status: MatchStatusDTO;
    @observable result?: MatchResult;

    @observable article?: Article;
    @observable events?: MatchEvent[];
    @observable team1Lineup?: MatchPlayer[];
    @observable team2Lineup?: MatchPlayer[];

    @observable matchMvpId?: string;
    @observable team1MvpId?: string;
    @observable team2MvpId?: string;

    @observable sportsFieldId?: string;
    @observable name?: string;

    @observable photos?: Photo[];

    abstract get isDeletable(): boolean;

    constructor(
        id: string,
        phaseId: string,
        {
            team1Id,
            team2Id,
            date,
            article,
            events,
            team1Lineup,
            team2Lineup,
            team1MvpId,
            team2MvpId,
            matchMvpId,
            status,
            result,
            sportsFieldId,
            name,
        }: MatchInit,
        competitionTeamStore: CompetitionTeamStore,
    ) {
        this.id = id;
        this.phaseId = phaseId;
        this.team1Id = team1Id;
        this.team2Id = team2Id;
        this.date = date;

        this.status = status;
        this.result = result;

        this.article = article;
        this.events = events;
        this.team1Lineup = team1Lineup;
        this.team2Lineup = team2Lineup;

        this.matchMvpId = matchMvpId;
        this.team1MvpId = team1MvpId;
        this.team2MvpId = team2MvpId;

        this.sportsFieldId = sportsFieldId;

        this.competitionTeamStore = competitionTeamStore;
        this.name = name;
    }

    @computed get isMatchReportPublished() {
        return this.events && this.result && this.status === MatchStatusDTO.Finished;
    }

    @computed get team1() {
        return this.team1Id ? this.competitionTeamStore.getById(this.team1Id) : undefined;
    }

    @computed get team2() {
        return this.team2Id ? this.competitionTeamStore.getById(this.team2Id) : undefined;
    }

    @computed get isTeam1Deleted() {
        return this.team1Id === undefined;
    }

    @computed get isTeam2Deleted() {
        return this.team2Id === undefined;
    }

    @computed get allPlayers(): Map<string, MatchPlayer> {
        return new Map<string, MatchPlayer>(
            [...(this.team1Lineup ?? []), ...(this.team2Lineup ?? [])].map(p => [p.id, p]),
        );
    }

    @computed get canAddReport() {
        return (
            (this.status === MatchStatusDTO.Upcoming || this.status === MatchStatusDTO.Ongoing) &&
            this.team1Id !== undefined &&
            this.team2Id !== undefined
        );
    }

    @computed get canEditReport() {
        return this.status === MatchStatusDTO.Finished && this.team1Id !== undefined && this.team2Id !== undefined;
    }

    @computed get canRecreateMatch() {
        return this.status === MatchStatusDTO.Finished && this.team1Id !== undefined && this.team2Id !== undefined;
    }

    async fetchArticleDetails() {
        if (this.article?.id) {
            const articleId = this.article.id;
            const article = await retryQuery(() => api.articleDetails({ ArticleId: articleId }));

            runInAction(() => {
                this.article = Article.fromDTO(article);
            });
        }
    }

    async fetchPhotos() {
        const photos = await retryQuery(() =>
            api.matchPhotos({
                MatchId: this.id,
            }),
        );

        runInAction(() => {
            this.photos = photos.map(Photo.fromDTO);
        });
    }

    async uploadPhotos(files: File[]) {
        if (files.length === 0) {
            return;
        }

        await Photo.uploadPhotos(files, async ({ photoId, uri }) => {
            const response = await api.addPhotoForMatch({
                MatchId: this.id,
                PhotoId: photoId,
                Uri: uri,
            });

            return response.isSuccess && response.result.WasSuccessful;
        });

        await this.fetchPhotos();
    }

    async deletePhoto(photoId: string) {
        const photo = this.photos?.find(p => p.id === photoId);

        if (!photo) {
            throw new Error("Photo not found. Cannot delete.");
        }

        const handler = await photo.delete();

        handler.handle("success", () => {
            runInAction(() => {
                this.photos = this.photos?.filter(p => p.id !== photo.id);
            });
        });

        return handler;
    }

    async setDate(date?: moment.Moment) {
        const response = await api.setMatchDate({
            MatchId: this.id,
            Date: dateTimeOffsetToDTOOptional(date),
        });

        handleResponse(response, SetMatchDate).handle("success", () => {
            runInAction(() => {
                this.date = date;
            });
        });

        return handleResponse(response, SetMatchDate);
    }

    async addArticle(content: string) {
        const articleId = newId();
        const title = `${this.team1?.displayName} vs ${this.team2?.displayName}`;
        const summary = title;

        const response = await api.addMatchArticle({
            Id: articleId,
            MatchId: this.id,
            Content: Article.prepareContent(content),
            Title: title,
            Summary: summary,
        });

        return handleResponse(response, AddMatchArticle);
    }

    async editArticle(content: string) {
        if (!this.article) {
            throw new Error("There is no match related article");
        }

        const title = `${this.team1?.displayName} vs ${this.team2?.displayName}`;
        const summary = title;

        const response = await api.editMatchArticle({
            ArticleId: this.article.id,
            MatchId: this.id,
            Content: Article.prepareContent(content),
            Title: title,
            Summary: summary,
            DatePublished: dateTimeToDTOOptional(this.article.datePublished),
        });

        return handleResponse(response, EditMatchArticle);
    }

    async recreate() {
        if (!this.team1 || !this.team2) {
            throw new Error("Could not recreate match with deleted teams.");
        }

        const response = await api.recreateMatch({
            MatchId: this.id,
        });
        return handleResponse(response, RecreateMatch);
    }

    async publishMatchReport({
        result,
        team1PresentPlayers,
        team2PresentPlayers,
        matchMvp,
        team1Mvp,
        team2Mvp,
    }: MatchReportModifyData) {
        if (!this.canAddReport) {
            throw new Error("Could not publish match report. Match is in invalid state.");
        }

        const events = [
            ..._.fill(
                Array(result.fullTimeScore.team1),
                new MatchEvent({
                    side: MatchEventSideDTO.Team1,
                    type: MatchEventTypeDTO.Goal,
                    subtype: MatchEventSubtypeDTO.Unspecified,
                }),
            ),
            ..._.fill(
                Array(result.fullTimeScore.team2),
                new MatchEvent({
                    side: MatchEventSideDTO.Team2,
                    type: MatchEventTypeDTO.Goal,
                    subtype: MatchEventSubtypeDTO.Unspecified,
                }),
            ),
        ];

        const response = await api.publishMatchReport({
            MatchId: this.id,
            Result: result.toDTO(),
            Events: events.map(e => e.toDTO()),
            Team1PresentPlayers: team1PresentPlayers.map(this.presentPlayerToDTO),
            Team2PresentPlayers: team2PresentPlayers.map(this.presentPlayerToDTO),
            MatchMvpId: matchMvp,
            Team1MvpId: team1Mvp,
            Team2MvpId: team2Mvp,
        });

        return handleResponse(response, PublishMatchReport);
    }

    async editMatchReport({
        result,
        team1PresentPlayers,
        team2PresentPlayers,
        matchMvp,
        team1Mvp,
        team2Mvp,
    }: MatchReportModifyData) {
        if (!this.canEditReport || !this.events) {
            throw new Error("Could not publish match report. Match is in invalid state.");
        }

        const response = await api.updateMatchReport({
            MatchId: this.id,
            Events: this.events.map(e => e.toDTO()),
            Result: result.toDTO(),
            MatchMvpId: matchMvp,
            Team1MvpId: team1Mvp,
            Team2MvpId: team2Mvp,
            Team1PresentPlayers: team1PresentPlayers.map(this.presentPlayerToDTO),
            Team2PresentPlayers: team2PresentPlayers.map(this.presentPlayerToDTO),
        });

        return handleResponse(response, UpdateMatchReport);
    }

    private async updateMatchEvents(matchEvents: MatchEvent[]) {
        if (this.status !== MatchStatusDTO.Finished || !this.result) {
            throw new Error("Invalid match state");
        }

        const result = this.calculateResultFromEvents(matchEvents);

        const response = await api.updateMatchReport({
            MatchId: this.id,
            Events: matchEvents.map(e => e.toDTO()),
            Result: result.toDTO(),
            MatchMvpId: this.matchMvpId,
            Team1MvpId: this.team1MvpId,
            Team2MvpId: this.team2MvpId,
        });

        handleResponse(response, UpdateMatchReport).handle("success", () => {
            runInAction(() => {
                this.events = matchEvents;
                this.result = result;
            });
        });

        return handleResponse(response, UpdateMatchReport);
    }

    private calculateResultFromEvents(events: MatchEvent[]): MatchResult {
        if (this.status !== MatchStatusDTO.Finished || !this.result) {
            throw new Error("Invalid match state");
        }

        const fullTimeScore: Score = {
            team1: events.filter(e => e.type === MatchEventTypeDTO.Goal && e.side === MatchEventSideDTO.Team1).length,
            team2: events.filter(e => e.type === MatchEventTypeDTO.Goal && e.side === MatchEventSideDTO.Team2).length,
        };

        return new MatchResult({
            fullTimeScore: fullTimeScore,
            winner: this.calculateNewWinner(fullTimeScore, this.result),
            afterExtraTimeScore: this.result.afterExtraTimeScore,
            halfTimeScore: this.result.halfTimeScore,
            penaltyScore: fullTimeScore.team1 === fullTimeScore.team2 ? this.result.penaltyScore : undefined,
        });
    }

    private calculateNewWinner(newFullTimeScore: Score, oldResult: MatchResult) {
        if (
            newFullTimeScore.team1 === newFullTimeScore.team2 &&
            oldResult.fullTimeScore.team1 === oldResult.fullTimeScore.team2
        ) {
            return oldResult.winner;
        } else if (newFullTimeScore.team1 > newFullTimeScore.team2) {
            return MatchWinnerDTO.Team1;
        } else if (newFullTimeScore.team1 === newFullTimeScore.team2) {
            return MatchWinnerDTO.Draw;
        } else {
            return MatchWinnerDTO.Team2;
        }
    }

    async editEvent(eventIndex: number, newEvent: MatchEvent) {
        const event = this.events?.[eventIndex];

        if (!event) {
            throw new Error("Event not found.");
        }

        let events = [...(this.events ?? [])];

        events.splice(eventIndex, 1, newEvent);
        events = this.sortEvents(events);

        return this.updateMatchEvents(events);
    }

    async addEvent(event: MatchEvent) {
        let events = [...(this.events ?? [])];

        events.push(event);
        events = this.sortEvents(events);

        return this.updateMatchEvents(events);
    }

    async deleteEvent(eventIndex: number) {
        const events = [...(this.events ?? [])];

        events.splice(eventIndex, 1);

        return this.updateMatchEvents(events);
    }

    private sortEvents(events: MatchEvent[]): MatchEvent[] {
        return _.sortBy(events, e => e.secondOfMatch ?? -1);
    }

    private presentPlayerToDTO(player: PresentPlayer): PresentPlayerDTO {
        return {
            Id: player.id,
            IsGoalkeeper: player.isGoalkeeper,
        };
    }
}

export type MatchInit = Pick<
    MatchBase,
    | "team1Id"
    | "team2Id"
    | "date"
    | "article"
    | "events"
    | "team1Lineup"
    | "team2Lineup"
    | "team1MvpId"
    | "team2MvpId"
    | "matchMvpId"
    | "status"
    | "result"
    | "sportsFieldId"
    | "name"
>;

export type MatchReportModifyData = {
    result: MatchResult;
    team1PresentPlayers: PresentPlayer[];
    team2PresentPlayers: PresentPlayer[];
    matchMvp?: string;
    team1Mvp?: string;
    team2Mvp?: string;
};

export type PresentPlayer = {
    id: string;
    isGoalkeeper: boolean;
};

export default MatchBase;
