import { handleResponse } from "@leancode/validation";
import { message } from "antd";
import { ReorderCompetitions } from "Contracts/PlayooLeagueClient";
import CompetitionGroup from "Domain/CompetitionGroups/CompetitionGroup";
import { l } from "Languages";
import { action, computed, observable } from "mobx";
import api from "Services/Api";
import { notUndefined } from "Utils/predicates";
import ReorderStore from "Utils/ReorderStore";
import retryQuery from "Utils/retryQuery";
import Competition from "./Competition";
import CompetitionStore from "./CompetitionStore";

export type GroupWithCompetitions = { group?: CompetitionGroup; competitions: Competition[] };

class OrderedCompetitionStore extends ReorderStore<Competition> {
    private static readonly noSeasonKey = "no-season";
    private readonly competitionStore: CompetitionStore;

    @observable private orderedCompetitionsBySeason: Map<string, string[]> = new Map();
    @observable seasonId?: string;

    constructor(competitionStore: CompetitionStore) {
        super();

        this.competitionStore = competitionStore;
    }

    @computed get areAllCompetitionsWithoutGroup() {
        return this.competitionsInSeasonByGroup.length === 1 && this.competitionsInSeasonByGroup[0].group === undefined;
    }

    @computed get competitionsInSeasonByGroup(): GroupWithCompetitions[] {
        return [...this.competitionStore.competitionGroupStore.items, undefined]
            .map(g => {
                return {
                    group: g,
                    competitions: this.orderedCompetitionsInSeason?.filter(c => c.groupId === g?.id) ?? [],
                };
            })
            .filter(g => g.competitions.length > 0);
    }

    @computed get orderedCompetitionsInSeason() {
        const orderedCompetitionsIds = this.orderedCompetitionsBySeason.get(
            this.seasonId ?? OrderedCompetitionStore.noSeasonKey,
        );

        return orderedCompetitionsIds?.map(id => this.competitionStore.getById(id)).filter(notUndefined);
    }

    @action.bound
    private updateCompetitionsOrderInSeason(orderedCompetitionsIds: string[], seasonId?: string) {
        this.orderedCompetitionsBySeason.set(seasonId ?? OrderedCompetitionStore.noSeasonKey, orderedCompetitionsIds);
    }

    @action.bound
    updateSeasonId(seasonId?: string) {
        this.seasonId = seasonId;

        this.stopReorder();
        this.fetchCompetitionsInSeason(seasonId);
    }

    @action.bound
    startCompetitionsReorder() {
        if (this.orderedCompetitionsInSeason === undefined || this.orderedCompetitionsInSeason.length <= 1) {
            throw new Error("There is no competitions to be reordered.");
        }

        this.startReorder(this.orderedCompetitionsInSeason);
    }

    async saveNewCompetitionsOrder() {
        if (!this.draft) {
            throw new Error("Store is not in reorder mode.");
        }

        const saveNewOrderMessageKey = "save-in-progress";
        message.loading({ content: l("Competitions_Reorder_Loading"), duration: 0, key: saveNewOrderMessageKey });

        const response = await api.reorderCompetitions({
            SeasonId: this.seasonId,
            OrderedCompetitionIds: this.draft.map(c => c.id),
        });

        handleResponse(response, ReorderCompetitions)
            .handle("success", () => {
                this.draft &&
                    this.updateCompetitionsOrderInSeason(
                        this.draft.map(c => c.id),
                        this.seasonId,
                    );

                this.stopReorder();

                message.success({
                    content: l("Competitions_Reorder_Success"),
                    key: saveNewOrderMessageKey,
                });
            })
            .handle(
                [
                    "CompetitionNotFound",
                    "DuplicateCompetitionId",
                    "OrderedCompetitionIdsMissingOrEmpty",
                    "SeasonNotFound",
                    "failure",
                ],
                () => {
                    message.error({
                        content: l("Competitions_Reorder_Failure"),
                        key: saveNewOrderMessageKey,
                    });
                },
            )
            .check();

        return handleResponse(response, ReorderCompetitions);
    }

    async fetchCompetitionsInSeason(seasonId?: string) {
        const competitions = await retryQuery(() => api.competitionsInSeason({ SeasonId: seasonId }));

        this.updateCompetitionsOrderInSeason(
            competitions.map(c => c.Id),
            seasonId,
        );

        this.competitionStore.putFromDTO(seasonId, ...competitions);
    }
}

export default OrderedCompetitionStore;
