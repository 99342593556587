import jwt_decode from "jwt-decode";
import { computed, observable, runInAction } from "mobx";
import moment from "moment";
import cmsApi from "Services/CmsApi";
import retryQuery from "Utils/retryQuery";

class TinyDriveTokenStore {
    @observable private token?: string;
    public static readonly thumbnailUploadPath = "/uploads/thumbnails";

    async getToken() {
        if (this.shouldRefresh) {
            const token = await retryQuery(() => cmsApi.tinyDriveToken({}));

            runInAction(() => {
                this.token = token;
            });
        }

        return this.token;
    }

    @computed private get shouldRefresh() {
        if (!this.token) {
            return true;
        }

        const decodedToken = jwt_decode<TinyDriveToken>(this.token);

        const expirationDate = moment.unix(decodedToken.exp);
        const now = moment();

        return expirationDate.isSameOrBefore(now);
    }
}

type TinyDriveToken = {
    exp: number;
};

export default TinyDriveTokenStore;
