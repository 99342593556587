import { action, computed, IObservableArray, observable } from "mobx";

class ReorderStore<T extends { id: string }> {
    @observable private rawDraft: IObservableArray<T> | undefined;

    @computed get draft() {
        return this.rawDraft ? [...this.rawDraft.values()] : undefined;
    }

    @computed get isReordering() {
        return this.draft !== undefined;
    }

    @action.bound
    startReorder(itemsToReorder: T[]) {
        this.rawDraft = observable.array<T>(itemsToReorder);
    }

    @action.bound
    moveItemUp(itemId: string) {
        const itemIndex = this.rawDraft?.findIndex(i => i.id === itemId);

        if (itemIndex === undefined || itemIndex === -1) {
            throw new Error("Invalid item id.");
        }

        if (itemIndex === 0) {
            throw new Error("Item is already on top of list.");
        }

        this.swapDraftArrayElements(itemIndex - 1, itemIndex);
    }

    @action.bound
    moveItemDown(itemId: string) {
        const itemIndex = this.rawDraft?.findIndex(i => i.id === itemId);

        if (!this.rawDraft || itemIndex === undefined || itemIndex === -1) {
            throw new Error("Invalid item id.");
        }

        if (itemIndex === this.rawDraft.length - 1) {
            throw new Error("Item is already on the bottom of list.");
        }

        this.swapDraftArrayElements(itemIndex, itemIndex + 1);
    }

    @action.bound
    private swapDraftArrayElements(index1: number, index2: number) {
        if (!this.rawDraft) {
            throw new Error("Store is not in reorder mode.");
        }

        [this.rawDraft[index1], this.rawDraft[index2]] = [this.rawDraft[index2], this.rawDraft[index1]];
    }

    @action.bound stopReorder() {
        this.rawDraft = undefined;
    }
}

export default ReorderStore;
