import { EditOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import React from "react";
import styles from "./styles.scss";

const cx = mkCx(styles);

type EditableLabelProps = {
    onClick: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
};

const EditableLabel: React.FunctionComponent<EditableLabelProps> = ({ children, onClick }) => {
    return (
        <span className={cx("container")}>
            {children} <EditOutlined className={cx("icon")} onClick={onClick} />
        </span>
    );
};

export default EditableLabel;
