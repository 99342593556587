import {
    CheckOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    MergeCellsOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import { Avatar, Button, message, Modal, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import DropdownMenu from "Components/DropdownMenu";
import EmptyState from "Components/EmptyState";
import NavigationLink from "Components/NavigationLink";
import PageContent from "Components/PageContent";
import { AccountStateDTO } from "Contracts/PlayooLeagueClient";
import competitionStore from "Domain/Competitions";
import Competition from "Domain/Competitions/Competition";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import CompetitionTeamPlayer from "Domain/Competitions/CompetitionTeamPlayer";
import { TeamPlayersSelectionResult } from "Domain/Competitions/TeamPlayersCreator";
import Season from "Domain/Season/Season";
import PlayersCreatorSelect from "DomainComponents/PlayersCreatorSelect";
import PlayerFormDialog, { FormFields, PlayerFormValidationErrors } from "DomainComponents/PlayersFormDialog";
import isPromise from "is-promise";
import { l } from "Languages";
import { Observer, useObserver } from "mobx-react-lite";
import React, { useCallback, useMemo, useState } from "react";
import routes from "Router/routes";
import { concat, from } from "rxjs";
import { concatMap, map, mapTo, mergeMap, reduce } from "rxjs/operators";
import MergeProfilesFormDialog from "./MergeProfilesFormDialog";
import ProfileInvitationFormDialog from "./ProfileInvitationFormDialog";

type TeamPlayersProps = {
    team: CompetitionTeam;
    competition: Competition;
    season?: Season;
};

const TeamPlayers: React.FunctionComponent<TeamPlayersProps> = ({ team, competition, season }) => {
    const [isPlayersCreatorOpen, setIsPlayersCreatorOpen] = useState(false);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [playerToInvite, setPlayerToInvite] = useState<CompetitionTeamPlayer>();
    const [playerToEdit, setPlayerToEdit] = useState<CompetitionTeamPlayer>();
    const [playerForMerge, setPlayerForMerge] = useState<CompetitionTeamPlayer>();

    const saveSelection = useCallback(
        async ({ existingPlayers, newPlayers }: TeamPlayersSelectionResult) => {
            setSaveInProgress(true);

            const result = await concat(
                from(existingPlayers.length > 0 ? team.addExistingPlayers(existingPlayers.map(p => p.id)) : []).pipe(
                    map(r => r.isSuccess && r.result.WasSuccessful),
                ),
                from(newPlayers).pipe(
                    concatMap(p => team.addNewPlayer(p)),
                    map(r => r.isSuccess && r.result.WasSuccessful),
                ),
            )
                .pipe(
                    reduce<boolean, [number, number]>(
                        ([successCount, allCount], isSuccess) => [successCount + (isSuccess ? 1 : 0), allCount + 1],
                        [0, 0],
                    ),
                    mergeMap(response =>
                        from(competitionStore.fetchCompetitionTeamDetails(team.id)).pipe(mapTo(response)),
                    ),
                )
                .toPromise();

            const [successCount, allCount] = result;

            if (allCount === 0) {
                return;
            }
            if (successCount === allCount) {
                message.success(l("CompetitionTeamPlayers_AddPlayers_Success"));
            } else if (successCount === 0) {
                message.error(l("CompetitionTeamPlayers_AddPlayers_Failure"));
            } else {
                message.error(l("CompetitionTeamPlayers_AddPlayers_PartialFailure"));
            }

            setIsPlayersCreatorOpen(false);
            setSaveInProgress(false);
        },
        [team],
    );

    const removePlayer = useCallback((playerId: string) => team.removePlayer(playerId), [team]);

    const showRemovePlayerConfirmation = useCallback(
        (player: CompetitionTeamPlayer) => {
            Modal.confirm({
                onOk: () => removePlayer(player.id),
                icon: <ExclamationCircleOutlined />,
                title: l("CompetitionTeams_RemovePlayer_Confirmation_Title"),
                content: l("CompetitionTeams_RemovePlayer_Confirmation_Content", player.firstName, player.lastName),
                okText: l("Common_Remove"),
                okButtonProps: {
                    danger: true,
                },
                cancelText: l("Common_Cancel"),
                centered: true,
                maskClosable: true,
            });
        },
        [removePlayer],
    );

    const showCancelInvitationConfirmation = useCallback((player: CompetitionTeamPlayer) => {
        Modal.confirm({
            onOk: () => player.cancelProfileInvitation(),
            icon: <ExclamationCircleOutlined />,
            title: l("CompetitionTeamPlayers_CancelInvitation_Confirm_Title"),
            content: l("CompetitionTeamPlayers_CancelInvitation_Confirm_Content"),
            okText: l("CompetitionTeamPlayers_CancelInvitation_Confirm_OkText"),
            cancelText: l("CompetitionTeamPlayers_CancelInvitation_Confirm_CancelText"),
            centered: true,
            maskClosable: true,
        });
    }, []);

    const savePlayerEdition = useCallback(
        async (values: FormFields): Promise<PlayerFormValidationErrors | undefined> => {
            if (!playerToEdit) {
                throw new Error("No player to edit");
            }

            const result = await playerToEdit.edit({
                firstName: values.firstName,
                lastName: values.lastName,
                shirtNumber: values.shirtNumber ? parseInt(values.shirtNumber) : undefined,
            });

            const validationErrors = await Promise.all(
                result
                    .handle(["FirstNameNullOrEmpty", "FirstNameTooLong"], error => ({
                        firstName:
                            error === "FirstNameNullOrEmpty"
                                ? l("Common_Validation_FieldRequired")
                                : l("Common_Validation_FieldTooLong"),
                    }))
                    .handle(["LastNameNullOrEmpty", "LastNameTooLong"], error => ({
                        firstName:
                            error === "LastNameNullOrEmpty"
                                ? l("Common_Validation_FieldRequired")
                                : l("Common_Validation_FieldTooLong"),
                    }))
                    .handle(["PlayerNotFound", "TeamNotFound", "failure"], () => {
                        message.error(l("CompetitionTeamPlayers_Edit_Failure"));

                        return {};
                    })
                    .handle("ShirtNumberOutOfRange", () => ({
                        shirtNumber: l("CompetitionTeamPlayers_CreateNew_ShirtNumberError"),
                    }))
                    .handle("success", async () => {
                        await competitionStore.fetchCompetitionTeamDetails(team.id);

                        message.success(l("CompetitionTeamPlayers_Edit_Success"));
                        setPlayerToEdit(undefined);

                        return {};
                    })
                    .check({
                        reducer: (prev, curr) => {
                            return [...prev, isPromise(curr) ? curr : Promise.resolve(curr)];
                        },
                        initialValue: [] as Promise<PlayerFormValidationErrors>[],
                    }),
            );

            return validationErrors.reduce((prev, curr) => ({ ...prev, ...curr }), {});
        },
        [setPlayerToEdit, playerToEdit, team.id],
    );

    const columns = useMemo(
        (): ColumnProps<CompetitionTeamPlayer>[] => [
            {
                title: l("Common_Ordinal"),
                render: (_, player, index) => index + 1,
            },
            {
                render: (_, player) => player.pictureUri && <Avatar src={player.pictureUri} />,
            },
            {
                title: l("CompetitionTeamPlayers_LastName"),
                render: (_, player) => (
                    <NavigationLink
                        to={routes.competitionTeam.players.playerDetails({ teamId: team.id, playerId: player.id })}>
                        {player.lastName}
                    </NavigationLink>
                ),
            },
            {
                title: l("CompetitionTeamPlayers_FirstName"),
                render: (_, player) => (
                    <NavigationLink
                        to={routes.competitionTeam.players.playerDetails({ teamId: team.id, playerId: player.id })}>
                        {player.firstName}
                    </NavigationLink>
                ),
            },
            {
                title: l("CompetitionTeamPlayers_ShirtNumber"),
                render: (_, player) => player.shirtNumber,
            },
            {
                title: l("CompetitionTeamPlayers_Account"),
                render: (_, player) => (
                    <Observer>
                        {() =>
                            player.accountState === AccountStateDTO.Invited ? (
                                <>{l("CompetitionTeamPlayers_PlayerInvited")}</>
                            ) : player.accountState === AccountStateDTO.Linked ? (
                                <CheckOutlined />
                            ) : (
                                <Button type="link" onClick={() => setPlayerToInvite(player)}>
                                    {l("CompetitionTeamPlayers_InvitePlayer")}
                                </Button>
                            )
                        }
                    </Observer>
                ),
            },
            {
                title: l("Common_Actions"),
                render: (_, player) => (
                    <Observer>
                        {() => (
                            <DropdownMenu
                                menuItems={[
                                    ...[
                                        {
                                            children: (
                                                <>
                                                    <EditOutlined /> {l("CompetitionTeamPlayers_Edit")}
                                                </>
                                            ),
                                            onClick: () => setPlayerToEdit(player),
                                        },
                                        {
                                            children: (
                                                <>
                                                    <MergeCellsOutlined /> {l("CompetitionTeamPlayers_MergeProfiles")}
                                                </>
                                            ),
                                            onClick: () => setPlayerForMerge(player),
                                        },
                                        {
                                            children: (
                                                <>
                                                    <DeleteOutlined /> {l("CompetitionTeams_RemovePlayer")}
                                                </>
                                            ),
                                            onClick: () => showRemovePlayerConfirmation(player),
                                        },
                                    ],
                                    ...(player.pendingProfileInvitation
                                        ? [
                                              {
                                                  children: (
                                                      <>
                                                          <CloseCircleOutlined />{" "}
                                                          {l("CompetitionTeamPlayers_CancelInvitation")}
                                                      </>
                                                  ),
                                                  onClick: () => showCancelInvitationConfirmation(player),
                                              },
                                          ]
                                        : []),
                                ]}
                            />
                        )}
                    </Observer>
                ),
            },
        ],
        [team.id, showRemovePlayerConfirmation, showCancelInvitationConfirmation],
    );

    return useObserver(() => (
        <>
            <Table
                dataSource={team.players}
                columns={columns}
                pagination={false}
                locale={{ emptyText: <EmptyState text={l("CompetitionTeamPlayers_NoPlayersInTeam")} /> }}
            />
            {team && isPlayersCreatorOpen ? (
                <PlayersCreatorSelect
                    team={team}
                    saveInProgress={saveInProgress}
                    onSave={saveSelection}
                    onCancel={() => setIsPlayersCreatorOpen(false)}
                />
            ) : (
                <PageContent.ActionButton onClick={() => setIsPlayersCreatorOpen(true)}>
                    <PlusOutlined /> {l("CompetitionTeamPlayers_AddPlayer")}
                </PageContent.ActionButton>
            )}
            {playerToInvite && (
                <ProfileInvitationFormDialog player={playerToInvite} onClose={() => setPlayerToInvite(undefined)} />
            )}
            {playerToEdit && (
                <PlayerFormDialog
                    onClose={() => setPlayerToEdit(undefined)}
                    onSave={savePlayerEdition}
                    player={playerToEdit}
                />
            )}
            {playerForMerge && (
                <MergeProfilesFormDialog
                    player={playerForMerge}
                    team={team}
                    onClose={() => setPlayerForMerge(undefined)}
                    competitionName={competition.name}
                    seasonName={season?.name}
                />
            )}
        </>
    ));
};

export default TeamPlayers;
