import { DeleteOutlined } from "@ant-design/icons";
import { Button, DatePicker, Form, Input } from "antd";
import { useForm } from "antd/lib/form/util";
import DefferedPhotoUpload, { UploadedOrInlinePhoto } from "Components/DefferedPhotoUpload";
import Modal from "Components/Modal";
import Spacing from "Components/Spacing";
import Video from "Domain/Videos/Video";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import moment from "moment";
import React, { useCallback } from "react";
import { dateFormat } from "Utils/formatting";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";

export type VideoFormValidationErrors = Partial<Record<FormField, string>>;

type VideoFormDialogProps = {
    video?: Video;
    onDeleteVideo?: () => void;
    onSave: (values: FormFields) => Promise<VideoFormValidationErrors | undefined>;
    onClose: () => void;
};

const VideoFormDialog: React.FunctionComponent<VideoFormDialogProps> = ({ video, onClose, onSave, onDeleteVideo }) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const onFinish = useCallback(
        async (values: FormFields) => {
            const validationErrors = await runInTask(() => onSave(values));

            for (const field in validationErrors) {
                form.setFields([
                    {
                        name: field,
                        errors: [validationErrors[field]],
                        value: values[field],
                    },
                ]);
            }
        },
        [form, onSave, runInTask],
    );

    return useObserver(() => (
        <Modal
            title={
                <Spacing childrenGutterY>
                    <div>{video ? l("Videos_Form_Edit_Title") : l("Videos_Form_Add_Title")}</div>
                    {video && onDeleteVideo && (
                        <Button type="danger" onClick={onDeleteVideo}>
                            <DeleteOutlined /> {l("Common_Remove")}
                        </Button>
                    )}
                </Spacing>
            }
            onCancel={onClose}
            okText={video ? l("Common_Save") : l("Common_Add")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}
            okButtonProps={{
                disabled: isRunning,
                loading: isRunning,
            }}>
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                initialValues={{
                    [guard<FormField>("thumbnail")]: video?.thumbnailUri,
                    [guard<FormField>("title")]: video?.title,
                    [guard<FormField>("uri")]: video?.uri,
                    [guard<FormField>("dateRecorded")]: video?.dateRecorded ?? moment(),
                }}>
                <Form.Item
                    label={l("Videos_Form_Thumbnail")}
                    name={guard<FormField>("thumbnail")}
                    rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                    <DefferedPhotoUpload />
                </Form.Item>
                <Form.Item
                    name={guard<FormField>("title")}
                    label={l("Videos_Form_Title")}
                    rules={[
                        { required: true, message: l("Common_Validation_FieldRequired") },
                        { max: 128, message: l("Common_Validation_FieldTooLong") },
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    name={guard<FormField>("uri")}
                    label={l("Videos_Form_Uri")}
                    rules={[
                        { required: true, message: l("Common_Validation_FieldRequired") },
                        { max: 500, message: l("Common_Validation_FieldTooLong") },
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label={l("Videos_Form_DateCreated")}
                    name={guard<FormField>("dateRecorded")}
                    rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                    <DatePicker format={dateFormat} allowClear={false} />
                </Form.Item>
            </Form>
        </Modal>
    ));
};

export type FormFields = {
    title: string;
    thumbnail: UploadedOrInlinePhoto;
    uri: string;
    dateRecorded: moment.Moment;
};

type FormField = keyof FormFields;

export default VideoFormDialog;
