import { PageHeader, Skeleton } from "antd";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import PageContent from "Components/PageContent";
import RoutedTabs from "Components/RoutedTabs";
import Spacing from "Components/Spacing";
import competitionStore from "Domain/Competitions";
import seasonStore from "Domain/Season";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import routes, { PageComponent } from "Router/routes";
import useRunInTask from "Utils/Hooks/useRunInTask";
import CompetitionPhases from "./CompetitionPhases";
import CompetitionReferees from "./CompetitionReferees";
import CompetitionSettings from "./CompetitionSettings";
import CompetitionTeams from "./CompetitionTeams";

const CompetitionDetailsPage: PageComponent<typeof routes.competitionDetails> = ({
    match: {
        params: { competitionId },
    },
    history: { push },
    location,
}) => {
    const [detailsFetchInProgress, runDetailsFetchInTask] = useRunInTask();

    useEffect(() => {
        runDetailsFetchInTask(() => competitionStore.fetchCompetitionDetails(competitionId));
    }, [competitionId, runDetailsFetchInTask]);

    const competition = useObserver(() => competitionStore.getById(competitionId));
    const season = useObserver(() => (competition?.seasonId ? seasonStore.getById(competition.seasonId) : undefined));

    const breadcrumb: BreadcrumbProps["routes"] = useMemo(
        () =>
            competition
                ? [
                      {
                          breadcrumbName: season ? season.name : l("Competitions_SeasonSelection_NoSeason"),
                          path: routes.competitions({ seasonId: competition.seasonId }),
                      },
                      {
                          breadcrumbName: competition.name,
                          path: routes.competitionDetails({ competitionId }),
                      },
                  ]
                : [],
        [competition, competitionId, season],
    );

    return (
        <>
            <PageContent>
                <PageContent.Header>
                    <PageContent.HeaderSkeleton active={!competition} loading={!competition}>
                        <PageHeader
                            title={competition?.name}
                            onBack={() => competition && push(routes.competitions({ seasonId: competition.seasonId }))}
                            breadcrumb={{
                                routes: breadcrumb,
                                itemRender: ({ path, breadcrumbName }) =>
                                    path === location.pathname ? (
                                        breadcrumbName
                                    ) : (
                                        <Link to={path}>{breadcrumbName}</Link>
                                    ),
                            }}
                        />
                    </PageContent.HeaderSkeleton>
                </PageContent.Header>
                <PageContent.Card>
                    <Spacing childrenGutterY>
                        <Skeleton active={detailsFetchInProgress} loading={detailsFetchInProgress}>
                            {competition && (
                                <RoutedTabs<"phases" | "teams" | "referees" | "settings">
                                    tabs={[
                                        {
                                            key: "phases",
                                            label: l("CompetitionDetails_Phases"),
                                            navigateTo: routes.competitionDetails.phases({
                                                competitionId: competitionId,
                                            }),
                                            routePattern: routes.competitionDetails.phases.pattern,
                                            content: <CompetitionPhases competition={competition} />,
                                        },
                                        {
                                            key: "teams",
                                            label: l("CompetitionDetails_Teams"),
                                            navigateTo: routes.competitionDetails.teams({
                                                competitionId: competitionId,
                                            }),
                                            routePattern: routes.competitionDetails.teams.pattern,
                                            content: <CompetitionTeams competition={competition} />,
                                        },
                                        {
                                            key: "referees",
                                            label: l("CompetitionDetails_Referees"),
                                            navigateTo: routes.competitionDetails.referees({
                                                competitionId: competitionId,
                                            }),
                                            routePattern: routes.competitionDetails.referees.pattern,
                                            content: <CompetitionReferees competition={competition} />,
                                        },
                                        {
                                            key: "settings",
                                            label: l("CompetitionSettings"),
                                            navigateTo: routes.competitionDetails.settings({
                                                competitionId: competitionId,
                                            }),
                                            routePattern: routes.competitionDetails.settings.pattern,
                                            content: <CompetitionSettings competition={competition} />,
                                        },
                                    ]}
                                    defaultKey="phases"
                                />
                            )}
                        </Skeleton>
                    </Spacing>
                </PageContent.Card>
            </PageContent>
        </>
    );
};

export default CompetitionDetailsPage;
