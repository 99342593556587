import { PlusOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Button } from "antd";
import EmptyState from "Components/EmptyState";
import { Select } from "Components/Select";
import Spacing from "Components/Spacing";
import getTeamsAndReferencesOptions from "Components/TeamsAndReferencesOptions";
import GroupsPhaseTeamsSelection from "Domain/CompetitionPhases/GroupsPhase/GroupsPhaseTeamsSelection";
import { l } from "Languages";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect } from "react";
import useRunInTask from "Utils/Hooks/useRunInTask";
import styles from "./styles.scss";

const cx = mkCx(styles);

type AddTeamsToGroupSelectProps = {
    selection?: GroupsPhaseTeamsSelection;

    onShowTeamsSelection: () => void;
    onAddTeamsToGroup: () => Promise<void>;
    onAddPlaceholderTeamForPhase: () => void;
    onCancel: () => void;
};

const AddTeamsToGroupSelect: React.FunctionComponent<AddTeamsToGroupSelectProps> = observer(
    ({ selection, onShowTeamsSelection, onCancel, onAddTeamsToGroup, onAddPlaceholderTeamForPhase }) => {
        const [isTeamsAdditionInProgress, runTeamsAdditionInTask] = useRunInTask();

        const availableTeams = selection?.availableTeams.teams;

        useEffect(() => {
            selection?.syncSelection();
        }, [availableTeams, selection]);

        const addTeamsToGroup = useCallback(() => {
            runTeamsAdditionInTask(() => onAddTeamsToGroup());
        }, [onAddTeamsToGroup, runTeamsAdditionInTask]);

        return (
            <div className={cx("container")}>
                {selection ? (
                    <div className={cx("add-teams-form")}>
                        <Select
                            notFoundContent={<EmptyState text={l("CompetitionPhases_Groups_AddTeams_EmptyState")} />}
                            value={selection.selectedIds}
                            onChange={(ids: string[]) => selection.setSelectedIds(ids)}
                            className={cx("select")}
                            mode="multiple"
                            showArrow
                            allowClear
                            additionalOptions={[
                                {
                                    content: (
                                        <>
                                            <PlusOutlined /> {l("CompetitionPhases_PlaceholderTeams_Add")}
                                        </>
                                    ),
                                    onClick: onAddPlaceholderTeamForPhase,
                                },
                            ]}>
                            {getTeamsAndReferencesOptions(selection)}
                        </Select>
                        <Spacing childrenGutterX>
                            <Button onClick={onCancel}>{l("Common_Cancel")}</Button>
                            <Button
                                type="primary"
                                onClick={addTeamsToGroup}
                                loading={isTeamsAdditionInProgress}
                                disabled={selection.selectedIds.length === 0}>
                                {l("Common_Add")}
                            </Button>
                        </Spacing>
                    </div>
                ) : (
                    <Button type="dashed" onClick={onShowTeamsSelection} className={cx("show-selection-button")}>
                        <PlusOutlined /> {l("CompetitionPhases_Groups_AddTeams")}
                    </Button>
                )}
            </div>
        );
    },
);

export default AddTeamsToGroupSelect;
