import { MatchGroupDTO } from "Contracts/PlayooLeagueClient";
import { action, observable } from "mobx";

class MatchGroup {
    readonly id: string;
    @observable name?: string;

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }

    static fromDTO(dto: MatchGroupDTO): MatchGroup {
        return new MatchGroup(dto.LocalId, dto.Name);
    }

    @action.bound
    updateName(name: string) {
        this.name = name;
    }
}

export default MatchGroup;
