import mkCx from "@leancode/cx";
import { Form, InputNumber } from "antd";
import { useForm } from "antd/lib/form/util";
import TextArea from "antd/lib/input/TextArea";
import Modal from "Components/Modal";
import { Select } from "Components/Select";
import CompetitionTeam from "Domain/Competitions/CompetitionTeam";
import { l } from "Languages";
import React, { useCallback } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";
import styles from "./styles.scss";

const cx = mkCx(styles);

type TablePointsModifierFormDialogProps = {
    nonPlaceholderPhaseTeams: CompetitionTeam[];

    onSave: (formFields: FormFields) => Promise<void>;
    onClose?: () => void;
};

const TablePointsModifierFormDialog: React.FunctionComponent<TablePointsModifierFormDialogProps> = ({
    nonPlaceholderPhaseTeams,
    onSave,
    onClose,
}) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const onFinish = useCallback(
        (values: FormFields) => {
            runInTask(() => onSave(values));
        },
        [onSave, runInTask],
    );

    return (
        <Modal
            title={l("CompetitionPhases_TablePointsModifiers_Form_Title")}
            onCancel={() => onClose?.()}
            okText={l("Common_Add")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}
            okButtonProps={{
                disabled: isRunning,
                loading: isRunning,
            }}>
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Form.Item
                    label={l("CompetitionPhases_TablePointsModifiers_Form_Team")}
                    name={guard<FormField>("teamId")}
                    rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                    <Select
                        showSearch
                        showArrow
                        selectOptions={nonPlaceholderPhaseTeams.map(t => ({
                            label: t.displayName,
                            value: t.id,
                            key: t.id,
                        }))}
                    />
                </Form.Item>
                <Form.Item
                    label={l("CompetitionPhases_TablePointsModifiers_Form_Points")}
                    name={guard<FormField>("points")}
                    rules={[
                        { required: true, message: l("Common_Validation_FieldRequired") },
                        {
                            validator: (rule, value: FormFields["points"] | null | undefined, callback) => {
                                if (value === 0) {
                                    callback(rule.message as string);
                                } else {
                                    callback();
                                }
                            },
                            message: l("CompetitionPhases_TablePointsModifiers_Form_Points_PointsEqualZero"),
                        },
                    ]}>
                    <InputNumber precision={0} className={cx("points-input")} />
                </Form.Item>
                <Form.Item
                    label={l("CompetitionPhases_TablePointsModifiers_Form_Description")}
                    name={guard<FormField>("description")}
                    rules={[{ max: 500, message: l("Common_Validation_FieldTooLong") }]}>
                    <TextArea />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export type FormFields = {
    teamId: string;
    points: number;
    description?: string;
};

type FormField = keyof FormFields;

export default TablePointsModifierFormDialog;
