import { Form, Input } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import MatchGroup from "Domain/CompetitionPhases/CustomPhase/MatchGroup";
import { l } from "Languages";
import React from "react";
import guard from "Utils/guard";

type CompetitionGroupFormDialogProps = {
    matchGroup?: MatchGroup;

    saveInProgress: boolean;

    onFinish: (formFields: FormFields) => void;
    onClose?: () => void;
};

const MatchGroupFormDialog: React.FunctionComponent<CompetitionGroupFormDialogProps> = ({
    matchGroup,
    saveInProgress,
    onFinish,
    onClose,
}) => {
    const [form] = useForm();

    return (
        <Modal
            title={
                matchGroup
                    ? l("CompetitionPhases_Details_MatchGroups_Edit_Title")
                    : l("CompetitionPhases_Details_MatchGroups_Create_Title")
            }
            onCancel={onClose}
            okText={matchGroup ? l("Common_Save") : l("Common_Add")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}
            okButtonProps={{
                loading: saveInProgress,
                disabled: saveInProgress,
            }}>
            <Form
                layout="vertical"
                form={form}
                onFinish={onFinish}
                initialValues={
                    matchGroup
                        ? {
                              [guard<FormField>("name")]: matchGroup.name,
                          }
                        : undefined
                }>
                <Form.Item
                    label={l("CompetitionPhases_Details_MatchGroups_Name")}
                    name={guard<FormField>("name")}
                    rules={[
                        { required: true, message: l("Common_Validation_FieldRequired") },
                        { max: 250, message: l("Common_Validation_FieldTooLong") },
                    ]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export type FormFields = {
    name: string;
};

type FormField = keyof FormFields;

export default MatchGroupFormDialog;
