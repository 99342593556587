import mkCx from "@leancode/cx";
import { Input } from "antd";
import React from "react";
import styles from "./styles.scss";

const cx = mkCx(styles);

export type Score = {
    team1?: string;
    team2?: string;
};

type ScoreInputProps = {
    value?: Score;
    team1Name: string;
    team2Name: string;
    disabled?: boolean;
    onChange?: (score: Score) => void;
};

const ScoreInput: React.FunctionComponent<ScoreInputProps> = ({ value, team1Name, team2Name, disabled, onChange }) => {
    return (
        <div className={cx("container")}>
            <div className={cx("team1-name")}>{team1Name}</div>
            <Input
                type="number"
                className={cx("input")}
                value={value?.team1}
                onChange={e => onChange?.({ ...value, team1: e.target.value })}
                disabled={disabled}
            />
            <div> : </div>
            <Input
                type="number"
                className={cx("input")}
                value={value?.team2}
                onChange={e => onChange?.({ ...value, team2: e.target.value })}
                disabled={disabled}
            />
            <div>{team2Name}</div>
        </div>
    );
};

export default ScoreInput;
