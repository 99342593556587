import { handleResponse } from "@leancode/validation";
import { CompetitionGroupDTO, UpdateCompetitionGroup } from "Contracts/PlayooLeagueClient";
import { observable, runInAction } from "mobx";
import api from "Services/Api";

class CompetitionGroup {
    readonly id: string;

    @observable name: string;

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }

    static fromDTO(dto: CompetitionGroupDTO) {
        return new CompetitionGroup(dto.Id, dto.Name);
    }

    async edit(name: string) {
        const response = await api.updateCompetitionGroup({
            GroupId: this.id,
            Name: name,
        });

        handleResponse(response, UpdateCompetitionGroup).handle("success", () => {
            runInAction(() => {
                this.name = name;
            });
        });

        return handleResponse(response, UpdateCompetitionGroup);
    }
}

export default CompetitionGroup;
