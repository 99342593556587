import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Modal as AntdModal, Row, Spin } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import { Select, SelectProps } from "Components/Select";
import Spacing from "Components/Spacing";
import { MatchEventSideDTO, MatchEventSubtypeDTO, MatchEventTypeDTO } from "Contracts/PlayooLeagueClient";
import MatchEventEditor, { FormField, FormFields } from "Domain/Matches/MatchEventEditor";
import MatchPlayer from "Domain/Matches/MatchPlayer";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";
import { isValidInteger } from "Utils/validationHelpers";

export type MatchEventFormValidationErrors = Partial<Record<FormField, string>>;

type MatchEventFormDialog = {
    mode: "create" | "edit";
    onDelete?: () => Promise<void>;
    onSave: (fields: FormFields) => Promise<MatchEventFormValidationErrors | undefined>;
    onClose: () => void;

    team1Name: string;
    team2Name: string;

    editor: MatchEventEditor;
};

const eventTypes: { type: MatchEventTypeDTO; label: string }[] = [
    {
        type: MatchEventTypeDTO.Goal,
        label: l("MatchEvent_Type_Goal"),
    },
    {
        type: MatchEventTypeDTO.Shot,
        label: l("MatchEvent_Type_Shot"),
    },
    {
        type: MatchEventTypeDTO.Foul,
        label: l("MatchEvent_Type_Foul"),
    },
    {
        type: MatchEventTypeDTO.YellowCard,
        label: l("MatchEvent_Type_YellowCard"),
    },
    {
        type: MatchEventTypeDTO.RedCard,
        label: l("MatchEvent_Type_RedCard"),
    },
    {
        type: MatchEventTypeDTO.MinutesPenalty,
        label: l("MatchEvent_Type_MinutesPenalty"),
    },
];

const goalEventSubtypes: { type: MatchEventSubtypeDTO; label: string }[] = [
    {
        type: MatchEventSubtypeDTO.Unspecified,
        label: l("MatchEvent_Subtype_Goal_Unspecified"),
    },
    {
        type: MatchEventSubtypeDTO.FromNear,
        label: l("MatchEvent_Subtype_Goal_FromNear"),
    },
    {
        type: MatchEventSubtypeDTO.FromDistance,
        label: l("MatchEvent_Subtype_Goal_FromDistance"),
    },
    {
        type: MatchEventSubtypeDTO.FollowUp,
        label: l("MatchEvent_Subtype_Goal_FollowUp"),
    },
    {
        type: MatchEventSubtypeDTO.Head,
        label: l("MatchEvent_Subtype_Goal_Head"),
    },
    {
        type: MatchEventSubtypeDTO.Penalty,
        label: l("MatchEvent_Subtype_Goal_Penalty"),
    },
    {
        type: MatchEventSubtypeDTO.FreeKick,
        label: l("MatchEvent_Subtype_Goal_FreeKick"),
    },
    {
        type: MatchEventSubtypeDTO.Own,
        label: l("MatchEvent_Subtype_Goal_Own"),
    },
];

const shotEventSubtypes: { type: MatchEventSubtypeDTO; label: string }[] = [
    {
        type: MatchEventSubtypeDTO.OnTarget,
        label: l("MatchEvent_Subtype_Shot_OnTarget"),
    },
    {
        type: MatchEventSubtypeDTO.OffTarget,
        label: l("MatchEvent_Subtype_Shot_OffTarget"),
    },
    {
        type: MatchEventSubtypeDTO.GoalPost,
        label: l("MatchEvent_Subtype_Shot_GoalPost"),
    },
    {
        type: MatchEventSubtypeDTO.Crossbar,
        label: l("MatchEvent_Subtype_Shot_Crossbar"),
    },
];

const MatchEventFormDialog: React.FunctionComponent<MatchEventFormDialog> = ({
    onSave,
    onDelete,
    onClose,
    team1Name,
    team2Name,
    editor,
    mode,
}) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const onFinish = useCallback(
        (values: FormFields) =>
            runInTask(async () => {
                const validationErrors = await onSave(values);

                for (const field in validationErrors) {
                    form.setFields([
                        {
                            name: field,
                            errors: [validationErrors[field]],
                            value: values[field],
                        },
                    ]);
                }
            }),
        [onSave, form, runInTask],
    );

    const showDeleteEventConfirmation = useCallback(() => {
        AntdModal.confirm({
            onOk: onDelete,
            icon: <ExclamationCircleOutlined />,
            title: l("MatchEvent_Delete_Confirmation_Title"),
            content: l("MatchEvent_Delete_Confirmation_Content"),
            okText: l("Common_Remove"),
            okButtonProps: {
                danger: true,
            },
            cancelText: l("Common_Cancel"),
            centered: true,
            maskClosable: true,
        });
    }, [onDelete]);

    return useObserver(() => (
        <Modal
            title={
                <Spacing childrenGutterY>
                    <div>{mode === "edit" ? l("MatchEvent_Edit_Title") : l("MatchEvent_Add_Title")}</div>
                    {mode === "edit" && (
                        <Button type="danger" onClick={showDeleteEventConfirmation}>
                            <DeleteOutlined /> {l("Common_Remove")}
                        </Button>
                    )}
                </Spacing>
            }
            onCancel={onClose}
            okText={mode === "edit" ? l("Common_Save") : l("Common_Add")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}>
            <Spin spinning={isRunning}>
                <Form
                    layout="vertical"
                    form={form}
                    fields={editor.fields}
                    onFinish={onFinish}
                    onFieldsChange={editor.onChange}>
                    <Form.Item
                        name={guard<FormField>("side")}
                        label={l("MatchEvent_Edit_Side")}
                        rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                        <Select
                            showArrow
                            selectOptions={[
                                {
                                    value: MatchEventSideDTO.Team1,
                                    key: "team1",
                                    label: team1Name,
                                },
                                {
                                    value: MatchEventSideDTO.Team2,
                                    key: "team2",
                                    label: team2Name,
                                },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        name={guard<FormField>("type")}
                        label={l("MatchEvent_Edit_Type")}
                        rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                        <Select
                            showArrow
                            selectOptions={eventTypes.map(t => ({
                                value: t.type,
                                label: t.label,
                                key: t.type.toString(),
                            }))}
                        />
                    </Form.Item>
                    {(editor.type === MatchEventTypeDTO.Goal || editor.type === MatchEventTypeDTO.Shot) && (
                        <Form.Item
                            name={guard<FormField>("subtype")}
                            label={l("MatchEvent_Edit_Subtype")}
                            rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                            <Select
                                showArrow
                                selectOptions={(editor.type === MatchEventTypeDTO.Goal
                                    ? goalEventSubtypes
                                    : shotEventSubtypes
                                ).map(t => ({
                                    value: t.type,
                                    label: t.label,
                                    key: t.type.toString(),
                                }))}
                            />
                        </Form.Item>
                    )}
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name={guard<FormField>("minute")}
                                label={l("MatchEvent_Edit_Minute")}
                                rules={[
                                    {
                                        validator: (rule, value: FormFields["minute"], callback) => {
                                            if (
                                                value &&
                                                (!isValidInteger(value) ||
                                                    (isValidInteger(value) && parseInt(value) < 0))
                                            ) {
                                                callback(rule.message as string);
                                            } else {
                                                callback();
                                            }
                                        },
                                        message: l("Common_Validation_Number"),
                                    },
                                    {
                                        validator: (rule, value: FormFields["minute"], callback) => {
                                            const second: FormFields["second"] = form.getFieldValue(
                                                guard<FormField>("second"),
                                            );

                                            if (second && !value) {
                                                callback(rule.message as string);
                                            } else {
                                                callback();
                                            }
                                        },
                                        message: l("Common_Validation_FieldRequired"),
                                    },
                                ]}>
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name={guard<FormField>("second")}
                                label={l("MatchEvent_Edit_Second")}
                                rules={[
                                    {
                                        validator: (rule, value: FormFields["second"], callback) => {
                                            if (
                                                value &&
                                                (!isValidInteger(value) ||
                                                    (isValidInteger(value) &&
                                                        (parseInt(value) < 0 || parseInt(value) > 60)))
                                            ) {
                                                callback(rule.message as string);
                                            } else {
                                                callback();
                                            }
                                        },
                                        message: l("Common_Validation_Number"),
                                    },
                                ]}>
                                <Input type="number" />
                            </Form.Item>
                        </Col>
                    </Row>
                    {editor.selectablePlayers && (
                        <Form.Item name={guard<FormField>("playerId")} label={l("MatchEvent_Edit_Player")}>
                            <PlayerSelect players={editor.selectablePlayers} />
                        </Form.Item>
                    )}
                    {editor.selectableOtherPlayers && (
                        <Form.Item
                            name={guard<FormField>("otherPlayerId")}
                            label={
                                editor.type === MatchEventTypeDTO.Goal
                                    ? l("MatchEvent_Edit_OtherPlayer_Assistant")
                                    : l("MatchEvent_Edit_OtherPlayer_Fouled")
                            }>
                            <PlayerSelect players={editor.selectableOtherPlayers} />
                        </Form.Item>
                    )}
                    {editor.selectableGoalkeepers && (
                        <Form.Item
                            name={guard<FormField>("goalkeeperPlayerId")}
                            label={l("MatchEvent_Edit_Goalkeeper")}>
                            <PlayerSelect players={editor.selectableGoalkeepers} />
                        </Form.Item>
                    )}
                    {editor.type === MatchEventTypeDTO.MinutesPenalty && (
                        <Form.Item
                            name={guard<FormField>("penaltyValue")}
                            label={l("MatchEvent_Edit_PenaltyValue")}
                            rules={[
                                { required: true, message: l("Common_Validation_FieldRequired") },
                                {
                                    validator: (rule, value: FormFields["second"], callback) => {
                                        if (
                                            value &&
                                            (!isValidInteger(value) || (isValidInteger(value) && parseInt(value) < 0))
                                        ) {
                                            callback(rule.message as string);
                                        } else {
                                            callback();
                                        }
                                    },
                                    message: l("Common_Validation_Number"),
                                },
                            ]}>
                            <Input type="number" />
                        </Form.Item>
                    )}
                </Form>
            </Spin>
        </Modal>
    ));
};

const PlayerSelect: React.FunctionComponent<{ players: MatchPlayer[] } & SelectProps> = ({
    players,
    ...selectProps
}) => {
    return (
        <Select
            showArrow
            allowClear
            showSearch
            optionFilterProp="searchField"
            selectOptions={players.map(p => ({
                value: p.id,
                label: (
                    <>
                        {p.shirtNumber && <b>{p.shirtNumber} </b>}
                        {p.fullName}
                    </>
                ),
                key: p.id,
                searchField: (p.shirtNumber ? `${p.shirtNumber} ` : "") + p.fullName,
            }))}
            {...selectProps}
        />
    );
};

export default MatchEventFormDialog;
