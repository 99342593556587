import { handleResponse } from "@leancode/validation";
import { AddSeason, HideSeason } from "Contracts/PlayooLeagueClient";
import { computed } from "mobx";
import moment from "moment";
import api from "Services/Api";
import { dateToDTO } from "Utils/DTO";
import { MapStore } from "Utils/mobx";
import retryQuery from "Utils/retryQuery";
import stableSort from "Utils/stableSort";
import Season from "./Season";

class SeasonStore extends MapStore<Season> {
    @computed get seasons() {
        return stableSort(Array.from(this.items.values()), (a, b) =>
            a.expectedStartDate.isSameOrBefore(b.expectedStartDate) ? 1 : -1,
        );
    }

    async fetchSeasons() {
        const seasons = await retryQuery(() => api.allSeasons({}));

        return this.put(...seasons.map(s => Season.fromDTO(s)));
    }

    async addSeason(data: CreateSeasonData) {
        const response = await api.addSeason({
            SeasonId: data.id,
            ExpectedStartDate: dateToDTO(data.expectedStartDate),
            Name: data.name,
        });

        return handleResponse(response, AddSeason);
    }

    async hideSeason(seasonId: string) {
        const season = this.getById(seasonId);

        if (!season) {
            throw new Error("Season not found.");
        }

        const response = await api.hideSeason({
            SeasonId: seasonId,
        });

        handleResponse(response, HideSeason).handle("success", () => {
            this.remove(season);
        });

        return handleResponse(response, HideSeason);
    }
}

export type CreateSeasonData = {
    id: string;
    name: string;
    expectedStartDate: moment.Moment;
};

export default SeasonStore;
