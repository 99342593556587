import { ArrowRightOutlined, DeleteOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Button, message, Modal, Skeleton, Table } from "antd";
import { ColumnProps } from "antd/lib/table";
import DropdownMenu from "Components/DropdownMenu";
import NavigationLink from "Components/NavigationLink";
import PageContent from "Components/PageContent";
import SeasonStateTag from "Components/SeasonStateTag";
import Spacing from "Components/Spacing";
import { SeasonStateDTO } from "Contracts/PlayooLeagueClient";
import seasonStore from "Domain/Season";
import Season from "Domain/Season/Season";
import SeasonFormDialog from "DomainComponents/SeasonFormDialog";
import { l } from "Languages";
import { Observer, useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import routes, { PageComponent } from "Router/routes";
import { formatDate } from "Utils/formatting";
import useRunInTask from "Utils/Hooks/useRunInTask";
import styles from "./styles.scss";

const cx = mkCx(styles);

type SeasonFormState =
    | {
          mode: "edit";
          season: Season;
      }
    | { mode: "create" | "off" };

const SeasonsPage: PageComponent<typeof routes.seasons> = () => {
    const [seasonStateUpdateInProgress, runSeasonStateUpdateInTask] = useRunInTask();
    const [seasonFetchInProgress, runSeasonsFetchInTask] = useRunInTask();
    const [seasonFormState, setSeasonFormState] = useState<SeasonFormState>({ mode: "off" });

    const onUpdateSeasonState = useCallback(
        (season: Season, seasonState: SeasonStateDTO) => () =>
            runSeasonStateUpdateInTask(() => season.updateState(seasonState)),
        [runSeasonStateUpdateInTask],
    );

    const hideSeason = useCallback(async (seasonId: string) => {
        const result = await seasonStore.hideSeason(seasonId);

        result
            .handle(["SeasonNotFound", "SeasonAlreadyHidden", "failure"], () => {
                message.error(l("Seasons_Hide_Failure"));
            })
            .handle("success", () => {
                message.success(l("Seasons_Hide_Success"));
            })
            .check();
    }, []);

    const showHideSeasonConfirmation = useCallback(
        (competitionId: string) => {
            Modal.confirm({
                onOk: () => hideSeason(competitionId),
                icon: <ExclamationCircleOutlined />,
                title: l("Seasons_Hide_Confirmation_Title"),
                content: l("Seasons_Hide_Confirmation_Content"),
                okText: l("Common_Remove"),
                cancelText: l("Common_Cancel"),
                centered: true,
                maskClosable: true,
                okType: "danger",
            });
        },
        [hideSeason],
    );

    const columns = useMemo(
        (): ColumnProps<Season>[] => [
            {
                render: (_, season) => (
                    <NavigationLink to={routes.competitions({ seasonId: season.id })}>{season.name}</NavigationLink>
                ),
            },
            {
                render: (_, season) => formatDate(season.expectedStartDate),
            },
            {
                render: (_, season) => <Observer>{() => <SeasonStateTag seasonState={season.state} />}</Observer>,
            },
            {
                render: (_, season) => {
                    return (
                        <div className={cx("actions-cell")}>
                            <DropdownMenu
                                menuItems={[
                                    {
                                        children: l("Seasons_UpdateState_Ongoing"),
                                        onClick: onUpdateSeasonState(season, SeasonStateDTO.Ongoing),
                                    },
                                    {
                                        children: l("Seasons_UpdateState_Next"),
                                        onClick: onUpdateSeasonState(season, SeasonStateDTO.Next),
                                    },
                                    {
                                        children: l("Seasons_UpdateState_Default"),
                                        onClick: onUpdateSeasonState(season, SeasonStateDTO.Default),
                                    },
                                    {
                                        children: l("Seasons_Edit"),
                                        onClick: () => setSeasonFormState({ mode: "edit", season: season }),
                                    },
                                    {
                                        children: (
                                            <>
                                                <DeleteOutlined /> {l("Seasons_Hide")}
                                            </>
                                        ),
                                        onClick: () => showHideSeasonConfirmation(season.id),
                                    },
                                ]}
                            />
                            <NavigationLink to={routes.seasons.planSchedule({ seasonId: season.id })}>
                                <Button>{l("Seasons_SchedulePlanner")}</Button>
                            </NavigationLink>
                            <NavigationLink to={routes.competitions({ seasonId: season.id })}>
                                <Button>
                                    <ArrowRightOutlined />
                                </Button>
                            </NavigationLink>
                        </div>
                    );
                },
            },
        ],
        [onUpdateSeasonState, showHideSeasonConfirmation],
    );

    useEffect(() => {
        runSeasonsFetchInTask(() => seasonStore.fetchSeasons());
    }, [runSeasonsFetchInTask]);

    const season = useObserver(() => seasonStore.seasons);

    return (
        <>
            <PageContent>
                <PageContent.Card title={l("Seasons_Title")}>
                    <Spacing childrenGutterY>
                        <Skeleton loading={seasonFetchInProgress} active={seasonFetchInProgress}>
                            <Table
                                loading={seasonStateUpdateInProgress}
                                columns={columns}
                                dataSource={season}
                                pagination={false}
                                showHeader={false}
                                rowKey="id"
                            />
                        </Skeleton>
                        <PageContent.ActionButton onClick={() => setSeasonFormState({ mode: "create" })}>
                            <PlusOutlined /> {l("Seasons_Create")}
                        </PageContent.ActionButton>
                    </Spacing>
                </PageContent.Card>
            </PageContent>
            {seasonFormState.mode !== "off" && (
                <SeasonFormDialog
                    season={seasonFormState.mode === "edit" ? seasonFormState.season : undefined}
                    onClose={async savedFormFields => {
                        if (savedFormFields) {
                            await seasonStore.fetchSeasons();
                        }
                        setSeasonFormState({ mode: "off" });
                    }}
                />
            )}
        </>
    );
};

export default SeasonsPage;
