import mkCx from "@leancode/cx";
import { DatePicker, Form } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import moment from "moment";
import React from "react";
import { dateWithTimeFormat, formatDate, timeFormat } from "Utils/formatting";
import guard from "Utils/guard";
import styles from "./styles.scss";

const cx = mkCx(styles);

type AddMatchdayFormDialogProps = {
    existingMatchdays: moment.Moment[];

    onAddMatchday: (date: moment.Moment) => void;
    onClose: () => void;
};

const AddMatchdayFormDialog: React.FunctionComponent<AddMatchdayFormDialogProps> = ({
    existingMatchdays,
    onAddMatchday,
    onClose,
}) => {
    const [form] = useForm();

    return useObserver(() => (
        <Modal
            title={l("CompetitionPhases_PlanSchedule_AddMatchday_Form_Title")}
            onCancel={onClose}
            okText={l("Common_Add")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}>
            <Form layout="vertical" form={form} onFinish={(values: FormFields) => onAddMatchday(values.date)}>
                <Form.Item
                    name={guard<FormField>("date")}
                    trigger="onSelect"
                    rules={[
                        {
                            required: true,
                            message: l("Common_Validation_FieldRequired"),
                        },
                    ]}>
                    <DatePicker
                        dropdownClassName={cx("date-picker-dropdown")}
                        allowClear={false}
                        showTime={{ format: timeFormat }}
                        format={dateWithTimeFormat}
                        disabledDate={date => existingMatchdays.some(m => formatDate(m) === formatDate(date))}
                        showToday={false}
                    />
                </Form.Item>
            </Form>
        </Modal>
    ));
};

export type FormFields = {
    date: moment.Moment;
};

type FormField = keyof FormFields;

export default AddMatchdayFormDialog;
