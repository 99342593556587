import { DatePicker, Form, Input, message, Spin } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import seasonStore from "Domain/Season";
import Season from "Domain/Season/Season";
import { CreateSeasonData } from "Domain/Season/SeasonStore";
import { l } from "Languages";
import moment from "moment";
import React, { useCallback } from "react";
import { dateFormat } from "Utils/formatting";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";
import newId from "Utils/newId";

type SeasonFormDialogProps = {
    season?: Season;

    onClose?: (savedFormFields?: CreateSeasonData) => void;
};

const SeasonFormDialog: React.FunctionComponent<SeasonFormDialogProps> = ({ season, onClose }) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const setNameError = useCallback(
        (value: FormFields["name"], error: string) =>
            form.setFields([
                {
                    name: guard<FormField>("name"),
                    errors: [error],
                    value: value,
                },
            ]),
        [form],
    );

    const onFinish = useCallback(
        (values: FormFields) => {
            runInTask(async () => {
                const data: CreateSeasonData = {
                    ...values,
                    id: newId(),
                };

                if (season) {
                    const result = await season.edit(data);

                    result
                        .handle("NameMissingOrEmpty", () =>
                            setNameError(values.name, l("Common_Validation_FieldRequired")),
                        )
                        .handle("NameTooLong", () => setNameError(values.name, l("Common_Validation_FieldTooLong")))
                        .handle(["SeasonNotFound", "ExpectedStartDateOutOfRange", "failure"], () =>
                            message.error(l("Seasons_Edit_Failure")),
                        )
                        .handle(["success"], () => {
                            message.success(l("Seasons_Edit_Success"));
                            onClose?.(data);
                        })
                        .check();
                } else {
                    const result = await seasonStore.addSeason(data);

                    result
                        .handle("NameMissingOrEmpty", () =>
                            setNameError(values.name, l("Common_Validation_FieldRequired")),
                        )
                        .handle("NameTooLong", () => setNameError(values.name, l("Common_Validation_FieldTooLong")))
                        .handle(["SeasonIdAlreadyInUse", "ExpectedStartDateOutOfRange", "failure"], () =>
                            message.error(l("Seasons_Create_Failure")),
                        )
                        .handle(["success"], () => {
                            message.success(l("Seasons_Create_Success"));
                            onClose?.(data);
                        })
                        .check();
                }
            });
        },
        [onClose, runInTask, season, setNameError],
    );

    return (
        <Modal
            title={season ? l("Seasons_Edit_Title") : l("Seasons_Create_Title")}
            onCancel={() => onClose?.()}
            okText={season ? l("Common_Save") : l("Common_Add")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}>
            <Spin spinning={isRunning}>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    initialValues={
                        season
                            ? {
                                  name: season.name,
                                  expectedStartDate: season.expectedStartDate,
                              }
                            : undefined
                    }>
                    <Form.Item
                        label={l("Seasons_Create_Name")}
                        name={guard<FormField>("name")}
                        rules={[
                            { required: true, message: l("Common_Validation_FieldRequired") },
                            { max: 250, message: l("Common_Validation_FieldTooLong") },
                        ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={l("Seasons_Create_ExpectedStartDate")}
                        name={guard<FormField>("expectedStartDate")}
                        rules={[{ required: true, message: l("Common_Validation_FieldRequired") }]}>
                        <DatePicker format={dateFormat} />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

export type FormFields = {
    name: string;
    expectedStartDate: moment.Moment;
};

type FormField = keyof FormFields;

export default SeasonFormDialog;
