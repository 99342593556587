import mkCx from "@leancode/cx";
import React from "react";
import styles from "./styles.scss";

const cx = mkCx(styles);

const ScheduleTableTags: React.FunctionComponent = ({ children }) => {
    return <div className={cx("tags")}>{children}</div>;
};

export default ScheduleTableTags;
