import config from "config";
import { LocalTokenStorage } from "@leancode/login-manager/LocalTokenStorage";
import { SyncLoginManager } from "@leancode/login-manager/SyncLoginManager";

const secret = "secret"; // unused anyway
const client = "league-manager";
const scopes = "openid offline_access manager";

const tokenStorage = new LocalTokenStorage();
const loginManager = new SyncLoginManager(tokenStorage, config.authEndpoint, secret, client, scopes);

export default loginManager;
