import { message } from "antd";
import { UploadProps } from "antd/lib/upload";
import Upload from "Components/Upload";
import { l } from "Languages";
import React from "react";
import extractFile from "Utils/extractFile";
import { acceptedImageExtensions, isValidImageFileExtension } from "Utils/validationHelpers";

type ImageUploadProps = {
    onUpload?: (file: File) => void;
} & UploadProps;

const ImageUpload: React.FunctionComponent<ImageUploadProps> = ({ onUpload, ...uploadProps }) => {
    return (
        <Upload
            accept={acceptedImageExtensions.map(e => `.${e}`).join(",")}
            onChange={info => {
                if (info.file.status === "done") {
                    if (isValidImageFileExtension(info.file.name)) {
                        const file = extractFile(info.file);

                        file && onUpload?.(file);
                    } else {
                        message.error(l("ImageUpload_InvalidFormat"));
                    }
                }
            }}
            {...uploadProps}
        />
    );
};

export default ImageUpload;
