import { handleResponse } from "@leancode/validation";
import { message } from "antd";
import { SeasonDTO, SeasonStateDTO, UpdateSeason, UpdateSeasonState } from "Contracts/PlayooLeagueClient";
import { l } from "Languages";
import { observable, runInAction } from "mobx";
import moment from "moment";
import api from "Services/Api";
import { dateFromDTO, dateToDTO } from "Utils/DTO";

class Season {
    readonly id: string;

    @observable name: string;
    @observable expectedStartDate: moment.Moment;
    @observable state: SeasonStateDTO;

    constructor(id: string, init: SeasonInit) {
        this.id = id;
        this.name = init.name;
        this.expectedStartDate = init.expectedStartDate;
        this.state = init.state;
    }

    static fromDTO(dto: SeasonDTO) {
        return new Season(dto.Id, {
            expectedStartDate: dateFromDTO(dto.ExpectedStartDate),
            name: dto.Name,
            state: dto.State,
        });
    }

    async edit({ name, expectedStartDate }: EditSeasonData) {
        const response = await api.updateSeason({
            SeasonId: this.id,
            Name: name,
            ExpectedStartDate: dateToDTO(expectedStartDate),
        });

        handleResponse(response, UpdateSeason).handle("success", () =>
            runInAction(() => {
                this.name = name;
                this.expectedStartDate = expectedStartDate;
            }),
        );

        return handleResponse(response, UpdateSeason);
    }

    async updateState(state: SeasonStateDTO) {
        const response = await api.updateSeasonState({
            SeasonId: this.id,
            State: state,
        });

        handleResponse(response, UpdateSeasonState)
            .handle(["SeasonNotFound", "StateNotInEnumerationValues", "failure"], () =>
                message.error(l("Seasons_UpdateState_Failure")),
            )
            .handle("success", () => {
                runInAction(() => {
                    this.state = state;
                });

                message.success(l("Seasons_UpdateState_Success"));
            })
            .check();

        return handleResponse(response, UpdateSeasonState);
    }
}

type SeasonInit = Pick<Season, "expectedStartDate" | "name" | "state">;

export type EditSeasonData = {
    name: string;
    expectedStartDate: moment.Moment;
};

export default Season;
