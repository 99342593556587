import humanizeDuration from "humanize-duration";
import { currentLanguage } from "Languages";
import { observable } from "mobx";
import newId from "Utils/newId";

class Break {
    static readonly defaultDurationInMinutes = 20;

    readonly id: string;
    @observable durationInMinutes: number;

    constructor(durationInMinutes: number) {
        this.id = newId();
        this.durationInMinutes = durationInMinutes;
    }

    formatDuration() {
        const durationInMiliseconds = this.durationInMinutes * 60 * 1000;

        return humanizeDuration(durationInMiliseconds, {
            language: currentLanguage,
        });
    }
}

export default Break;
