import mkCx from "@leancode/cx";
import { Button, message, Skeleton, Typography } from "antd";
import { BreadcrumbProps } from "antd/lib/breadcrumb";
import PageHeader from "antd/lib/page-header";
import DropdownMenu from "Components/DropdownMenu";
import PageContent from "Components/PageContent";
import Spacing from "Components/Spacing";
import { SchedulePlannerValidationErrors } from "Domain/CompetitionPhases/SchedulePlanner/SchedulePlanner";
import SeasonSchedulePlanner from "Domain/CompetitionPhases/SchedulePlanner/SeasonSchedulePlanner";
import seasonStore from "Domain/Season";
import SchedulePlannerBoard from "DomainComponents/SchedulePlanner/SchedulePlannerBoard";
import SchedulePlannerForm from "DomainComponents/SchedulePlanner/SchedulePlannerForm";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import routes, { PageComponent } from "Router/routes";
import useRunInTask from "Utils/Hooks/useRunInTask";
import styles from "./styles.scss";
import { AvailableSportsFieldsConfiguration } from "Domain/CompetitionPhases/SchedulePlanner/SportsFieldConfiguration";
import { PlanResult } from "Domain/CompetitionPhases/SchedulePlanner/AutomaticSchedulePlanning/GreedyMatchdayScheduler";
import GreedySeasonMatchdayScheduler from "Domain/CompetitionPhases/SchedulePlanner/AutomaticSchedulePlanning/GreedySeasonMatchdayScheduler";

const cx = mkCx(styles);

const getSchedulePlannerErrorMessage = (validationError: SchedulePlannerValidationErrors): string | undefined => {
    switch (validationError) {
        case SchedulePlannerValidationErrors.ScheduleNotPlannedForAnySportsField:
            return l("CompetitionPhases_PlanSchedule_Errors_ScheduleNotPlannedForAnySportsField");
        case SchedulePlannerValidationErrors.MatchDatesNotGenerated:
            return l("CompetitionPhases_PlanSchedule_Errors_MatchDatesNotGenerated");
    }
};

const PlanSeasonSchedulePage: PageComponent<typeof routes.seasons.planSchedule> = ({
    match: {
        params: { seasonId },
    },
    history: { push },
    location,
}) => {
    const [schedulePlanner, setSchedulePlanner] = useState<SeasonSchedulePlanner>();
    const [isSaveInProgress, runSaveInTask] = useRunInTask();

    const season = useObserver(() => seasonStore.getById(seasonId));

    useEffect(() => {
        if (season) {
            if (!schedulePlanner || schedulePlanner.status === "initialized") {
                const scheduler = new SeasonSchedulePlanner(seasonId);
                scheduler.fetchSeasonData();
                setSchedulePlanner(scheduler);
            }
        } else {
            seasonStore.fetchSeasons();
        }
    }, [schedulePlanner, season, seasonId]);

    const onSaveSchedule = useCallback(
        () =>
            runSaveInTask(async () => {
                if (schedulePlanner) {
                    const {
                        sportsFieldsSavedSuccessfully: sportsFieldsSaveHandler,
                        schedulePlanSavedSuccessfully: schedulePlanSaveHandler,
                    } = await schedulePlanner?.save();

                    if (!sportsFieldsSaveHandler) {
                        message.error(l("CompetitionPhases_PlanSchedule_Failure"));
                        return;
                    }

                    if (!schedulePlanSaveHandler) {
                        message.error(l("CompetitionPhases_PlanSchedule_Failure"));
                        return;
                    }

                    message.success(l("CompetitionPhases_PlanSchedule_Success"));

                    push(routes.seasons.planSchedule({ seasonId: seasonId }));
                }
            }),
        [runSaveInTask, schedulePlanner, push, seasonId],
    );

    const onPlanScheduleAutomatically = useCallback(
        (availableSportsFieldsConfiguation: AvailableSportsFieldsConfiguration) => {
            if (!schedulePlanner) {
                throw new Error("Schedule planner not instantiated.");
            }

            const automaticPlanner = new GreedySeasonMatchdayScheduler(schedulePlanner.slotsConfiguration);

            const result = automaticPlanner.plan(
                schedulePlanner.allCompetitions,
                schedulePlanner.currentMatchdayConfiguration,
                schedulePlanner.filteredUnassignedMatches,
                availableSportsFieldsConfiguation,
            );

            if (result === PlanResult.PartiallyPlanned) {
                message.info(l("CompetitionPhases_PlanSchedule_AutomaticSchedulePlanning_SchedulePartiallyPlanned"));
            }
        },
        [schedulePlanner],
    );

    const breadcrumb: BreadcrumbProps["routes"] = useMemo(
        () => [
            {
                breadcrumbName: season ? season.name : "",
                path: routes.competitions({ seasonId: seasonId }),
            },
            {
                breadcrumbName: l("CompetitionPhases_PlanSchedule_Header"),
                path: "",
            },
        ],
        [season, seasonId],
    );

    return useObserver(() => (
        <>
            <PageContent wide>
                <PageContent.Header>
                    <PageContent.HeaderSkeleton
                        active={!(schedulePlanner?.status === "loaded")}
                        loading={!(schedulePlanner?.status === "loaded")}>
                        <PageHeader
                            title={l("CompetitionPhases_PlanSchedule_Header")}
                            onBack={() => push(routes.seasons())}
                            breadcrumb={{
                                routes: breadcrumb,
                                itemRender: ({ path, breadcrumbName }) =>
                                    path === location.pathname ? (
                                        breadcrumbName
                                    ) : (
                                        <Link to={path}>{breadcrumbName}</Link>
                                    ),
                            }}
                            extra={
                                schedulePlanner ? (
                                    <Spacing className={cx("header-extra-container")}>
                                        <Spacing childrenGutterX>
                                            <Button onClick={() => push(routes.seasons())}>{l("Common_Cancel")}</Button>
                                            <Button
                                                type="primary"
                                                onClick={onSaveSchedule}
                                                loading={isSaveInProgress}
                                                disabled={
                                                    isSaveInProgress || schedulePlanner.validationError !== undefined
                                                }>
                                                {l("Common_Save")}
                                            </Button>
                                        </Spacing>
                                        {schedulePlanner.validationError !== undefined && (
                                            <Typography.Text type="danger">
                                                {getSchedulePlannerErrorMessage(schedulePlanner.validationError)}
                                            </Typography.Text>
                                        )}
                                    </Spacing>
                                ) : undefined
                            }
                        />
                    </PageContent.HeaderSkeleton>
                </PageContent.Header>
                <PageContent.Card>
                    <Skeleton
                        active={!(schedulePlanner?.status === "loaded")}
                        loading={!(schedulePlanner?.status === "loaded")}>
                        {schedulePlanner && (
                            <Spacing childrenGutterY>
                                <div className={cx("planner-control")}>
                                    {schedulePlanner.slotsConfiguration && (
                                        <SchedulePlannerForm planner={schedulePlanner} />
                                    )}
                                    <Spacing childrenGutterX>
                                        <DropdownMenu
                                            menuItems={[
                                                {
                                                    children: l("CompetitionPhases_PlanSchedule_ClearSportsFields"),
                                                    onClick:
                                                        schedulePlanner.currentMatchdayConfiguration
                                                            ?.clearSportsFields ?? (() => {}),
                                                },
                                            ]}
                                        />
                                    </Spacing>
                                </div>
                                <SchedulePlannerBoard
                                    phases={schedulePlanner.allPhases}
                                    matchConflicts={
                                        schedulePlanner.currentMatchdayConfiguration?.matchConflicts ?? new Map()
                                    }
                                    canPlanScheduleAutomatically={
                                        schedulePlanner.canPlanCurrentMatchdayAutomatically ?? false
                                    }
                                    sportsFields={schedulePlanner.sportsFields ?? []}
                                    schedulePlan={schedulePlanner.currentMatchdayConfiguration?.schedule ?? []}
                                    unassignedMatches={schedulePlanner.filteredUnassignedMatches}
                                    onAddSportsField={schedulePlanner.addSportsField}
                                    onChange={schedulePlanner.currentMatchdayConfiguration?.moveSlot ?? (() => {})}
                                    onSportsFieldStartTimeChange={
                                        schedulePlanner.currentMatchdayConfiguration?.setSportsFieldStartTime ??
                                        (() => {})
                                    }
                                    onAddBreak={
                                        schedulePlanner.currentMatchdayConfiguration?.addBreakToSportsField ??
                                        (() => {})
                                    }
                                    onRemoveBreak={
                                        schedulePlanner.currentMatchdayConfiguration?.removeBreak ?? (() => {})
                                    }
                                    onPlanScheduleAutomatically={onPlanScheduleAutomatically}
                                    filter={schedulePlanner.filter}
                                />
                            </Spacing>
                        )}
                    </Skeleton>
                </PageContent.Card>
            </PageContent>
        </>
    ));
};

export default PlanSeasonSchedulePage;
