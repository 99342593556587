import mkCx from "@leancode/cx";
import { Card, Typography } from "antd";
import ViriumLogo from "Components/ViriumLogo";
import React, { PropsWithChildren } from "react";
import styles from "./styles.scss";
import ueLogo from "./ue-logo.png";

const cx = mkCx(styles);

function UnauthorizedAppLayout({ children }: PropsWithChildren<{}>) {
    return (
        <div className={cx("authentication-layout")}>
            <div className={cx("content")}>
                <img src={ueLogo} className={cx("ue-logo")} />
                <Card>
                    <div className={cx("logo-container")}>
                        <ViriumLogo className={cx("logo")} />
                    </div>
                    {children}
                </Card>
            </div>
        </div>
    );
}

UnauthorizedAppLayout.Actions = ({ children }: PropsWithChildren<{}>) => (
    <div className={cx("actions")}>{children}</div>
);
UnauthorizedAppLayout.Header = ({ children }: PropsWithChildren<{}>) => (
    <Typography.Title level={4} className={cx("header")}>
        {children}
    </Typography.Title>
);

export default UnauthorizedAppLayout;
