import { DeleteOutlined } from "@ant-design/icons";
import mkCx from "@leancode/cx";
import DropdownMenu from "Components/DropdownMenu";
import NavigationLink from "Components/NavigationLink";
import GroupsPhase from "Domain/CompetitionPhases/GroupsPhase/GroupsPhase";
import { l } from "Languages";
import useScheduleDeletion from "Pages/CompetitionPhases/CompetitionPhaseDetails/useScheduleDeletion";
import React from "react";
import routes from "Router/routes";
import styles from "./styles.scss";

const cx = mkCx(styles);

type GroupsPhaseScheduleActionsProps = {
    phase: GroupsPhase;
};

const GroupsPhaseScheduleActions: React.FunctionComponent<GroupsPhaseScheduleActionsProps> = ({ phase }) => {
    const showDeleteScheduleConfirmation = useScheduleDeletion(phase);

    return (
        <div className={cx("container")}>
            {phase.schedule && (
                <>
                    <NavigationLink
                        to={routes.competitionPhase.planSchedule({ phaseId: phase.id })}
                        type="button-primary">
                        {l("CompetitionPhases_PlanSchedule")}
                    </NavigationLink>
                    <DropdownMenu
                        menuItems={[
                            {
                                children: (
                                    <>
                                        <DeleteOutlined /> {l("CompetitionPhases_Details_DeleteSchedule")}
                                    </>
                                ),
                                onClick: showDeleteScheduleConfirmation,
                            },
                        ]}
                    />
                </>
            )}
        </div>
    );
};

export default GroupsPhaseScheduleActions;
