import { Form, Radio, Select as AntdSelect, Switch } from "antd";
import { Rule } from "antd/lib/form";
import { FormInstance } from "antd/lib/form/util";
import ScoreInput, { Score } from "Components/ScoreInput";
import { Select } from "Components/Select";
import { MatchWinnerDTO } from "Contracts/PlayooLeagueClient";
import { l } from "Languages";
import React, { useCallback, useEffect, useMemo } from "react";
import guard from "Utils/guard";
import { isValidInteger } from "Utils/validationHelpers";

const { OptGroup, Option } = AntdSelect;

type Player = { id: string; firstName: string; lastName: string };

type MatchReportFormProps = {
    initialValues?: Partial<FormFields>;
    canEditResult: boolean;
    canMatchEndWithDraw: boolean;
    canEditWinner: boolean;

    team1Name: string;
    team2Name: string;

    team1Players: Player[];
    team2Players: Player[];

    form: FormInstance;
    onFinish: (values: FormFields) => Promise<void>;
    onFinishFailed: () => void;
};

const MatchReportForm: React.FunctionComponent<MatchReportFormProps> = ({
    team1Name,
    team2Name,
    team1Players,
    team2Players,
    form,
    initialValues,
    canEditResult,
    canMatchEndWithDraw,
    canEditWinner,
    onFinish,
    onFinishFailed,
}) => {
    const isValidScoreNumber = useCallback((value: string) => isValidInteger(value) && parseInt(value) >= 0, []);

    const getMatchWinner = useCallback(
        (finalScore: Score | undefined) => {
            if (
                !finalScore ||
                !finalScore.team1 ||
                !finalScore.team2 ||
                !isValidScoreNumber(finalScore.team1) ||
                !isValidScoreNumber(finalScore.team2)
            ) {
                return undefined;
            }

            const parsedTeam1FinalScore = parseInt(finalScore.team1);
            const parsedTeam2FinalScore = parseInt(finalScore.team2);

            if (parsedTeam1FinalScore > parsedTeam2FinalScore) {
                return MatchWinnerDTO.Team1;
            } else if (parsedTeam1FinalScore < parsedTeam2FinalScore) {
                return MatchWinnerDTO.Team2;
            } else {
                return MatchWinnerDTO.Draw;
            }
        },
        [isValidScoreNumber],
    );

    const scoreRules: Rule[] = useMemo(
        () => [
            {
                validator: (rule, value: Score | undefined) => {
                    if (value && ((value.team1 && !value.team2) || (value.team2 && !value.team1))) {
                        return Promise.reject(rule.message);
                    }

                    return Promise.resolve();
                },
                message: l("MatchDetails_PublishMatchReport_Form_Validation_ScoreNotComplete"),
            },
            {
                validator: (rule, value: Score | undefined) => {
                    if (!value) {
                        return Promise.resolve();
                    }

                    if (
                        (value.team1 && !isValidScoreNumber(value.team1)) ||
                        (value.team2 && !isValidScoreNumber(value.team2))
                    ) {
                        return Promise.reject(rule.message);
                    }

                    return Promise.resolve();
                },
                message: l("MatchDetails_PublishMatchReport_Form_Validation_InvalidScore"),
            },
        ],
        [isValidScoreNumber],
    );

    const onValuesChange = useCallback(
        (changedValues: Partial<FormFields>) => {
            if (changedValues.finalScore !== undefined) {
                const matchWinner = getMatchWinner(changedValues.finalScore);

                if (matchWinner === MatchWinnerDTO.Draw && !canMatchEndWithDraw) {
                    return;
                }

                form.setFieldsValue({
                    [guard<FormField>("winner")]: getMatchWinner(changedValues.finalScore),
                });
            }
        },
        [form, getMatchWinner, canMatchEndWithDraw],
    );

    useEffect(() => {
        const matchMvp: FormFields["matchMvp"] = form.getFieldValue(guard<FormField>("matchMvp"));
        const team1Mvp: FormFields["team1Mvp"] = form.getFieldValue(guard<FormField>("team1Mvp"));
        const team2Mvp: FormFields["team2Mvp"] = form.getFieldValue(guard<FormField>("team2Mvp"));

        if (matchMvp && !(team1Players.some(p => p.id === matchMvp) || team2Players.some(p => p.id === matchMvp))) {
            form.setFieldsValue({
                [guard<FormField>("matchMvp")]: undefined,
            });
        }

        if (team1Mvp && !team1Players.some(p => p.id === team1Mvp)) {
            form.setFieldsValue({
                [guard<FormField>("team1Mvp")]: undefined,
            });
        }

        if (team2Mvp && !team2Players.some(p => p.id === team2Mvp)) {
            form.setFieldsValue({
                [guard<FormField>("team2Mvp")]: undefined,
            });
        }
    }, [team1Players, team2Players, form]);

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            onFinishFailed={onFinishFailed}
            initialValues={initialValues}>
            <Form.Item
                label={l("MatchDetails_PublishMatchReport_Form_FinalScore")}
                required
                name={guard<FormField>("finalScore")}
                rules={[
                    ...scoreRules,
                    {
                        validator: (rule, value: Score | undefined) => {
                            if (!value || (!value.team1 && !value.team2)) {
                                return Promise.reject(rule.message);
                            }

                            return Promise.resolve();
                        },
                        message: l("Common_Validation_FieldRequired"),
                    },
                ]}
                validateTrigger="onBlur">
                <ScoreInput team1Name={team1Name} team2Name={team2Name} disabled={!canEditResult} />
            </Form.Item>
            <Form.Item
                label={l("MatchDetails_PublishMatchReport_Form_HalfTimeScore")}
                name={guard<FormField>("halfTimeScore")}
                rules={[...scoreRules]}
                validateTrigger="onBlur">
                <ScoreInput team1Name={team1Name} team2Name={team2Name} />
            </Form.Item>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues: Partial<FormFields>, currValues: Partial<FormFields>) =>
                    prevValues.penaltiesPlayed !== currValues.penaltiesPlayed ||
                    prevValues.finalScore !== currValues.finalScore
                }>
                {({ getFieldValue }) => {
                    const score: FormFields["finalScore"] | undefined = getFieldValue(guard<FormField>("finalScore"));

                    const matchWinner = getMatchWinner(score);

                    return matchWinner === MatchWinnerDTO.Draw ? (
                        <>
                            <Form.Item
                                name={guard<FormField>("penaltiesPlayed")}
                                label={l("MatchDetails_PublishMatchReport_Form_PenaltiesPlayed")}
                                valuePropName="checked">
                                <Switch
                                    checkedChildren={l("MatchDetails_PublishMatchReport_Form_PenaltiesPlayed_Checked")}
                                    unCheckedChildren={l(
                                        "MatchDetails_PublishMatchReport_Form_PenaltiesPlayed_Unchecked",
                                    )}
                                />
                            </Form.Item>
                            {getFieldValue(guard<FormField>("penaltiesPlayed")) && (
                                <Form.Item
                                    name={guard<FormField>("penaltyScore")}
                                    rules={[
                                        ...scoreRules,
                                        ({ getFieldValue }) => ({
                                            validator: (rule, value: FormFields["penaltyScore"]) => {
                                                const penaltiesPlayed: FormFields["penaltiesPlayed"] = getFieldValue(
                                                    guard<FormField>("penaltiesPlayed"),
                                                );

                                                if (penaltiesPlayed && (!value || (!value.team1 && !value.team2))) {
                                                    return Promise.reject(rule.message);
                                                }

                                                return Promise.resolve();
                                            },
                                            message: l("Common_Validation_FieldRequired"),
                                        }),
                                    ]}
                                    validateTrigger="onBlur">
                                    <ScoreInput team1Name={team1Name} team2Name={team2Name} />
                                </Form.Item>
                            )}
                        </>
                    ) : null;
                }}
            </Form.Item>
            <Form.Item
                noStyle
                shouldUpdate={(prevValues: Partial<FormFields>, currValues: Partial<FormFields>) =>
                    prevValues.winner !== currValues.winner || prevValues.finalScore !== currValues.finalScore
                }>
                {({ getFieldValue }) => {
                    const score: FormFields["finalScore"] | undefined = getFieldValue(guard<FormField>("finalScore"));

                    const matchWinner = getMatchWinner(score);

                    return (
                        <Form.Item
                            name={guard<FormField>("winner")}
                            label={l("MatchDetails_PublishMatchReport_Form_Winner")}
                            rules={[
                                {
                                    validator: (rule, value: FormFields["winner"]) => {
                                        if (!canMatchEndWithDraw && value === MatchWinnerDTO.Draw) {
                                            return Promise.reject(rule.message);
                                        }

                                        return Promise.resolve();
                                    },
                                    message: l(
                                        "MatchDetails_PublishMatchReport_Form_Validation_MatchCannotEndWithDraw",
                                    ),
                                },
                                {
                                    required: true,
                                    message: l("Common_Validation_FieldRequired"),
                                },
                            ]}>
                            <Radio.Group disabled={matchWinner !== MatchWinnerDTO.Draw || !canEditWinner}>
                                <Radio value={MatchWinnerDTO.Team1}>{team1Name}</Radio>
                                <Radio value={MatchWinnerDTO.Draw} disabled={!canMatchEndWithDraw}>
                                    {l("MatchDetails_PublishMatchReport_Form_Draw")}
                                </Radio>
                                <Radio value={MatchWinnerDTO.Team2}>{team2Name}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    );
                }}
            </Form.Item>
            <Form.Item name={guard<FormField>("matchMvp")} label={l("MatchDetails_PublishMatchReport_Form_MatchMvp")}>
                <Select showArrow allowClear showSearch>
                    {team1Players.length > 0 && (
                        <OptGroup label={team1Name}>
                            {team1Players.map(p => (
                                <Option value={p.id} key={p.id}>
                                    {p.lastName} {p.firstName}
                                </Option>
                            ))}
                        </OptGroup>
                    )}
                    {team2Players.length > 0 && (
                        <OptGroup label={team2Name}>
                            {team2Players.map(p => (
                                <Option value={p.id} key={p.id}>
                                    {p.lastName} {p.firstName}
                                </Option>
                            ))}
                        </OptGroup>
                    )}
                </Select>
            </Form.Item>
            <Form.Item
                name={guard<FormField>("team1Mvp")}
                label={l("MatchDetails_PublishMatchReport_Form_TeamMvp", team1Name)}>
                <Select
                    showArrow
                    showSearch
                    allowClear
                    selectOptions={team1Players.map(t => ({
                        value: t.id,
                        label: `${t.lastName} ${t.firstName}`,
                        key: t.id,
                    }))}
                />
            </Form.Item>
            <Form.Item
                name={guard<FormField>("team2Mvp")}
                label={l("MatchDetails_PublishMatchReport_Form_TeamMvp", team2Name)}>
                <Select
                    showArrow
                    showSearch
                    allowClear
                    selectOptions={team2Players.map(t => ({
                        value: t.id,
                        label: `${t.lastName} ${t.firstName}`,
                        key: t.id,
                    }))}
                />
            </Form.Item>
        </Form>
    );
};

export type FormFields = {
    finalScore: Required<Score>;
    halfTimeScore?: Score;

    penaltiesPlayed: boolean;
    penaltyScore?: Score;

    winner: MatchWinnerDTO;

    team1Mvp?: string;
    team2Mvp?: string;
    matchMvp?: string;
};

type FormField = keyof FormFields;

export default MatchReportForm;
