import { NthBestForPlaceInGroupReferenceDTO } from "Contracts/PlayooLeagueClient";
import { l } from "Languages";
import ExternalReferenceBase, { ExternalReferenceBaseInit, ExternalReferenceType } from "./ExternalReferenceBase";

class NthBestForPlaceInGroupReference extends ExternalReferenceBase {
    readonly type = ExternalReferenceType.NthBestForPlaceInGroup;

    readonly place: number;
    readonly placeInGroup: number;

    constructor({ place, placeInGroup, ...baseInit }: NthBestForPlaceInGroupReferenceInit) {
        super(baseInit);

        this.place = place;
        this.placeInGroup = placeInGroup;
    }

    static fromDTO(dto: NthBestForPlaceInGroupReferenceDTO, phaseId: string) {
        return new NthBestForPlaceInGroupReference({
            id: dto.Id,
            phaseId: phaseId,
            referencedPhaseId: dto.ReferencedPhaseId,
            place: dto.Place,
            placeInGroup: dto.PlaceInGroup,
            predictedAdvancingTeamId: dto.PredictedAdvancingTeamId ?? undefined,
        });
    }

    get displayName() {
        switch (this.placeInGroup) {
            case 1:
                return l(
                    "CompetitionPhases_ExternalReferences_NthBestForPlaceInGroup_DisplayName_1st",
                    this.place,
                    this.placeInGroup,
                );
            case 2:
            case 3:
                return l(
                    "CompetitionPhases_ExternalReferences_NthBestForPlaceInGroup_DisplayName_2nd_3rd",
                    this.place,
                    this.placeInGroup,
                );
            default:
                return l(
                    "CompetitionPhases_ExternalReferences_NthBestForPlaceInGroup_DisplayName_Nth",
                    this.place,
                    this.placeInGroup,
                );
        }
    }
}

type NthBestForPlaceInGroupReferenceInit = ExternalReferenceBaseInit &
    Pick<NthBestForPlaceInGroupReference, "place" | "placeInGroup">;

export default NthBestForPlaceInGroupReference;
