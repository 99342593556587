import Photo, { PhotoProps } from "Components/Photo";
import config from "config";
import React from "react";

type TeamLogoProps = Pick<PhotoProps, "photo" | "editable" | "onChange" | "small">;

export const TeamLogo: React.FunctionComponent<TeamLogoProps> = ({ ...logoProps }) => {
    return <Photo defaultPhoto={config.defaultTeamLogo} {...logoProps} />;
};

export default TeamLogo;
