import { Skeleton } from "antd";
import {
    isLinkablePhase,
    linkablePhases,
    LinkablePhase,
} from "Domain/CompetitionPhases/ExternalReferences/ExternalReference";
import GroupsPhase from "Domain/CompetitionPhases/GroupsPhase/GroupsPhase";
import GroupsPhaseConfigurator from "Domain/CompetitionPhases/GroupsPhase/GroupsPhaseConfigurator";
import Competition from "Domain/Competitions/Competition";
import GroupsBoard from "DomainComponents/GroupsBoard";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";

type TeamGroupsProps = {
    phase: GroupsPhase;
    competition: Competition;
};

const TeamGroups: React.FunctionComponent<TeamGroupsProps> = observer(({ phase, competition }) => {
    const [phaseConfigurator, setPhaseConfigurator] = useState<GroupsPhaseConfigurator>();

    const linkedPhase = useMemo(() => {
        return phase.linkedPhaseId ? competition.phases?.find(p => p.id === phase.linkedPhaseId) : undefined;
    }, [competition.phases, phase.linkedPhaseId]);

    const linkedPhaseDetailsFetched = linkedPhase?.hasDetails;

    useEffect(() => {
        if (linkedPhase && !linkedPhaseDetailsFetched && linkablePhases.includes(linkedPhase.type)) {
            linkedPhase.fetchDetails();
        }
    }, [phase.linkedPhaseId, linkedPhase, linkedPhaseDetailsFetched]);

    useEffect(() => {
        if (!phase.linkedPhaseId) {
            setPhaseConfigurator(new GroupsPhaseConfigurator(phase, competition));
        }

        if (phase.linkedPhaseId && linkedPhase && isLinkablePhase(linkedPhase) && linkedPhaseDetailsFetched) {
            setPhaseConfigurator(new GroupsPhaseConfigurator(phase, competition, linkedPhase as LinkablePhase));
        }
    }, [competition, phase, linkedPhase, linkedPhaseDetailsFetched]);

    useEffect(() => {
        // when changing groups outside of configurator, changes won't sync automatically
        phaseConfigurator?.syncGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phase.groups]);

    if (!phaseConfigurator) {
        return <Skeleton loading active />;
    }

    return <GroupsBoard phaseConfigurator={phaseConfigurator} />;
});

export default TeamGroups;
