import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import Competition from "Domain/Competitions/Competition";
import { l } from "Languages";
import React, { useCallback } from "react";

const usePlaceholderTeamForPhaseDeletion = (competition: Competition, phaseId: string) => {
    const deletePlaceholderTeamForPhase = useCallback(
        (teamId: string) => {
            return competition.deletePlaceholderTeamForPhase(phaseId, teamId);
        },
        [competition, phaseId],
    );

    const showDeletePlaceholderTeamForPhaseConfirmation = useCallback(
        (teamId: string) => {
            Modal.confirm({
                onOk: () => deletePlaceholderTeamForPhase(teamId),
                icon: <ExclamationCircleOutlined />,
                title: l("CompetitionPhases_PlaceholderTeams_Delete_Confirm_Title"),
                content: l("CompetitionPhases_PlaceholderTeams_Delete_Confirm_Content"),
                okText: l("CompetitionPhases_PlaceholderTeams_Delete_Confirm_OkText"),
                okButtonProps: {
                    danger: true,
                },
                cancelText: l("Common_Cancel"),
                centered: true,
                maskClosable: true,
            });
        },
        [deletePlaceholderTeamForPhase],
    );

    return showDeletePlaceholderTeamForPhaseConfirmation;
};

export default usePlaceholderTeamForPhaseDeletion;
