import { PlusOutlined } from "@ant-design/icons";
import { message, Tag, Tooltip } from "antd";
import NavigationLink from "Components/NavigationLink";
import PageContent from "Components/PageContent";
import ScheduleTableTags from "Components/ScheduleTableTags";
import Spacing from "Components/Spacing";
import CustomPhase from "Domain/CompetitionPhases/CustomPhase/CustomPhase";
import Competition from "Domain/Competitions/Competition";
import CompetitionSportsField from "Domain/Competitions/CompetitionSportsField";
import CustomPhaseMatchFormDialog, { FormFields } from "DomainComponents/Matches/CustomPhaseMatchFormDialog";
import ScheduleTable from "DomainComponents/ScheduleTable";
import { l } from "Languages";
import { Observer, useObserver } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import routes from "Router/routes";

type CustomPhaseScheduleProps = {
    phase: CustomPhase;
    sportsFields: CompetitionSportsField[];
    competition: Competition;
};

const CustomPhaseSchedule: React.FunctionComponent<CustomPhaseScheduleProps> = ({
    phase,
    sportsFields,
    competition,
}) => {
    const [showAddMatchDialog, setShowAddMatchDialog] = useState(false);

    const saveMatchCreation = useCallback(
        async (values: FormFields) => {
            const result = await phase.addMatch({
                name: values.name,
                team1Id: values.team1Id,
                team2Id: values.team2Id,
                matchGroupId: values.matchGroupId,
                date: values.date ?? undefined,
                sportsFieldId: values.sportsFieldId,
            });

            return await result
                .handle(
                    [
                        "GroupNotFound",
                        "MatchIdAlreadyInUse",
                        "NameTooLong",
                        "PhaseNotFoundOrNotACustomPhase",
                        "TeamCannotPlayAgainstItself",
                        "TeamNotFoundInPhase",
                        "SportsFieldNotFound",
                        "failure",
                    ],
                    () => {
                        message.error(l("CompetitionPhases_Custom_AddMatch_Failure"));
                        return false;
                    },
                )
                .handle("success", async () => {
                    await phase.fetchDetails();

                    message.success(l("CompetitionPhases_Custom_AddMatch_Success"));
                    setShowAddMatchDialog(false);

                    return true;
                })
                .check({
                    reducer: async (prev, curr) => prev || (await curr),
                    initialValue: false as boolean | Promise<boolean>,
                });
        },
        [phase],
    );

    return useObserver(() => (
        <>
            <Spacing childrenGutterY>
                {phase.schedule && (
                    <NavigationLink
                        to={routes.competitionPhase.planSchedule({ phaseId: phase.id })}
                        type="button-primary">
                        {l("CompetitionPhases_PlanSchedule")}
                    </NavigationLink>
                )}
                <ScheduleTable
                    competition={competition}
                    matches={phase.schedule}
                    phase={phase}
                    sportsFields={sportsFields}
                    phaseSpecificColumnProps={{
                        render: (_, match) => {
                            const group = match.matchGroupId ? phase.groupsMap?.get(match.matchGroupId) : undefined;

                            return (
                                <Observer>
                                    {() => (
                                        <ScheduleTableTags>
                                            {match.name && (
                                                <Tooltip title={match.name}>
                                                    <Tag color="blue">{match.name}</Tag>
                                                </Tooltip>
                                            )}
                                            {group && (
                                                <Tooltip title={group.name}>
                                                    <Tag>{group.name}</Tag>
                                                </Tooltip>
                                            )}
                                        </ScheduleTableTags>
                                    )}
                                </Observer>
                            );
                        },
                    }}
                />
            </Spacing>
            <PageContent.ActionButton onClick={() => setShowAddMatchDialog(true)}>
                <PlusOutlined /> {l("CompetitionPhases_Custom_AddMatch")}
            </PageContent.ActionButton>
            {showAddMatchDialog && phase.teams && phase.groups && (
                <CustomPhaseMatchFormDialog
                    mode="create"
                    teams={phase.teams}
                    matchGroups={phase.groups}
                    sportsFields={sportsFields}
                    onSave={saveMatchCreation}
                    onClose={() => setShowAddMatchDialog(false)}
                    competition={competition}
                    phase={phase}
                />
            )}
        </>
    ));
};

export default CustomPhaseSchedule;
