import { action, computed, observable } from "mobx";
import SchedulePlanner from "../SchedulePlanner";
import SlotsFilling from "./SlotsFilling";

class SchedulePlanningWizard {
    private readonly schedulePlanner: SchedulePlanner;

    @observable selectedSportsFieldIds: Set<string>;

    @observable slotsFilling?: SlotsFilling;

    constructor(schedulePlanner: SchedulePlanner) {
        this.schedulePlanner = schedulePlanner;
        this.selectedSportsFieldIds = new Set(this.sportsFields.map(sf => sf.id));
    }

    @computed get sportsFields() {
        return this.schedulePlanner.sportsFields;
    }

    @computed get canStartAssigningMatchesToSlots() {
        return this.selectedSportsFieldIds.size > 0;
    }

    @action.bound
    selectSportsField(sportsFieldId: string) {
        this.selectedSportsFieldIds.add(sportsFieldId);
    }

    @action.bound
    unselectSportsField(sportsFieldId: string) {
        this.selectedSportsFieldIds.delete(sportsFieldId);
    }

    @action.bound
    startAssigningMatchesToSlots() {
        if (!this.canStartAssigningMatchesToSlots) {
            throw new Error("No sports fields to assign matches selected.");
        }

        this.slotsFilling = new SlotsFilling(
            this.schedulePlanner,
            this.sportsFields.filter(sf => this.selectedSportsFieldIds.has(sf.id)),
        );
    }
}

export default SchedulePlanningWizard;
