import { PlaceInTableReferenceDTO } from "Contracts/PlayooLeagueClient";
import { l } from "Languages";
import { computed } from "mobx";
import ExternalReferenceBase, { ExternalReferenceBaseInit, ExternalReferenceType } from "./ExternalReferenceBase";

class PlaceInTableReference extends ExternalReferenceBase {
    readonly type = ExternalReferenceType.PlaceInTable;

    readonly placeInTable: number;

    constructor({ placeInTable, ...baseInit }: PlaceInTableReferenceInit) {
        super(baseInit);

        this.placeInTable = placeInTable;
    }

    static fromDTO(dto: PlaceInTableReferenceDTO, phaseId: string) {
        return new PlaceInTableReference({
            id: dto.Id,
            phaseId: phaseId,
            referencedPhaseId: dto.ReferencedPhaseId,
            placeInTable: dto.PlaceInTable,
            predictedAdvancingTeamId: dto.PredictedAdvancingTeamId ?? undefined,
            advancingTeamId: dto.AdvancingTeamId ?? undefined,
        });
    }

    @computed get displayName() {
        return l("CompetitionPhases_Table_AddTeams_PlaceInTable", this.placeInTable);
    }
}

type PlaceInTableReferenceInit = ExternalReferenceBaseInit & Pick<PlaceInTableReference, "placeInTable">;

export default PlaceInTableReference;
