import { Form, Input } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import { l } from "Languages";
import React from "react";
import guard from "Utils/guard";

type CompetitionSportsFieldFormDialogProps = {
    initialValues?: FormFields;

    saveInProgress: boolean;

    mode: "create" | "edit";

    onFinish: (name: string) => Promise<void>;
    onClose: () => void;
};

const CompetitionSportsFieldFormDialog: React.FunctionComponent<CompetitionSportsFieldFormDialogProps> = ({
    initialValues,
    mode,
    saveInProgress,
    onFinish,
    onClose,
}) => {
    const [form] = useForm();

    return (
        <Modal
            title={
                mode === "create"
                    ? l("CompetitionDetails_SportsFields_Form_Title_Create")
                    : l("CompetitionDetails_SportsFields_Form_Title_Edit")
            }
            onCancel={onClose}
            okText={mode === "create" ? l("Common_Add") : l("Common_Save")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}
            okButtonProps={{
                loading: saveInProgress,
                disabled: saveInProgress,
            }}>
            <Form
                layout="vertical"
                form={form}
                onFinish={(values: FormFields) => onFinish(values.name)}
                initialValues={initialValues}>
                <Form.Item
                    label={l("CompetitionDetails_SportsFields_Form_Name")}
                    name={guard<FormField>("name")}
                    rules={[
                        { required: true, message: l("Common_Validation_FieldRequired") },
                        { max: 250, message: l("Common_Validation_FieldTooLong") },
                    ]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export type FormFields = {
    name: string;
};

type FormField = keyof FormFields;

export default CompetitionSportsFieldFormDialog;
