import { PhaseTypeDTO } from "Contracts/PlayooLeagueClient";
import { CompetitionPhase } from "Domain/CompetitionPhases/CompetitionPhase";
import GroupsPhase from "Domain/CompetitionPhases/GroupsPhase/GroupsPhase";
import TeamGroup from "Domain/CompetitionPhases/GroupsPhase/TeamGroup";
import Competition from "Domain/Competitions/Competition";
import { Match } from "Domain/Matches/Match";
import { action, computed, observable } from "mobx";
import PlannerFilter from "./PlannerFilter";

class SeasonPlannerFilter implements PlannerFilter {
    @observable competitions: Competition[];

    @observable private groupIds: Set<string>;
    @observable groups: TeamGroup[];

    @observable private phaseIds: Set<string>;
    @observable phases: CompetitionPhase[];

    constructor(competitions: Competition[]) {
        this.competitions = competitions;

        this.groups = competitions
            .flatMap(c => c.phases)
            .filter(p => p instanceof GroupsPhase)
            .flatMap(p => (p as GroupsPhase).groups ?? []);
        this.groupIds = new Set(this.groups.map(g => g.id));

        this.phases = competitions.flatMap(c => c.phases ?? []);
        this.setPhases(new Set(this.phases.map(p => p.id)));
    }

    @computed get phasesWithoutGroups() {
        return this.phases.filter(p => !(p instanceof GroupsPhase));
    }

    @computed get selectedGroupIds() {
        return [...this.groupIds];
    }

    @computed get selectedPhaseIds() {
        return [...this.phaseIds];
    }

    @computed get isFiltered() {
        return this.groupIds.size !== this.groups.length || this.phaseIds.size !== this.phasesWithoutGroups.length;
    }

    @action.bound
    setAllIds(ids: Set<string>) {
        this.setGroups(new Set([...ids].filter(id => this.groups.some(g => g.id === id))));
        this.setPhases(new Set([...ids].filter(id => this.phasesWithoutGroups.some(p => p.id === id))));
    }

    @action.bound
    setGroups(groupIds: Set<string>) {
        this.groupIds = groupIds;
    }

    @action.bound
    setPhases(phaseIds: Set<string>) {
        this.phaseIds = new Set([...phaseIds].filter(id => this.phasesWithoutGroups.some(p => p.id === id)));
    }

    apply(matches: Match[]) {
        return matches.filter(
            m =>
                (m.phaseType === PhaseTypeDTO.Groups && this.groupIds.has(m.groupId)) ||
                (m.phaseType !== PhaseTypeDTO.Groups && this.phaseIds.has(m.phaseId)),
        );
    }

    isPhaseNotFilteredOut(phase: CompetitionPhase) {
        return (
            this.phaseIds.has(phase.id) ||
            (phase instanceof GroupsPhase && phase.groups?.some(g => this.groupIds.has(g.id)))
        );
    }

    isCompetitionNotFilteredOut(competition: Competition) {
        return competition.phases?.some(p => this.isPhaseNotFilteredOut(p));
    }
}

export default SeasonPlannerFilter;
