import {
    ArrowRightOutlined,
    BarsOutlined,
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import mkCx from "@leancode/cx";
import { Button, message, Modal, Skeleton, Table, Tag } from "antd";
import { ColumnProps } from "antd/lib/table";
import DropdownMenu from "Components/DropdownMenu";
import NavigationLink from "Components/NavigationLink";
import PageContent from "Components/PageContent";
import ReorderButtons from "Components/ReorderButtons";
import SeasonStateTag from "Components/SeasonStateTag";
import { Select } from "Components/Select/index";
import Spacing from "Components/Spacing";
import { SeasonStateDTO } from "Contracts/PlayooLeagueClient";
import competitionGroupStore from "Domain/CompetitionGroups";
import competitionStore from "Domain/Competitions";
import Competition from "Domain/Competitions/Competition";
import OrderedCompetitionStore, { GroupWithCompetitions } from "Domain/Competitions/OrderedCompetitionStore";
import seasonStore from "Domain/Season";
import { l } from "Languages";
import { useObserver } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import routes, { PageComponent } from "Router/routes";
import CompetitionFormDialog from "../CompetitionFormDialog";
import { CompetitionsFormState, FormFields } from "../CompetitionFormDialog/useCompetitionModal";
import styles from "./styles.scss";

const cx = mkCx(styles);

const noSeasonOptionValue = "no-season";

const CompetitionsListPage: PageComponent<typeof routes.competitions> = ({
    match: {
        params: { seasonId },
    },
    history: { replace, push },
}) => {
    const [competitionFormState, setCompetitionFormState] = useState<CompetitionsFormState>({ mode: "off" });

    const orderedCompetitionStore = useMemo(() => new OrderedCompetitionStore(competitionStore), []);

    useEffect(() => {
        orderedCompetitionStore.updateSeasonId(seasonId);
    }, [seasonId, orderedCompetitionStore]);

    useEffect(() => {
        seasonStore.fetchSeasons();

        if (competitionGroupStore.items.length === 0) {
            competitionGroupStore.fetchCompetitionGroups();
        }
    }, []);

    const competitions = useObserver(() => orderedCompetitionStore.orderedCompetitionsInSeason);

    const competitionGroupColumns = useMemo(
        (): ColumnProps<GroupWithCompetitions>[] => [
            {
                render: (_, data) => <b>{data.group?.name ?? l("Competitions_CompetitionsWithoutGroup")}</b>,
            },
        ],
        [],
    );

    const hideCompetition = useCallback(async (competitionId: string) => {
        const result = await competitionStore.hideCompetition(competitionId);

        result
            .handle(["CompetitionAlreadyHidden", "CompetitionNotFound", "failure"], () => {
                message.error(l("Competitions_Hide_Failure"));
            })
            .handle("success", () => {
                message.success(l("Competitions_Hide_Success"));
            })
            .check();
    }, []);

    const showHideCompetitionConfirmation = useCallback(
        (competitionId: string) => {
            Modal.confirm({
                onOk: () => hideCompetition(competitionId),
                icon: <ExclamationCircleOutlined />,
                title: l("Competitions_Hide_Confirmation_Title"),
                content: l("Competitions_Hide_Confirmation_Content"),
                okText: l("Common_Remove"),
                cancelText: l("Common_Cancel"),
                centered: true,
                maskClosable: true,
                okType: "danger",
            });
        },
        [hideCompetition],
    );

    const columns = useMemo(
        (): ColumnProps<Competition>[] => [
            {
                render: (_, competition) => (
                    <Spacing childrenGutterX>
                        <NavigationLink to={routes.competitionDetails({ competitionId: competition.id })}>
                            {competition.name}
                        </NavigationLink>
                        {orderedCompetitionStore.isReordering && competition.group && (
                            <Tag>{competition.group.name}</Tag>
                        )}
                    </Spacing>
                ),
            },
            {
                render: (_, competition, index) => (
                    <div className={cx("actions-cell")}>
                        {orderedCompetitionStore.isReordering && competitions ? (
                            <ReorderButtons
                                isFirst={index === 0 ?? false}
                                isLast={index === competitions.length - 1}
                                onMoveUp={() => orderedCompetitionStore.moveItemUp(competition.id)}
                                onMoveDown={() => orderedCompetitionStore.moveItemDown(competition.id)}
                            />
                        ) : (
                            <DropdownMenu
                                menuItems={[
                                    {
                                        children: (
                                            <>
                                                <CopyOutlined /> {l("Competitions_Copy")}
                                            </>
                                        ),
                                        onClick: () =>
                                            setCompetitionFormState({ mode: "copy", competition: competition }),
                                    },
                                    {
                                        children: (
                                            <>
                                                <EditOutlined /> {l("Competitions_Edit")}
                                            </>
                                        ),
                                        onClick: () =>
                                            setCompetitionFormState({ mode: "edit", competition: competition }),
                                    },
                                    {
                                        children: (
                                            <>
                                                <DeleteOutlined /> {l("Competitions_Hide")}
                                            </>
                                        ),
                                        onClick: () => showHideCompetitionConfirmation(competition.id),
                                    },
                                ]}
                            />
                        )}
                        <NavigationLink to={routes.competitionDetails({ competitionId: competition.id })}>
                            <Button>
                                <ArrowRightOutlined />
                            </Button>
                        </NavigationLink>
                    </div>
                ),
                align: "right",
            },
        ],
        [competitions, orderedCompetitionStore, showHideCompetitionConfirmation],
    );

    const expandedRowRender = useCallback(
        ({ competitions }: GroupWithCompetitions) => (
            <Table dataSource={competitions} columns={columns} showHeader={false} pagination={false} />
        ),
        [columns],
    );

    const seasons = useObserver(() => seasonStore.seasons);

    const onCloseCreateCompetitionDialog = useCallback(
        (savedFormValues?: FormFields) => {
            if (savedFormValues) {
                if (savedFormValues.seasonId !== seasonId) {
                    replace(routes.competitions({ seasonId: savedFormValues.seasonId }));
                } else {
                    orderedCompetitionStore.fetchCompetitionsInSeason(seasonId);
                }
            }

            setCompetitionFormState({ mode: "off" });
        },
        [seasonId, replace, orderedCompetitionStore],
    );

    const currentSeason = seasonId ? seasonStore.getById(seasonId) : undefined;

    return useObserver(() => (
        <>
            <PageContent>
                <PageContent.Card
                    title={l("Competitions_Title")}
                    extra={
                        <Spacing childrenGutterY>
                            <Spacing childrenGutterX>
                                <Select
                                    className={cx("season-select")}
                                    value={seasonId ? currentSeason?.id : noSeasonOptionValue}
                                    onChange={value =>
                                        replace(
                                            routes.competitions({
                                                seasonId: value === noSeasonOptionValue ? undefined : value.toString(),
                                            }),
                                        )
                                    }
                                    selectOptions={[
                                        ...seasons.map(s => ({
                                            key: s.id,
                                            value: s.id,
                                            label: (
                                                <div className={cx("select-label")}>
                                                    <div className={cx("season-name")}>{s.name}</div>
                                                    {s.state !== SeasonStateDTO.Default && (
                                                        <div className={cx("season-state")}>
                                                            <SeasonStateTag seasonState={s.state} />
                                                        </div>
                                                    )}
                                                </div>
                                            ),
                                        })),
                                        {
                                            key: noSeasonOptionValue,
                                            value: noSeasonOptionValue,
                                            label: l("Competitions_SeasonSelection_NoSeason"),
                                        },
                                    ]}
                                    additionalOptions={[
                                        {
                                            content: (
                                                <>
                                                    <BarsOutlined /> {l("Competitions_SeasonSelection_ShowAllSeasons")}
                                                </>
                                            ),
                                            onClick: () => push(routes.seasons()),
                                        },
                                    ]}
                                />
                                <DropdownMenu
                                    menuItems={[
                                        {
                                            children: l("Competitions_Reorder"),
                                            onClick: () => orderedCompetitionStore.startCompetitionsReorder(),
                                            disabled: competitions === undefined || competitions.length <= 1,
                                        },
                                    ]}
                                />
                            </Spacing>
                            {orderedCompetitionStore.isReordering && (
                                <Spacing childrenGutterX className={cx("reorder-buttons")}>
                                    <Button onClick={() => orderedCompetitionStore.stopReorder()}>
                                        {l("Common_Cancel")}
                                    </Button>
                                    <Button
                                        type="primary"
                                        onClick={() => orderedCompetitionStore.saveNewCompetitionsOrder()}>
                                        {l("Common_Save")}
                                    </Button>
                                </Spacing>
                            )}
                        </Spacing>
                    }>
                    <Spacing childrenGutterY>
                        <Skeleton loading={!competitions} active={!competitions}>
                            {orderedCompetitionStore.areAllCompetitionsWithoutGroup ||
                            orderedCompetitionStore.isReordering ? (
                                <Table
                                    columns={columns}
                                    dataSource={orderedCompetitionStore.draft ?? competitions}
                                    pagination={false}
                                    showHeader={false}
                                    rowKey={c => c.id}
                                />
                            ) : (
                                <Table
                                    columns={competitionGroupColumns}
                                    dataSource={orderedCompetitionStore.competitionsInSeasonByGroup}
                                    pagination={false}
                                    showHeader={false}
                                    expandable={{
                                        expandedRowRender: expandedRowRender,
                                        expandedRowKeys: orderedCompetitionStore.competitionsInSeasonByGroup.map(
                                            r => r.group?.id ?? "",
                                        ),
                                        expandIcon: () => <div className={cx("expand-icon")} />,
                                    }}
                                    rowKey={r => r.group?.id ?? ""}
                                />
                            )}
                        </Skeleton>
                        {!orderedCompetitionStore.isReordering && (
                            <PageContent.ActionButton onClick={() => setCompetitionFormState({ mode: "create" })}>
                                <PlusOutlined /> {l("Competitions_AddNew")}
                            </PageContent.ActionButton>
                        )}
                    </Spacing>
                </PageContent.Card>
            </PageContent>
            {competitionFormState.mode !== "off" && (
                <CompetitionFormDialog
                    formState={competitionFormState}
                    initialValues={{ seasonId: seasonId }}
                    onClose={onCloseCreateCompetitionDialog}
                />
            )}
        </>
    ));
};

export default CompetitionsListPage;
