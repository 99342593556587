import { Form, Input, message, Spin } from "antd";
import { useForm } from "antd/lib/form/util";
import Modal from "Components/Modal";
import PhoneNumberInput from "Components/PhoneNumberInput";
import competitionStore from "Domain/Competitions";
import CompetitionTeamPlayer from "Domain/Competitions/CompetitionTeamPlayer";
import isPromise from "is-promise";
import { l } from "Languages";
import React, { useCallback } from "react";
import guard from "Utils/guard";
import useRunInTask from "Utils/Hooks/useRunInTask";
import { isValidPhoneNumber } from "Utils/validationHelpers";

type ProfileInvitationFormDialogProps = {
    player: CompetitionTeamPlayer;
    onClose: () => void;
};

const ProfileInvitationFormDialog: React.FunctionComponent<ProfileInvitationFormDialogProps> = ({
    player,
    onClose,
}) => {
    const [form] = useForm();
    const [isRunning, runInTask] = useRunInTask();

    const setPhoneNumberError = useCallback(
        (value: FormFields["phoneNumber"], error: string) =>
            form.setFields([
                {
                    name: guard<FormField>("phoneNumber"),
                    errors: [error],
                    value: value,
                },
            ]),
        [form],
    );

    const onFinish = useCallback(
        (values: FormFields) => {
            runInTask(async () => {
                const result = await player.inviteToProfile(values.countryCode, values.phoneNumber);

                await Promise.all(
                    result
                        .handle(["TeamNotFound", "UserNotAPlayerInTeam", "UserAlreadyHasAccount", "failure"], () => {
                            message.error(l("CompetitionTeamPlayers_Invite_Failure"));
                        })
                        .handle("PhoneNumberInvalidFormat", () => {
                            setPhoneNumberError(values.phoneNumber, l("Common_Validation_InvalidPhoneNumber"));
                        })
                        .handle("InvitationAlreadyExists", () => {
                            setPhoneNumberError(
                                values.phoneNumber,
                                l("CompetitionTeamPlayers_Invite_InvitationAlreadyExists"),
                            );
                        })
                        .handle("PhoneNumberNullOrEmpty", () => {
                            setPhoneNumberError(values.phoneNumber, l("Common_Validation_FieldRequired"));
                        })
                        .handle("success", async () => {
                            message.success(l("CompetitionTeamPlayers_Invite_Success"));

                            await competitionStore.fetchCompetitionTeamDetails(player.teamId);

                            onClose();
                        })
                        .check({
                            reducer: (prev, curr) => [...prev, ...(isPromise(curr) ? [curr] : [])],
                            initialValue: [] as Promise<void>[],
                        }),
                );
            });
        },
        [runInTask, onClose, setPhoneNumberError, player],
    );

    return (
        <Modal
            title={l("CompetitionTeamPlayers_Invite_Form_Title")}
            onCancel={() => onClose?.()}
            okText={l("CompetitionTeamPlayers_Invite_Form_OkText")}
            cancelText={l("Common_Cancel")}
            onOk={form.submit}
            okButtonProps={{
                disabled: isRunning,
                loading: isRunning,
            }}>
            <Spin spinning={isRunning}>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    initialValues={{
                        countryCode: "+48",
                    }}>
                    <Form.Item label={l("Common_Form_CountryCode")} name={guard<FormField>("countryCode")}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        label={l("Common_Form_PhoneNumber")}
                        name={guard<FormField>("phoneNumber")}
                        rules={[
                            { required: true, message: l("Common_Validation_FieldRequired") },
                            {
                                message: l("Common_Validation_InvalidPhoneNumber"),
                                validator: (rule, value: FormFields["phoneNumber"] | undefined, callback) => {
                                    if (value && !isValidPhoneNumber(value)) {
                                        callback(rule.message as string);
                                    } else {
                                        callback();
                                    }
                                },
                                validateTrigger: "onBlur",
                            },
                        ]}>
                        <PhoneNumberInput />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

export type FormFields = {
    countryCode: string;
    phoneNumber: string;
};

type FormField = keyof FormFields;

export default ProfileInvitationFormDialog;
